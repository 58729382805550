const AboutPageObjects=[
    {
    id:"About0",
    name:"About One",
    height:"1000px",
    code:` 
    <head>

    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
      
    </head>
     <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    </style>
   

<style>
     * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      font-family: "Poppins", sans-serif;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      width: 100%;
      margin: 0px;
      background:#fff;
    }

    /* page title start */
    .page_title_section {
        text-align: center;
        width: 100%;
        display: block;
        margin-bottom:50px;
        position: relative;
        }
        .page_title_section img{
        width: 100%;
    }
     .page_title_main{
    display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
           position: absolute;
        top: 0px;
    }
    .page_title{
      max-width: 500px;
      margin:0 auto;
    display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
         .page_title_main .page_title h2 {
            font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 43px;
    line-height: 46px;
    color: #000;
    margin: 0px;
    }
     .page_title_main .page_title p{
        font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
    padding-top:8px;
    }
    
    @media(max-width:540px){
     .page_title_main .page_title h2 {
        font-size: 28px !important;
    }
    
    .page_title_section{
       margin-bottom:30px;
    }
    }
    /* page title end */

    .about_page_content{
    margin-bottom:60px;
    display:block;
    width:100%;
    }
    .about_page_section_one_main{
    display:block;
    width:100%;
    }
    .custom_container{
    max-width:1140px;
    margin:0 auto;
    }
    .about_page_section_one_content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: ". .";
        grid-template-rows: auto;
        gap: 15px 0px;
        position: relative;
        z-index: 1;
        width: 100%;
        align-items: center;
    height:100%;
background: #FAF5EB;
    }

    .about_page_section_one_content_body{
       display: flex;
    width: 100%;
    padding: 30px 50px 30px 50px;
    flex-direction: column;
    }
    .about_page_section_one_content_heading{
     display: flex;
        width: 100%;
        padding-bottom:20px;
        flex-direction: column;
    }
    .about_page_section_one_content_heading h3{
        font-family: "Poppins", sans-serif;
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 58px;
text-transform: capitalize;
color: #03242D;
    }
    .about_page_section_one_content_des{
        display: grid;
        width: 100%;
    padding-bottom:40px;
    }
    .about_page_section_one_content_des p{
        font-family: "Poppins", sans-serif;
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 24px;
color: rgba(0, 0, 0, 0.6);
    }
    .about_page_section_one_content_btn{
        display: flex;
    }
    .about_page_section_one_content_btn a {
        font-family: "Poppins", sans-serif;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #03242D;
      width: 146px;
height: 48px;
background: #D8EAFF;
border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
.about_page_section_one_figure {
    width: 100%;
height:100%;
}
.about_page_section_one_figure figure {
    margin: 0px;
    width: 100%;
height:100%;
}
.about_page_section_one_figure figure img{
    width: 100%;
height:100%;
}
    .about_page_section_two_content{
background: #E8F2FF !important;
}
 
    @media(max-width:1024px){
    .about_page_section_one_content_body {
    padding: 30px 20px 30px 20px;
    }

    .about_page_section_one_content_heading h3 {
        font-size: 30px;
        line-height: 33px;
    }
    .about_page_section_one_content_des {
        padding-bottom: 30px;
    }
 
    }
    @media(max-width:768px){
    .about_page_section_one_content {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .about_page_section_two_content{
        display: flex;
        flex-direction: column-reverse;
        gap: 0px;
    }
    .about_page_section_one_content_body {
         padding: 30px 15px 30px 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
    }
 .about_page_section_one_figure figure {
    max-width: 320px;
    margin: 0 auto;
    padding-bottom: 30px;
}
    }
   
    </style>
<section class="page_title_section"><img src="https://static.tossdown.com/images/bc0e66c3-493f-4620-af79-bc6f91e0f5dd.jpg" />
<div class="page_title_main">
<div class="page_title">
<h2>About</h2>
</div>
</div>
</section>
<section class="about_page_content">
<div class="about_page_section_one_main">
<div class="custom_container">
<div class="about_page_section_one_content">
<div class="about_page_section_one_content_body">
<div class="about_page_section_one_content_heading">
<h3>Proudly Canadian</h3>
</div>
<div class="about_page_section_one_content_des">
<p>We love our beautiful country and the fact that we are able to research, develop, and manufacture all of our products right here in Canada. We believe in upholding the highest standards possible. That’s why we source our ingredients from the best suppliers, undergo the most stringent Canadian quality assurance procedures, and maintain manufacturing excellence. DermaNiche strives to support other local businesses, so we’ve partnered with spas and salons across Canada to help provide you with the best natural, luxury skincare and supplement products possible. Celebrate the natural beauty Canada has to offer with DermaNiche!</p>
</div>
<div class="about_page_section_one_content_btn"><a href="/shop">Shop Product</a></div>
</div>
<div class="about_page_section_one_figure">
<figure><img src="https://tossdown.site/images/17626406c2456898786e6f5a7224de37_1630592991.png" /></figure>
</div>
</div>
<div class="about_page_section_one_content about_page_section_two_content">
<div class="about_page_section_one_figure">
<figure><img src="https://tossdown.site/images/e1382e75e9125163cc08988724658283_1634733444.jpg" /></figure>
</div>
<div class="about_page_section_one_content_body">
<div class="about_page_section_one_content_heading">
<h3>Why Choose Us?</h3>
</div>
<div class="about_page_section_one_content_des">
<p>Feel confident and beautiful in your own skin with a natural routine you can trust. DermaNiche is made to let your natural beauty shine through effortlessly by promoting overall wellness and youthful, healthy skin. We’ve crafted our 100% natural formulations without harsh perfumes and chemicals that can cause irritation. DermaNiche aims to encourage your skin’s natural defensive, protective, and healing abilities with potent, natural Ayurvedic ingredients. Our products are also cruelty-free, halal-certified, and the majority of our products 100% vegan! Bring a bit of the spa home with you with the best nature can provide from DermaNiche.</p>
</div>
<div class="about_page_section_one_content_btn"><a href="/shop">Shop Product</a></div>
</div>
</div>
</div>
</div>
</section>
    
    `
    },
    {
      id:"About1",
      name:"About Two",
      height:"1000px",
        code:`
        <head>

        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        /> 
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
          
        </head>
         <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        </style>
       
    
    <style>
         * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
          font-family: "Poppins", sans-serif;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          width: 100%;
          margin: 0px;
          background: #FDF8F3 !important;
        }
    
        /* page title start */
        .page_title_section {
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom:50px;
            position: relative;
            }
            .page_title_section img{
            width: 100%;
        }
         .page_title_main{
        display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
               position: absolute;
            top: 0px;
        }
        .page_title{
          max-width: 500px;
          margin:0 auto;
        display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
        }
             .page_title_main .page_title h2 {
                font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 43px;
        line-height: 46px;
        color: #000;
        margin: 0px;
        }
         .page_title_main .page_title p{
            font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.8);
        padding-top:8px;
        }
        
        @media(max-width:540px){
         .page_title_main .page_title h2 {
            font-size: 28px !important;
        }
        
        .page_title_section{
           margin-bottom:30px;
        }
        }
        /* page title end */
    
        .about_page_content{
        margin-bottom:60px;
        display:block;
        width:100%;
        }
        .about_page_section_one_main{
        display:block;
        width:100%;
        padding: 0px 15px;
        padding-bottom: 50px;
        }
        .custom_container{
        max-width:1140px;
        margin:0 auto;
        }
        .about_page_section_one_content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: ". .";
        grid-template-rows: auto;
        gap: 15px 0px;
        position: relative;
        width: 100%;
        align-items: center;
        height: 100%;
       
        }
    
        .about_page_section_one_content_body{
            display: flex;
        width: 100%;
        height: 100%;
        padding: 15px 38px 15px 68px;
        box-shadow: 1px 0 0 0 #F58320, 0 1px 0 0 #F58320, 1px 1px 0 0 #F58320, /* Just to fix the corner */ 1px 0 0 0 #F58320 inset, 0 1px 0 0 #F58320 inset;
        flex-direction: column;
        justify-content: center;
        }
        .about_page_section_one_content_heading{
         display: flex;
            width: 100%;
            flex-direction: column;
            max-width: 400px;
        }
        .about_page_section_one_content_heading small{
            font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        padding-bottom: 4px;
        color: #F58320;
        position: relative;
        }
        .about_page_section_one_content_heading small:before {
        position: absolute;
        content: "";
        right: 0px;
        top: -28px;
        background-image: url('https://static.tossdown.com/images/83c984c1-b7e6-48e6-900e-f9014772d9e4.png');
        width: 80px;
        height: 81px;
        background-size: contain;
        background-repeat: no-repeat;
    }
        .about_page_section_one_content_heading h3{
            font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 61px;
        color: #000000;
        padding-bottom: 6px;
        }
        .about_page_section_one_content_des{
            display: grid;
            width: 100%;
        }
        .about_page_section_one_content_des p{
            font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #2A2A2A;
        padding-bottom: 25px;
        }
        .about_page_section_one_content_btn{
            display: flex;
        }
        .about_page_section_one_content_btn a {
            font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #03242D;
          width: 146px;
    height: 48px;
    background: #D8EAFF;
    border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    .about_page_section_one_figure {
        width: 100%;
    height:100%;
    }
    .about_page_section_one_figure figure {
        margin-bottom: 0px;
        width: 100%;
        box-shadow: 1px 0 0 0 #F58320, 0 1px 0 0 #F58320, 1px 1px 0 0 #F58320, /* Just to fix the corner */ 1px 0 0 0 #F58320 inset, 0 1px 0 0 #F58320 inset;
        padding: 35px;
        height: 100%;
    }
    .about_page_section_one_figure figure img{
        width: 100%;
    height:100%;
    }
    
    /*  */
    .about_page_section_two_main{
        display:block;
        width:100%;
        padding: 0px 15px;
   
        }
    .about_page_section_two_content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
    }
    .about_page_section_two_content h2 {
        font-style: normal;
        font-weight: bold !important;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin: 0px !important;
        font-family: 'Poppins';
        padding-bottom: 11px;
    }
    .about_page_section_two_content p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #2A2A2A;
        padding-bottom: 20px;
    }

    /*  */

.about_page_section_three_main{

    display:block;
        width:100%;
        padding: 0px 15px;
}
.about_page_section_three_content{

    gap: 15px 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.about_page_section_three_content_body {
    background: #ffffff;
    box-shadow: 0px 4px 34px rgb(0 0 0 / 8%);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px 25px;
    gap: 5px 0px;
}
.about_page_section_three_content_body h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin: 0px !important;
    font-family: 'Poppins';
}
.about_page_section_three_content_body small {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-family: 'Poppins';
}

     
        @media(max-width:1024px){
            .about_page_section_one_content_body {
        padding: 35px;
    }
    .about_page_section_one_content_heading small:before {
        right: -29px;
    }
    
     
        }
        @media(max-width:768px){
            .about_page_section_one_content{
        display: flex;
        flex-direction: column-reverse;
        gap: 0px;
    }
    .about_page_section_one_content_body {
        text-align: center;
    }
    .about_page_section_one_content_heading small:before {
        right: 0px;
        top: -20px;
    }
    .about_page_section_one_content_heading{
        margin: 0 auto;
    }
    .about_page_section_one_content_btn {
        justify-content: center;
    }
    .about_page_section_three_content {
        grid-template-columns: 1fr;
    }
        }
        @media(max-width:540px){
            .about_page_section_one_figure figure {
        padding: 15px;
    }
    .about_page_section_one_content_body{
        padding: 35px 15px;
    }
    .about_page_section_one_content_heading small:before {
        width: 50px;
    }
    .about_page_section_one_content_heading h3 {
        font-size: 36px;
        line-height: 46px;
    }
    }
       
        </style>
    <section class="page_title_section"><img src="https://static.tossdown.com/images/24c65a7d-aa67-4e05-97aa-e605b458b4d8.jpg" />
    <div class="page_title_main">
    <div class="page_title">
    <h2>Our Brand Story</h2>
    </div>
    </div>
    </section>
    <section class="about_page_content">
    <div class="about_page_section_one_main">
    <div class="custom_container">
    <div class="about_page_section_one_content">
        <div class="about_page_section_one_figure">
            <figure><img src="https://static.tossdown.com/images/ce9cb2fd-8358-4bd6-9a78-53d8e6b6f7b7.png" /></figure>
            </div>
    <div class="about_page_section_one_content_body">
    <div class="about_page_section_one_content_heading">
        <small>OUR STORY</small>
        <h3>A Message From the CEO</h3>
    </div>
    <div class="about_page_section_one_content_des">
        <p>Back in 2012, I had a dream to bring together two of my favorite foods from around the world. Ever since that vision, i acquired a highly dedicated team of researchers and professionals to create a concept that the community would love best. After years of production and hard work, Döner &amp; Gyros was born and made its debut in 2014.</p>
    </div>
    <div class="about_page_section_one_content_btn"><a href="/shop">Shop Product</a></div>
    </div>
    
    </div>
   
    </div>
    </div>

    <div class="about_page_section_two_main">
    <div class="custom_container">
    <div class="about_page_section_two_content">
    <h2>DÖNER &amp; GYROS WERE BORN TO BRING TWO OF THE MOST ICONIC SANDWICHES TO STREETS ACROSS THE WORLD.</h2>
    <p>Both of these sandwiches originates from the Mediterranean, and have in common a love for the freshest ingredients and great tasting food. However, each took a different journey in becoming famous. And just like any other good history story, the real person behind their fame is passionately contested with fortunes made and lost.</p>
    </div>
    </div>
    </div>

    <div class="about_page_section_three_main">
    <div class="custom_container">
    <div class="about_page_section_three_content">
    <div class="about_page_section_three_content_body">
    <h6>The Gyro Sandwich</h6>
    <small>Originated from Greece, and although similar to the döner, have a distinct flavour of its own – and is always topped with freshly made tzatziki sauce. The gyro sandwich can claim its journey to fame through a Greek immigrant who opened his first restaurant in Chicago in 1965.</small>
   </div>
    <div class="about_page_section_three_content_body">
    <h6>The Döner Sandwich</h6>
    <small>Whose heritage goes as far back as the Ottoman Empire, were made famous by a Turkish immigrant Chef Mahmut Aygun who opened his first restaurant in Berlin, Germany in 1971 – and has since grown to the most loved "on-the-go" sandwich in Germany and Europe.</small>
   </div>
    </div>
  </div>
  </div>


    </section>
    

        `,
        },
        {
          id:"About3",
          name:"About Three",
          height:"1000px",
            code:`

            <head>

            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            /> 
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
              
            </head>
             <style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            </style>
           
        
        <style>
             * {
              -web-kit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              font-family: "Poppins", sans-serif;
            }
            html {
              scroll-behavior: smooth;
            }
            body {
              float: left;
              width: 100%;
              margin: 0px;
              background:#fff;
            }
        
            /* page title start */
            .page_title_section {
                text-align: center;
                width: 100%;
                display: block;
                margin-bottom:50px;
                position: relative;
                }
                .page_title_section img{
                width: 100%;
            }
             .page_title_main{
            display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                height: 100%;
                   position: absolute;
                top: 0px;
            }
            .page_title{
              max-width: 500px;
              margin:0 auto;
            display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-direction: column;
            }
                 .page_title_main .page_title h2 {
                    font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 43px;
            line-height: 46px;
            color: #000;
            margin: 0px;
            }
             .page_title_main .page_title p{
                font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.8);
            padding-top:8px;
            }
            
            @media(max-width:540px){
             .page_title_main .page_title h2 {
                font-size: 28px !important;
            }
            
            .page_title_section{
               margin-bottom:30px;
            }
            }
            /* page title end */
        
            .about_page_content{
            margin-bottom:60px;
            display:block;
            width:100%;
            padding: 0px 15px;
            }
            .about_page_section_one_main{
            display:block;
            width:100%;
            padding-bottom:50px;
            }
            .custom_container{
            max-width:1140px;
            margin:0 auto;
            }
            .about_page_section_one_content{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: ". .";
            grid-template-rows: auto;
            gap: 15px 0px;
            position: relative;
            width: 100%;
            align-items: center;
            height: 100%;
            background: #fff;
            box-shadow: 0 0 30px rgb(0 0 0 / 8%);
            }
        
            .about_page_section_one_content_body{
                display: flex;
            width: 100%;
            height: 100%;
            padding: 15px 38px 15px 68px;
            flex-direction: column;
            justify-content: center;
            }
            .about_page_section_one_content_heading{
             display: flex;
                width: 100%;
                padding-bottom: 10px;
                flex-direction: column;
                max-width: 330px;
            }
            .about_page_section_one_content_heading small{
                font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            padding-bottom: 4px;
            color: #000;
            position: relative;
            }
           
            .about_page_section_one_content_heading h3{
                font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 44px;
            line-height:52px;
            color: #000000;
            
            }
            .about_page_section_one_content_des {
                display: flex;
                width: 100%;
                padding-bottom: 40px;
                flex-direction: column;
               
            }
            .about_page_section_one_content_des p{
                font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: #2A2A2A;
            padding-bottom: 16px;
            }
            .about_page_section_one_content_des ul {
              display: flex;
            flex-direction: column;
            
        }
        .about_page_section_one_content_des ul li {
            display: flex;
            align-items: center;
            padding-bottom:15px ;
        }
        .about_page_section_one_content_des ul li:last-child{
            padding-bottom: 0px;
        }
        .about_page_section_one_content_des ul li i {
            color: #37887D;
            font-size: 18px;
            padding-right: 12px;
        }
        .about_page_section_one_content_des ul li span {
            font-size: 16px;
            font-family: 'Poppins';
            font-weight: 700;
            color: #153531;
            text-align: left;
        }
            .about_page_section_one_content_btn{
                display: flex;
            }
            .about_page_section_one_content_btn a {
                font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
              width: 146px;
        height: 48px;
        background: #000;
        border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        .about_page_section_one_figure {
            width: 100%;
        height:100%;
        }
        .about_page_section_one_figure figure {
            margin-bottom: 0px;
            width: 100%;
            height: 100%;
        }
        .about_page_section_one_figure figure img{
            width: 100%;
        height:100%;
        }

        .about_page_section_two_main{
            display:block;
            width:100%;
            }
        .about_page_section_two_content{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: ". .";
            grid-template-rows: auto;
            gap: 15px 0px;
            position: relative;
            width: 100%;
            align-items: center;
            height: 100%;
            }
            .about_page_section_two_content_body{
                display: flex;
            width: 100%;
            height: 100%;
            padding: 0px 68px 0px 38px;
            flex-direction: column;
            justify-content: center;
            }
 
        @media(max-width:1024px){
            .about_page_section_one_content_body {
        padding: 35px;
         }   
         .about_page_section_two_content_body {
            padding: 0px 35px;
             }     
        }
        @media(max-width:768px){
            .about_page_section_one_content{
        display: flex;
        flex-direction: column-reverse;
        gap: 0px;
    }
    .about_page_section_two_content{
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .about_page_section_one_content_body {
        text-align: center;
    }
    .about_page_section_two_content_body {
        text-align: center;
         }   
  
    .about_page_section_one_content_heading{
        margin: 0 auto;
    }
    .about_page_section_one_content_des {
        align-items: center;
        justify-content: center;
    }
    .about_page_section_one_content_btn {
        justify-content: center;
    }
    .about_page_section_one_figure figure {
        max-width: 320px;
        margin: 0 auto;
        padding-bottom: 20px;
    }
        }
        @media(max-width:540px){
          
    .about_page_section_one_content_body{
        padding: 35px 15px;
    }
    .about_page_section_two_content_body{
        padding: 0px 15px;
    }
    .about_page_section_one_content_heading h3 {
        font-size: 36px;
        line-height: 46px;
    }
    }
       
        </style>
        
        <section class="page_title_section"><img src="https://static.tossdown.com/images/24c65a7d-aa67-4e05-97aa-e605b458b4d8.jpg" />
<div class="page_title_main">
<div class="page_title">
<h2>About Us</h2>
</div>
</div>
</section>
<section class="about_page_content">
<div class="about_page_section_one_main">
<div class="custom_container">
<div class="about_page_section_one_content">
    <div class="about_page_section_one_figure">
        <figure><img src="https://tossdown.site/images/d5293159e3b30c29d4d370e649351b35_1641887735.jpg" /></figure>
        </div>
<div class="about_page_section_one_content_body">
<div class="about_page_section_one_content_heading">
    <small>About</small>
    <h3>We Provide Best Meat</h3>
</div>
<div class="about_page_section_one_content_des">
    <p>We provide well shaped fresh and organic meat from our farm in a very hygienic way.</p>
    <ul>
        <li><i class="far fa-check-circle" aria-hidden="true"></i><span>100% Organic Meat</span></li>
        <li><i class="far fa-check-circle" aria-hidden="true"></i><span>Payment Securation</span></li>
    </ul>
</div>
<div class="about_page_section_one_content_btn"><a href="/shop">Shop Product</a></div>
</div>

</div>

</div>
</div>




<div class="about_page_section_two_main">
<div class="custom_container">
<div class="about_page_section_two_content">
   
<div class="about_page_section_two_content_body">
<div class="about_page_section_one_content_heading">
    <small>About</small>
    <h3>About Our Firm’s</h3>
</div>
<div class="about_page_section_one_content_des">
    <p>Last 30 years we provided services with trust and honesty. We give our customers fresh and organic meat.We have experts who verify everything so that customers can not face any difficulties.</p>
    <ul>
        <li><i class="far fa-check-circle" aria-hidden="true"></i><span>Expert Customer</span></li>
        <li><i class="far fa-check-circle" aria-hidden="true"></i><span>Free Return</span></li>
        <li><i class="far fa-check-circle" aria-hidden="true"></i><span>Free Shipping</span></li>
        <li><i class="far fa-check-circle" aria-hidden="true"></i><span>Amazing Deals</span></li>
    </ul>
</div>
</div>


<div class="about_page_section_one_figure">
<figure><img src="https://tossdown.site/images/2dbd1075c3be3153352f036667418b53_1641906035.jpg" /></figure>
</div>

</div>

</div>
</div>



</section>
            
            `,
            },    
    
    ]
    export default AboutPageObjects