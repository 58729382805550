const ProductCategorySectionObjects=[
    {
    id:"categorysection0",
    name:"CategorySection 0ne",
    height:"500px",
    head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>

    `,
    code:` 
    
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
    </style>
    <style>
    * {
     -web-kit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
     padding: 0px;
     margin: 0px;
   }
   html {
     scroll-behavior: smooth;
   }
   body {
     float: left;
     margin: 0px;
     width: 100%;
     background:#fff;
   }
    .product_category_section {
       display: block;
       width: 100%;
       padding:0px 15px 0px 15px;
       margin-bottom: 70px;
       margin-top: 40px;
       float: left;
     }
     .custom_container {
       max-width: 1140px;
       margin: 0px auto;
     }

   .product_category_main {
float:left;
width:100%;
margin:0px -20px;
}
.custom_sliding_box {
float:left;
width:25%;
padding:30px 20px;
display: flex;
   flex-direction: column;
   justify-content: center;
}

.custom_sliding_box small {
 font-family: 'Poppins', sans-serif;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 22px;
   text-transform: uppercase;
   color: #E7AFBA;
}
.custom_sliding_box  h5 {
   font-family: Prata;
   font-style: normal;
   font-weight: normal;
   font-size: 44px;
   line-height: 60px;
   color: #514533;
   padding-bottom: 8px;
   margin: 0px;
}
.custom_sliding_box p {
 font-family: 'Poppins', sans-serif;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 22px;
   color: rgba(0, 0, 0, 0.7);
   padding-bottom: 25px;
   margin: 0px;
}
.category_slider_arrow_box {
   display: flex;
}
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
   background: #FFFFFF;
   box-shadow: 0px 4px 14px rgb(0 0 0 / 8%);
   width: 44px;
   height: 44px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 10px;
}
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow:hover{
   background:#F5F5F5;
}
    
     .product_category_list {
       float: left;
       width: 75%;
       padding: 0px 20px;
     }
     .product_category_list ul {
       margin: 0px -10px;
       padding: 0px;
     }
     .product_category_list ul li {
       list-style: none;
       width: 25%;
       padding: 15px 10px 15px 10px;
       float: left;
         margin: 0px !important;
     }

     .product_category_figure_box {
       width: 100%;
       display: flex;
       flex-direction: column;
       background: #ffffff;
       justify-content: space-between;
       box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
     }
     .product_category_figure_box figure {
       margin-bottom: 0px;
     }
     .product_category_figure_box figure a {
       width: 100%;
       height: 100%;
       display: block;
     }
     .product_category_figure_box figure a img {
   width:100%;
   height:250px;
   object-fit: cover;
     }
     .product_category_figure_tags {
       display: flex;
       width: 100%;
       align-items: center;
       justify-content: center;
       position: relative;
       height: 100%;
     }
     .product_category_figure_tags a {
       width: 100%;
       padding: 15px 10px 15px 10px;
       display: flex;
      justify-content: center;
       align-items: center;
       text-decoration: none;
       height: 100%;
     }
     .product_category_figure_tags a span {
       font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
text-transform: capitalize;
color: #514533;
     }
     .product_category_figure_tags a i {
       font-style: normal;
       font-weight: 500;
       font-size: 18px;
       line-height: 24px;
       color: #212121;
       padding-left: 10px;
     }
 @media (max-width: 1024px) {
.product_category_main {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   margin: 0px 0px;

}
.custom_sliding_box {
   margin-bottom: 30px;
   padding: 0px;
   width: 100%;
}
.product_category_list {
width:100%;
padding:0px;
}

}
     @media (max-width: 540px) {
       .product_category_figure_tags a {
         flex-direction: column;
         align-items: center;
         justify-content: center;
         width: 100%;
       }

       .product_category_figure_tags a i {
         display: none;
       }
     }

   </style>
<section class="product_category_section">
<div class="custom_container">
<div class="product_category_main">
<div class="custom_sliding_box">
 <small>Shop collection</small>
<h5>Categories</h5>
<p>We offer now a great range of different flavored bite size different flavors in categories.</p>
<div class="category_slider_arrow_box"></div>
</div>
<div class="product_category_list">
<!-- <ul class="product_category_slider">
 <?php
           foreach($featured_category_section->result as $valueCategory) {
               $regex = '#[^a-z0-9_]#i';
               $val = preg_replace($regex, '', $valueCategory->category_name);
               $rel = strtolower($val);
               $urlCategory = "menu/".$rel;
$cdnHeight = '212' ;
$cdnWidth = '212' ;
$large_image = $valueCategory->image;
       ?>
<li>
<div class="product_category_figure_box">
<figure>
 <?php
                   echo '<a href="'.$urlCategory.'">';
                   echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                   echo '</a>';
               ?>
             </figure>
<div class="product_category_figure_tags"><?php
                   echo '<a href="'.$urlCategory.'">';
               echo '<span>';     
              echo $valueCategory->category_name;    
                 echo '</span>';     
               
                   echo '</a>';
               ?></div>
</div>
</li>
<?php }?>
</ul> -->


<ul class="product_category_slider">

<li>
<div class="product_category_figure_box">
<figure>
<a >
<img  class="lazy" src="https://static.tossdown.com/images/a2407daa-df31-4b71-98fe-d78663e7c1d4.jpg"  />
</a>
</figure>
<div class="product_category_figure_tags">
 <a>
 <span>  
  Custom Cakes
</span>    
</a>
</div>
</div>
</li>
<li>
 <div class="product_category_figure_box">
 <figure>
 <a >
 <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
 </a>
  </figure>
 <div class="product_category_figure_tags">
   <a>
   <span>  
   Cup Cakes
  </span>    
  </a>
  </div>
 </div>
 </li>
 <li>
   <div class="product_category_figure_box">
   <figure>
   <a >
   <img  class="lazy" src="https://static.tossdown.com/images/d090eadc-3e7d-4d7d-ae58-501f5e9e199e.jpg"  />
   </a>
    </figure>
   <div class="product_category_figure_tags">
     <a>
     <span>  
      Donuts
    </span>    
    </a>
    </div>
   </div>
   </li>
   <li>
     <div class="product_category_figure_box">
     <figure>
     <a >
     <img  class="lazy" src="https://static.tossdown.com/images/cb32778a-bb8b-4c2b-8266-90332c2916f3.jpg"  />
     </a>
      </figure>
     <div class="product_category_figure_tags">
       <a>
       <span>  
         Brownies
      </span>    
      </a>
      </div>
     </div>
     </li>
     <li>
       <div class="product_category_figure_box">
       <figure>
       <a >
       <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
       </a>
        </figure>
       <div class="product_category_figure_tags">
         <a>
         <span>  
           Custom Cakes
        </span>    
        </a>
        </div>
       </div>
       </li>

</ul>

</div>
</div>
</div>
</section>
<script>
     $(document).ready(function () {
       $(".product_category_slider").slick({
         slidesToShow:4,
         slidesToScroll: 1,
         autoplay: false,
         autoplaySpeed: 2000,
         dots: false,
        arrows: true,
appendArrows: $('.category_slider_arrow_box'),
nextArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
prevArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
         responsive: [
           {
             breakpoint: 1024,
             settings: {
               slidesToShow:3,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 1500,
               dots: false,
               arrows: true,
             },
           },
           {
             breakpoint: 767,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 1500,
                    dots: false,
                 arrows: true,
             },
           },
           {
             breakpoint: 540,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 1500,
                   dots: false,
               arrows: true,
             },
           },
           {
             breakpoint: 400,
             settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               autoplay: false,
             autoplaySpeed: 1500,
                    dots: false,
                arrows: true,
             },
           },
         ],
       });
     });


</script>

`
    },
    {
        id:"categorysection1",
        name:"CategorySection Two",
        height:"500px",
        head:`
        <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        /> 
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
        
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
    
      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
      <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
      
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
      
    </head>
    
        `,
        code:`

        <style>
        @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
        </style>
        <style>
        * {
         -web-kit-box-sizing: border-box;
         -moz-box-sizing: border-box;
         box-sizing: border-box;
         padding: 0px;
         margin: 0px;
       }
       html {
         scroll-behavior: smooth;
       }
       body {
         float: left;
         margin: 0px;
         width: 100%;
         background:#fff;
       }
        .product_category_section {
           display: block;
           width: 100%;
           padding:0px 15px 0px 15px;
           margin-bottom: 70px;
           margin-top: 40px;
           float: left;
         }
         .custom_container {
           max-width: 1140px;
           margin: 0px auto;
         }
        
      
    
       .product_category_main {
    float:left;
    width:100%;
    margin:0px -20px;
    }
    .custom_sliding_box {
    float:left;
    width:25%;
    padding:30px 20px;
    display: flex;
       flex-direction: column;
       justify-content: center;
    }
    
    .custom_sliding_box small {
     font-family: 'Poppins', sans-serif;
       font-style: normal;
       font-weight: normal;
       font-size: 14px;
       line-height: 22px;
       text-transform: uppercase;
       color: #E7AFBA;
    }
    .custom_sliding_box  h5 {
       font-family: Prata;
       font-style: normal;
       font-weight: normal;
       font-size: 44px;
       line-height: 60px;
       color: #514533;
       padding-bottom: 8px;
       margin: 0px;
    }
    .custom_sliding_box p {
     font-family: 'Poppins', sans-serif;
       font-style: normal;
       font-weight: normal;
       font-size: 14px;
       line-height: 22px;
       color: rgba(0, 0, 0, 0.7);
       padding-bottom: 25px;
       margin: 0px;
    }
    .category_slider_arrow_box {
        display: flex;
            justify-content: space-between;
            height: 100%;
            align-items: center;
            position: absolute;
            top: 0px;
            left: 0px;
         
            width: 100%;
        }
        .category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
         background: #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        z-index:99;
        }
        .category_slider_arrow_box  a.category_pre_arrow.slick-arrow i{
        color:#000;
        }
    .category_slider_arrow_box  a.category_pre_arrow.slick-arrow:hover{
       background:#F5F5F5;
    }
        
         .product_category_list {
           float: left;
           width: 75%;
           padding: 0px 20px;
           position: relative;
         }
         .product_category_list ul {
           margin: 0px -10px;
           padding: 0px;
         }
         .product_category_list ul li {
           list-style: none;
           width: 25%;
           padding: 15px 10px 15px 10px;
           float: left;
             margin: 0px !important;
         }
    
         .product_category_figure_box {
           width: 100%;
           display: flex;
           flex-direction: column;
           background: #ffffff;
           justify-content: space-between;
           box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
         }
         .product_category_figure_box figure {
           margin-bottom: 0px;
         }
         .product_category_figure_box figure a {
           width: 100%;
           height: 100%;
           display: block;
         }
         .product_category_figure_box figure a img {
       width:100%;
       height:250px;
       object-fit: cover;
         }
         .product_category_figure_tags {
           display: flex;
           width: 100%;
           align-items: center;
           justify-content: center;
           position: relative;
           height: 100%;
         }
         .product_category_figure_tags a {
           width: 100%;
           padding: 15px 10px 15px 10px;
           display: flex;
          justify-content: center;
           align-items: center;
           text-decoration: none;
           height: 100%;
         }
         .product_category_figure_tags a span {
           font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #514533;
         }
         .product_category_figure_tags a i {
           font-style: normal;
           font-weight: 500;
           font-size: 18px;
           line-height: 24px;
           color: #212121;
           padding-left: 10px;
         }
     @media (max-width: 1024px) {
    .product_category_main {
       display: flex;
       flex-direction: column;
       justify-content: center;
       width: 100%;
       margin: 0px 0px;
    
    }
    .custom_sliding_box {
       margin-bottom: 30px;
       padding: 0px;
       width: 100%;
    }
    .product_category_list {
    width:100%;
    padding:0px;
    }
    
    }
         @media (max-width: 540px) {
           .product_category_figure_tags a {
             flex-direction: column;
             align-items: center;
             justify-content: center;
             width: 100%;
           }
    
           .product_category_figure_tags a i {
             display: none;
           }
         }
    
       </style>
    <section class="product_category_section">
    <div class="custom_container">
   
    <div class="product_category_main">
    <div class="custom_sliding_box">
     <small>Shop collection</small>
    <h5>Categories</h5>
    <p>We offer now a great range of different flavored bite size different flavors in categories.</p>
   
    </div>
    <div class="product_category_list">
    <div class="category_slider_arrow_box"></div>
    <!-- <ul class="product_category_slider">
     <?php
               foreach($featured_category_section->result as $valueCategory) {
                   $regex = '#[^a-z0-9_]#i';
                   $val = preg_replace($regex, '', $valueCategory->category_name);
                   $rel = strtolower($val);
                   $urlCategory = "menu/".$rel;
    $cdnHeight = '212' ;
    $cdnWidth = '212' ;
    $large_image = $valueCategory->image;
           ?>
    <li>
    <div class="product_category_figure_box">
    <figure>
     <?php
                       echo '<a href="'.$urlCategory.'">';
                       echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                       echo '</a>';
                   ?>
                 </figure>
    <div class="product_category_figure_tags"><?php
                       echo '<a href="'.$urlCategory.'">';
                   echo '<span>';     
                  echo $valueCategory->category_name;    
                     echo '</span>';     
                   
                       echo '</a>';
                   ?></div>
    </div>
    </li>
    <?php }?>
    </ul> -->
    
    
    <ul class="product_category_slider">
    
    <li>
    <div class="product_category_figure_box">
    <figure>
    <a >
    <img  class="lazy" src="https://static.tossdown.com/images/a2407daa-df31-4b71-98fe-d78663e7c1d4.jpg"  />
    </a>
    </figure>
    <div class="product_category_figure_tags">
     <a>
     <span>  
      Custom Cakes
    </span>    
    </a>
    </div>
    </div>
    </li>
    <li>
     <div class="product_category_figure_box">
     <figure>
     <a >
     <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
     </a>
      </figure>
     <div class="product_category_figure_tags">
       <a>
       <span>  
       Cup Cakes
      </span>    
      </a>
      </div>
     </div>
     </li>
     <li>
       <div class="product_category_figure_box">
       <figure>
       <a >
       <img  class="lazy" src="https://static.tossdown.com/images/d090eadc-3e7d-4d7d-ae58-501f5e9e199e.jpg"  />
       </a>
        </figure>
       <div class="product_category_figure_tags">
         <a>
         <span>  
          Donuts
        </span>    
        </a>
        </div>
       </div>
       </li>
       <li>
         <div class="product_category_figure_box">
         <figure>
         <a >
         <img  class="lazy" src="https://static.tossdown.com/images/cb32778a-bb8b-4c2b-8266-90332c2916f3.jpg"  />
         </a>
          </figure>
         <div class="product_category_figure_tags">
           <a>
           <span>  
             Brownies
          </span>    
          </a>
          </div>
         </div>
         </li>
         <li>
           <div class="product_category_figure_box">
           <figure>
           <a >
           <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
           </a>
            </figure>
           <div class="product_category_figure_tags">
             <a>
             <span>  
               Custom Cakes
            </span>    
            </a>
            </div>
           </div>
           </li>
    
    </ul>
    
    </div>
    </div>
    </div>
    </section>
    <script>
         $(document).ready(function () {
           $(".product_category_slider").slick({
             slidesToShow:4,
             slidesToScroll: 1,
             autoplay: false,
             autoplaySpeed: 2000,
             dots: false,
            arrows: true,
    appendArrows: $('.category_slider_arrow_box'),
    nextArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
    prevArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
             responsive: [
               {
                 breakpoint: 1024,
                 settings: {
                   slidesToShow:3,
                   slidesToScroll: 1,
                   autoplay: false,
                   autoplaySpeed: 1500,
                   dots: false,
                   arrows: true,
                 },
               },
               {
                 breakpoint: 767,
                 settings: {
                   slidesToShow: 2,
                   slidesToScroll: 1,
                   autoplay: false,
                   autoplaySpeed: 1500,
                        dots: false,
                     arrows: true,
                 },
               },
               {
                 breakpoint: 540,
                 settings: {
                   slidesToShow: 2,
                   slidesToScroll: 1,
                   autoplay: false,
                   autoplaySpeed: 1500,
                       dots: false,
                   arrows: true,
                 },
               },
               {
                 breakpoint: 400,
                 settings: {
                   slidesToShow: 1,
                   slidesToScroll: 1,
                   autoplay: false,
                 autoplaySpeed: 1500,
                        dots: false,
                    arrows: true,
                 },
               },
             ],
           });
         });
    
    
    </script>

        `,
        },
        {
            id:"categorysection2",
            name:"CategorySection Three",
            height:"550px",
            head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>

    `,
           
            code:`
           
          
            <style>
            @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
            </style>
            <style>
            * {
             -web-kit-box-sizing: border-box;
             -moz-box-sizing: border-box;
             box-sizing: border-box;
             padding: 0px;
             margin: 0px;
           }
           html {
             scroll-behavior: smooth;
           }
           body {
             float: left;
             margin: 0px;
             width: 100%;
             background:#fff;
           }
            .product_category_section {
               display: block;
               width: 100%;
               padding:0px 15px 0px 15px;
               margin-bottom: 70px;
               margin-top: 40px;
               float: left;
             }
             .custom_container {
               max-width: 1140px;
               margin: 0px auto;
             }
             .product_category_heading {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: center;
              padding-bottom: 17px;
              text-align: center;
              flex-direction: column;
          }
          .product_category_heading h3 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 35px;
          color: #2D2A38;
              margin-bottom: 0px;
              text-transform: capitalize;
              position: relative;
          }
           .product_category_main {
        float:left;
        width:100%;
      
        }
       
        .category_slider_arrow_box {
            display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: center;
                position: absolute;
                top: 0px;
                left: 0px;
             
                width: 100%;
            }
            .category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
             background: #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
            border-radius: 2px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            z-index:99;
            }
            .category_slider_arrow_box  a.category_pre_arrow.slick-arrow i{
            color:#000;
            }
        .category_slider_arrow_box  a.category_pre_arrow.slick-arrow:hover{
           background:#F5F5F5;
        }
            
             .product_category_list {
               float: left;
               width: 100%;
               padding: 0px 20px;
               position: relative;
             }
             .product_category_list ul {
               margin: 0px -10px;
               padding: 0px;
             }
             .product_category_list ul li {
               list-style: none;
               width: 25%;
               padding: 15px 10px 15px 10px;
               float: left;
                 margin: 0px !important;
             }
        
             .product_category_figure_box {
               width: 100%;
               display: flex;
               flex-direction: column;
               background: #ffffff;
               justify-content: space-between;
               box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
             }
             .product_category_figure_box figure {
               margin-bottom: 0px;
             }
             .product_category_figure_box figure a {
               width: 100%;
               height: 100%;
               display: block;
             }
             .product_category_figure_box figure a img {
           width:100%;
           height:250px;
           object-fit: cover;
             }
             .product_category_figure_tags {
               display: flex;
               width: 100%;
               align-items: center;
               justify-content: center;
               position: relative;
               height: 100%;
             }
             .product_category_figure_tags a {
               width: 100%;
               padding: 15px 10px 15px 10px;
               display: flex;
              justify-content: center;
               align-items: center;
               text-decoration: none;
               height: 100%;
             }
             .product_category_figure_tags a span {
               font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-transform: capitalize;
        color: #514533;
             }
             .product_category_figure_tags a i {
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 24px;
               color: #212121;
               padding-left: 10px;
             }
         @media (max-width: 1024px) {
        .product_category_main {
           display: flex;
           flex-direction: column;
           justify-content: center;
           width: 100%;
           margin: 0px 0px;
        
        }
      
        .product_category_list {
        width:100%;
        padding:0px;
        }
        
        }
             @media (max-width: 540px) {
               .product_category_figure_tags a {
                 flex-direction: column;
                 align-items: center;
                 justify-content: center;
                 width: 100%;
               }
        
               .product_category_figure_tags a i {
                 display: none;
               }
             }
        
           </style>
        <section class="product_category_section">
        <div class="custom_container">
        <div class="product_category_heading">
        <h3>Shop By Category</h3>
        </div>
        <div class="product_category_main">
        <div class="product_category_list">
        <div class="category_slider_arrow_box"></div>
        <!-- <ul class="product_category_slider">
         <?php
                   foreach($featured_category_section->result as $valueCategory) {
                       $regex = '#[^a-z0-9_]#i';
                       $val = preg_replace($regex, '', $valueCategory->category_name);
                       $rel = strtolower($val);
                       $urlCategory = "menu/".$rel;
        $cdnHeight = '212' ;
        $cdnWidth = '212' ;
        $large_image = $valueCategory->image;
               ?>
        <li>
        <div class="product_category_figure_box">
        <figure>
         <?php
                           echo '<a href="'.$urlCategory.'">';
                           echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                           echo '</a>';
                       ?>
                     </figure>
        <div class="product_category_figure_tags"><?php
                           echo '<a href="'.$urlCategory.'">';
                       echo '<span>';     
                      echo $valueCategory->category_name;    
                         echo '</span>';     
                       
                           echo '</a>';
                       ?></div>
        </div>
        </li>
        <?php }?>
        </ul> -->
        
        
        <ul class="product_category_slider">
        
        <li>
        <div class="product_category_figure_box">
        <figure>
        <a >
        <img  class="lazy" src="https://static.tossdown.com/images/a2407daa-df31-4b71-98fe-d78663e7c1d4.jpg"  />
        </a>
        </figure>
        <div class="product_category_figure_tags">
         <a>
         <span>  
          Custom Cakes
        </span>    
        </a>
        </div>
        </div>
        </li>
        <li>
         <div class="product_category_figure_box">
         <figure>
         <a >
         <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
         </a>
          </figure>
         <div class="product_category_figure_tags">
           <a>
           <span>  
           Cup Cakes
          </span>    
          </a>
          </div>
         </div>
         </li>
         <li>
           <div class="product_category_figure_box">
           <figure>
           <a >
           <img  class="lazy" src="https://static.tossdown.com/images/d090eadc-3e7d-4d7d-ae58-501f5e9e199e.jpg"  />
           </a>
            </figure>
           <div class="product_category_figure_tags">
             <a>
             <span>  
              Donuts
            </span>    
            </a>
            </div>
           </div>
           </li>
           <li>
             <div class="product_category_figure_box">
             <figure>
             <a >
             <img  class="lazy" src="https://static.tossdown.com/images/cb32778a-bb8b-4c2b-8266-90332c2916f3.jpg"  />
             </a>
              </figure>
             <div class="product_category_figure_tags">
               <a>
               <span>  
                 Brownies
              </span>    
              </a>
              </div>
             </div>
             </li>
             <li>
               <div class="product_category_figure_box">
               <figure>
               <a >
               <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
               </a>
                </figure>
               <div class="product_category_figure_tags">
                 <a>
                 <span>  
                   Custom Cakes
                </span>    
                </a>
                </div>
               </div>
               </li>
        
        </ul>
        
        </div>
        </div>
        </div>
        </section>
        <script>
             $(document).ready(function () {
               $(".product_category_slider").slick({
                 slidesToShow:4,
                 slidesToScroll: 1,
                 autoplay: false,
                 autoplaySpeed: 2000,
                 dots: false,
                arrows: true,
        appendArrows: $('.category_slider_arrow_box'),
        nextArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
        prevArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
                 responsive: [
                   {
                     breakpoint: 1024,
                     settings: {
                       slidesToShow:3,
                       slidesToScroll: 1,
                       autoplay: false,
                       autoplaySpeed: 1500,
                       dots: false,
                       arrows: true,
                     },
                   },
                   {
                     breakpoint: 767,
                     settings: {
                       slidesToShow: 2,
                       slidesToScroll: 1,
                       autoplay: false,
                       autoplaySpeed: 1500,
                            dots: false,
                         arrows: true,
                     },
                   },
                   {
                     breakpoint: 540,
                     settings: {
                       slidesToShow: 2,
                       slidesToScroll: 1,
                       autoplay: false,
                       autoplaySpeed: 1500,
                           dots: false,
                       arrows: true,
                     },
                   },
                   {
                     breakpoint: 400,
                     settings: {
                       slidesToShow: 1,
                       slidesToScroll: 1,
                       autoplay: false,
                     autoplaySpeed: 1500,
                            dots: false,
                        arrows: true,
                     },
                   },
                 ],
               });
             });
        
        
        </script>
            `,
            }, 
            
            
            {
              id:"categorysection3",
              name:"CategorySection Four",
              height:"550px",
              head:`
              <head>
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              /> 
              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
              <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
              
              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
          
            <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
            <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
            
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
            
          </head>
          
              `,


              code:`
             
  
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
    </style>
    <style>
     * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      margin: 0px;
      width: 100%;
      background-color: #fff;
    }
     .product_category_section {
        display: block;
        width: 100%;
        padding:0px 15px 0px 15px;
        margin-bottom: 70px;
        margin-top: 30px;
        float: left;
      }
      .custom_container {
        max-width: 1140px;
        margin: 0px auto;
      }
      .product_category_heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 17px;
    text-align: center;
    flex-direction: column;
}
.product_category_heading h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 35px;
color: #2D2A38;
    margin-bottom: 0px;
    text-transform: capitalize;
    position: relative;
}

    .product_category_main {
 float:left;
width:100%;
}

.category_slider_arrow_box {
display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
 
    width: 100%;
}
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
z-index:99;
}
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow i{
color:#000;
}

     
      .product_category_list {
        float: left;
        width:100%;
        padding: 0px 50px;
        position: relative;
      }
      .product_category_list ul {
        margin: 0px -15px;
        padding: 0px;
      }
      .product_category_list ul li {
        list-style: none;
        width: 25%;
        padding: 15px 15px 15px 15px;
        float: left;
          margin: 0px !important;
      }

      .product_category_figure_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .product_category_figure_box figure {
        margin-bottom: 0px;
      }
      .product_category_figure_box figure a {
        width: 100%;
        height: 100%;
        display: block;
      }
      .product_category_figure_box figure a img {
    width:100%;
    height:300px;
    object-fit: cover;
      }
      .product_category_figure_tags {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
      }
      .product_category_figure_tags a {
        width: 100%;
        padding: 15px 10px 15px 10px;
        display: flex;
       justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        height: 100%;
      }
      .product_category_figure_tags a span {
        font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
text-transform: capitalize;
color: #514533;
      }
      .product_category_figure_tags a i {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    line-height: 22px;
    color: #514533;
        padding-left: 10px;
      }
  @media (max-width: 1024px) {
.product_category_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0px 0px;

}
.product_category_list {
width:100%;
padding:0px;
}
.product_category_list ul {
  margin: 0px -10px;
}
.product_category_list ul li {

  padding: 15px 10px 15px 10px;

}
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
 background: #FFFFFF;
box-sizing: border-box;
box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
border-radius: 2px;
}


}
      @media (max-width: 540px) {
        .product_category_figure_tags a {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .product_category_figure_tags a i {
          display: none;
        }
      }
 
    </style>
<section class="product_category_section">
<div class="custom_container">
  <div class="product_category_heading">
    <h3>Shop By Category</h3>
    </div>
<div class="product_category_main">
<div class="product_category_list">
  <div class="category_slider_arrow_box"></div>
<!-- <ul class="product_category_slider">
  <?php
            foreach($featured_category_section->result as $valueCategory) {
                $regex = '#[^a-z0-9_]#i';
                $val = preg_replace($regex, '', $valueCategory->category_name);
                $rel = strtolower($val);
                $urlCategory = "menu/".$rel;
$cdnHeight = '212' ;
$cdnWidth = '212' ;
$large_image = $valueCategory->image;
        ?>
<li>
<div class="product_category_figure_box">
<figure>
  <?php
                    echo '<a href="'.$urlCategory.'">';
                    echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                    echo '</a>';
                ?>
              </figure>
<div class="product_category_figure_tags"><?php
                    echo '<a href="'.$urlCategory.'">';
                echo '<span>';     
               echo $valueCategory->category_name;    
                  echo '</span>';     
                 echo ' <i class="far fa-long-arrow-right">';  echo '</i> ';   
                    echo '</a>';
                ?></div>
</div>
</li>
<?php }?>
</ul> -->


<ul class="product_category_slider">
 
<li>
<div class="product_category_figure_box">
<figure>
<a >
<img  class="lazy" src="https://static.tossdown.com/images/a2407daa-df31-4b71-98fe-d78663e7c1d4.jpg"  />
</a>
 </figure>
<div class="product_category_figure_tags">
  <a>
  <span>  
   Custom Cakes
 </span> 
 <i class="far fa-long-arrow-right"></i>   
 </a>
 </div>
</div>
</li>
<li>
  <div class="product_category_figure_box">
  <figure>
  <a >
  <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
  </a>
   </figure>
  <div class="product_category_figure_tags">
    <a>
    <span>  
    Cup Cakes
   </span>   
   <i class="far fa-long-arrow-right"></i> 
   </a>
   </div>
  </div>
  </li>
  <li>
    <div class="product_category_figure_box">
    <figure>
    <a >
    <img  class="lazy" src="https://static.tossdown.com/images/d090eadc-3e7d-4d7d-ae58-501f5e9e199e.jpg"  />
    </a>
     </figure>
    <div class="product_category_figure_tags">
      <a>
      <span>  
       Donuts
     </span>  
     <i class="far fa-long-arrow-right"></i>  
     </a>
     </div>
    </div>
    </li>
    <li>
      <div class="product_category_figure_box">
      <figure>
      <a >
      <img  class="lazy" src="https://static.tossdown.com/images/cb32778a-bb8b-4c2b-8266-90332c2916f3.jpg"  />
      </a>
       </figure>
      <div class="product_category_figure_tags">
        <a>
        <span>  
          Brownies
       </span>    
       <i class="far fa-long-arrow-right"></i>
       </a>
       </div>
      </div>
      </li>
      <li>
        <div class="product_category_figure_box">
        <figure>
        <a >
        <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
        </a>
         </figure>
        <div class="product_category_figure_tags">
          <a>
          <span>  
            Custom Cakes
         </span>    
         <i class="far fa-long-arrow-right"></i>
         </a>
         </div>
        </div>
        </li>

</ul>

</div>
</div>
</div>
</section>
<script>
      $(document).ready(function () {
        $(".product_category_slider").slick({
          slidesToShow:3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 2000,
          dots: false,
         arrows: true,
appendArrows: $('.category_slider_arrow_box'),
nextArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
prevArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow:3,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 1500,
                dots: false,
                arrows: true,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 1500,
                     dots: false,
                  arrows: true,
              },
            },
            {
              breakpoint: 540,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 1500,
                    dots: false,
                arrows: true,
              },
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
              autoplaySpeed: 1500,
                     dots: false,
                 arrows: true,
              },
            },
          ],
        });
      });


 </script>
              `,
              }, 
    
              {
                id:"categorysection4",
              name:"CategorySection Five",
              height:"550px",
              
              head:`
              <head>
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              /> 
              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
              <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
              
              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
          
            <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
            <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
            
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
            
          </head>
          
              `,
              
              code:` 
                
              
                <style>
                @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
                </style>
                <style>
     * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      margin: 0px;
      width: 100%;
      background-color: #fff;
    }
     .product_category_section {
        display: block;
        width: 100%;
        padding:0px 15px 0px 15px;
        margin-bottom: 70px;
        margin-top: 30px;
        float: left;
      }
      .custom_container {
        max-width: 1140px;
        margin: 0px auto;
      }
      .product_category_heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 17px;
    text-align: center;
    flex-direction: column;
}
.product_category_heading h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 35px;
color: #2D2A38;
    margin-bottom: 0px;
    text-transform: capitalize;
    position: relative;
}

    .product_category_main {
 float:left;
width:100%;
}

.category_slider_arrow_box {
display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
 
    width: 100%;
}
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
z-index:99;
}
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow i{
color:#000;
}

     
      .product_category_list {
        float: left;
        width:100%;
        padding: 0px 50px;
        position: relative;
      }
      .product_category_list ul {
        margin: 0px -15px;
        padding: 0px;
      }
      .product_category_list ul li {
        list-style: none;
        width: 25%;
        padding: 15px 15px 15px 15px;
        float: left;
          margin: 0px !important;
      }

      .product_category_figure_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: 0.5s ease-in-out;
        border-radius: 16px;
      }
      .product_category_figure_box:hover{
        background: #FFFFFF;
        box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.08);
        transition: 0.5s ease-in-out;
        border-radius: 16px;
      }
      .product_category_figure_box figure {
        margin-bottom: 0px;
      }
      .product_category_figure_box figure a {
        width: 100%;
        height: 100%;
        display: block;
      }
      .product_category_figure_box figure a img {
    width:100%;
    height:300px;
    object-fit: cover;
    border-radius: 16px;
      }

      .product_category_figure_tags {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
      }

      .product_category_figure_tags a {
        width: 100%;
        padding: 15px 10px 15px 10px;
        display: flex;
       justify-content: space-between;
        align-items: center;
        text-decoration: none;
        height: 100%;
      }
      .product_category_figure_tags a span {
        font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
text-transform: capitalize;
color: #514533;
      }
      .product_category_figure_tags a i {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    line-height: 22px;
    color: #514533;
        padding-left: 10px;
      }
  @media (max-width: 1024px) {
.product_category_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0px 0px;

}
.product_category_list {
width:100%;
padding:0px;
}
.product_category_list ul {
        margin: 0px -10px;
      }
      .product_category_list ul li {
      
        padding: 15px 10px 15px 10px;
      
      }
.category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
 background: #FFFFFF;
box-sizing: border-box;
box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
border-radius: 2px;
}


}
      @media (max-width: 540px) {
        .product_category_figure_tags a {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .product_category_figure_tags a i {
          display: none;
        }
      }
 
    </style>
            <section class="product_category_section">
            <div class="custom_container">
              <div class="product_category_heading">
                <h3>Shop By Category</h3>
                </div>
            <div class="product_category_main">
            <div class="product_category_list">
              <div class="category_slider_arrow_box"></div>
            <!-- <ul class="product_category_slider">
              <?php
                        foreach($featured_category_section->result as $valueCategory) {
                            $regex = '#[^a-z0-9_]#i';
                            $val = preg_replace($regex, '', $valueCategory->category_name);
                            $rel = strtolower($val);
                            $urlCategory = "menu/".$rel;
            $cdnHeight = '212' ;
            $cdnWidth = '212' ;
            $large_image = $valueCategory->image;
                    ?>
            <li>
            <div class="product_category_figure_box">
            <figure>
              <?php
                                echo '<a href="'.$urlCategory.'">';
                                echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                                echo '</a>';
                            ?>
                          </figure>
            <div class="product_category_figure_tags"><?php
                                echo '<a href="'.$urlCategory.'">';
                            echo '<span>';     
                           echo $valueCategory->category_name;    
                              echo '</span>';     
                             echo ' <i class="far fa-long-arrow-right">';  echo '</i> ';   
                                echo '</a>';
                            ?></div>
            </div>
            </li>
            <?php }?>
            </ul> -->
            
            
            <ul class="product_category_slider">
             
            <li>
            <div class="product_category_figure_box">
            <figure>
            <a >
            <img  class="lazy" src="https://static.tossdown.com/images/a2407daa-df31-4b71-98fe-d78663e7c1d4.jpg"  />
            </a>
             </figure>
            <div class="product_category_figure_tags">
              <a>
              <span>  
               Custom Cakes
             </span> 
             <i class="far fa-long-arrow-right"></i>   
             </a>
             </div>
            </div>
            </li>
            <li>
              <div class="product_category_figure_box">
              <figure>
              <a >
              <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
              </a>
               </figure>
              <div class="product_category_figure_tags">
                <a>
                <span>  
                Cup Cakes
               </span>   
               <i class="far fa-long-arrow-right"></i> 
               </a>
               </div>
              </div>
              </li>
              <li>
                <div class="product_category_figure_box">
                <figure>
                <a >
                <img  class="lazy" src="https://static.tossdown.com/images/d090eadc-3e7d-4d7d-ae58-501f5e9e199e.jpg"  />
                </a>
                 </figure>
                <div class="product_category_figure_tags">
                  <a>
                  <span>  
                   Donuts
                 </span>  
                 <i class="far fa-long-arrow-right"></i>  
                 </a>
                 </div>
                </div>
                </li>
                <li>
                  <div class="product_category_figure_box">
                  <figure>
                  <a >
                  <img  class="lazy" src="https://static.tossdown.com/images/cb32778a-bb8b-4c2b-8266-90332c2916f3.jpg"  />
                  </a>
                   </figure>
                  <div class="product_category_figure_tags">
                    <a>
                    <span>  
                      Brownies
                   </span>    
                   <i class="far fa-long-arrow-right"></i>
                   </a>
                   </div>
                  </div>
                  </li>
                  <li>
                    <div class="product_category_figure_box">
                    <figure>
                    <a >
                    <img  class="lazy" src="https://static.tossdown.com/images/788720cf-9329-4c4c-a4b5-86b8f8ba059b.jpg"  />
                    </a>
                     </figure>
                    <div class="product_category_figure_tags">
                      <a>
                      <span>  
                        Custom Cakes
                     </span>    
                     <i class="far fa-long-arrow-right"></i>
                     </a>
                     </div>
                    </div>
                    </li>
            
            </ul>
            
            </div>
            </div>
            </div>
            </section>
            <script>
                  $(document).ready(function () {
                    $(".product_category_slider").slick({
                      slidesToShow:3,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 2000,
                      dots: false,
                     arrows: true,
            appendArrows: $('.category_slider_arrow_box'),
            nextArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
                      responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow:3,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 1500,
                            dots: false,
                            arrows: true,
                          },
                        },
                        {
                          breakpoint: 767,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 1500,
                                 dots: false,
                              arrows: true,
                          },
                        },
                        {
                          breakpoint: 540,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 1500,
                                dots: false,
                            arrows: true,
                          },
                        },
                        {
                          breakpoint: 400,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: false,
                          autoplaySpeed: 1500,
                                 dots: false,
                             arrows: true,
                          },
                        },
                      ],
                    });
                  });
            
            
             </script>
            
            `
                },


                {
                  id:"categorysection5",
                name:"CategorySection Six",
                height:"750px",
                head:`
                <head>
                <meta charset="utf-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                /> 
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
            
              <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
              <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
              
              <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
              <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
              
            </head>
            
                `,

                  code:` 
               
                
                <style>
                  @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap");
                </style>
                <style>
                  * {
                    -web-kit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 0px;
                    margin: 0px;
                  }
                  html {
                    scroll-behavior: smooth;
                  }
                  body {
                    float: left;
                    margin: 0px;
                    width: 100%;
                    background: #fff;
                  }
                  .product_category_section {
                    display: block;
                    width: 100%;
                    padding: 0px 15px 0px 15px;
                    margin-bottom: 70px;
                    margin-top: 40px;
                    float: left;
                  }
                  .custom_container {
                    max-width: 1140px;
                    margin: 0px auto;
                  }
                  .product_category_heading {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 17px;
                    text-align: center;
                    flex-direction: column;
                  }
                  .product_category_heading h3 {
                    font-family: "Poppins", sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 35px;
                    color: #2d2a38;
                    margin-bottom: 0px;
                    text-transform: capitalize;
                    position: relative;
                  }
                  .product_category_main {
                    float: left;
                    width: 100%;
                  }
                
                
                  .product_category_list {
                    float: left;
                    width: 100%;
                    position: relative;
                  }
                  .product_category_list ul {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    grid-template-areas: ". . . . .";
                    gap: 15px 15px;
                    width: 100%;
                    padding-bottom: 45px;
                  }
                  .product_category_list ul li {
                    list-style: none;
                    width: 100%;
                    margin: 0px !important;
                  }
                
                  .product_category_figure_box {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background: #F1F6F9;
                    border-radius: 4px;
                    height: 100%;
                  }
                  .product_category_figure_box figure {
                    margin-bottom: 0px;
                  }
                  .product_category_figure_box figure a {
                    width: 100%;
                    height: 100%;
                    display: block;
                   padding: 10px 10px;  
                }
                  .product_category_figure_box figure a img {
                    width: 100%;
                    height: 150px;
                    object-fit: contain;
                    
                  }
                  .product_category_figure_tags {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    height: 100%;
                  }
                  .product_category_figure_tags a {
                    width: 100%;
                    padding: 5px 10px 15px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    height: 100%;
                  }
                  .product_category_figure_tags a span {
                    font-family: "Poppins", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    text-transform: capitalize;
                    color: #514533;
                  }
                  .product_category_figure_tags a i {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #212121;
                    padding-left: 10px;
                  }
                  @media (max-width: 1024px) {
                  
                    .product_category_main {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      width: 100%;
                      margin: 0px 0px;
                    }
                    .product_category_list ul  {
                      grid-template-columns: 1fr 1fr 1fr 1fr;
                      grid-template-areas: ". . . .";
                  }
                
                  }
                  @media (max-width: 768px) {
                    .product_category_list ul  {
                      grid-template-columns: 1fr 1fr 1fr;
                      grid-template-areas: ". . .";
                  }
                  }
                  @media (max-width: 540px) {
                    .product_category_list ul  {
                      grid-template-columns: 1fr 1fr;
                      grid-template-areas: ". .";
                  }
                    .product_category_figure_tags a {
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                    }
                    .product_category_figure_tags a span{
                    
                      text-align: center;
                    }
                
                    .product_category_figure_tags a i {
                      display: none;
                    }
                  }
                </style>
                <section class="product_category_section">
                  <div class="custom_container">
                    <div class="product_category_heading">
                      <h3>Shop By Category</h3>
                    </div>
                    <div class="product_category_main">
                      <div class="product_category_list">
                        <!-- <ul class="product_category_slider">
                         <?php
                                   foreach($featured_category_section->result as $valueCategory) {
                                       $regex = '#[^a-z0-9_]#i';
                                       $val = preg_replace($regex, '', $valueCategory->category_name);
                                       $rel = strtolower($val);
                                       $urlCategory = "menu/".$rel;
                        $cdnHeight = '212' ;
                        $cdnWidth = '212' ;
                        $large_image = $valueCategory->image;
                               ?>
                        <li>
                        <div class="product_category_figure_box">
                        <figure>
                         <?php
                                           echo '<a href="'.$urlCategory.'">';
                                           echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                                           echo '</a>';
                                       ?>
                                     </figure>
                        <div class="product_category_figure_tags"><?php
                                           echo '<a href="'.$urlCategory.'">';
                                       echo '<span>';     
                                      echo $valueCategory->category_name;    
                                         echo '</span>';     
                                       
                                           echo '</a>';
                                       ?></div>
                        </div>
                        </li>
                        <?php }?>
                        </ul> -->
                
                        <ul class="product_category_slider">
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/500f005b-8e59-4b5e-92f4-4e9c701671ab.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Poultry </span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/3a09a1a5-72bb-4af7-84c4-d82269e30993.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Lamb & Goat </span>
                                </a>
                              </div>
                            </div>
                          </li>
                
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/500f005b-8e59-4b5e-92f4-4e9c701671ab.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Poultry </span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/3a09a1a5-72bb-4af7-84c4-d82269e30993.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Lamb & Goat </span>
                                </a>
                              </div>
                            </div>
                          </li>
                
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/500f005b-8e59-4b5e-92f4-4e9c701671ab.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Poultry </span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/3a09a1a5-72bb-4af7-84c4-d82269e30993.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Lamb & Goat </span>
                                </a>
                              </div>
                            </div>
                          </li>
                
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/500f005b-8e59-4b5e-92f4-4e9c701671ab.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Poultry </span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/3a09a1a5-72bb-4af7-84c4-d82269e30993.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Lamb & Goat </span>
                                </a>
                              </div>
                            </div>
                          </li>
                
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/500f005b-8e59-4b5e-92f4-4e9c701671ab.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Poultry </span>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="product_category_figure_box">
                              <figure>
                                <a>
                                  <img
                                    class="lazy"
                                    src="https://static.tossdown.com/images/170x190/3a09a1a5-72bb-4af7-84c4-d82269e30993.png"
                                  />
                                </a>
                              </figure>
                              <div class="product_category_figure_tags">
                                <a>
                                  <span> Lamb & Goat </span>
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                
      
            
              
              `
                  },

                  {
                    id:"categorysection6",
                  name:"CategorySection Seven",
                  height:"650px",
                  head:`
                  <head>
                  <meta charset="utf-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                  /> 
                  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                  <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                  
                  <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
              
                <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                
                <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                
              </head>
              
                  `, 
                  
                  code:` 
                  
                  
                  <style>
                  @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap");
                </style>
                <style>
                * {
                -web-kit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                padding: 0px;
                margin: 0px;
              }
              html {
                scroll-behavior: smooth;
              }
              body {
                float: left;
                margin: 0px;
                width: 100%;
              
              }
                .product_category_section {
                    display: block;
                    width: 100%;
                    padding:0px 15px 48px 15px;
                background: #F5F5F5;
                }
                .custom_container {
                    max-width: 1140px;
                    margin: 0px auto;
                }
                .product_category_heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding-bottom: 32px;
                    text-align: center;
                }
                .product_category_heading h3 {
                  font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 26px;
                line-height: 26px;
                color: #282726;
                    margin-bottom: 0px;
                }
                .product_category_heading a{
                 font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 13px;
                text-transform: uppercase;
                color: #282726;
                    display: flex;
                    align-items: center;
                }
                .product_category_heading a i{
                font-size: 13px;
                line-height: 13px;
                color: #282726;
                padding-left:7px;
                }
                .product_category_list {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    grid-template-areas: ". . . .";
                    width: 100%;
                   gap: 1px 1px;
                }
                .product_category_figure_box {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                justify-content: space-between;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 2px rgb(0 0 0 / 8%);
                  padding:32px 10px 29px 10px;
                    transition: 0.5s ease-in-out;
                }
                .product_category_figure_box:hover{
                    box-shadow: 0px 2px 15px rgb(0 166 79 / 36%);
                    background: #FFFFFF;
                    transform: scale(1.1);
                    transition: 0.5s ease-in-out;
                }
                .product_category_figure_box figure{
                    margin-bottom: 0px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding-bottom: 16px;
                    margin: 0px;
                }
                .product_category_figure_box figure a {
                    height: 48px;
                   width:48px;
                }
                .product_category_figure_box figure a img{
                height:100%;
                width:100%;
                }
                .product_category_figure_tags{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                }
                .product_category_figure_tags a{
                  font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 15px;
                color: #282726;
                    margin: 0px;
                    width: 100%;
                    padding: 0px 0px 0px 0px;
                }
                
              
                @media (max-width:1024px){
                .product_category_list {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-template-areas: ". . . .";
                }
                }
                @media ( max-width:768px){
                .product_category_list {
                grid-template-columns: 1fr 1fr 1fr;
                    grid-template-areas: ". . .";
                }
                }
                @media (max-width: 540px){
                .product_category_list {
                    grid-template-columns: 1fr 1fr;
                    grid-template-areas: ". .";
                }
                .product_category_figure_tags a {
                    font-size: 16px;
                    line-height: 20px;
                    color: #212121;
                }
                .product_category_heading {
                    flex-direction: column;
                }
                .product_category_heading h3 {
                    padding-bottom: 20px;
                }
                .product_category_figure_box {
                  padding:25px 10px 20px 10px
                }
              }
                @media (max-width: 450px){
            
                }
                
                </style>
                <section class="product_category_section">
                <div class="custom_container">
                <div class="product_category_heading">
                <h3>Product categories</h3>
                <a href="/shop">View All <i class="far fa-arrow-right"></i></a></div>
                
                <!-- <div class="product_category_list">
                
                 <?php
                            foreach($featured_category_section->result as $valueCategory) {
                                $regex = '#[^a-z0-9_]#i';
                                $val = preg_replace($regex, '', $valueCategory->category_name);
                                $rel = strtolower($val);
                                $urlCategory = "menu/".$rel.'-'.$valueCategory->category_id;
                
                $cdnHeight = '170' ;
                $cdnwidth = '190' ;
                  $large_image = $this->obw->convertDomainToCdnUrl2(DOMAIN_URL2, CDN_URL2, $cdnwidth , $cdnHeight, $valueCategory->image);
                //$large_image = $valueCategory->image;
                        ?>
                <div class="product_category_figure_box">
                <figure><?php
                                    echo '<a href="'.$urlCategory.'">';
                                    echo '<img class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                                    echo '</a>';
                                ?></figure>
                <div class="product_category_figure_tags"><?php
                                    echo '<a href="'.$urlCategory.'">';
                                    echo $valueCategory->category_name;           
                                    echo '</a>';
                                ?></div>
                </div>
                <?php }?> 
                
                </div> -->
            
            
                <div class="product_category_list"><div class="product_category_figure_box">
                <figure><a><img class="lazy" src="https://static.tossdown.com/images/170x190/578f4319-5eb1-42ef-b3bd-c3ae5495f907.png"></a></figure>
                <div class="product_category_figure_tags"><a>Containers And Bowls</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure><a ><img class="lazy" src="https://static.tossdown.com/images/170x190/97ac9fd5-fc69-4106-a067-2a197dd4efef.png" ></a></figure>
                <div class="product_category_figure_tags"><a >Cake Boxes</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure><a ><img class="lazy" src="https://static.tossdown.com/images/170x190/b0d3f962-7909-4015-9ffa-d230cc14064e.png"></a></figure>
                <div class="product_category_figure_tags"><a >Accessories Picks</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure><a ><img class="lazy" src="https://static.tossdown.com/images/170x190/1bc4b90e-0776-4696-8a4e-e71db74dbe01.png"></a></figure>
                <div class="product_category_figure_tags"><a >Plastic Cups</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure><a ><img class="lazy" src="https://static.tossdown.com/images/170x190/e587c89e-fa46-4735-864a-2c66cd55381a.png" ></a></figure>
                <div class="product_category_figure_tags"><a >Aluminium Foil Rolls</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure><a ><img class="lazy" src="https://static.tossdown.com/images/170x190/466b4dca-de24-4cf0-aa50-e18c28db25ed.png" ></a></figure>
                <div class="product_category_figure_tags"><a >Industrial Packaging Roll</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure><a ><img class="lazy" src="https://static.tossdown.com/images/170x190/546fd4b4-e8c1-4707-aa2b-16e343abfd19.png" ></a></figure>
                <div class="product_category_figure_tags"><a >Tissue Items</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure><a ><img class="lazy" src="https://static.tossdown.com/images/170x190/0233a578-0ca8-435c-9bf2-658193bd5ff2.png"></a></figure>
                <div class="product_category_figure_tags"><a >Paper Bag</a></div>
                </div>
                </div>
            
            
                </div>
                </section>
                
                
                `
                    },


                    {
                      id:"categorysection7",
                    name:"CategorySection Eight",
                    height:"550px",
                    head:`
                    <head>
                    <meta charset="utf-8" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                    /> 
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                    
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                
                  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                  
                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                  
                </head>
                
                    `,


                      code:` 
                    
                    
                    <style>
                    @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap");
                  </style>
                  <style>
                  * {
                       -web-kit-box-sizing: border-box;
                       -moz-box-sizing: border-box;
                       box-sizing: border-box;
                       padding: 0px;
                       margin: 0px;
                     }
                     html {
                       scroll-behavior: smooth;
                     }
                     body {
                       float: left;
                       margin: 0px;
                       width: 100%;
                       background-color: #fff;
                     }
                 .product_category_section {
                    display: block;
                    width: 100%;
                    padding:0px 15px 0px 15px;
                    margin-bottom: 70px;
                    margin-top: 30px;
                    float: left;
                  }
                  .custom_container {
                    max-width: 1140px;
                    margin: 0px auto;
                  }
                  .product_category_heading {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                padding-bottom: 17px;
                text-align: center;
                flex-direction: column;
               }
               .product_category_heading h3 {
               font-family: 'Poppins', sans-serif;
               font-style: normal;
               font-weight: 700;
               font-size: 28px;
               line-height: 35px;
               color: #2D2A38;
                margin-bottom: 0px;
                text-transform: capitalize;
                position: relative;
               }
               
                .product_category_main {
               float:left;
               width:100%;
               }
               
               .category_slider_arrow_box {
               display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: center;
                position: absolute;
                top: 0px;
                left: 0px;
               
                width: 100%;
               }
               .category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
               z-index:99;
               }
               .category_slider_arrow_box  a.category_pre_arrow.slick-arrow i{
               color:#000;
               }
               
                  .product_category_list {
                    float: left;
                    width:100%;
                    padding: 0px 50px;
                    position: relative;
                  }
                  .product_category_list ul {
                    margin: 0px -15px;
                    padding: 0px;
                  }
                  .product_category_list ul li {
                    list-style: none;
                    width: 25%;
                    padding: 15px 15px 15px 15px;
                    float: left;
                      margin: 0px !important;
                display: flex !important;
                justify-content: center;
                  }
               
                  .product_category_figure_box {
                    width: 100%;
                display: flex;
                justify-content: center;
                width: 150px;
               height: 150px;
                background: #fff;
                box-shadow: 0px 4px 14px rgb(0 0 0 / 8%);
                border-radius: 50%;
                align-items: center;
                border: 1px dashed rgba(21, 53, 49, 0.5);
                flex-direction: column;
                  }
               .single_product_category_figure_box{
               background: #faf8f3;
                margin-bottom: 0px;
                float: left;
                text-align: center;
                display: flex;
                justify-content: center;
                width: 135px;
               height: 135px;
                border-radius: 50%;
                align-items: center;
                flex-direction: column;
               }
                  .product_category_figure_box figure {
                    margin-bottom: 0px;
                width: 68px;
                height: 68px;
                  }
                  .product_category_figure_box figure img {
                    width: 100%;
                }
                  .product_category_figure_box figure a {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                  .product_category_figure_box figure a img {
                width:100%;
                height:100%;
               
                  }
                  .product_category_figure_tags {
                    width: 100%;
                overflow: hidden;
                text-overflow: ellipsis !important;
                display: block;
                white-space: nowrap;
                padding: 5px 15px 0px 15px;
                  }
               
                  .product_category_figure_tags span {
                    font-family: 'Poppins', sans-serif;
               font-style: normal;
               font-weight: normal;
               font-size: 16px;
               line-height: 22px;
               text-transform: capitalize;
               color: #514533;
                  }
               
               @media (max-width: 1024px) {
               .product_category_main {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: 0px 0px;
               
               }
               .product_category_list {
               width:100%;
               padding:0px 20px;
               }
               .product_category_list ul {
               margin: 0px -10px;
               }
               .product_category_list ul li {
               
               padding: 15px 10px 15px 10px;
               
               }
               .category_slider_arrow_box  a.category_pre_arrow.slick-arrow {
               background: #FFFFFF;
               box-sizing: border-box;
               box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
               border-radius: 2px;
               }
               
               
               }
                 
                </style>
               <section class="product_category_section">
               <div class="custom_container">
               <div class="product_category_heading">
               <h3>Shop By Category</h3>
               </div>
               <div class="product_category_main">
               <div class="product_category_list">
               <div class="category_slider_arrow_box"></div>
               <!-- <ul class="product_category_slider"><?php
                        foreach($featured_category_section->result as $valueCategory) {
                            $regex = '#[^a-z0-9_]#i';
                            $val = preg_replace($regex, '', $valueCategory->category_name);
                            $rel = strtolower($val);
                            $urlCategory = "menu/".$rel.'-'.$valueCategory->category_id;
               $cdnHeight = '150' ;
               //$large_image = $this->obw->convertDomainToCdnUrl(DOMAIN_URL, CDN_URL, $cdnHeight, $valueCategory->image);
               $large_image = $valueCategory->image;
                    ?>
               <li>
               <div class="product_category_figure_box"><?php
               echo  '<a href="'.$urlCategory.'" class="single_product_category_figure_box">'; ?>
               <figure><?php
                                echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                            ?></figure>
               <div class="product_category_figure_tags"><?php
                            echo '<span>';     
                           echo $valueCategory->category_name;    
                              echo '</span>';        
                            ?></div>
               <?php echo '</a>'; ?></div>
               </li>
               <?php }?></ul> -->
               
               
               <ul class="product_category_slider">
                 <li>
                   <div class="product_category_figure_box">
                     <a class="single_product_category_figure_box">
                      <figure>
                           <img
                             class="lazy"
                             src="https://static.tossdown.com/images/aa8ef584-363d-45af-8faa-b0fd3c82abfd.png"
                           />
                      </figure>
                      <div class="product_category_figure_tags">
                         <span> Beef </span>
                       </div>
                     </a>
                   </div>
                 </li>
                 <li>
                   <div class="product_category_figure_box">
                     <a class="single_product_category_figure_box">
                      <figure>
                           <img
                             class="lazy"
                             src="https://static.tossdown.com/images/aa8ef584-363d-45af-8faa-b0fd3c82abfd.png"
                           />
                      </figure>
                      <div class="product_category_figure_tags">
                         <span> Beef </span>
                       </div>
                     </a>
                   </div>
                 </li>
                 <li>
                   <div class="product_category_figure_box">
                     <a class="single_product_category_figure_box">
                      <figure>
                           <img
                             class="lazy"
                             src="https://static.tossdown.com/images/aa8ef584-363d-45af-8faa-b0fd3c82abfd.png"
                           />
                      </figure>
                      <div class="product_category_figure_tags">
                         <span> Beef </span>
                       </div>
                     </a>
                   </div>
                 </li>
                 <li>
                   <div class="product_category_figure_box">
                     <a class="single_product_category_figure_box">
                      <figure>
                           <img
                             class="lazy"
                             src="https://static.tossdown.com/images/aa8ef584-363d-45af-8faa-b0fd3c82abfd.png"
                           />
                      </figure>
                      <div class="product_category_figure_tags">
                         <span> Beef </span>
                       </div>
                     </a>
                   </div>
                 </li>
                 <li>
                   <div class="product_category_figure_box">
                     <a class="single_product_category_figure_box">
                      <figure>
                           <img
                             class="lazy"
                             src="https://static.tossdown.com/images/aa8ef584-363d-45af-8faa-b0fd3c82abfd.png"
                           />
                      </figure>
                      <div class="product_category_figure_tags">
                         <span> Beef </span>
                       </div>
                     </a>
                   </div>
                 </li>
                 <li>
                   <div class="product_category_figure_box">
                     <a class="single_product_category_figure_box">
                      <figure>
                           <img
                             class="lazy"
                             src="https://static.tossdown.com/images/aa8ef584-363d-45af-8faa-b0fd3c82abfd.png"
                           />
                      </figure>
                      <div class="product_category_figure_tags">
                         <span> Beef </span>
                       </div>
                     </a>
                   </div>
                 </li>
               </ul>
               
               </div>
               </div>
               </div>
               </section>
               <script>
                  $(document).ready(function () {
                    $(".product_category_slider").slick({
                      slidesToShow:5,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 2000,
                      dots: false,
                     arrows: true,
               appendArrows: $('.category_slider_arrow_box'),
               nextArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
               prevArrow: '<a class="category_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
                      responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow:4,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 1500,
                            dots: false,
                            arrows: true,
                          },
                        },
                        {
                          breakpoint: 767,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 1500,
                                 dots: false,
                              arrows: true,
                          },
                        },
                        {
                          breakpoint: 540,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 1500,
                                dots: false,
                            arrows: true,
                          },
                        },
                        {
                          breakpoint: 400,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: false,
                          autoplaySpeed: 1500,
                                 dots: false,
                             arrows: true,
                          },
                        },
                      ],
                    });
                  });
               
               
               </script>
                  
                  
                  `
                      },

                      {
                        id:"categorysection7",
                      name:"CategorySection Eight",
                      height:"550px",

                      head:`
                      <head>
                      <meta charset="utf-8" />
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, shrink-to-fit=no"
                      /> 
                      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                      
                      <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                  
                    <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                    <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                    
                    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                    
                  </head>
                  
                      `,
                        code:` 
                       
                      <style>
                      @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap");
                    </style>
                    <style>
                    * {
                                  -web-kit-box-sizing: border-box;
                                  -moz-box-sizing: border-box;
                                  box-sizing: border-box;
                                  padding: 0px;
                                  margin: 0px;
                                  font-family: "Poppins", sans-serif;
                                }
                                html {
                                  scroll-behavior: smooth;
                                }
                                body {
                                  float: left;
                                  width: 100%;
                                  margin: 0px;
                              background-color: #fff;
                                }
                .product_category_section {
                    display: block;
                    width: 100%;
                    padding:0px 15px 0px 15px;
                margin-bottom:55px;
                }
                .iqbal_custom_container {
                    max-width: 1140px;
                    margin: 0px auto;
                }
                .product_category_heading {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding-bottom: 34px;
                    text-align: center;
                }
                .product_category_heading h3 {
                   font-family: Playfair Display;
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 53px;
                color: #212121;
                text-align:center;
                    margin-bottom: 0px;
                    text-transform: capitalize;
                }
                .product_category_list {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    grid-template-areas: ". . . . .";
                    gap: 15px 15px;
                    width: 100%;
                padding-bottom:45px;
                }
                .product_category_figure_box {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                background: #FFFFFF;
                justify-content: space-between;
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
                align-items: center;
                }
                .product_category_figure_box figure{
                margin-bottom:0px;
                }
                .product_category_figure_tags{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                }
                .product_category_figure_tags a{
                    font-family: Playfair Display;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #212121;
                    margin: 0px;
                    text-transform: capitalize;
                    width: 100%;
                    padding: 15px 0px 15px 0px;
                }
                
                .product_category_shop_btn{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
                .product_category_shop_btn a{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
                width: 146px;
                height: 48px;
                display: flex;
                align-items: center;
                background: #0F994B;
                border-radius: 4px;
                justify-content: center;
                text-transform: capitalize;
                }
                @media(min-width:768px) and (max-width:1024px){
                .product_category_list {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-template-areas: ". . . .";
                }
                }
                @media ( max-width:767px){
                .product_category_list {
                grid-template-columns: 1fr 1fr 1fr;
                    grid-template-areas: ". . .";
                }
                }
                @media (max-width: 767px){
                .product_category_list {
                    grid-template-columns: 1fr 1fr;
                    grid-template-areas: ". .";
                }
                .product_category_figure_tags a {
                    font-size: 16px;
                    line-height: 20px;
                    color: #212121;
                    padding: 15px 5px 15px 5px;
                }
                }
                </style>
                <section class="product_category_section">
                <div class="iqbal_custom_container">
                <div class="product_category_heading">
                <h3>Product Categories</h3>
                </div>
                <!-- <div class="product_category_list">
                 <?php
                            foreach($featured_category_section->result as $valueCategory) {
                                $regex = '#[^a-z0-9_]#i';
                                $val = preg_replace($regex, '', $valueCategory->category_name);
                                $rel = strtolower($val);
                                $urlCategory = "menu/".$rel.'-'.$valueCategory->category_id;
                
                $cdnHeight = '170' ;
                $cdnwidth = '190' ;
                  $large_image = $this->obw->convertDomainToCdnUrl2(DOMAIN_URL2, CDN_URL2, $cdnwidth , $cdnHeight, $valueCategory->image);
                //$large_image = $valueCategory->image;
                        ?>
                <div class="product_category_figure_box">
                <figure><?php
                                    echo '<a href="'.$urlCategory.'">';
                                    echo '<img style="padding: 15px;" class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                                    echo '</a>';
                                ?></figure>
                <div class="product_category_figure_tags" style="border-bottom: 2px solid #97693E;"><?php
                                    echo '<a href="'.$urlCategory.'">';
                                    echo $valueCategory->category_name;           
                                    echo '</a>';
                                ?>
                                </div>
                </div>
                 <?php }?>
                </div> -->
                
                <div class="product_category_list">
                <div class="product_category_figure_box">
                <figure>
                <a><img src="https://images-beta.tossdown.com/images/170x190/b712174c-593b-43be-9a74-5e39950e530d.jpg"></a>
                </figure>
                <div class="product_category_figure_tags" style="border-bottom: 2px solid #97693E;"><a>Bbq</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure>
                <a><img src="https://images-beta.tossdown.com/images/170x190/b712174c-593b-43be-9a74-5e39950e530d.jpg"></a>
                </figure>
                <div class="product_category_figure_tags" style="border-bottom: 2px solid #97693E;"><a>Bbq</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure>
                <a><img src="https://images-beta.tossdown.com/images/170x190/b712174c-593b-43be-9a74-5e39950e530d.jpg"></a>
                </figure>
                <div class="product_category_figure_tags" style="border-bottom: 2px solid #97693E;"><a>Bbq</a></div>
                </div>
                <div class="product_category_figure_box">
                <figure>
                <a><img src="https://images-beta.tossdown.com/images/170x190/b712174c-593b-43be-9a74-5e39950e530d.jpg"></a>
                </figure>
                <div class="product_category_figure_tags" style="border-bottom: 2px solid #97693E;"><a>Bbq</a></div>
                </div>
                
                <div class="product_category_figure_box">
                <figure>
                <a><img src="https://images-beta.tossdown.com/images/170x190/b712174c-593b-43be-9a74-5e39950e530d.jpg"></a>
                </figure>
                <div class="product_category_figure_tags" style="border-bottom: 2px solid #97693E;"><a>Bbq</a></div>
                </div>
                
                <div class="product_category_figure_box">
                <figure>
                <a><img src="https://images-beta.tossdown.com/images/170x190/b712174c-593b-43be-9a74-5e39950e530d.jpg"></a>
                </figure>
                <div class="product_category_figure_tags" style="border-bottom: 2px solid #97693E;"><a>Bbq</a></div>
                </div>
                

                </div>
                
                </div>
                <div class="product_category_shop_btn"><a>Shop All</a></div>
                </section>
                    
                    
                    `
                        },
                        {
                          id:"categorysection8",
                        name:"CategorySection Nine",
                        height:"550px",
                        head:`
                        <head>
                        <meta charset="utf-8" />
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                        /> 
                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                        
                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                    
                      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                      <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                      
                      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                      
                    </head>
                    
                        `,
                        code:` 

                        <style>
                        @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap");
                      </style>
                      <style>
    * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
      .product_category_section {
        display: block;
        width: 100%;
        padding: 46px 15px 0px 15px;
margin-bottom: 64px;
float:left;
      }
      .custom_container {
        max-width: 1140px;
        margin: 0px auto;
      }
      .product_category_heading {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
padding-bottom:32px;
     text-align: center;
      }
      .product_category_heading h3 {
            text-align: center;
    margin-bottom: 0px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    text-transform: uppercase;
    color: #262B40;
        }
       
      .product_category_list ul {
        margin: 0px -10px;
        padding: 0px;
      }
      .product_category_list ul li {
        list-style: none;
        width: 25%;
        padding: 0px 6px 0px 6px;
        float: left;
          margin: 0px !important;
      }
      .product_category_figure_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       background: #FFFFFF;
    position: relative;
      }
      .product_category_figure_box figure {
        margin-bottom: 0px;
      }
      .product_category_figure_box figure a {
        width: 100%;
        height: 100%;
        display: block;
      }
      .product_category_figure_box figure a img {
    width:100%;
    height: 400px;
    object-fit: cover;

      }
 .product_category_figure_tags{
          display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    left: 0px;
      }
      .product_category_figure_tags a{
        background: #ffffff;
    width: 152px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #262B40;
        }
        .product_category_figure_tags a span{
            white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0px 10px;
    font-weight: 600 !important;
    font-family: 'Jost';
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #262B40;
        }
 @media (max-width: 1250px) {
.product_category_slider .slick-prev{
display:none !important;
}
.product_category_slider .slick-next {
display:none !important;
}
}
 @media (max-width: 767px) {
.product_category_list ul li {
    padding: 0px 10px 0px 10px;
}
}
 @media (max-width: 480px) {
        .product_category_heading h3 {
        line-height: 42px;
        }
}
      @media (max-width: 540px) {
.product_category_figure_tags {
    padding: 0px 15px;
}
        .product_category_figure_tags a {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        .product_category_figure_tags a i {
          display: none;
        }
.product_category_figure_box figure a img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
      }
    </style>
<section class="product_category_section">
<div class="custom_container">
<div class="product_category_heading">
<h3>Browse by Category</h3>
</div>
<div class="product_category_list">
<!-- <ul class="product_category_slider">
<?php
            foreach($featured_category_section->result as $valueCategory) {
                $regex = '#[^a-z0-9_]#i';
                $val = preg_replace($regex, '', $valueCategory->category_name);
                $rel = strtolower($val);
                $urlCategory = "menu/".$rel."-".$valueCategory->category_id;
$cdnHeight = '212' ;
$cdnWidth = '212' ;
$large_image = $valueCategory->image;
        ?>
<li>
<div class="product_category_figure_box">
<figure><?php
                    echo '<a href="'.$urlCategory.'">';
                    echo '<img  class="lazy" src="https://iqbalfoods.ca/assets/images/blank_image_1.svg" data-src="'.$large_image.'" />';
                    echo '</a>';
                ?></figure>
<div class="product_category_figure_tags"><?php
                    echo '<a href="'.$urlCategory.'" data-aos-delay="100" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">';
                echo '<span>';
               echo $valueCategory->category_name;
                  echo '</span>';
                    echo '</a>';
                ?></div>
</div>
</li>
<?php }?>
</ul> -->
<ul class="product_category_slider">
<li>
<div class="product_category_figure_box">
<figure>
<a><img src="https://static.tossdown.com/images/49173656-8b99-456f-8c11-b3779c1103e8.jpg"></a>
</figure>
<div class="product_category_figure_tags">
<a><span>Down Shirts</span></a>
</div>
</div>
</li>
<li>
<div class="product_category_figure_box">
<figure>
<a><img src="https://static.tossdown.com/images/49173656-8b99-456f-8c11-b3779c1103e8.jpg"></a>
</figure>
<div class="product_category_figure_tags">
<a><span>Down Shirts</span></a>
</div>
</div>
</li>
<li>
<div class="product_category_figure_box">
<figure>
<a><img src="https://static.tossdown.com/images/49173656-8b99-456f-8c11-b3779c1103e8.jpg"></a>
</figure>
<div class="product_category_figure_tags">
<a><span>Down Shirts</span></a>
</div>
</div>
</li>
<li>
<div class="product_category_figure_box">
<figure>
<a><img src="https://static.tossdown.com/images/49173656-8b99-456f-8c11-b3779c1103e8.jpg"></a>
</figure>
<div class="product_category_figure_tags">
<a><span>Down Shirts</span></a>
</div>
</div>
</li>
<li>
<div class="product_category_figure_box">
<figure>
<a><img src="https://static.tossdown.com/images/49173656-8b99-456f-8c11-b3779c1103e8.jpg"></a>
</figure>
<div class="product_category_figure_tags">
<a><span>Down Shirts</span></a>
</div>
</div>
</li>

</ul>
</div>
</div>
</section>
<script>
      $(document).ready(function () {
        $(".product_category_slider").slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 2000,
          dots: false,
          arrows: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: false,
        arrows: true,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                     dots: false,
      arrows: true,
              },
            },
            {
              breakpoint: 540,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                    dots: false,
          arrows: true,
              },
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                     dots: false,
           arrows: true,
              },
            },
          ],
        });
      });
    </script>
                      
                      `
                          },

                          {
                            id:"categorysection9",
                          name:"CategorySection ten",
                          height:"550px",
                          head:`
                          <head>
                          <meta charset="utf-8" />
                          <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1, shrink-to-fit=no"
                          /> 
                          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                          
                          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                      
                        <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                        <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                        
                        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                        
                      </head>
                      
                          `,  
                          
                          code:` 
                           
                          <style>
                          @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap");
                        </style>
                        <style>
                        *{
                            margin: 0;padding: 0;box-sizing: border-box;
                                }
                                .section-amwares-categories{
                                    width: 100%;
                                    display: block;
                                    background: #F0EBE5;
                                    padding: 50px 20px;
                margin-bottom:72px;
                
                                }
                                .amwares-categories-container{
                                    max-width: 1136px;
                                    margin: 0px auto;
                                }
                                .serperator-categories-section{
                                    display: grid;
                                    grid-template-columns: 1fr 1.2fr;
                                    gap: 80px;
                                }
                                .categories-section-left{
                                    display: flex;
                                align-items: center;
                            justify-content: center;                
                }
                
                            .categories-section-right-seperator2 {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 27px;
                            }
                .categories-section-right-seperator2 img{
                
                    width: 285px;
                height: 349px;
                 
                }
                .figure-upper{
                    position: relative;
                }
                .figure-cap{
                    position: absolute;
                    bottom: 30px;
                    left: 22px
                }
                .figure-cap a{
                    font-family: 'Raleway';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 18px;
                color: #0B474B;
                background-color: #fff;
                padding: 11px 48px!important;
                text-decoration: none;
                }
                .content-categories-section h2{
                    font-family: 'Raleway';
                font-style: normal;
                font-weight: 800!important;
                font-size: 34px;
                line-height: 40px;
                text-transform: capitalize;
                color: #0B474B;
                margin-bottom: 19px;
                
                }
                .content-categories-section p{
                    
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 26px;
                /* or 186% */
                
                
                color: #0B474B;
                margin-bottom: 46px;
                }
                .figure-cap.adjustment-for {
                    left: 12px;
                }
                .content-categories-section a{
                    font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                /* identical to box height */
                
                text-decoration-line: underline;
                
                color: #0B474B;
                position: relative;
                
                }
                .content-categories-section a::after{
                    content: '→';
                    position: absolute;
                    right: -50px;
                    font-size: 25px;
                }   
                @media (max-width:1111px) {
                
                .figure-cap.adjustment-for {
                    left: 9px;
                }
                .figure-cap a {
                 
                    padding: 10px 32px!important;
                  
                }
                
                }
                
                @media (max-width:992px) {
                
                .categories-section-right-seperator2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                    .serperator-categories-section {
                    display: grid;
                    grid-template-columns: 1fr ;
                  
                }
                .figure-cap a {
                    padding: 10px 57px!important;
                }
                
                .amwares-categories-container {
                    max-width: 600px;
                    margin: 0px auto;
                }
                .content-categories-section {
                    text-align: center;
                }
                }
                @media (max-width:650px) {
                
                
                    .categories-section-right-seperator2 {
                    display: grid!important;
                    grid-template-columns: 1fr;
                    gap: 27px;
                    max-width: 301px;
                    margin: auto;
                }
                }
                
                    </style>
                <div class="section-amwares-categories">
                <div class="amwares-categories-container">
                <div class="serperator-categories-section">
                <div class="categories-section-left">
                <div class="content-categories-section">
                <h2>Shop Categories</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <a>VIEW ALL CATEGORIES</a></div>
                </div>
                <div class="categories-section-right">
                <div class="categories-section-right-seperator2">
                <figure class="figure-upper"><img src="https://images-beta.tossdown.com/site/a8819954-cf91-498f-8010-630eb0cbc472.webp" alt="" />
                <div class="figure-cap"><a href="#">Spoons &amp; Turners</a></div>
                </figure>
                <figure class="figure-upper "><img src="https://images-beta.tossdown.com/site/6461ea07-f7d5-4620-aad8-c17add738062.webp" alt="" />
                <div class="figure-cap adjustment-for
                                        "><a href="#">House Hold Products</a></div>
                </figure>
                </div>
                </div>
                </div>
                </div>
                </div>
                        
                        `
                            },
    ]
    export default ProductCategorySectionObjects