const BannerObjects=[
    {
    id:"banner0",
    name:"Banner One",
    height:"510px",
    head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>

<style>
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    margin: 0px;
    width: 100%;
  }
   </style>

    `,
    code:` 
    
    <style>
    .banner_section {
      float: left;
      width: 100%;
  position:relative;
  height:100%;
  }
  .banner_slider_arrow_box {
       position: absolute;
      width: 100% !important;
      height: 100% !important;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .banner_slider_arrow_box .banner_next_arrow.slick-arrow {
                        position: absolute;
                        right: 20px;
                        cursor: pointer;
                        z-index: 99;
                    }
                .banner_slider_arrow_box .banner_next_arrow.slick-arrow i {
                        color: #fff !important;
                        font-size: 27px;
                    }
                .banner_slider_arrow_box .banner_pre_arrow.slick-arrow {
                        position: absolute;
                        left: 20px;
                        z-index: 99;
                    }
                  .banner_slider_arrow_box .banner_pre_arrow.slick-arrow i {
                        color: #fff !important;
                        font-size: 24px;
                    }
      .Banner_slider {
      overflow: hidden;
      margin:0px;
      }
      .Banner_slider .Banner{
          margin: 0px !important;
      }
      .Banner figure {
      float: left;
      width: 100%;
      margin: 0px;
      position: relative;
      }
      .Banner figure img {
      float: left;
      width: 100%;
      }
      .banner_text_section{
          position: absolute;
            padding-left: 10px;
            padding-right: 10px;
            height: 100%;
            width: 100%;
          top:0px;
          left:0px;
          }
          .banner_one_custom_container{
          max-width: 662px;
           margin: 0 auto;
              height: 100%;
          }
          .banner_slider_text_parent{
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;
              align-items: center;
              text-align: center;
          }
          .banner_slider_text_parent small{
  font-family: Oooh Baby;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: capitalize;
      color: #FFFFFF;
      padding-bottom: 8px;
      }
          .banner_slider_text {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          .banner_slider_text h2 {
      font-family: Prata;
      font-style: normal;
      font-weight: 400;
      font-size: 70px;
      line-height: 78px;
      text-align: center;
      color: #FFFFFF;
      padding-bottom: 16px;
          margin: 0px !important;
          }
          .banner_slider_text p{    
            font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: capitalize;
      color: #FFFFFF;
              padding-bottom: 40px;
              margin: 0px !important;
          }
          .banner_slider_text .first_banner_shop_btn {
              width: 100%;
              text-align: center;
              display: flex;
              justify-content: center;
          }
          .banner_slider_text .first_banner_shop_btn a {
            font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #000000;
            display: flex;
            align-items: center;
              justify-content: center;
          width: 152px;
          height: 48px;
          background: #FFFFFF;
      border-radius: 60px;
          }
       .Banner_slider ul.slick-dots {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin: 0px !important;
          bottom: 20px;
          position: absolute;
                    }
                   .Banner_slider ul.slick-dots li {
                     width: 10px !important;
          height: 10px !important;
          margin-right: 10px !important;
                    }
           .Banner_slider ul.slick-dots li:last-child{
             margin-right: 0px !important;
      }
                  .Banner_slider .slick-dots li button {
                    width: 100% !important;
          height: 100% !important;
          padding: 5px;
          cursor: pointer;
          color: white;
          border: 0;
          outline: none;
          background: transparent !important;
          border-radius: 50% !important;
          border: 1px solid #fff !important;
          position: relative;
          color: transparent;
                    }
                  .Banner_slider li.slick-active button {
                        background: #fff !important;
                    border: 1px solid #fff !important;
                    }
                   .Banner_slider .slick-dots li button:before{
                        display: none !important;
                    }
          @media (max-width: 1024px) {
          .banner_slider_text h2 {
              font-size: 40px;
      line-height: 48px;
      padding-bottom: 5px;
          }
          .banner_slider_text p {
              padding-bottom: 20px;
      }
      .banner_slider_text_parent small {
      display: none;
      }
      .banner_one_custom_container {
      max-width: 540px;
      }
          }
          @media (max-width: 768px) {
          .banner_slider_text p {
      padding-bottom: 15px;
      font-size: 13px;
      line-height: 18px;
      }
          .banner_slider_text h2 {
              font-size: 28px;
      line-height: 30px;
      padding-bottom: 10px;
          }
          .banner_slider_text .first_banner_shop_btn a {
              font-size: 12px;
              line-height: 17px;
              width: 140px;
              height: 42px
          }
  .banner_slider_arrow_box .banner_next_arrow.slick-arrow {
                        right: 10px;
                    }
                .banner_slider_arrow_box .banner_next_arrow.slick-arrow i {
                        font-size: 16px;
                    }
                .banner_slider_arrow_box .banner_pre_arrow.slick-arrow {
                        left: 10px;
                    }
                  .banner_slider_arrow_box .banner_pre_arrow.slick-arrow i {
                        font-size: 16px;
                    }
  .banner_text_section {
      padding-left: 30px;
      padding-right: 30px;
  }
          }
          @media (max-width: 540px) {
          .banner_one_custom_container {
            max-width: 320px;
           }
          .banner_slider_text p {
              font-size: 14px;
              display: block;
              text-overflow: ellipsis;
              word-wrap: break-word;
              overflow: hidden;
              white-space: nowrap;
              width: 100%;
              padding-bottom: 10px !important;
          }
          .banner_slider_text h2 {
      font-size: 20px;
      line-height: 26px;
      padding-bottom: 3px;
      }
          }
          @media (max-width: 430px) {
          .banner_slider_text p {
          display:none;
          }
          .banner_slider_text h2 {
      padding-bottom: 8px;
      }
          .banner_slider_text .first_banner_shop_btn a {
              font-size: 9px;
              width: 100px;
              height: 30px;
          }
          }
        
        
        </style>
<section class="banner_section">
<div class="banner_slider_arrow_box"></div>
<div class="Banner_slider">
<div class="Banner">
<figure><img src="https://tossdown.site/assets/media/Rectangle_732.png" />
<section class="banner_text_section">
<div class="banner_one_custom_container">
<div class="banner_slider_text_parent"><small>Bakery</small>
<div class="banner_slider_text">
<h2>Made by hand,from scratch,with love.</h2>
<p>Bakery Cakes, Cupcakes &amp; Cookies-For Custom orders order 3-4 days in advance.</p>
<div class="first_banner_shop_btn"><a>Shop Now</a></div>
</div>
</div>
</div>
</section>
</figure>
</div>
<div class="Banner">
<figure><img src="https://tossdown.site/assets/media/Rectangle_732.png" />
<section class="banner_text_section">
<div class="banner_one_custom_container">
<div class="banner_slider_text_parent"><small>Bakery</small>
<div class="banner_slider_text">
<h2>Made by hand,from scratch,with love.</h2>
<p>Bakery Cakes, Cupcakes &amp; Cookies-For Custom orders order 3-4 days in advance.</p>
<div class="first_banner_shop_btn"><a>Shop Now</a></div>
</div>
</div>
</div>
</section>
</figure>
</div>
</div>
</section>
<script>
    $(document).ready(function() {      
      $('.Banner_slider').slick({
          slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        dots: false,
        arrows:false,
  appendArrows: $('.banner_slider_arrow_box'),
   nextArrow: '<a class="banner_next_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
    prevArrow: '<a class="banner_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        responsive: [
{
  breakpoint: 1024,
  settings: {
    autoplay: false,
    dots: false,
    arrows: true,
  },
},
{
  breakpoint: 768,
  settings: {
    autoplay: false,
    dots: false,
    arrows: true,
  },
},
{
  breakpoint: 540,
  settings: {
    autoplay: false,
    dots: false,
    arrows: true,
  },
},
{
  breakpoint: 400,
  settings: {
    autoplay: false,
    dots: false,
    arrows: true,
  },
},
],
        });
        }); 
      
      </script>

`
    },
    {
      id:"banner1",
      name:"Banner Two",
      height:"510px",
      head:`
      <head>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      /> 
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
      
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
  
    <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
    <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
    
    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
    
  </head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

  * {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  margin: 0px;
  width: 100%;
}
</style>

      `,  
      
      code:` 
          
        <style>

.banner_section {
float: left;
width: 100%;

}
.Banner_slider {
overflow: hidden;
margin:0px;
}
.Banner_slider .Banner{
  margin: 0px !important;
}
.Banner figure {
float: left;
width: 100%;
margin-bottom: 0px;
position: relative;
}
.Banner figure img {
float: left;
width: 100%;
}
.banner_text_section{
      position: absolute;
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;
        width: 100%;
      top:0px;
      left:0px;
      }
      .banner_one_custom_container{
      max-width: 1140px;
       margin: 0 auto;
          height: 100%;
      }
      .banner_slider_text_parent{
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          align-items: flex-start;
          max-width: 520px;
      }

      .banner_slider_text_parent small{
         
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 20px;
text-align: center;
letter-spacing: 0.1em;
text-transform: capitalize;
color: #FFFFFF;
padding-bottom: 8px;
}
      .banner_slider_text {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .banner_slider_text h2 {
        font-family: Playfair Display;
          font-style: normal;
font-weight: 600;
font-size: 61px;
line-height: 72px;
color: #FFFFFF;
padding-bottom: 16px;
      margin: 0px !important;
      }
      
      .banner_slider_text p{
        font-family: inter;
          font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: rgba(255, 255, 255, 0.8);
          padding-bottom: 40px;
          margin: 0px !important;
      }
      .banner_slider_text .first_banner_shop_btn {
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: flex-start;
      }
      
      .banner_slider_text .first_banner_shop_btn a {
        font-family: inter;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #FFFFFF;
        display: flex;
        align-items: center;
          justify-content: center;
      width: 146px;
      height: 48px;
      background: #194E8C;
border-radius: 4px;
      }
     
      @media (max-width: 1024px) {
      .banner_slider_text h2 {
          font-size: 40px;
  line-height: 48px;
  padding-bottom: 5px;
      }
      .banner_slider_text p {
          padding-bottom: 20px;
}
.banner_slider_text_parent small {
  display: none;
}
      }
      @media (max-width: 768px) {
          .banner_one_custom_container {
  max-width: 400px;
}
.banner_slider_text_parent {
  max-width: 100%;
  text-align: center;
}
      .banner_slider_text p {
          padding-bottom: 15px;
  font-size: 14px;
  line-height: 18px;
}
      .banner_slider_text h2 {
          font-size: 28px;
  line-height: 30px;
  padding-bottom: 8px;
      
      }
      .banner_slider_text .first_banner_shop_btn {
  justify-content: center;
}
      .banner_slider_text .first_banner_shop_btn a {
          font-size: 12px;
          line-height: 17px;
          width: 140px;
          height: 42px
      }
      }
      
      @media (max-width: 540px) {
      .banner_one_custom_container {
        max-width: 320px;
       }
      .banner_slider_text p {
          font-size: 14px;
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding-bottom: 14px !important;
      }
      .banner_slider_text h2 {
          font-size: 22px;
  line-height: 28px;
  padding-bottom: 4px;
}
      }
      
      @media (max-width: 430px) {
      .banner_slider_text p {
      display:none;
      }
      .banner_slider_text h2 {
          padding-bottom: 10px;
}
  
      .banner_slider_text .first_banner_shop_btn a {
          font-size: 9px;
          width: 100px;
          height: 30px;
      }
      }
      
      
      </style>
  <section class="banner_section">
    <div class="Banner_slider">
      <div class="Banner">
         <figure>
           <img src="https://staging.tossdown.site/assets/media/Rectangle_181.png">
            <section class="banner_text_section">
              <div class="banner_one_custom_container">
              <div class="banner_slider_text_parent">
              <div class="banner_slider_text">
              <h2>Hand Slaughtered
                  Halal Meat!</h2>
              <p>Currently delivering to the city of toronto only.Delivery within 1-3
                  business days</p>
              <div class="first_banner_shop_btn"><a>Shop Now</a></div>
              </div>
              </div>
              </div>
              </section>


          </figure>
      </div> 
      <div class="Banner">
          <figure>
               <img src="https://staging.tossdown.site/assets/media/Rectangle_181.png">

               <section class="banner_text_section">
                  <div class="banner_one_custom_container">
                  <div class="banner_slider_text_parent">
                  <div class="banner_slider_text">
                      <h2>Hand Slaughtered
                          Halal Meat!</h2>
                      <p>Currently delivering to the city of toronto only.Delivery within 1-3
                          business days</p>
                  <div class="first_banner_shop_btn"><a>Shop Now</a></div>
                  </div>
                  </div>
                  </div>
                  </section>
           </figure>
          </div> 
    </div>

  </section>
  <script>
  $(document).ready(function() {      
    $('.Banner_slider').slick({
        slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      dots: false,
    arrows: false,
      });
      }); 
    
    </script>

        `,
        },
        {
          id:"banner2",
            name:"Banner Three",
            height:"510px",
            head:`
            <head>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            /> 
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
            
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
        
          <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
          <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
          
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
          
        </head>
        <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    margin: 0px;
    width: 100%;
  }
    </style>
            `,
          
            code:`
          
    
        <style>
        
      .home_custom_section_8_main{
      display:block;
          width: 100%;
          padding:0px 0px;
          float: left;
      background: #F5F5F5;
      }
      .custom_container{
      max-width:1140px;
      margin:0 auto;
      }
      .home_custom_section_8{
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". .";
          grid-template-rows: auto;
          position: relative;
          z-index: 1;
          width: 100%;
          align-items: center;
      height:100%;
      }
    
      .home_custom_section_8 figure {
          margin-bottom: 0px;
          width: 100%;
          height: 100%;
      }
      .home_custom_section_8 figure img{
          width: 100%;
          height: 100%;
      }
      .home_custom_section_8_body_main {
          justify-content: flex-end;
          align-items: flex-end;
          display: flex;
          width: 100%;
          padding-right: 100px;
        padding-left: 15px;
      }
      .home_custom_section_8_body{
          display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 460px;
      }
      .home_custom_section_8_heading{
          display: grid;
          width: 100%;
          gap: 4px 0px;
      }
      .home_custom_section_8_heading h3{
       
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 60px;
color: #000000;
margin: 0px;
padding-bottom: 42px;     
position: relative;  
}
.home_custom_section_8_heading h3::before{
  position: absolute;
  content: "";
background-image: url('https://tossdown.site/images/418315479dba5cc1c4f0755ec2afa044_1658832468.png');
width: 112px;
  height: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0px;
  bottom: 24px;
}

      .home_custom_section_8_des{
          display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 32px;
  max-width: 400px;
      }
      .home_custom_section_8_des p{
      
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: rgba(0, 0, 0, 0.5);
    margin: 0px;
      }
      .home_custom_section_8_body .home_custom_section_8_shop_btn{
          width: 100%;
          display: flex;
      }
      .home_custom_section_8_body .home_custom_section_8_shop_btn a{
         
          font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
text-transform: uppercase;
color: #FFFFFF;
          width: 146px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #194E8C;
      }
      @media(max-width:1140px){
      .home_custom_section_8_body_main {
  padding-right: 40px;
  justify-content: flex-start;
}
}
      @media(max-width:1024px){
          .home_custom_section_8_body_main {
          padding-right: 20px;
      }
          .home_custom_section_8_body {
  max-width: 320px;
}
      .home_custom_section_8_heading h3 {
          font-size: 30px;
  line-height: 33px;
  padding-bottom: 30px;
      }
      .home_custom_section_8_heading h3::before {
  bottom: 17px;
}
.home_custom_section_8_des {
  padding-bottom: 17px;
   max-width: 100%; 
}
      }
      @media(max-width:768px){
          .home_custom_section_8{
          grid-template-columns: 1fr;
        grid-template-areas: ".";
      }
      .home_custom_section_8_body_main {
          padding-right:15px;
          justify-content: center;
          padding-bottom:40px;
      }
      .home_custom_section_8_main{
          padding-top: 40px;
         
      }
      .home_custom_section_8_body {
          justify-content: center;
          text-align: center;
          max-width: 100%;
      }
      .home_custom_section_8_heading h3::before {
  bottom: 17px;
  left: 38%;
}
      .home_custom_section_8_body .home_custom_section_8_shop_btn {
  justify-content: center;
}
         
      }
      @media(max-width:540px){
      .home_custom_section_8_heading h3 {
          padding-bottom: 14px;
      }
      .home_custom_section_8_heading h3::before{
          display: none;
      }
      .home_custom_section_8_body_main {
  padding-bottom: 25px;
}
.home_custom_section_8_main {
  padding-top: 25px;
}
      }
    
      </style>

  <div class="home_custom_section_8_main">
      <div class="home_custom_section_8">
      <div class="home_custom_section_8_body_main">
      <div class="home_custom_section_8_body">
      <div class="home_custom_section_8_heading">
      <h3>Nourishing A
          Brighter Future.</h3>
      </div>
      <div class="home_custom_section_8_des">
          <p>Jam as a brand believes in nourishing & nurturing a brighter future.</p>
      </div>
      <div class="home_custom_section_8_shop_btn">
          <a href="/shop">Order Online</a></div>
      </div>
      </div>
      <figure><img src="https://tossdown.site/images/cb865d18530dadc7b51ab4534d255ccd_1658831304.png"></figure>
      </div>
      </div>
            `,
            }, 
            
            
            {
              id:"banner3",
              name:"Banner Four",
              height:"510px",
              head:`
              <head>
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              /> 
              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
              <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
              
              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
          
            <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
            <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
            
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
            
          </head>
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
          * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          margin: 0px;
          width: 100%;
        }
          </style>

              `,
              
              code:`
              
     
          <style>
.banner_section {
  float: left;
  width: 100%;
}
.Banner_slider {
  overflow: hidden;
  margin:0px;
}
.Banner figure {
  float: left;
  width: 100%;
  margin-bottom: 0px;
  position: relative;
}
.Banner_slider .Banner{
  margin: 0px !important;
}
.Banner figure img {
  float: left;
  width: 100%;
}
.banner_text_section{
        position: absolute;
          padding-left: 20px;
          padding-right: 20px;
          height: 100%;
          width: 100%;
        top:0px;
        left:0px;
        }
        .banner_one_custom_container{
        max-width: 1140px;
         margin: 0 auto;
            height: 100%;
        }
        .banner_slider_text_parent{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            align-items: flex-start;
            max-width: 400px;
        }

        .banner_slider_text_parent small{
            font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 22px;
text-transform: uppercase;
color: #37887D;
padding-bottom: 8px;
}
        .banner_slider_text {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .banner_slider_text h2 {
            font-family: 'Merriweather';
font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 66px;
color: #153531;
padding-bottom: 24px;
        margin: 0px !important;
        }
        
        .banner_slider_text p{
            font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;

color: #000000;

            padding-bottom: 48px;
            margin: 0px !important;
        }
        .banner_slider_text .first_banner_shop_btn {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: flex-start;
        }
        
        .banner_slider_text .first_banner_shop_btn a {
            font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
color: #FFFFFF;
          display: flex;
          align-items: center;
            justify-content: center;
        width: 136px;
        height: 48px;
        background: #153531;
        }
       
        @media (max-width: 1024px) {
        .banner_slider_text h2 {
            font-size: 40px;
    line-height: 48px;
    padding-bottom: 5px;
        }
        .banner_slider_text p {
            padding-bottom: 20px;
}

.banner_slider_text_parent {
    max-width: 300px;
}
        }

        @media (max-width: 768px) {
    .banner_slider_text_parent {
        width: 41%;
    }
    .banner_slider_text_parent small{
    font-size: 13px;
    line-height: 16px;
}
    .banner_slider_text p{
        font-size: 13px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 32px;
    margin: 0px !important;
    line-height: 15px;
    margin-bottom: 10px !important;
    padding-bottom: 0px !important;
    max-height: 32px !important;
    }
    .banner_slider_text h2 {
        font-size: 26px;
    line-height: 33px;
    }
    
    .banner_slider_text .first_banner_shop_btn a {
    width: 118px;
    height: 34px;
    }
    }
    
    @media (max-width: 540px) {
    
        .banner_slider_text p {
    height: 19px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: unset;
    }
    .banner_slider_text h2 {
        font-size: 21px;
    line-height: 25px;
    padding-bottom: 6px;
    }
    .banner_slider_text .first_banner_shop_btn a {
    width: 118px;
        height: 35px;
    }
    }
    
    @media (max-width: 430px) {
        .banner_slider_text_parent small {
    display: none;
}
.banner_slider_text p{
    display:none;
    }
    .banner_slider_text h2 {
        font-size: 16px;
    line-height: 21px;
    padding-bottom: 10px;
    }
    .banner_slider_text .first_banner_shop_btn a  {
        font-size: 13px;
        width: 93px;
        height: 26px;
    }
    
    }
        
        </style>
  
        <section class="banner_section">
        <div class="Banner_slider">
          <div class="Banner">
             <figure>
               <img src="https://tossdown.site/assets/media/Group_69.png">
               <section class="banner_text_section">
                  <div class="banner_one_custom_container">
                  <div class="banner_slider_text_parent">
                      <small>Butchery</small>
                  <div class="banner_slider_text">
                  <h2>Finest Quality Fresh Meat</h2>
                  <p>We provide well shaped fresh and organic meat from our farm in a very hygienic way.</p>
                  <div class="first_banner_shop_btn"><a>Shop Now</a></div>    
              </div>
                  </div>
                  </div>
                 </section>
  
  
              </figure>
          </div> 
          <div class="Banner">
              <figure>
                   <img src="https://tossdown.site/assets/media/Group_69.png">
  
                   <section class="banner_text_section">
                      <div class="banner_one_custom_container">
                      <div class="banner_slider_text_parent">
                          <small>Butchery</small>
                      <div class="banner_slider_text">
                      <h2>Finest Quality Fresh Meat</h2>
                      <p>We provide well shaped fresh and organic meat from our farm in a very hygienic way.</p>
                      <div class="first_banner_shop_btn"><a>Shop Now</a></div>    
                  </div>
                      </div>
                      </div>
                     </section>
               </figure>
              </div> 
        </div>
  
      </section> 
      <script>
      $(document).ready(function() {      
        $('.Banner_slider').slick({
            slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          dots: false,
          arrows:false,
          autoplaySpeed: 2000,
          });
          }); 
        
        </script>
              `,
              }, 
    
              {
                id:"banner4",
                name:"Banner Five",
                height:"510px",
                head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  margin: 0px;
  width: 100%;
}
</style>
          
    `,
               
               
                code:` 
               
               
                <style>     
            .banner_section {
            float: left;
            width: 100%;
            
            }
            .banner_main_container{
                max-width: 1140px;
                margin: 0 auto;
            }
            .Banner_slider {
            overflow: hidden;
            margin:0px;
            }
            .Banner_slider .Banner{
              margin: 0px !important;
          }
            .Banner figure {
            float: left;
            width: 100%;
            margin-bottom: 0px;
            position: relative;
            }
            .Banner figure img {
            float: left;
            width: 100%;
            }
            .banner_text_section{
                position: absolute;
                  padding-left: 10px;
                  padding-right: 10px;
                  height: 100%;
                  width: 100%;
                top:0px;
                left:0px;
                }
                .banner_one_custom_container{
                max-width: 662px;
                 margin: 0 auto;
                    height: 100%;
                }
                .banner_slider_text_parent{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    align-items: center;
                    text-align: center;
                }
            
                .banner_slider_text_parent small{
                 
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: capitalize;
            color: #FFFFFF;
            padding-bottom: 8px;
            }
                .banner_slider_text {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                }
                .banner_slider_text h2 {
                   
            font-style: normal;
            font-weight: 400;
            font-size: 70px;
            line-height: 78px;
            text-align: center;
            color: #FFFFFF;
            padding-bottom: 16px;
                margin: 0px !important;
                }
                
                .banner_slider_text p{
                   
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: capitalize;
            color: #FFFFFF;
                    padding-bottom: 40px;
                    margin: 0px !important;
                }
                .banner_slider_text .first_banner_shop_btn {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }
                
                .banner_slider_text .first_banner_shop_btn a {
                   
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #000000;
                  display: flex;
                  align-items: center;
                    justify-content: center;
                width: 152px;
                height: 48px;
                background: #FFFFFF;
            border-radius: 60px;
                }

                ul.slick-dots {
                  display: flex;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  margin: 0px !important;
                  bottom: 30px;
              }
              ul.slick-dots li {
                  width: unset !important;
                  height: unset !important;
              }
              
              .slick-dots li button {
                  width: 10px !important;
                  height: 10px !important;
                  padding: 5px;
                  cursor: pointer;
                  color: white;
                  border: 0;
                  outline: none;
              background: transparent !important;
                  border-radius: 50% !important;
              border: 1px solid #fff !important;
              position:relative;
              color: transparent;
              }
              li.slick-active button {
                  background: #fff !important;
              border: 1px solid #fff !important;
              }
              .slick-dots li button:before{
                  display: none !important;
              }
              .banner_next_arrow.slick-arrow {
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  z-index: 99;
              }
              .banner_next_arrow.slick-arrow i {
                  color: #fff !important;
                  font-size: 27px;
              }
              .banner_pre_arrow.slick-arrow {
                  position: absolute;
                  left: 20px;
                  top: 50%;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  z-index: 99;
              }
              .banner_pre_arrow.slick-arrow i {
                  color: #fff !important;
                  font-size: 27px;
              }
               
                @media (max-width: 1024px) {
                .banner_slider_text h2 {
                    font-size: 40px;
            line-height: 48px;
            padding-bottom: 5px;
                }
                .banner_slider_text p {
                    padding-bottom: 20px;
            }
            .banner_slider_text_parent small {
            display: none;
            }
            .banner_one_custom_container {
            max-width: 540px;
            }
                }
                @media (max-width: 768px) {
                .banner_slider_text p {
            padding-bottom: 15px;
            font-size: 13px;
            line-height: 18px;
            }
                .banner_slider_text h2 {
                    font-size: 28px;
            line-height: 30px;
            padding-bottom: 10px;
                
                }
                .banner_slider_text .first_banner_shop_btn a {
                    font-size: 12px;
                    line-height: 17px;
                    width: 140px;
                    height: 42px
                }
                }
                
                @media (max-width: 540px) {
                .banner_one_custom_container {
                  max-width: 320px;
                 }
                .banner_slider_text p {
                    font-size: 14px;
                    display: block;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                    padding-bottom: 10px !important;
                }
                .banner_slider_text h2 {
            font-size: 20px;
            line-height: 26px;
            padding-bottom: 3px;
            }
            .banner_next_arrow.slick-arrow{
              display: none !important;
            }
            .banner_pre_arrow.slick-arrow{
              display: none !important;
            }
            ul.slick-dots {
              bottom: 15px;
          }
                }
                
                @media (max-width: 430px) {
                .banner_slider_text p {
                display:none;
                }
                .banner_slider_text h2 {
            padding-bottom: 8px;
            }
            
                .banner_slider_text .first_banner_shop_btn a {
                    font-size: 9px;
                    width: 100px;
                    height: 30px;
                }
                }
                
                
                </style>
            <section class="banner_section">
                <div class="banner_main_container">
              <div class="Banner_slider">
                <div class="Banner">
                   <figure>
                     <img src="https://static.tossdown.com/images/883ce755-d5c7-42cb-8246-ab132b1612af.png">
                      <section class="banner_text_section">
                        <div class="banner_one_custom_container">
                        <div class="banner_slider_text_parent">
                            <small>Manal’s</small>
                        <div class="banner_slider_text">
                        <h2>Made by hand,from scratch,with love.</h2>
                        <p>Manal’s Cakes, Cupcakes &amp; Cookies-For Custom orders order 3-4 days in advance. </p>
                        <div class="first_banner_shop_btn"><a>Shop Now</a></div>
                        </div>
                        </div>
                        </div>
                        </section>
            
            
                    </figure>
                </div> 
                <div class="Banner">
                    <figure>
                         <img src="https://static.tossdown.com/images/883ce755-d5c7-42cb-8246-ab132b1612af.png">
            
                         <section class="banner_text_section">
                            <div class="banner_one_custom_container">
                            <div class="banner_slider_text_parent">
                                <small>Bakery</small>
                            <div class="banner_slider_text">
                            <h2>Made by hand,from scratch,with love.</h2>
                            <p>Bakery Cakes, Cupcakes &amp; Cookies-For Custom orders order 3-4 days in advance. </p>
                            <div class="first_banner_shop_btn"><a>Shop Now</a></div>
                            </div>
                            </div>
                            </div>
                            </section>
                     </figure>
                    </div> 
              </div>
            </div>
            </section>
            <script>
            $(document).ready(function() {      
              $('.Banner_slider').slick({
                  slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: true,
                arrows: true,
                nextArrow: '<a class="banner_next_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
              prevArrow: '<a class="banner_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
                });
                }); 
              
              </script>
            
            `
                },

                {
                  id:"banner5",
                  name:"Banner Six",
                  height:"510px",
                  head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  margin: 0px;
  width: 100%;
  background:#fff;
}
</style>
    `,
                 
                 
                  code:` 
                 
                
                  <style>
                
              .banner_section {
                  display: block;
                 width: 100%;
                 margin-bottom: 50px;
                 padding: 40px 15px 40px 15px;
             background: rgba(245, 198, 207, 0.24);
             background-repeat: no-repeat;
             background-size: cover;
              }
              .custom_container{
                 max-width: 1140px;
                 margin: 0px auto;
             }
              .Banner_slider {
              overflow: hidden;
              margin:0px;
              }
              .Banner_slider .Banner{
                margin: 0px !important;
            }
              .home_page_banner_box{
             display: grid;
                 grid-template-columns: 1fr 1fr;
                 grid-template-rows: auto;
                 grid-template-areas: ". .";
                 gap: 40px 65px;
                 width: 100% ;
             align-items: center;
             }
             .home_page_banner_box figure{
              width: 100%;
             }
             .home_page_banner_box figure img{
              width: 100%;
             }
          
             .home_page_banner_figcaption{
             display: flex;
                 flex-direction: column;
                 align-items: flex-start;
                 justify-content: center;
                 height: 100%;
             }
             .home_page_banner_figcaption small{
             
              font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          color: #2D2A38;
          padding-bottom: 12px;
             }
             .home_page_banner_figcaption h4 {
            
             font-style: normal;
              font-weight: normal;
              font-size: 64px;
              line-height: 74px;
              color: #514533;
              padding-bottom: 16px;
             margin:0px;
             }
             .home_page_banner_figcaption p{
             
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.7);
          padding-bottom:48px;
          margin: 0px;
             }
            
             .home_page_banner_figcaption_btn{
                 display: flex;
                 align-items: center;
             }
             .home_page_banner_figcaption_btn a {
                   display: flex;
                 align-items: center;
                 width: 146px;
          height: 48px;
         
          background: #D8EAFF;
          border-radius: 2px;
                 justify-content: center;
                 font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #03242D;
             }
             .slick-slide .Banner {
              display: none !important;
          }
          .slick-slide.slick-current.slick-active .Banner {
              display: block !important;
          }
            
             @media (max-width: 1024px){
             .home_page_banner_box {
                 gap: 40px 40px;
             }
             .home_page_banner_figcaption h4 {
             font-size: 40px;
             line-height:48px;
             padding-bottom: 20px;
             }
             }
             @media  (max-width:768px){
             .home_page_banner_box {
                 grid-template-columns: 1fr;
                 grid-template-areas: ".";
             }
             .home_page_banner_box figure{
             max-width: 540px;
              margin: 0 auto;
             }
             .home_page_banner_figcaption {
                 align-items: center;
                 text-align: center;
             }
             .home_page_banner_figcaption p {
                 padding-bottom: 30px;
             }
          }
             @media  (max-width:480px){
             .home_page_banner_figcaption h4 {
             font-size: 36px;
             line-height: 48px;
             }
          
             }
           
             </style>
                  <section class="banner_section">
                  <div class="custom_container">
                   
                  <div class="Banner_slider">
                      <div class="Banner">
                          <div class="home_page_banner_box">
                          <div class="home_page_banner_figcaption">
                              <small>FRESH & TASTY</small>
                          <h4>Our Passion is Perfection!</h4>
                          <p>Light up all your occasions and add that much needed happy vibe to your celebrations with our scrumptious cakes.</p>
                          <div class="home_page_banner_figcaption_btn">
                              <a>Order now  </a>
                          </div>
                          </div>
                          <figure><img src="https://static.tossdown.com/site/images/8ef09bdcf4582ffb506f3d5ff9cafc24_1643091300.png"></figure>
                          </div>
                         </div> 
                          <div class="Banner">
                          <div class="home_page_banner_box">
                              <div class="home_page_banner_figcaption">
                                  <small>The best of nature</small>
                                <h4>Fall in love with
                                  your skin!</h4>
                              <div class="home_page_banner_figcaption_btn">
                                  <a href="/shop">Shop Now </a>
                              </div>
                              </div>
                              <figure><img src="https://static.tossdown.com/site/images/8ef09bdcf4582ffb506f3d5ff9cafc24_1643091300.png"></figure>
                              </div>
                          </div>   
                </div>
          
          
              </div>
              </section>
              <script>
              $(document).ready(function() {      
                $('.Banner_slider').slick({
                    slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: false,
                  autoplaySpeed: 2000,
                  fade: true,
                  cssEase: 'linear',
                  arrows: false,
                  dots: false,
                  autoplay: false,
                  });
                  }); 
                
                </script>
              
              `
                  },


                  {
                    id:"banner6",
                    name:"Banner Seven",
                    height:"510px",
                    head:`
                    <head>
                    <meta charset="utf-8" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                    /> 
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                    
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                
                  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                  
                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                  
                </head>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                    * {
                    -web-kit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 0px;
                    margin: 0px;
                  }
                  html {
                    scroll-behavior: smooth;
                  }
                  body {
                    float: left;
                    margin: 0px;
                    width: 100%;
                    background:#fff;
                  }
                    </style>
                 
                    `,
                    
                    
                    code:` 
                    
                    
                    <style>
                   
              .banner_section {
                float: left;
                width: 100%;
              }
              .Banner_slider {
                overflow: hidden;
                margin:0px;
              }
             .Banner_slider .Banner{
            margin:0px;
            }
              .Banner figure {
                float: left;
                width: 100%;
                margin-bottom: 0px;
                position: relative;
              }
              .Banner figure img {
                float: left;
                width: 100%;
              }
              .banner_text_section{
                      position: absolute;
                        padding-left: 20px;
                        padding-right: 20px;
                        height: 100%;
                        width: 100%;
                      top:0px;
                      left:0px;
                      }
                      .banner_one_custom_container{
                      max-width: 1140px;
                       margin: 0 auto;
                          height: 100%;
                          position: relative;
                      }
                      .banner_slider_text_parent{
                          height: 100%;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          width: 100%;
                          align-items: flex-start;
                          max-width: 460px;
                      }
              
                      .banner_slider_text_parent small{
                      font-family: 'Cabin', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: #2D2A38;
              padding-bottom: 12px;
              }
                      .banner_slider_text {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                      }
                      .banner_slider_text h2 {
                font-family: butler;
             font-style: normal;
            font-weight: 700 !important;
            font-size: 56px;
            line-height: 66px;
            text-transform: capitalize;
            color: #03242D;
              padding-bottom: 32px;
             margin: 0px !important;
                      }
                      
                      .banner_slider_text p{
                            font-family: 'Cabin', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              
              color: #000000;
             
                          padding-bottom: 48px;
                          margin: 0px !important;
                      }
                      .banner_slider_text .first_banner_shop_btn {
                          width: 100%;
                          text-align: center;
                          display: flex;
                          justify-content: flex-start;
                      }
                      
                      .banner_slider_text .first_banner_shop_btn a {
                                font-family: 'Cabin', sans-serif;
             font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #03242D;
                        display: flex;
                        align-items: center;
                          justify-content: center;
               width: 146px;
            height: 48px;
            background: #D8EAFF;
            border-radius: 2px;
                      }
                     
                      @media (max-width: 1024px) {
                      .banner_slider_text h2 {
                          font-size: 40px;
                  line-height: 48px;
                      }
                      .banner_slider_text p {
                          padding-bottom: 20px;
              }
              .banner_slider_text_parent small {
                padding-bottom: 4px;
            }
              .banner_slider_text_parent {
                  max-width: 300px;
              }
                      }
              
                      @media (max-width: 768px) {
                  .banner_slider_text_parent {
                      width: 41%;
                  }
                  .banner_slider_text_parent small{
                  font-size: 13px;
                  line-height: 16px;
              }
                  .banner_slider_text p{
                      font-size: 13px;
                  word-break: break-word;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  height: 32px;
                  margin: 0px !important;
                  line-height: 15px;
                  margin-bottom: 10px !important;
                  padding-bottom: 0px !important;
                  max-height: 32px !important;
                  }
                  .banner_slider_text h2 {
                      font-size: 26px;
                  line-height: 33px;
                  }
                  
                  .banner_slider_text .first_banner_shop_btn a {
                  width: 118px;
                  height: 34px;
                  }
                  }
                  
                  @media (max-width: 540px) {
                  
                      .banner_slider_text p {
                  height: 19px;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  display: unset;
                  }
                  .banner_slider_text h2 {
                      font-size: 21px;
                  line-height: 25px;
            padding-bottom: 12px;
                  }
                  .banner_slider_text .first_banner_shop_btn a {
                  width: 118px;
                      height: 35px;
                  }
            .banner_slider_text_parent small {
                font-size: 12px;
                line-height: 16px;
                padding-bottom: 3px;
            }
                  }
                  
                  @media (max-width: 430px) {
                      .banner_slider_text_parent small {
                  display: none;
              }
              .banner_slider_text p{
                  display:none;
                  }
                  .banner_slider_text h2 {
                     font-size: 18px;
                line-height: 21px;
                padding-bottom: 12px;
                  }
                  .banner_slider_text .first_banner_shop_btn a  {
                      font-size: 13px;
                      width: 93px;
                      height: 26px;
                  }
                  
                  }
                      
                      </style>
            <section class="banner_section">
            <div class="Banner_slider">
            <div class="Banner">
            <figure><img src="https://static.tossdown.com/images/20a22417-ccf7-43db-944b-27dcb5af79d6.jpg" />
            <section class="banner_text_section">
            <div class="banner_one_custom_container">
            <div class="banner_slider_text_parent"><small>The best of nature</small>
            <div class="banner_slider_text">
            <h2>Fall in love with your skin!</h2>
            <div class="first_banner_shop_btn"><a >Shop Now</a></div>
            </div>
            </div>
            </div>
            </section>
            </figure>
            </div>
            </div>
            </section>
                
                `
                    },

                    {
                      id:"banner7",
                      name:"Banner Eight",
                      height:"510px",
                      head:`
                      <head>
                      <meta charset="utf-8" />
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, shrink-to-fit=no"
                      /> 
                      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                      
                      <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                  
                    <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                    <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                    
                    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                    
                  </head>
                  <style>
                  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                  * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
                  </style>
                      `,
                      
                      code:` 
                      
                     
                      <style>
                      
                         .home_custom_section_9_main{
                         display:block;
                             width: 100%;
                             padding:40px 15px;
                             float: left;
                         margin-bottom:70px;
                        background: #FCFAF7;
                         }
                         .custom_container{
                         max-width:1140px;
                         margin:0 auto;
                         }
                         .home_custom_section_9{
                             display: grid;
                             grid-template-columns: 1fr 1.6fr;
                             grid-template-areas: ". .";
                             grid-template-rows: auto;
                             position: relative;
                             z-index: 1;
                             width: 100%;
                             align-items: center;
                         height:100%;
                         }
                       .banner_absolute_img{
                   position:absolute;
                       position: absolute;
                       bottom: -40px;
                       left: 0px;
                   }
                         .home_custom_section_9 figure {
                             margin-bottom: 0px;
                             width: 100%;
                             height: 100%;
                         }
                         .home_custom_section_9 figure img{
                             width: 100%;
                             height: 100%;
                         }
                         .home_custom_section_9_body_main {
                       ustify-content: flex-start;
                       align-items: flex-start;
                       display: flex;
                       width: 100%;
                       padding-right: 68px;
                         }
                         .home_custom_section_9_body{
                         display: flex;
                       flex-direction: column;
                       justify-content: center;
                       align-items: flex-start;
                       max-width: 280px;
                         }
                         .home_custom_section_9_heading{
                             display: grid;
                             width: 100%;
                             gap: 8px 0px;
                         }
                    .home_custom_section_9_heading small{
                       font-family: 'canela';
                   font-style: normal;
                   font-weight: 250;
                   font-size: 24px;
                   line-height: 24px;
                   color: #004029;
                   }
                         .home_custom_section_9_heading h3{
                            font-family: 'Oswald' !important;
                   font-style: normal;
                   font-weight: 700;
                   font-size: 40px;
                   line-height: 55px;
                   text-transform: uppercase;
                   color: #212121;
                   margin: 0px;
                   padding-bottom: 40px;     
                   
                   }
                   
                       
                         .home_custom_section_9_body .home_custom_section_9_shop_btn{
                             width: 100%;
                             display: flex;
                       position: relative;
                         }
                         .home_custom_section_9_body .home_custom_section_9_shop_btn a{
                         font-family: 'Oswald';
                   font-style: normal;
                   font-weight: 500;
                   font-size: 14px;
                   line-height: 21px;
                   text-transform: uppercase;
                   color: #FFFFFF;
                        width: 164px;
                   height: 48px;
                             display: flex;
                             justify-content: center;
                             align-items: center;
                        background: #004029;
                   border-radius: 52px;
                         }
                      .home_custom_section_9_body .home_custom_section_9_shop_btn a i{
                       background: #FFFFFF;
                       width: 18px;
                       height: 18px;
                       color: #004029;
                       border-radius: 50%;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       font-size: 14px;
                       font-weight: bold;
                       margin-left: 8px;
                   }
                         @media(max-width:1140px){
                         .home_custom_section_9_body_main {
                     padding-right: 40px;
                     justify-content: flex-start;
                   }
                   }
                         @media(max-width:1024px){
                             .home_custom_section_9_body_main {
                             padding-right: 20px;
                         }
                             .home_custom_section_9_body {
                     max-width: 320px;
                   }
                         .home_custom_section_9_heading h3 {
                             font-size: 30px;
                     line-height: 33px;
                     padding-bottom: 30px;
                         }
                        
                   
                         }
                         @media(max-width:768px){
                             .home_custom_section_9{
                             grid-template-columns: 1fr;
                           grid-template-areas: ".";
                         }
                       .banner_absolute_img{
                       display: none;
                   }
                         .home_custom_section_9_body_main {
                             padding-right:15px;
                             justify-content: center;
                             padding-bottom:40px;
                         }
                         .home_custom_section_9_main{
                             padding-top: 40px;
                            
                         }
                   .home_custom_section_9_body .home_custom_section_9_shop_btn {
                       justify-content: center;
                   }
                         .home_custom_section_9_body {
                             justify-content: center;
                             text-align: center;
                             max-width: 100%;
                         }
                       
                         .home_custom_section_9_body .home_custom_section_8_shop_btn {
                     justify-content: center;
                   }
                            
                         }
                         @media(max-width:540px){
                         .home_custom_section_9_heading h3 {
                             padding-bottom: 14px;
                         }
                    
                         .home_custom_section_8_body_main {
                     padding-bottom: 25px;
                   
                   }
                   .home_custom_section_9_main {
                     padding-top: 25px;
                       margin-bottom: 50px;
                   }
                         }
                       
                         </style>
                   <div class="home_custom_section_9_main">
                   <div class="custom_container">
                   <div class="home_custom_section_9">
                   <div class="banner_absolute_img"><img src="https://static.tossdown.com/images/56fe33f5-fa4b-48d1-8092-7f86c70164b6.png" /></div>
                   <div class="home_custom_section_9_body_main">
                   <div class="home_custom_section_9_body">
                   <div class="home_custom_section_9_heading"><small>Foundation</small>
                   <h3>Pakistan needs our help! Emergency flood relief</h3>
                   </div>
                   <div class="home_custom_section_9_shop_btn"><a  target="_blank" rel="noopener">Donate Now </a></div>
                   </div>
                   </div>
                   <figure><img src="https://images-beta.tossdown.com/site/06961e4d-981a-49a6-ae25-cdf122750a24.webp" /></figure>
                   </div>
                   </div>
                   </div>
                  
                  `
                      },


                      {
                        id:"banner8",
                        name:"Banner Nine",
                        height:"510px",
                        
                        head:`
                        <head>
                        <meta charset="utf-8" />
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                        /> 
                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                        
                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                    
                      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                      <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                      
                      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                      
                    </head>
                    <style>
                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                    *{
                      -web-kit-box-sizing: border-box;
                     -moz-box-sizing: border-box; 
                 box-sizing:border-box; 
                      padding:0px;
                      margin:0px;
                      }
                      html {
                      scroll-behavior: smooth;
                      }
                      body{
                      float: left;
                      width: 100%;
                      margin:0px;
                      }
                    </style>
                        `,
                        
                        code:` 
                        
                      

                        <style>
            
              
                        
                        
                                    .section2{
                                        display: block;
                                width: 100%;
                                padding: 60px 0px 91px 0px ;
                        
                                background: #F8F3ED;
                                overflow:hidden ;
                        
                            
                                    }
                                
                                    .section2-custom-container{
                            max-width: 1100px;
                            margin: 0px auto;
                                    }
                        
                            .section2-inner-content{
                                display: flex;align-items: center;justify-content: center; flex-direction: column;
                                text-align: center;
                            }
                                    .section2-heading h2{
                                        font-family: 'Cormorant';
                            font-style: normal;
                            font-weight: 700!important;
                            font-size: 68px!important;
                            line-height: 82px!important;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            text-transform: capitalize;
                            padding-bottom: 35px;
                        
                            color: #3E3E3E;
                            margin: 0px!important;
                                    }
                        
                                    .section2-cta a{
                                        font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            /* identical to box height */
                        
                            display: flex;
                            align-items: center;
                            text-transform: uppercase;
                        
                            color: #FFFFFF;
                            background: #B67A65;
                            width: 172px;
                            height: 44px;
                            justify-content: center;
                            text-decoration: none;
                            margin: auto;
                            margin-bottom: 80px;
                                    }
                        
                          .section2-cta a:hover{
                        
                            box-shadow: 0px 4px 6px rgb(0 0 0 / 8%);
                            transition: 0.5s;
                            background: linear-gradient(to left, #F8F3ED 50%, #B67A65 50%) right;
                            background-size: 200%;
                            transition: .5s ease-out;
                        }
                        
                        
                        
                        
                                    figure{
                                        margin: 0px!important
                                        ;
                                    }
                                    .img-collection-section2{
                                        display: grid;
                                        grid-template-columns: 1fr 1fr 1fr;
                                        gap: 0px 80px;
                                    
                                    }
                                    .imgs-collection-image1 img{
                                        max-width:100% ;
                        
                                    }
                                    .imgs-collection-image2 img{
                                       max-width:100% ;
                                        text-align: center;
                        
                                    }
                                    .imgs-collection-image3 img{
                                        max-width:100% ;
                                           
                        
                                    }
                        
                        
                                    @media (max-width:1024px) {
                        
                        
                                    .section2-heading h2{
                                        font-size: 60px!important;
                                    }
                                    .img-collection-section2 {
                   
                    gap: 0px 44px;
                }
                                    }
                                    @media (max-width:850px) {
                
                                        .container-responisev-help {
                    max-width: 750px;
                }
                                        
                                    }
                                    @media (max-width:767px) {
                        
                        .section2-heading h2{
                        font-size: 50px!important;
                        
                        }
                        .img-collection-section2 {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            gap: 0px 30px;
                        }
                        
                        }
                        @media (max-width:650px) {
                            .container-responisev-help {
                    max-width: 530px;
                }
                            .imgs-collection-image1 img{
                                max-width: 100%;
                                        
                        
                                    }
                                    .imgs-collection-image2 img{
                                       max-width: 100%;
                                        text-align: center;
                        
                                    }
                                    .img-collection-section2 {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            gap: 0px 20px;
                        }
                                    .imgs-collection-image3 img{
                                  max-width: 100%   ;
                                           
                        
                                    }
                                    .section2-heading h2 {
                            font-size: 40px!important;
                            line-height: 53px!important;
                        }
                        .section2-cta a{
                            margin-bottom: 40px;
                        }
                        
                        }
                        
                        @media (max-width:550px) {
                            .section2-heading h2 {
                            font-size: 33px!important;
                            line-height: 50px!important;
                            padding-bottom: 20px;
                        }
                        .imgs-collection-image2 img {
                          max-width: 100%;
                            text-align: center;
                        }
                        }
                
                
                   
                        @media (max-width:500px) {
                            .img-collection-section2 {
                    display: grid;
                    grid-template-columns: auto 1fr auto;
                    gap: 0px 15px;
                }
                        }
                
                        @media (max-width:450px) {
                            .section2-heading h2 {
                            font-size: 25px!important;
                            line-height: 41px!important;
                            padding-bottom: 20px;
                        }
                        .section2-cta a {
                           
                            width: 150px;
                            height: 40px;
                        font-size:12px;
                        }
                        .imgs-collection-image2 img {
                         max-width: 100%;
                            text-align: center;
                        }
                        }
                        @media (max-width:400px) {
                            .section2-cta a {
                    width: 100px;
                    height: 40px;
                }
                .imgs-collection-image2 img {
                    max-width: 110px;
                  
                }
                        }
                        
                                </style>
                <div class="section2">
                <div class="section2-custom-container">
                <div class="section2-inner-content">
                <div class="section2-heading" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1200" data-aos-once="true" data-aos-offset="50">
                <h2>Fine art of ancient knotting for modern home</h2>
                </div>
                <div class="container-responisev-help">
                <div class="img-collection-section2">
                <figure class="imgs-collection-image1" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/eb83c2dd0982d6bc98685cc4f6fc4277_1656955448.png" alt="" /></figure>
                <figure class="imgs-collection-image2" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">
                <div class="section2-cta" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50"><a>SHOP NOW</a></div>
                <img src="https://tossdown.site/images/d9f0e6ab5745cb7486a6ea091e70edf1_1656955512.png" alt="" /></figure>
                <figure class="imgs-collection-image3" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/8964fb97b53baeed2f3a86c23f9b7e6e_1656955480.png" alt="" /></figure>
                </div>
                </div>
                </div>
                </div>
                </div>
                    
                    `
                        },


                        {
                          id:"banner9",
                          name:"Banner Ten",
                          height:"510px",
                          head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
 float: left;
            width: 100%;
            margin: 0px;
        background-color: #fff;
}
</style>
    `,
                         
                          code:` 
                         
                         
                          <style>
                        
                          .section-banner {
                            background-image: url("https://tossdown.site/images/0343f5c4dfea046923cb1b26073ce085_1657900323.png");
                            width:no 100%;
                            height: 650px;
                            background-position: center;
                            background-size: cover;
                            background-repeat: -repeat;
                            position: relative;
                          }
                    
                          .banner-section-container {
                            max-width: 1136px;
                            margin: 0px auto;
                          }
                          .partition-sections {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 50px;
                          }
                          .partition-section-upper {
                            padding: 42px 80px;
                          }
                          .banner-section-left-side img {
                            max-width: 100%;
                            max-height: 604px;
                          }
                          .inner-contant-banner {
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            justify-content: center;
                            height: 100%;
                            position: relative;
                          }
                          h2.heading-banner {
                            font-family: "Albra";
                            font-style: normal;
                            font-weight: 700!important;
                            font-size: 62px;
                            line-height: 62px;
                            text-transform: capitalize;
                            color: #ffffff;
                            padding-bottom: 61px;
                            z-index: 9;
                    margin-bottom:0px!important;
                          }
                          .heading-container {
                          }
                    
                          p.banner-para {
                            font-family: "Karla";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 18px;
                            color: rgba(255, 255, 255, 0.8);
                            padding-bottom: 31px;
                          }
                          a.banner-cta {
                            font-family: "Karla";
                            font-style: normal;
                            font-weight: 700;
                            font-size: 15px;
                            line-height: 18px;
                            text-transform: uppercase;
                            color: #000000;
                            background: #f6c114;
                            border-radius: 6px;
                            width: 172px;
                            height: 44px;
                            left: 836px;
                            text-decoration: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          }
                          span.tender-yellow {
                            color: #f6c114;
                          }
                          span.juicy-green {
                            color: #00a149;
                            position: relative;
                          }
                          span.juicy-green::after {
                            content: "";
                            background-image: url("https://tossdown.site/images/9b3693138db80041d3553f5a863c6332_1657900376.png");
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            left: -23px;
                            bottom: -50px;
                          }
                          .green-img {
                            position: absolute;
                            top: 110px;
                            left: -52px;
                          }
                          .upper-img{
                            position: absolute;
                          }
                          .upper-img1{
                            top:50%;
                            right: 0;
                          }
                          .upper-img2{
                            bottom: 30%;
                            left: 0;
                          }
                          .upper-img3{
                            bottom: 0px;
                            left: 50%;
                          }
                    .rotate {
                      animation: rotation 8s infinite linear;
                    }
                    
                    @keyframes rotation {
                      from {
                        transform: rotate(0deg);
                      }
                      to {
                        transform: rotate(359deg);
                      }
                    }
                    
                          @media (max-width:1024px) {
                    
                            h2.heading-banner {
                        font-size: 53px;
                    }
                            
                          }
                          @media (max-width:950px) {
                    
                    h2.heading-banner {
                    font-size: 40px;
                    }
                    .green-img {
                    display: none;
                    }
                    
                    .section-banner {
                        
                            height: 600px;
                           
                          }
                          span.juicy-green::after {
                        
                            left: 0px;
                           
                          }
                    }
                        @media (max-width:850px) {
                        .section-banner {
                        
                        height: 550px;
                       
                      }
                      .upper-img{
                           width: 90px;
                          }
                          p.banner-para {
                        font-size: 14px;
                    }
                    h2.heading-banner {
                        font-size: 38px;
                    }
                    
                    }
                    @media (max-width:750px) {
                        .section-banner {
                        
                        height: 100%;
                       
                      }
                      .partition-sections {
                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 50px;
                    }
                    .banner-section-left-side img {
                        max-width: 60%;
                        max-height: 604px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto;
                    }
                    .inner-contant-banner {
                       
                        align-items: center;
                      
                    }
                    .upper-img3 {
                        bottom: 0px;
                        left: 62%;
                    }
                    h2.heading-banner {
                        font-size: 30px;
                        padding-bottom: 22px;
                    }
                    
                    }
                    @media (max-width:588px) {
                    
                    
                    h2.heading-banner {
                        font-size: 30px;
                        padding-bottom: 22px;
                        margin: 0px!important;
                    }
                    p.banner-para {
                        font-size: 12px;
                    }   
                    img.upper-img.upper-img3 {
                        display: none;
                    }
                    span.juicy-green::after {
                            display: none;}
                            .partition-section-upper {
                        padding: 42px 51px;
                    }
                    .banner-section-left-side img {
                        max-width: 50%;
                        max-height: 604px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto;
                    }
                    }
                    
                    @media (max-width:468px) {
                    .banner-section-left-side img {
                        max-width: 40%;
                        max-height: 560px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 40px auto 0px auto;
                    }
                    
                    
                        img.upper-img.upper-img1 {
                      width: 50px;
                    }
                    img.upper-img.upper-img2 {
                        width: 50px;
                    }
                    p.banner-para {
                        font-size: 10px;
                    }
                    h2.heading-banner {
                        font-size: 20px;
                    }
                    .partition-section-upper {
                        padding: 0px;
                        padding-bottom: 30px;
                    }
                    }
                    
                        </style>
                    <div class="section-banner"><img class="upper-img upper-img1 " data-aos="fade-right" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/f9991826127465a235373a6f9d0212a3_1657900556.png" alt="" /> <img class="upper-img upper-img2" data-aos="fade-left" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/d9dc7f80aa431a48196839c97db31ea0_1657900600.png" alt="" /> <img class="upper-img upper-img3" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/87e8084b91afa561f947345cf9b0680f_1657900394.png" alt="" />
                    <div class="banner-section-container">
                    <div class="partition-section-upper">
                    <div class="partition-sections">
                    <div class="banner-section-left-side">
                    <figure><img data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/438b6c733146bd8bbe7b1f64302b1dea_1657900428.png" alt="" /></figure>
                    </div>
                    <div class="banner-section-right-side">
                    <div class="inner-contant-banner">
                    <div class="heading-container"><img class="green-img rotate" data-aos="zoom-out" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/b18d0cf8f614d4bf776e32198e4b918a_1657900340.png" alt="" />
                    <h2 class="heading-banner" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50">Bite into <span class="tender-yellow">Tender</span> <span class="juicy-green">Juicy</span> Goodness.</h2>
                    </div>
                    <p class="banner-para" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50">Serving the greatest burgers on the planet. Join us in burger heaven</p>
                    <a class="banner-cta" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50"> ORDER NOW </a></div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                      
                      `
                          },
                          
                        {
                          id:"banner10",
                          name:"Banner 11",
                          height:"510px",
                          head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  width: 100%;
  margin: 0px;
}
</style>
    `,
                         
                          code:` 
                         
                        
                          <style>
                        
                         .banner_section {
                           float: left;
                             width: 100%;
                             margin-bottom: 40px;
                             background-image: url("https://static.tossdown.com/images/45723548-43d5-4093-a93b-335ed194bef9.png");
                             width: 100%;
                             height: 100%;
                             background-repeat: no-repeat;
                             background-size: cover;
                         padding:0px 15px;
                             position: relative;
                             padding-top: 120px;
                             padding-bottom: 45px;
                         }
                         .banner_section:before{
                             position: absolute;
                             top: 0px;
                             left: 0px;
                                background: rgba(0,0,0,0.9);
                             width: 100%;
                             height: 100%;
                         content: "";
                         }
                         .custom_container {
                             max-width: 1140px;
                             margin: 0 auto;
                         }
                         .single_banner_slider{
                           float: left;
                             width: 100%;
                             margin-bottom:0px;
                         }
                         .Banner_slider {
                           overflow: hidden;
                           margin:0px;
                         }
                         .single_banner_slider_data {
                             display: flex;
                             flex-direction: column;
                             width: 100%;
                         }
                         .single_banner_slider_data figure {
                             display: flex;
                             width: 100%;
                             align-items: center;
                             justify-content: center;
                             margin: 0px;
                         padding-bottom: 40px;
                         }
                         .single_banner_slider_data figure img {
                             max-width: 724px;
                         }
                         .single_banner_slider_detail {
                             display: flex;
                             justify-content: space-between;
                             align-items: center;
                             width: 100%;
                         }
                         .single_banner_slider_detail_des {
                             display: flex;
                             flex-direction: column;
                             padding-right: 20px;
                         }
                         .single_banner_slider_detail_des small{
                         font-family: 'Epilogue';
                         font-style: normal;
                         font-weight: 600 !important;
                         font-size: 14px;
                         line-height: 14px;
                         text-transform: uppercase;
                         color: #FFFFFF;
                         padding-bottom: 12px;
                         }
                         .single_banner_slider_detail_des h1 {
                             font-family: 'Epilogue';
                             font-style: normal;
                             font-weight: 700 !important;
                             font-size: 40px;
                             line-height: 41px;
                             text-transform: uppercase;
                             color: #FFFFFF;
                             margin: 0px;
                         }
                         .single_banner_slider_detail_btn {
                             display: flex;
                             align-items: center;
                         }
                         .single_banner_slider_detail_btn .discover_btn{
                         background: #FFFFFF;
                         border-radius: 6px;
                         width:164px;
                         height:44px;
                         font-family: 'Epilogue';
                         font-style: normal;
                         font-weight: 600;
                         font-size: 13px;
                         line-height: 13px;
                         text-transform: uppercase;
                         color: #000000;
                             display: flex;
                             align-items: center;
                             justify-content: center;
                             margin-right: 18px;
                         }
                         .single_banner_slider_detail_btn .buy_btn{
                         border: 1.5px solid #FFFFFF;
                         background: transparent;
                         border-radius: 6px;
                         width:164px;
                         height:44px;
                         font-family: 'Epilogue';
                         font-style: normal;
                         font-weight: 600;
                         font-size: 13px;
                         line-height: 13px;
                         text-transform: uppercase;
                         color: #FFFFFF;
                             display: flex;
                             align-items: center;
                             justify-content: center;
                         }
                         .single_banner_slider_detail_btn .buy_btn:hover{
                         background: #FFFFFF;
                         color: #000000;
                         transition:0.3s ease-in-out;
                         }
                         @media(max-width:767px){
                         .single_banner_slider_detail {
                             flex-direction: column;
                             align-items: center;
                             text-align: center;
                         }
                         .single_banner_slider_detail_des {
                             padding-right: 0px;
                             padding-bottom: 20px;
                         }
                         }
                         @media(max-width:540px){
                         .single_banner_slider_detail_des h1 {
                             font-size: 30px;
                             line-height: 33px;
                         }
                         .single_banner_slider_detail_btn .discover_btn {
                             width: 130px;
                         }
                         .single_banner_slider_detail_btn .buy_btn {
                             width: 130px;
                         }
                         .single_banner_slider_detail_des small {
                             font-size: 12px;
                             line-height: 12px;
                             padding-bottom: 9px;
                         
                         }
                         }
                         
                         </style>
                         <section class="banner_section">
                         <div class="custom_container">
                         <div class="Banner_slider">
                         <div class="single_banner_slider">
                         <div class="single_banner_slider_data">
                         <figure><img src="https://static.tossdown.com/images/40ee706b-367d-4d96-80a8-2b63029b95ec.png" /></figure>
                         <div class="single_banner_slider_detail">
                         <div class="single_banner_slider_detail_des"><small>Lorem Ipsum</small>
                         <h1>fitness Bench 360°</h1>
                         </div>
                         <div class="single_banner_slider_detail_btn"><a class="discover_btn">Discover</a> <a  class="buy_btn">buy</a></div>
                         </div>
                         </div>
                         </div>
                         <div class="single_banner_slider"></div>
                         </div>
                         </div>
                         </section>
                         <script type="text/javascript">
                           $(document).ready(function() {      
                         $('.Banner_slider').slick({
                           dots: false,
                         arrows: false,
                           infinite: false,
                           speed: 500,
                           fade: true,
                           cssEase: 'linear'
                         });
                           }); 
                         
                         </script>
                      
                      `
                          },

                          {
                            id:"banner11",
                            name:"Banner 12",
                            height:"510px",
                            head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  width: 100%;
  margin: 0px;
background-color: #fff;
}
</style>
    `,
                           
                            code:` 
                          
                           
                            <style>
                           
                            .home_page_second_banner_section{
                                display: block;
                                width: 100%;
                                padding: 0px 15px 0px 15px;
                            background-color: #C75C36;
                                position: relative;
                            
                            }
                            
                            .off-img {
                                font-family: 'Montserrat';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 137.867px;
                                line-height: 168px;
                                text-transform: uppercase;
                                position: absolute;
                                right: 0;
                                bottom: 19px;
                                -webkit-text-stroke: 1px rgb(255 255 255);
                                color: transparent;
                            }
                            .custom_container{
                                max-width: 1140px;
                                margin: 0px auto;
                            }
                            
                            .home_page_second_banner_box{
                                display: grid;
                                grid-template-columns:1fr 1fr;
                                grid-template-rows: auto;
                                grid-template-areas: ". .";
                                gap: 40px 46px;
                                width: 100%;
                            align-items: center;
                            position: relative;
                            padding: 110px 0px 95px 0px;
                            }
                            
                            
                            .banner-img {
                                position: absolute;
                                right: 0;
                            bottom: 0;
                                z-index: 11;
                            }
                            .home_page_second_banner_figcaption{
                            display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                height: 100%;
                                padding-right: 100px;
                            }
                            .home_page_second_banner_figcaption small{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 25px;
                            text-transform: capitalize;
                            color: #000000;
                            
                            }
                            .home_page_second_banner_figcaption h4 {
                            padding-bottom: 36px;
                            margin:0px;
                            font-family: 'Jost';
                            font-style: normal;
                            font-weight: 800 !important;
                            font-size: 44px;
                            line-height: 50px;
                            text-transform: uppercase;
                            color: #FFFFFF;
                            }
                            .home_page_second_banner_figcaption p{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: #000000;
                            padding-bottom:40px;
                            margin:0px;
                            }
                            .application_download_links{
                                display: flex;
                                align-items: center;
                            padding-bottom: 12px;
                            }
                            .application_download_links-two{
                                display: flex;
                                align-items: center;
                            padding-bottom: 12px;
                            }
                            .application_download_links-two a {
                            font-family: 'Jost';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            text-transform: uppercase;
                            color: #FFFFFF;
                                 display: flex;
                                align-items: center;
                            background: #262B40;
                            width: 189px;
                            height: 44px;
                                justify-content: center;
                            color: #FFFFFF;
                                align-items: center;
                            
                            }
                            
                            
                            
                            
                            .application_download_links a {
                            font-family: 'Jost';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            text-transform: uppercase;
                            color: #FFFFFF;
                                 display: flex;
                                align-items: center;
                            background: #262B40;
                            width: 163px;
                            height: 44px;
                                justify-content: center;
                            color: #FFFFFF;
                                align-items: center;
                            
                            }
                            
                            .home_page_second_banner_figure {
                            
                                width: 517px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                            position: relative;
                            }
                            i.fal.fa-arrow-right.arrow {
                                margin-left: 8px;
                            }
                            .home_page_second_banner_figure_section{
                             display: flex;
                            flex-direction: column;
                            height: 100%;
                                align-items: center;
                                justify-content: center;
                            }
                            .home_page_second_banner_figure {
                            
                             
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                             
                                padding-top: 64px;
                                padding-bottom: 63px;
                                padding-left: 29px;
                                padding-right: 157px;
                                background-color: #AF3742;
                            }
                            .home_page_second_banner_figure_section img{
                             padding-bottom: 34px;
                            }
                            .home_page_second_banner_figure small {
                                font-family: 'Jost';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 23px;
                                text-transform: uppercase;
                                color: #FFFFFF;
                            }
                            .home_page_second_banner_figure h4 {
                                font-family: 'Jost';
                                font-style: italic;
                                font-weight: 700;
                                font-size: 54px;
                                line-height: 62px;
                                text-transform: uppercase;
                                color: #FFFFFF;
                            }
                            .home_page_second_banner_figure figure{
                                display: flex;
                            position:relative;
                             z-index: 11;
                            }
                            .home_page_second_banner_plate_figure{
                                position: absolute;
                             bottom: -33px;
                                left: -33px;
                                z-index: -1;
                            width: 383px;
                            }
                            .home_page_second_banner_carate_figure{
                                position: absolute;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                z-index: -1;
                                right: 130px;
                                content: "";
                                width: 100%;
                            }
                            @media(max-width:1024px){
                            .home_page_second_banner_figcaption {
                                padding-right: 32px;
                            }
                            .off-img {
                                font-family: 'Montserrat';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 110px;
                                line-height: 168px;
                                text-transform: uppercase;
                                position: absolute;
                                right: 0;
                                bottom: 19px;
                                -webkit-text-stroke: 1px rgb(255 255 255);
                                color: transparent;
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                                justify-content: space-around;
                                top: 0;
                            }
                            .home_page_second_banner_figure {
                                width: 460px;
                            }
                            .home_page_second_banner_figure h4 {
                                font-family: 'Jost';
                                font-style: italic;
                                font-weight: 700;
                                font-size: 38px;
                                line-height: 50px;
                                text-transform: uppercase;
                                color: #FFFFFF;
                            }
                            }
                            
                            @media  (max-width:768px){
                            .home_page_second_banner_figcaption {
                                position: relative;
                            }
                            .off-img {
                                font-family: 'Montserrat';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 110px;
                                line-height: 145px;
                                text-transform: uppercase;
                                position: absolute;
                                right: 0;
                                bottom: 19px;
                                -webkit-text-stroke: 1px rgb(255 255 255);
                                color: transparent;
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                                justify-content: center;
                                top: 0;
                                width: 100%;
                                align-items: center;
                            }
                            .home_page_second_banner_figure {
                                width: 100%;
                            }
                            .home_page_second_banner_figure {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                padding-top: 64px;
                                padding-bottom: 63px;
                                padding-left: 29px;
                                padding-right: 224px;
                                background-color: #AF3742;
                            }
                            .home_page_second_banner_figcaption {
                                padding-right: 0;
                            }
                            home_page_second_banner_figcaption{
                            overflow: hidden;
                            }
                            .home_page_second_banner_box {
                                grid-template-columns: 1fr;
                                grid-template-areas: ".";
                            }
                            .home_page_second_banner_figure_section{
                                justify-content: center;
                                padding-right: 0px;
                            }
                            .home_page_second_banner_box figure{
                            text-align:center;
                            }
                            .home_page_second_banner_figcaption {
                                align-items: center;
                                text-align: center;
                            }
                            .home_page_second_banner_figcaption p {
                                padding-bottom: 30px;
                            }
                            .home_page_second_banner_figcaption h4 {
                                font-size: 48px;
                                line-height: 57px;
                            }
                            }
                            @media  (max-width:540px){
                            
                            .home_page_second_banner_box {
                                padding: 60px 0px 50px 0px;
                            }
                            .home_page_second_banner_figcaption {
                                padding-right: 0;
                            }
                            
                            .home_page_second_banner_figure {
                                margin: 0 auto;
                                width: 100%;
                            }
                            .banner-img img {
                                height: 400px;
                            }
                            .off-img {
                                font-family: 'Montserrat';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 60px;
                                line-height: 83px;
                                text-transform: uppercase;
                                position: absolute;
                                right: 0;
                                bottom: 19px;
                                -webkit-text-stroke: 1px rgb(255 255 255);
                                color: transparent;
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                                justify-content: center;
                                top: 0;
                                width: 100%;
                                align-items: center;
                            }
                            .home_page_second_banner_figure {
                               
                                
                               
                                padding-right: 160px;
                               
                            }
                            .home_page_second_banner_plate_figure {
                                bottom: -19px;
                                left: -24px;
                                width: 287px;
                            }
                            .home_page_second_banner_carate_figure {
                                right: 87px;
                            }
                            .home_page_second_banner_figcaption h4 {
                                font-size: 36px;
                                line-height: 43px;
                            }
                            }
                            @media  (max-width:450px){
                            .home_page_second_banner_figure {
                                padding-right: 120px;
                                padding-top: 32px;
                                padding-bottom: 32px;
                            }
                            .home_page_second_banner_figure small {
                                font-size: 13px;
                                line-height: 20px;  
                            }
                            .home_page_second_banner_figure h4 {
                                font-family: 'Jost';
                                font-style: italic;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 34px;
                                text-transform: uppercase;
                                color: #FFFFFF;
                            }
                            .banner-img img {
                                height: 260px;
                            }
                            
                            
                            }
                            </style>
                            <section class="home_page_second_banner_section">
                            <div class="custom_container">
                            <div class="home_page_second_banner_box">
                            <div class=" off-img">
                            <p>20% OFF</p>
                            <p>20% OFF</p>
                            <p>20% OFF</p>
                            </div>
                            <div class="banner-img"><img src="https://tossdown.site/images/b6510c9bac39a3fb3852ad3461271fe5_1655367226.png" /></div>
                            <div class="home_page_second_banner_figcaption" style="overflow: hidden;">
                            <h4 data-aos-delay="50" data-aos="fade-right" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" style="overflow: hidden;" class="aos-init aos-animate">New arrivals have hit the store!</h4>
                            <div class="application_download_links"><a  data-aos-delay="50" data-aos="zoom-in-up" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" style="overflow: hidden;" class="aos-init aos-animate">Shop men <i class="fal fa-arrow-right arrow"></i></a></div>
                            <div class="application_download_links-two"><a data-aos-delay="50" data-aos="zoom-in-up" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" style="overflow: hidden;" class="aos-init aos-animate">Shop women <i class="fal fa-arrow-right arrow"></i></a></div>
                            <div class="application_download_links"><a data-aos-delay="50" data-aos="zoom-in-up" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" style="overflow: hidden;" class="aos-init aos-animate">Shop kids <i class="fal fa-arrow-right arrow"></i></a></div>
                            </div>
                            <div class="home_page_second_banner_figure"><small>MID Summer Sale</small>
                            <h4>UPTo 20% Off on all Brands</h4>
                            </div>
                            </div>
                            </div>
                            </section>
                        
                        `
                            },

                            {
                              id:"banner12",
                              name:"Banner 13",
                              height:"510px",
                              head:`
                              <head>
                              <meta charset="utf-8" />
                              <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1, shrink-to-fit=no"
                              /> 
                              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                              <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                              
                              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                          
                            <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                            <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                            
                            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                            
                          </head>
                          <style>
                          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                          *{
                            -web-kit-box-sizing: border-box;
                             -moz-box-sizing: border-box; 
                             box-sizing:border-box; 
                            padding:0px;
                            margin:0px;
                            }
                            html {
                            scroll-behavior: smooth;
                            }
                          </style>
                              `,
                            
                            
                              code:` 
                            
                             
                              <style>
                             
                              section.banner {
                                  padding-bottom: 240px;
                              }
                              
                              
                              
                              .banner-img {
                              background-image: url("https://tossdown.site/images/52666146f6183f56bc2a55a9d17bd9ab_1656428837.png");
                              max-width: 100%;height: 600px;
                              background-repeat: no-repeat;
                              background-size: cover;
                              background-position: center;
                              }
                              .banner-contant {
                              
                              text-align: center;
                              
                              
                              max-width: 1140px;
                              margin:0px auto;
                              height: 100%;
                              padding: 0px 135px 0px 135px;
                              position: relative;
                              display: flex;
                              text-align: center;
                              align-items: center;
                              flex-direction: column;}
                              h2.banner-heading {
                                  font-family: 'Pally';
                                  font-style: normal;
                                  font-weight: 700!important;
                                  font-size: 88px;
                                  line-height: 119px;
                                  display: flex;
                                  align-items: center;
                                  color: #FFFFFF;
                                  padding-top: 80px;
                                  padding-bottom: 25px;
                                  z-index: 1;
                              }
                              
                              
                              .banner-pizzz-img{
                                  width: 100%;
                              }
                              .banner-pizzz-img img{
                                  max-width:588px;
                                  height: 588px;
                              }
                              
                              /* identical to box height, or 131% */
                              a.banner-cta {
                                  font-family: 'Pally';
                                  font-style: normal;
                                  font-weight: 700;
                                  font-size: 14px;
                                  line-height: 19px;
                                  display: flex;
                                  align-items: center;
                                  text-transform: uppercase;
                                  color: #FFFFFF;
                                  background: #1E2531;
                                  border-radius: 22px;
                                  width: 172px;
                                  height: 44px;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  text-decoration: none;
                                     z-index: 2;
                              
                              }
                              .banner-pizzz-img {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                              }
                              .rotate {
                                animation: rotation 8s infinite linear;
                              }
                              
                              @keyframes rotation {
                                from {
                                  transform: rotate(0deg);
                                }
                                to {
                                  transform: rotate(359deg);
                                }
                              }
                              .btn-class {
                              width: 100%;
                              display: flex;
                              justify-content: center;
                              }
                              .group-imges-top{
                              position: absolute;
                              top: 50px;
                              left: 0px;
                              z-index: 0;
                              max-width: 100%;
                              }
                              .group-imges-top img{
                              width: 100%;}
                              .blub-two
                              {
                              
                              position: absolute;
                              bottom:  -100px;
                              left: 100px;
                              max-width: 100%;
                              
                              
                              }
                              
                              
                              .blub-two img{
                                  width: 100%;
                              }
                              .blub-three{
                              position: absolute;
                              bottom:  -100px;
                              right: 100px;
                              max-width: 100%;
                              
                              
                              
                              }
                              
                              
                              
                              @media (max-width:1093px) {
                                  h2.banner-heading{
                                      font-size: 80px;
                                  }
                              }
                              @media (max-width:1024px) {
                              section.banner {
                                  padding-bottom: 146px;
                              }
                                  .banner-pizzz-img img{
                                  max-width:500px;
                                  height: 500px;
                              
                                  }
                                  h2.banner-heading{
                                      font-size: 70px;
                                  }
                              }
                              
                              @media (max-width:926px) {
                              section.banner {
                                  padding-bottom: 120px;
                              }
                                  h2.banner-heading{
                                      font-size: 60px;
                                  }
                                  .banner-pizzz-img img{
                                  max-width:450px;
                                  height: 450px;
                              
                                  }
                                  .blub-two img{
                              width: 80%;
                                  }
                                  .blub-three img{
                                      width: 80%;
                                  }
                              }
                              
                              @media (max-width:835px) { 
                                  h2.banner-heading {
                                  font-size: 50px;
                              }
                              a.banner-cta{
                                  width: 150px;
                                  height: 40px;
                              }
                              
                              .blub-two
                              {
                              
                              position: absolute;
                              top:150px;
                              left: 40px;
                              max-width: 100%;
                              
                              
                              }
                              
                              
                              
                              .blub-three{
                              position: absolute;
                              top: 150px;
                              right: 40px;
                              max-width: 100%;
                              
                              
                              
                              }
                              }
                              @media (max-width:745px) { 
                                  h2.banner-heading {
                                  font-size: 40px;
                                  padding-bottom: 0px!important;
                                  padding-top: 10px!important;
                              }
                              a.banner-cta{
                                  margin-bottom: 30px;   
                              }
                              .banner-pizzz-img {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                              }
                              .banner-pizzz-img img{
                                  width: 400px;
                                  height: 400px;
                              }
                              .banner-img {
                              
                                  height: 450px!important;
                                
                              } 
                              
                              
                                  
                              }
                              @media (max-width:650px) {
                              
                              
                                  .banner-contant{
                                      padding: 0px!important
                                      ;
                                  }
                                  .blub-two img{
                              width: 60%;
                                  }
                                  .blub-three img{
                                      width: 60%;
                                  }
                                  .blub-two
                              {
                              
                              position: absolute;
                              top:150px;
                              left: 20px;
                              max-width: 100%;
                              }
                              .blub-three{
                              position: absolute;
                              top: 150px;
                              right: 20px;
                              max-width: 100%;
                              
                              
                              
                              }
                              }
                              @media(max-width: 440px){
                              .banner-pizzz-img img {
                                  width: 320px;
                                  height: 320px;
                              }
                              }
                              @media (max-width:400px) 
                              {   
                                  .banner-img {
                                  height: 400px!important;
                              }
                              h2.banner-heading{
                                  font-size:30px;
                              }
                              .banner-pizzz-img img{
                                  width: 300px;
                                  height: 300px;
                              }
                              .blub-two
                              {
                              
                              display: none;
                              }
                              .blub-three{
                              
                              display: none;
                              
                              
                              
                              }
                              }
                              
                              @media(max-width: 380px){
                              .banner-pizzz-img img {
                                  width: 250px;
                                  height: 250px;
                              }
                              }
                              
                              
                               </style>
                              <section class="banner">
                              <div class="banner-img">
                              <div class="banner-contant">
                              <div class="group-imges-top" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-delay=" 500" data-aos-offset="50"><img src="https://tossdown.site/images/f9d8872294161837ca872b6d88a2c967_1656675613.png" alt="" /></div>
                              <div class="blub-two" data-aos="fade-right" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50"><img class="rotate" src="https://tossdown.site/images/262754236970beeae84d85d2a19ff625_1656675632.png " alt="" /></div>
                              <div class="blub-three" data-aos="fade-left" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" offset="50"><img class="rotate" src="https://tossdown.site/images/4f29a5eee206158f0d03186b1db9aecd_1656675648.png" alt="" /></div>
                              <h2 class="banner-heading" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">Fast. Fresh. Delicious</h2>
                              <div class="btn-class" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1500" data-aos-once="true" data-aos-offset="50"><a class="banner-cta" href="/shop">Order Now</a></div>
                              <div class="banner-pizzz-img" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true
                              
                              " data-aos-offset="50" data-aos-delay="700"><img class="rotate" src="https://tossdown.site/images/faaa074de33baff0eea322b70cf32c21_1656428861.png" alt="" /></div>
                              </div>
                              </div>
                              </section>
                          
                          `
                              },

          ,

    ]
    export default BannerObjects