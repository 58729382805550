import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./CSS-Files/Library.css";
import "./CSS-Files/dashboard.css";
import "./CSS-Files/EditorStyle.css";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home";
import Sidebar from "./Sidebar/Sidebar";
import FooterDesign from "./Assests/FooterDesign/FooterDesign";
import NewsletterDesign from "./Assests/NewsletterDesign/NewsletterDesign";
import ResponsiveToolFooter from "./Components/Footer/ResponsiveToolFooter";
import BannerDesign from "./Assests/BannerDesign/BannerDesign";
import ContactPageDesign from "./Assests/StaticPages/ContactPages/ContactPageDesign";
import ProductCategorySectionDesign from "./Assests/ProductCategorySectionDesign/ProductCategorySectionDesign";
import TestimonialDesign from "./Assests/TestimonialDesign/TestimonialDesign";
import AboutPageDesign from "./Assests/StaticPages/AboutPage/AboutPageDesign";
import PrivacyPolicyPageDesign from "./Assests/StaticPages/privacy Policy Page/PrivacyPolicyPageDesign";
import BrandSectionDesign from "./Assests/BrandSectionDesign/BrandSectionDesign";
import InstagramFeedsDesign from "./Assests/InstagramFeedsDesign/InstagramFeedsDesign";
import HomePageCustomSectionsDesign from "./Assests/Custom Sections/HomePageCustomSectionsDesign/HomePageCustomSectionsDesign";
import DetailPageDesign from "./Assests/DetailPage/DetailPageDesign/DetailPageDesign";
import DetailPageAssets from "./Assests/DetailPage/DetailPageAssets/DetailPageAssets";
import HeaderDesign from "./Assests/HeaderDesign/HeaderDesign";
import NewCustomSectionDesign from "./Assests/Custom Sections/NewCustomSection/NewCustomSectionDesign";
import BlogSection from "./Assests/Custom Sections/Blog Section/BlogSection";

function App() {



  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
          <Sidebar />
          <div className="content-section">
            <Header />
            <Home name="/ Home" />
            <ResponsiveToolFooter />
          </div>
          </Route>
         
          <Route path="/hearder" >
          <Sidebar />
          <div className="content-section">
            <Header />
            <HeaderDesign name="/ Hearder"  />
            <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/footer" >
          <Sidebar />
          <div className="content-section">
            <Header />
            <FooterDesign name="/ Footer" />
            <ResponsiveToolFooter />
          </div>
          </Route>
         
          <Route path="/Newsletter" >
          <Sidebar />
          <div className="content-section">
            <Header />
            <NewsletterDesign name="/ Newsletter" />
            <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/Banner" >
          <Sidebar />
          <div className="content-section">
            <Header />
             <BannerDesign name="/ Banner" />
             <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/Contact" >
          <Sidebar />
          <div className="content-section">
            <Header />
            <ContactPageDesign name="/ Contact" />
            <ResponsiveToolFooter />
          </div>
          </Route>
          <Route path="/Product-Category-Section" >
          <Sidebar />
          <div className="content-section">
            <Header />
            <ProductCategorySectionDesign name="/ Category Section"  />
            <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/Testimonials" >
          <Sidebar />
          <div className="content-section">
            <Header />
             <TestimonialDesign name="/ Testimonials Design" />
             <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/about" >
          <Sidebar />
          <div className="content-section">
            <Header />         
            <AboutPageDesign name="/ About Page Design" />
            <ResponsiveToolFooter />
          </div>
          </Route>


          <Route path="/privacypolicy" >
          <Sidebar />
          <div className="content-section">
            <Header />          
            <PrivacyPolicyPageDesign name="/ Privacy Policy Page Design" />
            <ResponsiveToolFooter />
          </div>
          </Route>

          
          <Route path="/Brand-Section" >         
          <Sidebar />
          <div className="content-section">
            <Header />   
            <BrandSectionDesign name="/ Brand Section" />
            <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/Instagram-Feeds" >
          <Sidebar />
          <div className="content-section">
            <Header />            
            <InstagramFeedsDesign name="/ Instagram Feeds" />
            <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/Home-Page-Custom-Section" >  
          <Sidebar />
          <div className="content-section">
            <Header />        
            <HomePageCustomSectionsDesign name="/ Home Page Custom Section" />
            <ResponsiveToolFooter />
          </div>
          </Route>

          <Route path="/new-custom-section" >
          <Sidebar />
          <div className="content-section">
            <Header />    
             <NewCustomSectionDesign name="/ New Custom Section" />  
             <ResponsiveToolFooter />
          </div>      
          </Route>

          <Route path="/blog-section" >
          <Sidebar />
          <div className="content-section">
            <Header />    
            <BlogSection name="/  Blog Section" /> 
            <ResponsiveToolFooter />
          </div>      
          </Route>

          <Route path="/detail-page-designs" >
          <Sidebar />
          <div className="content-section">
            <Header />    
            <DetailPageDesign name="/ Detail Page Designs" />            
            <ResponsiveToolFooter />
          </div>
          </Route>
          <Route path="/detail-page-assets" >
          <Sidebar />
          <div className="content-section">
            <Header />
            <DetailPageAssets name="/ Detail Page Assets" />         
            <ResponsiveToolFooter />
          </div>
          </Route>
          

        </Switch>
      </Router>
   
      
    </>
  );
}

export default App;
