import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
//import SidebarCustomJs from "../../Custom-Js-files/SidebarCustomJs";
const SidebarContent = () => {
  
  React.useEffect(() => {
    $(function ($) {
      var $question = $(".single-sidebar-menu-list");
      var $answer = $(".single-menu-data-box");
      $question.click(function () {
        // Hide all answers
        $answer.slideUp();
  
        // Check if this answer is already open
        if ($(this).hasClass("open")) {
          // If already open, remove 'open' class and hide answer
          $(this).removeClass("open").next($answer).slideUp();
          // If it is not open...
        } else {
          // Remove 'open' class from all other questions
          $question.removeClass("open");
          // Open this answer and add 'open' class
          $(this).addClass("open").next($answer).slideDown();
        }
      });
    });

   //for inner btn toggle

   $(function ($) {
    var $question_two = $(".single-menu-data-list-tag a");
    var $answer_two = $(".inner-content-list");

    $question_two.click(function () {
      // Hide all answers
      $answer_two.slideUp();

      // Check if this answer is already open
      if ($(this).hasClass("open")) {
        // If already open, remove 'open' class and hide answer
        $(this).removeClass("open").next($answer_two).slideUp();
        // If it is not open...
      } else {
        // Remove 'open' class from all other questions
        $question_two.removeClass("open");
        // Open this answer and add 'open' class
        $(this).addClass("open").next($answer_two).slideDown();
      }
    });
  });
  $(".hamburger_img").click(function () {
    $(".dashboard-sidebar").toggleClass("active");
    $(".content-section").toggleClass("active");
  });
  

  },[])

  return (
    <>
      {/*  sidebar start  */}
      <div className="dashboard-sidebar">
            <div className="dashboard-sidebar-logo">
                <img src="image/last1.png" alt="logo" />
              </div>
        <div className="sidebar-menu-list">
          {/* <div className="sidebar-menu-list-box p-tb-20 d-n res-header-nav-list-btn">
            <div className="single-sidebar-menu-list">
              <span>Component list</span>
              <div className="expand-bar"></div>
            </div>
            <div className="single-menu-data-box">
              <div className="res-header-nav-list-box d-f f-d-c p-lr-45">
                <span className="p-t-15">component</span>
                <span className="p-t-15">Templates</span>
                <span className="p-t-15">Faq's</span>
              </div>
            </div>
          </div> */}

          <div className="sidebar-menu-list-box-header"><span>Components</span></div>

          <div className="sidebar-menu-list-box">
            <div className="single-sidebar-menu-list">
              <span>Ecommerce Components</span>
              <i className="ri-arrow-down-s-line"></i>
              {/* <div className="expand-bar"></div> */}
            </div>
            <div className="single-menu-data-box">

            <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/hearder'>Headers</Link>
                
                </div>
               
              </div> 

            <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/Banner'>Banners</Link>
                
                </div>
               
              </div> 

              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/Product-Category-Section'>Product Category Section</Link>
                
                </div>
               
              </div> 

              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/Brand-Section'>Brand Section</Link>
                
                </div>
               
              </div> 


              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/Testimonials'>Testimonials</Link>
                
                </div>
               
              </div> 

              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/Instagram-Feeds'>Instagram Feeds</Link>
                
                </div>
               
              </div> 

              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/Newsletter'>Newsletters</Link>
                
                </div>
               
              </div>

            <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag ">
                  <Link to='/footer'>Footers</Link>
                
                </div>
               
              </div>


            </div>
          </div>



          <div className="sidebar-menu-list-box">
            <div className="single-sidebar-menu-list">
              <span>Static Pages</span>
              <i className="ri-arrow-down-s-line"></i>
              {/* <div className="expand-bar"></div> */}
            </div>
            <div className="single-menu-data-box">

            <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag  ">
                  <Link to='/contact'>Contact Pages</Link>
                
                </div>
               
              </div>

              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag  ">
                  <Link to='/about'>About Page</Link>
                
                </div>
               
              </div>

              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag  ">
                  <Link to='/privacypolicy'>Privacy Policy Page</Link>
                
                </div>
               
              </div>

            </div>
          </div>


          <div className="sidebar-menu-list-box-header"><span>Pages</span></div>

           <div className="sidebar-menu-list-box">
            <div className="single-sidebar-menu-list">
              <span>Custom Section</span>
              <i className="ri-arrow-down-s-line"></i>
              {/* <div className="expand-bar"></div> */}
            </div>
            <div className="single-menu-data-box">

            <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag  ">
                  <Link to='/Home-Page-Custom-Section'>Home Page Custom Section</Link>
                
                </div>               
              </div>

              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag  ">
                  <Link to='/new-custom-section'>New Custom Section</Link>

                </div>
               
              </div>

              
              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag  ">
                  <Link to='/blog-section'>Blog Section</Link>

                </div>
               
              </div>

            </div>
          </div> 


          <div className="sidebar-menu-list-box">
            <div className="single-sidebar-menu-list">
              <span>Detail Pages</span>
              <i className="ri-arrow-down-s-line"></i>
              {/* <div className="expand-bar"></div> */}
            </div>
            <div className="single-menu-data-box">

            <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag">
                  <Link to='/detail-page-designs'>Detail Page designs</Link>
                
                </div>
               
               
              </div>



              <div className="single-menu-data-list">
                
                <div className="single-menu-data-list-tag">
                  <Link to='/detail-page-assets'>Detail Page Assets</Link>
                
                </div>
               
              </div>

            </div>
          </div> 


         {/* <div className="sidebar-menu-list-box">
            <div className="single-sidebar-menu-list">
              <span>Static Pages</span>
              <div className="expand-bar"></div>
            </div>
            <div className="single-menu-data-box">
              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag ">
                  <span>Contact Pages</span>
                  <div className="inner-expand-bar"></div>
                </div>
                <div className="inner-content-list">
                  <div className="inner-content-list-data">
                    <span>Input Designs</span>
                  </div>
                </div>
              </div>
              <div className="single-menu-data-list">
                <div className="single-menu-data-list-tag ">
                  <span>Form</span>
                  <div className="inner-expand-bar"></div>
                </div>
                <div className="inner-content-list">
                  <div className="inner-content-list-data">
                    <span>Input Designs</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
 */}



        </div>

        
      </div>
      {/*  sidebar End  */}
    </>
  );
};

export default SidebarContent;
