const DetailPageObjects=[
    {
    id:"detailpage0",
    name:"Detail Page One",
    height:"600px",
    code:` 
    
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
    </head>

    <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-family: 'Poppins', sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    margin: 0px;
    width: 100%;
    background-color:#fff;
  }
    </style>
    <style>
  .product_detail_page_section {
    display: block;
    width: 100%;
    padding: 50px 50px;
  }
  .detail_page_container {
    max-width: 1200px;
    margin: 0px auto;
  }
  
  .product_detail_page_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". .";
    gap: 30px 30px;
    width: 100%;
  }
  .product_detail_page_figure_box {
    float: left;
    width: 100%;
  }
  .product_detail_page_figure_box figure {
    width: 100%;
      position: relative;
      margin: 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background:#FAFAFA;
      padding: 10px;
  }
  .product_detail_page_figure_box figure img {
    float: left;
    width: 100%;
    max-height: 500px;
    height: 500px;
    object-fit: contain;
  }
  
  /* detail page content side css start */
  
  .product_detail_page_box {
    float: left;
    width: 100%;
    height: 100%;
  }
  
  .product_detail_page_box_section {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .product_detail_page_box_header {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .product_detail_page_category {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #9b9bb4;
    padding-bottom: 5px;
  }
  .product_detail_page_heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .product_detail_page_heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 0px !important;
    padding-right: 20px;
  }
  .product_detail_page_heading .product_detail_page_wish_list {
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  text-decoration: none;
  }
  .product_detail_page_heading .product_detail_page_wish_list i {
    font-size: 28px;
    color: #000000;
  }
  .product_detail_page_box_content_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
  }
  .product_detail_page_box_content_list p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 10px;
    margin: 0px;
  }
  .product_detail_page_box_content_list strong {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 10px;
  }
  .product_detail_page_box_content_list ul {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 10px 10px;
    margin-bottom: 0px !important;
  }
  .product_detail_page_box_content_list ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 10px;
  }
  .product_detail_page_box_content_list ul li:last-child {
    padding-bottom: 0px;
  }
  .product_detail_page_box_content_list ul li::marker {
    font-size: 16px;
    color: #2cbef8;
  }
  
  .product_detail_page_box_extras {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
    flex-wrap: wrap;
      padding-bottom: 15px;
  }
  .detail_page_box_extras_list {
    display: flex;
    align-items: center;
    padding: 15px 15px 0px 0px;
  }
  .detail_page_box_extras_list:last-child {
    padding-right: 0px;
  }
  
  .detail_page_box_extras_list strong {
    font-weight: 600 !important;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #000000;
    padding-right: 5px;
  }
  .detail_page_box_extras_list span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    color: #000000;
  }
  .product_detail_page_box_prices {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    align-items: center;
    flex-wrap: wrap;
  }
  .product_detail_page_box_prices strong {
    font-style: normal;
    font-weight: bold !important;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #000000;
    padding-right: 10px;
  }
  .product_detail_page_box_prices .text_decoration_line_through {
    font-size: 16px;
    font-weight: 600;
  }
  .product_detail_page_box_prices span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    text-transform: capitalize;
    color: #9b9bb4;
  }
  .product_detail_page_box_cart_btns_with_outofstock {
    display: flex;
    flex-direction: column;
  }
  .cart_product_outofstock {
    float: left;
    width: 100%;
    display: block;
    padding-bottom: 20px;
  }
  
  .cart_product_outofstock h3 {
    color: #ee4430;
    margin: 0px;
    font-size: 25px;
  }
  
  .product_detail_page_box_cart_btns {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
  }
  .product_detail_page_quantity_plus_minu_btns {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    padding: 5px 4px;
  }
  .product_detail_page_quantity_plus_minu_btns span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #000000;
    padding: 0px 15px;
  }
  .product_detail_page_quantity_plus_minu_btns .increment_btn {
    width: 36px;
    height: 36px;
    background: #2cbef8;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product_detail_page_quantity_plus_minu_btns .decrement_btn {
    width: 36px;
    height: 36px;
    background: #f5f5f5;
    border-radius: 50%;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product_detail_page_add_to_cart_btn {
    width: 100%;
    padding-left: 20px;
  }
  .product_detail_page_add_to_cart_btn a {
    background: #233995;
    border-radius: 40px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff !important;
  }
  .product_detail_page_box_social_links {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
  .product_detail_page_box_social_links h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #9b9bb4;
    margin-bottom: 0px !important;
    padding-bottom: 15px;
  }
  .product_detail_page_socail_links_list {
    display: flex;
    align-items: center;
  }
  .product_detail_page_socail_links_list a {
    color: #ffffff;
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
  }
  .product_detail_page_socail_links_list a:last-child {
    margin-right: 0px;
  }
  .product_detail_page_socail_links_list a i {
    color: #ffffff;
    font-size: 20px;
  }
  
  
  /* detail page content side css end */
  
  @media(max-width: 1024px) {
  
  }
  
  @media (max-width: 768px) {
    .product_detail_page_section {
    padding: 50px 15px;
  }
    .product_detail_page_figure_box figure img {
      max-height: unset;
      height: unset;
  }
  .detail_page_container {
      max-width: 540px;
      margin: 0px auto;
    }
    .product_detail_page_content {
      grid-template-columns: 1fr;
      grid-template-areas: ".";
    }
  }
  
  @media (max-width: 540px) {
  }
  
  
  
      </style>
  
      <section class="product_detail_page_section">
        <div class="detail_page_container">
          <div class="product_detail_page_content">
            <div class="product_detail_page_figure_box">
              <figure>
                <img src="https://static.tossdown.com/images/c1292ab5-1b84-4014-99d8-626feb1cf231.jpg" />
              </figure>
            </div>
  
            <div class="product_detail_page_box">
              <div class="product_detail_page_box_section">
                <div class="product_detail_page_box_header">
                  <small class="product_detail_page_category">Tea & coffee</small>
                  <div class="product_detail_page_heading">
                    <h2>Tim horton french vanila cappuccino</h2>
                    <a class="product_detail_page_wish_list"><i class="far fa-heart"></i></a>
                  </div>
                </div>
  
                <div class="product_detail_page_box_content_list">
                  <p>
                    please indicate how the chicken should be cut (16 pieces
                    (Karahi), 12 pieces, 8 pieces, Whole chicken (do not cut).
                    Please also provide any further instructions on how to process
                    your order in comments .
                  </p>
                  <strong><span>Note:</span> The approximate price of one chicken is $8.99. Price of
                    each chicken varies depending on weight of the chicken. The
                    actual is calculated at $2.99 per LB at the time we are
                    processing your order</strong>
                  <ul>
                    <li>Convenient 16 Ounce container</li>
                    <li>
                      Makes a cup of rich, delicious frothy flavored cappuccino
                      anytime
                    </li>
                    <li>
                      Mix with hot water and treat yourself to the smooth, creamy
                      French vanilla flavor
                    </li>
                    <li>Ideal for Automatic Coffee Makers</li>
                    <li>A perfect way to relax and indulge</li>
                  </ul>
                </div>
  
                <div class="product_detail_page_box_extras">
                  <div class="detail_page_box_extras_list">
                    <strong>Weight :</strong><span>454g</span>
                  </div>
                  <div class="detail_page_box_extras_list">
                    <strong>Brand :</strong><span>Tim Hortons</span>
                  </div>
                </div>
  
                <div class="product_detail_page_box_prices">
                  <strong>CAD 50.70</strong>
                  <strong class="text_decoration_line_through">CAD 8.99</strong>
                  <span>10 % OFF</span>
                </div>
  
                <div class="product_detail_page_box_cart_btns_with_outofstock">
                  <div class="cart_product_outofstock"><h3>Out of Stock</h3></div>
                  <div class="product_detail_page_box_cart_btns">
                    <div class="product_detail_page_quantity_plus_minu_btns">
                      <i class="far fa-minus decrement_btn"></i><span>0</span
                      ><i class="fal fa-plus increment_btn"></i>
                    </div>
                    <div class="product_detail_page_add_to_cart_btn">
                      <a>Add to cart</a>
                    </div>
                  </div>
                </div>
  
                <div class="product_detail_page_box_social_links">
                  <h5>Share using:</h5>
                  <div class="product_detail_page_socail_links_list">
                    <a style="background: #425893"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                    <a style="background: #4d9eea"
                      ><i class="fab fa-twitter"></i
                    ></a>
                    <a style="background: #3375b0"><i class="fas fa-info"></i></a>
                    <a style="background: #65d072"
                      ><i class="fab fa-whatsapp"></i
                    ></a>
                    <a
                      style="
                        background: linear-gradient(
                          216.61deg,
                          #a232c1 7.99%,
                          #f24d57 46.49%,
                          #ffbb4f 85.9%
                        );
                      "
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

`
    },


    {
        id:"detailpage1",
        name:"Detail Page Two",
        height:"600px",
        code:` 
        
        <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        /> 
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
        
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
        </head>
    
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        * {
        -web-kit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        font-family: 'Poppins', sans-serif;
      }
      html {
        scroll-behavior: smooth;
      }
      body {
        float: left;
        margin: 0px;
        width: 100%;
        background-color:#fff;
      }
        </style>

        <style>
     .product_detail_page_section {
  display: block;
  width: 100%;
  padding: 50px 50px;
}
.detail_page_container {
  max-width: 1200px;
  margin: 0px auto;
}

.product_detail_page_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". .";
  gap: 30px 30px;
  width: 100%;
}
.product_detail_page_figure_box {
  float: left;
  width: 100%;
}
.product_detail_page_figure_box figure {
  width: 100%;
    position: relative;
    margin: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#FAFAFA;
    padding: 10px;
}
.product_detail_page_figure_box figure img {
  float: left;
  width: 100%;
  max-height: 500px;
  height: 500px;
  object-fit: contain;
}

/* detail page content side css start */
ul.breadcrumbs {
    padding: 25px 0px 25px 0px;
    list-style: none;
    background-color: transparent;
    text-align: left !important;
    margin: 0px !important;
}
ul.breadcrumbs li {
    display: inline;
    font-size: 16px;
    color: rgba(3, 36, 45, 0.5) !important;
}
ul.breadcrumbs li a {
    color: inherit;
    text-decoration: none;
}
ul.breadcrumbs li+li:before {
    color: rgba(3, 36, 45, 0.5) !important;
}
ul.breadcrumbs li a:hover {
    color: #0E6A38;
    text-decoration: none;
}
.product_detail_page_box {
  float: left;
  width: 100%;
  height: 100%;
}

.product_detail_page_box_section {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.product_detail_page_box_header {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 20px;
}
.product_detail_page_category {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #9b9bb4;
  padding-bottom: 5px;
}
.product_detail_page_heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.product_detail_page_heading h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 0px !important;
  padding-right: 20px;
}
.product_detail_page_heading .pro-wishlist-detail {
    position: unset !important;
}
.product_detail_page_heading .pro-wishlist-detail li.pro-wishlist-li {
    padding: 0px !important;
    margin: 0px !important;
}
.product_detail_page_heading .pro-wishlist-detail li.pro-wishlist-li i {
    font-size: 25px !important;
}
.product_detail_page_box_content_list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
}
.product_detail_page_box_content_list h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 10px;
  }
.product_detail_page_box_content_list p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 10px;
  margin: 0px;
}
.product_detail_page_box_content_list strong {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 10px;
}

.product_detail_page_box_content_list ul {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 10px 10px;
  margin-bottom: 0px !important;
}
.product_detail_page_box_content_list ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 10px;
}
.product_detail_page_box_content_list ul li:last-child {
  padding-bottom: 0px;
}
.product_detail_page_box_content_list ul li::marker {
  font-size: 16px;
  color: #2cbef8;
}
.detail_page_option_set{
  margin-bottom: 15px !important;
}

.product_detail_page_box_extras {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
  flex-wrap: wrap;
    padding-bottom: 15px;
}
.detail_page_box_extras_list {
  display: flex;
  align-items: center;
  padding: 15px 15px 0px 0px;
}
.detail_page_box_extras_list:last-child {
  padding-right: 0px;
}

.detail_page_box_extras_list strong {
  font-weight: 600 !important;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
  padding-right: 5px;
}
.detail_page_box_extras_list span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
}
.product_detail_page_box_prices {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.product_detail_page_box_prices strong {
  font-style: normal;
  font-weight: bold !important;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  color: #000000;
  padding-right: 10px;
}
.product_detail_page_box_prices .text_decoration_line_through {
  font-size: 16px;
  font-weight: 600;
}
.product_detail_page_box_prices span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-transform: capitalize;
  color: #9b9bb4;
}
.product_detail_page_box_cart_btns_with_outofstock {
  display: flex;
  flex-direction: column;
}
.product_detail_page_outofstock {
  width: 100%;
  display: block;
}

.product_detail_page_outofstock h3 {
  font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 15px !important;
    text-transform: uppercase;
    color: #BE3041;
    margin: 0px !important;
}
.product_detail_page_special_instructions{
  width: 100%;
  display: block;
}
.product_detail_page_special_instructions p{
  width: 100%;
  display: block;
  margin: 0px !important;
    font-size: 16px !important;
    line-height: 15px !important;
color: #000;
  }

.product_detail_page_box_cart_btns {
  display: flex;
  align-items: center;
  width: 100%;
}
.counter_btns_det {
    display: flex !important;
    align-items: center;
    border: 1px solid #404040 !important;
    border-radius: 4px !important;
    padding: 5px 4px !important;
    flex-wrap: unset !important;
    margin: 0px !important;
}
.counter_minus_btn a {
  width: 36px !important;
    height: 36px !important;
    background: #F6F6F6;
    color: #000000 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    border-radius: 4px !important;
}
.counter_qty span{
  padding: 0px 15px;
    font-weight: 700;
    font-size: 16px;
}
.counter_plus_btn a {
    width: 36px !important;
    height: 36px !important;
    background: #000000;
    border-radius: 4px !important;
    color: #ffffff !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
}

.product_detail_page_add_to_cart_btn {
  width: 100%;
  padding-left: 20px;
}
.product_detail_page_add_to_cart_btn a {
  height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff !important;
    border: 1px solid #3BB54A !important;
    background: #3BB54A;
    border-radius: 4px;
}

.product_detail_page_box_social_links {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.product_detail_page_box_social_links label {
    margin: 0px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #9b9bb4;
    margin-bottom: 0px !important;
    padding-bottom: 15px;
}

.product_detail_page_box_social_links .share-button {
    gap: 0px;
    width: unset !important;
}
.product_detail_page_box_social_links .share-button a {
    color: #ffffff;
    display: flex !important;
    width: 40px !important;
    height: 40px !important;
    align-items: center;
    justify-content: center;
    border-radius: 6px !important;
    border: unset !important;
    margin-right: 15px;
}
.product_detail_page_box_social_links .share-button a:last-child{
  margin-right: 0px;
}
/* detail page content side css end */

@media(max-width: 1024px) {

}

@media (max-width: 768px) {
  .product_detail_page_section {
  padding: 50px 15px;
}
  .product_detail_page_figure_box figure img {
    max-height: unset;
    height: unset;
}
.detail_page_container {
    max-width: 540px;
    margin: 0px auto;
  }
  .product_detail_page_content {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}

@media (max-width: 540px) {
}



    </style>

    <section class="product_detail_page_section">
      <div class="detail_page_container">
        <div class="product_detail_page_content">
          <div class="product_detail_page_figure_box">
            <figure>
              <img src="https://static.tossdown.com/images/c1292ab5-1b84-4014-99d8-626feb1cf231.jpg" />
            </figure>
          </div>

          <!-- content side html start -->
          <div class="product_detail_page_box">
            <div class="product_detail_page_box_section">
              <div class="product_detail_page_box_header">
                <small class="product_detail_page_category">{{category}}</small>
                <div class="product_detail_page_heading">
                  <h2>{{name}}</h2>
                  {{wishlist}}
                </div>
              </div>
              <div class="product_detail_page_box_content_list">
                {{short_desc}}
              </div>
              <div class="detail_page_option_set">{{option_set}}</div>

              <div class="product_detail_page_box_extras">
                <div class="detail_page_box_extras_list">
                  <strong>Category : </strong><span>{{category}}</span>
                </div>
                <div class="detail_page_box_extras_list">
                  <strong>Brand :</strong><span>{{brand}}</span>
                </div>
              </div>

              <div class="product_detail_page_box_prices">
                {{discount_price}} {{price}}
                <span>{{discount_percentage}}</span>
              </div>

              <div class="product_detail_page_box_cart_btns_with_outofstock">
                <div class="product_detail_page_outofstock">
                  <h3>{{out_of_stock}}</h3>
                </div>
                <div class="product_detail_page_special_instructions"><p>{{special_instructions}}</p></div>
                <div class="product_detail_page_box_cart_btns">
                  <div class="product_detail_page_quantity_plus_minu_btns">
                    {{counter}}
                  </div>
                  <div class="product_detail_page_add_to_cart_btn">
                    {{cart_button_start}}
                  </div>
                </div>
              </div>
              <div class="product_detail_page_box_social_links">{{share_button}}</div>
            </div>
          </div>
             <!-- content side html end -->
        </div>
      </div>
    </section>
    
    `
        },

    ]
    export default DetailPageObjects