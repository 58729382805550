const PrivacyPolicyPageObjects=[
    {
    id:"PrivacyPolicy0",
    name:"PrivacyPolicy One",
    height:"1000px",
    head:`
    <head>

    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
      
    </head>
     <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-family: "Poppins", sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    width: 100%;
    margin: 0px;
    background:#fff;
  }
  
    </style>

    `,

    code:` 
    
   <style>
    .privacy_policy_page{
        display: block;
        width: 100%;
        padding: 50px 15px 60px 15px;
        background: #fbf7f4;
    }
    .custom_container{
    max-width:1140px;
    margin:0 auto;
    }
    .privacy_policy_header{
        display: grid;
        gap: 10px 0px;
        text-align: center;
        width: 100%;
        padding-bottom: 30px;
    }
    .privacy_policy_header small{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: #e51451;
    }
    .privacy_policy_header h1{
        font-style: normal;
        font-weight: bold !important;
        font-size: 68px;
        line-height: 74px;
        text-align: center;
        color: #000000;
        margin: 0px !important;
    }
    .privacy_policy_header h1 .heading_dot_color{
        color: #e51451 !important;
    }
    .privacy_policy_header p{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 15px;
        width: 100%;
    }
   
    .privacy_policy_detail{
       display: grid;
        gap: 20px 0px;
        width: 100%;
    }
    .privacy_policy_detail_description{
       display: grid;
        gap: 10px 0px;
        width: 100%;
    }
    .privacy_policy_detail_description p{
     font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.7);
        width: 100%;
    }
    .privacy_policy_detail_two p a {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: none;
        font-weight: bold;
    }
    .privacy_policy_detail_two{
       display: grid;
        gap: 10px 0px;
        width: 100%;
    }
    .privacy_policy_detail_two h2{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
        margin: 0px !important;
    }
    .privacy_policy_detail_two p{
     font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.7);
        width: 100%;
    }
    .privacy_policy_detail_two ul{
       display: grid;
        gap: 10px 0px;
        width: 100%;
        padding: 0px 0px 0px 17px;
    }
    .privacy_policy_detail_two ul li{
     font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.7);
        width: 100%;
        list-style: square;
    }
    .privacy_policy_detail_two ul li::marker{
        color: #e51451 !important;
    }
    .privacy_policy_detail_two ol{
       display: grid;
        gap: 10px 0px;
        width: 100%;
        padding: 0px 0px 0px 17px;
    }
    .privacy_policy_detail_two ol li{
     font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.7);
        width: 100%;
    }
    @media (max-width: 1024px){
    .privacy_policy_header h1{
        font-size: 60px;
        line-height: 62px;
    }
    }
    
    @media (max-width: 768px){
    .privacy_policy_header h1{
    font-size: 45px;
        line-height: 49px;
    }
    }
    @media (max-width: 540px){
    .privacy_policy_header h1{
        font-size: 36px;
        line-height: 42px;
    }
    .privacy_policy_detail_description {
        text-align: center;
    }
    .privacy_policy_detail_two {
        text-align: center;
    }
    }
    @media (max-width: 450px){
    .privacy_policy_header {
        gap: 10px 0px;
    }
    .privacy_policy_header small {
        font-size: 13px;
        line-height: 20px;
    }
    .privacy_policy_header h1{
        font-size: 24px;
        line-height: 30px;
    }
    .privacy_policy_header p {
        font-size: 16px;
        line-height: 20px;
    }
    }
    
    </style>
    <div class="privacy_policy_page">
    <div class="custom_container">
    <div class="privacy_policy_header"><small>tossdown Inc.</small>
    <h1>Privacy Policy<span class="heading_dot_color">.</span></h1>
    <p>Last Updated: February 11, 2022</p>
    </div>
    <div class="privacy_policy_detail">
    <div class="privacy_policy_detail_description">
    <p>At tossdown Inc, accessible from https://tossdown.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by tossdown and how we use it.</p>
    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in tossdown. This policy is not applicable to any information collected offline or via channels other than this website.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>Consent</h2>
    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>Information we collect</h2>
    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
    <p>If you contact us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
    <p>When you first interact with our website, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>How we use your information</h2>
    <p>The information collected on the website can be used in various ways, including to:</p>
    <ul>
    <li>Provide, operate, and maintain our website</li>
    <li>Improve, personalize, and expand our website</li>
    <li>Understand and analyze how you use our website</li>
    <li>Develop new products, services, features, and functionality</li>
    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website and for marketing and promotional purposes</li>
    <li>Send you emails</li>
    <li>Find and prevent fraudulent activity</li>
    <li>Might use for legal purposes</li>
    </ul>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>Log Files</h2>
    <p>tossdown follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>Cookies and Web Beacons</h2>
    <p>Like any other website, tossdown uses '<a href="https://www.allaboutcookies.org" target="blank">cookies</a>'. These cookies are used to store information, including visitors' preferences and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>Third-Party Privacy Policies</h2>
    <p>tossdown's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt out of certain options.</p>
    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found on the browsers' respective websites. Below are the third-party services that tossdown uses to help us understand how you use the site and improve upon your experience. A link to their privacy policy is given in front of the mentioned third-party services that we use. We take help from <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener nofollow">Google Analytics - Privacy Policy</a>, <a href="https://legal.hubspot.com/privacy-policy?__hstc=126249106.f71101f43fe79d9a7e3b54f8d08155c7.1659080177318.1661516963506.1661770929579.6&amp;__hssc=126249106.2.1661770929579&amp;__hsfp=3635348595" target="_blank" rel="noopener nofollow">HubSpot - Privacy Policy</a>, and <a href="https://docs.microsoft.com/en-us/clarity/faq#privacy" target="_blank" rel="noopener nofollow">Microsoft Clarity - Privacy Policy</a> on our website to understand user behavior and improve the web surfing experience. Their privacy practices and policies can be found in the aforementioned links.</p>
    <p>We do not share any information collected – either personal or anonymous – with any other parties, with the exception of the reporting programs we use in conjunction with those cookies. By continuing to use this site, you agree to the use of these cookies. If you do not agree, please close the site.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
    <ul>
    <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
    <li>Request that a business deletes any personal data about the consumer that a business has collected.</li>
    <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
    </ul>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>User Protection Rights under GDPR</h2>
    <ol>
    <li>The right to be informed; This means anyone processing your personal data must make clear what they are processing, why, and to whom else the data may be passed to.</li>
    <li>The right of access; this is your right to see what data is held about you by a Data Controller.</li>
    <li>The right to rectification; The right to have your data corrected or amended if what is held is incorrect in some way.</li>
    <li>The right to erasure; under certain circumstances you can ask for your personal data to be deleted. This is also called 'the Right to be Forgotten'. This would apply if the personal data is no longer required for the purposes it was collected for, or your consent for the processing of that data has been withdrawn, or the personal data has been unlawfully processed.</li>
    <li>The right to restrict processing; This gives the Data Subject the right to ask for a temporary halt to the processing of personal data, such as in the case where a dispute or legal case has to be concluded, or the data is being corrected.</li>
    <li>The right to data portability; a Data Subject has the right to ask for any data supplied directly to the Data Controller by him or her, to be provided in a structured, commonly used, and machine-readable format.</li>
    <li>The right to object; the Data Subject has the right to object to the further processing of their data that is inconsistent with the primary purpose for which it was collected, including profiling, automation, and direct marketing.</li>
    <li>Rights in relation to automated decision making and profiling; Data Subjects have the right not to be subject to a decision based solely on automated processing.</li>
    </ol>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
    </div>
    <div class="privacy_policy_detail_two">
    <h2>Children's Information</h2>
    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
    <p>tossdown does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
    </div>
    </div>
    </div>
    </div>
    
    `
    },
    {
        id:"PrivacyPolicy1",
        name:"PrivacyPolicy Two",
      height:"1000px",
      head:`
      <head>
  
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      /> 
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
        
      </head>
       <style>
      @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
      * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      font-family: "Poppins", sans-serif;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      width: 100%;
      margin: 0px;
      background:#fff;
    }
    
      </style>
  
      `,

        code:`

    <style>
      
    
        /* page title start */
        .page_title_section {
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom:50px;
            position: relative;
            }
            .page_title_section img{
            width: 100%;
        }
         .page_title_main{
        display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
               position: absolute;
            top: 0px;
        }
        .page_title{
          max-width: 500px;
          margin:0 auto;
        display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
        }
             .page_title_main .page_title h2 {
                font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 43px;
        line-height: 46px;
        color: #000;
        margin: 0px;
        }
         .page_title_main .page_title p{
            font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.8);
        padding-top:8px;
        }
        
        @media(max-width:540px){
         .page_title_main .page_title h2 {
            font-size: 28px !important;
        }
        
        .page_title_section{
           margin-bottom:30px;
        }
        }
        /* page title end */
    
        .privacy_policy_page{
            display: block;
            width: 100%;
            padding: 50px 15px 60px 15px;
            background: #fbf7f4;
        }
        .custom_container{
        max-width:1140px;
        margin:0 auto;
        }
        .privacy_policy_detail{
           display: grid;
            gap: 20px 0px;
            width: 100%;
        }
        .privacy_policy_detail_description{
           display: grid;
            gap: 10px 0px;
            width: 100%;
        }
        .privacy_policy_detail_description p{
         font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.7);
            width: 100%;
        }
        .privacy_policy_detail_two p a {
            color: rgba(0, 0, 0, 0.7);
            text-decoration: none;
            font-weight: bold;
        }
        .privacy_policy_detail_two{
           display: grid;
            gap: 10px 0px;
            width: 100%;
        }
        .privacy_policy_detail_two h2{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #000000;
            margin: 0px !important;
        }
        .privacy_policy_detail_two p{
         font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.7);
            width: 100%;
        }
        .privacy_policy_detail_two ul{
           display: grid;
            gap: 10px 0px;
            width: 100%;
            padding: 0px 0px 0px 17px;
        }
        .privacy_policy_detail_two ul li{
         font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.7);
            width: 100%;
            list-style: square;
        }
        .privacy_policy_detail_two ul li::marker{
            color: #e51451 !important;
        }
        .privacy_policy_detail_two ol{
           display: grid;
            gap: 10px 0px;
            width: 100%;
            padding: 0px 0px 0px 17px;
        }
        .privacy_policy_detail_two ol li{
         font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.7);
            width: 100%;
        }
        @media (max-width: 1024px){
      
        }
        
        @media (max-width: 768px){
       
        }
        @media (max-width: 540px){
       
        .privacy_policy_detail_description {
            text-align: center;
        }
        .privacy_policy_detail_two {
            text-align: center;
        }
        }
        @media (max-width: 450px){
       
        }
        
        </style>
        <section class="page_title_section"><img src="https://static.tossdown.com/images/b88b2406-835d-4151-ac62-e345511afbd8.jpg">
<div class="page_title_main">
<div class="page_title">
<h2>Privacy Policy</h2>
</div>
</div>
</section>
        <div class="privacy_policy_page">
        <div class="custom_container">
       
        <div class="privacy_policy_detail">
        <div class="privacy_policy_detail_description">
        <p>At tossdown Inc, accessible from https://tossdown.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by tossdown and how we use it.</p>
        <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
        <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in tossdown. This policy is not applicable to any information collected offline or via channels other than this website.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>Consent</h2>
        <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>Information we collect</h2>
        <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
        <p>If you contact us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
        <p>When you first interact with our website, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>How we use your information</h2>
        <p>The information collected on the website can be used in various ways, including to:</p>
        <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website and for marketing and promotional purposes</li>
        <li>Send you emails</li>
        <li>Find and prevent fraudulent activity</li>
        <li>Might use for legal purposes</li>
        </ul>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>Log Files</h2>
        <p>tossdown follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>Cookies and Web Beacons</h2>
        <p>Like any other website, tossdown uses '<a href="https://www.allaboutcookies.org" target="blank">cookies</a>'. These cookies are used to store information, including visitors' preferences and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>Third-Party Privacy Policies</h2>
        <p>tossdown's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt out of certain options.</p>
        <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found on the browsers' respective websites. Below are the third-party services that tossdown uses to help us understand how you use the site and improve upon your experience. A link to their privacy policy is given in front of the mentioned third-party services that we use. We take help from <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener nofollow">Google Analytics - Privacy Policy</a>, <a href="https://legal.hubspot.com/privacy-policy?__hstc=126249106.f71101f43fe79d9a7e3b54f8d08155c7.1659080177318.1661516963506.1661770929579.6&amp;__hssc=126249106.2.1661770929579&amp;__hsfp=3635348595" target="_blank" rel="noopener nofollow">HubSpot - Privacy Policy</a>, and <a href="https://docs.microsoft.com/en-us/clarity/faq#privacy" target="_blank" rel="noopener nofollow">Microsoft Clarity - Privacy Policy</a> on our website to understand user behavior and improve the web surfing experience. Their privacy practices and policies can be found in the aforementioned links.</p>
        <p>We do not share any information collected – either personal or anonymous – with any other parties, with the exception of the reporting programs we use in conjunction with those cookies. By continuing to use this site, you agree to the use of these cookies. If you do not agree, please close the site.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
        <p>Under the CCPA, among other rights, California consumers have the right to:</p>
        <ul>
        <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
        <li>Request that a business deletes any personal data about the consumer that a business has collected.</li>
        <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
        </ul>
        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>User Protection Rights under GDPR</h2>
        <ol>
        <li>The right to be informed; This means anyone processing your personal data must make clear what they are processing, why, and to whom else the data may be passed to.</li>
        <li>The right of access; this is your right to see what data is held about you by a Data Controller.</li>
        <li>The right to rectification; The right to have your data corrected or amended if what is held is incorrect in some way.</li>
        <li>The right to erasure; under certain circumstances you can ask for your personal data to be deleted. This is also called 'the Right to be Forgotten'. This would apply if the personal data is no longer required for the purposes it was collected for, or your consent for the processing of that data has been withdrawn, or the personal data has been unlawfully processed.</li>
        <li>The right to restrict processing; This gives the Data Subject the right to ask for a temporary halt to the processing of personal data, such as in the case where a dispute or legal case has to be concluded, or the data is being corrected.</li>
        <li>The right to data portability; a Data Subject has the right to ask for any data supplied directly to the Data Controller by him or her, to be provided in a structured, commonly used, and machine-readable format.</li>
        <li>The right to object; the Data Subject has the right to object to the further processing of their data that is inconsistent with the primary purpose for which it was collected, including profiling, automation, and direct marketing.</li>
        <li>Rights in relation to automated decision making and profiling; Data Subjects have the right not to be subject to a decision based solely on automated processing.</li>
        </ol>
        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
        </div>
        <div class="privacy_policy_detail_two">
        <h2>Children's Information</h2>
        <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
        <p>tossdown does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
        </div>
        </div>
        </div>
        </div>
    

        `,
        },
  
    ]
    export default PrivacyPolicyPageObjects