const DetailPageAssetsObjects=[
    {
    id:"DetailPageAsset0",
    name:"Detail Page Asset One",
    height:"600px",
    code:` 
    
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

    </head>

    <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    margin: 0px;
    width: 100%;
  }
    </style>

  <style>
  .detail_page_long_des_section{
   display: block;
width: 100%;
padding: 60px 10px;
  }

  .custom_container{
max-width: 1140px;
margin: 0 auto;
}

.detail_page_long_des_data_parent{
display: flex;
flex-direction: column;
width: 100%;
}

.detail_page_long_pills {
 border-bottom: 1px solid rgba(0, 0, 0, 0.12);
 padding-bottom: 16px;
 margin-bottom: 24px !important;
 overflow: auto;
 white-space: nowrap;
 flex-wrap: nowrap;
 display: flex;
 align-items: center;
}
.detail_page_long_pills::-webkit-scrollbar {
    display: none;
  background: transparent; 
}
.detail_page_long_pills li{
list-style: none;
}
.detail_page_long_pills li a{
 font-style: normal;
 font-weight: 600;
 font-size: 14px;
 line-height: 19px;
 text-transform: uppercase;
 color: #DB0F2F;
 padding: 12px 30px !important;
 text-align: center;
 display: flex;
 border-radius: 4px;
 border: unset !important;
}
.detail_page_long_pills li a:hover{
border: unset !important; 
}
.detail_page_long_pills li a.active {
 background: #DB0F2F !important;
 color: #FFFFFF !important;
 padding: 12px 30px !important;
border: unset !important; 
}

.detail_page_long_pills_data {
 background: #F6F6F6;
 border-radius: 5px;
 padding: 24px 25px;
}



.detail_page_long_des_detail{
    display: grid;
     gap: 20px 0px;
     width: 100%;
 }
 .detail_page_long_des_data{
    display: grid;
     gap: 10px 0px;
     width: 100%;
 }
 .detail_page_long_des_data h2{
     font-style: normal;
     font-weight: 600;
     font-size: 20px;
     line-height: 24px;
     letter-spacing: 0.1em;
     text-transform: capitalize;
     color: #000000;
     margin: 0px !important;
 }
 .detail_page_long_des_data p{
  font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 28px;
     color: rgba(0, 0, 0, 0.7);
     width: 100%;
     margin: 0px;
 }
 .detail_page_long_des_data ul{
    display: grid;
     gap: 10px 0px;
     width: 100%;
     padding: 0px 0px 0px 17px;
 }
 .detail_page_long_des_data ul li{
  font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 28px;
     color: rgba(0, 0, 0, 0.7);
     width: 100%;
     list-style: square;
 }
 .detail_page_long_des_data ul li::marker{
     color: #e51451 !important;
 }
 .detail_page_long_des_data ol{
    display: grid;
     gap: 10px 0px;
     width: 100%;
     padding: 0px 0px 0px 17px;
 }
 .detail_page_long_des_data ol li{
  font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 28px;
     color: rgba(0, 0, 0, 0.7);
     width: 100%;
 }



 #tab-editor-container{
 max-width: 485px;
}
   .detail_page_long_des_table_section {
     display: block;
     width: 100%;
   }
.detail_page_long_des_table{
display: grid;
 grid-template-columns: 1fr ;
 width: 100%;
border: 1px solid rgba(0, 0, 0, 0.12);
}
.detail_page_long_des_table_heading {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.detail_page_long_des_table_heading h2{
background: #ffffff;
font-style: normal;
font-weight: 600 !important;
font-size: 14px;
line-height: 21px;
color: #DB0F2F;
padding: 12px 5px;
border-left: 1px solid rgba(0, 0, 0, 0.12);
text-align: center;
 margin: 0px;
 word-break: break-all;
height: 100%;
 align-items: center;
 display: flex;
}
.detail_page_long_des_table_heading h2:first-child{
border-left: unset;
}

.detail_page_long_des_table_detail{
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 align-items: center;
background: #FFFFFF;
 border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.detail_page_long_des_table_detail span{
color: #656060;
 margin: 0px;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
 text-transform: capitalize;
 padding: 12px 5px;
 height: 100%;
 align-items: center;
 display: flex;
border-left: 1px solid rgba(0, 0, 0, 0.12);
 word-break: break-all;
}
.detail_page_long_des_table_detail span:first-child{
border-left: unset;
}
.detail_page_long_des_table_detail:nth-child(even){
background:#F6F6F6;
}
.detail_page_long_des_table_detail:last-child{
 border: none;
}

@media (max-width: 1024px){

}

@media (max-width: 768px){
 .detail_page_long_pills_data {
 padding: 24px 15px;
}
}
@media (max-width: 540px){
  .detail_page_long_des_section {
    padding: 30px 10px;
}

}


 </style>

<!-- bootstrap pills tabs used -->

<!-- detail page long des html start -->
 
<div class="detail_page_long_des_section">
<div class="custom_container">
<div class="detail_page_long_des_data_parent">
<!-- detail page long des tabs start  -->

<ul class="detail_page_long_pills nav nav-tabs" id="myTab" role="tablist">
 <li class="nav-item">
  <a class='nav-link active' id='detail-long-pills-1-tab' data-toggle='tab' href='#detail-long-pills-tab-1' 
  role='tab' aria-controls='detail-long-pills-tab-1' aria-selected='true'>Home</a>
</li>
<li class="nav-item">
  <a class='nav-link' id='detail-long-pills-2-tab' data-toggle='tab' href='#detail-long-pills-tab-2' 
  role='tab' aria-controls='detail-long-pills-tab-2' aria-selected='false'>Profile</a>
</li>
<li class="nav-item">
  <a class='nav-link' id='detail-long-pills-3-tab' data-toggle='tab' href='#detail-long-pills-tab-3' 
  role='tab' aria-controls='detail-long-pills-tab-3' aria-selected='false'>Contact</a>
</li> 
</ul>

<!-- detail page long des tabs end  -->

<!-- detail page long des tabs content start  -->

<div class="tab-content detail_page_long_pills_data" id="myTabContent">
<div class="tab-pane fade show active" id="detail-long-pills-tab-1" role="tabpanel" aria-labelledby="detail-long-pills-1-tab">
 <div class="detail_page_long_des_detail">

   <div class="detail_page_long_des_data">
   <h2>How we use your information</h2>
   <p>The information collected on the website can be used in various ways, including to:</p>
   <ul>
   <li>Provide, operate, and maintain our website</li>
   <li>Improve, personalize, and expand our website</li>
   </ul>
 
   </div>
   <div class="detail_page_long_des_data">
     <h2>How we use your information</h2>
     <p>The information collected on the website can be used in various ways, including to:</p>
     <ol>
     <li>Provide, operate, and maintain our website</li>
     <li>Improve, personalize, and expand our website</li>
     </ol>
   
     </div>
   
   </div>
</div>
<div class="tab-pane fade" id="detail-long-pills-tab-2" role="tabpanel" aria-labelledby="detail-long-pills-2-tab">
 <div class="detail_page_long_des_detail">

   <div class="detail_page_long_des_data">
   <h2>How we use your information</h2>
   <p>The information collected on the website can be used in various ways, including to:</p>
   <ul>
   <li>Provide, operate, and maintain our website</li>
   <li>Improve, personalize, and expand our website</li>
   </ul>
 
   </div>
   <div class="detail_page_long_des_data">
     <h2>How we use your information</h2>
     <p>The information collected on the website can be used in various ways, including to:</p>
     <ol>
     <li>Provide, operate, and maintain our website</li>
     <li>Improve, personalize, and expand our website</li>
     </ol>
   
     </div>
   
   </div>
</div>
<div class="tab-pane fade" id="detail-long-pills-tab-3" role="tabpanel" aria-labelledby="detail-long-pills-3-tab"> 
     <div id="tab-editor-container"> 
       <div class="detail_page_long_des_table_section"> 
         <div class="detail_page_long_des_table">
            <div class="detail_page_long_des_table_heading"> 
             <h2></h2> 
             <h2>Per 100g</h2>
              <h2>Per Portion 75g</h2>
             </div>
              <div class="detail_page_long_des_table_detail">
               <span>Energy (kJ / kcal)</span>
               <span>1223 / 290</span>
               <span>917 / 218</span>
             </div> 
         <div class="detail_page_long_des_table_detail">
           <span>Salt (g)</span>
           <span>1.6</span>
           <span>1.2</span>
         </div>
         
         </div>
        </div>
       </div>
</div>
       
</div>
<!-- detail page long des tabs content end  -->
</div>
</div>

</div>  

<!-- detail page long des html end -->

`
    },

    {
      id:"DetailPageAsset0",
      name:"Detail Page Asset One",
      height:"600px",
      code:` 
      
      <head>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      /> 
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
      <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  
      </head>
  
      <style>
      @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
      * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      margin: 0px;
      width: 100%;
    }
      </style>
  
    <style>
    .detail_page_long_des_section{
     display: block;
  width: 100%;
  padding: 60px 10px;
    }
  
    .custom_container{
  max-width: 1140px;
  margin: 0 auto;
  }
  
  .detail_page_long_des_data_parent{
  display: flex;
  flex-direction: column;
  width: 100%;
  }
  
  .detail_page_long_pills {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
      padding: 24px 0px;
      margin-bottom: 24px !important;
      overflow: auto;
      white-space: nowrap;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      justify-content: center;
  }
  .detail_page_long_pills::-webkit-scrollbar {
      display: none;
    background: transparent; 
  }
  .detail_page_long_pills li{
  list-style: none;
  }
  .detail_page_long_pills li a{
    background: transparent !important;
    padding: 0px 30px !important;
      display: flex;
      border: unset !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      border-radius: unset !important;
      color: #03242D;
  }
  .detail_page_long_pills li a:hover{
  border: unset !important; 
  }
  .detail_page_long_pills li a.active {
   color: #A5170D !important;
  border: unset !important; 
  position: relative;
  }
  .detail_page_long_pills li a.active:before{
    position: absolute;
      left: 0px;
      content: "";
      width: 100%;
      border-bottom: 3px solid #A5170D;
      bottom: -23px;
  }
  
  .detail_page_long_pills_data {
   background: #F6F6F6;
   border-radius: 5px;
   padding: 24px 25px;
  }
  
  
  
  .detail_page_long_des_detail{
      display: grid;
       gap: 20px 0px;
       width: 100%;
   }
   .detail_page_long_des_data{
      display: grid;
       gap: 10px 0px;
       width: 100%;
   }
   .detail_page_long_des_data h2{
       font-style: normal;
       font-weight: 600;
       font-size: 20px;
       line-height: 24px;
       letter-spacing: 0.1em;
       text-transform: capitalize;
       color: #000000;
       margin: 0px !important;
   }
   .detail_page_long_des_data p{
    font-style: normal;
       font-weight: normal;
       font-size: 18px;
       line-height: 28px;
       color: rgba(0, 0, 0, 0.7);
       width: 100%;
       margin: 0px;
   }
   .detail_page_long_des_data ul{
      display: grid;
       gap: 10px 0px;
       width: 100%;
       padding: 0px 0px 0px 17px;
   }
   .detail_page_long_des_data ul li{
    font-style: normal;
       font-weight: normal;
       font-size: 18px;
       line-height: 28px;
       color: rgba(0, 0, 0, 0.7);
       width: 100%;
       list-style: square;
   }
   .detail_page_long_des_data ul li::marker{
       color: #e51451 !important;
   }
   .detail_page_long_des_data ol{
      display: grid;
       gap: 10px 0px;
       width: 100%;
       padding: 0px 0px 0px 17px;
   }
   .detail_page_long_des_data ol li{
    font-style: normal;
       font-weight: normal;
       font-size: 18px;
       line-height: 28px;
       color: rgba(0, 0, 0, 0.7);
       width: 100%;
   }
  
  
  
   #tab-editor-container{
   max-width: 485px;
  }
     .detail_page_long_des_table_section {
       display: block;
       width: 100%;
     }
  .detail_page_long_des_table{
  display: grid;
   grid-template-columns: 1fr ;
   width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .detail_page_long_des_table_heading {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  .detail_page_long_des_table_heading h2{
  background: #ffffff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 21px;
  color: #DB0F2F;
  padding: 12px 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  text-align: center;
   margin: 0px;
   word-break: break-all;
  height: 100%;
   align-items: center;
   display: flex;
  }
  .detail_page_long_des_table_heading h2:first-child{
  border-left: unset;
  }
  
  .detail_page_long_des_table_detail{
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   align-items: center;
  background: #FFFFFF;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .detail_page_long_des_table_detail span{
  color: #656060;
   margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
   text-transform: capitalize;
   padding: 12px 5px;
   height: 100%;
   align-items: center;
   display: flex;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
   word-break: break-all;
  }
  .detail_page_long_des_table_detail span:first-child{
  border-left: unset;
  }
  .detail_page_long_des_table_detail:nth-child(even){
  background:#F6F6F6;
  }
  .detail_page_long_des_table_detail:last-child{
   border: none;
  }
  
  @media (max-width: 1024px){
  
  }
  
  @media (max-width: 768px){
   .detail_page_long_pills_data {
   padding: 24px 15px;
  }
  }
  @media (max-width: 540px){
    .detail_page_long_pills {
      justify-content: flex-start;
  }
  .detail_page_long_des_section {
      padding: 30px 10px;
  }
  
  }
  
  
   </style>
  
  <!-- bootstrap pills tabs used -->
  
  <!-- detail page long des html start -->
   
  <div class="detail_page_long_des_section">
  <div class="custom_container">
  <div class="detail_page_long_des_data_parent">
  <!-- detail page long des tabs start  -->
  
  <ul class="detail_page_long_pills nav nav-tabs" id="myTab" role="tablist">
   <li class="nav-item">
    <a class='nav-link active' id='detail-long-pills-1-tab' data-toggle='tab' href='#detail-long-pills-tab-1' 
    role='tab' aria-controls='detail-long-pills-tab-1' aria-selected='true'>Home</a>
  </li>
  <li class="nav-item">
    <a class='nav-link' id='detail-long-pills-2-tab' data-toggle='tab' href='#detail-long-pills-tab-2' 
    role='tab' aria-controls='detail-long-pills-tab-2' aria-selected='false'>Profile</a>
  </li>
  <li class="nav-item">
    <a class='nav-link' id='detail-long-pills-3-tab' data-toggle='tab' href='#detail-long-pills-tab-3' 
    role='tab' aria-controls='detail-long-pills-tab-3' aria-selected='false'>Contact</a>
  </li> 
  </ul>
  
  <!-- detail page long des tabs end  -->
  
  <!-- detail page long des tabs content start  -->
  
  <div class="tab-content detail_page_long_pills_data" id="myTabContent">
  <div class="tab-pane fade show active" id="detail-long-pills-tab-1" role="tabpanel" aria-labelledby="detail-long-pills-1-tab">
   <div class="detail_page_long_des_detail">
  
     <div class="detail_page_long_des_data">
     <h2>How we use your information</h2>
     <p>The information collected on the website can be used in various ways, including to:</p>
     <ul>
     <li>Provide, operate, and maintain our website</li>
     <li>Improve, personalize, and expand our website</li>
     </ul>
   
     </div>
     <div class="detail_page_long_des_data">
       <h2>How we use your information</h2>
       <p>The information collected on the website can be used in various ways, including to:</p>
       <ol>
       <li>Provide, operate, and maintain our website</li>
       <li>Improve, personalize, and expand our website</li>
       </ol>
     
       </div>
     
     </div>
  </div>
  <div class="tab-pane fade" id="detail-long-pills-tab-2" role="tabpanel" aria-labelledby="detail-long-pills-2-tab">
   <div class="detail_page_long_des_detail">
  
     <div class="detail_page_long_des_data">
     <h2>How we use your information</h2>
     <p>The information collected on the website can be used in various ways, including to:</p>
     <ul>
     <li>Provide, operate, and maintain our website</li>
     <li>Improve, personalize, and expand our website</li>
     </ul>
   
     </div>
     <div class="detail_page_long_des_data">
       <h2>How we use your information</h2>
       <p>The information collected on the website can be used in various ways, including to:</p>
       <ol>
       <li>Provide, operate, and maintain our website</li>
       <li>Improve, personalize, and expand our website</li>
       </ol>
     
       </div>
     
     </div>
  </div>
  <div class="tab-pane fade" id="detail-long-pills-tab-3" role="tabpanel" aria-labelledby="detail-long-pills-3-tab"> 
       <div id="tab-editor-container"> 
         <div class="detail_page_long_des_table_section"> 
           <div class="detail_page_long_des_table">
              <div class="detail_page_long_des_table_heading"> 
               <h2></h2> 
               <h2>Per 100g</h2>
                <h2>Per Portion 75g</h2>
               </div>
                <div class="detail_page_long_des_table_detail">
                 <span>Energy (kJ / kcal)</span>
                 <span>1223 / 290</span>
                 <span>917 / 218</span>
               </div> 
           <div class="detail_page_long_des_table_detail">
             <span>Salt (g)</span>
             <span>1.6</span>
             <span>1.2</span>
           </div>
           
           </div>
          </div>
         </div>
  </div>
         
  </div>
  <!-- detail page long des tabs content end  -->
  </div>
  </div>
  
  </div>  
  
  <!-- detail page long des html end -->
  
  `
      },

    ]
    export default DetailPageAssetsObjects