const ProductCategorySectionObjects=[
    {
    id:"brandsection0",
    name:"BrandSection 0ne",
    height:"400px",
    head:`
    
    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
    </head> 
  
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    margin: 0px;
    width: 100%;
    background-color: #fff;
    padding:40px 0px;
  }
  
    </style>
    
    `,

    code:` 
 
      <style>
     
       .product_brand_section {
          display: block;
          width: 100%;
          padding:0px 15px 0px 15px;
          margin-bottom: 70px;
          margin-top: 30px;
          float: left;
        }
        .custom_container {
          max-width: 1140px;
          margin: 0px auto;
        }
        .product_brand_heading {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding-bottom: 17px;
      text-align: center;
      flex-direction: column;
  }
  .product_brand_heading h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #2D2A38;
      margin-bottom: 0px;
      text-transform: capitalize;
      position: relative;
  }
  
      .product_brand_main {
   float:left;
  width:100%;
  }
  
  .brand_slider_arrow_box {
  display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      position: absolute;
      top: 0px;
      left: 0px;
   
      width: 100%;
  }
  .brand_slider_arrow_box  a.brand_pre_arrow.slick-arrow {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
  z-index:99;
  }
  .brand_slider_arrow_box  a.brand_pre_arrow.slick-arrow i{
  color:#000;
  }
  
       
        .product_brand_list {
          float: left;
          width:100%;
          padding: 0px 50px;
          position: relative;
        }
        .product_brand_list ul {
          margin: 0px -15px;
          padding: 0px;
        }
        .product_brand_list ul li {
          list-style: none;
          width: 25%;
          padding: 15px 15px 15px 15px;
          float: left;
            margin: 0px !important;
        }
  
        .product_brand_figure_box {
          float: left;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 130px;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
    border-radius: 6px;
        }
        .product_brand_figure_box figure {
          width: 70px;
    height: 70px;
    margin: 0px !important;
        }
        .product_brand_figure_box figure img {
          width: 100%;
    object-fit: contain;
    height: 100%;
        }
      
    @media (max-width: 1024px) {
  .product_brand_main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: 0px 0px;
  
  }
  .product_brand_list {
  width:100%;
  padding:0px;
  }
  .product_brand_list ul {
    margin: 0px -10px;
  }
  .product_brand_list ul li {
  
    padding: 15px 10px 15px 10px;
  
  }
  .brand_slider_arrow_box  a.brand_pre_arrow.slick-arrow {
   background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  }
  
  
  }
        @media (max-width: 540px) {
       
        }
   
      </style>
  <section class="product_brand_section">
  <div class="custom_container">
    <div class="product_brand_heading">
      <h3>Featured brands</h3>
      </div>
  <div class="product_brand_main">
  <div class="product_brand_list">
    <div class="brand_slider_arrow_box"></div>

  <!-- <ul class="product_brand_slider"><?php
    foreach($featured_brand_section->result as $valueBrand) {
    $regex = '#[^a-z0-9_]#i';
    $val = preg_replace($regex, '', $valueBrand->name);
    $rel = strtolower($val);
    $urlBrand = "menu?brand=".$rel.'-'.$valueBrand->id;
$cdnHeight = '70' ;
$large_image = $this->obw->convertDomainToCdnUrl2(DOMAIN_URL2, CDN_URL2, $cdnHeight, $cdnWidth, $valueBrand->image);
?>
<li>
<div class="product_brand_figure_box"><?php
          echo '<figure>';
        echo '<img src="'.$large_image.'" />';
echo '</figure>';                          
    ?></div>
</li>
<?php }?></ul>  -->

<ul class="product_brand_slider">
   
  <li>
  <div class="product_brand_figure_box">
  <figure>
  <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
   </figure>
 
  </div>
  </li>

  <li>
    <div class="product_brand_figure_box">
    <figure>
    <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
     </figure>
   
    </div>
    </li>

    <li>
      <div class="product_brand_figure_box">
      <figure>
      <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
       </figure>
     
      </div>
      </li>

      <li>
        <div class="product_brand_figure_box">
        <figure>
        <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
         </figure>
       
        </div>
        </li>

        <li>
          <div class="product_brand_figure_box">
          <figure>
          <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
           </figure>
         
          </div>
          </li>

          <li>
            <div class="product_brand_figure_box">
            <figure>
            <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
             </figure>
           
            </div>
            </li>
 
  
  </ul>

  </div>
  </div>
  </div>
  </section>
  <script>
        $(document).ready(function () {
          $(".product_brand_slider").slick({
            slidesToShow:5,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            dots: false,
           arrows: true,
  appendArrows: $('.brand_slider_arrow_box'),
  nextArrow: '<a class="brand_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
  prevArrow: '<a class="brand_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow:3,
                  slidesToScroll: 1,
                  autoplay: false,
                  autoplaySpeed: 1500,
                  dots: false,
                  arrows: true,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  autoplay: false,
                  autoplaySpeed: 1500,
                       dots: false,
                    arrows: true,
                },
              },
              {
                breakpoint: 540,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  autoplay: false,
                  autoplaySpeed: 1500,
                      dots: false,
                  arrows: true,
                },
              },
              {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: false,
                autoplaySpeed: 1500,
                       dots: false,
                   arrows: true,
                },
              },
            ],
          });
        });
  
  
   </script>
                

`
    },

    {
        id:"brandsection1",
        name:"BrandSection Two",
        height:"300px",
        head:`
        <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
        
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
      <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
      
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
        </head> 
      
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
        * {
        -web-kit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
      }
      html {
        scroll-behavior: smooth;
      }
      body {
        float: left;
        margin: 0px;
        width: 100%;
        background-color: #fff;
        padding:40px 0px;
      }
       
        </style>
        
        `,

        code:` 
      
          <style>
           .product_brand_section {
              display: block;
              width: 100%;
              padding:0px 15px 0px 15px;
              margin-bottom: 70px;
              margin-top: 30px;
              float: left;
            }
            .custom_container {
              max-width: 1140px;
              margin: 0px auto;
            }
            .product_brand_heading {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding-bottom: 17px;
          text-align: center;
          flex-direction: column;
      }
      .product_brand_heading h3 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 35px;
      color: #2D2A38;
          margin-bottom: 0px;
          text-transform: capitalize;
          position: relative;
      }
      
          .product_brand_main {
       float:left;
      width:100%;
      }
      
      .brand_slider_arrow_box {
      display: flex;
          justify-content: space-between;
          height: 100%;
          align-items: center;
          position: absolute;
          top: 0px;
          left: 0px;
       
          width: 100%;
      }
      .brand_slider_arrow_box  a.brand_pre_arrow.slick-arrow {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
      z-index:99;
      }
      .brand_slider_arrow_box  a.brand_pre_arrow.slick-arrow i{
      color:#000;
      }
      
            .product_brand_list {
              float: left;
              width:100%;
              padding: 0px 50px;
              position: relative;
            }
            .product_brand_list ul {
              margin: 0px -15px;
              padding: 0px;
            }
            .product_brand_list ul .slick-track {
                display: flex;
                align-items: center;
            }
            .product_brand_list ul li {
              list-style: none;
              width: 25%;
              padding: 15px 15px 15px 15px;
              float: left;
                margin: 0px !important;
            }
      
            .product_brand_figure_box {
              float: left;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
      
            }
            .product_brand_figure_box figure {
              width: 140px;
        margin: 0px !important;
            }
            .product_brand_figure_box figure img {
              width: 100%;
            }
          
        @media (max-width: 1024px) {
      .product_brand_main {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          margin: 0px 0px;
      
      }
      .product_brand_list {
      width:100%;
      padding: 0px 30px;
      }
      .product_brand_list ul {
        margin: 0px -10px;
      }
      .product_brand_list ul li {
      
        padding: 15px 10px 15px 10px;
      
      }
      .brand_slider_arrow_box  a.brand_pre_arrow.slick-arrow {
       background: #FFFFFF;
      box-sizing: border-box;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
      border-radius: 2px;
      }
      
      
      }
            @media (max-width: 540px) {
           
            }
       
          </style>
      <section class="product_brand_section">
      <div class="custom_container">
        <div class="product_brand_heading">
          <h3>Featured brands</h3>
          </div>
      <div class="product_brand_main">
      <div class="product_brand_list">
        <div class="brand_slider_arrow_box"></div>
    
      <!-- <ul class="product_brand_slider"><?php
        foreach($featured_brand_section->result as $valueBrand) {
        $regex = '#[^a-z0-9_]#i';
        $val = preg_replace($regex, '', $valueBrand->name);
        $rel = strtolower($val);
        $urlBrand = "menu?brand=".$rel.'-'.$valueBrand->id;
    $cdnHeight = '70' ;
    $large_image = $this->obw->convertDomainToCdnUrl2(DOMAIN_URL2, CDN_URL2, $cdnHeight, $cdnWidth, $valueBrand->image);
    ?>
    <li>
    <div class="product_brand_figure_box"><?php
              echo '<figure>';
            echo '<img src="'.$large_image.'" />';
    echo '</figure>';                          
        ?></div>
    </li>
    <?php }?></ul>  -->
    
    <ul class="product_brand_slider">
       
      <li>
      <div class="product_brand_figure_box">
      <figure>
      <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
       </figure>
     
      </div>
      </li>
    
      <li>
        <div class="product_brand_figure_box">
        <figure>
        <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
         </figure>
       
        </div>
        </li>
    
        <li>
          <div class="product_brand_figure_box">
          <figure>
          <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
           </figure>
         
          </div>
          </li>
    
          <li>
            <div class="product_brand_figure_box">
            <figure>
            <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
             </figure>
           
            </div>
            </li>
    
            <li>
              <div class="product_brand_figure_box">
              <figure>
              <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
               </figure>
             
              </div>
              </li>
    
              <li>
                <div class="product_brand_figure_box">
                <figure>
                <img  class="lazy" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"  />
                 </figure>
               
                </div>
                </li>
     
      
      </ul>
    
      </div>
      </div>
      </div>
      </section>
      <script>
            $(document).ready(function () {
              $(".product_brand_slider").slick({
                slidesToShow:5,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: false,
               arrows: true,
      appendArrows: $('.brand_slider_arrow_box'),
      nextArrow: '<a class="brand_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
      prevArrow: '<a class="brand_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow:3,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 1500,
                      dots: false,
                      arrows: true,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 1500,
                           dots: false,
                        arrows: true,
                    },
                  },
                  {
                    breakpoint: 540,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 1500,
                          dots: false,
                      arrows: true,
                    },
                  },
                  {
                    breakpoint: 400,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      autoplay: false,
                    autoplaySpeed: 1500,
                           dots: false,
                       arrows: true,
                    },
                  },
                ],
              });
            });
      
      
       </script>
                    
    
    `
        },
    
    
        {
          id:"brandsection2",
          name:"BrandSection Three",
          height:"600px",
          head:`

          <head>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          /> 
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
          
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
        <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
        <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
        
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
          </head>
      
          <style>
      @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
      
          * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          margin: 0px;
          width: 100%;
          background:#fff;
          padding:40px 0px;
        }
          </style>
          
         `
          ,
          code:` 
          
      
          <style>
        
          .brand_section {
             display: block;
             width: 100%;
             padding:0px 10px 0px 10px;
             margin-bottom:48px;
             float: left;
           }
           .custom_container {
             max-width: 1140px;
             margin: 0px auto;
           }
      
         .brand_main {
          float: left;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
      }
      .brand_sliding_box {
      float:left;
      width:25%;
      padding:30px 20px;
      display: flex;
         flex-direction: column;
         justify-content: center;
      }
      
      .brand_sliding_box small {
      padding-bottom: 16px;
      }
      .brand_sliding_box  h5 {
        font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
         padding-bottom: 16px;
         margin: 0px;
      }
      .brand_sliding_box p {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
         padding-bottom: 24px;
         margin: 0px;
      }
      .brabd_slider_arrow_box_main{
         display: flex;
          justify-content: center;
      }
      .brabd_slider_arrow_box {
          display: flex;
      
      }
      .brabd_slider_arrow_box  a.brabd_pre_arrow.slick-arrow {
      justify-content: center;
          background: #EAE5DB;
          display: flex;
      
      }
      .brabd_slider_arrow_box  a.brabd_pre_arrow.slick-arrow:first-child{
      border-top-left-radius: 56px;
          border-bottom-left-radius: 56px;
          padding: 12px 8px 12px 16px;
      }
      .brabd_slider_arrow_box  a.brabd_pre_arrow.slick-arrow:last-child{
      border-top-right-radius: 56px;
          border-bottom-right-radius: 56px;
          padding: 12px 16px 12px 8px;
      }
          .brabd_slider_arrow_box a.brabd_pre_arrow.slick-arrow i {
          font-size: 14px;
      }
           .brand_list {
             float: left;
             width: 75%;
             padding: 0px 20px;
           }
           .brand_list ul {
             margin: 0px -8px;
             padding: 0px;
           }
           .brand_list ul li {
             list-style: none;
             width: 33.333%;
             padding: 15px 8px 15px 8px;
             float: left;
               margin: 0px !important;
           }
      
           .brand_figure_box {
             width: 100%;
             display: flex;
             flex-direction: column;
             background: #ffffff;
             justify-content: space-between;
         
           }
           .brand_figure_box figure {
             margin-bottom: 0px;
           }
           .brand_figure_box figure a {
             width: 100%;
             height: 100%;
             display: block;
           }
           .brand_figure_box figure a img {
         width:100%;
         height:180px;
         object-fit: cover;
           }
           .brand_figure_tags {
             display: flex;
             width: 100%;
             height: 100%;
        padding: 15px 15px 24px 15px;
          flex-direction: column;
          text-align: left;
          box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
           }
        .brand_figure_tags h5{
          font-family: 'Work Sans' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          padding-bottom: 8px;
          margin: 0px;
      }
        .brand_figure_tags p{
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          margin-bottom: 24px;
          height: 60px;
          display: -webkit-box !important;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          width: 100% !important;
          max-width: 100% !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          overflow: hidden;
      }
       .brand_figure_tags_btn{
          display: flex;
      }
       .brand_figure_tags_btn a{
      height: 48px;
      border: 1px solid #000000;
      border-radius: 56px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #000000;
          padding: 2px 20px 0px 20px;
          justify-content: space-between;
      }
       .brand_figure_tags_btn a i{
      padding-left:20px;
      }
       @media (max-width: 1024px) {
      .brand_main {
         display: flex;
         flex-direction: column;
         justify-content: center;
         width: 100%;
         margin: 0px 0px;
      
      }
      .brand_sliding_box {
         margin-bottom: 30px;
         padding: 0px;
         width: 100%;
      }
      .brand_list {
      width:100%;
      padding:0px;
      }
      
      }
           @media (max-width: 768px) {
      .brand_figure_tags {
          text-align: center;
      }
      .brand_figure_tags_btn {
          justify-content: center;
      }
      }
           @media (max-width: 540px) {
           .brand_sliding_box h5 {
          font-size: 24px;
          line-height: 31px;
          padding-bottom: 8px;
      }
      .brand_sliding_box p {
          padding-bottom: 16px;
      }
      .brand_sliding_box {
          margin-bottom: 16px;
      }
      .brand_section {
          margin-bottom: 30px;
      }
         .brand_main {
      padding-bottom:30px;
      }
      .brand_figure_tags_btn a i {
          display: none;
      }
      .brand_figure_tags_btn a {
          padding: 0px 25px;
           }
      }
         </style>
      <section class="brand_section">
      <div class="custom_container">
      <div class="brand_main">
      <div class="brand_sliding_box"><small><img src="https://static.tossdown.com/site/2f71bbc4-06ab-4021-984d-668c1129110b.png" /></small>
      <h5>Featured Brands</h5>
      <div class="brabd_slider_arrow_box_main">
      <div class="brabd_slider_arrow_box"></div>
      </div>
      </div>
      <div class="brand_list">
      <ul class="brand_slider">
      <li>
      <div class="brand_figure_box">
      <figure><a> <img class="lazy" src="https://static.tossdown.com/images/bfa2095e-b6ef-4132-b4a2-f7fc5001f3c1.png" /> </a></figure>
      <div class="brand_figure_tags">
      <h5>Lorem Ipsum</h5>
      <p>It is a long established fact that a reader will be distracted.</p>
      <div class="brand_figure_tags_btn"><a target="blank">Get directions <i class="far fa-arrow-right" aria-hidden="true"></i></a></div>
      </div>
      </div>
      </li>
      <li>
      <div class="brand_figure_box">
      <figure><a> <img class="lazy" src="https://static.tossdown.com/images/bfa2095e-b6ef-4132-b4a2-f7fc5001f3c1.png" /> </a></figure>
      <div class="brand_figure_tags">
      <h5>Lorem Ipsum</h5>
      <p>Mouth-watering and juicy fried chicken bursting with Louisiana flavor.</p>
      <div class="brand_figure_tags_btn"><a target="blank">Get directions <i class="far fa-arrow-right" aria-hidden="true"></i></a></div>
      </div>
      </div>
      </li>
      <li>
      <div class="brand_figure_box">
      <figure><a> <img class="lazy" src="https://static.tossdown.com/images/bfa2095e-b6ef-4132-b4a2-f7fc5001f3c1.png" /> </a></figure>
      <div class="brand_figure_tags">
      <h5>Lorem Ipsum</h5>
      <p>It is a long established fact that a reader will be distracted.</p>
      <div class="brand_figure_tags_btn"><a target="blank">View details <i class="far fa-arrow-right" aria-hidden="true"></i></a></div>
      </div>
      </div>
      </li>
      <li>
      <div class="brand_figure_box">
      <figure><a> <img class="lazy" src="https://static.tossdown.com/images/bfa2095e-b6ef-4132-b4a2-f7fc5001f3c1.png" /> </a></figure>
      <div class="brand_figure_tags">
      <h5>Lorem Ipsum</h5>
      <p>Mouth-watering and juicy fried chicken bursting with Louisiana flavor.</p>
      <div class="brand_figure_tags_btn"><a target="blank">View details <i class="far fa-arrow-right" aria-hidden="true"></i></a></div>
      </div>
      </div>
      </li>
      </ul>
      </div>
      </div>
      </div>
      </section>
      <script>
           $(document).ready(function () {
             $(".brand_slider").slick({
               slidesToShow:3,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 1500,
               dots: false,
              arrows: true,
      appendArrows: $('.brabd_slider_arrow_box'),
      nextArrow: '<a class="brabd_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
      prevArrow: '<a class="brabd_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
               responsive: [
                 {
                   breakpoint: 1024,
                   settings: {
                     slidesToShow:3,
                     slidesToScroll: 1,
                      autoplay: false,
                     autoplaySpeed: 1500,
                     dots: false,
                     arrows: true,
                   },
                 },
                 {
                   breakpoint: 767,
                   settings: {
                     slidesToShow: 2,
                     slidesToScroll: 1,
                      autoplay: true,
                     autoplaySpeed: 1500,
                          dots: false,
                       arrows: true,
                   },
                 },
                 {
                   breakpoint: 540,
                   settings: {
                     slidesToShow: 2,
                     slidesToScroll: 1,
                       autoplay: true,
                     autoplaySpeed: 1500,
                         dots: false,
                     arrows: true,
                   },
                 },
                 {
                   breakpoint: 400,
                   settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1,
                      autoplay: true,
                   autoplaySpeed: 1500,
                          dots: false,
                      arrows: true,
                   },
                 },
               ],
             });
           });
      
      
      </script>
                      
      
      `
          },
    
      ]
    export default ProductCategorySectionObjects