const HomePageCustomSectionsObjects=[
    {
    id:"HomeCustomSection0",
    name:"HomeCustomSection One",
    height:"800px",
    code:` 
    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    </head>  
  
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
    </style>
<style>
    * {
-web-kit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
padding: 0px;
margin: 0px;
}
html {
scroll-behavior: smooth;
}
body {
float: left;
width: 100%;
margin: 0px;
background:#fff;
padding-top:50px;
}
    .home_custom_section_1_main{
    display:block;
    width:100%;
    padding:0px 15px;
    margin-bottom: 50px;
    }
    .custom_container{
    max-width:1140px;
    margin:0 auto;
    }
    .home_custom_section_1{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: ". .";
        grid-template-rows: auto;
        gap:0px 0px;
        width: 100%;
       height:100%;
       align-items: center;
    }
    .home_custom_section_1_figure_box{
        width: 100%;
        height: 100%;
    }
    .home_custom_section_1_figure_box figure {
        margin: 0px;
        width: 100%;
        height: 100%;
    }
    .home_custom_section_1_figure_box figure img{
        width: 100%;
        height: 100%;
    }
    .home_custom_section_1_body{
      display: flex;
      flex-direction: column;
        width: 100%;
        padding: 30px 15px 30px 80px;
    }
    .home_custom_section_1_heading{
        display: flex;
      flex-direction: column;
        width: 100%;
        padding-bottom: 15px;
    }
    .home_custom_section_1_heading h3{
        font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 45px;
    line-height: 58px;
    text-transform: capitalize;
    color: #150000;
    }
    .home_custom_section_1_des{
        display: flex;
      flex-direction: column;
        width: 100%;
        padding-bottom: 35px;

    }
    .home_custom_section_1_des p{
       font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
      margin: 0px;
    }
    .home_custom_section_1_btn{
        display: flex;
        width: 100%;
    }
    .home_custom_section_1_btn a{
       font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #000000;
        width: 146px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background:  #FCC917;
        border-radius: 3px;
        position: relative;
    }
    
    @media(max-width:1024px){
    .home_custom_section_1_body {
        padding: 30px 15px 30px 35px;
    }
    .home_custom_section_1_figure_box figure img {
     object-fit: contain;
    }
    }
    @media(max-width:768px){
        .home_custom_section_1{
        display: flex;
        flex-direction: column-reverse;
        gap: 0px;
    }
    .home_custom_section_1_body {
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 30px 25px 30px 25px;
    }
    .home_custom_section_1_btn {
justify-content: center;
    }
    .home_custom_section_1_figure_box figure {
     height: 500px;
     }
    }
    @media(max-width:540px){
    .home_custom_section_1_heading h3 {
        font-size: 36px;
        line-height: 46px;
    }
    .home_custom_section_1_body {
        padding: 30px 0px 30px 0px;
    }
    .home_custom_section_1_figure_box figure {
     height: 300px;
     }
    
    }
   
    </style>

   <div class="home_custom_section_1_main">
    <div class="custom_container">
    <div class="home_custom_section_1">
    <div class="home_custom_section_1_figure_box"><figure><img src="https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png"></figure></div>
    <div class="home_custom_section_1_body">
    <div class="home_custom_section_1_heading">
    <h3>Nothing better than Ipsum grilled chicken</h3>
    <div class="home_custom_section_1_under_img"><img src="https://static.tossdown.com/images/f6271232-cba6-4831-a06a-50e19b5ab2a1.png" ></div>
    </div>
    <div class="home_custom_section_1_des">
    <p>A small taste of Peri adds ESSENCE OF AFRICA to the food and makes it magical. 
        The heat of this chilli provokes a sense of excitement and tickles the taste buds beyond imagination. Sizzlo’z chicken is
         served with the sauces filled with proteins, necessary for strength and energy.</p>
    </div>     
    <div class="home_custom_section_1_btn"><a>Learn more</a></div>
    </div>
    </div>
    </div>
    </div>

    
    `
    },

    {
        id:"HomeCustomSection1",
        name:"HomeCustomSection Two",
        height:"800px",
        code:` 
        <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
        </head>  
      
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
        </style>
    <style>
        * {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  width: 100%;
  margin: 0px;
  background:#fff;
  padding-top:50px;
}
        .home_custom_section_2_main{
        display:block;
        width:100%;
        padding:0px 0px;
        margin-bottom: 50px;
        }
        .custom_container{
        max-width:1140px;
        margin:0 auto;
        }
        .home_custom_section_2{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: ". .";
            grid-template-rows: auto;
            gap:0px 10px;
            width: 100%;
           height:100%;
           align-items: center;
           background: #F7941D;
           padding: 75px 20px 47px 39px;
           border-radius: 12px;
        }
        .home_custom_section_2_figure_box{
            width: 100%;
            height: 100%;
        }
        .home_custom_section_2_figure_box figure{
            margin: 0px;
            width: 100%;
            height: 100%;
            position: relative;
        }
        .home_custom_section_2_figure_box figure img{
            width: 100%;

        }
      
        .home_custom_section_2_body{
          display: flex;
          flex-direction: column;
            width: 100%;
            max-width: 460px;
        }
        .home_custom_section_2_heading{
            display: flex;
          flex-direction: column;
            width: 100%;
            padding-bottom: 12px;
        }
        .home_custom_section_2_heading small{
            font-family: 'Poppins', sans-serif;
            font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
color: #FFFFFF;
        padding-bottom:12px;
        }
        .home_custom_section_2_heading h3{
            font-family: 'Poppins', sans-serif;
            font-style: normal;
font-weight: 800;
font-size: 48px;
line-height: 53px;
text-transform: uppercase;
margin: 0px;
color: #FFFFFF;
        }
        .home_custom_section_2_des{
            display: flex;
          flex-direction: column;
            width: 100%;
            padding-bottom: 48px;

        }
        .home_custom_section_2_des p{
           font-family: 'Poppins', sans-serif;
           font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 26px;

color: #F1F1F1;
          margin: 0px;
        }
        .home_custom_section_2_btn{
            display: flex;
            width: 100%;
        }
        .home_custom_section_2_btn a{
           font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #000000;
            width: 146px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            background:  #FCC917;
            border-radius: 3px;
            position: relative;
        }
        
        @media(max-width:1024px){
            .home_custom_section_2_heading h3 {
    font-size: 34px;
    line-height: 44px;
}
.home_custom_section_2_heading small {
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 8px;
}
.home_custom_section_2_des {
    padding-bottom: 38px;
}
        }
        @media(max-width:768px){
            .home_custom_section_2{
            display: flex;
            flex-direction: column-reverse;
            gap: 0px;
            padding: 50px 15px 50px 15px;
        }
        .home_custom_section_2_body {
            justify-content: center;
            text-align: center;
            align-items: center;
            padding: 0px 0px 40px 0px;
          max-width: 540px;
        }
        .home_custom_section_2_btn {
    justify-content: center;
        }
      
        }
        @media(max-width:540px){
            .home_custom_section_2_heading h3 {
    font-size: 26px;
    line-height: 34px;
}
      
        
        }
       
        </style>

       <div class="home_custom_section_2_main">
        <div class="custom_container">
        <div class="home_custom_section_2">
        <div class="home_custom_section_2_figure_box">
            <figure>
                <img src="https://static.tossdown.com/images/e3fdcb8c-d33f-4a8e-b81b-334402d47e89.png">
            </figure>
        </div>
        <div class="home_custom_section_2_body">
        <div class="home_custom_section_2_heading">
            <small>Takeaway & Delivery</small>
           <h3>WASIER, SIMPLER QUICKER</h3>
        </div>
        <div class="home_custom_section_2_des">
        <p>Place your order in just 30 seconds via our app.Download the app, order your meal & enjoy</p>
        </div>     
        <div class="home_custom_section_2_btn"><a>Learn more</a></div>
        </div>
        </div>
        </div>
        </div>


        
        `
        },
 

        {
            id:"HomeCustomSection2",
            name:"HomeCustomSection Three",
            height:"800px",
            code:` 
            <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
            </head>  
          
            <style>
            @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
            </style>
        <style>
            * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      width: 100%;
      margin: 0px;
      background:#fff;
    }
            .home_custom_section_3_main{
            display:block;
            width:100%;
            padding:0px 15px;
            margin-bottom: 50px;
            }
            .custom_container{
            max-width:1140px;
            margin:0 auto;
            }
            .home_custom_section_3{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-areas: ". .";
                grid-template-rows: auto;
                gap:0px 90px;
                width: 100%;
               height:100%;
               align-items: center;
             
            }
    
            .home_custom_section_3_body{
              display: flex;
              flex-direction: column;
                width: 100%;
                padding: 50px 0px 50px 0px;
            }
            .home_custom_section_3_heading{
                display: flex;
              flex-direction: column;
                width: 100%;
                padding-bottom: 36px;
        align-items: center;
            }
    
            .home_custom_section_3_heading h3{
                font-family: 'Poppins', sans-serif;
                font-style: normal;
        font-weight: 800 !important;
        font-size: 36px;
        line-height: 50px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin: 0px;
            }
            .home_custom_section_3_des{
                display: flex;
              flex-direction: column;
                width: 100%;
                padding-bottom: 36px;
            }
            .home_custom_section_3_des_hours_list {
        display: grid;
        gap: 24px 0px;
        width: 100%;
    }
    .home_custom_section_3_des_single_hours_list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .home_custom_section_3_des_single_hours_list span {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #212121;
    }
    .home_custom_section_3_des_single_hours_list small {
        flex-grow: 5;
        line-height: 0;
        text-align: center;
        position: relative;
        padding: 0px 10px 0px 15px;
    }
    .home_custom_section_3_des_single_hours_list small:before {
        content: " ";
        display: block;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    }
            .home_custom_section_3_btn{
                display: flex;
                width: 100%;
                justify-content: center;
            }
            .home_custom_section_3_btn a{
               font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-transform: uppercase;
                color: #000000;
                width: 146px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                background:  #FCC917;
                border-radius: 3px;
                position: relative;
            }
            .home_custom_section_3_map_box {
        width: 100%;
    }
    .home_custom_section_3_map_box iframe{
        width: 100%;
    }
            
            @media(max-width:1024px){
                .home_custom_section_3{
                gap:0px 45px;
             
            }
            }
            @media(max-width:768px){
                .home_custom_section_3{
                display: flex;
                flex-direction: column;
                gap: 0px;
            }
            .home_custom_section_3_map_box iframe {
        height: 300px;
        width: 100%;
    }
            .home_custom_section_3_body {
                justify-content: center;
                text-align: center;
                align-items: center;
                padding: 0px 0px 40px 0px;
            }
            .home_custom_section_3_btn {
           justify-content: center;
            }
          
            }
            @media(max-width:540px){
             
            
            }
           
            </style>
    
           <div class="home_custom_section_3_main">
            <div class="custom_container">
            <div class="home_custom_section_3">
            
            <div class="home_custom_section_3_body">
            <div class="home_custom_section_3_heading">
               <h3>Opening Hours</h3>
            </div>
            <div class="home_custom_section_3_des">
                <div class="home_custom_section_3_des_hours_list">
                    <div class="home_custom_section_3_des_single_hours_list"><span>Monday</span><small></small><span>12:00 PM - 1:00 AM</span></div>
                    <div class="home_custom_section_3_des_single_hours_list"><span>Tuesday</span><small></small><span>12:00 PM - 1:00 AM</span></div>
                    <div class="home_custom_section_3_des_single_hours_list"><span>Wednesday</span><small></small><span>12:00 PM - 1:00 AM</span></div>
                    <div class="home_custom_section_3_des_single_hours_list"><span>Thursday</span><small></small><span>12:00 PM - 1:00 AM</span></div>
                    <div class="home_custom_section_3_des_single_hours_list"><span>Friday</span><small></small><span>12:00 PM - 2:00 AM</span></div>
                    <div class="home_custom_section_3_des_single_hours_list"><span>Saturday</span><small></small><span>12:00 PM - 2:00 AM</span></div>
                    <div class="home_custom_section_3_des_single_hours_list"><span>Sunday</span><small></small><span>12:00 PM - 2:00 AM</span></div>
                    </div>
            </div>     
            <div class="home_custom_section_3_btn"><a>905-567-6666</a></div>
            </div>
    
            <div class="home_custom_section_3_map_box">
                <iframe width="100%" height="550" style="border: 0;" src="https://maps.google.com/maps?q=43.55848510547175,-79.74198860116303&amp;hl=es;z=14&amp;output=embed" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
            </div>
            </div>
            </div>
            </div>
    
            
            `
            },

            {
                id:"HomeCustomSection3",
                name:"HomeCustomSection Four",
                height:"800px",
                code:` 
                 <head>

                <meta charset="utf-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                /> 
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                  
                </head> 
                 <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                </style>
               
            
            <style>
                 * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
                  background: #FDF8F3 !important;
                  padding-top:40px;
                }
            
               
                .home_custom_section_4_main{
                display:block;
                width:100%;
                padding: 0px 15px;
                padding-bottom: 50px;
                }
                .custom_container{
                max-width:1140px;
                margin:0 auto;
                }
                .home_custom_section_4{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-areas: ". .";
                grid-template-rows: auto;
                gap: 15px 0px;
                position: relative;
                width: 100%;
                align-items: center;
                height: 100%;
               
                }
            
                .home_custom_section_4_body{
                    display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 38px 15px 68px;
                box-shadow: 1px 0 0 0 #F58320, 0 1px 0 0 #F58320, 1px 1px 0 0 #F58320, /* Just to fix the corner */ 1px 0 0 0 #F58320 inset, 0 1px 0 0 #F58320 inset;
                flex-direction: column;
                justify-content: center;
                }
                .home_custom_section_4_heading{
                 display: flex;
                    width: 100%;
                    flex-direction: column;
                    max-width: 400px;
                }
                .home_custom_section_4_heading small{
                    font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                padding-bottom: 4px;
                color: #F58320;
                position: relative;
                }
                .home_custom_section_4_heading small:before {
                position: absolute;
                content: "";
                right: 0px;
                top: -28px;
                background-image: url('https://static.tossdown.com/images/83c984c1-b7e6-48e6-900e-f9014772d9e4.png');
                width: 80px;
                height: 81px;
                background-size: contain;
                background-repeat: no-repeat;
            }
                .home_custom_section_4_heading h3{
                    font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 44px;
                line-height: 61px;
                color: #000000;
                padding-bottom: 6px;
                margin: 0px;
                }
                .home_custom_section_4_des{
                    display: grid;
                    width: 100%;
                    padding-bottom: 25px;
                }
                .home_custom_section_4_des p{
                    font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 26px;
                color: #2A2A2A;
         
                margin: 0px;
                }
                .home_custom_section_4_btn{
                    display: flex;
                }
                .home_custom_section_4_btn a {
                    font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #03242D;
                  width: 146px;
            height: 48px;
            background: #D8EAFF;
            border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            .home_custom_section_4_figure_box {
                width: 100%;
            height:100%;
            }
            .home_custom_section_4_figure_box figure {
                margin-bottom: 0px;
                width: 100%;
                box-shadow: 1px 0 0 0 #F58320, 0 1px 0 0 #F58320, 1px 1px 0 0 #F58320, /* Just to fix the corner */ 1px 0 0 0 #F58320 inset, 0 1px 0 0 #F58320 inset;
                padding: 35px;
                height: 100%;
            }
            .home_custom_section_4_figure_box figure img{
                width: 100%;
            height:100%;
            }
         
        
             
                @media(max-width:1024px){
                    .home_custom_section_4_body {
                padding: 35px;
            }
            .home_custom_section_4_heading small:before {
                right: -29px;
            }
            
             
                }
                @media(max-width:768px){
                    .home_custom_section_4{
                display: flex;
                flex-direction: column-reverse;
                gap: 0px;
            }
            .home_custom_section_4_body {
                text-align: center;
            }
            .home_custom_section_4_heading small:before {
                right: 0px;
                top: -20px;
            }
            .home_custom_section_4_heading{
                margin: 0 auto;
            }
            .home_custom_section_4_btn {
                justify-content: center;
            }
          
                }
                @media(max-width:540px){
                    .home_custom_section_4_figure_box figure {
                padding: 15px;
            }
            .home_custom_section_4_body{
                padding: 35px 15px;
            }
            .home_custom_section_4_heading small:before {
                width: 50px;
            }
            .home_custom_section_4_heading h3 {
                font-size: 36px;
                line-height: 46px;
            }
            }
               
                </style>
    
            <div class="home_custom_section_4_main">
            <div class="custom_container">
            <div class="home_custom_section_4">
                <div class="home_custom_section_4_figure_box">
                    <figure><img src="https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png" /></figure>
                    </div>
            <div class="home_custom_section_4_body">
            <div class="home_custom_section_4_heading">
                <small>OUR STORY</small>
                <h3>A Message From the CEO</h3>
            </div>
            <div class="home_custom_section_4_des">
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
            </div>
            <div class="home_custom_section_4_btn"><a>Shop Product</a></div>
            </div>
            
            </div>
           
            </div>
            </div>
    
    
                
                `
                },


                {
                    id:"HomeCustomSection4",
                    name:"HomeCustomSection Five",
                    height:"800px",
                    code:` 
                     <head>
    
                    <meta charset="utf-8" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                    /> 
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                      
                    </head> 
                     <style>
                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                    </style>
                   
                
                    <style>
                    * {
                                  -web-kit-box-sizing: border-box;
                                  -moz-box-sizing: border-box;
                                  box-sizing: border-box;
                                  padding: 0px;
                                  margin: 0px;
                                  font-family: "Poppins", sans-serif;
                                }
                                html {
                                  scroll-behavior: smooth;
                                }
                                body {
                                  float: left;
                                  width: 100%;
                                  margin: 0px;
                                  background: #FDF8F3 !important;
                                  padding-top:40px;
                                }
                            
                  .home_custom_section_8_main{
                  display:block;
                      width: 100%;
                      padding:0px 0px;
                      float: left;
                  margin-bottom:70px;
                  background: #FAF5EB;
                  }
                  .custom_container{
                  max-width:1140px;
                  margin:0 auto;
                  }
                  .home_custom_section_8{
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-template-areas: ". .";
                      grid-template-rows: auto;
                      position: relative;
                      z-index: 1;
                      width: 100%;
                      align-items: center;
                  height:100%;
                  }
                
                  .home_custom_section_8 figure {
                      margin-bottom: 0px;
                      width: 100%;
                      height: 100%;
                  }
                  .home_custom_section_8 figure img{
                      width: 100%;
                      height: 100%;
                   object-fit: cover;
                  }
                  .home_custom_section_8_body_main {
                      justify-content: flex-end;
                      align-items: flex-end;
                      display: flex;
                      width: 100%;
                      padding-right: 90px;
                    padding-left: 15px;
            padding-top: 20px;
            padding-bottom: 20px;
                  }
                  .home_custom_section_8_body{
                      display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              max-width: 460px;
                  }
                  .home_custom_section_8_heading{
                      display: grid;
                      width: 100%;
                  }
                 .home_custom_section_8_heading small {
                font-family: 'Cabin', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-transform: uppercase;
                color: #2D2A38;
            padding-bottom:12px;
            }
            
                  .home_custom_section_8_heading h3{
                     font-family: 'Butler';
            font-style: normal;
            font-weight: 700 !important;
            font-size: 48px;
            line-height: 58px;
            text-transform: capitalize;
            color: #03242D;
            padding-bottom:12px;
            }
            
            
                  .home_custom_section_8_des{
                      display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              padding-bottom: 29px;
                  }
                  .home_custom_section_8_des p{
                     font-family: 'Cabin', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.6);
                margin: 0px;
                  }
            ul.home_custom_section_8_des_list {
                display: grid;
                gap: 8px;
                padding-bottom: 44px;
            }
            ul.home_custom_section_8_des_list li {
                display: flex;
                align-items: center;
                font-family: 'Cabin', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                color: #03242D;
                list-style: none;
            }
            ul.home_custom_section_8_des_list li i {
                background: #016F45;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                margin-right: 12px;
            }
                  .home_custom_section_8_body .home_custom_section_8_shop_btn{
                      width: 100%;
                      display: flex;
                  }
                  .home_custom_section_8_body .home_custom_section_8_shop_btn a{
                font-family: 'Cabin', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #03242D;
                      width: 146px;
            height: 48px;
            background: #D8EAFF;
            border-radius: 2px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                  }
                  @media(max-width:1140px){
                  .home_custom_section_8_body_main {
              padding-right: 40px;
              justify-content: flex-start;
            }
            }
                  @media(max-width:1024px){
                      .home_custom_section_8_body_main {
                      padding-right: 20px;
                  }
                      .home_custom_section_8_body {
              max-width: 100%;
            }
                  .home_custom_section_8_heading h3 {
                      font-size: 30px;
              line-height: 33px;
              padding-bottom: 30px;
                  }
                  .home_custom_section_8_heading h3::before {
              bottom: 17px;
            }
            .home_custom_section_8_des {
              padding-bottom: 17px;
               max-width: 100%; 
            }
                  }
                  @media(max-width:768px){
                      .home_custom_section_8{
                      grid-template-columns: 1fr;
                    grid-template-areas: ".";
                  }
                  .home_custom_section_8_body_main {
                      padding-right:15px;
                      justify-content: center;
                      padding-bottom:40px;
                  }
                  .home_custom_section_8_main{
                      padding-top: 40px;
                     
                  }
                  .home_custom_section_8_body {
                      justify-content: center;
                      text-align: center;
                      max-width: 100%;
                  }
                  .home_custom_section_8_heading h3::before {
              bottom: 17px;
              left: 38%;
            }
                  .home_custom_section_8_body .home_custom_section_8_shop_btn {
              justify-content: center;
            }
            ul.home_custom_section_8_des_list {
                width: 100%;
                justify-content: center;
            }
                     
                  }
                  @media(max-width:540px){
                  .home_custom_section_8_heading h3 {
                      padding-bottom: 14px;
                  }
                  .home_custom_section_8_heading h3::before{
                      display: none;
                  }
                  .home_custom_section_8_body_main {
              padding-bottom: 25px;
            }
            .home_custom_section_8_main {
              padding-top: 25px;
            }
                  }
                
                  </style>
            <div class="home_custom_section_8_main">
            <div class="home_custom_section_8">
            <div class="home_custom_section_8_body_main">
            <div class="home_custom_section_8_body">
            <div class="home_custom_section_8_heading"><small>The best of nature</small>
            <h3>A Natural Routine You Can Trust!</h3>
            </div>
            <div class="home_custom_section_8_des">
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
            </div>
            <ul class="home_custom_section_8_des_list">
            <li><i class="far fa-check"></i>Chemical Free</li>
            <li><i class="far fa-check"></i>100% Natural</li>
            <li><i class="far fa-check"></i>Cruelty Free Formulation</li>
            <li><i class="far fa-check"></i>Halal Ingredients</li>
            <li><i class="far fa-check"></i>No Harsh Chemicals</li>
            </ul>
            <div class="home_custom_section_8_shop_btn"><a>Learn More</a></div>
            </div>
            </div>
            <figure><img src="https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png" /></figure>
            </div>
            </div>
        
                    
                    `
                    },


                    {
                        id:"HomeCustomSection5",
                        name:"HomeCustomSection Fix",
                        height:"800px",
                        code:` 
                         <head>
        
                        <meta charset="utf-8" />
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                        /> 
                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                          
                        </head> 
                         <style>
                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                        </style>
                       
                    
                        <style>
                        * {
                                                             -web-kit-box-sizing: border-box;
                                                             -moz-box-sizing: border-box;
                                                             box-sizing: border-box;
                                                             padding: 0px;
                                                             margin: 0px;
                                                             font-family: "Poppins", sans-serif;
                                                           }
                                                           html {
                                                             scroll-behavior: smooth;
                                                           }
                                                           body {
                                                             float: left;
                                                             width: 100%;
                                                             margin: 0px;
                                                           }
                       .home_custom_section_1.custom-second-cate {
                           right: 0px !important;
                           left: unset;
                       }
                       .home_custom_section_1.custom-second-cate {
                           direction: rtl;
                       }
                       .home_custom_section_1.custom-second-cate .top-layer-hold {
                           left: unset;
                       }
                       .home_custom_section_1.custom-second-cate .new-layer-hold {
                           position: absolute;
                           right: 48%;
                           top: 0px;
                       }
                       .home_custom_section_1.custom-second-cate .home_custom_section_1_body {
                           text-align: left;
                           padding: 160px 65px 150px 120px;
                       }
                       .home_custom_section_1_main {
                           display: block;
                           width: 100%;
                           padding: 60px 15px 140px;
                           background-color: #faf2e3;
                           position: relative;
                       overflow: hidden;
                       }
                       .new-layer-hold img {
                           width: 240px;
                       }
                       .top-layer-hold {
                           position: absolute;
                           left: 0px;
                           top: 0px;
                       }
                       .bottom-layer-hold {
                           position: absolute;
                           bottom: 20px;
                           left: auto;
                           margin-left: 100px;
                       }
                       .middle-layer-hold {
                           position: absolute;
                           right: -65px;
                           top: 34%;
                       }
                       .custom_container{
                       max-width:1140px;
                       margin:0 auto;
                       }
                       .home_custom_section_1{
                           display: grid;
                           grid-template-columns: 1fr 1fr;
                           grid-template-areas: ". .";
                           grid-template-rows: auto;
                           gap: 15px 0px;
                           position: relative;
                           z-index: 1;
                           width: 100%;
                           align-items: center;
                       height:100%;
                       }
                       .home_custom_section_1.custom-second-cate figure {
                         width: 732px;
                           height: 404px;
                          background-image: url('https://tossdown.site/images/86071eb2bcc1bc5ff534f5a70cbd2e1b_1660578642.jpg');
                           background-size: cover;
                           background-repeat: no-repeat;
                           border-radius: 3px;
                           margin: unset !important;
                           left: -75px !important;
                           margin-right: auto !important;
                           position: absolute;
                           bottom: -80px;
                       }
                       
                       .home_custom_section_1 figure img{
                           background-size: cover;
                           background-repeat: no-repeat;
                           width: 100%;
                           height: 100%;
                           background-position: center;
                       }
                       
                       .home_custom_section_1_heading{
                           display: grid;
                           width: 100%;
                           flex-direction: column;
                           gap: 8px 0px;
                       }
                       .home_custom_section_1_heading small {
                           font-style: normal;
                           font-weight: 700;
                           font-size: 13px;
                           line-height: 17px;
                           text-transform: capitalize;
                           color: #9E9E9E;
                           margin-bottom: 18px;
                       font-family: "GT Walsheim Pro Regular";
                       }
                       .home_custom_section_1_heading h3 {
                           font-style: normal;
                           font-weight: 700;
                           font-size: 44px;
                           line-height: 55px;
                           color: #002E3B;
                       }
                       .home_custom_section_1_body {
                           background-color: #ffff !important;
                       }
                       .home_custom_section_1_body p {
                           font-style: normal;
                           font-weight: 400;
                           font-size: 16px;
                           line-height: 24px;
                           color: rgba(0, 46, 59, 0.5);
                           margin-bottom: 20px;
                       }
                       .home_custom_section_1_learn_btn a {
                           background: #FAC703;
                           border-radius: 3px;
                           font-weight: 400;
                           font-size: 13px;
                           text-transform: uppercase;
                           color: #000000;
                           width: 139px;
                           height: 46px;
                           display: inline-block;
                           line-height: 46px;
                           justify-content: center;
                           text-align: center;
                       }
                       .home_custom_section_1_body .more_detail_btn{
                       font-style: normal;
                       font-weight: 500;
                       font-size: 14px;
                       line-height: 21px;
                       color: #000000;
                           width: 174px;
                           height: 48px;
                           display: flex;
                           justify-content: center;
                           align-items: center;
                       background: #fff;
                       border-radius: 3px;
                           position: relative;
                       }
                       
                       @media(max-width:1200px){
                       
                       .home_custom_section_1.custom-second-cate figure {
                           width: 626px !important;
                           height: 320px !important;
                           left: 10px !important;
                       }
                       }
                       @media(max-width:1025px){
                       .home_custom_section_1.custom-second-cate .new-layer-hold {
                           right: 33% !important;
                       }
                       .home_custom_section_1.custom-second-cate figure {
                           width: 525px !important;
                           height: 270px !important;
                           left: 10px !important;
                       }
                       .home_custom_section_8_body {
                           padding: 0px 35px 0px 0px;
                       gap: 18px 0px;
                       }
                       .home_custom_section_8_heading {
                           gap: 5px 0px;
                       }
                       .home_custom_section_8 figure {
                           height: 450px;
                       }
                       .home_custom_section_8_body p {
                           padding-bottom: 0px;
                       }
                       }
                       @media (min-width: 768px) and (max-width: 992px) {
                       .home_custom_section_1.custom-second-cate figure {
                       width: 100% !important;
                           height: 100% !important;
                           left: 10px !important;
                           position: unset;
                       }
                       .home_custom_section_1_heading h3 {
                           font-size: 32px !important;
                           line-height: 45px !important;
                       }
                       .home_custom_section_1.custom-second-cate .home_custom_section_1_body {
                           padding: 110px 50px 110px 50px  !important;
                       }
                       .new-layer-hold img {
                           width: 130px !important;
                       }
                       .home_custom_section_1_main {
                           padding: 60px 15px 60px  !important;
                       }
                       }
                       @media(max-width:767px){
                       
                       
                       .home_custom_section_1_body p {
                           font-size: 14px  !important;
                       }
                       .home_custom_section_1.custom-second-cate .home_custom_section_1_heading h3 {
                           margin-bottom: 15px !important;
                       }
                       .home_custom_section_1.custom-second-cate .home_custom_section_1_body{
                           padding: 130px 30px 60px 30px !important;
                       }
                       
                       .home_custom_section_1.custom-second-cate figure {
                           width: 100% !important;
                           height: 270px !important;
                           left: 0px !important;
                           position: unset !important;
                           bottom: 0px !important;
                       }
                       .home_custom_section_1_main {
                           display: block;
                           width: 100%;
                           padding: 50px 15px 40px;
                           background-color: #faf2e3;
                           position: relative;
                           overflow: hidden;
                           height: 880px;
                       }
                       .home_custom_section_1.custom-second-cate {
                       direction: unset;
                           background-color: #fff;
                           height: 485px;
                           margin-bottom: 30px;
                       }
                       .home_custom_section_1.custom-second-cate .home_custom_section_1_body {
                          text-align: center;
                           padding: 160px 30px 85px 30px;
                           display: block;
                           background-color: #FFF;
                           margin-bottom: 30px;
                       }
                       .home_custom_section_1_heading h3 {
                           font-size: 30px !important;
                       }
                       .home_custom_section_1 {
                           grid-template-columns: 1fr !important;
                           grid-template-areas: "." !important;
                       }
                       
                       .home_custom_section_8_learn_btn {
                           width: 100%;
                           display: flex;
                           justify-content: center;
                       }
                       .home_custom_section_8_body {
                           justify-content: center;
                           text-align: center;
                       }
                       .home_custom_section_8{
                           display: flex;
                           flex-direction: column;
                           gap: 0px;
                       }
                       .home_custom_section_8 figure {
                           height: 300px;
                       background-position: center;
                           background-image: url('https://tossdown.site/images/f585a1ea1dbdec566fd653eac6573897_1654259961.jpg');
                       border-radius: 10px;
                       }
                       .home_custom_section_8_body {
                           padding:0px 25px 40px 25px;
                       }
                       
                       }
                       @media(max-width:540px){
                       .home_custom_section_8_heading h3 {
                           font-size: 36px;
                           line-height: 46px;
                       }
                       .home_custom_section_8_body {
                           padding: 0px 0px 40px 0px;
                       }
                       }
                       @media(max-width:450px){
                       .home_custom_section_8 figure {
                           height: 200px;
                       background-position: center;
                           background-image: url('https://tossdown.site/images/7862144e9c5214c006c9535e79b9b4ad_1660295309.jpg');
                       border-radius: 10px;
                       }
                       }
                       </style>
                       <div class="home_custom_section_1_main">
                       <div class="custom_container">
                       <div class="home_custom_section_1 custom-second-cate">
                       <div class="new-layer-hold"><img src="https://tossdown.site/images/deb3d5289482c50079cea705995a78a6_1660564979.png" data-aos="fade-left" data-aos-offset="100" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="100" data-aos-once="true" /></div>
                       <div class="home_custom_section_1_body">
                       <div class="bottom-layer-hold" data-aos="zoom-in" data-aos-offset="150" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="80" data-aos-once="true"><img src="https://tossdown.site/images/26ba5b8b0ee6f688a3d61fc5d49af3f4_1660297089.png" /></div>
                       <div class="home_custom_section_1_heading">
                       <h3>School Toys</h3>
                       </div>
                       <div class="middle-layer-hold" data-aos="zoom-in" data-aos-offset="100" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="100" data-aos-once="true"><img src="https://tossdown.site/images/338b0f37fce96ca6a713e560cd928f0b_1660297475.png" /></div>
                       <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                       <div class="home_custom_section_1_learn_btn"><a>LEARN MORE</a></div>
                       </div>
                       <div class="top-layer-hold" data-aos="zoom-in" data-aos-offset="100" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="100"><img src="https://tossdown.site/images/52cd184e85403c7c1a8efe4f9f948ded_1660297073.png" /></div>
                       <figure></figure>
                       </div>
                       </div>
                       </div>
            
                        
                        `
                        },
    
                        {
                            id:"HomeCustomSection6",
                            name:"HomeCustomSection Seven",
                            height:"800px",
                            code:` 
                             <head>
            
                            <meta charset="utf-8" />
                            <meta
                              name="viewport"
                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                            /> 
                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                              
                            </head> 
                             <style>
                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                            </style>
                           
                        
                            <style>
                            <style>
                            * {
                                                                                         -web-kit-box-sizing: border-box;
                                                                                         -moz-box-sizing: border-box;
                                                                                         box-sizing: border-box;
                                                                                         padding: 0px;
                                                                                         margin: 0px;
                                                                                         font-family: "Poppins", sans-serif;
                                                                                       }
                                                                                       html {
                                                                                         scroll-behavior: smooth;
                                                                                       }
                                                                                       body {
                                                                                         float: left;
                                                                                         width: 100%;
                                                                                         margin: 0px;
                                                                                         background:#fff;
                                                                                       }
                            .box-text {
                                justify-content: center;
                                text-align: center;
                            }
                            .contact-sectionOne-box-heading h3 {
                                font-size: 29px;
                                line-height: 36px;
                                color: #000;
                                font-weight: 500;
                                margin: 0px;
                                margin-bottom: 30px;
                            }
                                button#submit-recaptcha:hover {
                                color: #fff;
                            }
                                .contact_us_form_submit_btn button {
                                color: #ffffff;
                                background: transparent;
                                font-style: normal;
                                font-size: 15px;
                                line-height: 19px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 56px;
                                cursor: pointer;
                                border: 2px solid #fac703;
                                background-color:#fac703;
                                border-radius: 4px;
                            }
                                .modal-body {
                                padding-top: 0px !important;
                            }
                                .contact-sectionOne-box-heading h3 {
                                font-size: 29px;
                                line-height: 36px;
                                color: #000;
                                font-weight: 500;
                                margin: 0px;
                            }
                                .modal-header .close {
                                padding: 1rem 1rem;
                                margin: -1rem -1rem -1rem auto;
                                display: block !important;
                                font-size: 47px;
                                position: absolute;
                                right: 20px;
                                top: 4px;
                            }
                                .modal-header {
                                border: unset;
                                padding: 5px !important;
                            }
                            .contact_us_form_fields input {
                                border: 1px solid rgba(0, 0, 0, 0.16);
                                box-sizing: border-box;
                                height: 48px;
                                padding: 0px 10px 0px 15px;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 13px;
                                line-height: 19px;
                                color: rgba(0, 0, 0, 0.9);
                                width: 100%;
                                border-radius: 4px;
                            }
                            .contact_us_form_fields textarea {
                                border: 1px solid rgba(0, 0, 0, 0.16);
                                box-sizing: border-box;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 13px;
                                line-height: 19px;
                                color: rgba(0, 0, 0, 0.9);
                                padding: 15px 15px 15px 15px;
                                width: 100%;
                                height: 90px;
                                border-radius: 4px;
                            }
                            section.custome-section-new {
                                color: #002e3b;
                                width: 100%;
                            }
                            .custom-section-area {
                                display: grid;
                                grid-template-columns: 1.1fr 1fr 1.1fr;
                                grid-template-areas: ". . .";
                                grid-gap: 58px;
                                height: 550px;
                            }
                            .box-content {
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                align-items: center;
                                height: 100%;
                            padding: 112px 0px 157px 0px;
                            }
                            .box-img {
                                padding-bottom: 16px;
                            }
                            .box-text h4 {
                            font-size: 40px;
                                line-height: 56px;
                                text-align: center;
                                text-transform: uppercase;
                                color: #262B40;
                                font-weight: bold;
                                margin-bottom: 16px;
                            }
                            .box-text p {
                                text-align: center;
                            }
                            .box-button a:hover {
                                background-color: #002e3b;
                                color: #fff;
                            }
                            
                            .box-button a {
                                text-decoration: none;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                                text-transform: uppercase;
                                color: #002e3b;
                                width: 201px;
                                height: 49px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: #fac703;
                                margin-top: 30px;
                                border-radius: 3px;
                            }
                            .banner-img-one {
                                background-image: url(https://static.tossdown.com/site/b74116dd-09de-4505-b0eb-0c89a9eed719.jpg);
                               width: 270px;
                                height: 448px;
                            }
                            .banner-img-two-box {
                                display: flex;
                                height: 100%;
                                justify-content: flex-end;
                                align-items: flex-end;
                            margin-left: 12px;
                            }
                            .banner-img-two {
                                background-image: url(https://static.tossdown.com/site/60a1dd2e-5de8-47fa-b522-295acde6ab6b.jpg);
                            width: 270px;
                                height: 448px;
                            }
                            .banner-img-three-box {
                                display: flex;
                                height: 100%;
                                align-items: flex-end;
                            margin-right: 12px;
                            }
                            .banner-img-three{
                                background-image: url(https://static.tossdown.com/site/7180e062-a812-4fd6-8276-9fb35048650a.png);
                            width: 270px;
                                height: 448px;
                            }
                            .banner-img-four{
                                background-image: url(https://static.tossdown.com/site/4ae56ada-5673-49a6-89ec-d9d86191ecca.png);
                            width: 270px;
                                height: 448px;
                            }
                            
                            .banner-section-box-one {
                                display: flex;
                            }
                            .banner-section-box-two{
                                display: flex;
                            }
                            .banner-section-box-three{
                                display: flex;
                            }
                            @media(max-width:1024px){
                            .box-content {
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                align-items: center;
                                height: 100%;
                            padding: 0px 0px 0px 0px;
                            }
                            .banner-img-two-box {
                            
                                width: 100%;
                                height: 100%;
                                justify-content: flex-end;
                                align-items: flex-end;
                                margin-left: 12px;
                                display: none;
                            }
                            .banner-img-three-box {
                                width: 100%;
                                height: 100%;
                                align-items: flex-end;
                                margin-right: 12px;
                                display: none;
                            }
                            }
                            @media (min-width: 768px) and (max-width: 992px) {
                            .banner-img-four {
                                width: 100% !important;
                            }
                            .banner-img-one {
                                width: 100% !important;
                            }
                            .custom-section-area {
                                height: 510px !important;
                            }
                            }
                            @media(max-width: 768px){
                            .box-text h4 {
                                font-size: 30px !important;
                                line-height: 35px !important;
                                padding: 0px !important;
                            }
                            .box-content {
                                padding: 0px 15px 0px 15px !important;
                            }
                            .banner-img-four {
                                width: 100%  !important;
                                height: 250px !important;
                            }
                            .banner-img-three {
                             width: 100%  !important;
                                height: 250px !important;
                            }
                            .custom-section-area {
                                grid-template-columns: 1fr;
                                grid-template-areas: ".";
                            
                            }
                            .banner-section-box-two {
                                padding-top: 50px;
                            }
                            .banner-img-four {
                               
                                background-size: cover;
                            }
                            .banner-img-three {
                                background-size: cover;
                            }
                            .banner-img-three-box {
                                width: 100%;
                                height: 100%;
                                align-items: flex-end;
                                margin-right: 12px;
                            display: block;
                            background-size: cover;
                            }
                            
                            .banner-section-box-one {
                                display: none;
                            }
                            
                            
                            }
                            @media(max-width: 540px){
                            section.custome-section-new {
                                margin-bottom: 50px;
                            }
                            .box-text h4 {
                                font-weight: 800;
                                font-size: 30px;
                                line-height: 55px;
                                text-align: center;
                                text-transform: uppercase;
                                color: #262B40;
                              padding: 0px 5px 28px 5px;
                            }
                            }
                                </style>
                            <section class="custome-section-new">
                            <div class="custom-section-area">
                            <div class="banner-section-box-one">
                            <div class="banner-img-one" data-aos="fade-down" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"></div>
                            <div class="banner-img-two-box">
                            <div class="banner-img-two" data-aos="fade-up" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"></div>
                            </div>
                            </div>
                            <div class="banner-section-box-two">
                            <div class="box-content">
                            <div class="box-text"><img src="https://static.tossdown.com/site/481c5a93-71de-4d23-8b08-28e9687b166d.png" />
                            <p>The new way for smart learning, our DIY studio helps children discover new hobbies!</p>
                            </div>
                            <div class="box-button"><a data-toggle="modal" data-target="#exampleModal">Make An Appoinment</a></div>
                            </div>
                            </div>
                            <div class="banner-section-box-three">
                            <div class="banner-img-three-box">
                            <div class="banner-img-three" data-aos="fade-down" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"></div>
                            </div>
                            <div class="banner-img-four" data-aos="fade-up" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"></div>
                            </div>
                            </div>
                            </section>
                
                            
                            `
                            },

                            {
                                id:"HomeCustomSection7",
                                name:"HomeCustomSection Eight",
                                height:"800px",
                                code:` 
                                 <head>
                
                                <meta charset="utf-8" />
                                <meta
                                  name="viewport"
                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                /> 
                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                               
                                </head> 
                                 <style>
                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                </style>
                               
                                <style>
                                * {
                              -web-kit-box-sizing: border-box;
                              -moz-box-sizing: border-box;
                              box-sizing: border-box;
                              padding: 0px;
                              margin: 0px;
                              }
                              html {
                              scroll-behavior: smooth;
                              }
                              body {
                              float: left;
                              width: 100%;
                              margin: 0px;
                              background:#fff;
                              }
                              .general-custom-shape-left {
                              position: absolute;
                                  left: -20px;
                                  top: 10px;
                              }
                              .general-custom-shape-left img {
                              }
                              .general-custom-shape-right {
                                  position: absolute;
                                  right: 0px;
                                  top: 15px;
                              }
                              .general-custom-shape-right img {
                                  width: 210px;
                              }
                              .general-custom-shape-right img {
                                  width: 160px;
                              }
                              .general-custom-third-shapes-right img {
                                  width: 180px;
                              }
                              .general-custom-third-shapes-right {
                                  position: absolute;
                                  left: 27px;
                                  bottom: -9px;
                              }
                              .home_custom_section_two {
                                  padding: 60px 0px 40px;
                                  background-color: #f2e6ff;
                                  overflow: hidden;
                              }
                              .cards-box-img-left {
                                  display: flex;
                                  flex-direction: column;
                                  justify-content: center;
                                  text-align: center;
                                  padding: 0px 50px;
                              position: relative;
                              }
                              .cards-box-img-left p {
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 16px;
                                  line-height: 24px;
                                  color: rgba(0, 46, 59, 0.5);
                                  margin-bottom: 20px;
                              }
                              .cards-box-img-left h2 {
                                  padding-bottom: 0px;
                                  margin-bottom: 16px !important;
                                  font-style: normal;
                                  font-weight: bold !important;
                                  font-size: 40px;
                                  line-height: 56px;
                                  color: #002E3B;
                              }
                              .main-cards-box:hover .on-hover {
                                  opacity: 1.3;
                              }
                              .home_custom_section_two .container {
                                  padding: 0px;
                              }
                              .main-cards-box {
                                  position: relative;
                                  margin-bottom: 15px;
                              }
                              .main-cards-box .on-hover {
                                  position: absolute;
                                  top: 0;
                                  height: 100%;
                                  width: 100%;
                                  display: flex;
                                  justify-content: center;
                                  background-color: #0f3a46bd;
                                  align-items: center;
                                  cursor: pointer;
                                  opacity: 0;
                                  transition: 0.5s ease;
                                  z-index: 111111;
                              }
                              .text {
                                  width: 100%;
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                              }
                              .on-hover a.cate-btn {
                                  background: #FAC703;
                                  border-radius: 3px;
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 12px;
                                  text-transform: uppercase;
                                  width: 195px;
                                  height: 44px;
                                  color: #000000;
                                  display: block;
                                  line-height: 46px;
                                  justify-content: center;
                                  text-align: center;
                              }
                              .main-cards-box:hover {
                                  opacity: 1.3;
                              }
                              .main-cards-box .card-box-img-hold img {
                                  width: 100%;
                                  height: auto;
                              }
                              
                              .main-cards-box .card-box-content-hold p.category-name {
                                  font-weight: 400;
                                  font-size: 11px;
                                  line-height: 15px;
                                  color: rgba(0, 0, 0, 0.5);
                                  margin-top: 16px;
                              }
                              .main-cards-box .card-box-content-hold h2 {
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 16px;
                                  line-height: 21px;
                                  color: #002E3B;
                                  margin-top: 6px;
                              }
                              @media (max-width: 1200px) {
                              .custom_container {
                                  max-width: 1110px !important;
                                  margin: 0 auto;
                              }
                              .container {
                                  max-width: 1110px !important;
                                  margin: 0 auto;
                              }
                              }
                              @media (max-width: 1025px) {
                              .descri-layer-second {
                                  right: 0px !important;
                              }
                              .home_custom_section_two {
                                  padding: 60px 15px 40px;
                              }
                              }
                              @media (max-width: 767px) {
                              .cards-box-img-left p{
                              font-size: 14px !important;
                              }
                              .main-cards-box {
                                  margin-bottom: 0px !important;
                              }
                              .main-cards-box .card-box-img-hold img {
                                  height: auto;
                              }
                              .cards-box-img-left h2 {
                                  font-size: 26px !important;
                              }
                              
                              .cards-box-img-left {
                                  padding: 150px 20px  !important;
                              }
                              .general-custom-third-shapes-right img {
                                  width: 130px;
                              }
                              .general-custom-shape-right img {
                                  width: 130px;
                              }
                              }
                              </style>
                              <div class="home_custom_section_two">
                              <div class="container">
                              <div class="row">
                              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                              <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 inner_class_mobile_responsive_grid " data-aos="fade-right" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                              <div class="main-cards-box">
                              <div class="card-box-img-hold"><img src="https://static.tossdown.com/site/d60bcef9-bd0c-43de-9bd1-53f659136157.jpg" /></div>
                              <div class="on-hover">
                              <div class="text"><a class="cate-btn"  target="_blank" rel="noopener">View Catalogue</a></div>
                              </div>
                              </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 inner_class_mobile_responsive_grid " data-aos="fade-right" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                              <div class="main-cards-box">
                              <div class="card-box-img-hold"><img src="https://tossdown.site/images/86d6f55d3dd8d473aa0a98628e66b7bf_1660387878.jpg" /></div>
                              <div class="on-hover">
                              <div class="text"><a class="cate-btn" target="_blank" rel="noopener">View Catalogue</a></div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="margin-bottom: 15px; background-color: #fff; overflow: hidden;">
                              <div class="cards-box-img-left  align-items-center h-100">
                              <div class="general-custom-shape-left"><img /></div>
                              <div class="general-custom-shape-right" data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"><img src="https://static.tossdown.com/site/2bf6a971-8e3e-4fd9-bdd1-40ffd78030c3.png" /></div>
                              <h2>General Toys</h2>
                              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                              <div class="home_custom_section_1_learn_btn"><a href="/general-toys">VIEW DETAILS</a></div>
                              </div>
                              <div class="general-custom-third-shapes-right" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"><img src="https://static.tossdown.com/site/fe1ea370-2653-468f-9fb5-8e3e57f59ddc.png" /></div>
                              </div>
                              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                              <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 inner_class_mobile_responsive_grid " data-aos="fade-left" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                              <div class="main-cards-box">
                              <div class="card-box-img-hold"><img src="https://static.tossdown.com/site/740971a5-42ae-4422-a9d3-c1579a69a393.jpg" /></div>
                              <div class="on-hover">
                              <div class="text"><a class="cate-btn"  target="_blank" rel="noopener">View Catalogue</a></div>
                              </div>
                              </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 inner_class_mobile_responsive_grid " data-aos="fade-left" data-aos-duration="800" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                              <div class="main-cards-box">
                              <div class="card-box-img-hold"><img src="https://static.tossdown.com/site/f9dde4b4-9e77-44db-bfc2-6b2479d43211.jpg" /></div>
                              <div class="on-hover">
                              <div class="text"><a class="cate-btn" target="_blank" rel="noopener">View Catalogue</a></div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              </div>
                    
                                
                                `
                                },
                             

                              
                            {
                                id:"HomeCustomSection8",
                                name:"HomeCustomSection Nine",
                                height:"800px",
                                code:` 
                                 <head>
                
                                <meta charset="utf-8" />
                                <meta
                                  name="viewport"
                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                /> 
                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                               
                                </head> 
                                 <style>
                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                </style>
                               
                                <style>
                                * {
                                                              -web-kit-box-sizing: border-box;
                                                              -moz-box-sizing: border-box;
                                                              box-sizing: border-box;
                                                              padding: 0px;
                                                              margin: 0px;
                                                              }
                                                              html {
                                                              scroll-behavior: smooth;
                                                              }
                                                              body {
                                                              float: left;
                                                              width: 100%;
                                                              margin: 0px;
                                                              background:#fff;
                                                              }
                                .home_custom_section_1_main.custom-third-cate {
                                    background-color: #daede7;
                                    padding: 60px 15px 140px;
                                overflow: hidden;
                                }
                                .custom-third-cate .home_custom_section_1_body {
                                    background-color: #fff;
                                }
                                .new-layer-hold {
                                    position: absolute;
                                    top: 0px;
                                }
                                .home_custom_section_1_main.custom-third-cate .new-layer-hold {
                                    right: 28%;
                                }
                                .new-layer-hold img {
                                    width: 240px;
                                }
                                .top-layer-hold {
                                    position: absolute;
                                    left: 0px;
                                    top: 0px;
                                }
                                .bottom-layer-hold {
                                    position: absolute;
                                    bottom: 20px;
                                    left: auto;
                                    margin-left: 100px;
                                }
                                .middle-layer-hold {
                                    position: absolute;
                                    right: -65px;
                                    top: 34%;
                                }
                                .custom_container{
                                max-width:1140px;
                                margin:0 auto;
                                }
                                .home_custom_section_1{
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    grid-template-areas: ". .";
                                    grid-template-rows: auto;
                                    gap: 15px 0px;
                                    position: relative;
                                    z-index: 1;
                                    width: 100%;
                                    align-items: center;
                                height:100%;
                                }
                                .home_custom_section_1_main.custom-third-cate figure {
                                  width: 732px;
                                    height: 404px;
                                   background-image: url('https://static.tossdown.com/site/images/c89080e89ee5e94f0c90766d70c204e3_1660580180.jpg');
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    border-radius: 3px;
                                    margin: auto;
                                    position: absolute;
                                    right: -75px;
                                    bottom: -80px;
                                }
                                
                                .home_custom_section_1 figure img{
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    width: 100%;
                                    height: 100%;
                                    background-position: center;
                                }
                                .home_custom_section_1_body{
                                display: grid;
                                    width: 100%;
                                    flex-direction: column;
                                    gap: 24px 0px;
                                    padding: 160px 120px 160px 65px;
                                    background-color: #faf2e3;
                                    text-align: right;
                                }
                                .home_custom_section_1_heading{
                                    display: grid;
                                    width: 100%;
                                    flex-direction: column;
                                    gap: 8px 0px;
                                }
                                .home_custom_section_1_heading small {
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 13px;
                                    line-height: 17px;
                                    text-transform: capitalize;
                                    color: #9E9E9E;
                                    margin-bottom: 18px;
                                font-family: "GT Walsheim Pro Regular";
                                }
                                .home_custom_section_1_heading h3 {
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 44px;
                                    line-height: 55px;
                                    color: #002E3B;
                                }
                                .home_custom_section_1_body p {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 15px;
                                    line-height: 24px;
                                    color: rgba(0, 46, 59, 0.5);
                                    margin-bottom: 20px;
                                }
                                .home_custom_section_1_learn_btn a {
                                    background: #FAC703;
                                    border-radius: 3px;
                                    font-weight: 400;
                                    font-size: 13px;
                                    text-transform: uppercase;
                                    color: #000000;
                                    width: 139px;
                                    height: 46px;
                                    display: inline-block;
                                    line-height: 46px;
                                    justify-content: center;
                                    text-align: center;
                                }
                                .home_custom_section_1_body .more_detail_btn{
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #000000;
                                    width: 174px;
                                    height: 48px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                background: #F5C628;
                                border-radius: 3px;
                                    position: relative;
                                }
                                
                                
                                @media(max-width:1200px){
                                .home_custom_section_1_main.custom-third-cate figure {
                                    width: 626px !important;
                                    height: 320px !important;
                                    right: 10px !important;
                                }
                                }
                                
                                @media(max-width:1025px){
                                .home_custom_section_1_main.custom-third-cate .new-layer-hold {
                                    left: 0%  !important;
                                }
                                .home_custom_section_1_body {
                                    padding: 105px 48px 65px 48px !important;
                                }
                                .home_custom_section_1_main.custom-third-cate figure {
                                    width: 525px !important;
                                    height: 320px !important;
                                    right: 10px !important;
                                }
                                .home_custom_section_8_body {
                                    padding: 0px 35px 0px 0px;
                                gap: 18px 0px;
                                }
                                .home_custom_section_8_heading {
                                    gap: 5px 0px;
                                }
                                .home_custom_section_8 figure {
                                    height: 450px;
                                }
                                .home_custom_section_8_body p {
                                    padding-bottom: 0px;
                                }
                                }
                                @media (min-width: 768px) and (max-width: 992px) {
                                .home_custom_section_1_main.custom-third-cate figure {
                                    width: 100% !important;
                                    height: 100% !important;
                                    right: 10px !important;
                                    position: unset !important;
                                }
                                .home_custom_section_1_heading h3 {
                                    font-size: 32px !important;
                                    line-height: 45px !important;
                                }
                                .home_custom_section_1.custom-second-cate .home_custom_section_1_body {
                                    padding: 110px 50px 110px 50px  !important;
                                }
                                .new-layer-hold img {
                                    width: 130px !important;
                                }
                                .home_custom_section_1_main {
                                    padding: 60px 15px 60px  !important;
                                }
                                }
                                @media(max-width:767px){
                                .bottom-layer-hold {
                                    margin-left: 40px  !important;
                                }
                                .custom-third-cate .home_custom_section_1_body {
                                    background-color: #fff;
                                    padding: 145px 30px 60px;
                                }
                                .home_custom_section_1_heading h3 {
                                    font-size: 25px !important;
                                    margin-bottom: 0px !important;
                                    padding-bottom: 0px !important;
                                    line-height: 30px !important;
                                    margin-top: 30px !important;
                                }
                                .home_custom_section_1_heading h3 {
                                    font-size: 28px !important;
                                }
                                .home_custom_section_1_main.custom-third-cate .new-layer-hold {
                                    right: 50% !important;
                                }
                                .new-layer-hold img {
                                    width: 160px !important;
                                }
                                
                                .home_custom_section_1_main.custom-third-cate {
                                    padding: 50px 15px 40px !important;
                                    height: 920px !important;
                                }
                                .home_custom_section_1 {
                                    direction: unset  !important;
                                    background-color: #fff  !important;
                                    height: 485px  !important;
                                    margin-bottom: 30px  !important;
                                    justify-content: center  !important;
                                }
                                .home_custom_section_1_body {
                                    text-align: center !important;
                                }
                                .home_custom_section_1_main.custom-third-cate figure {
                                    width: 100% !important;
                                    height: 210px !important;
                                    right: 0px !important;
                                    position: unset !important;
                                }
                                
                                .home_custom_section_8_learn_btn {
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                }
                                .home_custom_section_8_body {
                                    justify-content: center;
                                    text-align: center;
                                }
                                .home_custom_section_8{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0px;
                                }
                                .home_custom_section_8 figure {
                                    height: 300px;
                                background-position: center;
                                    background-image: url('https://static.tossdown.com/site/images/f585a1ea1dbdec566fd653eac6573897_1654259961.jpg');
                                border-radius: 10px;
                                }
                                .home_custom_section_8_body {
                                    padding:0px 25px 40px 25px;
                                }
                                
                                }
                                @media(max-width:540px){
                                .home_custom_section_8_heading h3 {
                                    font-size: 36px;
                                    line-height: 46px;
                                }
                                .home_custom_section_8_body {
                                    padding: 0px 0px 40px 0px;
                                }
                                }
                                @media(max-width:450px){
                                .home_custom_section_8 figure {
                                    height: 200px;
                                background-position: center;
                                    background-image: url('https://static.tossdown.com/site/images/7862144e9c5214c006c9535e79b9b4ad_1660295309.jpg');
                                border-radius: 10px;
                                }
                                }
                                </style>
                                <div class="home_custom_section_1_main custom-third-cate">
                                <div class="custom_container">
                                <div class="home_custom_section_1">
                                <div class="new-layer-hold" data-aos="zoom-in" data-aos-offset="100" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="200" data-aos-once="true"><img src="https://tossdown.site/images/882b598a23ea7997c2255b991a503e8e_1660566754.png" /></div>
                                <div class="home_custom_section_1_body">
                                <div class="bottom-layer-hold" data-aos="zoom-in" data-aos-offset="100" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="200" data-aos-once="true"><img src="https://tossdown.site/images/26ba5b8b0ee6f688a3d61fc5d49af3f4_1660297089.png" /></div>
                                <div class="home_custom_section_1_heading">
                                <h3>Educational Toys</h3>
                                </div>
                                <div class="middle-layer-hold" data-aos="zoom-in" data-aos-offset="100" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="200" data-aos-once="true"><img src="https://tossdown.site/images/338b0f37fce96ca6a713e560cd928f0b_1660297475.png" /></div>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                <div class="home_custom_section_1_learn_btn"><a>LEARN MORE</a></div>
                                </div>
                                <div class="top-layer-hold" data-aos="zoom-in" data-aos-offset="100" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-delay="200" data-aos-once="true"><img src="https://tossdown.site/images/52cd184e85403c7c1a8efe4f9f948ded_1660297073.png" /></div>
                                <figure></figure>
                                </div>
                                </div>
                                </div>
                    
                                
                                `
                                },
                               


                                {
                                    id:"HomeCustomSection9",
                                    name:"HomeCustomSection Ten",
                                    height:"800px",
                                    code:` 
                                     <head>
                    
                                    <meta charset="utf-8" />
                                    <meta
                                      name="viewport"
                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                    /> 
                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                   
                                    </head> 
                                     <style>
                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                    </style>
                                    <style>
                                    * {
                                                                                                      -web-kit-box-sizing: border-box;
                                                                                                      -moz-box-sizing: border-box;
                                                                                                      box-sizing: border-box;
                                                                                                      padding: 0px;
                                                                                                      margin: 0px;
                                                                                                      }
                                                                                                      html {
                                                                                                      scroll-behavior: smooth;
                                                                                                      }
                                                                                                      body {
                                                                                                      float: left;
                                                                                                      width: 100%;
                                                                                                      margin: 0px;
                                                                                                      background:#fff;
                                                                                                      }
                                    section.custome-section-new {
                                      width:100%;
                                     background-image :url(https://static.tossdown.com/site/8629d97d-436b-44c4-864a-15ec1446b119.png);
                                    max-width:1140px;
                                    margin: 0 auto;
                                    background-repeat: no-repeat;
                                        background-position: center;
                                    background-size: cover;
                                    height: 469px;
                                    }
                                    .box-section{
                                          padding: 50px 0px 0px 0px;
                                    }
                                    .custom-section-area {
                                        display: grid;
                                        grid-template-columns: 1fr 1.3fr 1fr;
                                        grid-template-areas: ". . .";
                                    grid-gap: 12px;
                                    padding: 0px 37px 0px 62px; 
                                    position: relative;
                                    
                                    
                                    }
                                    .box-content {
                                        display: flex;
                                        justify-content: center;
                                        flex-direction: column;
                                        align-items: center;
                                        height: 100%;
                                    
                                    }
                                    .box-content-one {
                                        display: flex;
                                        justify-content: center;
                                        flex-direction: column;
                                        align-items: center;
                                        height: 100%;
                                    
                                    
                                    }
                                    .box-title small {
                                    font-family: 'Manrope' !important;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 19px;
                                    letter-spacing: 0.1em;
                                    text-transform: uppercase;
                                    color: #262161;
                                    }
                                    .box-heading h4 {
                                       font-family: 'Austin News Headline Web';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 56px;
                                    line-height: 58px;
                                    letter-spacing: 0.05em;
                                    color: #262161;
                                    margin-bottom:12px;
                                    }
                                    .box-text p {
                                    font-family: 'Manrope' !important;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #262161;
                                    margin-bottom:16px;
                                    }
                                    .years-img{
                                    margin-bottom:40px;
                                    }
                                    .box-button {
                                        width: 275px;
                                        height: 51px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background: #FF5B35;
                                    border-radius: 50px;
                                    }
                                    .box-button a {
                                    font-family: 'Manrope' !important;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 19px;
                                    color: #FFFFFF;
                                        text-decoration: none;
                                    
                                    }
                                    .box-img-2{
                                    display:none;
                                    }
                                    .box-img-1 {
                                        height: 100%;
                                        width: 100%;
                                    }
                                    .box-img-3 {
                                        height: 100%;
                                        width: 100%;
                                    }
                                    .box-img-2 img {
                                        width: 288px;
                                        height: 261px;
                                    }
                                    .box-img-3 img {
                                        width: 288px;
                                        height: 261px;
                                    }
                                    .box-img-1 img {
                                       width: 191px;
                                        height: 225px;
                                    
                                    }
                                    .banner-section-box-three {
                                        height: 100%;
                                        width: 100%;
                                    
                                    }
                                    .banner-section-box-three img {
                                        height: 261px;
                                        width: 288px;
                                    object-fit: contain;
                                    }
                                    .banner-img-one {
                                        background-image: url(https://tossdown.site/images/4800a801e70353e89f64a2f17c3ada6f_1655382629.png);
                                        background-repeat: no-repeat;
                                        width: 100%;
                                    background-position: center;
                                    height: 448px;
                                    background-size: cover;
                                    }
                                    .banner-img-two-box {
                                        display: flex;
                                        width: 100%;
                                        height: 100%;
                                        justify-content: flex-end;
                                        align-items: flex-end;
                                    margin-left: 12px;
                                    }
                                    .banner-img-two {
                                        background-image: url(https://tossdown.site/images/e63f65c38b30211a8af0d83a45c0e353_1655382760.jpg);
                                        background-repeat: no-repeat;
                                        width: 100%;
                                    height: 448px;
                                    background-position: center;
                                    }
                                    .banner-img-three-box {
                                        display: flex;
                                        width: 100%;
                                        height: 100%;
                                        align-items: flex-end;
                                    margin-right: 12px;
                                    }
                                    .banner-img-three{
                                        background-image: url(https://tossdown.site/images/ab27b75f1845b7d3783419cfb9b04cd9_1655382957.jpg);
                                        background-repeat: no-repeat;
                                        width: 100%;
                                    height: 448px;
                                    background-position: center;
                                    }
                                    .banner-img-four{
                                        background-image: url(https://tossdown.site/images/00fcd2c2bc6deac1c32c470369362c33_1655383004.png);
                                        background-repeat: no-repeat;
                                        width: 100%;
                                    background-position: center;
                                    height:448px;
                                        background-size: cover;
                                    }
                                    
                                    
                                    @media(max-width:1024px){
                                    .box-content {
                                        display: flex;
                                        justify-content: center;
                                        flex-direction: column;
                                        align-items: center;
                                        height: 100%;
                                    padding: 0px 0px 0px 0px;
                                    }
                                    .box-heading h4 {
                                        text-align: center;
                                    }
                                    .box-text p {
                                        text-align: center;
                                    }
                                    
                                    }
                                    @media(max-width: 768px){
                                    section.custome-section-new{
                                    height: 100%
                                    }
                                    .banner-section-box-three img {
                                        height: 178px;
                                        width: 288px;
                                        object-fit: contain;
                                    }
                                    .box-section {
                                        padding: 25px 0px 0px 0px;
                                    }
                                    .custom-section-area {
                                        grid-template-columns: 1fr;
                                        grid-template-areas: ".";
                                    padding :0px 0px 18px 0px;
                                    
                                    }
                                    .banner-section-box-two {
                                        padding-top: 20px;
                                    }
                                    .banner-img-four {
                                       
                                        background-size: cover;
                                    }
                                    .banner-img-three {
                                        background-size: cover;
                                    }
                                    .banner-section-box-three {
                                        height: 100%;
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                    }
                                    .banner-img-three-box {
                                        width: 100%;
                                        height: 100%;
                                        align-items: flex-end;
                                        margin-right: 12px;
                                    display: block;
                                    background-size: cover;
                                    }
                                    .banner-section-box-one {
                                        display: none;
                                    }
                                    .box-img-2{
                                    height:100%;
                                    width:100%;
                                    display:block;
                                    }
                                    
                                    
                                    }
                                    @media(max-width: 540px){
                                    section.custome-section-new {
                                        margin-bottom: 50px;
                                    }
                                    .box-section {
                                        padding: 10px 15px;
                                    }
                                    .box-text h4 {
                                        font-family: 'Jost';
                                        font-style: italic;
                                        font-weight: 800;
                                        font-size: 45px;
                                        line-height: 55px;
                                        text-align: center;
                                        text-transform: uppercase;
                                        color: #262B40;
                                      padding: 0px 5px 28px 5px;
                                    }
                                    
                                    @media(max-width: 440px)
                                    {
                                    .box-heading h4 {
                                        text-align: center;
                                    }
                                    .box-text p {
                                        text-align: center;
                                    }
                                    }
                                    
                                        </style>
                                    <section class="custome-section-new">
                                    <div class="box-section">
                                    <div class="box-content-one">
                                    <div class="box-title"><small>Why do we use</small></div>
                                    <div class="box-heading">
                                    <h4>Mindful Parenting Workshop</h4>
                                    </div>
                                    <div class="box-text">
                                    <p>This Summer Camp Tips for helping your kids through <strong>Emotional Distress!</strong></p>
                                    </div>
                                    </div>
                                    <div class="custom-section-area">
                                    <div class="banner-section-box-one">
                                    <div class="box-img-1"><img src="https://static.tossdown.com/site/7f0d5244-d607-4105-89be-5ca2f7503929.png" /></div>
                                    <div class="box-img-2"><img src="https://staging.tossdown.site/images/ba32ffbe5284dcc585d8cdbb045fc851_1658128532.png" /></div>
                                    </div>
                                    <div class="banner-section-box-two">
                                    <div class="box-content"><!--<div class="box-title"><small>Perspectives presents</small></div>
                                    <div class="box-heading">
                                    <h4>Mindful Parenting Workshop</h4>
                                    </div>
                                    <div class="box-text">
                                    <p>This Summer Camp Tips for helping your kids through <strong>Emotional Distress!</strong></p>
                                    </div>-->
                                    <div class="years-img"><img src="https://staging.tossdown.site/images/2af2ceb580678adca7de0ed5d4cf6047_1658129606.png" /></div>
                                    <div class="box-button"><a href="/signup">Register now for parenting workshop </a></div>
                                    </div>
                                    </div>
                                    <div class="banner-section-box-three">
                                    <div class="box-img-2"><img src="https://static.tossdown.com/site/7f0d5244-d607-4105-89be-5ca2f7503929.png" /></div>
                                    <div class="box-img-3"><img src="https://static.tossdown.com/site/ec3fac3a-97aa-4e64-82b6-7b83b0ded4db.png" /></div>
                                    </div>
                                    </div>
                                    </div>
                                    </section>
                                    
                                    `
                                    },


                                    {
                                        id:"HomeCustomSection10",
                                        name:"HomeCustomSection Eleven",
                                        height:"800px",
                                        code:` 
                                         <head>
                        
                                        <meta charset="utf-8" />
                                        <meta
                                          name="viewport"
                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                        /> 
                                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                       
                                        </head> 
                                         <style>
                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                        </style>
                                        <style>
                                        * {
                                                         -web-kit-box-sizing: border-box;
                                                         -moz-box-sizing: border-box;
                                                         box-sizing: border-box;
                                                         padding: 0px;
                                                         margin: 0px;
                                                         font-family: "Poppins", sans-serif;
                                                       }
                                                       html {
                                                         scroll-behavior: smooth;
                                                       }
                                                       body {
                                                         float: left;
                                                         width: 100%;
                                                         margin: 0px;
                                                     background-color: #fff;
                                                       }
                                       .home_custom_section_3_main{
                                       display:block;
                                           width: 100%;
                                           padding: 100px 15px;
                                           float: left;
                                       
                                       }
                                       .custom_container{
                                       max-width:1140px;
                                       margin:0 auto;
                                       }
                                       .home_custom_section_3{
                                           display: grid;
                                           grid-template-columns: 1fr 1fr;
                                           grid-template-areas: ". .";
                                           grid-template-rows: auto;
                                           gap: 15px 0px;
                                           position: relative;
                                           z-index: 1;
                                           width: 100%;
                                           align-items: center;
                                       height:100%;
                                       }
                                       .home_section_3_text {
                                       padding-left: 46px;
                                           border-left: 1px solid #32A994;
                                       }
                                       .products-step-hold p{
                                       margin-bottom:16px;
                                       font-family: 'Manrope' !important;
                                       font-style: normal;
                                       font-weight: 400;
                                       font-size: 15px;
                                       line-height: 24px;
                                       color: #262161;
                                       }
                                       
                                       
                                       .pro-step-h p b {
                                           font-weight: 800;
                                       }
                                       .home_custom_section_3 figure img{
                                           background-size: cover;
                                           background-repeat: no-repeat;
                                           width: 100%;
                                           height: 100%;
                                           background-position: center;
                                       }
                                       .home_custom_section_3_body{
                                           display: grid;
                                           width: 100%;
                                           flex-direction: column;
                                           gap: 24px 0px;
                                           padding: 0px 50px 0px 0px;
                                       margin-right:50px;
                                       position:relative;
                                       }
                                       .home_custom_section_3_heading{
                                           display: grid;
                                           width: 100%;
                                           flex-direction: column;
                                           gap: 8px 0px;
                                       
                                       }
                                       .home_custom_section_3_heading small{
                                       font-family: 'Manrope' !important;
                                       font-style: normal;
                                       font-weight: 500;
                                       font-size: 14px;
                                       line-height: 19px;
                                       letter-spacing: 0.1em;
                                       text-transform: uppercase;
                                       color: #262161;
                                       }
                                       .home_custom_section_3_heading h3{
                                       font-family: 'Austin News Headline Web';
                                       font-style: normal;
                                       font-weight: 500;
                                       font-size: 48px;
                                       line-height: 58px;
                                       letter-spacing: 0.05em;
                                       color: #262161;
                                       }
                                       .home_custom_section_3_heading h3:before {
                                           content: "";
                                           position: absolute;
                                           background-image: url(https://staging.tossdown.site/images/83e18064dc87f6a74bd70d0d59ac1139_1658133971.png);
                                           top: 0;
                                           right: 0;
                                       width: 100px;
                                           height: 100px;
                                           background-repeat: no-repeat;
                                       }
                                       
                                       .home_custom_section_3_body .more_detail_btn{
                                          width: 139px;
                                           height: 51px;
                                           display: flex;
                                           justify-content: center;
                                           align-items: center;
                                           font-family: 'Manrope' !important;
                                       font-style: normal;
                                       font-weight: 600;
                                       font-size: 14px;
                                       color: #262161;
                                           line-height: 16px;
                                       
                                           border: 1px solid #262161;
                                           border-radius: 50px;
                                       }
                                       
                                       @media(max-width:1024px){
                                       .home_custom_section_3_body {
                                           padding: 0px 35px 0px 0px;
                                       gap: 18px 0px;
                                       }
                                       .home_custom_section_3_heading {
                                           gap: 5px 0px;
                                       }
                                       .home_custom_section_3 figure {
                                           height: 450px;
                                       }
                                       .home_custom_section_3_body p {
                                           padding-bottom: 0px;
                                       }
                                       }
                                       @media(max-width:767px){
                                       .home_custom_section_3_main {
                                       
                                           padding: 54px 15px 45px 15px;
                                       
                                       }
                                       .products-step-hold p{
                                       text-align: center;
                                       }
                                       .home_custom_section_3_learn_btn {
                                           width: 100%;
                                           display: flex;
                                           justify-content: center;
                                       }
                                       .home_custom_section_3_body {
                                           justify-content: center;
                                           text-align: center;
                                       }
                                       .home_custom_section_3_body {
                                           padding: 0px 0px 35px 0px !important;
                                           margin-right: 0px !important;
                                       }
                                       .home_section_3_text {
                                           padding-left: 0px !important;
                                           border-left: unset !important;
                                       }
                                       .home_custom_section_3{
                                           display: flex;
                                           flex-direction: column;
                                           gap: 0px;
                                       }/*
                                       .home_custom_section_3 figure {
                                           height: 300px;
                                       background-position: center;
                                           background-image: url('https://tossdown.site/images/62d13ecba616ad197ac2030712b50968_1657019585.png');
                                       }
                                       .home_custom_section_3_body {
                                           padding:0px 25px 40px 25px;
                                       }*/
                                       
                                       }
                                       @media(max-width:540px){
                                       .home_custom_section_3_heading h3 {
                                           font-size: 36px;
                                           line-height: 46px;
                                       }
                                       
                                       }
                                       @media(max-width:450px){
                                       /*
                                       .home_custom_section_3 figure {
                                           height: 200px;
                                       background-position: center;
                                           background-image: url('https://tossdown.site/images/62d13ecba616ad197ac2030712b50968_1657019585.png');
                                       }*/
                                       }
                                       </style>
                                       <div class="home_custom_section_3_main">
                                       <div class="custom_container">
                                       <div class="home_custom_section_3">
                                       <div class="home_custom_section_3_body">
                                       <div class="home_custom_section_3_heading"><small data-aos-delay="200" data-aos="fade-down" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">Persative</small>
                                       <h3 data-aos-delay="400" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">Mental Healthcare Platform for Children and Adults</h3>
                                       </div>
                                       <div class="home_custom_section_3_learn_btn"><a data-aos-delay="900" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="more_detail_btn aos-init aos-animate">Learn more</a></div>
                                       </div>
                                       <div class="home_section_3_text">
                                       <div class="products-step-hold">
                                       <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                                       <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                                       </div>
                                       </div>
                                       </div>
                                       </div>
                                       </div>
                                        
                                        `
                                        },


                                        {
                                            id:"HomeCustomSection11",
                                            name:"HomeCustomSection 12",
                                            height:"800px",
                                            code:` 
                                             <head>
                            
                                            <meta charset="utf-8" />
                                            <meta
                                              name="viewport"
                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                            /> 
                                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                           
                                            </head> 
                                             <style>
                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                            </style>
                                            <style>
                                            * {
                                                             -web-kit-box-sizing: border-box;
                                                             -moz-box-sizing: border-box;
                                                             box-sizing: border-box;
                                                             padding: 0px;
                                                             margin: 0px;
                                                             font-family: "Poppins", sans-serif;
                                                           }
                                                           html {
                                                             scroll-behavior: smooth;
                                                           }
                                                           body {
                                                             float: left;
                                                             width: 100%;
                                                             margin: 0px;
                                                         background-color: #fff;
                                                           }
                                            .home_section_six {
                                                   display: block;
                                                   width: 100%;
                                                   padding: 70px 15px 90px 15px;
                                           background-color:#FEF3E5;
                                           float: left;
                                           
                                                 }
                                                 .custom_container_section {
                                                   max-width: 1140px;
                                                   margin: 0 auto;
                                           
                                                 }
                                           .home_section_six_header{
                                               display: flex;
                                               justify-content: center;
                                               align-items: center;
                                               width: 100%;
                                           padding-bottom: 39px;
                                               flex-direction: column;
                                               max-width:860px;
                                               margin: 0 auto;
                                           position: relative;
                                           }
                                           .home_section_six_header small{
                                           font-family: 'Manrope' !important;
                                           font-style: normal;
                                           font-weight: 500;
                                           font-size: 14px;
                                           line-height: 19px;
                                           letter-spacing: 0.1em;
                                           text-transform: uppercase;
                                           color: #262161;
                                           margin-bottom:8px;
                                           }
                                           /*
                                           .home_section_six_header small:before {
                                               top: 0;
                                               background-repeat: no-repeat;
                                               content: "";
                                               height: 100px;
                                               width: 112px;
                                               background-image: url(https://tossdown.site/images/eee82c78ddc9737c5b8a6d6fbfbde607_1658212664.png);
                                               position: absolute;
                                               right: 0;
                                           }
                                           
                                           .home_section_six_header h3:before {
                                               top: 50px;
                                               background-repeat: no-repeat;
                                               content: "";
                                               height: 100px;
                                               width: 112px;
                                               background-image: url(https://tossdown.site/images/1c9627d5ba228355c8210116b7bdf66f_1658211848.png);
                                               position: absolute;
                                               left: 0;
                                           }
                                           */
                                           .home_section_six_header h3{
                                           font-family: 'Austin News Headline Web';
                                           font-style: normal;
                                           font-weight: 500;
                                           font-size: 56px;
                                           line-height: 58px;
                                           letter-spacing: 0.05em;
                                           color: #262161;
                                           margin-bottom:12px
                                           }
                                           .home_section_six_header p{
                                           font-family: 'Manrope' !important;
                                           font-style: normal;
                                           font-weight: 400;
                                           font-size: 15px;
                                           line-height: 24px;
                                           text-align: center;
                                           color: #262161;
                                           margin-bottom:40px;
                                           max-width: 485px;
                                           }
       
                                           figure.imgs-collection-image2 img {
                                               padding-left: 50px;
                                           }

                                           
                                                      figure.imgs-collection-image1{
                                           position: relative;
                                           display: flex;
                                           justify-content: center;
                                           
                                           
                                           }
                                                    figure.imgs-collection-image2{
                                           position: relative;
                                               display: flex;
                                               justify-content: center;
                                           
                                           }
                                           
                                                       .img-collection-section2{
                                                           display: grid;
                                                           grid-template-columns: 1fr 1fr;
                                                          grid-template-rows: auto;
                                                   
                                                       }
                                           .img-collection-section{
                                                           display: grid;
                                                           grid-template-columns: 1fr 1fr;
                                                          grid-template-rows: auto;
                                          
                                                       }
                                           
                                               figure.imgs-collection-image2 {
                                               display: flex;
                                               justify-content: center;
                                               flex-direction: column;
                                               width: 100%;
                                               align-items: center;
                                           }   
                                           .section2-cta {
                                               margin-bottom: 30px;
                                           }
                                                   
                                                    
                                                    .text-area {
                                             display: flex;
                                               align-items: center;
                                               flex-direction: column;
                                               margin-top: 40px;
                                           position: relative;
                                           width: 100%;
                                           
                                           }
                                           .text-heading {
                                               max-width: 155px;
                                           }
                                                    .text-area-2 {
                                             display: flex;
                                               align-items: center;
                                               flex-direction: column;
                                               margin-top: 40px;
                                           position: relative;
                                           width: 100%;
                                           
                                           }
                                                    .text-area-4 {
                                             display: flex;
                                               align-items: center;
                                               flex-direction: column;
                                               margin-top: 40px;
                                           position: relative;
                                           width: 100%;
                                           
                                           }
                                           .text-area:before {
                                               position: absolute;
                                               content: "";
                                               border-left: 2px dashed #262161;
                                               height: 170px;
                                               left: 0px;
                                               bottom: 0;
                                           }
                                           .text-area-2:before {
                                               position: absolute;
                                               content: "";
                                               border-left: 2px dashed #262161;
                                               height: 170px;
                                               right: 0px;
                                               bottom: 0;
                                           }
                                           
                                           
                                           .fig-layer {
                                               position: absolute;
                                               top: 0;
                                               left: -23px;
                                           }
                                           .fig-layer-2 {
                                               position: absolute;
                                               top: 0;
                                               right: -23px;
                                           }
                                           
                                           .text-heading h3 {
                                               font-family: 'Manrope' !important;
                                               font-style: normal;
                                               font-weight: 800;
                                               font-size: 28px;
                                               line-height: 36px;
                                               color: #262161;
                                               margin-bottom: 30px;
                                           }
                                           .area-btn a {
                                               width: 139px;
                                               height: 51px;
                                               font-family: 'Manrope' !important;
                                               font-style: normal;
                                               font-weight: 600;
                                               font-size: 14px;
                                               line-height: 19px;
                                               color: #262161;
                                               display: flex;
                                               justify-content: center;
                                               align-items: center;
                                               border: 1px solid;
                                               border-radius: 60px;
                                           }
                                           
                                           
                                                       @media (max-width:1024px) {
                                           
                                           
                                                       .section2-heading h2{
                                                           font-size: 60px!important;
                                                       }
                                                           
                                                       }
                                                       
                                                       @media (max-width:768px) {
                                           figure.imgs-collection-image2 img {
                                               padding-left: 0px;
                                           }
                                           .home_section_six {
                                               padding: 70px 15px 30px 15px;
                                             
                                           }
                                           
                                           
                                           .home_section_six_header{
                                           padding-bottom: 0px;
                                           }
                                           
                                           .section2-heading h2{
                                           font-size: 50px!important;
                                           
                                           }
                                           .text-heading {
                                               max-width: 100%;
                                           }
                                           .text-area {
                                               margin-bottom: 40px;
                                           
                                           }
                                           .text-area-2 {
                                               margin: 40px 0px 40px 0px;
                                           }
                                           
                                           
                                           .text-area-4 {
                                               margin-bottom: 40px;
                                           
                                           }
                                           .img-collection-section2 {
                                               display: flex;
                                               flex-direction: column-reverse;
                                           }
                                           .img-collection-section {
                                               display: flex;
                                               flex-direction: column;
                                           }
                                           figure.imgs-collection-image1 {
                                               position: relative;
                                               display: flex;
                                               justify-content: center;
                                           }
                                           figure.imgs-collection-image2 {
                                               position: relative;
                                               display: flex;
                                               justify-content: center;
                                           }
                                           .text-area:before {
                                           
                                               width: 100%;
                                           border-left: none;
                                           }
                                           .text-area-2:before {
                                           
                                               width: 100%;
                                           border-left: none;
                                           }
                                           .fig-layer {
                                               display: none;
                                           }
                                           .fig-layer-2 {
                                               display: none;
                                           }
                                           
                                           }
                                           @media (max-width:650px) {
                                              
                                                      
                                                       .section2-heading h2 {
                                               font-size: 40px!important;
                                               line-height: 53px!important;
                                           }
                                           .section2-cta a{
                                               margin-bottom: 30px;
                                           }
                                           
                                           }
                                           
                                           @media (max-width:550px) {
                                               .section2-heading h2 {
                                               font-size: 33px!important;
                                               line-height: 50px!important;
                                               padding-bottom: 20px;
                                           }
                                           .home_section_six {
                                           
                                           position: relative;
                                           }
                                           .home_section_six_header {
                                               position: unset;
                                           }
                                           .home_section_six_header small:before {
                                               top: 0;
                                           
                                           }
                                           }
                                           @media (max-width:450px) {
                                           
                                           .home_section_six_header h3:before {
                                              display: none;
                                           }
                                           
                                               .section2-heading h2 {
                                               font-size: 25px!important;
                                               line-height: 41px!important;
                                               padding-bottom: 20px;
                                           }
                                           .section2-cta a {
                                              
                                               width: 150px;
                                               height: 40px;
                                           
                                           }
                                           
                                           .home_section_six_header h3 {
                                               text-align: center;
                                           }
                                           }
                                           
                                                   </style>
                                           <section class="home_section_six">
                                           <div class="custom_container_section">
                                           <div class="home_section_six_header" style="overflow: hidden;"><small data-aos-delay="100" data-aos="zoom-in-down" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-offset="100" style="overflow: hidden;">Persative</small>
                                           <h3 data-aos-delay="100" data-aos="fade-right" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-offset="100" style="overflow: hidden;">Our Services</h3>
                                           <p data-aos-delay="100" data-aos="fade-left" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-offset="100" style="overflow: hidden;">Our core values include being empathetic, maintaining confidentially, providing a safe environment, and working with the clients needs.</p>
                                           </div>
                                           <div class="img-collection-section2">
                                           <figure class="imgs-collection-image1 aos-init aos-animate" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://staging.tossdown.site/images/d7309a0b2ae9a5866b4a8963041d08af_1659424978.png" alt="" /></figure>
                                           <!--<figure class="imgs-collection-image2 aos-init aos-animate" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">
                                           <div class="section2-cta aos-init aos-animate" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50"><img src="https://staging.tossdown.site/images/46eb272322007ba513250210e82d59d9_1659424532.png" /></div>
                                           <img class="img-center" src="https://staging.tossdown.site/images/480e4f3ea1052fc739f3bb71cf67276e_1659423710.png" alt="" /></figure>-->
                                           <div class="text-area">
                                           <div class="fig-layer"><img src="https://staging.tossdown.site/images/46eb272322007ba513250210e82d59d9_1659424532.png" /></div>
                                           <div class="text-heading">
                                           <h3>Individual Therapy</h3>
                                           </div>
                                           <div class="area-btn"><a >Learn more</a></div>
                                           </div>
                                           </div>
                                           <div class="img-collection-section">
                                           <div class="text-area-2">
                                           <div class="fig-layer-2"><img src="https://static.tossdown.com/site/306b13ee-221e-4dd6-aae0-216b8a5fc632.png" /></div>
                                           <div class="text-heading">
                                           <h3>Group Therapy</h3>
                                           </div>
                                           <div class="area-btn"><a>Learn more</a></div>
                                           </div>
                                           <figure class="imgs-collection-image2 aos-init aos-animate" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://static.tossdown.com/site/f365d3a6-ef72-4869-833a-f1d480444702.png" alt="" /></figure>
                                           </div>
                                           <div class="img-collection-section2">
                                           <figure class="imgs-collection-image1 aos-init aos-animate" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://static.tossdown.com/site/dce53525-9340-4769-91b2-02018886bed4.png" alt="" /></figure>
                                           <div class="text-area">
                                           <div class="fig-layer"><img src="https://staging.tossdown.site/images/28700a9c61a2402f61c026a0be465161_1659508127.png" /></div>
                                           <div class="text-heading">
                                           <h3>Trainings &amp; Workshops</h3>
                                           </div>
                                           <div class="area-btn"><a>Learn more</a></div>
                                           </div>
                                           </div>
                                           <!--
                                           <div class="img-collection-section">
                                           <div class="text-area-4">
                                           <div class="fig-layer-2"><img src="https://staging.tossdown.site/images/a7b3601af57f7efcf2771b27eab6f652_1659508716.png" /></div>
                                           <div class="text-heading">
                                           <h3>Behaviour Therapy</h3>
                                           </div>
                                           <div class="area-btn"><a >Learn more</a></div>
                                           </div>
                                           <figure class="imgs-collection-image2 aos-init aos-animate" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://staging.tossdown.site/images/439084f136c5abccf59fadf925d34b0d_1659508567.png" alt="" /></figure>
                                           </div>--></div>
                                           </section>
                                            
                                            `
                                            },


                                            
                                        {
                                            id:"HomeCustomSection12",
                                            name:"HomeCustomSection 13",
                                            height:"800px",
                                            code:` 
                                             <head>
                            
                                            <meta charset="utf-8" />
                                            <meta
                                              name="viewport"
                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                            /> 
                                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                           
                                            </head> 
                                             <style>
                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                            </style>
                                            <style>
                                            * {
                                                             -web-kit-box-sizing: border-box;
                                                             -moz-box-sizing: border-box;
                                                             box-sizing: border-box;
                                                             padding: 0px;
                                                             margin: 0px;
                                                             font-family: "Poppins", sans-serif;
                                                           }
                                                           html {
                                                             scroll-behavior: smooth;
                                                           }
                                                           body {
                                                             float: left;
                                                             width: 100%;
                                                             margin: 0px;
                                                         background-color: #fff;
                                                           }
                                           .home_custom_section_8_parent{
                                               display:block;
                                               width:100%;
                                               padding:0px 15px;
                                           margin-bottom:100px;
                                           }
                                               .custom_container{
                                               max-width:1140px;
                                               margin:0 auto;
                                               }
                                               .home_custom_section_8{
                                                   display: grid;
                                                   grid-template-columns: 1fr 1fr;
                                                   grid-template-areas: ". .";
                                                   grid-template-rows: auto;
                                           gap: 0px 119px;
                                                   position: relative;
                                                   z-index: 1;
                                                   width: 100%;
                                                   align-items: center;
                                               height:100%;
                                               }
                                               .home_custom_section_8 figure {
                                                   margin-bottom: 0px;
                                                   width: 100%;
                                               display: flex;
                                               justify-content: flex-end;
                                           }
                                            
                                               .home_custom_section_8_body{
                                                    display: flex;
                                               width: 100%;
                                               flex-direction: column;
                                               justify-content: center;
                                               }
                                              .home_custom_section_8_body small{
                                           font-family: 'Oswald';
                                           font-style: normal;
                                           font-weight: 400;
                                           font-size: 22px;
                                           line-height: 33px;
                                           color: #212121;
                                           padding-bottom:8px;
                                           }
                                             
                                               .home_custom_section_8_heading{
                                                   display: grid;
                                                   width: 100%;
                                               max-width: 400px;
                                               }
                                               .home_custom_section_8_heading h3{
                                           font-family: 'Oswald';
                                           font-style: normal;
                                           font-weight: 700;
                                           font-size: 40px;
                                           line-height: 48px;
                                           text-transform: capitalize;
                                           color: #212121;
                                           padding-bottom:24px;
                                               }
                                               
                                               .home_custom_section_8_body p{
                                           font-family: 'poppins' !important;
                                           font-style: normal;
                                           font-weight: 250;
                                           font-size: 15px;
                                           line-height: 30px;
                                           color: #212121;
                                               padding-bottom:48px
                                               }
                                            .home_custom_section_8_learn_btn{
                                              display: flex;
                                           width: 100%;
                                           }
                                            .home_custom_section_8_learn_btn a{
                                            font-family: 'Oswald';
                                           font-style: normal;
                                           font-weight: 500;
                                           font-size: 14px;
                                           line-height: 21px;
                                           color: #FFFFFF;
                                                   display: flex;
                                                   justify-content: center;
                                                   align-items: center;
                                           width: 165px;
                                           height: 48px;
                                           background: #004029;
                                           border-radius: 52px;
                                               }
                                            .home_custom_section_8_learn_btn a:hover{
                                           color: #FFFFFF;
                                           }
                                              .home_custom_section_8_learn_btn a i{
                                           color: #FFFFFF;
                                           padding-right:8px;
                                           }
                                           .home_custom_section_8_figure {
                                               position: relative;
                                           }
                                           .home_custom_section_8_figure_absolute_one {
                                               background: #FFFFFF;
                                               border: 0.859155px solid rgba(0, 0, 0, 0.04);
                                               box-shadow: -8px 10px 0px rgb(0 64 41 / 10%);
                                               border-radius: 4px;
                                               padding: 16px;
                                               display: flex;
                                               align-items: flex-start;
                                               position: absolute;
                                               top: 60px;
                                               left: -76px;
                                           }
                                           .custom_section_8_figure_absolute_one_img {
                                               padding-right: 7px;
                                           }
                                           .custom_section_8_figure_absolute_one_data {
                                               display: flex;
                                               flex-direction: column;
                                           }
                                           .custom_section_8_figure_absolute_one_data small {
                                               font-family: 'Oswald';
                                               font-style: normal;
                                               font-weight: 400;
                                               font-size: 8px;
                                               line-height: 12px;
                                               text-transform: uppercase;
                                               color: #004029;
                                           }
                                           .custom_section_8_figure_absolute_one_data p {
                                               font-family: 'Oswald';
                                               font-style: normal;
                                               font-weight: 500;
                                               font-size: 15px;
                                               line-height: 22px;
                                               color: #004029;
                                           }
                                           .home_custom_section_8_figure_absolute_two {
                                             background: #FFFFFF;
                                           border: 0.859155px solid rgba(0, 0, 0, 0.04);
                                           box-shadow: 8px 10.3099px 0px rgba(0, 64, 41, 0.1);
                                           border-radius: 4px;
                                           max-width: 190px;
                                               padding: 16px;
                                               display: flex;
                                               align-items: flex-start;
                                               position: absolute;
                                               bottom: 75px;
                                               right: -56px;
                                           }
                                               @media(max-width:1024px){
                                              .home_custom_section_8 {
                                               gap: 0px 30px;
                                           }
                                           .home_custom_section_8_figure_absolute_two {
                                               bottom: 10px;
                                               right: -10px;
                                           }
                                           .home_custom_section_8_figure_absolute_one {
                                               top: 10px;
                                               left: -10px;
                                           }
                                               }
                                               @media(max-width:768px){
                                               .home_custom_section_8_learn_btn {
                                                   width: 100%;
                                                   display: flex;
                                                   justify-content: center;
                                               }
                                               .home_custom_section_8_body {
                                                   justify-content: center;
                                                   text-align: center;
                                               }
                                               .home_custom_section_8{
                                                   display: flex;
                                                   flex-direction: column-reverse;
                                                   gap: 0px;
                                               }
                                           
                                           
                                           .home_custom_section_8 figure {
                                               justify-content: center;
                                               padding-top: 40px;
                                           }
                                           .home_custom_section_8_heading {
                                               margin: 0 auto;
                                           }
                                               }
                                               @media(max-width:540px){
                                               .home_custom_section_8_heading h3 {
                                                   font-size: 36px;
                                                   line-height: 46px;
                                               }
                                           
                                               }
                                               
                                               </style>
                                           <div class="home_custom_section_8_parent">
                                           <div class="custom_container">
                                           <div class="home_custom_section_8">
                                           <figure>
                                           <div class="home_custom_section_8_figure"><img src="https://static.tossdown.com/images/3e371ea1-611b-409d-9172-588a5e9c2c7e.png" />
                                           <div class="home_custom_section_8_figure_absolute_one">
                                           <div class="custom_section_8_figure_absolute_one_img"><img src="https://static.tossdown.com/images/d139cdcc-fbb4-474a-8c9c-8e6fb0b194c3.png" /></div>
                                           <div class="custom_section_8_figure_absolute_one_data"><small>Lorem Ipsum</small>
                                           <p>Your help will go a long way!</p>
                                           </div>
                                           </div>
                                           <div class="home_custom_section_8_figure_absolute_two">
                                           <div class="custom_section_8_figure_absolute_one_img"><img src="https://static.tossdown.com/images/ded9b7f1-1b6f-4389-b01e-e8a029becf27.png" /></div>
                                           <div class="custom_section_8_figure_absolute_one_data"><small>Help Lorem Ipsum</small>
                                           <p>Funding people in different causes!</p>
                                           </div>
                                           </div>
                                           </div>
                                           </figure>
                                           <div class="home_custom_section_8_body"><small>Together we can do More</small>
                                           <div class="home_custom_section_8_heading">
                                           <h3>Lorem Ipsum (PAKISTAN)</h3>
                                           </div>
                                           <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                           <div class="home_custom_section_8_learn_btn"><a  target="_blank" rel="noopener"><i class="fas fa-heart"></i> Donate Now </a></div>
                                           </div>
                                           </div>
                                           </div>
                                           </div>
                                            
                                            `
                                            },

                                            {
                                                id:"HomeCustomSection13",
                                                name:"HomeCustomSection 14",
                                                height:"800px",
                                                code:` 
                                                 <head>
                                
                                                <meta charset="utf-8" />
                                                <meta
                                                  name="viewport"
                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                /> 
                                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                               
                                                </head> 
                                                 <style>
                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                </style>
                                                <style>
                                                * {
                                                                 -web-kit-box-sizing: border-box;
                                                                 -moz-box-sizing: border-box;
                                                                 box-sizing: border-box;
                                                                 padding: 0px;
                                                                 margin: 0px;
                                                                 font-family: "Poppins", sans-serif;
                                                               }
                                                               html {
                                                                 scroll-behavior: smooth;
                                                               }
                                                               body {
                                                                 float: left;
                                                                 width: 100%;
                                                                 margin: 0px;
                                                             background-color: #fff;
                                                               }
                                                .home_section_6 {
                                                       display: block;
                                                       width: 100%;
                                                       padding: 0px 0px 70px 0px;
                                                     }
                                                     .custom_container {
                                                       max-width: 1140px;
                                                       margin: 0 auto;
                                                     }
                                               .home_section_6_main{
                                               background: linear-gradient(99.95deg, #D4F6F8 47.31%, #F7FDED 100%);
                                               padding:70px 70px;
                                                   position: relative;
                                               }
                                               .home_section_6_main_absolute_img {
                                                   position: absolute;
                                                   bottom: 0px;
                                                   right: 0px;
                                               }
                                               .home_section_6_header_main {
                                                   display: flex;
                                                   flex-direction: column;
                                               }
                                               .home_section_6_header_main small{
                                               font-family: 'Canela';
                                               font-style: normal;
                                               font-weight: 250;
                                               font-size: 22px;
                                               line-height: 22px;
                                               padding-bottom:6px;
                                               color: #212121;
                                               }
                                               .home_section_6_header{
                                                      display: flex;
                                                   justify-content: space-between;
                                                   width: 100%;
                                                   padding-bottom: 32px;
                                               }
                                               .home_section_6_header h3{
                                               font-family: 'Oswald';
                                               font-style: normal;
                                               font-weight: 700;
                                               font-size: 40px;
                                               line-height: 59px;
                                               text-transform: capitalize;
                                               color: #212121;
                                               margin:0px;
                                               }
                                               .home_section_6_header a{
                                               font-family: 'Oswald';
                                                   font-style: normal;
                                                   font-weight: 500;
                                                   font-size: 14px;
                                                   line-height: 21px;
                                               color: #004029;
                                                   display: flex;
                                                   justify-content: center;
                                                   align-items: center;
                                               width: 165px;
                                               height: 48px;
                                               background: #A2F678;
                                               border-radius: 52px;
                                               }
                                               .home_section_6_header a i {
                                               color: #004029;
                                                   padding-right: 8px;
                                               }
                                                     .home_section_6_box {
                                                       display: grid;
                                                       grid-template-columns: 1fr 1fr;
                                                       width: 100%;
                                                       grid-template-rows: auto;
                                                       grid-template-areas: ". .";
                                                       gap: 25px 30px;
                                                     }
                                                     .home_section_6_single_box {
                                                         width: 100%;
                                                   display: flex;
                                                   flex-direction: column;
                                                   height: 100%;
                                                   padding: 48px 24px;
                                                   position: relative;
                                                
                                                  background: #FFFFFF;
                                               box-shadow: -8px 12px 0px rgba(0, 64, 41, 0.1);
                                               border-radius: 4px;
                                                     }
                                                     .home_section_6_single_box h3 {
                                                  font-family: 'Oswald';
                                               font-style: normal;
                                               font-weight: 500;
                                               font-size: 20px;
                                               line-height: 30px;
                                               color: #212121;
                                                       padding-bottom: 16px;
                                                       margin: 0px;
                                                     }
                                                     .home_section_6_single_box p {
                                                 font-family: 'Poppins';
                                               font-style: normal;
                                               font-weight: 400;
                                               font-size: 14px;
                                               line-height: 26px;
                                               color: rgba(0, 0, 0, 0.5);
                                                       margin: 0px;
                                                       padding-bottom: 24px;
                                                     }
                                                  .home_section_6_single_box a{
                                               font-family: 'Oswald';
                                               font-style: normal;
                                               font-weight: 400;
                                               font-size: 13px;
                                               line-height: 19px;
                                               display: flex;
                                               align-items: center;
                                               text-transform: uppercase;
                                               color: #212121;
                                                     }
                                                .home_section_6_single_box a i{
                                               font-size: 13px;
                                               line-height: 19px;
                                               display: flex;
                                               align-items: center;
                                               color: #212121;
                                               padding-left:6px;
                                               }
                                               
                                               
                                                     @media (min-width: 768px) and (max-width: 1024px) {
                                                   .home_section_6_box {
                                                         grid-template-columns: 1fr 1fr;
                                                         grid-template-areas: ". .";
                                                       }
                                                       .home_section_6_single_box{
                                                     padding:32px 15px;
                                                       }
                                                     }
                                               
                                                     @media (max-width: 767px) {
                                                       .home_section_6_box {
                                                         grid-template-columns: 1fr;
                                                         grid-template-areas: ".";
                                                       }
                                               .home_section_6_header_main {
                                                   justify-content: center;
                                                   align-items: center;
                                               }
                                               .home_section_6_header {
                                                   flex-direction: column;
                                                   justify-content: center;
                                                   text-align: center;
                                                   align-items: center;
                                               }
                                               .home_section_6_header h3 {
                                                   padding: 0px 0px 10px 0px;
                                               }
                                               .home_section_6_single_box {
                                                 justify-content: center;
                                                   align-items: center;
                                                   text-align: center;
                                               }
                                               .home_section_6_main {
                                                   padding: 50px 15px;
                                               }
                                                     }
                                               
                                                     @media (max-width: 540px) {
                                               
                                                       .home_section_6_box {
                                                         grid-template-columns: 1fr;
                                                         grid-template-areas: ".";
                                                       }
                                                  
                                                     }
                                                
                                               
                                               </style>
                                               <section class="home_section_6">
                                               <div class="custom_container">
                                               <div class="home_section_6_main">
                                               <div class="home_section_6_main_absolute_img"><img src="https://static.tossdown.com/images/60be826b-6a48-45c0-892b-943684a157af.png" /></div>
                                               <div class="home_section_6_header_main"><small>How We Help</small>
                                               <div class="home_section_6_header">
                                               <h3>TOGETHER WE CAN DO MORE</h3>
                                               <a target="_blank" rel="noopener"><i class="fas fa-heart" aria-hidden="true"></i> Donate Now </a></div>
                                               </div>
                                               <div class="home_section_6_box">
                                               <div class="home_section_6_single_box">
                                               <h3>Our Vision</h3>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                                               <a >Learn More <i class="fal fa-long-arrow-right"></i></a></div>
                                               <div class="home_section_6_single_box">
                                               <h3>Our Mission</h3>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                                               <a>Learn More <i class="fal fa-long-arrow-right"></i></a></div>
                                               </div>
                                               </div>
                                               </div>
                                               </section>
                                                
                                                `
                                                },

                                                {
                                                    id:"HomeCustomSection14",
                                                    name:"HomeCustomSection 15",
                                                    height:"800px",
                                                    code:` 
                                                     <head>
                                    
                                                    <meta charset="utf-8" />
                                                    <meta
                                                      name="viewport"
                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                    /> 
                                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                   
                                                    </head> 
                                                     <style>
                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                    </style>
                                                    <style>
 * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.feature_heading_section_one{
    display: block;

    float: left;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: #F2F5F7;
    max-width: 900px;
}
.custom_container {
    max-width: 1140px;
    margin: 0 auto;
}
.feature_product_one_heading{
      display: flex;
    width: 100%;
    flex-direction: column;
    padding: 48px 64px;
    position: relative;
}
.feature_product_one_heading small{
font-family: 'canela';
font-style: normal;
font-weight: 250;
font-size: 22px;
line-height: 20px;
color: #212121;
padding-bottom:8px;
}
.feature_product_one_heading h3 {
font-family: 'Oswald';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 49px;
text-transform: uppercase;
color: #212121;
    margin-bottom: 0px;
padding-bottom:24px;
}
.feature_product_one_heading a {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 48px;
    background: #004029;
    border-radius: 52px;
}
.feature_product_one_heading a:hover{
    color: #FFFFFF;
}
.feature_product_one_heading a i{
    color: #FFFFFF;
    padding-right: 8px;
}

.home_custom_section_12 {
    display: block;
    width: 100%;
}
.home_custom_section_12 figure {
    display: block;
    width: 100%;
    position: relative;
}
.home_custom_section_12 figure img {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
@media (max-width: 1024px){
.feature_heading_section_one {
  position: unset;
    width: 100%;
    max-width: 100%;
}
.home_custom_section_12 figure {
    display: flex;
    flex-direction: column-reverse;
}
.feature_product_one_heading {
    max-width: 900px;
}
}
@media (max-width: 768px){
.feature_product_one_heading {
    text-align: center;
    align-items: center;
   max-width: 100%;
}
.feature_product_one_heading{
    padding: 48px 15px;
}
}
.feature_product_one_absolute_img {
    position: absolute;
    bottom: 0px;
    left: 41%;
}
</style>
<div class="home_custom_section_12">
<figure><img src="https://images-beta.tossdown.com/site/527c73c4-dcf4-41c6-8025-d482ff41b7a0.webp" />
<div class="feature_heading_section_one">
<div class="custom_container">
<div class="feature_product_one_heading"><small>Local &amp; Global Projects</small>
<h3>Just A Little Help Can Make Their Hearts Happier.</h3>
<a target="_blank" rel="noopener"><i class="fas fa-heart" aria-hidden="true"></i>Donate Now</a>
<div class="feature_product_one_absolute_img"><img src="https://static.tossdown.com/images/56fe33f5-fa4b-48d1-8092-7f86c70164b6.png" style="height: 100%; object-fit: contain;" /></div>
</div>
</div>
</div>
</figure>
</div>
                                                    
                                                    `
                                                    },

                                                    {
                                                        id:"HomeCustomSection15",
                                                        name:"HomeCustomSection 16",
                                                        height:"800px",
                                                        code:` 
                                                         <head>
                                        
                                                        <meta charset="utf-8" />
                                                        <meta
                                                          name="viewport"
                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                        /> 
                                                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                       
                                                        </head> 
                                                         <style>
                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                        </style>
                                                        <style>
                                                        div#myTabContent {
                                                            padding: 0px 120px;
                                                            position: relative;
                                                        }
                                                        .home_section_six_absolute_img {
                                                            position: absolute;
                                                            bottom: 0px;
                                                            right: 0px;
                                                            width: 125px;
                                                        }
                                                        .project_section_btn {
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            padding-bottom: 70px;
                                                        }
                                                        .project_section_btn a {
                                                         display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                           width: 154px;
                                                        height: 48px;
                                                        border: 1px solid rgba(0, 0, 0, 0.16);
                                                        border-radius: 52px;
                                                         
                                                        font-family: 'Oswald';
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        font-size: 14px;
                                                        line-height: 21px;
                                                        text-transform: uppercase;
                                                        color: #212121;
                                                        }
                                                        .project_section_btn a i {
                                                          background: #212121;
                                                            width: 18px;
                                                            height: 18px;
                                                            color: #fff;
                                                            border-radius: 50%;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            font-size: 12px;
                                                            font-weight: bold;
                                                            margin-left: 8px;
                                                        }
                                                        </style>
                                                        <style>
                                                        .home_section_six_single_box_main {
                                                            display: flex;
                                                            flex-direction: column;
                                                        }
                                                        .home_section_six_single_box_figure img {
                                                            width: 100%;
                                                        }
                                                         .home_section_six {
                                                                display: block;
                                                                width: 100%;
                                                                padding: 70px 15px 36px 15px;
                                                              }
                                                              .custom_container {
                                                                max-width: 1140px;
                                                                margin: 0 auto;
                                                              }
                                                        .home_section_six_header{
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            width: 100%;
                                                        padding-bottom: 16px;
                                                            flex-direction: column;
                                                        }
                                                        .home_section_six_header p{
                                                        font-family: 'Oswald';
                                                        font-style: normal;
                                                        font-weight: 250;
                                                        font-size: 22px;
                                                        line-height: 20px;
                                                        color: #212121;
                                                        padding-bottom:8px;
                                                        }
                                                        .home_section_six_header h3{
                                                        font-family: 'Oswald';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 40px;
                                                        line-height: 49px;
                                                        text-align: center;
                                                        text-transform: uppercase;
                                                        color: #212121;
                                                        margin:0px;
                                                        padding-bottom:8px;
                                                        }
                                                        .home_section_six_header small{
                                                        font-family: 'Poppins';
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        font-size: 14px;
                                                        line-height: 26px;
                                                        color: rgba(0, 0, 0, 0.5);
                                                        text-align: center;
                                                        }
                                                        .section_six_tabs {
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            padding-bottom: 32px;
                                                        display: none;
                                                        }
                                                        .section_six_tabs  ul#myTab {
                                                            display: flex;
                                                            justify-content: center;
                                                            background: #FCFAF7;
                                                            border: 1px solid rgba(0, 0, 0, 0.1);
                                                            border-radius: 52px;
                                                            height: 44px;
                                                        }
                                                        .section_six_tabs  ul#myTab li{
                                                            margin-bottom: 0px !important;
                                                        }
                                                        .section_six_tabs  ul#myTab li a{
                                                        font-family: 'Oswald';
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        font-size: 14px;
                                                        line-height: 21px;
                                                        display: flex;
                                                        text-transform: uppercase;
                                                        color: #212121;
                                                            text-decoration: none;
                                                            justify-content: center;
                                                            width: 146px;
                                                            height: 44px;
                                                        }
                                                        .section_six_tabs  ul#myTab li a.active{
                                                            background: #004029;
                                                            border-radius: 52px;
                                                            display: flex;
                                                            justify-content: center;
                                                            color: #FFFFFF;
                                                        }
                                                              .home_section_six_box {
                                                                 display: grid;
                                                            grid-template-columns: 1fr 1fr 1fr;
                                                            width: 100%;
                                                            grid-template-rows: auto;
                                                            grid-template-areas: ". . .";
                                                            gap: 25px 31px;
                                                              }
                                                              .home_section_six_single_box {
                                                                  width: 100%;
                                                            display: flex;
                                                            flex-direction: column;
                                                            height: 100%;
                                                            padding: 21px 16px;
                                                            position: relative;
                                                        background: #FCFAF7;
                                                            border-radius: 4px;
                                                              }
                                                              .home_section_six_single_box h3 {
                                                          font-family: 'Oswald';
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 24px;
                                                        color: #212121;
                                                                padding-bottom: 8px;
                                                                margin: 0px;
                                                              }
                                                              .home_section_six_single_box p {
                                                         font-family: 'Poppins';
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        font-size: 13px;
                                                        line-height: 22px;
                                                        color: rgba(0, 0, 0, 0.5);
                                                                margin: 0px;
                                                        padding-bottom:21px;
                                                              }
                                                            .home_section_six_single_box a{
                                                        font-family: 'Oswald';
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        font-size: 13px;
                                                        line-height: 19px;
                                                        display: flex;
                                                        text-transform: uppercase;
                                                        color: #212121;
                                                        }
                                                        
                                                              @media (min-width: 768px) and (max-width: 1024px) {
                                                                .home_section_six_single_box{
                                                              padding:32px 15px;
                                                                }
                                                        .home_section_six_absolute_img{
                                                        display:none;
                                                        }
                                                        div#myTabContent {
                                                            padding: 0px 0px;
                                                        }
                                                              }
                                                        
                                                              @media (max-width: 767px) {
                                                        .home_section_six_absolute_img{
                                                        display:none;
                                                        }
                                                        div#myTabContent {
                                                            padding: 0px 0px;
                                                        }
                                                                .home_section_six_box {
                                                                  grid-template-columns: 1fr 1fr;
                                                                  grid-template-areas: ". .";
                                                                }
                                                              }
                                                              @media (max-width: 540px) {
                                                        
                                                                .home_section_six_box {
                                                                  grid-template-columns: 1fr;
                                                                  grid-template-areas: ".";
                                                                }
                                                           
                                                              }
                                                        .home_section_six_single_box a i {
                                                            font-size: 13px;
                                                            line-height: 19px;
                                                            display: flex;
                                                            align-items: center;
                                                            color: #212121;
                                                            padding-left: 6px;
                                                        }
                                                        
                                                        </style>
                                                        <section class="home_section_six">
                                                        <div class="custom_container">
                                                        <div class="home_section_six_header">
                                                        <p>Our Projects Gallery</p>
                                                        <h3>LATEST PROJECTS</h3>
                                                        <small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</small></div>
                                                        <div class="section_six_tabs">
                                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                        <li class="nav-item"><a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Short term</a></li>
                                                        <li class="nav-item"><a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Long term</a></li>
                                                        </ul>
                                                        </div>
                                                        <div class="tab-content" id="myTabContent">
                                                        <div class="home_section_six_absolute_img"><img src="https://static.tossdown.com/images/1fc13974-5b19-4818-9ecb-a8ec8457f9d0.png" /></div>
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                        <div class="home_section_six_box">
                                                        <div class="home_section_six_single_box_main">
                                                        <div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/1b951f0f-2782-421d-9b68-dba7c67d93b9.png" /></div>
                                                        <div class="home_section_six_single_box">
                                                        <h3>Health Literacy Camps</h3>
                                                        <p>A lack of health literacy can have an impact on the public’s health as a whole. Health-literate individuals can make better decisions when it comes to their health.</p>
                                                        <a >Learn More <i class="fal fa-long-arrow-right" aria-hidden="true"></i></a></div>
                                                        </div>
                                                        <div class="home_section_six_single_box_main">
                                                        <div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/d07524c3-d4db-4732-be67-9717e46d7a50.png" /></div>
                                                        <div class="home_section_six_single_box">
                                                        <h3>Food Insecurity</h3>
                                                        <p>No one should go to bed hungry. No one should have to beg for food when it’s the most basic right as human beings.</p>
                                                        <a >Learn More <i class="fal fa-long-arrow-right" aria-hidden="true"></i></a></div>
                                                        </div>
                                                        <div class="home_section_six_single_box_main">
                                                        <div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/f39e6b84-e21e-4ca1-9c16-9d04459bf706.png" /></div>
                                                        <div class="home_section_six_single_box">
                                                        <h3>Water Access</h3>
                                                        <p>According to the Human Development Report, it’s expected that 2.5 billion people will be affected by water stress and scarcity by 2050 in South Asia alone.</p>
                                                        <a >Learn More <i class="fal fa-long-arrow-right" aria-hidden="true"></i></a></div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                        <div class="home_section_six_box">
                                                        <div class="home_section_six_single_box_main">
                                                        <div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/1b951f0f-2782-421d-9b68-dba7c67d93b9.png" /></div>
                                                        <div class="home_section_six_single_box">
                                                        <h3>Health Literacy Camps</h3>
                                                        <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nulla laoreet me tus nec lacus mattis.</p>
                                                        <a href="/health-litracy-camps">Learn More <i class="fal fa-long-arrow-right" aria-hidden="true"></i></a></div>
                                                        </div>
                                                        <div class="home_section_six_single_box_main">
                                                        <div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/d07524c3-d4db-4732-be67-9717e46d7a50.png" /></div>
                                                        <div class="home_section_six_single_box">
                                                        <h3>Food Insecurity</h3>
                                                        <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nulla laoreet me tus nec lacus mattis.</p>
                                                        <a href="/food-insecurity">Learn More <i class="fal fa-long-arrow-right" aria-hidden="true"></i></a></div>
                                                        </div>
                                                        <div class="home_section_six_single_box_main">
                                                        <div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/f39e6b84-e21e-4ca1-9c16-9d04459bf706.png" /></div>
                                                        <div class="home_section_six_single_box">
                                                        <h3>Water Access</h3>
                                                        <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nulla laoreet me tus nec lacus mattis.</p>
                                                        <a href="/clean-water-access">Learn More <i class="fal fa-long-arrow-right" aria-hidden="true"></i></a></div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </section>
                                                        <div class="project_section_btn d-none"><a>Learn More <i class="fal fa-plus" aria-hidden="true"></i></a></div>
                                                        
                                                        `
                                                        },

                                                        {
                                                            id:"HomeCustomSection16",
                                                            name:"HomeCustomSection 17",
                                                            height:"800px",
                                                            code:` 
                                                             <head>
                                            
                                                            <meta charset="utf-8" />
                                                            <meta
                                                              name="viewport"
                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                            /> 
                                                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                           
                                                            </head> 
                                                             <style>
                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                            </style>
                                                            <style>
        *{
-web-kit-box-sizing: border-box;
 -moz-box-sizing: border-box; 
 box-sizing:border-box; 
padding:0px;
margin:0px;
}
html {
scroll-behavior: smooth;
}
body{
float: left;
width: 100%;
margin:0px;
}

        .section1{

            display: block;
    width: 100%;
  float: left;
   
    background: #F8F3ED;
padding:71px 0px 72px 0px;


        }

    .section1-custom-container{
    max-width: 1136px;
        margin: 0px auto;
    }
.inner-section1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}


.section1-bg-img{
    background-image: url('https://tossdown.site/images/8ff610f2d6a6a4eab4ce1970f7e332fa_1656929110.png');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 521px;
    margin: 0px!important;


}
.section1-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #B67A65;
 

}

    .section1-heading{
        font-family: 'Cormorant';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    color: #FFFFFF;
    margin-bottom: 8px!important;
    margin-top: 0px!important;
    }
.section1-para{
    font-family: 'inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;
margin: 0px!important;
margin-bottom: 25px!important;

}

.section1-content a{
    font-family: 'inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* identical to box height */

display: flex;
align-items: center;
text-transform: uppercase;

color: #F8F3ED!important;
text-decoration: none!important;
width: 150px;
height: 45px;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #F8F3ED;
 transition: .5s ease-out;

}
.section1-content a:hover{

  
color: #F8F3ED!important;
background:transparent;
}

@media (max-width:936px) {
    
.inner-section1{
    display: grid;
    grid-template-columns: 1fr ;

}
.section1-bg-img{

    width: 100%;
    height: 400px;
    margin: 0px!important;
    background-position: center;

    background-repeat: no-repeat;


}
.section1-custom-container{
   max-width: 700px;
    margin: 0px auto;
}
.section1-content   {
  height: 300px;
}
.section1-heading{
    font-size: 45px;
}
}
@media (max-width:650px) {
    .section1-heading{
    font-size: 40px;
}
    
}
@media (max-width:550px) {
    .section1-heading{
    font-size: 35px;
}
.section1-para{
    font-size: 17px;
}
    
}
@media (max-width:477px) {
    .section1-heading{
    font-size: 25px;
}
.section1-para{
    font-size: 14px;
}
    
}
@media (max-width:345px) {
    .section1-heading{
    font-size: 20px;
}
.section1-para{
    font-size: 12px;
}
    
}
</style>
<div class="section1">
<div class="section1-custom-container">
<div class="inner-section1">
<figure class="section1-bg-img" data-aos="zoom-in-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"></figure>
<div class="section1-content" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">
<h3 class="section1-heading" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50">Macrame blanket and pillows</h3>
<p class="section1-para" data-aos="zoom-in-up" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50">to complete the boho look of your place</p>
<a  class="section1-cta" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50">SHOP NOW</a></div>
</div>
</div>
</div>
                                                            `
                                                            },

                                                            {
                                                                id:"HomeCustomSection17",
                                                                name:"HomeCustomSection 18",
                                                                height:"800px",
                                                                code:` 
                                                                 <head>
                                                
                                                                <meta charset="utf-8" />
                                                                <meta
                                                                  name="viewport"
                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                /> 
                                                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                               
                                                                </head> 
                                                                 <style>
                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                </style>
                                                                <style>
                                                                *{
                                                        -web-kit-box-sizing: border-box;
                                                         -moz-box-sizing: border-box; 
                                                         box-sizing:border-box; 
                                                        padding:0px;
                                                        margin:0px;
                                                        }
                                                        html {
                                                        scroll-behavior: smooth;
                                                        }
                                                        body{
                                                        float: left;
                                                        width: 100%;
                                                        margin:0px;
                                                        }
                                                        .section-grid{
                                                            background-image: url('https://tossdown.site/images/4c698fd62e0949deb0ce9481d1665664_1657007402.png');
                                                            background-repeat: no-repeat;
                                                            background-position: center;
                                                            background-size: contain;
                                                            width: 100%;
                                                            background-color: #F8F3ED;
                                                            padding-bottom: 72px;
                                                            padding-top: 71px;
                                                            border-top: 1px solid rgba(231, 231, 231, 0.8);
                                                        float:left;
                                                        }
                                                        .grid-section-custom-container{
                                                           max-width: 1136px;
                                                            margin: 0px auto;
                                                        }
                                                        .inner-section-grid{
                                                            display: grid;
                                                            grid-template-columns: 1fr 1fr;
                                                            gap: 0px 50px;
                                                        }
                                                        .section-grid-left-text{
                                                            display: flex;
                                                            flex-direction: column;
                                                            align-items: flex-start;
                                                            justify-content: center
                                                        
                                                        }
                                                        
                                                        .section-grid-left-text h2 {
                                                            font-family: 'Cormorant';
                                                            font-style: normal;
                                                            font-weight: 700;
                                                            font-size: 44px;
                                                            line-height: 58px;
                                                            display: flex;
                                                            align-items: center;
                                                            text-transform: capitalize;
                                                            color: #232323;
                                                            padding: 0px 38px 22px 0px;
                                                        }
                                                        
                                                        .section-grid-left-text p {
                                                            font-family: 'Inter';
                                                            font-style: normal;
                                                            font-weight: 300;
                                                            font-size: 16px;
                                                            line-height: 31px;
                                                            display: flex;
                                                            align-items: center;
                                                            color: #000000;
                                                            padding-bottom: 45px;
                                                        }
                                                        .section-grid-left-text a {
                                                            font-family: 'Inter';
                                                            font-style: normal;
                                                            font-weight: 600;
                                                            font-size: 14px;
                                                            line-height: 17px;
                                                            display: flex;
                                                            align-items: center;
                                                            text-transform: uppercase;
                                                            color: #232323;
                                                            text-decoration: none;
                                                            width: 172px;
                                                        height: 44px;
                                                        border: 1px solid #232323;
                                                        display: flex;
                                                        justify-content: center;
                                                        transition: 0.5s ;
                                                        }
                                                        
                                                        
                                                        .section-grid-left-text a:hover{
                                                         background: #B67A65!important;
                                                            box-shadow: inset 12em 0 0 0 #B67A65!important;
                                                            color: #fff!important;
                                                        
                                                        transition: 0.5s;
                                                        
                                                        
                                                        }
                                                        
                                                        
                                                        .section-grid-right-imgs{
                                                            display: grid;
                                                            grid-template-columns: 1fr 1fr;
                                                            gap: 10px 10px;
                                                        }
                                                        .section-grid-right-imgs img{
                                                            width: 100%;
                                                        }
                                                        
                                                        @media (max-width:950px) {
                                                            .inner-section-grid{
                                                            display: grid;
                                                            grid-template-columns: 1fr;
                                                            gap: 50px 0px;
                                                            
                                                        }
                                                        .section-grid-right-imgs img{
                                                            width:100%;
                                                        }
                                                        .section-grid-right-imgs{
                                                            display: grid;
                                                            grid-template-columns: 1fr 1fr 
                                                        
                                                        }
                                                        .section-grid-left-text {
                                                            text-align: center;
                                                            display: flex;
                                                            flex-direction: column;
                                                            align-items: center;
                                                            justify-content: center;
                                                        }
                                                        .grid-section-custom-container {
                                                            max-width: 661px;
                                                            margin: 0px auto;
                                                        }
                                                        }
                                                        
                                                        
                                                        @media (max-width:767px) {
                                                        
                                                            .grid-section-custom-container {
                                                            max-width: 561px;
                                                            margin: 0px auto;
                                                        
                                                        }
                                                        .section-grid-left-text a {
                                                        
                                                            width: 165px;
                                                            height: 40px;
                                                           
                                                        }
                                                        .section-grid-left-text h2 {
                                                          
                                                            font-size: 40px;
                                                            
                                                        }
                                                        .section-grid-left-text p {
                                                           
                                                            font-size: 14px;
                                                           
                                                        }
                                                        }
                                                        @media (max-width:550px) {
                                                        
                                                            .grid-section-custom-container {
                                                            max-width: 400px;
                                                            margin: 0px auto;
                                                        }
                                                        .section-grid-left-text h2 {
                                                            font-size: 28px;
                                                        }   
                                                        .section-grid-left-text p {
                                                            font-size: 13px;
                                                        }
                                                        .section-grid-left-text a {
                                                            width: 150px;
                                                            height: 40px;
                                                        }
                                                        }
                                                        @media (max-width:400px) {
                                                        
                                                        .section-grid-left-text h2 {
                                                            
                                                            padding: 0px 0px 0px 0px!important;
                                                        }
                                                        }
                                                        
                                                        
                                                        
                                                        
                                                        
                                                        
                                                        
                                                            </style>
                                                        <div class="section-grid">
                                                        <div class="grid-section-custom-container">
                                                        <div class="inner-section-grid">
                                                        <div class="section-grid-left-text" data-aos="zoom-in-up" data-aos-mirror="true" data-aos-duration="1500" data-aos-once="true" data-aos-offset="50">
                                                        <h2 data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">The Story of Every Home</h2>
                                                        <p data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                                                        <a data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50" >Learn more</a></div>
                                                        <div class="section-grid-right-imgs"><img data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/85eeb62f9a70207ca52c325522f3cd56_1657007296.png" alt="" /> <img data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/36cbf40f550070fb69d1f70843c9184c_1657007320.png" alt="" /> <img data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/76a0a141b43d1850936b5846a971c65c_1657007337.png" alt="" /> <img data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/5f149523c0fb47a7eaf4047fbf82f75d_1657007355.png" alt="" /></div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                                `
                                                                },


                                                                {
                                                                    id:"HomeCustomSection18",
                                                                    name:"HomeCustomSection 19",
                                                                    height:"800px",
                                                                    code:` 
                                                                     <head>
                                                    
                                                                    <meta charset="utf-8" />
                                                                    <meta
                                                                      name="viewport"
                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                    /> 
                                                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                   
                                                                    </head> 
                                                                     <style>
                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                    </style>
                                                                    <style>
                                                                    *{
                                                            -web-kit-box-sizing: border-box;
                                                             -moz-box-sizing: border-box; 
                                                             box-sizing:border-box; 
                                                            padding:0px;
                                                            margin:0px;
                                                            }
                                                            html {
                                                            scroll-behavior: smooth;
                                                            }
                                                            body{
                                                            float: left;
                                                            width: 100%;
                                                            margin:0px;
                                                            }
                                                          
                                                            .home_section_six {
                                                                   display: block;
                                                                   width: 100%;
                                                                   padding: 0px 0px 70px 0px;
                                                                 }
                                                           
                                                                 .home_section_six_box {
                                                                   display: grid;
                                                                   grid-template-columns: 1fr 1fr 1fr;
                                                                   width: 100%;
                                                                   grid-template-rows: auto;
                                                                   grid-template-areas: ". . .";
                                                                 }
                                                                 .home_section_six_single_box {
                                                                   width: 100%;
                                                               display: flex;
                                                               flex-direction: column;
                                                               height: 100%;
                                                               padding: 15px;
                                                               position: relative;
                                                               text-align: center;
                                                               justify-content: center;
                                                           box-shadow: 1px 0 0 0 #F58320, 0 1px 0 0 #F58320, 1px 1px 0 0 #F58320, /* Just to fix the corner */ 1px 0 0 0 #F58320 inset, 0 1px 0 0 #F58320 inset;      
                                                           }
                                                                 .home_section_six_single_box_heading h3 {
                                                              font-family: 'Poppins';
                                                           font-style: normal;
                                                           font-weight: 700;
                                                           font-size: 49px;
                                                           line-height: 74px;
                                                           text-transform: uppercase;
                                                           color: #F58320;
                                                               position: relative;
                                                                   padding-bottom: 9px;
                                                                   margin: 0px;
                                                                 }
                                                           .home_section_six_single_box_heading{
                                                               width: 100%;
                                                               display: flex;
                                                               justify-content: center;
                                                           }
                                                                 .home_section_six_single_box_heading h3:before {
                                                               position: absolute;
                                                               content: "";
                                                               background-image: url("https://static.tossdown.com/images/bc7b98f9-00db-45ac-9012-bbaf04123d59.png");
                                                               left: -26px;
                                                               top: 0px;
                                                               width: 20px;
                                                               height: 27px;
                                                           }
                                                                 .home_section_six_single_box_heading h3:after {
                                                               position: absolute;
                                                               content: "";
                                                               background-image: url("https://static.tossdown.com/images/d60188e9-a06b-465a-ab11-55812eaa4ff3.png");
                                                             right: -35px;
                                                               bottom: 14px;
                                                                width: 29px;
                                                               height: 31px;
                                                           }
                                                           
                                                                 .home_section_six_single_box p {
                                                             font-family: 'Poppins';
                                                           font-style: normal;
                                                           font-weight: 400;
                                                           font-size: 21px;
                                                           line-height: 29px;
                                                           text-align: center;
                                                           color: #000000;
                                                           padding-bottom:30px;
                                                                   max-width: 260px;
                                                               margin: 0 auto;
                                                                 }
                                                             .home_section_six_single_box_link{
                                                            display: flex;
                                                               justify-content: center;
                                                              align-items: center;
                                                           }
                                                             .home_section_six_single_box_link a{
                                                           background: #000000;
                                                           border-radius: 2px;
                                                           width: 159px;
                                                           height: 48px;
                                                           font-family: 'Poppins';
                                                           font-style: normal;
                                                           font-weight: 800;
                                                           font-size: 13px;
                                                           line-height: 20px;
                                                           text-transform: uppercase;
                                                           color: #FFFFFF;
                                                             display: flex;
                                                               justify-content: center;
                                                              align-items: center;
                                                           }
                                                           .home_section_six_single_box_img{
                                                               width: 100%;
                                                               display: flex;
                                                               justify-content: center;
                                                           padding-bottom:24px;
                                                           
                                                           }
                                                           .home_section_six_single_box_only_img {
                                                               display: block;
                                                               width: 100%;
                                                           padding: 15px;
                                                           box-shadow: 1px 0 0 0 #F58320, 0 1px 0 0 #F58320, 1px 1px 0 0 #F58320, /* Just to fix the corner */ 1px 0 0 0 #F58320 inset, 0 1px 0 0 #F58320 inset;
                                                           }
                                                           .home_section_six_single_box_only_img figure{
                                                               display: flex;
                                                               width: 100%;
                                                               justify-content: center;
                                                               align-items: center;
                                                               height: 100%;
                                                           }
                                                           .home_section_six_single_box_only_img figure img{
                                                               display: block;
                                                               width: 100%;
                                                           }
                                                           
                                                                 @media (max-width: 1140px) {
                                                           .home_section_six_single_box_img {
                                                               padding-bottom: 0px;
                                                           }
                                                           
                                                           .home_section_six_single_box_heading h3 {
                                                               font-size: 32px;
                                                               line-height: 48px;
                                                           }
                                                           .home_section_six_single_box p {
                                                               font-size: 18px;
                                                               line-height: 24px;
                                                               padding-bottom: 19px;
                                                           }
                                                           
                                                           }
                                                                 @media (min-width: 768px) and (max-width: 1024px) {
                                                                   .home_section_six_single_box{
                                                                 padding:32px 15px;
                                                                   }
                                                                 }
                                                           
                                                                 @media (max-width: 767px) {
                                                                   .home_section_six_box {
                                                                     grid-template-columns: 1fr;
                                                                     grid-template-areas: ".";
                                                                   }
                                                                 }
                                                                 @media (max-width: 540px) {
                                                           
                                                             
                                                                 }
                                                            
                                                           
                                                           </style>
                                                                <section class="home_section_six">
                                                                <div class="home_section_six_box">
                                                                <div class="home_section_six_single_box_only_img">
                                                                <figure><img src="https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png"></figure>
                                                                </div>
                                                                <div class="home_section_six_single_box">
                                                                <div class="home_section_six_single_box_img">
                                                                <figure><img src="https://static.tossdown.com/images/d105c809-67ba-4a55-b434-56493220f27f.png"></figure>
                                                                </div>
                                                                <div class="home_section_six_single_box_heading">
                                                                <h3>Lorem Ipsum</h3>
                                                                </div>
                                                                <p>Iconic Berlin Lorem Ipsum</p>
                                                                <div class="home_section_six_single_box_link"><a>ORDER NOW</a></div>
                                                                </div>
                                                                <div class="home_section_six_single_box_only_img">
                                                                <figure><img src="https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png"></figure>
                                                                </div>
                                                                </div>
                                                                </section>
                                                                    `
                                                                    },


                                                                    
                                                                {
                                                                    id:"HomeCustomSection19",
                                                                    name:"HomeCustomSection 20",
                                                                    height:"800px",
                                                                    code:` 
                                                                     <head>
                                                    
                                                                    <meta charset="utf-8" />
                                                                    <meta
                                                                      name="viewport"
                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                    /> 
                                                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                   
                                                                    </head> 
                                                                     <style>
                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                    </style>
                                                                    <style>
                                                                    *{
                                                                                                                              -web-kit-box-sizing: border-box;
                                                                                                                               -moz-box-sizing: border-box; 
                                                                                                                               box-sizing:border-box; 
                                                                                                                              padding:0px;
                                                                                                                              margin:0px;
                                                                                                                              }
                                                                                                                              html {
                                                                                                                              scroll-behavior: smooth;
                                                                                                                              }
                                                                                                                              body{
                                                                                                                              float: left;
                                                                                                                              width: 100%;
                                                                                                                              margin:0px;
                                                                                                                              }
                                                                      section.custom-section-two {
                                                                      width: 100%;
                                                                      padding: 0px 15px;
                                                                  float: left;
                                                                  }
                                                                  .custom-container-section {
                                                                      margin: 0 auto;
                                                                      max-width: 1365px;
                                                                  }
                                                                      .grid-section {
                                                                      display: grid;
                                                                      grid-template-columns: 1fr 1fr 1fr;
                                                                      grid-template-areas: ". .";
                                                                  
                                                                  }/*
                                                                  .image-section-two {
                                                                      display: grid;
                                                                      grid-template-columns: 1fr 1fr;
                                                                      grid-template-areas: ". .";
                                                                      gap: 30px;
                                                                  }*/
                                                                  img.bottom-img {
                                                                      margin-top: 67px;
                                                                      width: 100%;
                                                                  }
                                                                  /*
                                                                  .image-section-one {
                                                                      display: grid;
                                                                      grid-template-rows: 0.7fr 0.3fr;
                                                                      gap: 30px;
                                                                  }*/
                                                                  .image-section {
                                                                      display: grid;
                                                                      grid-template-rows: 1fr 1fr;
                                                                  
                                                                  }
                                                                  img.images.overlay {
                                                                      width: 100%;
                                                                      height:100%;
                                                                  }
                                                                  img.images_t.overlay-fo {
                                                                      width: 100%;
                                                                      height:100%;
                                                                  object-fit: cover;
                                                                  }
                                                                  img.images.overlay-fi {
                                                                      width: 100%;
                                                                  }
                                                                  img.img-o {
                                                                      width: 100%;
                                                                    height:100%;
                                                                  object-fit: cover;
                                                                  }
                                                                  img.img-t {
                                                                  height:100%;
                                                                  object-fit: cover;
                                                                  width:100%;
                                                                  }
                                                                  .hov-text {
                                                                      width: 86%;
                                                                  }
                                                                  .hov-text p {
                                                                      font-family: 'Lora' !important;
                                                                      font-style: normal;
                                                                      font-weight: 400;
                                                                      font-size: 44px;
                                                                      line-height: 55px;
                                                                      text-align: center;
                                                                      color: #FFFFFF;
                                                                  
                                                                  }
                                                                  .hov-text small {
                                                                      font-family: 'hankenbook';
                                                                      font-style: normal;
                                                                      font-weight: 400;
                                                                      font-size: 14px;
                                                                      line-height: 16px;
                                                                      letter-spacing: 0.14em;
                                                                      color: #FFFFFF;
                                                                      text-align: center;
                                                                      display: block;
                                                                  }
                                                                  
                                                                  .hov-text-black p{
                                                                      font-family: 'Lora' !important;
                                                                      font-style: normal;
                                                                      font-weight: 900;
                                                                      font-size: 44px;
                                                                      line-height: 60px;
                                                                      text-align: center;
                                                                      color: #000000;
                                                                  margin-bottom:14px;
                                                                  }
                                                                  .hov-text-black small {
                                                                      display: block;
                                                                      font-family: 'Lora' !important;
                                                                      font-style: normal;
                                                                      font-weight: 900;
                                                                      font-size: 30px;
                                                                      line-height: 41px;
                                                                      text-align: center;
                                                                      color: #000000;
                                                                  }
                                                                  
                                                                  p.img-text {
                                                                      font-family: 'hankenbook';
                                                                      font-style: normal;
                                                                      font-weight: 300;
                                                                      font-size: 30px;
                                                                      line-height: 33px;
                                                                      text-align: center;
                                                                      color: #000000;
                                                                  margin-bottom: 40px;
                                                                  }
                                                                  
                                                                  img.hover-img {
                                                                      margin-bottom: 15px;
                                                                  }
                                                                  .text {
                                                                      width: 86%;
                                                                      display: flex;
                                                                      flex-direction: column;
                                                                      align-items: center;
                                                                  }
                                                                  a.hover-btn {
                                                                    padding: 8px 30px;
                                                                      border-radius: 25px;
                                                                      border: 1px solid #ffffff;
                                                                      font-family: 'hankenbook';
                                                                      font-style: normal;
                                                                      font-weight: 400;
                                                                      font-size: 14px;
                                                                      line-height: 16px;
                                                                      text-transform: uppercase;
                                                                      color: #FFFFFF;
                                                                  
                                                                  }
                                                                  
                                                                  a.img-hover-btn {
                                                                   padding: 8px 30px;
                                                                      border-radius: 25px;
                                                                      font-family: 'hankenbook';
                                                                      font-style: normal;
                                                                      font-weight: 400;
                                                                      font-size: 14px;
                                                                      line-height: 16px;
                                                                      text-transform: uppercase;
                                                                      color: #FFFFFF;
                                                                      background: #14AAD5;
                                                                  
                                                                  }
                                                                  .img-one {
                                                                      position: relative;
                                                                  }
                                                                  .on-hover {
                                                                      position: absolute;
                                                                      top: 0;
                                                                      height: 100%;
                                                                      width: 100%;
                                                                      display: flex;
                                                                      justify-content: center;
                                                                  
                                                                      align-items: center;
                                                                      cursor: pointer;
                                                                  
                                                                  }
                                                                  .img-two.overlay-fo {
                                                                      position: relative;
                                                                  }
                                                                  .on-hover-fo {
                                                                      position: absolute;
                                                                      top: 0;
                                                                      height: 100%;
                                                                      width: 100%;
                                                                      display: flex;
                                                                      justify-content: center;
                                                                  
                                                                      align-items: center;
                                                                      cursor: pointer;
                                                                  
                                                                  }
                                                                  .img-three.overlay-o {
                                                                      position: relative;
                                                                  }
                                                                  .on-hover-o {
                                                                      position: absolute;
                                                                      top: 0;
                                                                      height: 100%;
                                                                      width: 100%;
                                                                      display: flex;
                                                                      justify-content: center;
                                                                  
                                                                      align-items: center;
                                                                      cursor: pointer;
                                                                  }
                                                                  .img-four.overlay-two {
                                                                      position: relative;
                                                                  }
                                                                  .on-hover-two {
                                                                      position: absolute;
                                                                      top: 0;
                                                                      height: 100%;
                                                                      width: 100%;
                                                                      display: flex;
                                                                      justify-content: center;
                                                                  
                                                                      align-items: center;
                                                                      cursor: pointer;
                                                                  
                                                                  }
                                                                  .img-five.overlay-fi {
                                                                      position: relative;
                                                                  }
                                                                  .on-hover-fi {
                                                                      position: absolute;
                                                                      top: 0;
                                                                      height: 100%;
                                                                      width: 100%;
                                                                      display: flex;
                                                                      justify-content: center;
                                                                  
                                                                      align-items: center;
                                                                      cursor: pointer;
                                                                  
                                                                  }
                                                                  /*
                                                                  .overlay-o:hover .on-hover-o {
                                                                      opacity: 1.3;
                                                                  }
                                                                  .overlay:hover .on-hover {
                                                                      opacity: 1.3;
                                                                  }
                                                                  
                                                                  .overlay-fi:hover .on-hover-fi {
                                                                      opacity: 1.3;
                                                                  }
                                                                  .overlay-two:hover .on-hover-two {
                                                                      opacity: 1.3;
                                                                  }*/
                                                                  
                                                                  @media(max-width: 992px){
                                                                  .hov-text-black p {
                                                                      line-height: 33px;
                                                                      font-size: 26px;
                                                                      margin-bottom: 8px;
                                                                  }
                                                                  .text {
                                                                      width: 100%;
                                                                  }
                                                                  .hov-text p {
                                                                      font-size: 34px;
                                                                      line-height: 45px;
                                                                  margin-bottom: 12px;
                                                                    
                                                                  }
                                                                  
                                                                  p.img-text {
                                                                      margin-bottom: 8px;
                                                                  }
                                                                  
                                                                  }
                                                                  
                                                                  @media(max-width:768px){
                                                                  .grid-section {
                                                                      display: grid;
                                                                      grid-template-columns: 1fr;
                                                                      grid-template-areas: ".";
                                                                      gap: 15px;
                                                                  }
                                                                  .image-section-one {
                                                                      display: grid;
                                                                      gap: 15px;
                                                                  }
                                                                  .image-section-two {
                                                                      display: grid;
                                                                      grid-template-columns: 1fr;
                                                                      grid-template-areas: ".";
                                                                      gap: 15px;
                                                                  }
                                                                  .image-section {
                                                                      display: grid;
                                                                      grid-template-rows: 0.5fr 1.5fr;
                                                                      gap: 15px;
                                                                  }
                                                                  
                                                                  }
                                                                    </style>
                                                                  <section class="custom-section-two">
                                                                  <div class="custom-container-section">
                                                                  <div class="grid-section">
                                                                  <div class="image-section-one">
                                                                  <div class="img-three overlay-o" style="text-align: left;"><img class="img-o" src="https://staging.tossdown.site/images/8763042f4d9d927de9854d6f95247525_1658737750.png" />
                                                                  <div class="on-hover-o">
                                                                  <div class="text">
                                                                  <div class="hov-text"><small>• MAKE UP •</small>
                                                                  <p>The Magnification of Your Beauty</p>
                                                                  </div>
                                                                  <a class="hover-btn" >Get ready at ipsum</a></div>
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                  <div class="image-section">
                                                                  <div class="img-two overlay-fo"><img class="images_t overlay-fo" src="https://staging.tossdown.site/images/70631e7dd071d95137a8faec313f40e8_1658744292.png" />
                                                                  <div class="on-hover-fo">
                                                                  <div class="text">
                                                                  <div class="hov-text-black">
                                                                  <p>30% OFF</p>
                                                                  <p class="img-text">On Bridal Makeup</p>
                                                                  </div>
                                                                  <a class="img-hover-btn" >BOOK NOW</a></div>
                                                                  </div>
                                                                  </div>
                                                                  <div class="image-section-two">
                                                                  <div class="img-one overlay"><img class="images overlay" src="https://staging.tossdown.site/images/580d0ff1eb09984adcf47e6b5553eada_1658744108.png" />
                                                                  <div class="on-hover">
                                                                  <div class="text">
                                                                  <div class="hov-text-black"><small>Avail</small>
                                                                  <p>30% OFF</p>
                                                                  <p class="img-text">On Our Birthday Week</p>
                                                                  </div>
                                                                  <a class="img-hover-btn" >BOOK NOW</a></div>
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                  <div class="image-section-one">
                                                                  <div class="img-three overlay-o" style="text-align: left;"><img class="img-o" src="https://staging.tossdown.site/images/1dda72b83906694f05d312fcd593fcb4_1658748193.png" />
                                                                  <div class="on-hover-o">
                                                                  <div class="text">
                                                                  <div class="hov-text"><small>• FACIAL &amp; BLEACH •</small>
                                                                  <p>Whitening Facial in Only 1500/-</p>
                                                                  </div>
                                                                  <a class="hover-btn" >Book now</a></div>
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                  </section>
                                                                    `
                                                                    },



                                                                    {
                                                                        id:"HomeCustomSection20",
                                                                        name:"HomeCustomSection 21",
                                                                        height:"800px",
                                                                        code:` 
                                                                         <head>
                                                        
                                                                        <meta charset="utf-8" />
                                                                        <meta
                                                                          name="viewport"
                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                        /> 
                                                                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                        <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                                                                        <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                                                                        </head> 
                                                                         <style>
                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                        </style>
                                                                        <style>
                                                                        * {
                                                                                         -web-kit-box-sizing: border-box;
                                                                                         -moz-box-sizing: border-box;
                                                                                         box-sizing: border-box;
                                                                                         padding: 0px;
                                                                                         margin: 0px;
                                                                                         font-family: "Poppins", sans-serif;
                                                                                       }
                                                                                       html {
                                                                                         scroll-behavior: smooth;
                                                                                       }
                                                                                       body {
                                                                                         float: left;
                                                                                         width: 100%;
                                                                                         margin: 0px;
                                                                                     background-color: #fff;
                                                                                       }
                                                                       .home_section_eight{
                                                                           display: block;
                                                                           width: 100%;
                                                                           padding: 72px 15px 70px 15px;
                                                                           float: left;
                                                                       position: relative;
                                                                       }
                                                                       .section_layer_1 {
                                                                           position: absolute;
                                                                           left: 0;
                                                                           top: 0;
                                                                       }
                                                                       .home_section_eight_list {
                                                                           display: flex;
                                                                           flex-direction: column;
                                                                           width: 100%;
                                                                       }
                                                                       .home_section_eight_heading {
                                                                           display: flex;
                                                                           width: 100%;
                                                                           justify-content: center;
                                                                           align-items: center;
                                                                           padding-bottom: 50px;
                                                                           flex-direction: column;
                                                                           text-align: center;
                                                                       }
                                                                       .home_section_eight_heading h3{
                                                                          font-family: 'Lora' !important;
                                                                       font-style: normal;
                                                                       font-weight: 400;
                                                                       font-size: 40px;
                                                                       line-height: 54px;
                                                                       color: #412114;
                                                                       z-index: 999999;
                                                                       
                                                                       }
                                                                       .step-heading h3 {
                                                                           font-family: 'Lora' !important;
                                                                           font-style: normal;
                                                                           font-weight: 900;
                                                                           font-size: 30px;
                                                                           line-height: 41px;
                                                                           color: #000000;
                                                                       }
                                                                       
                                                                       .home_section_eight_list ul {
                                                                         margin: 20px 0px 0px 0px;
                                                                       }
                                                                       .home_section_eight_list ul li {
                                                                         list-style: none;
                                                                         float: left;
                                                                       }
                                                                       .slick-track {
                                                                           display: flex;
                                                                           align-items: center;
                                                                       }
                                                                       .section_six_client_box {
                                                                           width: 100%;
                                                                           display: flex;
                                                                           background: #ffffff;
                                                                         
                                                                           border-radius: 16px;
                                                                           flex-direction: column;
                                                                           height: 100%;
                                                                           padding: 48px 32px;
                                                                       }/*
                                                                       .section_client_box {
                                                                           padding: 0px 22px;
                                                                       }*/
                                                                       .single_category_box {
                                                                           float: left;
                                                                           display: flex;
                                                                           width: 100%;
                                                                           background: #C8E2D726;
                                                                           box-shadow: 0px 4px 24px #6d6d6d14;
                                                                           border-radius: 6px;
                                                                           text-align: left;
                                                                           padding: 30px;
                                                                       flex-direction: column;
                                                                       }
                                                                       .single_category_box_2 {
                                                                           float: left;
                                                                           display: flex;
                                                                           width: 100%;
                                                                           background: #ffffff;
                                                                           box-shadow: 0px 4px 24px #6d6d6d14;
                                                                           border-radius: 6px;
                                                                           text-align: left;
                                                                           padding: 30px;
                                                                       flex-direction: column;
                                                                       }
                                                                       
                                                                       
                                                                       
                                                                       
                                                                       .products-step-hold ul li .pro-step-h {
                                                                           display: flex;
                                                                       
                                                                       }
                                                                       .products-step-hold ul li .pro-step-h.pro-step-h i.fa.fa-check color{
                                                                           color: #EF5486;
                                                                           font-size: 14px;
                                                                           font-weight: 800;
                                                                           margin: 24px 10px 7px 5px;
                                                                       }
                                                                       .products-step-hold ul li .pro-step-h.pro-step-h i.fa.fa-check {
                                                                           color: #449293;
                                                                           font-size: 14px;
                                                                           font-weight: 800;
                                                                           margin: 24px 10px 7px 5px;
                                                                       }
                                                                       
                                                                       .products-step-hold ul li .pro-step-h p {
                                                                            padding-top: 20px;
                                                                           font-family: 'hankenbook';
                                                                           font-style: normal;
                                                                           font-weight: 300;
                                                                           font-size: 15px;
                                                                           line-height: 28px;
                                                                           color: #000000;
                                                                       }
                                                                       .section-btn {
                                                                           display: flex;
                                                                           justify-content: center;
                                                                           margin-top: 32px;
                                                                       }
                                                                       a.book-btn {
                                                                           width: 173px;
                                                                           height: 46px;
                                                                           display: flex;
                                                                           justify-content: center;
                                                                           align-items: center;
                                                                           background: #14AAD5;
                                                                           color: #ffffff;
                                                                           border-radius: 65px;
                                                                       }
                                                                       a.book-btn-2 {
                                                                           width: 173px;
                                                                           height: 46px;
                                                                           display: flex;
                                                                           justify-content: center;
                                                                           align-items: center;
                                                                           background: #EF5486;
                                                                           color: #ffffff;
                                                                           border-radius: 65px;
                                                                       }
                                                                       
                                                                       
                                                                       .section_client_box p {
                                                                          font-family: 'hankenbook';
                                                                       font-style: normal;
                                                                       font-weight: 400;
                                                                       font-size: 16px;
                                                                       line-height: 18px;
                                                                       text-transform: uppercase;
                                                                       color: #000000;
                                                                       
                                                                       }
                                                                       
                                                                       
                                                                       
                                                                       .clinet-details img {
                                                                           width: 36px;
                                                                           height: 36px;
                                                                       }
                                                                       .slick-slide img {
                                                                           display: block;
                                                                       }
                                                                       .clinet-details a {
                                                                           font-family: 'Poppins';
                                                                           font-style: normal;
                                                                           font-weight: 700;
                                                                           font-size: 14px;
                                                                           line-height: 22px;
                                                                           color: #000000;
                                                                           padding: 10px 20px;
                                                                       }
                                                                       
                                                                       .home_section_eight_list a figure img{
                                                                       width: 100% !important; 
                                                                           height: 100% !important;
                                                                       }
                                                                       .home_section_eight_list .slick-next {
                                                                         right: -13px !important;
                                                                       }
                                                                       .home_section_eight_list .slick-prev {
                                                                        left: -22px !important;
                                                                       }
                                                                       
                                                                       .slick-prev::before,
                                                                       .slick-next::before {
                                                                         opacity: 1 !important;
                                                                         color: #000 !important;
                                                                       font-family: 'slick';
                                                                       font-size: 20px;
                                                                       line-height: 1;
                                                                       }
                                                                       .slick-prev, .slick-next {
                                                                           font-size: 0;
                                                                           line-height: 0;
                                                                           position: absolute;
                                                                           top: 50%;
                                                                           display: block;
                                                                           width: 20px;
                                                                           height: 20px;
                                                                           padding: 0;
                                                                           -webkit-transform: translate(0, -50%);
                                                                           -ms-transform: translate(0, -50%);
                                                                           transform: translate(0, -50%);
                                                                           cursor: pointer;
                                                                           color: transparent;
                                                                           border: none;
                                                                           outline: none;
                                                                           background: transparent;
                                                                       }
                                                                       .single_category_box figure img {
                                                                           width: 140px !important;
                                                                           margin: auto;
                                                                       }
                                                                       .slick-prev::before {
                                                                           content: '←';
                                                                           width: 35px;
                                                                           height: 35px;
                                                                           background: #F5F5F5;
                                                                           border-radius: 40px;
                                                                           padding: 6px;
                                                                       }
                                                                       .slick-next::before {
                                                                           content: '→';
                                                                           width: 35px;
                                                                           height: 35px;
                                                                           background: #F5F5F5;
                                                                           border-radius: 50%;
                                                                           padding: 6px;
                                                                       }
                                                                       .slick-prev::before {
                                                                          content: '←';
                                                                           width: 35px;
                                                                           height: 35px;
                                                                           background: #F5F5F5;
                                                                           border-radius: 50%;
                                                                           padding: 6px;
                                                                       }
                                                                       
                                                                            .home_section_eight_btn {
                                                                               display: flex;
                                                                               width: 100%;
                                                                               justify-content: center;
                                                                               text-align: center;
                                                                       padding-top:40px;
                                                                             }
                                                                         
                                                                            .home_section_eight_btn a {
                                                                               display: flex;
                                                                               align-items: center;
                                                                               border-radius: 6px;
                                                                               width: 146px;
                                                                               height: 48px;
                                                                               justify-content: center;
                                                                             
                                                                       font-family: 'Radio Canada';
                                                                       font-style: normal;
                                                                       font-weight: 500;
                                                                       font-size: 14px;
                                                                       line-height: 17px;
                                                                       color: #FFFFFF;
                                                                       background: #F6921E;
                                                                       border-radius: 54px;
                                                                             transition: 0.2s;
                                                                             }
                                                                       
                                                                       @media(max-width:1220px){
                                                                       .home_section_eight_list ul li {
                                                                           text-align: center;
                                                                           justify-content: center;
                                                                       }
                                                                       .home_section_eight_list ul {
                                                                           margin: 0px 20px;
                                                                       }
                                                                       }
                                                                       ul.slick-dots {
                                                                           display: flex;
                                                                           width: 100%;
                                                                           justify-content: center;
                                                                           align-items: center;
                                                                           margin: 0px !important;
                                                                       }
                                                                       ul.slick-dots li {
                                                                           width: unset !important;
                                                                           height: unset !important;
                                                                           padding-top: 25px;
                                                                           padding-right: 15px;
                                                                       }
                                                                       ul.slick-dots li:last-child{
                                                                           padding-right: 0px;
                                                                       }
                                                                       .slick-dots li button {
                                                                           width: 10px !important;
                                                                           height: 10px !important;
                                                                           padding: 5px;
                                                                           cursor: pointer;
                                                                           color: white;
                                                                           border: 0;
                                                                           outline: none;
                                                                       background: transparent !important;
                                                                           border-radius: 50% !important;
                                                                       border: 1px solid #4D5574 !important;
                                                                       position:relative;
                                                                       color: transparent;
                                                                       }
                                                                       li.slick-active button {
                                                                           background: #412114 !important;
                                                                       border: 1px solid #412114 !important;
                                                                       }
                                                                       .slick-dots li button:before {
                                                                           font-family: 'slick';
                                                                           font-size: 6px;
                                                                           line-height: 20px;
                                                                           position: absolute;
                                                                           top: 0;
                                                                           left: 0;
                                                                           width: 20px;
                                                                           height: 20px;
                                                                           content: '•';
                                                                           text-align: center;
                                                                           opacity: .25;
                                                                           color: black;
                                                                           -webkit-font-smoothing: antialiased;
                                                                           -moz-osx-font-smoothing: grayscale;
                                                                       }
                                                                       @media(max-width:1024px){
                                                                       .home_section_eight_heading p{
                                                                            width: 100% !important;
                                                                       }
                                                                       }
                                                                       @media(max-width:920x){
                                                                       
                                                                       }
                                                                       @media (max-width: 768px){
                                                                       .section_client_box p {
                                                                           text-align: center;
                                                                       }
                                                                       }
                                                                           </style>
                                                                       <div class="home_section_eight">
                                                                       <div class="section_layer_1"><img src="https://staging.tossdown.site/images/beab3b067b6243d34c699f289220c287_1658757493.png" /></div>
                                                                       <div class="custom_container">
                                                                       <div class="home_section_eight_list">
                                                                       <div class="home_section_eight_heading">
                                                                       <h3>Our Wedding Packages</h3>
                                                                       </div>
                                                                       <div class="home_section_eight_list">
                                                                       <ul class="home_section_eight_slider">
                                                                       <li>
                                                                       <div class="single_category_box">
                                                                       <div class="section_client_box">
                                                                       <p>ipsum Pearl</p>
                                                                       <hr />
                                                                       <div class="products-step-hold">
                                                                       <ul>
                                                                       <li>
                                                                       <div class="step-heading">
                                                                       <h3>Rs. 30,000</h3>
                                                                       </div>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Barat Makeup</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Hairstyling + Dupatta Setting</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Basic Mani Pedi</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Full Body Wax</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Express Whitening Facial</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Threading</p>
                                                                       </div>
                                                                       <div class="section-btn"><a class="book-btn" >BOOK NOW</a></div>
                                                                       </li>
                                                                       </ul>
                                                                       </div>
                                                                       </div>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="single_category_box_2">
                                                                       <div class="section_client_box">
                                                                       <p>ipsum Silver</p>
                                                                       <hr />
                                                                       <div class="products-step-hold">
                                                                       <ul>
                                                                       <li>
                                                                       <div class="step-heading">
                                                                       <h3>Rs. 40,000</h3>
                                                                       </div>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Mehndi + Barat Makeup</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Hairstyling + Dupatta Setting</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Whitening Mani Pedi</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Full Body Wax</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>(Two) Express Whitening Facial</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Threading</p>
                                                                       </div>
                                                                       <div class="section-btn"><a class="book-btn-2" >BOOK NOW</a></div>
                                                                       </li>
                                                                       </ul>
                                                                       </div>
                                                                       </div>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="single_category_box">
                                                                       <div class="section_client_box">
                                                                       <p>ipsum Pearl</p>
                                                                       <hr />
                                                                       <div class="products-step-hold">
                                                                       <ul>
                                                                       <li>
                                                                       <div class="step-heading">
                                                                       <h3>Rs. 30,000</h3>
                                                                       </div>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Barat Makeup</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Hairstyling + Dupatta Setting</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Basic Mani Pedi</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Full Body Wax</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Express Whitening Facial</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check color" aria-hidden="true"></i>
                                                                       <p>Threading</p>
                                                                       </div>
                                                                       <div class="section-btn"><a class="book-btn" >BOOK NOW</a></div>
                                                                       </li>
                                                                       </ul>
                                                                       </div>
                                                                       </div>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="single_category_box_2">
                                                                       <div class="section_client_box">
                                                                       <p>ipsum Gold</p>
                                                                       <hr />
                                                                       <div class="products-step-hold">
                                                                       <ul>
                                                                       <li>
                                                                       <div class="step-heading">
                                                                       <h3>Rs. 50,000</h3>
                                                                       </div>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Barat + Walima Makeup</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Hairstyling + Dupatta Setting</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Whitening Mani Pedi</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Full Body Wax</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>(Two) Express Whitening Facial</p>
                                                                       </div>
                                                                       </li>
                                                                       <li>
                                                                       <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                       <p>Threading</p>
                                                                       </div>
                                                                       <div class="section-btn"><a class="book-btn-2" >BOOK NOW</a></div>
                                                                       </li>
                                                                       </ul>
                                                                       </div>
                                                                       </div>
                                                                       </div>
                                                                       </li>
                                                                       </ul>
                                                                       </div>
                                                                       <script>
                                                                       $(document).ready(function() {      
                                                                       $('.home_section_eight_slider').slick({
                                                                           slidesToShow: 3,
                                                                         slidesToScroll: 1,
                                                                         autoplay: true,
                                                                         autoplaySpeed: 2000,
                                                                         dots: true,
                                                                         arrows: false,
                                                                         responsive: [
                                                                               {
                                                                               breakpoint: 1024,
                                                                               settings: {
                                                                           slidesToShow: 2,
                                                                         slidesToScroll: 1,
                                                                         autoplay: true,
                                                                         autoplaySpeed: 2000,
                                                                         dots: true,
                                                                         arrows: false,
                                                                                 }
                                                                               },
                                                                               {
                                                                               breakpoint: 767,
                                                                               settings: {
                                                                           slidesToShow: 2,
                                                                         slidesToScroll: 1,
                                                                         autoplay: true,
                                                                         autoplaySpeed: 2000,
                                                                         dots: true,
                                                                         arrows: false,
                                                                                 }
                                                                               },
                                                                               {
                                                                               breakpoint: 540,
                                                                               settings: {
                                                                           slidesToShow: 1,
                                                                         slidesToScroll: 1,
                                                                         autoplay: true,
                                                                         autoplaySpeed: 2000,
                                                                         dots: true,
                                                                         arrows: false,
                                                                                 },
                                                                                 
                                                                               },
                                                                               {
                                                                               breakpoint: 400,
                                                                               settings: {
                                                                           slidesToShow: 1,
                                                                         slidesToScroll: 1,
                                                                         autoplay: true,
                                                                         autoplaySpeed: 2000,
                                                                         dots: true,
                                                                         arrows: false,
                                                                                 },
                                                                                 
                                                                               }
                                                                             ]
                                                                       
                                                                       
                                                                         });
                                                                         }); 
                                                                       
                                                                       
                                                                       </script>
                                                                       </div>
                                                                       </div>
                                                                       </div>
                                                                        
                                                                        `
                                                                        },

                                                                        {
                                                                            id:"HomeCustomSection21",
                                                                            name:"HomeCustomSection 22",
                                                                            height:"800px",
                                                                            code:` 
                                                                             <head>
                                                            
                                                                            <meta charset="utf-8" />
                                                                            <meta
                                                                              name="viewport"
                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                            /> 
                                                                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                           
                                                                            </head> 
                                                                             <style>
                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                            </style>
                                                                            <style>
                                                                            * {
                                                                                             -web-kit-box-sizing: border-box;
                                                                                             -moz-box-sizing: border-box;
                                                                                             box-sizing: border-box;
                                                                                             padding: 0px;
                                                                                             margin: 0px;
                                                                                             font-family: "Poppins", sans-serif;
                                                                                           }
                                                                                           html {
                                                                                             scroll-behavior: smooth;
                                                                                           }
                                                                                           body {
                                                                                             float: left;
                                                                                             width: 100%;
                                                                                             margin: 0px;
                                                                                         background-color: #fff;
                                                                                           }
                                                                           .custom_section_4 {
                                                                               display: block;
                                                                               width: 100%;
                                                                               padding-bottom: 48px;
                                                                           background: #F5F5F5;
                                                                           }
                                                                           .custom_container {
                                                                               max-width: 1140px;
                                                                               margin: 0 auto;
                                                                           }
                                                                           .custom_section_4_list {
                                                                               display: grid;
                                                                               grid-template-columns: 1.5fr 1fr;
                                                                               grid-template-rows: auto;
                                                                               grid-template-areas: ". .";
                                                                               width: 100%;
                                                                           }
                                                                           .custom_section_4_box {
                                                                               float: left;
                                                                               width: 100%;
                                                                               height: 100%;
                                                                           }
                                                                           .custom_section_4_figcaption{
                                                                               position: relative;
                                                                               z-index: 11;
                                                                           }
                                                                           .custom_section_4_figcaption small{
                                                                               padding-bottom: 24px;
                                                                               font-family: 'Josefin Sans';
                                                                               font-style: normal;
                                                                               font-weight: 400;
                                                                               font-size: 14px;
                                                                               line-height: 14px;
                                                                               color: rgba(255, 255, 255, 0.6);
                                                                               display: block;
                                                                           }
                                                                           .custom_section_4_figcaption h4{
                                                                               font-family: 'Bowlby One';
                                                                               font-style: normal;
                                                                               font-weight: 400;
                                                                               font-size: 32px;
                                                                               line-height: 40px;
                                                                               text-transform: uppercase;
                                                                               color: #FFFFFF;
                                                                               padding-bottom: 16px;
                                                                               margin-bottom: 0px;
                                                                           }
                                                                           
                                                                           .custom_section_4_figcaption .figcaption_data_box_btn a{
                                                                           width: 151px;
                                                                               height: 44px;
                                                                               background: transparent;
                                                                               display: flex;
                                                                               align-items: center;
                                                                               justify-content: center;
                                                                               border: 1px solid #F07E27;
                                                                               border-radius: 73px;
                                                                               font-family: 'Josefin Sans';
                                                                               font-style: normal;
                                                                               font-weight: 600;
                                                                               font-size: 13px;
                                                                               line-height: 13px;
                                                                               text-transform: uppercase;
                                                                               color: #F07E27;
                                                                               padding-top: 3px;
                                                                           }
                                                                           .custom_section_4_data_box_one{
                                                                           height: 100%;
                                                                               width: 100%;
                                                                           }
                                                                           .custom_section_4_data_box_one .figcaption_data_box_figure {
                                                                               width: 100%;
                                                                           height: 100%;
                                                                           }
                                                                           .custom_section_4_data_box_one .figcaption_data_box_figure img{
                                                                               width: 100%;
                                                                           height: 100%;
                                                                           }
                                                                           .custom_section_4_data_box_two .figcaption_data_box_figure {
                                                                               position: absolute;
                                                                               bottom: 22px;
                                                                               right: 22px;
                                                                             width: 56px !important;
                                                                           Height:92.94px;
                                                                           }
                                                                           .custom_section_4_data_box_two .figcaption_data_box_figure img{
                                                                            width:100%;
                                                                           }
                                                                           .custom_section_4_data_box_two{
                                                                           display: grid;
                                                                               padding-top: 46px;
                                                                               padding-left: 32px;
                                                                               padding-right: 20px;
                                                                               padding-bottom: 46px;
                                                                               align-items: center;
                                                                               position: relative;
                                                                               background: #282726;
                                                                               border-radius: 0px 3px 3px 0px;
                                                                           }
                                                                           .custom_section_4_data_box_two .bg_img{
                                                                               position: absolute;
                                                                               top: 0px;
                                                                               left: 0px;
                                                                               width: 100%;
                                                                               height: 100%;
                                                                               display: flex;
                                                                               align-items: center;
                                                                               justify-content: center;
                                                                           }
                                                                           @media(min-width:768px) and (max-width:1024px){
                                                                           .custom_section_4_data_box_two .figcaption_data_box_figure {
                                                                               bottom: 8px;
                                                                           }
                                                                           }
                                                                           @media (max-width:767px){
                                                                           .custom_section_4_list {
                                                                               display: flex;
                                                                               flex-direction: column-reverse;
                                                                           }
                                                                           
                                                                           }
                                                                           @media (max-width:540px){
                                                                           
                                                                           .custom_section_4_figcaption {
                                                                               text-align: center;
                                                                           }
                                                                           .figcaption_data_box_btn {
                                                                               display: flex;
                                                                               justify-content: center;
                                                                           }
                                                                           .custom_section_4_data_box_two .figcaption_data_box_figure {
                                                                               bottom: 4px;
                                                                               right: 6px;
                                                                           }
                                                                           }
                                                                           </style>
                                                                           <section class="custom_section_4">
                                                                           <div class="custom_container">
                                                                           <div class="custom_section_4_list">
                                                                           <div class="custom_section_4_data_box_one">
                                                                           <div class="figcaption_data_box_figure"><img src="https://static.tossdown.com/images/90442034-4a1b-4110-ac29-4f8e76951706.png" style="height: 100%; width: 100%;" /></div>
                                                                           </div>
                                                                           <div class="custom_section_4_data_box_two">
                                                                           <div class="custom_section_4_figcaption">
                                                                           <h4>Price match guarantee!</h4>
                                                                           <small>Love Savings? contact us tel:90095-306-0215</small>
                                                                           <div class="figcaption_data_box_btn"><a>Call Us <i class="far fa-arrow-right" aria-hidden="true" style="padding-left: 7px; font-size: 14px;"></i></a></div>
                                                                           </div>
                                                                           <div class="figcaption_data_box_figure"><img src="https://static.tossdown.com/images/93fc7996-07bc-4b5f-996e-16b0623b12de.png" /></div>
                                                                           </div>
                                                                           </div>
                                                                           </div>
                                                                           </section>
                                                                            
                                                                            `
                                                                            },



                                                                            {
                                                                                id:"HomeCustomSection22",
                                                                                name:"HomeCustomSection 23",
                                                                                height:"800px",
                                                                                code:` 
                                                                                 <head>
                                                                
                                                                                <meta charset="utf-8" />
                                                                                <meta
                                                                                  name="viewport"
                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                /> 
                                                                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                               
                                                                                </head> 
                                                                                 <style>
                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                </style>
                                                                                <style>
                                                                                * {
                                                                                                 -web-kit-box-sizing: border-box;
                                                                                                 -moz-box-sizing: border-box;
                                                                                                 box-sizing: border-box;
                                                                                                 padding: 0px;
                                                                                                 margin: 0px;
                                                                                                 font-family: "Poppins", sans-serif;
                                                                                               }
                                                                                               html {
                                                                                                 scroll-behavior: smooth;
                                                                                               }
                                                                                               body {
                                                                                                 float: left;
                                                                                                 width: 100%;
                                                                                                 margin: 0px;
                                                                                             background-color: #fff;
                                                                                               }
                                                                               .custom_section_5 {
                                                                                   display: block;
                                                                                   width: 100%;
                                                                                   padding-bottom: 48px;
                                                                               background: #F5F5F5;
                                                                               }
                                                                               .custom_container {
                                                                                   max-width: 1140px;
                                                                                   margin: 0 auto;
                                                                               }
                                                                               
                                                                               .custom_section_5_list_two {
                                                                                   display: grid;
                                                                                   grid-template-columns: 1fr 1.5fr;
                                                                                   grid-template-rows: auto;
                                                                                   grid-template-areas: ". .";
                                                                                   width: 100%;
                                                                               }
                                                                               .custom_section_5_box {
                                                                                   float: left;
                                                                                   width: 100%;
                                                                                   height: 100%;
                                                                               }
                                                                               .custom_section_5_figcaption{
                                                                                   position: relative;
                                                                                   z-index: 11;
                                                                                   max-width: 200px
                                                                               }
                                                                               .custom_section_5_figcaption small{
                                                                                   padding-bottom: 24px;
                                                                                   font-family: 'Josefin Sans';
                                                                                   font-style: normal;
                                                                                   font-weight: 400;
                                                                                   font-size: 14px;
                                                                                   line-height: 14px;
                                                                                   color: rgba(255, 255, 255, 0.6);
                                                                                   display: block;
                                                                               }
                                                                               .custom_section_5_figcaption h4{
                                                                                   font-family: 'Bowlby One';
                                                                                   font-style: normal;
                                                                                   font-weight: 400;
                                                                                   font-size: 32px;
                                                                                   line-height: 40px;
                                                                                   text-transform: uppercase;
                                                                                   color: #FFFFFF;
                                                                                   padding-bottom: 16px;
                                                                                   margin-bottom: 0px;
                                                                               }
                                                                               
                                                                               .custom_section_5_figcaption .figcaption_data_box_btn a{
                                                                               width: 151px;
                                                                                   height: 44px;
                                                                                   background: transparent;
                                                                                   display: flex;
                                                                                   align-items: center;
                                                                                   justify-content: center;
                                                                                 border: 1px solid #FFFFFF;
                                                                               border-radius: 73px;
                                                                                   font-family: 'Josefin Sans';
                                                                                   font-style: normal;
                                                                                   font-weight: 600;
                                                                                   font-size: 13px;
                                                                                   line-height: 13px;
                                                                                   text-transform: uppercase;
                                                                               color: #FFFFFF;
                                                                                   padding-top: 3px;
                                                                               }
                                                                               .custom_section_5_data_box_one{
                                                                               height: 100%;
                                                                                   width: 100%;
                                                                               }
                                                                               .custom_section_5_data_box_one .figcaption_data_box_figure {
                                                                                   width: 100%;
                                                                                   height: 100%;
                                                                               }
                                                                               .custom_section_5_data_box_one .figcaption_data_box_figure img{
                                                                                   width: 100%;
                                                                                   height: 100%;
                                                                               }
                                                                               .custom_section_5_data_box_two .figcaption_data_box_figure {
                                                                                   position: absolute;
                                                                                   bottom: 0px;
                                                                                   right: 22px;
                                                                                   width: unset !important;
                                                                                   height: unset !important;
                                                                               }
                                                                               .custom_section_5_data_box_two{
                                                                               display: grid;
                                                                                   padding-top: 46px;
                                                                                   padding-left: 32px;
                                                                                   padding-right: 20px;
                                                                                   padding-bottom: 46px;
                                                                                   align-items: center;
                                                                                   position: relative;
                                                                                background: #F07E27;
                                                                               border-radius: 0px 3px 3px 0px;
                                                                               }
                                                                               .custom_section_5_data_box_two .bg_img{
                                                                                   position: absolute;
                                                                                   top: 0px;
                                                                                   left: 0px;
                                                                                   width: 100%;
                                                                                   height: 100%;
                                                                                   display: flex;
                                                                                   align-items: center;
                                                                                   justify-content: center;
                                                                               }
                                                                               @media(min-width:768px) and (max-width:1024px){
                                                                               .custom_section_5_data_box_two .figcaption_data_box_figure {
                                                                                   right: 8px;
                                                                               }
                                                                               }
                                                                               @media (max-width:767px){
                                                                               .custom_section_5_list_two {
                                                                                   display: flex;
                                                                                   flex-direction: column;
                                                                               }
                                                                               
                                                                               }
                                                                               @media (max-width:540px){
                                                                               
                                                                               .custom_section_5_figcaption {
                                                                                   text-align: center;
                                                                               }
                                                                               .figcaption_data_box_btn {
                                                                                   display: flex;
                                                                                   justify-content: center;
                                                                               }
                                                                               .custom_section_5_data_box_two .figcaption_data_box_figure {
                                                                                   right: 6px;
                                                                               }
                                                                               .custom_section_5_figcaption {
                                                                                   max-width: 100%;
                                                                               }
                                                                               }
                                                                               </style>
                                                                               <section class="custom_section_5">
                                                                               <div class="custom_container">
                                                                               <div class="custom_section_5_list_two">
                                                                               <div class="custom_section_5_data_box_two">
                                                                               <div class="custom_section_5_figcaption">
                                                                               <h4>SurfACE CLEANERS</h4>
                                                                               <small>With bleach and fresh scent</small>
                                                                               <div class="figcaption_data_box_btn"><a>Order Now <i class="far fa-arrow-right" aria-hidden="true" style="padding-left: 7px; font-size: 14px;"></i></a></div>
                                                                               </div>
                                                                               <div class="figcaption_data_box_figure"><img src="https://static.tossdown.com/images/980a8ef6-a04a-42c0-9eb2-ffaedd4946b3.png" /></div>
                                                                               </div>
                                                                               <div class="custom_section_5_data_box_one">
                                                                               <div class="figcaption_data_box_figure"><img src="https://static.tossdown.com/images/2fda4eaa-f292-4eac-8a88-887f3fa14323.png" style="height: 100%; width: 100%;" /></div>
                                                                               </div>
                                                                               </div>
                                                                               </div>
                                                                               </section>
                                                                                
                                                                                `
                                                                                },

                                                                                {
                                                                                    id:"HomeCustomSection23",
                                                                                    name:"HomeCustomSection 24",
                                                                                    height:"800px",
                                                                                    code:` 
                                                                                     <head>
                                                                    
                                                                                    <meta charset="utf-8" />
                                                                                    <meta
                                                                                      name="viewport"
                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                    /> 
                                                                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                                   
                                                                                    </head> 
                                                                                     <style>
                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                    </style>
                                                                                    <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.home_section_six_single_box_main {
    display: flex;
    flex-direction: column;
}
.home_section_six_single_box_figure img {
    width: 100%;
}
 .home_section_six {
        display: block;
        width: 100%;
        padding: 0px 15px 48px 15px;
background: #F5F5F5;
      }
      .custom_container {
        max-width: 1140px;
        margin: 0 auto;
      }
.home_section_six_header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
padding-bottom: 32px;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
}
.home_section_six_header h3{
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 32px;
color: #282726;
margin:0px;
padding-bottom:8px;
}
.home_section_six_header small{
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;
color: rgba(0, 0, 0, 0.6);
}
      .home_section_six_box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        grid-template-rows: auto;
        grid-template-areas: ". . . .";
        gap: 25px 25px;
      }
      .home_section_six_single_box {
          width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 36px 30px 36px  30px;
    position: relative;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgb(117 131 142 / 24%);
    border-radius: 4px;
      }
      .home_section_six_single_box h3 {
    font-family: 'Josefin Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 18px;
color: #282726;
        padding-bottom: 8px;
        margin: 0px;
      }
      .home_section_six_single_box p {
 font-family: 'Josefin Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;
color: rgba(0, 0, 0, 0.6);
        margin: 0px;
      }

.home_section_six_single_box_img{
width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -40px;
    left: 0px;
}
.home_section_six_single_box_img figure{
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 8%);
    border-radius: 6px;
}
.home_section_six_single_box_img figure img{
    width: 28px;
}

      @media (min-width: 768px) and (max-width: 1024px) {
    .home_section_six_box {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". .";
        }
        .home_section_six_single_box{
      padding:32px 15px;
        }
      }

      @media (max-width: 767px) {
        .home_section_six_box {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". .";
        }
      }
      @media (max-width: 540px) {

        .home_section_six_box {
          grid-template-columns: 1fr;
          grid-template-areas: ".";
        }
   
      }
 

</style>
<section class="home_section_six">
<div class="custom_container">
<div class="home_section_six_header">
<h3>Services we provide</h3>
<small>We can provide these services to make customers improve their shopping experience.</small></div>
<div class="home_section_six_box">
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/eab1b073-948c-4db2-a733-b366c5b1fe0d.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/10e98788-e31e-4178-a985-2df4aa43f159.png" /></figure>
</div>
<h3>Manufacturing</h3>
<p>"Food service packaging and private labeling services"</p>
</div>
</div>
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/1eb60138-79fb-4ce5-89cf-f066604ca7e1.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/e4f00db8-de35-4989-81dc-66ac0c188a6d.png" /></figure>
</div>
<h3>Place order</h3>
<p>"We accept place orders by phone, email, online and fax."</p>
</div>
</div>
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/eead9a56-baf8-41e4-b473-0662ecc85476.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/0cc9ef05-c856-4ab8-8e45-e0bb6db9ab21.png" /></figure>
</div>
<h3>Fast delivery</h3>
<p>"We accept place orders by phone, email, online and fax."</p>
</div>
</div>
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/423156fb-cca1-4264-84c3-64876afd35ef.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/f62cbc85-c9ef-4802-b457-dc14e56ec3d9.png" /></figure>
</div>
<h3>Quality brands</h3>
<p>"We accept place orders by phone, email, online and fax."</p>
</div>
</div>
</div>
</div>
</section>
                                                                                    
                                                                                    `
                                                                                    },


                                                                                                 {
                                                                                    id:"HomeCustomSection24",
                                                                                    name:"HomeCustomSection 25",
                                                                                    height:"800px",
                                                                                    code:` 
                                                                                     <head>
                                                                    
                                                                                    <meta charset="utf-8" />
                                                                                    <meta
                                                                                      name="viewport"
                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                    /> 
                                                                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                                   
                                                                                    </head> 
                                                                                     <style>
                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                    </style>
                                                                                    <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.home_section_six_single_box_main {
    display: flex;
    flex-direction: column;
}
.home_section_six_single_box_figure img {
    width: 100%;
}
 .home_section_six {
        display: block;
        width: 100%;
        padding: 0px 15px 48px 15px;
background: #F5F5F5;
      }
      .custom_container {
        max-width: 1140px;
        margin: 0 auto;
      }
.home_section_six_header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
padding-bottom: 32px;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
}
.home_section_six_header h3{
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 32px;
color: #282726;
margin:0px;
padding-bottom:8px;
}
.home_section_six_header small{
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;
color: rgba(0, 0, 0, 0.6);
}
      .home_section_six_box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        grid-template-rows: auto;
        grid-template-areas: ". . . .";
        gap: 25px 25px;
      }
      .home_section_six_single_box {
          width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 36px 30px 36px  30px;
    position: relative;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgb(117 131 142 / 24%);
    border-radius: 4px;
      }
      .home_section_six_single_box h3 {
    font-family: 'Josefin Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 18px;
color: #282726;
        padding-bottom: 8px;
        margin: 0px;
      }
      .home_section_six_single_box p {
 font-family: 'Josefin Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;
color: rgba(0, 0, 0, 0.6);
        margin: 0px;
      }

.home_section_six_single_box_img{
width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -40px;
    left: 0px;
}
.home_section_six_single_box_img figure{
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 8%);
    border-radius: 6px;
}
.home_section_six_single_box_img figure img{
    width: 28px;
}

      @media (min-width: 768px) and (max-width: 1024px) {
    .home_section_six_box {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". .";
        }
        .home_section_six_single_box{
      padding:32px 15px;
        }
      }

      @media (max-width: 767px) {
        .home_section_six_box {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". .";
        }
      }
      @media (max-width: 540px) {

        .home_section_six_box {
          grid-template-columns: 1fr;
          grid-template-areas: ".";
        }
   
      }
 

</style>
<section class="home_section_six">
<div class="custom_container">
<div class="home_section_six_header">
<h3>Services we provide</h3>
<small>We can provide these services to make customers improve their shopping experience.</small></div>
<div class="home_section_six_box">
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/eab1b073-948c-4db2-a733-b366c5b1fe0d.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/10e98788-e31e-4178-a985-2df4aa43f159.png" /></figure>
</div>
<h3>Manufacturing</h3>
<p>"Food service packaging and private labeling services"</p>
</div>
</div>
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/1eb60138-79fb-4ce5-89cf-f066604ca7e1.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/e4f00db8-de35-4989-81dc-66ac0c188a6d.png" /></figure>
</div>
<h3>Place order</h3>
<p>"We accept place orders by phone, email, online and fax."</p>
</div>
</div>
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/eead9a56-baf8-41e4-b473-0662ecc85476.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/0cc9ef05-c856-4ab8-8e45-e0bb6db9ab21.png" /></figure>
</div>
<h3>Fast delivery</h3>
<p>"We accept place orders by phone, email, online and fax."</p>
</div>
</div>
<div class="home_section_six_single_box_main">
<div class="home_section_six_single_box_figure"><img src="https://static.tossdown.com/images/423156fb-cca1-4264-84c3-64876afd35ef.png" /></div>
<div class="home_section_six_single_box">
<div class="home_section_six_single_box_img">
<figure><img src="https://static.tossdown.com/images/f62cbc85-c9ef-4802-b457-dc14e56ec3d9.png" /></figure>
</div>
<h3>Quality brands</h3>
<p>"We accept place orders by phone, email, online and fax."</p>
</div>
</div>
</div>
</div>
</section>
                                                                                    
                                                                                    `
                                                                                    },
                                                                                    {
                                                                                        id:"HomeCustomSection25",
                                                                                        name:"HomeCustomSection 26",
                                                                                        height:"800px",
                                                                                        code:` 
                                                                                         <head>
                                                                        
                                                                                        <meta charset="utf-8" />
                                                                                        <meta
                                                                                          name="viewport"
                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                        /> 
                                                                                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                                       
                                                                                        </head> 
                                                                                         <style>
                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                        </style>
                                                                                        <style>
 * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.home_section_six {
    display: block;
    width: 100%;
    padding: 72px 15px 100px 15px;
    float: left;
    background: rgba(181, 11, 20, 0.04);
}
      .custom_container {
        max-width: 1140px;
        margin: 0 auto;
      }
      .home_section_six_figcaption_heading {
        display: grid;
        width: 100%;
        gap: 5px 0px;
        text-align: center;
        padding-bottom: 40px;
        
      }
      .home_section_six_figcaption_heading small {
        font-size: 16px;
        text-transform: uppercase;
        color: #6F6C6C;
        font-family: "Montserrat";
        font-weight: 700;
        line-height: 22px;
      }
      .home_section_six_figcaption_heading h4 {
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 44px;
text-align: center;
color: #212121;
      }
      .home_section_six_figcaption_heading .inner_color {
        color: #E51451;
      }
      .home_section_six_box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        grid-template-rows: auto;
        grid-template-areas: ". . . .";
        gap: 30px 30px;
      }
.home_section_six_single_box img {
    width: 72px;
margin-bottom: 24px;
}
      .home_section_six_single_box {
      width: 100%;
    display: flex;
    background: #FFFFFF;
    flex-direction: column;
    border-left: 4px solid #3E4095;
    height: 100%;
    padding: 48px 32px;
    align-items: flex-start;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
    border-radius: 5px;
      }
      .home_section_six_single_box h3 {
font-style: normal;
font-weight:600;
font-size: 18px;
line-height: 22px;
text-align: center;
color: #212121;
margin-bottom: 12px;
      }
      .home_section_six_single_box p {
      font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 26px;
color: #4D5574;

      }
      .home_section_six_single_box a {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 27px;
        text-decoration-line: underline !important;
        color: #E51451 !important;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        .home_section_six_single_box {
          padding: 36px 18px;
        }
        .home_section_six_single_box h3 {
          font-size: 16px;
          line-height: 20px;
        }
      }
      @media (max-width: 767px) {
        .home_section_six_box {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". .";
        }
.home_section_six_single_box {
    justify-content: center;
    align-items: center;
    text-align: center;
}
      }
      @media (max-width: 540px) {
.home_section_five figure img{
height:auto !important;
}
.company_patners_list {
    margin-bottom: 45px;
}
        .home_section_six_box {
          grid-template-columns: 1fr;
          grid-template-areas: ".";
        }
        .home_section_six_figcaption_heading h4 {
          font-size: 32px;
          line-height: 39px;
        }
      }
      @media (max-width: 450px) {
      }
</style>
<section class="home_section_six">
<div class="home_section_six_figcaption_heading" id="program"><!--<small><span class="inner_color">We</span>ECOMMERCE</small>-->
<h4>Our Distinguishing Qualities</h4>
</div>
<div class="custom_container">
<div class="home_section_six_box">
<div class="home_section_six_single_box"><img src="https://tossdown.site/images/bdb368f06aeb53147d7458ee33d753bf_1657026734.png" />
<h3>CSA Certified</h3>
<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
</div>
<div class="home_section_six_single_box"><img src="https://tossdown.site/images/79c0cdc9bead6c7eb92b7048e1d76610_1657028681.png" />
<h3>NSF Certified</h3>
<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
</div>
<div class="home_section_six_single_box"><img src="https://tossdown.site/images/5fb7003b0ac565471582c885746d0760_1657028713.png" />
<h3>Seasoned Clay</h3>
<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
</div>
<div class="home_section_six_single_box"><img src="https://tossdown.site/images/aebd3967bd21902b0bd8ef689f934e9e_1657028734.png" />
<h3>30Years in Business</h3>
<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
</div>
</div>
</div>
</section>
                                                                                        
                                                                                        `
                                                                                        },

                                                                                        {
                                                                                            id:"HomeCustomSection26",
                                                                                            name:"HomeCustomSection 27",
                                                                                            height:"800px",
                                                                                            code:` 
                                                                                             <head>
                                                                            
                                                                                            <meta charset="utf-8" />
                                                                                            <meta
                                                                                              name="viewport"
                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                            /> 
                                                                                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                                           
                                                                                            </head> 
                                                                                             <style>
                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                            </style>
                                                                                            <style>
                                                                                            * {
                                                                                                             -web-kit-box-sizing: border-box;
                                                                                                             -moz-box-sizing: border-box;
                                                                                                             box-sizing: border-box;
                                                                                                             padding: 0px;
                                                                                                             margin: 0px;
                                                                                                             font-family: "Poppins", sans-serif;
                                                                                                           }
                                                                                                           html {
                                                                                                             scroll-behavior: smooth;
                                                                                                           }
                                                                                                           body {
                                                                                                             float: left;
                                                                                                             width: 100%;
                                                                                                             margin: 0px;
                                                                                                         background-color: #fff;
                                                                                                           }
                                                                                           .home_custom_section_3_main{
                                                                                           display:block;
                                                                                               width: 100%;
                                                                                               padding: 72px 15px;
                                                                                               float: left;
                                                                                           
                                                                                           }
                                                                                           .custom_container{
                                                                                           max-width:1140px;
                                                                                           margin:0 auto;
                                                                                           }
                                                                                           .home_custom_section_3{
                                                                                               display: grid;
                                                                                               grid-template-columns: 1fr 1fr;
                                                                                               grid-template-areas: ". .";
                                                                                               grid-template-rows: auto;
                                                                                               gap: 15px 0px;
                                                                                               position: relative;
                                                                                               z-index: 1;
                                                                                               width: 100%;
                                                                                               align-items: center;
                                                                                           height:100%;
                                                                                           }/*
                                                                                           .home_custom_section_3 figure {
                                                                                               margin-bottom: 0px;
                                                                                               width: 100%;
                                                                                               height: 550px;
                                                                                              background-image: url('https://tossdown.site/images/62d13ecba616ad197ac2030712b50968_1657019585.png');
                                                                                               background-size: cover;
                                                                                               background-repeat: no-repeat;
                                                                                           }*/
                                                                                           .home_section_3_text {
                                                                                           padding-left: 46px;
                                                                                               border-left: 1px solid rgba(77, 85, 116, 0.16);
                                                                                           }
                                                                                           .products-step-hold ul li .pro-step-h {
                                                                                               margin-bottom: 10px;
                                                                                           }
                                                                                           .products-step-hold ul li .pro-step-h.pro-step-h i.fa.fa-check {
                                                                                               color: #069B27;
                                                                                               font-size: 14px;
                                                                                               font-weight: 800;
                                                                                               margin: 8px 10px 7px 5px;
                                                                                           }
                                                                                           .products-step-hold ul li .pro-step-h {
                                                                                               display: flex;
                                                                                               padding: 3px 0px;
                                                                                           font-family: 'Roboto';
                                                                                               font-style: normal;
                                                                                               font-weight: 500;
                                                                                               font-size: 15px;
                                                                                               line-height: 28px;
                                                                                               color: #4D5574;
                                                                                           }
                                                                                           .pro-step-h p b {
                                                                                               font-weight: 800;
                                                                                           }
                                                                                           .home_custom_section_3 figure img{
                                                                                               background-size: cover;
                                                                                               background-repeat: no-repeat;
                                                                                               width: 100%;
                                                                                               height: 100%;
                                                                                               background-position: center;
                                                                                           }
                                                                                           .home_custom_section_3_body{
                                                                                               display: grid;
                                                                                               width: 100%;
                                                                                               flex-direction: column;
                                                                                               gap: 24px 0px;
                                                                                               padding: 0px 50px 0px 0px;
                                                                                           margin-right:50px;
                                                                                           }
                                                                                           .home_custom_section_3_heading{
                                                                                               display: grid;
                                                                                               width: 100%;
                                                                                               flex-direction: column;
                                                                                               gap: 8px 0px;
                                                                                           }
                                                                                           .home_custom_section_3_heading small{
                                                                                           font-family: 'Roboto';
                                                                                           font-style: normal;
                                                                                           font-weight: 500;
                                                                                           font-size: 15px;
                                                                                           line-height: 18px;
                                                                                           letter-spacing: 0.04em;
                                                                                           text-transform: uppercase;
                                                                                           color: #FFFFFF;
                                                                                           
                                                                                           text-shadow: 0px 6px 24px rgba(0, 0, 0, 0.09);
                                                                                           }
                                                                                           .home_custom_section_3_heading h3{
                                                                                           font-style: normal;
                                                                                           font-weight:600;
                                                                                           font-size: 40px;
                                                                                           line-height: 48px;
                                                                                           color: #212121;
                                                                                           }
                                                                                           
                                                                                           .home_custom_section_3_body p{
                                                                                           font-family: 'Roboto';
                                                                                           font-style: normal;
                                                                                           font-weight: 400;
                                                                                           font-size: 15px;
                                                                                           line-height: 28px;
                                                                                           color: #4D5574;
                                                                                           }
                                                                                           .home_custom_section_3_body .more_detail_btn{
                                                                                              width: 174px;
                                                                                               height: 48px;
                                                                                               display: flex;
                                                                                               justify-content: center;
                                                                                               align-items: center;
                                                                                               position: relative;
                                                                                               font-family: 'Roboto';
                                                                                               font-style: normal;
                                                                                               font-weight: 500;
                                                                                               font-size: 14px;
                                                                                               line-height: 16px;
                                                                                               text-transform: uppercase;
                                                                                               color: #000000;
                                                                                               border: 1px solid rgba(0, 0, 0, 0.12);
                                                                                               border-radius: 5px;
                                                                                               transition: 0.5s !important;
                                                                                           }
                                                                                           .home_custom_section_3_body .more_detail_btn:hover {
                                                                                               background: #B50B14;
                                                                                               box-shadow: inset 12em 0 0 0 #b50b14;
                                                                                               color: #fff;
                                                                                           }
                                                                                           @media(max-width:1024px){
                                                                                           .home_custom_section_3_body {
                                                                                               padding: 0px 35px 0px 0px;
                                                                                           gap: 18px 0px;
                                                                                           }
                                                                                           .home_custom_section_3_heading {
                                                                                               gap: 5px 0px;
                                                                                           }
                                                                                           .home_custom_section_3 figure {
                                                                                               height: 450px;
                                                                                           }
                                                                                           .home_custom_section_3_body p {
                                                                                               padding-bottom: 0px;
                                                                                           }
                                                                                           }
                                                                                           @media(max-width:767px){
                                                                                           .home_custom_section_3_learn_btn {
                                                                                               width: 100%;
                                                                                               display: flex;
                                                                                               justify-content: center;
                                                                                           }
                                                                                           .home_custom_section_3_body {
                                                                                               justify-content: center;
                                                                                               text-align: center;
                                                                                           }
                                                                                           .home_custom_section_3_body {
                                                                                               padding: 0px 0px 35px 0px !important;
                                                                                               margin-right: 0px !important;
                                                                                           }
                                                                                           .home_section_3_text {
                                                                                               padding-left: 0px !important;
                                                                                               border-left: unset !important;
                                                                                           }
                                                                                           .home_custom_section_3{
                                                                                               display: flex;
                                                                                               flex-direction: column;
                                                                                               gap: 0px;
                                                                                           }/*
                                                                                           .home_custom_section_3 figure {
                                                                                               height: 300px;
                                                                                           background-position: center;
                                                                                               background-image: url('https://tossdown.site/images/62d13ecba616ad197ac2030712b50968_1657019585.png');
                                                                                           }
                                                                                           .home_custom_section_3_body {
                                                                                               padding:0px 25px 40px 25px;
                                                                                           }*/
                                                                                           
                                                                                           }
                                                                                           @media(max-width:540px){
                                                                                           .home_custom_section_3_heading h3 {
                                                                                               font-size: 36px;
                                                                                               line-height: 46px;
                                                                                           }
                                                                                           
                                                                                           }
                                                                                           @media(max-width:450px){
                                                                                           /*
                                                                                           .home_custom_section_3 figure {
                                                                                               height: 200px;
                                                                                           background-position: center;
                                                                                               background-image: url('https://tossdown.site/images/62d13ecba616ad197ac2030712b50968_1657019585.png');
                                                                                           }*/
                                                                                           }
                                                                                           </style>
                                                                                           <div class="home_custom_section_3_main">
                                                                                           <div class="custom_container">
                                                                                           <div class="home_custom_section_3">
                                                                                           <div class="home_custom_section_3_body">
                                                                                           <div class="home_custom_section_3_heading"><!--<small data-aos-delay="200" data-aos="fade-down" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">Loram Ipsunm</small>-->
                                                                                           <h3 data-aos-delay="400" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">What’s Special about Loram ipsum’s Clay Oven &amp; Lorams?</h3>
                                                                                           </div>
                                                                                           <p data-aos-delay="600" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                                                                           <p data-aos-delay="600" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                                                                           <div class="home_custom_section_3_learn_btn"><a data-aos-delay="900" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="more_detail_btn aos-init aos-animate">Learn more</a></div>
                                                                                           </div>
                                                                                           <div class="home_section_3_text">
                                                                                           <div class="products-step-hold">
                                                                                           <ul>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>NSF certified</b> – This certification indicates that our Lorams have been tested and approved by NSF for operation in the USA.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>CSA certified</b> – This certification indicates that our Lorams have been tested and approved by CSA for operation in the USA and Canada.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>Seasoned clay</b> – After years of innovation and experimentation, we have created the perfect clay mix for longevity, functionality, and taste.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>Exterior casing</b> – high-grade stainless-steel construction to ensure durability.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>Conversion kit</b> – you can switch to natural gas or propane gas with our gas Lorams for sale when you don’t feel like using charcoal as fuel.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>Low operational cost</b> – Thanks to efficient insulation achieved by using fiber wool and sand.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>Simple and portable</b> – for ease of manageability and operability.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>Very Durable</b> – Capable of sustaining high rate of thermal heat and shock.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           <li>
                                                                                           <div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
                                                                                           <p><b>30 years of experience</b> – ipsum is a brand you can trust. Over the years, we have perfected our products and customer service for our clients.</p>
                                                                                           </div>
                                                                                           </li>
                                                                                           </ul>
                                                                                           </div>
                                                                                           </div>
                                                                                           </div>
                                                                                           </div>
                                                                                           </div>
                                                                                            
                                                                                            `
                                                                                            },


                                                                                            {
                                                                                                id:"HomeCustomSection27",
                                                                                                name:"HomeCustomSection 28",
                                                                                                height:"800px",
                                                                                                code:` 
                                                                                                 <head>
                                                                                
                                                                                                <meta charset="utf-8" />
                                                                                                <meta
                                                                                                  name="viewport"
                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                /> 
                                                                                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                                               
                                                                                                </head> 
                                                                                                 <style>
                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                </style>
                                                                                                <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }

.home_custom_section_8_main{
display:block;
    width: 100%;
    padding: 85px 15px ;
    float: left;
background-image: url("https://tossdown.site/images/bed330b73c8d7bdeb1a39631c48b7d76_1657020423.png");
 background-size: cover;
    background-repeat: no-repeat;
margin-top: 72px;
}
.custom_container{
max-width:1140px;
margin:0 auto;
}
.home_custom_section_8{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
    grid-template-rows: auto;
    gap: 15px 0px;
    position: relative;
    z-index: 1;
    width: 100%;
    align-items: center;
height:100%;
}
.home_custom_section_8 figure {
    margin-bottom: 0px;
    width: 100%;
    height: 550px;
   background-image: url('https://static.tossdown.com/images/c2a5779e-a037-4bdb-b12a-569db4b55081.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.home_custom_section_8 figure img{
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
}
.home_custom_section_8_body{
    display: grid;
    width: 100%;
    flex-direction: column;
    gap: 24px 0px;
    padding: 0px 100px 0px 0px;
}
.home_custom_section_8_heading{
    display: grid;
    width: 100%;
    flex-direction: column;
    gap: 8px 0px;
}
.home_custom_section_8_heading small{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #FFFFFF;

text-shadow: 0px 6px 24px rgba(0, 0, 0, 0.09);
}
.home_custom_section_8_heading h3{

font-style: normal;
font-weight: 600;
font-size: 44px;
line-height: 52px;
color: #FFFFFF;
}

.home_custom_section_8_body p{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 28px;
color: rgba(255, 255, 255, 0.7);
    padding-bottom: 24px;
}
.home_custom_section_8_body .more_detail_btn{
    width: 174px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #FFA900;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
transition: 0.5s !important;
}
.home_custom_section_8_body .more_detail_btn:hover {
    background: #B50B14;
    box-shadow: inset 12em 0 0 0 #b50b14;
    color: #fff;
}

@media(max-width:1024px){
.home_custom_section_8_body {
    padding: 0px 35px 0px 0px;
gap: 18px 0px;
}
.home_custom_section_8_heading {
    gap: 5px 0px;
}
.home_custom_section_8 figure {
    height: 450px;
}
.home_custom_section_8_body p {
    padding-bottom: 0px;
}
}
@media(max-width:767px){
.home_custom_section_8_learn_btn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.home_custom_section_8_body {
    justify-content: center;
    text-align: center;
}
.home_custom_section_8{
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.home_custom_section_8 figure {
    height: 300px;
background-position: center;
    background-image: url('https://static.tossdown.com/images/c2a5779e-a037-4bdb-b12a-569db4b55081.jpg');
object-fit: contain !important;
    background-size: contain !important;
}
.home_custom_section_8_body {
    padding:0px 25px 40px 25px;
}

}
@media(max-width:540px){
.home_custom_section_8_heading h3 {
    font-size: 36px;
    line-height: 46px;
}
.home_custom_section_8_body {
    padding: 0px 0px 40px 0px;
}
}
@media(max-width:450px){

.home_custom_section_8 figure {
      height: 290px;
background-position: center;
    background-image: url('https://static.tossdown.com/images/c2a5779e-a037-4bdb-b12a-569db4b55081.jpg');
}
}
</style>
<div class="home_custom_section_8_main">
<div class="custom_container">
<div class="home_custom_section_8">
<div class="home_custom_section_8_body">
<div class="home_custom_section_8_heading"><small data-aos-delay="200" data-aos="fade-down" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">Lorem Ipsum</small>
<h3 data-aos-delay="400" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">Celebrating 30 Years of Experience</h3>
</div>
<p data-aos-delay="600" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
<div class="home_custom_section_8_learn_btn"><a data-aos-delay="900" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="more_detail_btn aos-init aos-animate" >Learn more</a></div>
</div>
<figure data-aos-delay="200" data-aos-offset="120" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" class="aos-init aos-animate"><!--<img src="https://static.tossdown.com/images/3e4e099c-cd70-4873-b49d-621c9de25d98.jpg" />--></figure>
</div>
</div>
</div>
                                                                                                
                                                                                                `
                                                                                                },


                                                                                                {
                                                                                                    id:"HomeCustomSection28",
                                                                                                    name:"HomeCustomSection 29",
                                                                                                    height:"800px",
                                                                                                    code:` 
                                                                                                     <head>
                                                                                    
                                                                                                    <meta charset="utf-8" />
                                                                                                    <meta
                                                                                                      name="viewport"
                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                    /> 
                                                                                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
      <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
     
                                                                                                    </head> 
                                                                                                     <style>
                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                    </style>
                                                                                                    <style>
 * {
                      -web-kit-box-sizing: border-box;
                      -moz-box-sizing: border-box;
                      box-sizing: border-box;
                      padding: 0px;
                      margin: 0px;
                      font-family: "Poppins", sans-serif;
                    }
                    html {
                      scroll-behavior: smooth;
                    }
                    body {
                      float: left;
                      width: 100%;
                      margin: 0px;
                  background-color: #fff;
                    }
    
.promotion-card-box {
    text-align: center;
}
.promotion-card-box.right {
    padding-right: 0px;
}
.promotion-card-box img {
    width: 100%;
    height: 284px;
}
.promotion-card-box span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #222321;
    text-align: center;
    margin-top: 21px;
    display: block;
}
.promo-bottom-pd {
    padding: 16px 15px;
}
.promotion-card-box {
    padding: 0px 8px;
}
.pd-zero-hold {
    padding: 0px;
}
.promotion-card-box.left {
    padding-left: 0px !important;
}
.pro-main-custom-heading {
    padding-top: 40px;
    text-align: center;
}
.pro-main-custom-heading h2 {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #222321;
    padding-bottom: 32px;
    margin-bottom: 0px;
}
.custom_section_4 {
    display: block;
    width: 100%;
    margin-bottom: 65px;
    padding: 0px 15px;
}
.custom_container {
    max-width: 1200px !important;
    margin: 0 auto;
}
.custom_section_4_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". .";
    gap: 15px 15px;
    width: 100%;
}
.custom_section_4_box {
    float: left;
    width: 100%;
    height: 100%;
}
.custom_section_4_figcaption{
position: relative;
    z-index: 11;
    background: #404041;
    padding: 30px 20px;
    width: 204px;
    top: 0px;
    left: 0px;
}
.custom_section_4_figcaption h4{
    margin-bottom: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.custom_section_4_figcaption .figcaption_data_box_btn a{
width: 126px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 6px 24px rgb(0 0 0 / 8%);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #404041;
}
.custom_section_4_data_box_one {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0px;
    padding-left: 24px;
    align-items: center;
    background-size: 100%;
    background-repeat: no-repeat;
    top: 0px;
    height: 250px;
}

@media(min-width:768px) and (max-width:1024px){
.custom_section_4_figcaption h4 {
     font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
}
}
@media (max-width:767px){
.pro-main-custom-heading {
    padding-top: 25px !important;
}
.custom_section_4 {
    margin-bottom: 0px !important;
}
.promotion-card-box img {
    height: auto !important;
}
.promotion-card-box span {
    font-size: 15px  !important;
    margin-top: 14px  !important;
    margin-bottom: 30px  !important;
}
.custom_section_4_figcaption h4 br {
    display: none !important;
}
.custom_section_4_figcaption h4 {
    margin-bottom: 13px !important;
    font-size: 13px !important;
}
.custom_section_4_figcaption {
    padding: 14px 0px !important;
    width: auto !important;
}
.custom_section_4_data_box_one {
    height: 135px !important;
}
.pro-main-custom-heading h2 {
    font-size: 25px !important;
}
.custom_section_4_list {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
}

}
@media (max-width:540px){
.custom_section_4_data_box_one{
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
.custom_section_4_figcaption {
    padding-bottom: 20px;
}
.figcaption_data_box_btn{
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
}
}
</style>
<section class="custom_section_4">
<div class="container">
<div class="row">
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div class="pro-main-custom-heading">
<h2>Sale goodness with extra offers on top</h2>
</div>
</div>
</div>
</div>
<div class="custom_container">
<div class="custom_section_4_list">
<div class="custom_section_4_data_box_one" style="background-image: url('https://static.tossdown.com/images/e7c83ec0-879f-4af2-9fd9-30b2c5770b81.jpg');">
<div class="custom_section_4_figcaption">
<h4>SHOP<br />SPECIAL<br />BUYS</h4>
<div class="figcaption_data_box_btn"><a >Buy Now</a></div>
</div>
</div>
<div class="custom_section_4_data_box_one" style="background-image: url('https://static.tossdown.com/site/10b8a229-e298-4191-aa9b-4bd75f49df32.jpg');">
<div class="custom_section_4_figcaption">
<h4>EXTRA £ OFF<br />ALL POWER<br />RECLINERS</h4>
<div class="figcaption_data_box_btn"><a >Buy Now</a></div>
</div>
</div>
</div>
</div>
<div class="container">
<div class="row promo-bottom-pd">
<div class="col-lg-3 col-md-3 col-sm-3  col-xs-12 pd-zero-hold">
<div class="promotion-card-box left"><img src="https://tossdown.site/images/12685a61b1c534813d265faaf0ec729d_1658825220.jpg" /> <span>10% off on TV sets</span></div>
</div>
<div class="col-lg-3 col-md-3 col-sm-3  col-xs-12 pd-zero-hold">
<div class="promotion-card-box"><img src="https://tossdown.site/images/8cbcb97545ccd8bc2323f988767d22dd_1658825269.jpg" /> <span>Two free chairs</span></div>
</div>
<div class="col-lg-3 col-md-3 col-sm-3  col-xs-12 pd-zero-hold">
<div class="promotion-card-box"><img src="https://static.tossdown.com/site/3294169a-fb16-4a61-9483-cac0ff7fafcf.jpg" /> <span>King size for the price of a double</span></div>
</div>
<div class="col-lg-3 col-md-3 col-sm-3  col-xs-12 pd-zero-hold">
<div class="promotion-card-box right"><img src="https://tossdown.site/images/7dad0c64e878fd101d1613118202c850_1658825335.jpg" /> <span>Extra 10% on Wardrobes</span></div>
</div>
</div>
</div>
</section>
                                                                                                    
                                                                                                    `
                                                                                                    },

                                                                                                    {
                                                                                                        id:"HomeCustomSection29",
                                                                                                        name:"HomeCustomSection 30",
                                                                                                        height:"800px",
                                                                                                        code:` 
                                                                                                         <head>
                                                                                        
                                                                                                        <meta charset="utf-8" />
                                                                                                        <meta
                                                                                                          name="viewport"
                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                        /> 
                                                                                                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                                                                                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
          <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
         
                                                                                                        </head> 
                                                                                                         <style>
                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                        </style>
                                                                                                        <style>
                                                                                                        * {
                                                                                                                          -web-kit-box-sizing: border-box;
                                                                                                                          -moz-box-sizing: border-box;
                                                                                                                          box-sizing: border-box;
                                                                                                                          padding: 0px;
                                                                                                                          margin: 0px;
                                                                                                                          font-family: "Poppins", sans-serif;
                                                                                                                        }
                                                                                                                        html {
                                                                                                                          scroll-behavior: smooth;
                                                                                                                        }
                                                                                                                        body {
                                                                                                                          float: left;
                                                                                                                          width: 100%;
                                                                                                                          margin: 0px;
                                                                                                                      background-color: #fff;
                                                                                                                        }
                                                                                                        section.shipping_custom_section {
                                                                                                            background: #E9EDE3;
                                                                                                            padding: 0px 0px 70px;
                                                                                                        }
                                                                                                        .custom-inner-heading {
                                                                                                            margin-top: 70px;
                                                                                                        }
                                                                                                        .custom-inner-heading span {
                                                                                                              font-style: normal;
                                                                                                            font-weight: 100;
                                                                                                            font-size: 16px;
                                                                                                            line-height: 16px;
                                                                                                            text-transform: uppercase;
                                                                                                            color: #212121;
                                                                                                            margin-bottom: 12px;
                                                                                                            display: block;
                                                                                                            font-family: 'fsmeridianreg' !important;
                                                                                                        }
                                                                                                        .custom-inner-heading h2 {
                                                                                                            font-style: normal;
                                                                                                            font-weight: 500 !important;
                                                                                                            font-size: 44px;
                                                                                                            line-height: 44px;
                                                                                                            color: #212121;
                                                                                                        }
                                                                                                        .content-inner-box {
                                                                                                            display: flex;
                                                                                                        margin-bottom: 30px;
                                                                                                        }
                                                                                                        .shipping-description-hold span {
                                                                                                            font-style: normal;
                                                                                                            font-weight: 500;
                                                                                                            font-size: 18px;
                                                                                                            line-height: 18px;
                                                                                                            color: #222321;
                                                                                                            margin-bottom: 12px;
                                                                                                            display: block;
                                                                                                            text-align: left;
                                                                                                        }
                                                                                                        .shipping-description-hold p {
                                                                                                            font-style: normal;
                                                                                                            font-weight: 400;
                                                                                                            font-size: 15px;
                                                                                                            line-height: 21px;
                                                                                                            color: #464646;
                                                                                                            font-family: 'fsmeridianreg';
                                                                                                        }
                                                                                                        .shipping-img-hold {
                                                                                                            margin-right: 15px;
                                                                                                        }
                                                                                                        .content-inner-box .shipping-description-hold {
                                                                                                            width: 320px;
                                                                                                        }
                                                                                                        .content-inner-box .shipping-img-hold img {
                                                                                                            width: 56px;
                                                                                                            height: 56px;
                                                                                                        }
                                                                                                        
                                                                                                        </style>
                                                                                                        <section class="shipping_custom_section">
                                                                                                        <div class="container">
                                                                                                        <div class="row">
                                                                                                        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                                                                                                        <div class="custom-inner-heading"><span>Furniture Bazar</span>
                                                                                                        <h2>Distinctive features</h2>
                                                                                                        </div>
                                                                                                        <div class="shipping-content-hold">
                                                                                                        <div class="content-inner-box">
                                                                                                        <div class="shipping-img-hold"><img src="https://tossdown.site/images/0ceeb1bc6117240d4ff09334eb7f9d80_1658827443.png" /></div>
                                                                                                        <div class="shipping-description-hold"><span>Free shipping on all orders</span>
                                                                                                        <p>Get Free Shipping on all orders over £300 in Most of the Parts of UK. Items will arrive in 1 to 7 Busin -ess days.</p>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        <div class="content-inner-box">
                                                                                                        <div class="shipping-img-hold"><img src="https://tossdown.site/images/2bd39e15f634794f313ee2abd9b622a1_1658827528.png" /></div>
                                                                                                        <div class="shipping-description-hold"><span>Amazing customer service</span>
                                                                                                        <p>Customers Satisfaction is first priority of Furnitures Bazar. We provide best quality products and amazing customer care service with Return and Exchange Policy.</p>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        <div class="content-inner-box">
                                                                                                        <div class="shipping-img-hold"><img src="https://tossdown.site/images/02b6b9b87695b449e33499cdaeff5018_1658827543.png" /></div>
                                                                                                        <div class="shipping-description-hold"><span>Best &amp; cheapest furniture</span>
                                                                                                        <p>Furnitures Bazar have the Cheapest price of all Furniture Items as compared to other Man -ufacturer in Uk. Shop Now and you will find it True with No compromise on Quality of Furniture.</p>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
                                                                                                        <div class="shipgin-right-second-img"><img src="https://tossdown.site/images/88acd110a19960ea52499ceb00038596_1658827702.png" /></div>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                        </section>
                                                                                                        
                                                                                                        `
                                                                                                        },

                                                                                                        {
                                                                                                            id:"HomeCustomSection30",
                                                                                                            name:"HomeCustomSection 31",
                                                                                                            height:"800px",
                                                                                                            code:` 
                                                                                                            <head>
                                                                                                            <meta charset="utf-8" />
                                                                                                            <meta
                                                                                                              name="viewport"
                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                            />
                                                                                                          
                                                                                                            <link
                                                                                                              rel="stylesheet"
                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                              crossorigin="anonymous"
                                                                                                            />
                                                                                                            <link
                                                                                                              rel="stylesheet"
                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                              crossorigin="anonymous"
                                                                                                            />
                                                                                                          
                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                            <script
                                                                                                              type="text/javascript"
                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                            ></script>
                                                                                                            <script
                                                                                                              type="text/javascript"
                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                            ></script>
                                                                                                          
                                                                                                            <link
                                                                                                              rel="stylesheet"
                                                                                                              type="text/css"
                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                            />
                                                                                                            <link
                                                                                                              rel="stylesheet"
                                                                                                              type="text/css"
                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                            />
                                                                                                          </head>
                                                                                                             <style>
                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                            </style>
                                                                                                            <style>

                                                                                                            * {
                                                                                                                           -web-kit-box-sizing: border-box;
                                                                                                                           -moz-box-sizing: border-box;
                                                                                                                           box-sizing: border-box;
                                                                                                                           padding: 0px;
                                                                                                                           margin: 0px;
                                                                                                                           font-family: "Poppins", sans-serif;
                                                                                                                         }
                                                                                                                         html {
                                                                                                                           scroll-behavior: smooth;
                                                                                                                         }
                                                                                                                         body {
                                                                                                                           float: left;
                                                                                                                           width: 100%;
                                                                                                                           margin: 0px;
                                                                                                                       background-color: #fff;
                                                                                                                         }
                                                                                                         
                                                                                                         section#section1 {
                                                                                                             position: relative;
                                                                                                         }
                                                                                                         figure.slider-side-layer-img {
                                                                                                             position: absolute;
                                                                                                             bottom: -300px;
                                                                                                             z-index: 11;
                                                                                                             right: 0px;
                                                                                                         }
                                                                                                         .custom-container{
                                                                                                             max-width: 1200px;
                                                                                                             margin: 100px auto;
                                                                                                         }
                                                                                                         .slider li{
                                                                                                             list-style: none;
                                                                                                             margin: 0px 10px;
                                                                                                         }
                                                                                                         .slick-slider{  
                                                                                                             
                                                                                                             display: flex;
                                                                                                             align-items: center;
                                                                                                             justify-content: center;
                                                                                                         }
                                                                                                         .section1-heading h1 {
                                                                                                             font-family: 'Albra';
                                                                                                             font-style: normal;
                                                                                                             font-weight: 700;
                                                                                                             font-size: 48px;
                                                                                                             line-height: 55px;
                                                                                                             text-transform: capitalize;
                                                                                                             color: #FFFFFF;
                                                                                                             text-align: center;
                                                                                                             margin-bottom: 41px;
                                                                                                         z-index:20;
                                                                                                         
                                                                                                         }
                                                                                                         
                                                                                                         
                                                                                                         
                                                                                                         .section1-heading {
                                                                                                         position: relative;
                                                                                                         
                                                                                                         }
                                                                                                         .section1-heading::before{
                                                                                                          content: 'our Categories';
                                                                                                         font-family: 'Karla';
                                                                                                         font-style: normal;
                                                                                                         font-weight: 700;
                                                                                                         font-size: 88px;
                                                                                                         line-height: 103px;
                                                                                                         
                                                                                                         
                                                                                                         text-align: center;
                                                                                                         text-transform: uppercase;
                                                                                                         position: absolute;
                                                                                                         
                                                                                                         
                                                                                                         color: #0E0E0E;
                                                                                                         top: -70px;
                                                                                                             left: 21%;
                                                                                                         
                                                                                                         }
                                                                                                         
                                                                                                         .card1 {
                                                                                                             position: relative;
                                                                                                             width: 250px;
                                                                                                             display: flex;
                                                                                                             align-items: center;
                                                                                                         }
                                                                                                         .fig-price-or-more {
                                                                                                             display: flex;
                                                                                                             flex-direction: column;
                                                                                                             gap: 11px;
                                                                                                         }
                                                                                                         .fig-price-or-more h3{
                                                                                                         
                                                                                                         
                                                                                                             
                                                                                                         font-family: 'Albra';
                                                                                                         font-style: normal;
                                                                                                         font-weight: 700;
                                                                                                         font-size: 24px;
                                                                                                         line-height: 22px;
                                                                                                         /* identical to box height, or 92% */
                                                                                                         
                                                                                                         
                                                                                                         color: #FFFFFF;
                                                                                                         
                                                                                                         }
                                                                                                         .fig-price-or-more a{
                                                                                                             font-family: 'Karla';
                                                                                                         font-style: normal;
                                                                                                         font-weight: 700;
                                                                                                         font-size: 14px;
                                                                                                         line-height: 22px;
                                                                                                         /* identical to box height, or 157% */
                                                                                                         
                                                                                                         text-decoration-line: underline;
                                                                                                         text-transform: uppercase;
                                                                                                         
                                                                                                         color: #000000;
                                                                                                         
                                                                                                         }
                                                                                                         
                                                                                                         .figure-buttom{
                                                                                                             text-decoration: none;
                                                                                                             font-family: 'Mulish';
                                                                                                         font-style: normal;
                                                                                                         font-weight: 600;
                                                                                                         font-size: 20px;
                                                                                                         line-height: 25px;
                                                                                                         position: absolute;
                                                                                                         top: 77px;
                                                                                                         margin-top: 10px;
                                                                                                         
                                                                                                         color: #FFFFFF;
                                                                                                             
                                                                                                             display: flex!important
                                                                                                             ;
                                                                                                             align-items: center;
                                                                                                             justify-content: center;
                                                                                                            text-align: center;
                                                                                                             display: inline-block;
                                                                                                             width: 90%;
                                                                                                             height: 50px;
                                                                                                         }
                                                                                                         .slider {
                                                                                                             display: flex;
                                                                                                             position: relative;
                                                                                                             align-items: center;
                                                                                                             justify-content: center;
                                                                                                         }
                                                                                                         .img-figure {
                                                                                                           overflow: hidden;
                                                                                                         }
                                                                                                         
                                                                                                         .first-bg{
                                                                                                             background: #DB731E;height: 100%;
                                                                                                         }
                                                                                                         
                                                                                                         
                                                                                                         .second-bg{
                                                                                                             background: #FFBA00;height: 100%;
                                                                                                         }
                                                                                                         
                                                                                                         .thirld-bg{
                                                                                                             background: #007936;
                                                                                                         height: 100%;
                                                                                                         }
                                                                                                         .slider img{
                                                                                                             width: 137.61px;
                                                                                                         height: 137.61px;
                                                                                                         }
                                                                                                         .fourth-bg{
                                                                                                             background: #CC3433;
                                                                                                         height: 100%;
                                                                                                         
                                                                                                         }
                                                                                                         @media (max-width:900px){
                                                                                                         .section1-heading::before{
                                                                                                         display:none;
                                                                                                         
                                                                                                         }
                                                                                                         
                                                                                                         
                                                                                                         }
                                                                                                         @media (max-width:700px){
                                                                                                         .card1 {
                                                                                                             position: relative;
                                                                                                             width: 200px;
                                                                                                             display: flex;
                                                                                                             align-items: center;
                                                                                                         }
                                                                                                         
                                                                                                         .section1-heading h1 {
                                                                                                           
                                                                                                             font-size: 40px;}
                                                                                                         }
                                                                                                         
                                                                                                         @media (max-width:520px){
                                                                                                         .section1-heading h1 {
                                                                                                           
                                                                                                             font-size: 26px;
                                                                                                         
                                                                                                         }
                                                                                                         
                                                                                                         }
                                                                                                         
                                                                                                           </style>
                                                                                                         <section id="section1">
                                                                                                         <figure class="slider-side-layer-img" data-aos="fade-right" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/6001be449b8b8b9c14e6960951d4b6da_1660304879.png" alt="" /></figure>
                                                                                                         <div class="custom-container">
                                                                                                         <div class="section1-heading">
                                                                                                         <h1>A Cuisine to Keep you Going</h1>
                                                                                                         </div>
                                                                                                         <div class="cards">
                                                                                                         <ul class="slider">
                                                                                                         <li class="first-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/d49261146a42cc9f6e6a295880db67cd_1659519200.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Starters</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         <li class="second-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/f860da75b6467812bc73465303954da7_1659519254.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Burgers</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         <li class="thirld-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/fd42b22aecb6cc4be103195225f3e5b5_1659519280.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Pastas</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         <li class="fourth-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/eec877d4842a0bf8568d0b60e7bd4f22_1659519300.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Drinks</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         <li class="first-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/d49261146a42cc9f6e6a295880db67cd_1659519200.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Starters</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         <li class="second-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/f860da75b6467812bc73465303954da7_1659519254.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Burgers</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         <li class="thirld-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/fd42b22aecb6cc4be103195225f3e5b5_1659519280.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Pastas</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         <li class="fourth-bg">
                                                                                                         <div class="card1">
                                                                                                         <figure class="img-figure"><a href="/shop"><img width="250px" height="200px" src="https://tossdown.site/images/eec877d4842a0bf8568d0b60e7bd4f22_1659519300.png" alt="slider-img" /></a></figure>
                                                                                                         <div class="fig-price-or-more">
                                                                                                         <h3>Drinks</h3>
                                                                                                         <a>Explore</a></div>
                                                                                                         </div>
                                                                                                         </li>
                                                                                                         </ul>
                                                                                                         </div>
                                                                                                         </div>
                                                                                                         </section>
                                                                                                         <script>
                                                                                                                    $(document).ready(function () {  
                                                                                                                    
                                                                                                                 $(".slider").slick({
                                                                                                                   slidesToShow: 4,
                                                                                                                   slidesToScroll: 1,    
                                                                                                                   autoplay: false,
                                                                                                                   autoplaySpeed: 1000,
                                                                                                                   dots: false,
                                                                                                                   arrows: false,
                                                                                                                   responsive: [
                                                                                                                     {
                                                                                                                       breakpoint: 1024,
                                                                                                                       settings: {
                                                                                                                         slidesToShow: 3,
                                                                                                                         slidesToScroll: 1,
                                                                                                                         autoplay: false,
                                                                                                                         autoplaySpeed: 2000,
                                                                                                                         dots: false,
                                                                                                                 arrows: false,
                                                                                                                       },
                                                                                                                     },
                                                                                                                     {
                                                                                                                       breakpoint: 767,
                                                                                                                       settings: {
                                                                                                                         slidesToShow: 2,
                                                                                                                         slidesToScroll: 1,
                                                                                                                         autoplay: false,
                                                                                                                         autoplaySpeed: 2000,
                                                                                                                              dots: false,
                                                                                                               arrows: false,
                                                                                                                       },
                                                                                                                     },
                                                                                                                     {
                                                                                                                       breakpoint: 540,
                                                                                                                       settings: {
                                                                                                                         slidesToShow: 2,
                                                                                                                         slidesToScroll: 1,
                                                                                                                         autoplay: false,
                                                                                                                         autoplaySpeed: 2000,
                                                                                                                             dots: false,
                                                                                                                   arrows: false,
                                                                                                                       },
                                                                                                                     },
                                                                                                                     {
                                                                                                                       breakpoint: 400,
                                                                                                                       settings: {
                                                                                                                         slidesToShow: 1,
                                                                                                                         slidesToScroll: 1,
                                                                                                                         autoplay: false,
                                                                                                                         autoplaySpeed: 2000,
                                                                                                                              dots: false,
                                                                                                                    arrows: false,
                                                                                                                       },
                                                                                                                     },
                                                                                                                   ],
                                                                                                                 });
                                                                                                               });
                                                                                                             </script>
                                                                                                            
                                                                                                            `
                                                                                                            },


                                                                                                            {
                                                                                                                id:"HomeCustomSection31",
                                                                                                                name:"HomeCustomSection 32",
                                                                                                                height:"800px",
                                                                                                                code:` 
                                                                                                                <head>
                                                                                                                <meta charset="utf-8" />
                                                                                                                <meta
                                                                                                                  name="viewport"
                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                />
                                                                                                              
                                                                                                                <link
                                                                                                                  rel="stylesheet"
                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                  crossorigin="anonymous"
                                                                                                                />
                                                                                                                <link
                                                                                                                  rel="stylesheet"
                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                  crossorigin="anonymous"
                                                                                                                />
                                                                                                              
                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                <script
                                                                                                                  type="text/javascript"
                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                ></script>
                                                                                                                <script
                                                                                                                  type="text/javascript"
                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                ></script>
                                                                                                              
                                                                                                                <link
                                                                                                                  rel="stylesheet"
                                                                                                                  type="text/css"
                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                />
                                                                                                                <link
                                                                                                                  rel="stylesheet"
                                                                                                                  type="text/css"
                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                />
                                                                                                              </head>
                                                                                                                 <style>
                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                </style>
                                                                                                                <style>
                                                                                                                * {
                                                                                                                  -web-kit-box-sizing: border-box;
                                                                                                                   -moz-box-sizing: border-box; 
                                                                                                                  box-sizing:border-box; 
                                                                                                                  padding: 0px;
                                                                                                                  margin: 0px;
                                                                                                                }
                                                                                                                html {
                                                                                                                  scroll-behavior: smooth;
                                                                                                                }
                                                                                                                body {
                                                                                                             
                                                                                                                  width: 100%;
                                                                                                                  margin: 0px;
                                                                                                                }
                                                                                                          
                                                                                                                .section-inner-banner {
                                                                                                                  width: 100%;
                                                                                                                
                                                                                                          
                                                                                                                  display: block;
                                                                                                                  background-color: #000;
                                                                                                          position:relative;
                                                                                                               
                                                                                                                }
                                                                                                          
                                                                                                          
                                                                                                          figure.section-side-img {
                                                                                                              position: absolute;
                                                                                                              bottom: 0px;
                                                                                                          }
                                                                                                                .section-inner-container {
                                                                                                                  max-width: 1140px;
                                                                                                                  margin: 0px auto;
                                                                                                                }
                                                                                                                .banner-inner-left {
                                                                                                                  background-color: #00a850;
                                                                                                                  display: grid;
                                                                                                                  grid-template-columns: 1.4fr 1fr;
                                                                                                                  height: 300px;
                                                                                                                  border-radius: 11px;
                                                                                                                }
                                                                                                                .banner-inner-right {
                                                                                                                  background: #ffc900;
                                                                                                          
                                                                                                                  display: grid;
                                                                                                                  grid-template-columns: 1.4fr 1fr;
                                                                                                                  height: 300px;
                                                                                                                  border-radius: 11px;
                                                                                                                }
                                                                                                                .content-side {
                                                                                                                  display: flex;
                                                                                                                  align-items: start;
                                                                                                                  justify-content: center;
                                                                                                                  flex-direction: column;
                                                                                                                  padding-left: 30px;
                                                                                                                }
                                                                                                                .content-side-right {
                                                                                                                  display: flex;
                                                                                                                  align-items: start;
                                                                                                                  justify-content: center;
                                                                                                                  flex-direction: column;
                                                                                                                  padding-left: 30px;
                                                                                                                }
                                                                                                                .content-side-right span {
                                                                                                                  font-family: "Karla";
                                                                                                                  font-style: normal;
                                                                                                                  font-weight: 500;
                                                                                                                  font-size: 18px;
                                                                                                                  line-height: 21px;
                                                                                                                  /* identical to box height */
                                                                                                          
                                                                                                                  text-align: center;
                                                                                                                  letter-spacing: 0.02em;
                                                                                                          
                                                                                                                  color: #000000;
                                                                                                                  margin-bottom: 8px;
                                                                                                                }
                                                                                                                .content-side-right h3 {
                                                                                                                  font-family: "Albra";
                                                                                                                  font-style: normal;
                                                                                                                  font-weight: 800;
                                                                                                                  font-size: 36px;
                                                                                                                  line-height: 41px;
                                                                                                            
                                                                                                                  text-transform: capitalize;
                                                                                                          
                                                                                                                  color: #000000;
                                                                                                                  margin-bottom: 8px;
                                                                                                                }
                                                                                                          
                                                                                                                .content-side-right P {
                                                                                                                  font-family: "Karla";
                                                                                                                  font-style: normal;
                                                                                                                  font-weight: 400;
                                                                                                                  font-size: 14px;
                                                                                                                  line-height: 22px;
                                                                                                                  /* or 157% */
                                                                                                          
                                                                                                                  color: #313030;
                                                                                                                }
                                                                                                          
                                                                                                                .content-side span {
                                                                                                                  font-family: "Karla";
                                                                                                                  font-style: normal;
                                                                                                                  font-weight: 500;
                                                                                                                  font-size: 18px;
                                                                                                                  line-height: 21px;
                                                                                                                  /* identical to box height */
                                                                                                          
                                                                                                                  text-align: center;
                                                                                                                  letter-spacing: 0.02em;
                                                                                                                  padding-bottom: 8px;
                                                                                                          
                                                                                                                  color: #ffffff;
                                                                                                                }
                                                                                                                .content-side h3 {
                                                                                                                  font-family: "Albra";
                                                                                                                  font-style: normal;
                                                                                                                  font-weight: 700;
                                                                                                                  font-size: 36px;
                                                                                                                  line-height: 42px;
                                                                                                                  /* or 117% */
                                                                                                          
                                                                                                                  text-transform: capitalize;
                                                                                                          
                                                                                                                  color: #ffffff;
                                                                                                                  padding-bottom: 8px;
                                                                                                                }
                                                                                                                .content-side p {
                                                                                                                  font-family: "Karla";
                                                                                                                  font-style: normal;
                                                                                                                  font-weight: 400;
                                                                                                                  font-size: 14px;
                                                                                                                  line-height: 22px;
                                                                                                                  /* or 157% */
                                                                                                          
                                                                                                                  color: #e2dede;
                                                                                                                  padding-bottom: 18px;
                                                                                                                }
                                                                                                          
                                                                                                                .banner-inner-speration {
                                                                                                                  display: grid;
                                                                                                                  grid-template-columns: 1fr 1fr;
                                                                                                                  gap: 30px;
                                                                                                                }
                                                                                                                .price-inner {
                                                                                                                  font-family: "Albra";
                                                                                                                  font-style: normal;
                                                                                                                  font-weight: 700;
                                                                                                                  font-size: 25px;
                                                                                                                  line-height: 22px;
                                                                                                                  /* identical to box height, or 88% */
                                                                                                          
                                                                                                                  color: #f6c114;
                                                                                                                }
                                                                                                                .image-side {
                                                                                                                  display: flex;
                                                                                                                  align-items: center;
                                                                                                                  justify-content: center;
                                                                                                                }
                                                                                                          
                                                                                                                @media (max-width: 1064px) {
                                                                                                                  .banner-inner-speration {
                                                                                                                    grid-template-columns: 1fr;
                                                                                                                  }
                                                                                                                  .section-inner-container {
                                                                                                                    margin: 0px auto;
                                                                                                                    max-width: 800px;
                                                                                                                  }
                                                                                                                  .banner-inner-left {
                                                                                                                    grid-template-columns: 1fr 1fr;
                                                                                                                  }
                                                                                                                  .banner-inner-right {
                                                                                                                    grid-template-columns: 1fr 1fr;
                                                                                                                  }
                                                                                                                  .image-side {
                                                                                                                    width: 100%;
                                                                                                                  }
                                                                                                                }
                                                                                                                @media (max-width: 660px) {
                                                                                                                  .content-side h3 {
                                                                                                                    font-size: 30px;
                                                                                                                  }
                                                                                                                  .content-side p {
                                                                                                                    font-size: 12px;
                                                                                                                  }
                                                                                                                  .content-side-right h3 {
                                                                                                                    font-size: 30px;
                                                                                                                  }
                                                                                                                  .content-side-right P {
                                                                                                                    font-size: 12px;
                                                                                                                  }
                                                                                                                }
                                                                                                                @media (max-width: 598px) {
                                                                                                          figure.section-side-img {
                                                                                                             display:none;
                                                                                                          }
                                                                                                                  .banner-inner-left {
                                                                                                                    grid-template-columns: 1fr;
                                                                                                                    height: 100%;
                                                                                                                    gap: 30px;
                                                                                                                  }
                                                                                                                  .content-side {
                                                                                                                    align-items: center;
                                                                                                                    padding: 0px;
                                                                                                                    padding-top: 20px;
                                                                                                                  }
                                                                                                                  .banner-inner-right {
                                                                                                                    height: 100%;
                                                                                                                    grid-template-columns: 1fr;
                                                                                                                    padding: 0px !important;
                                                                                                                    gap: 30px;
                                                                                                                  }
                                                                                                                  .content-side-right {
                                                                                                                    padding: 0px;
                                                                                                          
                                                                                                                    padding-top: 20px;
                                                                                                                    align-items: center;
                                                                                                                  }
                                                                                                                  .image-side {
                                                                                                                    width: 100%;
                                                                                                                    padding-bottom: 30px;
                                                                                                                  }
                                                                                                                  .content-side-right h3 {
                                                                                                                    font-size: 20px;
                                                                                                                  }
                                                                                                                  .content-side h3 {
                                                                                                                    font-size: 20px;
                                                                                                                  }
                                                                                                                  .content-side span {
                                                                                                                    font-size: 15px;
                                                                                                                  }
                                                                                                                }
                                                                                                              </style>
                                                                                                          <div class="section-inner-banner">
                                                                                                          <figure class="section-side-img"><img data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="3000" data-aos-once="true" data-aos-offset="50" src="https://tossdown.site/images/4837c576b459f4d87a211488642f8718_1658135978.png" alt="" /></figure>
                                                                                                          <div class="section-inner-container">
                                                                                                          <div class="banner-inner-speration">
                                                                                                          <div class="banner-inner-left" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-offset="50">
                                                                                                          <div class="content-side"><span> Try Our New </span>
                                                                                                          <h3>Signature beef Burger</h3>
                                                                                                          <p>Made with 100% pure beef patty, cheese slice, lettuce, tomato, mayonnaise, ketchup, mustard, onions and pickles, all set on a sesame seed bun.</p>
                                                                                                          <h4 class="price-inner">CAD 10</h4>
                                                                                                          </div>
                                                                                                          <div class="image-side" data-aos="fade-down" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/28d38e38804352ec45e78ca71e8bfbf2_1657917239.png" alt="" /></div>
                                                                                                          </div>
                                                                                                          <div class="banner-inner-right" data-aos="fade-down" data-aos-mirror="true" data-aos-duration="1000" data-aos-once="true" data-aos-offset="50">
                                                                                                          <div class="content-side-right"><span> Try Our New </span>
                                                                                                          <h3>Get 10 % Discount on payday week</h3>
                                                                                                          <p>Challenging your spice level! The perfect spicy combination of chicken patty topped with freshly grown lettuce and our exceptional Hot N’ Crispy sauce, between a soft bun.</p>
                                                                                                          </div>
                                                                                                          <div class="image-side" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/707fbcd7e46508e2d23efb01088dbda6_1657917259.png" alt="" /></div>
                                                                                                          </div>
                                                                                                          </div>
                                                                                                          </div>
                                                                                                          </div>
                                                                                                                
                                                                                                                `
                                                                                                                },

                                                                                                                {
                                                                                                                    id:"HomeCustomSection32",
                                                                                                                    name:"HomeCustomSection 33",
                                                                                                                    height:"800px",
                                                                                                                    code:` 
                                                                                                                    <head>
                                                                                                                    <meta charset="utf-8" />
                                                                                                                    <meta
                                                                                                                      name="viewport"
                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                    />
                                                                                                                  
                                                                                                                    <link
                                                                                                                      rel="stylesheet"
                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                      crossorigin="anonymous"
                                                                                                                    />
                                                                                                                    <link
                                                                                                                      rel="stylesheet"
                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                      crossorigin="anonymous"
                                                                                                                    />
                                                                                                                  
                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                    <script
                                                                                                                      type="text/javascript"
                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                    ></script>
                                                                                                                    <script
                                                                                                                      type="text/javascript"
                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                    ></script>
                                                                                                                  
                                                                                                                    <link
                                                                                                                      rel="stylesheet"
                                                                                                                      type="text/css"
                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                    />
                                                                                                                    <link
                                                                                                                      rel="stylesheet"
                                                                                                                      type="text/css"
                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                    />
                                                                                                                  </head>
                                                                                                                     <style>
                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                    </style>
                                                                                                                    <style>
                                                                                                                    * {
                                                                                                                                      -web-kit-box-sizing: border-box;
                                                                                                                                      -moz-box-sizing: border-box;
                                                                                                                                      box-sizing: border-box;
                                                                                                                                      padding: 0px;
                                                                                                                                      margin: 0px;
                                                                                                                                      font-family: "Poppins", sans-serif;
                                                                                                                                    }
                                                                                                                                    html {
                                                                                                                                      scroll-behavior: smooth;
                                                                                                                                    }
                                                                                                                                    body {
                                                                                                                                      float: left;
                                                                                                                                      width: 100%;
                                                                                                                                      margin: 0px;
                                                                                                                                  background-color: #fff;
                                                                                                                                    }
                                                                                                                    .layers-right-side img {
                                                                                                                        position: absolute;
                                                                                                                        width: 137px;
                                                                                                                        right: 0px;
                                                                                                                        top: 15px;
                                                                                                                    }
                                                                                                                    .asto-man-layer img {
                                                                                                                        width: 110px;
                                                                                                                    }
                                                                                                                    .asto-man-layer {
                                                                                                                        position: absolute;
                                                                                                                        left: 60px;
                                                                                                                        bottom: 25px;
                                                                                                                    }
                                                                                                                    .layers-right-side {
                                                                                                                        position: relative;
                                                                                                                    }
                                                                                                                    .reverse-r {
                                                                                                                        border-top: 1px solid #ccc;
                                                                                                                        padding: 72px 0px;
                                                                                                                    }
                                                                                                                    .event-m-custom-section {
                                                                                                                        position: relative;
                                                                                                                        padding-top: 42px;
                                                                                                                    }
                                                                                                                    .custom-card-img-hold {
                                                                                                                        width: 825px;
                                                                                                                    }
                                                                                                                    .custom-card-img-hold img {
                                                                                                                        width: 100%;
                                                                                                                        height: 650px;
                                                                                                                        background-size: 100%;
                                                                                                                    }
                                                                                                                    .custom-card-content-hold {
                                                                                                                        background: #EB223A;
                                                                                                                        padding: 34px 34px;
                                                                                                                        display: inline-block;
                                                                                                                        position: absolute;
                                                                                                                        right: 0px;
                                                                                                                        bottom: 0px;
                                                                                                                        width: 445px;
                                                                                                                        background-image: url(https://tossdown.site/images/898d96fbb44218bb5ad2aa78b9d705ba_1656486794.png);
                                                                                                                    }
                                                                                                                    .custom-card-content-hold h2 {
                                                                                                                        font-family: 'Montserrat';
                                                                                                                        font-style: normal;
                                                                                                                        font-weight: 700 !important;
                                                                                                                        font-size: 44px;
                                                                                                                        line-height: 60px;
                                                                                                                        color: #FFFFFF;
                                                                                                                        padding: 60px 10px 0px 0px;
                                                                                                                        margin-bottom: 16px;
                                                                                                                    }
                                                                                                                    .custom-card-content-hold a {
                                                                                                                        display: block;
                                                                                                                        background: #F19F1F;
                                                                                                                        border-radius: 53px;
                                                                                                                        width: 164px;
                                                                                                                        text-align: center;
                                                                                                                        height: 48px;
                                                                                                                        line-height: 48px;
                                                                                                                        font-size: 15px;
                                                                                                                        color: #fff;
                                                                                                                        margin: 50px 0px;
                                                                                                                    }
                                                                                                                    </style>
                                                                                                                    <div class=" event-m-custom-section">
                                                                                                                    <div class="container">
                                                                                                                    <div class="row reverse-r">
                                                                                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                                    <div class="custom-card-img-hold" data-aos="fade-right" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/5824e8d35bd3c44df461d81f882a0c3e_1656487031.jpg" /></div>
                                                                                                                    <div class="custom-card-content-hold" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">
                                                                                                                    <h2 data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">Event Management? Loram ipsum Will Do it for You.</h2>
                                                                                                                    <img src="https://tossdown.site/images/1addf42242f1ade37eb76e569c03d39a_1656482570.png" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50" /> <a data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50">Book Now</a></div>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                    <div class="asto-man-layer" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/b707e2cd075f515a8a341b7f630ab26a_1658240124.png" /></div>
                                                                                                                    </div>
                                                                                                                    <!-- <div class="video-section-holds">
                                                                                                                    <div class="container">
                                                                                                                    <div class="row">
                                                                                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                                    <div class="main-headings-custom"><img src="https://tossdown.site/images/5e56928ca4d92c46a4e13826203b4b0d_1656415383.png" />
                                                                                                                    <h2>Daig’s Spice Tradition</h2>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                    <div class="videos-box-h"><video width="100%" height="auto" style="width: 100%; object-fit: scale-down; object-position: 50% 50%; background-color: #ffffff;" autoplay="autoplay" loop="loop" muted="" preload="auto">
                                                                                                                     <source src="https://tossdown.site/header_video_reduced%20(1)%20(1).mp4" type="video/mp4" /></video></div>
                                                                                                                    </div> -->
                                                                                                                    
                                                                                                                    `
                                                                                                                    },

                                                                                                                    {
                                                                                                                        id:"HomeCustomSection33",
                                                                                                                        name:"HomeCustomSection 34",
                                                                                                                        height:"800px",
                                                                                                                        code:` 
                                                                                                                        <head>
                                                                                                                        <meta charset="utf-8" />
                                                                                                                        <meta
                                                                                                                          name="viewport"
                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                        />
                                                                                                                      
                                                                                                                        <link
                                                                                                                          rel="stylesheet"
                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                          crossorigin="anonymous"
                                                                                                                        />
                                                                                                                        <link
                                                                                                                          rel="stylesheet"
                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                          crossorigin="anonymous"
                                                                                                                        />
                                                                                                                      
                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                        <script
                                                                                                                          type="text/javascript"
                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                        ></script>
                                                                                                                        <script
                                                                                                                          type="text/javascript"
                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                        ></script>
                                                                                                                      
                                                                                                                        <link
                                                                                                                          rel="stylesheet"
                                                                                                                          type="text/css"
                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                        />
                                                                                                                        <link
                                                                                                                          rel="stylesheet"
                                                                                                                          type="text/css"
                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                        />
                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                       
                                                                                                                      </head>
                                                                                                                         <style>
                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                        </style>
                                                                                                                        <style>
                                                                                                                        * {
                                                                                                                                          -web-kit-box-sizing: border-box;
                                                                                                                                          -moz-box-sizing: border-box;
                                                                                                                                          box-sizing: border-box;
                                                                                                                                          padding: 0px;
                                                                                                                                          margin: 0px;
                                                                                                                                          font-family: "Poppins", sans-serif;
                                                                                                                                        }
                                                                                                                                        html {
                                                                                                                                          scroll-behavior: smooth;
                                                                                                                                        }
                                                                                                                                        body {
                                                                                                                                          float: left;
                                                                                                                                          width: 100%;
                                                                                                                                          margin: 0px;
                                                                                                                                      background-color: #fff;
                                                                                                                                        }
                                                                                                                        .services-custom-sections {
                                                                                                                            background-color: #fbf8ed;
                                                                                                                            padding: 72px 0px 52px;
                                                                                                                            position: relative;
                                                                                                                        }
                                                                                                                        .upper-start-layer {
                                                                                                                            position: absolute;
                                                                                                                            right: 70px;
                                                                                                                            top: 50px;
                                                                                                                        }
                                                                                                                        .upper-start-layer img {
                                                                                                                            width: 135px;
                                                                                                                        }
                                                                                                                        .bottom-start-layer img {
                                                                                                                            width: 110px;
                                                                                                                        }
                                                                                                                        .bottom-start-layer {
                                                                                                                            position: absolute;
                                                                                                                            bottom: 30px;
                                                                                                                            left: 65px;
                                                                                                                        }
                                                                                                                        .services-card-box p {
                                                                                                                            font-family: 'Montserrat';
                                                                                                                            font-style: normal;
                                                                                                                            font-weight: 400;
                                                                                                                            font-size: 14px;
                                                                                                                            line-height: 21px;
                                                                                                                            text-align: center;
                                                                                                                            color: rgba(0, 0, 0, 0.7);
                                                                                                                            padding: 0px 25px;
                                                                                                                            margin-bottom: 24px;
                                                                                                                            height: 63px;
                                                                                                                        }
                                                                                                                        .services-card-box h4 {
                                                                                                                            font-family: 'Montserrat';
                                                                                                                            font-style: normal;
                                                                                                                            font-weight: 700;
                                                                                                                            font-size: 16px;
                                                                                                                            line-height: 20px;
                                                                                                                            color: #000000;
                                                                                                                            margin: 16px 0px 12px 0px;
                                                                                                                        }
                                                                                                                        .services-card-box {
                                                                                                                            background: #FFFFFF;
                                                                                                                            box-shadow: 0px 3px 4px rgb(0 0 0 / 8%);
                                                                                                                            border-radius: 8px;
                                                                                                                            text-align: center;
                                                                                                                            padding: 8px;
                                                                                                                            margin-bottom: 30px;
                                                                                                                        }
                                                                                                                        .services-card-box a img {
                                                                                                                            width: 100%;
                                                                                                                        }
                                                                                                                        </style>
                                                                                                                        <div class="services-custom-sections">
                                                                                                                        <div class="upper-start-layer" data-aos="fade-left" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/f7faa8e13cde420eb9716df744f3ff70_1658239738.png" /></div>
                                                                                                                        <div class="container">
                                                                                                                        <div class="row">
                                                                                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                                        <div class="main-headings-custom" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/5e56928ca4d92c46a4e13826203b4b0d_1656415383.png" />
                                                                                                                        <h2>Lorem Ipsum</h2>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <div class="row">
                                                                                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1200" data-aos-once="true" data-aos-offset="50">
                                                                                                                        <div class="services-card-box"><a href="/shop"> <img src="https://tossdown.site/images/ee5d4d5a8ee8e971dad2beac4e5b6f0c_1656491960.png" /></a>
                                                                                                                        <h4>Variety Menu</h4>
                                                                                                                        <p>Lorem Ipsum tasteful menus can be found in different dishes according to your preferences.</p>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1200" data-aos-once="true" data-aos-offset="50">
                                                                                                                        <div class="services-card-box"><a href="/shop?cat_filter[]=15121"> <img src="https://tossdown.site/images/f17340892edce4d51c21d9fa2b03cd5c_1656492077.png" /></a>
                                                                                                                        <h4>Catering</h4>
                                                                                                                        <p>Lorem Ipsum provides good food services along with waiters’ crew and all sort of catering arrangement.</p>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1200" data-aos-once="true" data-aos-offset="50">
                                                                                                                        <div class="services-card-box"><a href="/shop?cat_filter[]=15155"> <img src="https://tossdown.site/images/4662276bd48cf9f22995a71d2ebee086_1656492130.png" /> </a>
                                                                                                                        <h4>Weeding Menu</h4>
                                                                                                                        <p>Lorem Ipsum set up your events and ceremonies by providing the services of tents, crockery, and many more.</p>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1200" data-aos-once="true" data-aos-offset="50">
                                                                                                                        <div class="services-card-box"><a href="/events"> <img src="https://tossdown.site/images/8d33ea2bdf111fb342afc58f55c55ccc_1656492561.png" /> </a>
                                                                                                                        <h4>Weddings &amp; Events</h4>
                                                                                                                        <p>For Weddings and Events, we provide the service of ideal arrangments and food refreshments.</p>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1200" data-aos-once="true" data-aos-offset="50">
                                                                                                                        <div class="services-card-box"><a href="/shop?cat_filter[]=15126"> <img src="https://tossdown.site/images/7c95568be2b07bd12d8fb51792198ebb_1656492574.png" /></a>
                                                                                                                        <h4>Lorem Ipsum</h4>
                                                                                                                        <p>We present you the easiest way to offer your charity in form of Lorem Ipsum</p>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="1200" data-aos-once="true" data-aos-offset="50">
                                                                                                                        <div class="services-card-box"><a href="/shop?cat_filter[]=15127"> <img src="https://tossdown.site/images/03c14c64037757c6d281e6772e72c9a7_1656492591.png" /></a>
                                                                                                                        <h4>Lorem Ipsum</h4>
                                                                                                                        <p>You can give a gift to your loved ones on their special occasions in the form of arranging</p>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                        <div class="bottom-start-layer" data-aos="fade-right" data-aos-mirror="true" data-aos-duration="2000" data-aos-once="true" data-aos-offset="50"><img src="https://tossdown.site/images/4bcad6a0c7f1922443abd20662b9e39c_1658240636.png" /></div>
                                                                                                                        </div>
                                                                                                                        
                                                                                                                        `
                                                                                                                        },


                                                                                                                        {
                                                                                                                            id:"HomeCustomSection34",
                                                                                                                            name:"HomeCustomSection 35",
                                                                                                                            height:"800px",
                                                                                                                            code:` 
                                                                                                                            <head>
                                                                                                                            <meta charset="utf-8" />
                                                                                                                            <meta
                                                                                                                              name="viewport"
                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                            />
                                                                                                                          
                                                                                                                            <link
                                                                                                                              rel="stylesheet"
                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                              crossorigin="anonymous"
                                                                                                                            />
                                                                                                                            <link
                                                                                                                              rel="stylesheet"
                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                              crossorigin="anonymous"
                                                                                                                            />
                                                                                                                          
                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                            <script
                                                                                                                              type="text/javascript"
                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                            ></script>
                                                                                                                            <script
                                                                                                                              type="text/javascript"
                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                            ></script>
                                                                                                                          
                                                                                                                            <link
                                                                                                                              rel="stylesheet"
                                                                                                                              type="text/css"
                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                            />
                                                                                                                            <link
                                                                                                                              rel="stylesheet"
                                                                                                                              type="text/css"
                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                            />
                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                           
                                                                                                                          </head>
                                                                                                                             <style>
                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                            </style>
                                                                                                                            <style>
 * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.home_custom_section_8_main {
    display: block;
    width: 100%;
    padding: 72px 15px 0px 15px;
    float: left;
    margin-bottom: 34px;
}

.custom_container{
max-width:1140px;
margin:0 auto;
}
.home_custom_section_8_logo {
    padding-bottom: 32px;
}
.home_custom_section_8{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
    grid-template-rows: auto;
    
    position: relative;
    z-index: 1;
    width: 100%;
    align-items: center;
height:100%;
gap: 28px;
}
.home_custom_section_8 figure {
    margin-bottom: 0px;
    width: 100%;
    height: 550px;
   background-image: url('https://tossdown.site/images/83969cb07d272e1d99ef24b0a60ad84d_1656330313.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.home_custom_section_8 figure img{
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
}
.home_custom_section_8_body {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: start;
    height: 100%;
    background-color: #E4E4D8;
    border-radius: 15px;
    padding: 96px 70px;
}
.home_custom_section_8_heading {
    display: flex;
    width: 100%;
    flex-direction: column;

 
}
.home_custom_section_8_heading small {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #222222;

    Font-size: 14px;
    Line-height: 18px;
padding-bottom: 10px;
}
.home_custom_section_8_heading h3 {
    font-style: normal;
    font-weight: 900;
    color: #222222;
    Font-size: 34px;
    Line-height: 53px;
    letter-spacing: 1px;
}
.heading-list{
font-size: 16px;
    list-style: disc;
margin-left:20px;
margin-top: 27px;
}
.home_custom_section_8_body p{

font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 23px;
color: rgba(0, 0, 0, 0.6);
    padding-bottom: 24px;
}
.home_custom_section_8_body .more_detail_btn{

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
color: #000000;
    width: 174px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
background: #F5C628;
border-radius: 3px;
    position: relative;
}
.sec-fig{
border-radius: 15px;
}

@media(max-width:1110px){
.home_custom_section_8_heading h3 {

    font-size: 39px;

}
}

@media(max-width:1024px){
.home_custom_section_8_body {
    padding: 0px 50px;
    gap: 18px 0px;
}
.home_custom_section_8_heading {
    gap: 5px 0px;
}
.home_custom_section_8 figure {
    height: 450px;
}
.home_custom_section_8_heading h3 {
    font-size: 40px;    
}
.home_custom_section_8_body p {
    padding-bottom: 0px;
}
}
@media(max-width:767px){
.home_custom_section_8_learn_btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.home_custom_section_8_body {
    justify-content: center;
    text-align: center;
padding: 0px 25px 40px 25px;
    margin-bottom: 12px;
}
.home_custom_section_8{
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.home_custom_section_8 figure {
    height: 300px;
background-position: center;
    background-image: url('https://tossdown.site/images/83969cb07d272e1d99ef24b0a60ad84d_1656330313.jpg');
}
.home_custom_section_8_body {
    padding:0px 25px 40px 25px;
}

}
@media(max-width:540px){
.home_custom_section_8_heading h3 {
    font-size: 36px;
    line-height: 46px;
}
.home_custom_section_8_body {
    padding: 30px 0px 40px 0px;
}
}
@media(max-width:450px){
.home_custom_section_8 figure {
    height: 200px;
background-position: center;
    background-image: url('https://tossdown.site/images/83969cb07d272e1d99ef24b0a60ad84d_1656330313.jpg');
}
.heading-list{
    font-size: 16px;
    width: 202px;
    list-style: disc;
    margin-left: 61px;
    margin-top: 27px;
}
}
}
</style>
<div class="home_custom_section_8_main">
<div class="custom_container">
<div class="home_custom_section_8">
<div class="home_custom_section_8_body">
<div class="home_custom_section_8_heading">
<h3 data-aos-delay="400" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">BENEFITS OF REFINISHED FURNITURE</h3>
</div>
<ul class="heading-list">
<li>More sustainable</li>
<li>Higher quality</li>
<li>Unique one-of-a-kind pieces</li>
<li>More affordable</li>
<li>Supports a local small<br />business</li>
</ul>
</div>
<figure class="sec-fig" data-aos-delay="200" data-aos-offset="120" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true"></figure>
</div>
</div>
</div>
                                                                                                                            
                                                                                                                            `
                                                                                                                            },
                                                                                                                            {
                                                                                                                                id:"HomeCustomSection35",
                                                                                                                                name:"HomeCustomSection 36",
                                                                                                                                height:"800px",
                                                                                                                                code:` 
                                                                                                                                <head>
                                                                                                                                <meta charset="utf-8" />
                                                                                                                                <meta
                                                                                                                                  name="viewport"
                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                />
                                                                                                                              
                                                                                                                                <link
                                                                                                                                  rel="stylesheet"
                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                  crossorigin="anonymous"
                                                                                                                                />
                                                                                                                                <link
                                                                                                                                  rel="stylesheet"
                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                  crossorigin="anonymous"
                                                                                                                                />
                                                                                                                              
                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                <script
                                                                                                                                  type="text/javascript"
                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                ></script>
                                                                                                                                <script
                                                                                                                                  type="text/javascript"
                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                ></script>
                                                                                                                              
                                                                                                                                <link
                                                                                                                                  rel="stylesheet"
                                                                                                                                  type="text/css"
                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                />
                                                                                                                                <link
                                                                                                                                  rel="stylesheet"
                                                                                                                                  type="text/css"
                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                />
                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                               
                                                                                                                              </head>
                                                                                                                                 <style>
                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                </style>
                                                                                                                                <style>
                                                                                                                                * {
                                                                                                                                                 -web-kit-box-sizing: border-box;
                                                                                                                                                 -moz-box-sizing: border-box;
                                                                                                                                                 box-sizing: border-box;
                                                                                                                                                 padding: 0px;
                                                                                                                                                 margin: 0px;
                                                                                                                                                 font-family: "Poppins", sans-serif;
                                                                                                                                               }
                                                                                                                                               html {
                                                                                                                                                 scroll-behavior: smooth;
                                                                                                                                               }
                                                                                                                                               body {
                                                                                                                                                 float: left;
                                                                                                                                                 width: 100%;
                                                                                                                                                 margin: 0px;
                                                                                                                                             background-color: #fff;
                                                                                                                                               }
                                                                                                                               .desktop-layers-hold {
                                                                                                                                   position: relative;
                                                                                                                               }
                                                                                                                               .bg-color-hold {
                                                                                                                                   padding: 30px 0px 0px;
                                                                                                                                   background: linear-gradient(180deg, #3FA798 56.77%, #93EDC7 100%);
                                                                                                                               height: 690px;
                                                                                                                               }
                                                                                                                               .content-hold {
                                                                                                                                   text-align: center;
                                                                                                                               }
                                                                                                                               .content-hold p {
                                                                                                                                   font-family: 'Montserrat';
                                                                                                                                   font-style: normal;
                                                                                                                                   font-weight: 500;
                                                                                                                                   font-size: 14px;
                                                                                                                                   line-height: 24px;
                                                                                                                                   display: flex;
                                                                                                                                   align-items: center;
                                                                                                                                   text-align: center;
                                                                                                                                   color: rgba(0, 0, 0, 0.5);
                                                                                                                                   margin-bottom: 48px;
                                                                                                                               }
                                                                                                                               .content-hold h3 {
                                                                                                                                   display: flex;
                                                                                                                                   align-items: center;
                                                                                                                                   margin-bottom: 40px !important;
                                                                                                                                   font-family: 'Epilogue';
                                                                                                                                   font-style: normal;
                                                                                                                                   font-weight: 900;
                                                                                                                                   font-size: 52px;
                                                                                                                                   line-height: 62px;
                                                                                                                                   text-align: center;
                                                                                                                                   color: #FFFFFF;
                                                                                                                                   width: 70%;
                                                                                                                                   margin: auto;
                                                                                                                                   letter-spacing: 0.5px;
                                                                                                                               }
                                                                                                                               .content-hold a {
                                                                                                                                   background: #F6BA17;
                                                                                                                                   border-radius: 53px;
                                                                                                                                   font-family: 'Manrope';
                                                                                                                                   font-style: normal;
                                                                                                                                   font-weight: 700;
                                                                                                                                   font-size: 13px;
                                                                                                                                   line-height: 18px;
                                                                                                                                   text-transform: uppercase;
                                                                                                                                   color: #000000;
                                                                                                                                   padding: 14px 40px;
                                                                                                                               }
                                                                                                                               </style>
                                                                                                                               <div class="bg-color-hold">
                                                                                                                               <div class="container">
                                                                                                                               <div class="row">
                                                                                                                               <div class="col-sm-12">
                                                                                                                               <div class="content-hold" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                               <h3>Pakistan’s First On-Demand Waterless Car Wash App</h3>
                                                                                                                               <a >Book Now <i class="fa-solid fa-arrow-right" style="margin-left: 10px;"></i></a>
                                                                                                                               <div class="desktop-layers-hold"><img class="left-layer " data-aos="fade-right" data-aos-duration="900" data-aos-delay="200" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" src="https://tossdown.site/images/ae9e86047bfa05801bc13475b051a3d1_1653894072.png" div="" style="position: absolute; left: -50px; top: 50px;" /> <img data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" src="https://tossdown.site/images/81be0e4b2c1788b6e73be3e7a5ea563a_1653893351.png" div="" class="aos-init aos-animate" style="top: 30px; margin-top: 95px; z-index: 1111;" /> <img class="right-layer" data-aos="fade-left" data-aos-duration="900" data-aos-delay="200" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" src="https://tossdown.site/images/9f9ae653923fa373e6bdef5927f2995d_1653893480.png" div="" style="position: absolute; right: 0px; top: -20px;" /></div>
                                                                                                                               </div>
                                                                                                                               <div class="mob-resp-show" data-aos="fade-right" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"><img class="small-resp-size" src="https://tossdown.site/images/89ec093cc18d0cba7d004f8fcd517d96_1653478579.png" /> <img src="https://tossdown.site/images/7f0f55610c623d2afd172e0f1f1f6f30_1653478432.png" /></div>
                                                                                                                               </div>
                                                                                                                               </div>
                                                                                                                               </div>
                                                                                                                               </div>
                                                                                                                                
                                                                                                                                `
                                                                                                                                },


                                                                                                                                {
                                                                                                                                    id:"HomeCustomSection36",
                                                                                                                                    name:"HomeCustomSection 37",
                                                                                                                                    height:"800px",
                                                                                                                                    code:` 
                                                                                                                                    <head>
                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                    <meta
                                                                                                                                      name="viewport"
                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                    />
                                                                                                                                  
                                                                                                                                    <link
                                                                                                                                      rel="stylesheet"
                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                      crossorigin="anonymous"
                                                                                                                                    />
                                                                                                                                    <link
                                                                                                                                      rel="stylesheet"
                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                      crossorigin="anonymous"
                                                                                                                                    />
                                                                                                                                  
                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                    <script
                                                                                                                                      type="text/javascript"
                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                    ></script>
                                                                                                                                    <script
                                                                                                                                      type="text/javascript"
                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                    ></script>
                                                                                                                                  
                                                                                                                                    <link
                                                                                                                                      rel="stylesheet"
                                                                                                                                      type="text/css"
                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                    />
                                                                                                                                    <link
                                                                                                                                      rel="stylesheet"
                                                                                                                                      type="text/css"
                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                    />
                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                   
                                                                                                                                  </head>
                                                                                                                                     <style>
                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                    </style>
                                                                                                                                    <style>
                                                                                                                                    * {
                                                                                                                                                     -web-kit-box-sizing: border-box;
                                                                                                                                                     -moz-box-sizing: border-box;
                                                                                                                                                     box-sizing: border-box;
                                                                                                                                                     padding: 0px;
                                                                                                                                                     margin: 0px;
                                                                                                                                                     font-family: "Poppins", sans-serif;
                                                                                                                                                   }
                                                                                                                                                   html {
                                                                                                                                                     scroll-behavior: smooth;
                                                                                                                                                   }
                                                                                                                                                   body {
                                                                                                                                                     float: left;
                                                                                                                                                     width: 100%;
                                                                                                                                                     margin: 0px;
                                                                                                                                                 background-color: #fff;
                                                                                                                                                   }
                                                                                                                                   .custom-cards-boxes-hold {
                                                                                                                                       display: flex;
                                                                                                                                       justify-content: center;
                                                                                                                                   }
                                                                                                                                   .custom-section-second.pd-top-bottom-add {
                                                                                                                                       padding: 150px 0px 80px;
                                                                                                                                   }
                                                                                                                                   .custom-content-hold {
                                                                                                                                       width: 90%;
                                                                                                                                   }
                                                                                                                                   .custom-content-hold h4 {
                                                                                                                                       font-family: 'Epilogue';
                                                                                                                                       font-style: normal;
                                                                                                                                       font-weight: 800;
                                                                                                                                       font-size: 40px;
                                                                                                                                       line-height: 50px;
                                                                                                                                       color: #000000;
                                                                                                                                       margin-bottom: 10px;
                                                                                                                                   }
                                                                                                                                   .custom-content-hold p {
                                                                                                                                       font-family: 'Manrope';
                                                                                                                                       font-style: normal;
                                                                                                                                       font-weight: 600;
                                                                                                                                       font-size: 14px;
                                                                                                                                       line-height: 24px;
                                                                                                                                       color: #71717A;
                                                                                                                                   }
                                                                                                                                   .custom-cards-boxes-hold {
                                                                                                                                       display: flex;
                                                                                                                                       justify-content: center;
                                                                                                                                       position: relative;
                                                                                                                                   }
                                                                                                                                   .custom-cards {
                                                                                                                                       background: #FFFFFF;
                                                                                                                                       box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
                                                                                                                                       border-radius: 8px;
                                                                                                                                       display: block;
                                                                                                                                       width: 100%;
                                                                                                                                       margin: 0px 15px;
                                                                                                                                       text-align: center;
                                                                                                                                       padding: 35px;
                                                                                                                                   }
                                                                                                                                   .custom-cards img {
                                                                                                                                       width: 56px;
                                                                                                                                       height: 56px;
                                                                                                                                       margin-bottom: 24px;
                                                                                                                                   }
                                                                                                                                   .custom-cards span {
                                                                                                                                       font-family: 'Epilogue';
                                                                                                                                       font-style: normal;
                                                                                                                                       font-weight: 700;
                                                                                                                                       font-size: 18px;
                                                                                                                                       line-height: 18px;
                                                                                                                                       color: #000000;
                                                                                                                                       display: block;
                                                                                                                                       margin-bottom: 8px;
                                                                                                                                   }
                                                                                                                                   .custom-cards p {
                                                                                                                                       font-family: 'Manrope';
                                                                                                                                       font-style: normal;
                                                                                                                                       font-weight: 600;
                                                                                                                                       font-size: 14px;
                                                                                                                                       line-height: 20px;
                                                                                                                                       text-align: center;
                                                                                                                                       color: #71717A;
                                                                                                                                   }
                                                                                                                                   
                                                                                                                                   </style>
                                                                                                                                   <div class="custom-section-second pd-top-bottom-add" id="what-we-offer">
                                                                                                                                   <div class="container">
                                                                                                                                   <div class="row">
                                                                                                                                   <div class="col-lg-6 col-md-5 col-sm-12" style="margin: auto;" data-aos="fade-right" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                   <div class="custom-content-hold">
                                                                                                                                   <h4>A Super Glossy Mobile Car Wash</h4>
                                                                                                                                   <p>A conventional car wash on service station requires 90 liters of water and 2-3 hours including long queues. We at Loram save precious time and environment.</p>
                                                                                                                                   </div>
                                                                                                                                   </div>
                                                                                                                                   <div class="col-lg-6 col-md-7 col-sm-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                   <div class="custom-cards-boxes-hold">
                                                                                                                                   <div class="custom-cards"><img src="https://tossdown.site/images/a18295d97e83532c425b41350e5b98a6_1653030513.png" /> <span>25 mins</span>
                                                                                                                                   <p>*Time required</p>
                                                                                                                                   <p>*depends on car size</p>
                                                                                                                                   </div>
                                                                                                                                   <div class="custom-cards"><img src="https://tossdown.site/images/fb0eb61c8e99909e0407c9df8b9c1734_1653030541.png" /> <span>250ml</span>
                                                                                                                                   <p>Water used</p>
                                                                                                                                   </div>
                                                                                                                                   </div>
                                                                                                                                   </div>
                                                                                                                                   </div>
                                                                                                                                   </div>
                                                                                                                                   </div>
                                                                                                                                    
                                                                                                                                    `
                                                                                                                                    },

                                                                                                                                    {
                                                                                                                                        id:"HomeCustomSection37",
                                                                                                                                        name:"HomeCustomSection 38",
                                                                                                                                        height:"800px",
                                                                                                                                        code:` 
                                                                                                                                        <head>
                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                        <meta
                                                                                                                                          name="viewport"
                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                        />
                                                                                                                                      
                                                                                                                                        <link
                                                                                                                                          rel="stylesheet"
                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                          crossorigin="anonymous"
                                                                                                                                        />
                                                                                                                                        <link
                                                                                                                                          rel="stylesheet"
                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                          crossorigin="anonymous"
                                                                                                                                        />
                                                                                                                                      
                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                        <script
                                                                                                                                          type="text/javascript"
                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                        ></script>
                                                                                                                                        <script
                                                                                                                                          type="text/javascript"
                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                        ></script>
                                                                                                                                      
                                                                                                                                        <link
                                                                                                                                          rel="stylesheet"
                                                                                                                                          type="text/css"
                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                        />
                                                                                                                                        <link
                                                                                                                                          rel="stylesheet"
                                                                                                                                          type="text/css"
                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                        />
                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                       
                                                                                                                                      </head>
                                                                                                                                         <style>
                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                        </style>
                                                                                                                                        <style>
                                                                                                                                        * {
                                                                                                                                                         -web-kit-box-sizing: border-box;
                                                                                                                                                         -moz-box-sizing: border-box;
                                                                                                                                                         box-sizing: border-box;
                                                                                                                                                         padding: 0px;
                                                                                                                                                         margin: 0px;
                                                                                                                                                         font-family: "Poppins", sans-serif;
                                                                                                                                                       }
                                                                                                                                                       html {
                                                                                                                                                         scroll-behavior: smooth;
                                                                                                                                                       }
                                                                                                                                                       body {
                                                                                                                                                         float: left;
                                                                                                                                                         width: 100%;
                                                                                                                                                         margin: 0px;
                                                                                                                                                     background-color: #fff;
                                                                                                                                                       }
                                                                                                                                       .custom-bg-colors-part-three {
                                                                                                                                           background-color: #3E4B5D;
                                                                                                                                           position: relative;
                                                                                                                                           z-index: 1;
                                                                                                                                           overflow: hidden;
                                                                                                                                           padding-bottom: 90px;
                                                                                                                                       }
                                                                                                                                       
                                                                                                                                       .bg-color-radius-box {
                                                                                                                                           position: absolute;
                                                                                                                                           width: 850px;
                                                                                                                                           height: 988px;
                                                                                                                                           z-index: -1;
                                                                                                                                           object-fit: contain;
                                                                                                                                           top: -220px;
                                                                                                                                           right: -47px;
                                                                                                                                           background-image: url(https://tossdown.site/images/561583d9482bdf21f5514aa609b711f1_1653476952.png);
                                                                                                                                       }
                                                                                                                                       .content-box-first {
                                                                                                                                           display: flex;
                                                                                                                                           margin-bottom: 85px;
                                                                                                                                       }
                                                                                                                                       .content-box-first .inner-contnet {
                                                                                                                                           text-align: right;
                                                                                                                                           margin: 20px 32px 0px;
                                                                                                                                       }
                                                                                                                                       .content-box-first .inner-contnet span {
                                                                                                                                           font-family: 'Epilogue';
                                                                                                                                           font-style: normal;
                                                                                                                                           font-weight: 600;
                                                                                                                                           font-size: 24px;
                                                                                                                                           line-height: 25px;
                                                                                                                                           color: #FFFFFF;
                                                                                                                                       }
                                                                                                                                       .content-box-first .inner-contnet p {
                                                                                                                                           font-family: 'Manrope';
                                                                                                                                           font-style: normal;
                                                                                                                                           font-weight: 600;
                                                                                                                                           font-size: 14px;
                                                                                                                                           line-height: 19px;
                                                                                                                                           text-align: right;
                                                                                                                                           color: rgba(255, 255, 255, 0.6);
                                                                                                                                       }
                                                                                                                                       .custom-section-holds {
                                                                                                                                           overflow: hidden;
                                                                                                                                           clip-path: ellipse(150% 100% at 48.5% 100%);
                                                                                                                                       }
                                                                                                                                       .custom-right-side-color-bg {
                                                                                                                                           position: relative;
                                                                                                                                       }
                                                                                                                                       .custom-right-side-color-bg img {
                                                                                                                                           position: absolute;
                                                                                                                                           top: -45px;
                                                                                                                                       }
                                                                                                                                       .custom-left-side-content {
                                                                                                                                           position: relative;
                                                                                                                                           display: block;
                                                                                                                                           height: 730px;
                                                                                                                                       }
                                                                                                                                       .custom-left-side-content .content-box-first.first-pd-fund {
                                                                                                                                           position: absolute;
                                                                                                                                           right: -140px;
                                                                                                                                           top: 80px;
                                                                                                                                           z-index: 1;
                                                                                                                                       }
                                                                                                                                       .custom-left-side-content .content-box-first.second-pd-fund {
                                                                                                                                           position: absolute;
                                                                                                                                           right: -85px;
                                                                                                                                           top: 255px;
                                                                                                                                           z-index: 1;
                                                                                                                                       }
                                                                                                                                       .custom-left-side-content .content-box-first.third-pd-fund {
                                                                                                                                           position: absolute;
                                                                                                                                           right: -125px;
                                                                                                                                           top: 440px;
                                                                                                                                           z-index: 1;
                                                                                                                                       }
                                                                                                                                       .custom-left-side-content .content-box-first.forth-pd-fund {
                                                                                                                                           position: absolute;
                                                                                                                                           right: -250px;
                                                                                                                                           top: 575px;
                                                                                                                                           z-index: 1;
                                                                                                                                       }
                                                                                                                                       
                                                                                                                                       
                                                                                                                                       </style>
                                                                                                                                       <div class="custom-section-holds" id="how-it-works">
                                                                                                                                       <div class="custom-bg-colors-part-three">
                                                                                                                                       <div class="container">
                                                                                                                                       <div class="row revers-row-mob">
                                                                                                                                       <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                                                                                                       <div class="custom-left-side-content">
                                                                                                                                       <div class="content-box-first first-pd-fund" data-aos="fade-right" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                       <div class="inner-contnet"><span>Download App</span>
                                                                                                                                       <p>Download the app, sign up &amp; register your vehicle.</p>
                                                                                                                                       </div>
                                                                                                                                       <div class="box-img-hold"><img src="https://tossdown.site/images/6614e5fe7e91ca14d8dc200e6774edc7_1653036508.png" style="width: 72px; height: 81px;" /></div>
                                                                                                                                       </div>
                                                                                                                                       <div class="content-box-first second-pd-fund" data-aos="fade-right" data-aos-duration="900" data-aos-delay="100" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                       <div class="inner-contnet"><span>Schedule wash</span>
                                                                                                                                       <p>Choose a suitable time, date and location.</p>
                                                                                                                                       </div>
                                                                                                                                       <div class="box-img-hold"><img src="https://tossdown.site/images/8847dd88a23eea97fc786360ffb036b4_1653036655.png" style="width: 72px; height: 81px;" /></div>
                                                                                                                                       </div>
                                                                                                                                       <div class="content-box-first third-pd-fund" data-aos="fade-right" data-aos-duration="900" data-aos-delay="150" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                       <div class="inner-contnet"><span>Secure Payment</span>
                                                                                                                                       <p>Make payment (card/cash).</p>
                                                                                                                                       </div>
                                                                                                                                       <div class="box-img-hold"><img src="https://tossdown.site/images/c0117262f0d3db755cf364369755797a_1653036669.png" style="width: 72px; height: 81px;" /></div>
                                                                                                                                       </div>
                                                                                                                                       <div class="content-box-first forth-pd-fund" data-aos="fade-right" data-aos-duration="900" data-aos-delay="200" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                       <div class="inner-contnet"><span>Waterless wash done</span>
                                                                                                                                       <p>Get the waterless car wash done in 25 minutes at your place​.</p>
                                                                                                                                       </div>
                                                                                                                                       <div class="box-img-hold"><img src="https://tossdown.site/images/ddf32c3b83118000c0ea855b0e28ff88_1653036683.png" style="width: 72px; height: 81px;" /></div>
                                                                                                                                       </div>
                                                                                                                                       </div>
                                                                                                                                       </div>
                                                                                                                                       <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                                                                                                                       <div class="custom-right-side-color-bg" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"><span class="inner-content-cawa">How ipsum Works?</span> <img src="https://tossdown.site/images/e158d78fd10da5d1d06f3b52eb8b5d86_1653036869.png" /> <img class="show-mob-only" src="https://tossdown.site/images/3984ab99883cd7622aef7ca009a4a7bf_1653485040.png" /></div>
                                                                                                                                       </div>
                                                                                                                                       <div class="bg-color-radius-box" data-aos="fade-left" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"></div>
                                                                                                                                       </div>
                                                                                                                                       
                                                                                                                                       </div>
                                                                                                                                       </div>
                                                                                                                                       </div>                                                                                                                                        `
                                                                                                                                        },
                                                                                                                                        {
                                                                                                                                            id:"HomeCustomSection38",
                                                                                                                                            name:"HomeCustomSection 39",
                                                                                                                                            height:"800px",
                                                                                                                                            code:` 
                                                                                                                                            <head>
                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                            <meta
                                                                                                                                              name="viewport"
                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                            />
                                                                                                                                          
                                                                                                                                            <link
                                                                                                                                              rel="stylesheet"
                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                              crossorigin="anonymous"
                                                                                                                                            />
                                                                                                                                            <link
                                                                                                                                              rel="stylesheet"
                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                              crossorigin="anonymous"
                                                                                                                                            />
                                                                                                                                          
                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                            <script
                                                                                                                                              type="text/javascript"
                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                            ></script>
                                                                                                                                            <script
                                                                                                                                              type="text/javascript"
                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                            ></script>
                                                                                                                                          
                                                                                                                                            <link
                                                                                                                                              rel="stylesheet"
                                                                                                                                              type="text/css"
                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                            />
                                                                                                                                            <link
                                                                                                                                              rel="stylesheet"
                                                                                                                                              type="text/css"
                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                            />
                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                           
                                                                                                                                          </head>
                                                                                                                                             <style>
                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                            </style>
                                                                                                                                            <style>
                                                                                                                                            * {
                                                                                                                                                             -web-kit-box-sizing: border-box;
                                                                                                                                                             -moz-box-sizing: border-box;
                                                                                                                                                             box-sizing: border-box;
                                                                                                                                                             padding: 0px;
                                                                                                                                                             margin: 0px;
                                                                                                                                                             font-family: "Poppins", sans-serif;
                                                                                                                                                           }
                                                                                                                                                           html {
                                                                                                                                                             scroll-behavior: smooth;
                                                                                                                                                           }
                                                                                                                                                           body {
                                                                                                                                                             float: left;
                                                                                                                                                             width: 100%;
                                                                                                                                                             margin: 0px;
                                                                                                                                                         background-color: #fff;
                                                                                                                                                           }
                                                                                                                                           .home_section_one {
                                                                                                                                               display: block;
                                                                                                                                               width: 100%;
                                                                                                                                               margin-bottom: 30px;
                                                                                                                                               padding: 0px 15px;
                                                                                                                                           float:left;
                                                                                                                                           }
                                                                                                                                           .custom_container {
                                                                                                                                               max-width: 1140px;
                                                                                                                                               margin: 0 auto;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box{
                                                                                                                                           background: #F0F0F0;
                                                                                                                                           display: block;
                                                                                                                                               width: 100%;
                                                                                                                                               padding: 44px 10px 32px 10px;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_data{
                                                                                                                                               display: flex;
                                                                                                                                               flex-direction: column;
                                                                                                                                               justify-content: center;
                                                                                                                                               align-items: center;
                                                                                                                                               text-align: center;
                                                                                                                                           max-width:520px;
                                                                                                                                           margin:0 auto;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_des {
                                                                                                                                               display: flex;
                                                                                                                                               flex-direction: column;
                                                                                                                                               width: 100%;
                                                                                                                                           padding-bottom:15px;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_des small {
                                                                                                                                               font-family: 'Epilogue';
                                                                                                                                               font-style: normal;
                                                                                                                                               font-weight: 600;
                                                                                                                                               font-size: 14px;
                                                                                                                                               line-height: 14px;
                                                                                                                                               text-transform: uppercase;
                                                                                                                                               color: #000000;
                                                                                                                                           padding-bottom:12px;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_des h2{
                                                                                                                                           font-family: 'Epilogue';
                                                                                                                                           font-style: normal;
                                                                                                                                           font-weight: 800 !important;
                                                                                                                                           font-size: 28px;
                                                                                                                                           line-height: 29px;
                                                                                                                                           margin:0px;
                                                                                                                                           text-transform: uppercase;
                                                                                                                                           color: #000000;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_imgs {
                                                                                                                                               display: flex;
                                                                                                                                               align-items: center;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_imgs figure{
                                                                                                                                              width: 100%;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_imgs figure img{
                                                                                                                                              width: 100%;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_btn {
                                                                                                                                               display: flex;
                                                                                                                                               align-items: center;
                                                                                                                                               justify-content: center;
                                                                                                                                               width: 100%;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_btn a {
                                                                                                                                               background: #000000;
                                                                                                                                               border-radius: 6px;
                                                                                                                                               width: 136px;
                                                                                                                                               height: 44px;
                                                                                                                                               font-family: 'Epilogue';
                                                                                                                                               font-style: normal;
                                                                                                                                               font-weight: 600;
                                                                                                                                               font-size: 13px;
                                                                                                                                               line-height: 13px;
                                                                                                                                               text-transform: uppercase;
                                                                                                                                               display: flex;
                                                                                                                                               align-items: center;
                                                                                                                                               justify-content: center;
                                                                                                                                               color: #fff !important;
                                                                                                                                           }
                                                                                                                                           .home_section_one_box_btn a:hover{
                                                                                                                                            color: #fff !important;
                                                                                                                                           }
                                                                                                                                           @media(max-width:540px){
                                                                                                                                           .home_section_one_box_des small {
                                                                                                                                               font-size: 12px;
                                                                                                                                               line-height: 12px;
                                                                                                                                               padding-bottom: 9px;
                                                                                                                                           }
                                                                                                                                           }
                                                                                                                                           </style>
                                                                                                                                           <div class="home_section_one">
                                                                                                                                           <div class="custom_container">
                                                                                                                                           <div class="home_section_one_box">
                                                                                                                                           <div class="home_section_one_box_data">
                                                                                                                                           <div class="home_section_one_box_des"><small>Lorem Ipsum</small>
                                                                                                                                           <h2>Adjustable Dumbbells</h2>
                                                                                                                                           </div>
                                                                                                                                           <div class="home_section_one_box_imgs">
                                                                                                                                           <figure><img src="https://static.tossdown.com/images/a80b08e2-9b49-4de0-8b6b-9abb95579152.png" /></figure>
                                                                                                                                           <figure><img src="https://static.tossdown.com/images/69cbb137-1fae-4205-9b02-e6a7265c85fe.png" /></figure>
                                                                                                                                           </div>
                                                                                                                                           <div class="home_section_one_box_btn"><a >Discover</a></div>
                                                                                                                                           </div>
                                                                                                                                           </div>
                                                                                                                                           </div>
                                                                                                                                           </div>
                                                                                                                                           
                                                                                                                                           `
                                                                                                                                            },
                                                                                                                                            {
                                                                                                                                                id:"HomeCustomSection39",
                                                                                                                                                name:"HomeCustomSection 40",
                                                                                                                                                height:"800px",
                                                                                                                                                code:` 
                                                                                                                                                <head>
                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                <meta
                                                                                                                                                  name="viewport"
                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                />
                                                                                                                                              
                                                                                                                                                <link
                                                                                                                                                  rel="stylesheet"
                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                />
                                                                                                                                                <link
                                                                                                                                                  rel="stylesheet"
                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                />
                                                                                                                                              
                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                <script
                                                                                                                                                  type="text/javascript"
                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                ></script>
                                                                                                                                                <script
                                                                                                                                                  type="text/javascript"
                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                ></script>
                                                                                                                                              
                                                                                                                                                <link
                                                                                                                                                  rel="stylesheet"
                                                                                                                                                  type="text/css"
                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                />
                                                                                                                                                <link
                                                                                                                                                  rel="stylesheet"
                                                                                                                                                  type="text/css"
                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                />
                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                               
                                                                                                                                              </head>
                                                                                                                                                 <style>
                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                </style>
                                                                                                                                                <style>
                                                                                                                                                * {
                                                                                                                                                                 -web-kit-box-sizing: border-box;
                                                                                                                                                                 -moz-box-sizing: border-box;
                                                                                                                                                                 box-sizing: border-box;
                                                                                                                                                                 padding: 0px;
                                                                                                                                                                 margin: 0px;
                                                                                                                                                                 font-family: "Poppins", sans-serif;
                                                                                                                                                               }
                                                                                                                                                               html {
                                                                                                                                                                 scroll-behavior: smooth;
                                                                                                                                                               }
                                                                                                                                                               body {
                                                                                                                                                                 float: left;
                                                                                                                                                                 width: 100%;
                                                                                                                                                                 margin: 0px;
                                                                                                                                                      
                                                                                                                                                               }
                                                                                                                                               .custom_section_5 {
                                                                                                                                                   display: block;
                                                                                                                                                   width: 100%;
                                                                                                                                                   margin-bottom: 48px;
                                                                                                                                                   padding: 0px 15px;
                                                                                                                                               }
                                                                                                                                               .custom_container {
                                                                                                                                                   max-width: 1140px;
                                                                                                                                                   margin: 0 auto;
                                                                                                                                               }
                                                                                                                                               
                                                                                                                                               .custom_section_5_list {
                                                                                                                                                   display: grid;
                                                                                                                                                   grid-template-columns: 1fr 1fr;
                                                                                                                                                   grid-template-rows: auto;
                                                                                                                                                   grid-template-areas: ". .";
                                                                                                                                                   gap: 15px 15px;
                                                                                                                                                   width: 100%;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_box {
                                                                                                                                                   float: left;
                                                                                                                                                   width: 100%;
                                                                                                                                                   height: 100%;
                                                                                                                                               }
                                                                                                                                               
                                                                                                                                               .custom_section_5_figcaption small{
                                                                                                                                               font-family: 'Epilogue';
                                                                                                                                               font-style: normal;
                                                                                                                                               font-weight: 600;
                                                                                                                                               font-size: 12px;
                                                                                                                                               line-height: 12px;
                                                                                                                                               text-transform: uppercase;
                                                                                                                                               color: #000000;
                                                                                                                                               padding-bottom:8px;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_figcaption h4{
                                                                                                                                               font-family: 'Epilogue';
                                                                                                                                               font-style: normal;
                                                                                                                                               font-weight: 800;
                                                                                                                                               font-size: 28px;
                                                                                                                                               line-height: 32px;
                                                                                                                                               text-transform: uppercase;
                                                                                                                                               color: #000000;
                                                                                                                                               margin-bottom:32px;
                                                                                                                                               }
                                                                                                                                               
                                                                                                                                               .custom_section_5_figcaption .figcaption_data_box_btn a{
                                                                                                                                               font-family: 'Epilogue';
                                                                                                                                               font-style: normal;
                                                                                                                                               font-weight: 600;
                                                                                                                                               font-size: 13px;
                                                                                                                                               line-height: 13px;
                                                                                                                                               text-transform: uppercase;
                                                                                                                                               color: #FFFFFF;
                                                                                                                                               width: 136px;
                                                                                                                                               height: 44px;
                                                                                                                                               background: #000000;
                                                                                                                                               border-radius: 6px;
                                                                                                                                                 display: flex;
                                                                                                                                                   align-items: center;
                                                                                                                                                   justify-content: center;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_figcaption .figcaption_data_box_btn a:hover{
                                                                                                                                               color: #FFFFFF;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_data_box_one{
                                                                                                                                               background: #FFFFFF;
                                                                                                                                                   display: grid;
                                                                                                                                                   grid-template-columns: 1fr 1.4fr;
                                                                                                                                                   align-items: center;
                                                                                                                                               padding: 50px 30px;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_padding{
                                                                                                                                               padding-bottom:0px !important; 
                                                                                                                                                   padding-top: 0px;
                                                                                                                                               }
                                                                                                                                               .section_one_figcaption_data_box_figure {
                                                                                                                                                   height: 100%;
                                                                                                                                                   display: flex;
                                                                                                                                                   align-items: flex-end;
                                                                                                                                               }
                                                                                                                                               @media(min-width:768px) and (max-width:1024px){
                                                                                                                                               .custom_section_5_figcaption h4 {
                                                                                                                                                        font-size: 20px;
                                                                                                                                                   line-height: 23px;
                                                                                                                                                   margin-bottom: 25px;
                                                                                                                                               }
                                                                                                                                               }
                                                                                                                                               @media (max-width:767px){
                                                                                                                                               .custom_section_5_list {
                                                                                                                                                   grid-template-columns: 1fr;
                                                                                                                                                   grid-template-areas: ".";
                                                                                                                                               }
                                                                                                                                               
                                                                                                                                               }
                                                                                                                                               @media (max-width:540px){
                                                                                                                                               .custom_section_5_data_box_one{
                                                                                                                                                   grid-template-columns: 1fr;
                                                                                                                                                   justify-content: center;
                                                                                                                                                   text-align: center;
                                                                                                                                                   padding-left: 10px;
                                                                                                                                                   padding-right: 10px;
                                                                                                                                                padding-bottom: 30px;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_figcaption {
                                                                                                                                                   padding-bottom: 20px;
                                                                                                                                               }
                                                                                                                                               .figcaption_data_box_btn{
                                                                                                                                                   justify-content: center;
                                                                                                                                                   align-items: center;
                                                                                                                                                   width: 100%;
                                                                                                                                                   display: flex;
                                                                                                                                               }
                                                                                                                                               .section_one_figcaption_data_box_figure {
                                                                                                                                                   justify-content: center;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_padding{
                                                                                                                                               padding-top:50px;
                                                                                                                                               }
                                                                                                                                               .custom_section_5_figcaption h4{
                                                                                                                                                   margin-bottom: 20px;
                                                                                                                                               }
                                                                                                                                               }
                                                                                                                                               </style>
                                                                                                                                               <section class="custom_section_5">
                                                                                                                                               <div class="custom_container">
                                                                                                                                               <div class="custom_section_5_list">
                                                                                                                                               <div class="custom_section_5_data_box_one custom_section_5_padding">
                                                                                                                                               <div class="custom_section_5_figcaption"><small>Lorem Impsum</small>
                                                                                                                                               <h4>Adjustable dumbbells</h4>
                                                                                                                                               <div class="figcaption_data_box_btn"><a>Buy</a></div>
                                                                                                                                               </div>
                                                                                                                                               <div class="figcaption_data_box_figure section_one_figcaption_data_box_figure"><img src="https://static.tossdown.com/images/a26a925e-fc12-4599-b2bc-9f1ef047d0ff.png" /></div>
                                                                                                                                               </div>
                                                                                                                                               <div class="custom_section_5_data_box_one">
                                                                                                                                               <div class="custom_section_5_figcaption"><small>Lorem Impsum</small>
                                                                                                                                               <h4>Adjustable Bench 360°</h4>
                                                                                                                                               <div class="figcaption_data_box_btn"><a>Buy</a></div>
                                                                                                                                               </div>
                                                                                                                                               <div class="figcaption_data_box_figure"><img src="https://static.tossdown.com/images/4058a09d-e5d7-4cd4-963c-786e51dc432c.png" /></div>
                                                                                                                                               </div>
                                                                                                                                               </div>
                                                                                                                                               </div>
                                                                                                                                               </section>
                                                                                                                                               
                                                                                                                                               `
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                    id:"HomeCustomSection40",
                                                                                                                                                    name:"HomeCustomSection 41",
                                                                                                                                                    height:"800px",
                                                                                                                                                    code:` 
                                                                                                                                                    <head>
                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                    <meta
                                                                                                                                                      name="viewport"
                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                    />
                                                                                                                                                  
                                                                                                                                                    <link
                                                                                                                                                      rel="stylesheet"
                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                    />
                                                                                                                                                    <link
                                                                                                                                                      rel="stylesheet"
                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                    />
                                                                                                                                                  
                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                    <script
                                                                                                                                                      type="text/javascript"
                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                    ></script>
                                                                                                                                                    <script
                                                                                                                                                      type="text/javascript"
                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                    ></script>
                                                                                                                                                  
                                                                                                                                                    <link
                                                                                                                                                      rel="stylesheet"
                                                                                                                                                      type="text/css"
                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                    />
                                                                                                                                                    <link
                                                                                                                                                      rel="stylesheet"
                                                                                                                                                      type="text/css"
                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                    />
                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                   
                                                                                                                                                  </head>
                                                                                                                                                     <style>
                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                    </style>
                                                                                                                                                    <style>
                                                                                                                                                    * {
                                                                                                                                                                     -web-kit-box-sizing: border-box;
                                                                                                                                                                     -moz-box-sizing: border-box;
                                                                                                                                                                     box-sizing: border-box;
                                                                                                                                                                     padding: 0px;
                                                                                                                                                                     margin: 0px;
                                                                                                                                                                     font-family: "Poppins", sans-serif;
                                                                                                                                                                   }
                                                                                                                                                                   html {
                                                                                                                                                                     scroll-behavior: smooth;
                                                                                                                                                                   }
                                                                                                                                                                   body {
                                                                                                                                                                     float: left;
                                                                                                                                                                     width: 100%;
                                                                                                                                                                     margin: 0px;
                                                                                                                                                                 background-color: #fff;
                                                                                                                                                                   }
                                                                                                                                                       .banner_section_two {
                                                                                                                                                         float: left;
                                                                                                                                                           width: 100%;
                                                                                                                                                           margin-bottom: 48px;
                                                                                                                                                           width: 100%;
                                                                                                                                                           height: 100%;
                                                                                                                                                       padding:0px 15px;
                                                                                                                                                      
                                                                                                                                                       }
                                                                                                                                                       .custom_container {
                                                                                                                                                           max-width: 1140px;
                                                                                                                                                           margin: 0 auto;
                                                                                                                                                       }
                                                                                                                                                       .Banner_slider_two {
                                                                                                                                                         overflow: hidden;
                                                                                                                                                       }
                                                                                                                                                       .single_banner_slider_data_two {
                                                                                                                                                           display: flex;
                                                                                                                                                           flex-direction: column;
                                                                                                                                                           width: 100%;
                                                                                                                                                       }
                                                                                                                                                   
                                                                                                                                                       .banner_section_two_main_bg_img{
                                                                                                                                                        width: 100% !important;
                                                                                                                                                        position: relative;
                                                                                                                                                           margin: 0 auto !important;
                                                                                                                                                       }
                                                                                                                                                       .banner_section_two_main_bg_img img{
                                                                                                                                                         width: 100%;
                                                                                                                                                       }
                                                                                                                                                       .single_banner_slider_detail_two {
                                                                                                                                                       display: flex;
                                                                                                                                                       justify-content: flex-start;
                                                                                                                                                       align-items: flex-end;
                                                                                                                                                       width: 100%;
                                                                                                                                                       top: 0px;
                                                                                                                                                       left: 0px;
                                                                                                                                                       content: "";
                                                                                                                                                       height: 100%;
                                                                                                                                                       position: absolute;
                                                                                                                                                       padding: 10px 10px 90px 48px;
                                                                                                                                                       }
                                                                                                                                                    
                                                                                                                                                       .single_banner_slider_detail_des_two {
                                                                                                                                                           display: flex;
                                                                                                                                                           flex-direction: column;
                                                                                                                                                       max-width: 300px;
                                                                                                                                                       }
                                                                                                                                                       .single_banner_slider_detail_des_two small{
                                                                                                                                                     font-family: 'Epilogue';
                                                                                                                                                   font-style: normal;
                                                                                                                                                   font-weight: 600;
                                                                                                                                                   font-size: 12px;
                                                                                                                                                   line-height: 12px;
                                                                                                                                                   text-transform: uppercase;
                                                                                                                                                   color: #FFFFFF;
                                                                                                                                                       padding-bottom: 12px;
                                                                                                                                                       }
                                                                                                                                                       .single_banner_slider_detail_des_two h1 {
                                                                                                                                                         font-family: 'Epilogue';
                                                                                                                                                   font-style: normal;
                                                                                                                                                   font-weight: 800;
                                                                                                                                                   font-size: 40px;
                                                                                                                                                   line-height: 42px;
                                                                                                                                                   text-transform: uppercase;
                                                                                                                                                   color: #FFFFFF;
                                                                                                                                                           margin-bottom:36px;
                                                                                                                                                       }
                                                                                                                                                       .single_banner_slider_detail_btn_two {
                                                                                                                                                           display: flex;
                                                                                                                                                           align-items: center;
                                                                                                                                                       }
                                                                                                                                                       .single_banner_slider_detail_btn_two a{
                                                                                                                                                   background: #FFFFFF;
                                                                                                                                                   border-radius: 6px;
                                                                                                                                                       width:164px;
                                                                                                                                                       height:44px;
                                                                                                                                                       font-family: 'Epilogue';
                                                                                                                                                       font-style: normal;
                                                                                                                                                       font-weight: 600;
                                                                                                                                                       font-size: 13px;
                                                                                                                                                       line-height: 13px;
                                                                                                                                                       text-transform: uppercase;
                                                                                                                                                       color: #000000;
                                                                                                                                                           display: flex;
                                                                                                                                                           align-items: center;
                                                                                                                                                           justify-content: center;
                                                                                                                                                       }
                                                                                                                                                      
                                                                                                                                                   
                                                                                                                                                       @media(max-width:1024px){
                                                                                                                                                      .single_banner_slider_detail_two {
                                                                                                                                                       padding: 10px 10px 50px 30px;
                                                                                                                                                         }
                                                                                                                                                       }
                                                                                                                                                       @media(max-width:767px){
                                                                                                                                                   .single_banner_slider_detail_des_two small {
                                                                                                                                                       padding-bottom: 9px;
                                                                                                                                                   }   
                                                                                                                                                   .single_banner_slider_detail_des_two h1 {
                                                                                                                                                       font-size: 34px;
                                                                                                                                                       line-height: 37px;
                                                                                                                                                       margin-bottom: 22px;
                                                                                                                                                   }
                                                                                                                                                       }
                                                                                                                                                           @media(max-width:540px){
                                                                                                                                                   .single_banner_slider_detail_two {
                                                                                                                                                       padding: 10px 10px 25px 20px;
                                                                                                                                                   }
                                                                                                                                                   .single_banner_slider_detail_des_two h1 {
                                                                                                                                                       font-size: 22px;
                                                                                                                                                       line-height: 25px;
                                                                                                                                                       margin-bottom: 15px;
                                                                                                                                                   }
                                                                                                                                                   .single_banner_slider_detail_des_two {
                                                                                                                                                       max-width: 230px;
                                                                                                                                                   }
                                                                                                                                                   .single_banner_slider_detail_btn_two a {
                                                                                                                                                       width: 116px;
                                                                                                                                                       height: 40px;
                                                                                                                                                       font-size: 12px;
                                                                                                                                                       line-height: 10px;
                                                                                                                                                   }
                                                                                                                                                   }
                                                                                                                                                   @media (max-width: 450px){
                                                                                                                                                   .single_banner_slider_detail_des_two small {
                                                                                                                                                       padding-bottom: 6px;
                                                                                                                                                       font-size: 11px;
                                                                                                                                                   }
                                                                                                                                                   .single_banner_slider_detail_des_two h1 {
                                                                                                                                                       font-size: 18px;
                                                                                                                                                       line-height: 20px;
                                                                                                                                                       margin-bottom: 14px;
                                                                                                                                                   }
                                                                                                                                                   .single_banner_slider_detail_btn_two a {
                                                                                                                                                       width: 94px;
                                                                                                                                                       height: 35px;
                                                                                                                                                       font-size: 10px;
                                                                                                                                                       line-height: 6px;
                                                                                                                                                   }
                                                                                                                                                   .single_banner_slider_detail_two {
                                                                                                                                                       padding: 10px 10px 18px 15px;
                                                                                                                                                   }
                                                                                                                                                   }
                                                                                                                                                       </style>
                                                                                                                                                   <section class="banner_section_two">
                                                                                                                                                   <div class="custom_container">
                                                                                                                                                   <div class="Banner_slider_two">
                                                                                                                                                   <figure class="banner_section_two_main_bg_img"><img src="https://static.tossdown.com/images/56b1ba33-6235-4c2f-b9ec-cf2fd9759fd6.jpg" />
                                                                                                                                                   <div class="single_banner_slider_detail_two">
                                                                                                                                                   <div class="single_banner_slider_detail_des_two"><small>Lorem Ipsum</small>
                                                                                                                                                   <h1>Convenient. Affordable.</h1>
                                                                                                                                                   <div class="single_banner_slider_detail_btn_two"><a >Shop now</a></div>
                                                                                                                                                   </div>
                                                                                                                                                   </div>
                                                                                                                                                   </figure>
                                                                                                                                                   </div>
                                                                                                                                                   </div>
                                                                                                                                                   </section>
                                                                                                                                                   <script type="text/javascript">
                                                                                                                                                         $(document).ready(function() {      
                                                                                                                                                       $('.Banner_slider_two').slick({
                                                                                                                                                         dots: false,
                                                                                                                                                       arrows: false,
                                                                                                                                                         infinite: false,
                                                                                                                                                         speed: 500,
                                                                                                                                                         fade: true,
                                                                                                                                                         cssEase: 'linear'
                                                                                                                                                       });
                                                                                                                                                         }); 
                                                                                                                                                       
                                                                                                                                                       </script>
                                                                                                                                                   
                                                                                                                                                   `
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        id:"HomeCustomSection41",
                                                                                                                                                        name:"HomeCustomSection 42",
                                                                                                                                                        height:"800px",
                                                                                                                                                        code:` 
                                                                                                                                                        <head>
                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                        <meta
                                                                                                                                                          name="viewport"
                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                        />
                                                                                                                                                      
                                                                                                                                                        <link
                                                                                                                                                          rel="stylesheet"
                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                        />
                                                                                                                                                        <link
                                                                                                                                                          rel="stylesheet"
                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                        />
                                                                                                                                                      
                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                        <script
                                                                                                                                                          type="text/javascript"
                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                        ></script>
                                                                                                                                                        <script
                                                                                                                                                          type="text/javascript"
                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                        ></script>
                                                                                                                                                      
                                                                                                                                                        <link
                                                                                                                                                          rel="stylesheet"
                                                                                                                                                          type="text/css"
                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                        />
                                                                                                                                                        <link
                                                                                                                                                          rel="stylesheet"
                                                                                                                                                          type="text/css"
                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                        />
                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                       
                                                                                                                                                      </head>
                                                                                                                                                         <style>
                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                        </style>
                                                                                                                                                        <style>
                                                                                                                                                        * {
                                                                                                                                                                          -web-kit-box-sizing: border-box;
                                                                                                                                                                          -moz-box-sizing: border-box;
                                                                                                                                                                          box-sizing: border-box;
                                                                                                                                                                          padding: 0px;
                                                                                                                                                                          margin: 0px;
                                                                                                                                                                          font-family: "Poppins", sans-serif;
                                                                                                                                                                        }
                                                                                                                                                                        html {
                                                                                                                                                                          scroll-behavior: smooth;
                                                                                                                                                                        }
                                                                                                                                                                        body {
                                                                                                                                                                          float: left;
                                                                                                                                                                          width: 100%;
                                                                                                                                                                          margin: 0px;
                                                                                                                                                                      background-color: #fff;
                                                                                                                                                                        }
                                                                                                                                                        .home_section_six {
                                                                                                                                                            display: block;
                                                                                                                                                            float: left;
                                                                                                                                                            width: 100%;
                                                                                                                                                            padding: 0px 15px;
                                                                                                                                                        margin-bottom:60px;
                                                                                                                                                        }
                                                                                                                                                        .custom_container {
                                                                                                                                                            max-width: 1140px;
                                                                                                                                                            margin: 0 auto;
                                                                                                                                                        }
                                                                                                                                                        .home_section_six_box {
                                                                                                                                                            width: 100%;
                                                                                                                                                            background: #CDA16F;
                                                                                                                                                            padding: 40px 10px 38px 10px;
                                                                                                                                                            display: flex;
                                                                                                                                                        }
                                                                                                                                                        .home_section_six_single_data {
                                                                                                                                                            flex: 0 0 25%;
                                                                                                                                                            align-items: center;
                                                                                                                                                            justify-content: center;
                                                                                                                                                            display: flex;
                                                                                                                                                            flex-direction: column;
                                                                                                                                                        }
                                                                                                                                                        .home_section_six_single_data figure {
                                                                                                                                                            display: flex;
                                                                                                                                                            border: 1.5px solid #FFFFFF;
                                                                                                                                                            align-items: center;
                                                                                                                                                            justify-content: center;
                                                                                                                                                            border-radius: 50%;
                                                                                                                                                            height: 48px;
                                                                                                                                                            margin-bottom: 21px;
                                                                                                                                                            width: 48px;
                                                                                                                                                        }
                                                                                                                                                        .home_section_six_single_data small {
                                                                                                                                                        font-family: 'Epilogue';
                                                                                                                                                        font-style: normal;
                                                                                                                                                        font-weight: 700;
                                                                                                                                                        font-size: 14px;
                                                                                                                                                        line-height: 14px;
                                                                                                                                                        text-transform: uppercase;
                                                                                                                                                        color: #FFFFFF;
                                                                                                                                                        }
                                                                                                                                                        @media(max-width:767px){
                                                                                                                                                        .home_section_six_box {
                                                                                                                                                            flex-wrap: wrap;
                                                                                                                                                        }
                                                                                                                                                        .home_section_six_single_data {
                                                                                                                                                            flex: 0 0 50%;
                                                                                                                                                         padding-bottom: 25px;
                                                                                                                                                        }
                                                                                                                                                        .home_section_six_box {
                                                                                                                                                            padding: 45px 10px 20px 10px;
                                                                                                                                                        }
                                                                                                                                                        
                                                                                                                                                        }
                                                                                                                                                        @media(max-width:540px){
                                                                                                                                                        .home_section_six_single_data small {
                                                                                                                                                            font-size: 12px;
                                                                                                                                                            line-height: 12px;
                                                                                                                                                        }
                                                                                                                                                        }
                                                                                                                                                        </style>
                                                                                                                                                        <div class="home_section_six">
                                                                                                                                                        <div class="custom_container">
                                                                                                                                                        <div class="home_section_six_box">
                                                                                                                                                        <div class="home_section_six_single_data">
                                                                                                                                                        <figure><img src="https://static.tossdown.com/images/19757b64-718f-4778-9aee-3739e2ba1de3.png" /></figure>
                                                                                                                                                        <small>fast shipping</small></div>
                                                                                                                                                        <div class="home_section_six_single_data">
                                                                                                                                                        <figure><img src="https://static.tossdown.com/images/06c8859a-13a1-45a2-a703-1074d98dd827.png" /></figure>
                                                                                                                                                        <small>Secure payment</small></div>
                                                                                                                                                        <div class="home_section_six_single_data">
                                                                                                                                                        <figure><img src="https://static.tossdown.com/images/84d62dbb-8bdb-4b1e-8c4a-b86284cb651a.png" /></figure>
                                                                                                                                                        <small>easy return policy</small></div>
                                                                                                                                                        <div class="home_section_six_single_data">
                                                                                                                                                        <figure><img src="https://static.tossdown.com/images/3a0d7a65-2090-4372-97de-cbd6ae2eaac5.png" /></figure>
                                                                                                                                                        <small>1 year warranty</small></div>
                                                                                                                                                        </div>
                                                                                                                                                        </div>
                                                                                                                                                        </div>
                                                                                                                                                       
                                                                                                                                                       `
                                                                                                                                                        },
                                                                                                                                                        {
                                                                                                                                                            id:"HomeCustomSection42",
                                                                                                                                                            name:"HomeCustomSection 43",
                                                                                                                                                            height:"800px",
                                                                                                                                                            code:` 
                                                                                                                                                            <head>
                                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                                            <meta
                                                                                                                                                              name="viewport"
                                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                            />
                                                                                                                                                          
                                                                                                                                                            <link
                                                                                                                                                              rel="stylesheet"
                                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                            />
                                                                                                                                                            <link
                                                                                                                                                              rel="stylesheet"
                                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                            />
                                                                                                                                                          
                                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                            <script
                                                                                                                                                              type="text/javascript"
                                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                            ></script>
                                                                                                                                                            <script
                                                                                                                                                              type="text/javascript"
                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                            ></script>
                                                                                                                                                          
                                                                                                                                                            <link
                                                                                                                                                              rel="stylesheet"
                                                                                                                                                              type="text/css"
                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                            />
                                                                                                                                                            <link
                                                                                                                                                              rel="stylesheet"
                                                                                                                                                              type="text/css"
                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                            />
                                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                           
                                                                                                                                                          </head>
                                                                                                                                                             <style>
                                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                            </style>
                                                                                                                                                            <style> 
 * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.banner{
        float: left;
        width: 100%;
    }
.banner_two_container{
    max-width: 800px;
    margin-left: auto;
    height: 100%;
    padding: 0px 170px 0px 70px;
    position: relative;
}
.banner_bulb_one {
    position: absolute;
    top: 0px;
    left: 48px;
}
.banner_bulb_two {
    position: absolute;
    top: 0px;
    right: 86px;
}
.banner_bulb_three {
    position: absolute;
    top: 0px;
    right: 37px;
}
.banner_figure{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
background-image:url("https://static.tossdown.com/images/ad0d29fb-7666-4220-ab97-3a470b9df9db.png");
height:550px;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner_content{
    position: absolute;
    top: 0px;
    width: 100%;
    padding: 0px 15px;
    height: 100%;
  }
  .banner_content h2{
font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 48px;
    line-height: 58px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 0px;
    padding-bottom: 24px;
  }
  .banner_content h2 small{
font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 48px;
    line-height: 58px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 0px;
}
  .banner_content p{
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 21px;
color: #FFFFFF;
    padding-bottom: 47px;
  }
.main_active_color{

    color: #ed1c24 !important;
}
.banner_content_main {
    max-width: 560px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
      align-items: flex-end;
    height: 100%;
    justify-content: center;
}
.banner_content_btn{
    display: flex;
    align-items: center;
    width: 100%;
justify-content: flex-end;
}
.banner_content_btn a{
background: linear-gradient(90deg, #8D5A26 11.05%, #D2B060 43.16%, #F0DC80 60%, #A37135 82.11%);
border: 2px solid #F5DC7E;
border-radius: 53px;
Width:156px;
Height:48px;
    display: flex;
    align-items: center;
    justify-content: center;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
text-transform: capitalize;
color: #131B11;

}

.banner_content_main_two {
    max-width: 380px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding-left: 48px;
}

@media(max-width:1300px){
  .banner_figure::before{
        position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.5;
    }
}
@media(max-width:1024px){
.banner_two_container {
    padding: 0px 100px 0px 44px;
    margin: 0 auto;
}
.banner_bulb_one {
    left: 0px;
}
.banner_bulb_two {
    right: 50px;
}
.banner_bulb_three {
    right: 0px;
}

   .banner_figure {
        height: 500px;
    }
    .banner_content_main {
        max-width: 100%;
   margin: 0 auto;
    }
.banner_content_main {
    align-items: center;
    justify-content: center;
    text-align: center;
}
.banner_content h2 {
    text-align: center;
}
.banner_content_btn {
    justify-content: center;
}
}
@media(max-width:768px){
.banner_figure {
    height: 430px;
}
.banner_two_container {
    padding: 0px 100px 0px 70px;
}
.banner_content_main {
    max-width: 540px;
}
     .banner_content h2 {
        font-size: 35px;
        padding-bottom: 10px;
line-height: 32px;
    } 
 .banner_content h2 small{
        font-size: 35px;
        padding-bottom: 18px;
line-height: 32px;
    } 

}
@media(max-width:540px){
.banner_figure {
    height: 360px;
}
.banner_two_container {
    padding: 0px 50px 0px 50px;
}
.banner_bulb_two {
    display: none;
}
.banner_content h2 {
    font-size: 30px;
    padding-bottom: 10px;
    line-height: 32px;
}
.banner_content h2 small{
    font-size: 30px;
    padding-bottom: 10px;
    line-height: 32px;
}
.banner_content p {
    padding-bottom: 29px;
}
}
@media(max-width:410px){
.banner_figure {
    height: 280px;
}
.banner_content_btn a {
    Width: 135px;
    Height: 43px;
}
.banner_content p {
    font-size: 14px;
    line-height: 20px;
}
.banner_content h2 {
    font-size: 22px;
    padding-bottom: 10px;
    line-height: 26px;
}
.banner_content h2 small{
    font-size: 22px;
    padding-bottom: 10px;
    line-height: 26px;
}
}
.banner_section_top_head{
    display: flex;
    width: 100%;
}
.banner_section_bottom_head{
    display: flex;
    width: 100%;
}

.banner_bulb_one_main{
position:relative;
}
.banner_bulb_one_main:before{
  position: absolute;
    content: "";
    bottom: 0px;
    left: -3px;
    width: 47px;
    height: 47px;
    background-repeat: no-repeat;
  animation: banner_blink-animation_one 2s steps(5, start) infinite;
        -webkit-animation: banner_blink-animation_one 2s ease-in-out infinite alternate;
    color: transparent;
background: linear-gradient(180deg, #C08640 0%, #DCAE5C 44.23%, #FFFF8E 100%);
mix-blend-mode: normal;
opacity: 0.7;
filter: blur(21px);
}
 @keyframes banner_blink-animation_one {
       0% {
  opacity: 0;
        }
  100% {
 opacity: 1;
        }
      }
      @-webkit-keyframes banner_blink-animation_one{
        0% {
  opacity: 0;
        }
  100% {
 opacity: 1;
        }
      }




.banner_bulb_two_main{
position:relative;
}
.banner_bulb_two_main:before{
  position: absolute;
    content: "";
 bottom: -17px;
    left: 3px;
    width: 23px;
    height: 130px;
    background-repeat: no-repeat;
  animation: banner_blink-animation_two 2s steps(5, start) infinite;
        -webkit-animation: banner_blink-animation_two 2s ease-in-out infinite alternate;
    color: transparent;
background: linear-gradient(180deg, #C08640 0%, #DCAE5C 44.23%, #FFFF8E 100%);
mix-blend-mode: normal;
opacity: 0.7;
filter: blur(21px);
}
 @keyframes banner_blink-animation_two {
       0% {
  opacity: 0;
        }
  100% {
 opacity: 1;
        }
      }
      @-webkit-keyframes banner_blink-animation_two{
        0% {
  opacity: 0;
        }
  100% {
 opacity: 1;
        }
      }


.banner_bulb_three_main{
position:relative;
}
.banner_bulb_three_main:before{
  position: absolute;
    content: "";
    bottom: -10px;
    left: 3px;
    width: 34px;
    height: 84px;
    background-repeat: no-repeat;
  animation: banner_blink-animation_three 2s steps(5, start) infinite;
        -webkit-animation: banner_blink-animation_three 2s ease-in-out infinite alternate;
    color: transparent;
background: linear-gradient(180deg, #C08640 0%, #DCAE5C 44.23%, #FFFF8E 100%);
mix-blend-mode: normal;
opacity: 0.7;
filter: blur(21px);
}
 @keyframes banner_blink-animation_three {
       0% {
  opacity: 0;
        }
  100% {
 opacity: 1;
        }
      }
      @-webkit-keyframes banner_blink-animation_three{
        0% {
  opacity: 0;
        }
  100% {
 opacity: 1;
        }
      }

</style>
<div class="banner_section_top_head"><img src="https://static.tossdown.com/images/b78eeaf9-fbb2-409e-8608-0f66c96b91f0.png" style="width: 100%;" /></div>
<section class="banner">
<div class="banner_figure">
<div class="banner_content">
<div class="banner_two_container">
<div class="banner_bulb_one" data-aos-delay="150" data-aos-duration="900" data-aos="zoom-in" data-aos-mirror="true" data-aos-once="true" data-aos-offset="50">
<div class="banner_bulb_one_main"><img src="https://static.tossdown.com/images/ed51baaf-fa8d-4197-94cb-7184195a1cd4.png" /></div>
</div>
<div class="banner_bulb_two" data-aos-delay="300" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">
<div class="banner_bulb_two_main"><img src="https://static.tossdown.com/images/4acf7e3c-d2f0-4ae1-a1d9-d6a5158f162a.png" /></div>
</div>
<div class="banner_bulb_three" data-aos-delay="350" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">
<div class="banner_bulb_three_main"><img src="https://static.tossdown.com/images/bc2bf47d-f834-4600-8fad-a48e79240e9b.png" /></div>
</div>
<div class="banner_content_main">
<h2 data-aos-delay="150" data-aos="fade-down" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">Straight from Our <small class="main_active_color">Kitchen to </small>your <small class="main_active_color">Heart.</small></h2>
<p data-aos-delay="350" data-aos="fade-up" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">Available at your doorstep by our 24/7 free home delivery</p>
<div class="banner_content_btn"><a data-aos="fade-up" data-aos-delay="400" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">Order Now</a></div>
</div>
</div>
</div>
</div>
</section>
<div class="banner_section_bottom_head"><img src="https://static.tossdown.com/images/b78eeaf9-fbb2-409e-8608-0f66c96b91f0.png" style="width: 100%;" /></div>
                                                                                                                                                           
                                                                                                                                                           `
                                                                                                                                                            },


                                                                                                                                                            {
                                                                                                                                                                id:"HomeCustomSection43",
                                                                                                                                                                name:"HomeCustomSection 44",
                                                                                                                                                                height:"800px",
                                                                                                                                                                code:` 
                                                                                                                                                                <head>
                                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                                <meta
                                                                                                                                                                  name="viewport"
                                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                />
                                                                                                                                                              
                                                                                                                                                                <link
                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                />
                                                                                                                                                                <link
                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                />
                                                                                                                                                              
                                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                <script
                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                ></script>
                                                                                                                                                                <script
                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                ></script>
                                                                                                                                                              
                                                                                                                                                                <link
                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                  type="text/css"
                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                />
                                                                                                                                                                <link
                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                  type="text/css"
                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                />
                                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                               
                                                                                                                                                              </head>
                                                                                                                                                                 <style>
                                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                </style>
                                                                                                                                                                <style>
                                                                                                                                                                * {
                                                                                                                                                                                 -web-kit-box-sizing: border-box;
                                                                                                                                                                                 -moz-box-sizing: border-box;
                                                                                                                                                                                 box-sizing: border-box;
                                                                                                                                                                                 padding: 0px;
                                                                                                                                                                                 margin: 0px;
                                                                                                                                                                                 font-family: "Poppins", sans-serif;
                                                                                                                                                                               }
                                                                                                                                                                               html {
                                                                                                                                                                                 scroll-behavior: smooth;
                                                                                                                                                                               }
                                                                                                                                                                               body {
                                                                                                                                                                                 float: left;
                                                                                                                                                                                 width: 100%;
                                                                                                                                                                                 margin: 0px;
                                                                                                                                                                             background-color: #fff;
                                                                                                                                                                               }
                                                                                                                                                               .detail-eye>p>img{
                                                                                                                                                                object-fit: cover !important
                                                                                                                                                               }
                                                                                                                                                               .layer-icons {
                                                                                                                                                                   position: relative;
                                                                                                                                                               }
                                                                                                                                                               .layer-icons img {
                                                                                                                                                                   right: -100px;
                                                                                                                                                                   top: -81px;
                                                                                                                                                                   position: absolute;
                                                                                                                                                                   transform: rotate(0deg);
                                                                                                                                                               }
                                                                                                                                                               
                                                                                                                                                               .cutsom-box-section {
                                                                                                                                                                   padding: 80px 0px 0px;
                                                                                                                                                                   width: 100%;
                                                                                                                                                                   display: block;
                                                                                                                                                                   overflow: hidden;
                                                                                                                                                               }
                                                                                                                                                               .bg-color-hold {
                                                                                                                                                                   background: rgba(188, 175, 214, 0.4);
                                                                                                                                                                   padding: 80px 0px;
                                                                                                                                                               }
                                                                                                                                                               .content-hold {
                                                                                                                                                                   text-align: center;
                                                                                                                                                                   width: 50%;
                                                                                                                                                                   margin: auto;
                                                                                                                                                               }
                                                                                                                                                               .content-hold p {
                                                                                                                                                                   font-family: 'Montserrat';
                                                                                                                                                                   font-style: normal;
                                                                                                                                                                   font-weight: 500;
                                                                                                                                                                   font-size: 14px;
                                                                                                                                                                   line-height: 24px;
                                                                                                                                                                   display: flex;
                                                                                                                                                                   align-items: center;
                                                                                                                                                                   text-align: center;
                                                                                                                                                                   color: rgba(0, 0, 0, 0.5);
                                                                                                                                                                   margin-bottom: 48px;
                                                                                                                                                               }
                                                                                                                                                               .content-hold h3 {
                                                                                                                                                                   font-family: 'Montserrat';
                                                                                                                                                                   font-style: normal;
                                                                                                                                                                   font-weight: 800;
                                                                                                                                                                   font-size: 32px;
                                                                                                                                                                   line-height: 40px;
                                                                                                                                                                   display: flex;
                                                                                                                                                                   align-items: center;
                                                                                                                                                                   text-align: center;
                                                                                                                                                                   letter-spacing: 0.05em;
                                                                                                                                                                   text-transform: uppercase;
                                                                                                                                                                   margin-bottom: 24px;
                                                                                                                                                               }
                                                                                                                                                               .content-hold a.event-btn-hold {
                                                                                                                                                                   color: rgb(255 255 255);
                                                                                                                                                                   background-color: #000000;
                                                                                                                                                                   border-radius: 0px;
                                                                                                                                                                   margin: 26px 0px 24px 0px;
                                                                                                                                                                   padding: 16px 54px;
                                                                                                                                                                   font-size: 10px;
                                                                                                                                                                   border: 1px solid #000;
                                                                                                                                                                   font-weight: 600;
                                                                                                                                                               }
                                                                                                                                                               </style>
                                                                                                                                                               <div class="cutsom-box-section">
                                                                                                                                                               <div class="row" style="background-color: rgba(188, 175, 214, 0.4);">
                                                                                                                                                               <div class="col-sm-6" style="padding: 0px;">
                                                                                                                                                               <div class="promotion-img-holder"><img src="https://tossdown.site/images/84669ec8944b229300acddefca644cea_1648632176.png" style="width: 100%;" /></div>
                                                                                                                                                               </div>
                                                                                                                                                               <div class="col-sm-6" style="padding: 0px;">
                                                                                                                                                               <div class="promotion-img-holder"><img src="https://tossdown.site/images/3d4f7b75ebc0d1cab7972bd356553da4_1650623029.png" style="width: 100%;" /></div>
                                                                                                                                                               </div>
                                                                                                                                                               </div>
                                                                                                                                                               <div class="bg-color-hold">
                                                                                                                                                               <div class="container">
                                                                                                                                                               <div class="row">
                                                                                                                                                               <div class="col-sm-12">
                                                                                                                                                               <div class="layer-icons"><img src="https://tossdown.site/images/c212d23d5d428ccf3fb2e43b60dc98f0_1648633807.png" /></div>
                                                                                                                                                               <div class="content-hold">
                                                                                                                                                               <h3>A perfect place for your corporate dining</h3>
                                                                                                                                                               <p>We're soft launching our labour of love and your future second home in the city, and we invite you to let us delight you. Let us know your take and share your experience, to help us serve you better. Welcome!</p>
                                                                                                                                                               <a class="event-btn-hold" >BOOK AN EVENT</a></div>
                                                                                                                                                               </div>
                                                                                                                                                               </div>
                                                                                                                                                               </div>
                                                                                                                                                               </div>
                                                                                                                                                               </div>
                                                                                                                                                               
                                                                                                                                                               `
                                                                                                                                                                },
                                                                                                                                                                {
                                                                                                                                                                    id:"HomeCustomSection44",
                                                                                                                                                                    name:"HomeCustomSection 45",
                                                                                                                                                                    height:"800px",
                                                                                                                                                                    code:` 
                                                                                                                                                                    <head>
                                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                                    <meta
                                                                                                                                                                      name="viewport"
                                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                    />
                                                                                                                                                                  
                                                                                                                                                                    <link
                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                    />
                                                                                                                                                                    <link
                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                    />
                                                                                                                                                                  
                                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                    <script
                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                    ></script>
                                                                                                                                                                    <script
                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                    ></script>
                                                                                                                                                                  
                                                                                                                                                                    <link
                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                      type="text/css"
                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                    />
                                                                                                                                                                    <link
                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                      type="text/css"
                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                    />
                                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                   
                                                                                                                                                                  </head>
                                                                                                                                                                     <style>
                                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                    </style>
                                                                                                                                                                    <style>
                                                                                                                                                                    * {
                                                                                                                                                                                     -web-kit-box-sizing: border-box;
                                                                                                                                                                                     -moz-box-sizing: border-box;
                                                                                                                                                                                     box-sizing: border-box;
                                                                                                                                                                                     padding: 0px;
                                                                                                                                                                                     margin: 0px;
                                                                                                                                                                                     font-family: "Poppins", sans-serif;
                                                                                                                                                                                   }
                                                                                                                                                                                   html {
                                                                                                                                                                                     scroll-behavior: smooth;
                                                                                                                                                                                   }
                                                                                                                                                                                   body {
                                                                                                                                                                                     float: left;
                                                                                                                                                                                     width: 100%;
                                                                                                                                                                                     margin: 0px;
                                                                                                                                                                                 background-color: #fff;
                                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_section{
                                                                                                                                                                       display: block;
                                                                                                                                                                       width: 100%;
                                                                                                                                                                       margin-bottom: 25px;
                                                                                                                                                                       padding: 17px 15px 0px 15px;
                                                                                                                                                                   background: radial-gradient(70.56% 483.65% at 41.18% 47.09%, #F3F2F2 0%, rgba(232, 216, 216, 0) 100%);
                                                                                                                                                                   }
                                                                                                                                                                   .iqbal_custom_container{
                                                                                                                                                                       max-width: 1140px;
                                                                                                                                                                       margin: 0px auto;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_box{
                                                                                                                                                                   display: grid;
                                                                                                                                                                       grid-template-columns: 1fr 1fr;
                                                                                                                                                                       grid-template-rows: auto;
                                                                                                                                                                       grid-template-areas: ". .";
                                                                                                                                                                       gap: 40px 20px;
                                                                                                                                                                       width: 100%;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption{
                                                                                                                                                                   display: flex;
                                                                                                                                                                       flex-direction: column;
                                                                                                                                                                       align-items: flex-start;
                                                                                                                                                                       justify-content: center;
                                                                                                                                                                       height: 100%;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption small {
                                                                                                                                                                    font-style: normal;
                                                                                                                                                                   font-weight: normal;
                                                                                                                                                                   font-size: 14px;
                                                                                                                                                                   line-height: 24px;
                                                                                                                                                                   text-transform: uppercase;
                                                                                                                                                                   color: #212121;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption h4 {
                                                                                                                                                                       font-family: Playfair Display;
                                                                                                                                                                     font-style: normal;
                                                                                                                                                                   font-weight: 600;
                                                                                                                                                                   font-size: 48px;
                                                                                                                                                                   line-height: 56px;
                                                                                                                                                                       color: #212121;
                                                                                                                                                                       padding-bottom: 18px;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption p {
                                                                                                                                                                     font-style: normal;
                                                                                                                                                                   font-weight: normal;
                                                                                                                                                                   font-size: 14px;
                                                                                                                                                                   line-height: 24px;
                                                                                                                                                                   color: rgba(0, 0, 0, 0.7);
                                                                                                                                                                       padding-bottom: 49px;
                                                                                                                                                                   }
                                                                                                                                                                   .application_download_links{
                                                                                                                                                                       display: flex;
                                                                                                                                                                       align-items: center;
                                                                                                                                                                   
                                                                                                                                                                   }
                                                                                                                                                                   .application_download_links a {
                                                                                                                                                                         display: flex;
                                                                                                                                                                       align-items: center;
                                                                                                                                                                   background: #212121;
                                                                                                                                                                   border-radius: 6.28846px;
                                                                                                                                                                   width: 157px;
                                                                                                                                                                   height: 52px;
                                                                                                                                                                       justify-content: center;
                                                                                                                                                                   margin-right:12px;
                                                                                                                                                                   }
                                                                                                                                                                   .application_download_links a img{
                                                                                                                                                                   padding-right:5px;
                                                                                                                                                                   }
                                                                                                                                                                   .application_download_links a:last-child{
                                                                                                                                                                   margin-right:0px;
                                                                                                                                                                   }
                                                                                                                                                                   .application_download_links a span{
                                                                                                                                                                   font-style: normal;
                                                                                                                                                                   font-weight: 600;
                                                                                                                                                                   font-size: 16.7692px !important;
                                                                                                                                                                   line-height: 20px;
                                                                                                                                                                   color: #FFFFFF;
                                                                                                                                                                   opacity: 0.9;
                                                                                                                                                                   display: flex;
                                                                                                                                                                       flex-direction: column;
                                                                                                                                                                       text-transform: capitalize;
                                                                                                                                                                   }
                                                                                                                                                                   .application_download_links a small{
                                                                                                                                                                   font-style: normal;
                                                                                                                                                                   font-weight: 600;
                                                                                                                                                                   font-size: 8.38461px !important;
                                                                                                                                                                   line-height: 10px;
                                                                                                                                                                   color: #FFFFFF;
                                                                                                                                                                   opacity: 0.9;
                                                                                                                                                                       text-align: left;
                                                                                                                                                                   }
                                                                                                                                                                   
                                                                                                                                                                   @media (min-width: 768px) and (max-width: 1024px){
                                                                                                                                                                   .home_page_second_banner_box figure{
                                                                                                                                                                   text-align:right;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption h4 {
                                                                                                                                                                       font-size: 38px;
                                                                                                                                                                       line-height: 44px;
                                                                                                                                                                   }
                                                                                                                                                                   }
                                                                                                                                                                   @media  (max-width:767px){
                                                                                                                                                                   .home_page_second_banner_box {
                                                                                                                                                                       grid-template-columns: 1fr;
                                                                                                                                                                       grid-template-areas: ".";
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_box figure{
                                                                                                                                                                   text-align:center;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_section {
                                                                                                                                                                       padding: 30px 15px 0px 15px;
                                                                                                                                                                   }
                                                                                                                                                                   }
                                                                                                                                                                   @media  (max-width:480px){
                                                                                                                                                                   .application_download_links a {
                                                                                                                                                                   margin-right:0px;
                                                                                                                                                                   margin-bottom:15px;
                                                                                                                                                                   }
                                                                                                                                                                   .application_download_links {
                                                                                                                                                                       flex-direction: column;
                                                                                                                                                                       justify-content: center;
                                                                                                                                                                       width: 100%;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption p {
                                                                                                                                                                       padding-bottom: 30px;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption h4 {
                                                                                                                                                                       font-size: 32px;
                                                                                                                                                                       line-height: 44px;
                                                                                                                                                                   }
                                                                                                                                                                   .home_page_second_banner_figcaption {
                                                                                                                                                                       align-items: center;
                                                                                                                                                                       text-align: center;
                                                                                                                                                                   }
                                                                                                                                                                   }
                                                                                                                                                                   </style>
                                                                                                                                                                   <section class="home_page_second_banner_section">
                                                                                                                                                                   <div class="iqbal_custom_container">
                                                                                                                                                                   <div class="home_page_second_banner_box">
                                                                                                                                                                   <div class="home_page_second_banner_data">
                                                                                                                                                                   <div class="home_page_second_banner_figcaption"><small>lorem-ipsum Foods</small>
                                                                                                                                                                   <h4>Download Our App To Order On The Go!</h4>
                                                                                                                                                                   <p>Download our app in your phone to get your grocery orders done easily.</p>
                                                                                                                                                                   <div class="application_download_links"><a target="blank" > <img src="https://tossdown.site/images/4624c114d08bcddfbee2111e04df1ae1_1640765836.png" /><span><small>GET IT ON</small>Google Play</span></a> <a target="blank" > <img src="https://tossdown.site/images/0d663e7c3096f38a76682f5ac456a38f_1640765931.png" /><span><small>Download on the</small>App Store</span></a></div>
                                                                                                                                                                   </div>
                                                                                                                                                                   </div>
                                                                                                                                                                   <figure><img src="https://tossdown.site/images/6271fb13a95d5f43b46a37010e163923_1640764056.png" /></figure>
                                                                                                                                                                   </div>
                                                                                                                                                                   </div>
                                                                                                                                                                   </section>
                                                                                                                                                                   
                                                                                                                                                                   `
                                                                                                                                                                    },
                                                                                                                                                                    
                                                                                                                                                                    {
                                                                                                                                                                        id:"HomeCustomSection45",
                                                                                                                                                                        name:"HomeCustomSection 46",
                                                                                                                                                                        height:"800px",
                                                                                                                                                                        code:` 
                                                                                                                                                                        <head>
                                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                                        <meta
                                                                                                                                                                          name="viewport"
                                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                        />
                                                                                                                                                                      
                                                                                                                                                                        <link
                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                        />
                                                                                                                                                                        <link
                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                        />
                                                                                                                                                                      
                                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                        <script
                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                        ></script>
                                                                                                                                                                        <script
                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                        ></script>
                                                                                                                                                                      
                                                                                                                                                                        <link
                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                          type="text/css"
                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                        />
                                                                                                                                                                        <link
                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                          type="text/css"
                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                        />
                                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                       
                                                                                                                                                                      </head>
                                                                                                                                                                         <style>
                                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                        </style>
                                                                                                                                                                        <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.company_0ffers_section {
    display: block;
    width: 100%;
    padding:0px 15px 0px 15px;
margin-bottom:55px;
}
.iqbal_custom_container {
    max-width: 1140px;
    margin: 0px auto;
}
.company_0ffers_heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 34px;
    text-align: center;
}
.company_0ffers_heading h3 {
   font-family: Playfair Display;
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 53px;
color: #212121;
text-align:center;
    margin-bottom: 0px;
    text-transform: capitalize;
padding-bottom:10px;
}
.company_0ffers_heading p{
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
text-align: center;
color: rgba(0, 0, 0, 0.7);
margin-bottom: 0px;
}
.company_0ffers_list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". . . .";
    gap:20px 45px;
    width: 100%;
}
.company_0ffers_single_list{
    width: 100%;
    display: flex;
    align-items: center;
}
.company_0ffers_single_list figure{
margin-bottom:0px;
padding-right:10px;
}
.product_category_figure_tags{
    display: flex;
    width: 100%;
}
.company_0ffers_single_list h5{
font-family: Playfair Display;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #212121;
margin:0px;
text-transform: capitalize;
}
@media(min-width:768px) and (max-width:1024px){
.company_0ffers_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
}
.company_0ffers_single_list {
    padding: 0px 0px 0px 100px;
}
}
@media (max-width:767px){
.company_0ffers_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
}
.company_0ffers_single_list {
 padding: 0px 0px 0px 15px;
}
.company_0ffers_heading p {
    width: 75% !important;
}
}
@media (max-width:540px){
.company_0ffers_heading p {
    width: 100% !important;
}
.company_0ffers_single_list {
 padding: 0px 0px 0px 0px;
}
.company_0ffers_list {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
gap:20px 20px;
}
}
@media (max-width:480px){
.company_0ffers_list {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
}
.company_0ffers_single_list {
 padding: 0px 0px 0px 20px;
}
}
</style>
<section class="company_0ffers_section">
<div class="iqbal_custom_container">
<div class="company_0ffers_heading">
<h3>We Offer The Best</h3>
<p style="width: 49%;">We believe that the satisfaction of our customers comes from a series of distinct and appealing initiatives.</p>
</div>
<div class="company_0ffers_list">
<div class="company_0ffers_single_list">
<figure><img src="https://tossdown.site/images/d9281751c21de5d87eb40ab8d047c9a0_1640763547.png" /></figure>
<div class="company_0ffers_single_list_figure_tags">
<h5>Online Payments</h5>
<p></p>
</div>
</div>
<div class="company_0ffers_single_list">
<figure><img src="https://tossdown.site/images/262d5fef77052757e060adc329c47f02_1640763489.png" /></figure>
<div class="company_0ffers_single_list_figure_tags">
<h5>Easy Returns</h5>
<p></p>
</div>
</div>
<div class="company_0ffers_single_list">
<figure><img src="https://tossdown.site/images/b0ef29d8867c47787f82ea9609cecd76_1640763579.png" /></figure>
<div class="company_0ffers_single_list_figure_tags">
<h5>Customer Support</h5>
<p></p>
</div>
</div>
<div class="company_0ffers_single_list">
<figure><img src="https://tossdown.site/images/f7352597836a80f0c1b85167fa60e060_1640763609.png" /></figure>
<div class="company_0ffers_single_list_figure_tags">
<h5>Shipping Facility</h5>
<p></p>
</div>
</div>
</div>
</div>
</section>
                                                                                                                                                                       
                                                                                                                                                                       `
                                                                                                                                                                        },


                                                                                                                                                                        {
                                                                                                                                                                            id:"HomeCustomSection46",
                                                                                                                                                                            name:"HomeCustomSection 47",
                                                                                                                                                                            height:"800px",
                                                                                                                                                                            code:` 
                                                                                                                                                                            <head>
                                                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                                                            <meta
                                                                                                                                                                              name="viewport"
                                                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                            />
                                                                                                                                                                          
                                                                                                                                                                            <link
                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                            />
                                                                                                                                                                            <link
                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                            />
                                                                                                                                                                          
                                                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                            <script
                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                            ></script>
                                                                                                                                                                            <script
                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                            ></script>
                                                                                                                                                                          
                                                                                                                                                                            <link
                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                              type="text/css"
                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                            />
                                                                                                                                                                            <link
                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                              type="text/css"
                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                            />
                                                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                           
                                                                                                                                                                          </head>
                                                                                                                                                                             <style>
                                                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                            </style>
                                                                                                                                                                            <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.iqbal_collection_section {
    display: block;
    width: 100%;
    margin-bottom: 55px;
    padding: 0px 15px;
}
.iqbal_custom_container {
    max-width: 1140px;
    margin: 0 auto;
}
.kids_collection_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". .";
    gap: 20px 20px;
    width: 100%;
}
.iqbal_collection_box {
    float: left;
    width: 100%;
    height: 100%;
}
.iqbal_collection_box  figure {
    width: 100%;
    height: 100%;
    position: relative;
margin:0px;
}
.iqbal_collection_box  figure:before{
    position: absolute;
    top: 0px;
    content: "";
}
.iqbal_collection_box  figure img {
    width: 100%;
    height: 100%;
}
.iqbal_collection_figcaption {
    position: absolute;
    top: 0px;
    content: "";
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    padding: 15px 15px;
    justify-content: center;
    height: 100%;
}
.iqbal_collection_figcaption small{
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
text-transform: uppercase;
color: #212121;
padding-bottom:4px;
}
.iqbal_collection_figcaption h4{
font-family: Playfair Display;
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 40px;
color: #212121;
margin-bottom:18px;
}
.iqbal_collection_figcaption p{
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
color: rgba(0, 0, 0, 0.7);
margin-bottom:32px;
}
.iqbal_collection_figcaption a{
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #000000;
width: 146px;
height: 44px;
background: #F6F2F2;
opacity: 0.7;
border-radius: 4px;
  display: flex;
    align-items: center;
    justify-content: center;
}
@media(min-width:768px) and (max-width:1024px){
.iqbal_collection_figcaption {
width:80%;
}
.iqbal_collection_figcaption h4 {
    font-size: 28px;
    line-height: 33px;
}
.iqbal_collection_figcaption p {
    line-height: 16px;
    margin-bottom: 18px;
}
}
@media (max-width:767px){
.kids_collection_list {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
}

}
@media (max-width:540px){
.iqbal_collection_figcaption {
width:80%;
}
.iqbal_collection_figcaption h4 {
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 8px;
}

.iqbal_collection_figcaption p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
}
}
</style>
<section class="iqbal_collection_section">
<div class="iqbal_custom_container">
<div class="kids_collection_list">
<div class="iqbal_collection_box">
<figure><img src="https://tossdown.site/images/9d8673ca7a015cfe0ef047f01f8fe18c_1640694945.png" />
<div class="iqbal_collection_figcaption"><small>Loram Ipsum Foods</small>
<h4>Find Spectacular Savings Online</h4>
<p>Free delivery on orders above $99</p>
<a >Shop Now</a></div>
</figure>
</div>
<div class="iqbal_collection_box">
<figure><img src="https://tossdown.site/images/1ed18f0d480513464b8cedaefccf05df_1640694985.png" />
<div class="iqbal_collection_figcaption"><small>ILoram Ipsum Foods</small>
<h4>Discover the Trends &amp; Find Great Deals</h4>
<p>Available on all your favourite items and more</p>
<a  style="background: #C4C4C4;">Shop Now</a></div>
</figure>
</div>
</div>
</div>
</section>
                                                                                                                                                                           
                                                                                                                                                                           `
                                                                                                                                                                            },
                                                                                                                                                                            {
                                                                                                                                                                                id:"HomeCustomSection47",
                                                                                                                                                                                name:"HomeCustomSection 48",
                                                                                                                                                                                height:"800px",
                                                                                                                                                                                code:` 
                                                                                                                                                                                <head>
                                                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                                                <meta
                                                                                                                                                                                  name="viewport"
                                                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                />
                                                                                                                                                                              
                                                                                                                                                                                <link
                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                />
                                                                                                                                                                                <link
                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                />
                                                                                                                                                                              
                                                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                <script
                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                ></script>
                                                                                                                                                                                <script
                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                ></script>
                                                                                                                                                                              
                                                                                                                                                                                <link
                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                />
                                                                                                                                                                                <link
                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                />
                                                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                               
                                                                                                                                                                              </head>
                                                                                                                                                                                 <style>
                                                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                </style>
                                                                                                                                                                                <style>
    * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.home_custom_section_8_main{
	background-color: #DCCCBF;
display:block;
    width: 100%;
    padding: 72px 15px;
    float: left;
}
.custom_container{
max-width:1140px;
margin:0 auto;
}

.home_custom_section_8{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
    grid-template-rows: auto;
    gap: 15px 0px;
    position: relative;
    z-index: 1;
    width: 100%;
    align-items: center;
height:100%;
}
.home_custom_section_8 figure {
    margin-bottom: 0px;
    width: 100%;
    height: 651px;
   background-image: url('https://tossdown.site/images/260c95ebc274873d4c53bb6f2d3f8ab6_1655274995.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.home_custom_section_8 figure img{
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
}
.home_custom_section_8_body{
    display: grid;
    width: 100%;
    flex-direction: column;
    gap: 16px 0px;
    padding: 0px 93px 0px 0px;
}
.home_custom_section_8_heading{
    display: grid;
    width: 100%;
    flex-direction: column;
    gap: 16px 0px;
}
.home_custom_section_8_heading small{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.25em;
text-transform: uppercase;
color: #F5C628;
}
.home_custom_section_8_heading h3{
   font-family: 'Jost';
    font-style: normal;
    font-weight: 800;
    font-size: 44px;
    line-height: 50px;
    text-transform: uppercase;
    color: #262B40;
    padding-bottom: 8px;
}

.home_custom_section_8_body p{
font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: rgba(38, 43, 64, 0.8);

}
.home_custom_section_8_learn_btn {
    padding-top: 22px;
}
.home_custom_section_8_body .more_detail_btn{

    
    background: #AF3742;
    border-radius: 3px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Jost';
    text-decoration: none;
    width: 174px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

@media(max-width:1024px){
.home_custom_section_8_body {
    padding: 0px 35px 0px 0px;
gap: 18px 0px;
}
.home_custom_section_8_heading {
    gap: 5px 0px;
}
.home_custom_section_8 figure {
    height: 450px;
}
.home_custom_section_8_body p {
    padding-bottom: 0px;
}
}
@media(max-width:767px){
.home_custom_section_8_learn_btn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.home_custom_section_8_body {
    justify-content: center;
    text-align: center;
}
.home_custom_section_8{
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.home_custom_section_8 figure {
    height: 300px;
background-position: center;
    background-image: url('https://tossdown.site/images/260c95ebc274873d4c53bb6f2d3f8ab6_1655274995.png');
}
.home_custom_section_8_body {
    padding:0px 25px 40px 25px;
}

}
@media(max-width:540px){
.home_custom_section_8_heading h3 {
    font-size: 36px;
    line-height: 46px;
}
.home_custom_section_8_body {
    padding: 0px 0px 40px 0px;
}
}
@media(max-width:450px){
.home_custom_section_8 figure {
    height: 200px;
background-position: center;
    background-image: url('https://tossdown.site/images/260c95ebc274873d4c53bb6f2d3f8ab6_1655274995.png');
}
}
</style>
<div class="home_custom_section_8_main">
<div class="custom_container">
<div class="home_custom_section_8">
<div class="home_custom_section_8_body">
<div class="home_custom_section_8_heading">

<h3 data-aos-delay="400" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">Who are Ipsums? Get to know us</h3>
</div>
<p data-aos-delay="600" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="aos-init aos-animate">For Lorem IpsumAbout Us: Lorem Ipsum’s idea was pitched in December 2017. It was established in 2019 in a very small scale and in Pandemic it was started on social media. After getting good response on social media for 2 years we opened our first physical outlet in Gulberg 3 Lahore. We are the first one in our field to offer free returns to our customers without question asked. We aim to provide our customers 100% authentic Lorem Ipsum and Under Armour sourced from Lorem Ipsum</p>
<div class="home_custom_section_8_learn_btn"><a data-aos-delay="900" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="more_detail_btn aos-init aos-animate" >Learn More <i class="fal fa-arrow-right arrow" aria-hidden="true"></i></a></div>
</div>
<figure data-aos-delay="200" data-aos-offset="120" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" class="aos-init aos-animate"></figure>
</div>
</div>
</div>

                                                                                                                                                                               
                                                                                                                                                                               `
                                                                                                                                                                                },
                                                                                                                                                                                {
                                                                                                                                                                                    id:"HomeCustomSection48",
                                                                                                                                                                                    name:"HomeCustomSection 49",
                                                                                                                                                                                    height:"800px",
                                                                                                                                                                                    code:` 
                                                                                                                                                                                    <head>
                                                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                                                    <meta
                                                                                                                                                                                      name="viewport"
                                                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                    />
                                                                                                                                                                                  
                                                                                                                                                                                    <link
                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                    />
                                                                                                                                                                                    <link
                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                    />
                                                                                                                                                                                  
                                                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                    <script
                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                    ></script>
                                                                                                                                                                                    <script
                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                    ></script>
                                                                                                                                                                                  
                                                                                                                                                                                    <link
                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                    />
                                                                                                                                                                                    <link
                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                    />
                                                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                   
                                                                                                                                                                                  </head>
                                                                                                                                                                                     <style>
                                                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                    </style>
                                                                                                                                                                                    <style>
                                                                                                                                                                                    * {
                                                                                                                                                                                                  -web-kit-box-sizing: border-box;
                                                                                                                                                                                                  -moz-box-sizing: border-box;
                                                                                                                                                                                                  box-sizing: border-box;
                                                                                                                                                                                                  padding: 0px;
                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                                  font-family: "Poppins", sans-serif;
                                                                                                                                                                                                }
                                                                                                                                                                                                html {
                                                                                                                                                                                                  scroll-behavior: smooth;
                                                                                                                                                                                                }
                                                                                                                                                                                                body {
                                                                                                                                                                                                  float: left;
                                                                                                                                                                                                  width: 100%;
                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                              background-color: #fff;
                                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_section{
                                                                                                                                                                                    display: block;
                                                                                                                                                                                    width: 100%;
                                                                                                                                                                                    margin-bottom: 35px;
                                                                                                                                                                                    padding: 40px 15px 40px 15px;
                                                                                                                                                                                background: url('https://static.tossdown.com/images/dce15475-2cc0-4071-8af1-8be3dd438bb4.png');
                                                                                                                                                                                background-repeat: no-repeat;
                                                                                                                                                                                background-size: cover;
                                                                                                                                                                                    background-position: center;
                                                                                                                                                                                }
                                                                                                                                                                                .custom_container{
                                                                                                                                                                                    max-width: 1140px;
                                                                                                                                                                                    margin: 0px auto;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_box figure{
                                                                                                                                                                                 width: 100%;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_box figure img{
                                                                                                                                                                                 width: 100%;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_box{
                                                                                                                                                                                    display: grid;
                                                                                                                                                                                    grid-template-columns: 0.6fr 1fr;
                                                                                                                                                                                    grid-template-rows: auto;
                                                                                                                                                                                    grid-template-areas: ". .";
                                                                                                                                                                                    gap: 40px 55px;
                                                                                                                                                                                    width: 100%;
                                                                                                                                                                                align-items: center;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption{
                                                                                                                                                                                display: flex;
                                                                                                                                                                                    flex-direction: column;
                                                                                                                                                                                    align-items: flex-start;
                                                                                                                                                                                    justify-content: center;
                                                                                                                                                                                    height: 100%;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption small{
                                                                                                                                                                                font-family: 'Montserrat';
                                                                                                                                                                                font-style: normal;
                                                                                                                                                                                font-weight: 500;
                                                                                                                                                                                font-size: 12px;
                                                                                                                                                                                line-height: 15px;
                                                                                                                                                                                letter-spacing: 0.2em;
                                                                                                                                                                                text-transform: uppercase;
                                                                                                                                                                                color: #B29763;
                                                                                                                                                                                padding-bottom:12px;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption h4 {
                                                                                                                                                                                font-family: 'Merriweather';
                                                                                                                                                                                font-style: normal;
                                                                                                                                                                                font-weight: 400;
                                                                                                                                                                                font-size: 48px;
                                                                                                                                                                                line-height: 52px;
                                                                                                                                                                                text-transform: capitalize;
                                                                                                                                                                                color: #FFFFFF;
                                                                                                                                                                                padding-bottom:16px;
                                                                                                                                                                                margin:0px;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption p{
                                                                                                                                                                                font-family: 'Montserrat';
                                                                                                                                                                                font-style: normal;
                                                                                                                                                                                font-weight: 400;
                                                                                                                                                                                font-size: 14px;
                                                                                                                                                                                line-height: 17px;
                                                                                                                                                                                color: rgba(255, 255, 255, 0.8);
                                                                                                                                                                                padding-bottom:46px;
                                                                                                                                                                                margin:0px;
                                                                                                                                                                                }
                                                                                                                                                                                .application_download_links{
                                                                                                                                                                                    display: flex;
                                                                                                                                                                                    align-items: center;
                                                                                                                                                                                }
                                                                                                                                                                                .application_download_links a {
                                                                                                                                                                                      display: flex;
                                                                                                                                                                                    align-items: center;
                                                                                                                                                                                background: #A8253C;
                                                                                                                                                                                border-radius: 4px;
                                                                                                                                                                                width: 156px;
                                                                                                                                                                                height: 48px;
                                                                                                                                                                                    justify-content: center;
                                                                                                                                                                                font-family: 'Merriweather';
                                                                                                                                                                                font-style: normal;
                                                                                                                                                                                font-weight: 700;
                                                                                                                                                                                font-size: 14px;
                                                                                                                                                                                line-height: 18px;
                                                                                                                                                                                color: #FFFFFF;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_box figure{
                                                                                                                                                                                width:100%;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_box figure img{
                                                                                                                                                                                width:100%;
                                                                                                                                                                                }
                                                                                                                                                                                
                                                                                                                                                                                @media (min-width: 768px) and (max-width: 1024px){
                                                                                                                                                                                .home_page_second_banner_box {
                                                                                                                                                                                    gap: 40px 40px;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption h4 {
                                                                                                                                                                                font-size: 40px;
                                                                                                                                                                                line-height:48px;
                                                                                                                                                                                padding-bottom: 20px;
                                                                                                                                                                                }
                                                                                                                                                                                }
                                                                                                                                                                                @media  (max-width:767px){
                                                                                                                                                                                .home_page_second_banner_box {
                                                                                                                                                                                    grid-template-columns: 1fr;
                                                                                                                                                                                    grid-template-areas: ".";
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_box figure{
                                                                                                                                                                                text-align:center;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption {
                                                                                                                                                                                    align-items: center;
                                                                                                                                                                                    text-align: center;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption p {
                                                                                                                                                                                    padding-bottom: 30px;
                                                                                                                                                                                }
                                                                                                                                                                                @media  (max-width:480px){
                                                                                                                                                                                .home_page_second_banner_figcaption h4 {
                                                                                                                                                                                font-size: 36px;
                                                                                                                                                                                line-height: 48px;
                                                                                                                                                                                }
                                                                                                                                                                                .home_page_second_banner_figcaption {
                                                                                                                                                                                    align-items: center;
                                                                                                                                                                                    text-align: center;
                                                                                                                                                                                }
                                                                                                                                                                                }
                                                                                                                                                                                
                                                                                                                                                                                </style>
                                                                                                                                                                                <section class="home_page_second_banner_section">
                                                                                                                                                                                <div class="custom_container">
                                                                                                                                                                                <div class="home_page_second_banner_box">
                                                                                                                                                                                <div class="home_page_second_banner_figcaption"><small>Sweets shop</small>
                                                                                                                                                                                <h4>Make your own sweets box</h4>
                                                                                                                                                                                <p>Make your customised box based on your choice of sweets</p>
                                                                                                                                                                                <div class="application_download_links"><a ><span></span>Let’s do it!</a></div>
                                                                                                                                                                                </div>
                                                                                                                                                                                <figure><img src="https://static.tossdown.com/images/a3782483-3530-4fe0-9313-d2ba5a096dba.png" /></figure>
                                                                                                                                                                                </div>
                                                                                                                                                                                </div>
                                                                                                                                                                                </section>
    
                                                                                                                                                                                   
                                                                                                                                                                                   `
                                                                                                                                                                                    },

                                                                                                                                                                                    {
                                                                                                                                                                                        id:"HomeCustomSection49",
                                                                                                                                                                                        name:"HomeCustomSection 50",
                                                                                                                                                                                        height:"800px",
                                                                                                                                                                                        code:` 
                                                                                                                                                                                        <head>
                                                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                                                        <meta
                                                                                                                                                                                          name="viewport"
                                                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                        />
                                                                                                                                                                                      
                                                                                                                                                                                        <link
                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                        />
                                                                                                                                                                                        <link
                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                        />
                                                                                                                                                                                      
                                                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                        <script
                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                        ></script>
                                                                                                                                                                                        <script
                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                        ></script>
                                                                                                                                                                                      
                                                                                                                                                                                        <link
                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                        />
                                                                                                                                                                                        <link
                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                        />
                                                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                       
                                                                                                                                                                                      </head>
                                                                                                                                                                                         <style>
                                                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                        </style>
                                                                                                                                                                                        <style>
                                                                                                                                                                                        * {
                                                                                                                                                                                                         -web-kit-box-sizing: border-box;
                                                                                                                                                                                                         -moz-box-sizing: border-box;
                                                                                                                                                                                                         box-sizing: border-box;
                                                                                                                                                                                                         padding: 0px;
                                                                                                                                                                                                         margin: 0px;
                                                                                                                                                                                                         font-family: "Poppins", sans-serif;
                                                                                                                                                                                                       }
                                                                                                                                                                                                       html {
                                                                                                                                                                                                         scroll-behavior: smooth;
                                                                                                                                                                                                       }
                                                                                                                                                                                                       body {
                                                                                                                                                                                                         float: left;
                                                                                                                                                                                                         width: 100%;
                                                                                                                                                                                                         margin: 0px;
                                                                                                                                                                                                     background-color: #fff;
                                                                                                                                                                                                       }
                                                                                                                                                                                        .home_section_six {
                                                                                                                                                                                               display: block;
                                                                                                                                                                                               width: 100%;
                                                                                                                                                                                               padding: 0px 15px 20px 15px;
                                                                                                                                                                                             }
                                                                                                                                                                                             .custom_container {
                                                                                                                                                                                               max-width: 1140px;
                                                                                                                                                                                               margin: 0 auto;
                                                                                                                                                                                             }
                                                                                                                                                                                             .home_section_six_box {
                                                                                                                                                                                               display: grid;
                                                                                                                                                                                               grid-template-columns: 1fr 1fr 1fr;
                                                                                                                                                                                               width: 100%;
                                                                                                                                                                                               grid-template-rows: auto;
                                                                                                                                                                                               grid-template-areas: ". . .";
                                                                                                                                                                                               gap: 30px 30px;
                                                                                                                                                                                             }
                                                                                                                                                                                             .home_section_six_single_box {
                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                           display: flex;
                                                                                                                                                                                           background: #383644;
                                                                                                                                                                                           border-radius: 4px;
                                                                                                                                                                                           flex-direction: column;
                                                                                                                                                                                           height: 100%;
                                                                                                                                                                                             padding: 54px 40px;
                                                                                                                                                                                           position: relative;
                                                                                                                                                                                           text-align: center;
                                                                                                                                                                                             }
                                                                                                                                                                                             .home_section_six_single_box h3 {
                                                                                                                                                                                             font-family: 'Merriweather';
                                                                                                                                                                                       font-style: normal;
                                                                                                                                                                                       font-weight: 400;
                                                                                                                                                                                       font-size: 28px;
                                                                                                                                                                                       line-height: 35px;
                                                                                                                                                                                       text-transform: capitalize;
                                                                                                                                                                                       color: #FFFFFF;
                                                                                                                                                                                               width: 100%;
                                                                                                                                                                                               padding-bottom: 12px;
                                                                                                                                                                                               margin: 0px;
                                                                                                                                                                                             }
                                                                                                                                                                                             .home_section_six_single_box p {
                                                                                                                                                                                           font-family: 'Montserrat';
                                                                                                                                                                                       font-style: normal;
                                                                                                                                                                                       font-weight: 400;
                                                                                                                                                                                       font-size: 14px;
                                                                                                                                                                                       line-height: 17px;
                                                                                                                                                                                       text-align: center;
                                                                                                                                                                                       color: rgba(255, 255, 255, 0.8);
                                                                                                                                                                                               margin: 0px;
                                                                                                                                                                                               padding-bottom: 32px;
                                                                                                                                                                                             }
                                                                                                                                                                                           .home_section_six_single_box .link_btn{
                                                                                                                                                                                       width:100%;
                                                                                                                                                                                       text-align: center;
                                                                                                                                                                                           display: flex;
                                                                                                                                                                                           justify-content: center;
                                                                                                                                                                                       }
                                                                                                                                                                                       
                                                                                                                                                                                             .home_section_six_single_box .link_btn a {
                                                                                                                                                                                             font-family: 'Merriweather';
                                                                                                                                                                                       font-style: normal;
                                                                                                                                                                                       font-weight: 700;
                                                                                                                                                                                       font-size: 14px;
                                                                                                                                                                                       line-height: 18px;
                                                                                                                                                                                       color: #FFFFFF;
                                                                                                                                                                                       background: #A8253C;
                                                                                                                                                                                       border-radius: 4px;
                                                                                                                                                                                           width: 156px;
                                                                                                                                                                                           height: 48px;
                                                                                                                                                                                         display: flex;
                                                                                                                                                                                           align-items: center;
                                                                                                                                                                                           justify-content: center;
                                                                                                                                                                                             }
                                                                                                                                                                                       .home_section_six_single_box_img{
                                                                                                                                                                                           width: 100%;
                                                                                                                                                                                           display: flex;
                                                                                                                                                                                           justify-content: center;
                                                                                                                                                                                       padding-bottom:16px;
                                                                                                                                                                                       }
                                                                                                                                                                                       .home_section_six_absolute_img_one{
                                                                                                                                                                                       position:absolute;
                                                                                                                                                                                       top:0px;
                                                                                                                                                                                       right:0px;
                                                                                                                                                                                       }
                                                                                                                                                                                       .home_section_six_absolute_img_two{
                                                                                                                                                                                       position:absolute;
                                                                                                                                                                                       bottom:0px;
                                                                                                                                                                                       left:0px;
                                                                                                                                                                                       }
                                                                                                                                                                                       
                                                                                                                                                                                             @media (min-width: 768px) and (max-width: 1024px) {
                                                                                                                                                                                          
                                                                                                                                                                                               .home_section_six_single_box{
                                                                                                                                                                                             padding:54px 15px;
                                                                                                                                                                                               }
                                                                                                                                                                                             }
                                                                                                                                                                                       
                                                                                                                                                                                             @media (max-width: 767px) {
                                                                                                                                                                                               .home_section_six_box {
                                                                                                                                                                                                 grid-template-columns: 1fr 1fr;
                                                                                                                                                                                                 grid-template-areas: ". .";
                                                                                                                                                                                               }
                                                                                                                                                                                             }
                                                                                                                                                                                             @media (max-width: 540px) {
                                                                                                                                                                                       
                                                                                                                                                                                               .home_section_six_box {
                                                                                                                                                                                                 grid-template-columns: 1fr;
                                                                                                                                                                                                 grid-template-areas: ".";
                                                                                                                                                                                               }
                                                                                                                                                                                          
                                                                                                                                                                                             }
                                                                                                                                                                                        
                                                                                                                                                                                       
                                                                                                                                                                                       </style>
                                                                                                                                                                                       <section class="home_section_six">
                                                                                                                                                                                       <div class="custom_container">
                                                                                                                                                                                       <div class="home_section_six_box">
                                                                                                                                                                                       <div class="home_section_six_single_box">
                                                                                                                                                                                       <div class="home_section_six_absolute_img_one"><img src="https://static.tossdown.com/images/2dfe408e-6529-44f6-8d21-2276bfde86aa.png" /></div>
                                                                                                                                                                                       <div class="home_section_six_single_box_img"><img src="https://static.tossdown.com/images/225fb80d-4bb1-4ef2-88b9-ff35d4f39bea.png" /></div>
                                                                                                                                                                                       <h3>Wedding</h3>
                                                                                                                                                                                       <p>Discover our bespoke wedding gifting collection. Using our experience of over three decades.</p>
                                                                                                                                                                                       <div class="link_btn"><a >Explore</a></div>
                                                                                                                                                                                       <div class="home_section_six_absolute_img_two"><img src="https://static.tossdown.com/images/3cfdddbb-9be6-4e66-98d5-c0eace509c6d.png" /></div>
                                                                                                                                                                                       </div>
                                                                                                                                                                                       <div class="home_section_six_single_box">
                                                                                                                                                                                       <div class="home_section_six_absolute_img_one"><img src="https://static.tossdown.com/images/2dfe408e-6529-44f6-8d21-2276bfde86aa.png" /></div>
                                                                                                                                                                                       <div class="home_section_six_single_box_img"><img src="https://static.tossdown.com/images/bf768307-76b5-4626-9b48-b7c745178756.png" /></div>
                                                                                                                                                                                       <h3>Corporate</h3>
                                                                                                                                                                                       <p>Sweets Corporate Gifting Program is the ideal choice for seasonal and year-round corporate gifts.</p>
                                                                                                                                                                                       <div class="link_btn"><a >Explore</a></div>
                                                                                                                                                                                       <div class="home_section_six_absolute_img_two"><img src="https://static.tossdown.com/images/3cfdddbb-9be6-4e66-98d5-c0eace509c6d.png" /></div>
                                                                                                                                                                                       </div>
                                                                                                                                                                                       <div class="home_section_six_single_box">
                                                                                                                                                                                       <div class="home_section_six_absolute_img_one"><img src="https://static.tossdown.com/images/2dfe408e-6529-44f6-8d21-2276bfde86aa.png" /></div>
                                                                                                                                                                                       <div class="home_section_six_single_box_img"><img src="https://static.tossdown.com/images/fb48608b-1232-49dd-aa99-4a79f3598665.png" /></div>
                                                                                                                                                                                       <h3>Gifting</h3>
                                                                                                                                                                                       <p>We bring grandeur to celebrations with an elaborate and elegant collection of luxury and bespoke giftings.</p>
                                                                                                                                                                                       <div class="link_btn"><a >Explore</a></div>
                                                                                                                                                                                       <div class="home_section_six_absolute_img_two"><img src="https://static.tossdown.com/images/3cfdddbb-9be6-4e66-98d5-c0eace509c6d.png" /></div>
                                                                                                                                                                                       </div>
                                                                                                                                                                                       </div>
                                                                                                                                                                                       </div>
                                                                                                                                                                                       </section> 
                                                                                                                                                                                       
                                                                                                                                                                                       
                                                                                                                                                                                       `
                                                                                                                                                                                       
                                                                                                                                                                                        },
                                                                                                                                                                        
   
                                                                                                                                                                               
                                                                                                                                                                                        {
                                                                                                                                                                                            id:"HomeCustomSection50",
                                                                                                                                                                                            name:"HomeCustomSection 51",
                                                                                                                                                                                            height:"800px",
                                                                                                                                                                                            code:` 
                                                                                                                                                                                            <head>
                                                                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                                                                            <meta
                                                                                                                                                                                              name="viewport"
                                                                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                            />
                                                                                                                                                                                          
                                                                                                                                                                                            <link
                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                            />
                                                                                                                                                                                            <link
                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                            />
                                                                                                                                                                                          
                                                                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                            <script
                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                            ></script>
                                                                                                                                                                                            <script
                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                            ></script>
                                                                                                                                                                                          
                                                                                                                                                                                            <link
                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                            />
                                                                                                                                                                                            <link
                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                            />
                                                                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                           
                                                                                                                                                                                          </head>
                                                                                                                                                                                             <style>
                                                                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                            </style>
                                                                                                                                                                                            <style>
                                                                                                                                                                                            * {
                                                                                                                                                                                                          -web-kit-box-sizing: border-box;
                                                                                                                                                                                                          -moz-box-sizing: border-box;
                                                                                                                                                                                                          box-sizing: border-box;
                                                                                                                                                                                                          padding: 0px;
                                                                                                                                                                                                          margin: 0px;
                                                                                                                                                                                                          font-family: "Poppins", sans-serif;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        html {
                                                                                                                                                                                                          scroll-behavior: smooth;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        body {
                                                                                                                                                                                                          float: left;
                                                                                                                                                                                                          width: 100%;
                                                                                                                                                                                                          margin: 0px;
                                                                                                                                                                                                      background-color: #fff;
                                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_main{
                                                                                                                                                                                        display:block;
                                                                                                                                                                                        width:100%;
                                                                                                                                                                                        padding:0px 15px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .custom_container{
                                                                                                                                                                                        max-width:1140px;
                                                                                                                                                                                        margin:0 auto;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8{
                                                                                                                                                                                            display: grid;
                                                                                                                                                                                            grid-template-columns: 1fr 1fr;
                                                                                                                                                                                            grid-template-areas: ". .";
                                                                                                                                                                                            grid-template-rows: auto;
                                                                                                                                                                                            gap: 15px 0px;
                                                                                                                                                                                            position: relative;
                                                                                                                                                                                            z-index: 1;
                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                        height:100%;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8 figure {
                                                                                                                                                                                            margin-bottom: 0px;
                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                            height: 550px;
                                                                                                                                                                                           background-image: url('https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png');
                                                                                                                                                                                            background-size: cover;
                                                                                                                                                                                            background-repeat: no-repeat;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8 figure img{
                                                                                                                                                                                            background-size: cover;
                                                                                                                                                                                            background-repeat: no-repeat;
                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                            height: 100%;
                                                                                                                                                                                            background-position: center;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_body{
                                                                                                                                                                                            display: grid;
                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                            gap: 36px 0px;
                                                                                                                                                                                            padding: 50px 0px 0px 80px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_heading{
                                                                                                                                                                                            display: grid;
                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                            gap: 20px 0px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_heading h3{
                                                                                                                                                                                            font-family: 'croisntn';
                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                        font-weight: 400;
                                                                                                                                                                                        font-size: 45px;
                                                                                                                                                                                        line-height: 58px;
                                                                                                                                                                                        text-transform: capitalize;
                                                                                                                                                                                        color: #150000;
                                                                                                                                                                                        position:relative;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_heading h3:before{
                                                                                                                                                                                        position:absolute;
                                                                                                                                                                                        content:"";
                                                                                                                                                                                        bottom:14px;
                                                                                                                                                                                        left:-17px;
                                                                                                                                                                                        background-image:url('https://static.tossdown.com/images/15b087ad-6290-4f85-b3ee-b2849e825627.png');
                                                                                                                                                                                        width:94px;
                                                                                                                                                                                        height:160px;
                                                                                                                                                                                           background-size: cover;
                                                                                                                                                                                            background-repeat: no-repeat;
                                                                                                                                                                                        z-index: -1;
                                                                                                                                                                                        }
                                                                                                                                                                                        
                                                                                                                                                                                        .home_custom_section_8_body p{
                                                                                                                                                                                        font-family: 'Ubuntu';
                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                        font-weight: 400;
                                                                                                                                                                                        font-size: 14px;
                                                                                                                                                                                        line-height: 21px;
                                                                                                                                                                                        color: rgba(0, 0, 0, 0.6);
                                                                                                                                                                                        padding-bottom:10px
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_body .more_detail_btn{
                                                                                                                                                                                        font-family: 'Ubuntu';
                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                            font-weight: 500;
                                                                                                                                                                                            font-size: 14px;
                                                                                                                                                                                            line-height: 16px;
                                                                                                                                                                                            text-transform: uppercase;
                                                                                                                                                                                            color: #000000;
                                                                                                                                                                                            width: 146px;
                                                                                                                                                                                            height: 44px;
                                                                                                                                                                                            display: flex;
                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                            background:  #FCC917;
                                                                                                                                                                                            border-radius: 3px;
                                                                                                                                                                                            position: relative;
                                                                                                                                                                                        }
                                                                                                                                                                                        .more_detail_btn a:before{
                                                                                                                                                                                            position: absolute;
                                                                                                                                                                                            content: "";
                                                                                                                                                                                            background-image: url('https://tossdown.site/images/d317715e88612c0df7cb96898bc745df_1648187267.png');
                                                                                                                                                                                            width: 14px;
                                                                                                                                                                                            height: 22px;
                                                                                                                                                                                            background-size: cover;
                                                                                                                                                                                            background-repeat: no-repeat;
                                                                                                                                                                                            top: -11px;
                                                                                                                                                                                            left: 8px;
                                                                                                                                                                                        }
                                                                                                                                                                                        @media(max-width:1024px){
                                                                                                                                                                                        .home_custom_section_8_body {
                                                                                                                                                                                            padding: 50px 0px 0px 35px;
                                                                                                                                                                                        gap: 18px 0px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_heading {
                                                                                                                                                                                            gap: 5px 0px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8 figure {
                                                                                                                                                                                            height: 450px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_body p {
                                                                                                                                                                                            padding-bottom: 0px;
                                                                                                                                                                                        }
                                                                                                                                                                                        }
                                                                                                                                                                                        @media(max-width:767px){
                                                                                                                                                                                        .home_custom_section_8_learn_btn {
                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                            display: flex;
                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_body {
                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                            text-align: center;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8{
                                                                                                                                                                                            display: flex;
                                                                                                                                                                                            flex-direction: column-reverse;
                                                                                                                                                                                            gap: 0px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8 figure {
                                                                                                                                                                                            height: 300px;
                                                                                                                                                                                        background-position: center;
                                                                                                                                                                                            background-image: url('https://static.tossdown.com/images/bed83b1d-a094-4795-ac1f-9ae9b44e51e4.jpg');
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_body {
                                                                                                                                                                                            padding: 110px 25px 40px 25px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_main {
                                                                                                                                                                                            margin-bottom: 30px;
                                                                                                                                                                                        }
                                                                                                                                                                                        }
                                                                                                                                                                                        @media(max-width:540px){
                                                                                                                                                                                        .home_custom_section_8_heading h3 {
                                                                                                                                                                                            font-size: 36px;
                                                                                                                                                                                            line-height: 46px;
                                                                                                                                                                                        }
                                                                                                                                                                                        .home_custom_section_8_body {
                                                                                                                                                                                            padding: 110px 15px 40px 15px;
                                                                                                                                                                                        }
                                                                                                                                                                                        }
                                                                                                                                                                                        @media(max-width:450px){
                                                                                                                                                                                        .home_custom_section_8 figure {
                                                                                                                                                                                            height: 200px;
                                                                                                                                                                                        background-position: center;
                                                                                                                                                                                            background-image: url('https://static.tossdown.com/images/7875a6bd-224c-49ed-8ab7-b44dc8c45841.jpg');
                                                                                                                                                                                        }
                                                                                                                                                                                        }
                                                                                                                                                                                        </style>
                                                                                                                                                                                        <div class="home_custom_section_8_main">
                                                                                                                                                                                        <div class="custom_container">
                                                                                                                                                                                        <div class="home_custom_section_8">
                                                                                                                                                                                        <figure data-aos-offset="120" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true"></figure>
                                                                                                                                                                                        <div class="home_custom_section_8_body">
                                                                                                                                                                                        <div class="home_custom_section_8_heading">
                                                                                                                                                                                        <h3 data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">Nothing better than Lorem Ipsum grilled chicken</h3>
                                                                                                                                                                                        <div class="home_custom_section_8_under_img"><img src="https://static.tossdown.com/images/f6271232-cba6-4831-a06a-50e19b5ab2a1.png" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" /></div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                        <p data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">A small taste of Peri adds ESSENCE OF AFRICA to the food and makes it magical. The heat of this chilli provokes a sense of excitement and tickles the taste buds beyond imagination.Lorem Ipsum chicken is served with the sauces filled with proteins, necessary for strength and energy.</p>
                                                                                                                                                                                        <div class="home_custom_section_8_learn_btn"><a data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50" class="more_detail_btn" >Learn more</a></div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                           
                                                                                                                                                                                           `
                                                                                                                                                                                            },

                                                                                                                                                                                            {
                                                                                                                                                                                                id:"HomeCustomSection51",
                                                                                                                                                                                                name:"HomeCustomSection 52",
                                                                                                                                                                                                height:"800px",
                                                                                                                                                                                                code:` 
                                                                                                                                                                                                <head>
                                                                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                                                                <meta
                                                                                                                                                                                                  name="viewport"
                                                                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                />
                                                                                                                                                                                              
                                                                                                                                                                                                <link
                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                />
                                                                                                                                                                                                <link
                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                />
                                                                                                                                                                                              
                                                                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                <script
                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                ></script>
                                                                                                                                                                                                <script
                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                ></script>
                                                                                                                                                                                              
                                                                                                                                                                                                <link
                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                />
                                                                                                                                                                                                <link
                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                />
                                                                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                               
                                                                                                                                                                                              </head>
                                                                                                                                                                                                 <style>
                                                                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                </style>
                                                                                                                                                                                                <style>
                                                                                                                                                                                                * {
                                                                                                                                                                                                                 -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                 -moz-box-sizing: border-box;
                                                                                                                                                                                                                 box-sizing: border-box;
                                                                                                                                                                                                                 padding: 0px;
                                                                                                                                                                                                                 margin: 0px;
                                                                                                                                                                                                                 font-family: "Poppins", sans-serif;
                                                                                                                                                                                                               }
                                                                                                                                                                                                               html {
                                                                                                                                                                                                                 scroll-behavior: smooth;
                                                                                                                                                                                                               }
                                                                                                                                                                                                               body {
                                                                                                                                                                                                                 float: left;
                                                                                                                                                                                                                 width: 100%;
                                                                                                                                                                                                                 margin: 0px;
                                                                                                                                                                                                             background-color: #fff;
                                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_content_section {
                                                                                                                                                                                                   position: absolute;
                                                                                                                                                                                                   display: flex;
                                                                                                                                                                                                   max-width: 560px;
                                                                                                                                                                                                   top: 130px;
                                                                                                                                                                                                   right: 80px;
                                                                                                                                                                                                   width: 100%;
                                                                                                                                                                                                   justify-content: center;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_content_box {
                                                                                                                                                                                                   display: flex;
                                                                                                                                                                                                   width: 100%;
                                                                                                                                                                                                   align-items: center;
                                                                                                                                                                                                   justify-content: center;
                                                                                                                                                                                                   text-align: center;
                                                                                                                                                                                                   flex-direction: column;
                                                                                                                                                                                                   max-width: 415px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .banner_container{
                                                                                                                                                                                               max-width:1140px;
                                                                                                                                                                                               margin:0 auto;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_section{
                                                                                                                                                                                                   display: block;
                                                                                                                                                                                                   width: 100%;
                                                                                                                                                                                                   margin-bottom: 70px;
                                                                                                                                                                                               background-repeat: no-repeat;
                                                                                                                                                                                                   background-image: url('https://static.tossdown.com/images/54254780-1973-412b-a067-c20fcbe48b18.jpg');
                                                                                                                                                                                               background-size: cover;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_section figure{
                                                                                                                                                                                               position: relative;
                                                                                                                                                                                                   display: flex;
                                                                                                                                                                                                   justify-content: flex-end;
                                                                                                                                                                                               }
                                                                                                                                                                                               
                                                                                                                                                                                               .home_page_second_banner_header{
                                                                                                                                                                                                   display: flex;
                                                                                                                                                                                                   width: 100%;
                                                                                                                                                                                                   align-items: center;
                                                                                                                                                                                                   justify-content: center;
                                                                                                                                                                                                   text-align: center;
                                                                                                                                                                                                   flex-direction: column;
                                                                                                                                                                                               padding-bottom: 27px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_header h3{
                                                                                                                                                                                                   font-style: normal;
                                                                                                                                                                                                   font-weight: 400;
                                                                                                                                                                                                   font-size: 64px;
                                                                                                                                                                                               line-height: 58px;
                                                                                                                                                                                                   text-align: center;
                                                                                                                                                                                                   text-transform: capitalize;
                                                                                                                                                                                                   color: #000000;
                                                                                                                                                                                                   font-family: 'croisntn';
                                                                                                                                                                                                   padding-bottom: 20px;
                                                                                                                                                                                                   padding-top: 20px
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_des{
                                                                                                                                                                                               padding-bottom: 50px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_des p{
                                                                                                                                                                                               font-family: 'Ubuntu';
                                                                                                                                                                                               font-style: normal;
                                                                                                                                                                                               font-weight: 400;
                                                                                                                                                                                               font-size: 18px;
                                                                                                                                                                                               line-height: 21px;
                                                                                                                                                                                               color: #000000;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_btn a{
                                                                                                                                                                                               background: #FCC917;
                                                                                                                                                                                               border-radius: 3px;
                                                                                                                                                                                                   font-family: 'Ubuntu';
                                                                                                                                                                                                   font-style: normal;
                                                                                                                                                                                                   font-weight: 500;
                                                                                                                                                                                                   font-size: 14px;
                                                                                                                                                                                                   line-height: 16px;
                                                                                                                                                                                                   display: flex;
                                                                                                                                                                                                   align-items: center;
                                                                                                                                                                                                   text-transform: uppercase;
                                                                                                                                                                                                   color: #FFFFFF;
                                                                                                                                                                                                   position: relative;
                                                                                                                                                                                                   width: 146px;
                                                                                                                                                                                                   height: 44px;
                                                                                                                                                                                                   justify-content: center;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_btn a:before{
                                                                                                                                                                                                   position: absolute;
                                                                                                                                                                                                   content: "";
                                                                                                                                                                                                   background-image: url('https://static.tossdown.com/images/375c972c-950a-4244-b34c-8596b5b1e1e5.png');
                                                                                                                                                                                                   width: 14px;
                                                                                                                                                                                                   height: 22px;
                                                                                                                                                                                                   background-size: cover;
                                                                                                                                                                                                   background-repeat: no-repeat;
                                                                                                                                                                                                   top: -11px;
                                                                                                                                                                                                   left: 8px;
                                                                                                                                                                                               }
                                                                                                                                                                                               @media (min-width: 768px) and (max-width: 1024px){
                                                                                                                                                                                               
                                                                                                                                                                                               }
                                                                                                                                                                                               @media  (max-width:767px){
                                                                                                                                                                                               .home_page_second_banner_section {
                                                                                                                                                                                                   background-position: center;
                                                                                                                                                                                               }
                                                                                                                                                                                               .banner_container {
                                                                                                                                                                                                   max-width: 540px;
                                                                                                                                                                                                   margin: 0 auto;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_content_section {
                                                                                                                                                                                                   max-width: 460px;
                                                                                                                                                                                                   top: 50px;
                                                                                                                                                                                                   right: 15px;
                                                                                                                                                                                               }
                                                                                                                                                                                               
                                                                                                                                                                                               .home_page_second_banner_content_box {
                                                                                                                                                                                                   max-width: 235px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_header h3 {
                                                                                                                                                                                                   font-size: 36px;
                                                                                                                                                                                                   line-height: 34px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_des {
                                                                                                                                                                                                   padding-bottom: 20px;
                                                                                                                                                                                               }
                                                                                                                                                                                               }
                                                                                                                                                                                               @media  (max-width:540px){
                                                                                                                                                                                               .banner_container {
                                                                                                                                                                                                   max-width: 320px;
                                                                                                                                                                                                   margin: 0 auto;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_header h3 {
                                                                                                                                                                                                   font-size: 22px;
                                                                                                                                                                                                   line-height: 22px;
                                                                                                                                                                                               padding-bottom: 10px;
                                                                                                                                                                                                   padding-top: 10px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_content_section {
                                                                                                                                                                                                   max-width: 258px;
                                                                                                                                                                                                   top: 25px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_content_section {
                                                                                                                                                                                                   right: 18px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_content_box {
                                                                                                                                                                                                   max-width: 169px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_header {
                                                                                                                                                                                                   padding-bottom: 0px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_des p {
                                                                                                                                                                                                   font-size: 14px;
                                                                                                                                                                                                   line-height: 15px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_des {
                                                                                                                                                                                                   padding-bottom: 12px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .home_page_second_banner_btn a {
                                                                                                                                                                                                   font-size: 13px;
                                                                                                                                                                                                   width: 125px;
                                                                                                                                                                                                   height: 38px;
                                                                                                                                                                                               }
                                                                                                                                                                                               .second_banner_img_two{
                                                                                                                                                                                               display:none;
                                                                                                                                                                                               }
                                                                                                                                                                                               }
                                                                                                                                                                                               
                                                                                                                                                                                               </style>
                                                                                                                                                                                               <section class="home_page_second_banner_section">
                                                                                                                                                                                               <div class="banner_container">
                                                                                                                                                                                               <figure><img src="https://static.tossdown.com/images/5f858488-4ea6-4ec7-8cd5-b078704ed28a.png" />
                                                                                                                                                                                               <div class="home_page_second_banner_content_section">
                                                                                                                                                                                               <div class="home_page_second_banner_content_box" data-aos-offset="80" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true">
                                                                                                                                                                                               <div class="home_page_second_banner_header"><img src="https://static.tossdown.com/images/0093201a-da3b-4d02-9f0b-cd3dc80d6a9d.png" />
                                                                                                                                                                                               <h3>Taste that best, its on time</h3>
                                                                                                                                                                                               <img class="second_banner_img_two" src="https://static.tossdown.com/images/cb3af22e-c372-47f7-89cb-7cf6e9405f95.png" /></div>
                                                                                                                                                                                               <div class="home_page_second_banner_des">
                                                                                                                                                                                               <p>Tasty food delivered to your doorstep.</p>
                                                                                                                                                                                               </div>
                                                                                                                                                                                               <div class="home_page_second_banner_btn"><a>Order now</a></div>
                                                                                                                                                                                               </div>
                                                                                                                                                                                               </div>
                                                                                                                                                                                               </figure>
                                                                                                                                                                                               </div>
                                                                                                                                                                                               </section>
                                                                                                                                                                                               
                                                                                                                                                                                               `
                                                                                                                                                                                                },
                                                                                                                                                                               
                                                                                                                                                                                                {
                                                                                                                                                                                                    id:"HomeCustomSection52",
                                                                                                                                                                                                    name:"HomeCustomSection 53",
                                                                                                                                                                                                    height:"800px",
                                                                                                                                                                                                    code:` 
                                                                                                                                                                                                    <head>
                                                                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                                                                    <meta
                                                                                                                                                                                                      name="viewport"
                                                                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                    />
                                                                                                                                                                                                  
                                                                                                                                                                                                    <link
                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                    />
                                                                                                                                                                                                    <link
                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                    />
                                                                                                                                                                                                  
                                                                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                    <script
                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                    <script
                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                  
                                                                                                                                                                                                    <link
                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                    />
                                                                                                                                                                                                    <link
                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                    />
                                                                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                   
                                                                                                                                                                                                  </head>
                                                                                                                                                                                                     <style>
                                                                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                    </style>
                                                                                                                                                                                                    <style>
                                                                                                                                                                                                    * {
                                                                                                                                                                                                                  -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                  -moz-box-sizing: border-box;
                                                                                                                                                                                                                  box-sizing: border-box;
                                                                                                                                                                                                                  padding: 0px;
                                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                                                  font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                html {
                                                                                                                                                                                                                  scroll-behavior: smooth;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                body {
                                                                                                                                                                                                                  float: left;
                                                                                                                                                                                                                  width: 100%;
                                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                                              background-color: #fff;
                                                                                                                                                                                                                }
                                                                                                                                                                                                .personalized-heading h3 {
                                                                                                                                                                                                    font-family: Outfit;
                                                                                                                                                                                                    font-size: 44px;
                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                    font-weight: 800;
                                                                                                                                                                                                    line-height: 48px;
                                                                                                                                                                                                    letter-spacing: 0em;
                                                                                                                                                                                                    text-align: center;
                                                                                                                                                                                                    margin: 0px;
                                                                                                                                                                                                    padding-bottom: 38px;
                                                                                                                                                                                                }
                                                                                                                                                                                                .personalized-heading a {
                                                                                                                                                                                                    text-transform: uppercase;
                                                                                                                                                                                                    color: white;
                                                                                                                                                                                                    text-decoration: none;
                                                                                                                                                                                                    border-radius: 38px;
                                                                                                                                                                                                    padding: 16px 31px;
                                                                                                                                                                                                    background-color: black;
                                                                                                                                                                                                    display: inline-block;
                                                                                                                                                                                                    font-family: Outfit;
                                                                                                                                                                                                    font-size: 13px;
                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                    font-weight: 500;
                                                                                                                                                                                                    line-height: 16px;
                                                                                                                                                                                                    letter-spacing: 0.07em;
                                                                                                                                                                                                    text-align: left;
                                                                                                                                                                                                    margin-bottom: 48px;
                                                                                                                                                                                                }
                                                                                                                                                                                                
                                                                                                                                                                                                
                                                                                                                                                                                                
                                                                                                                                                                                                </style>
                                                                                                                                                                                                <section style="margin-bottom: 88px;">
                                                                                                                                                                                                <div class="container">
                                                                                                                                                                                                <div class="personalized-heading" style="text-align: center;">
                                                                                                                                                                                                <h3>Personalized Wedding <br />Attires by Hinash</h3>
                                                                                                                                                                                                <a>formal wear</a></div>
                                                                                                                                                                                                <figure style="margin: 0px;"><img src="https://tossdown.site/images/3564c444238b64a69d55fca7a91214b9_1648715412.png" alt="" width="100%" /></figure>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                </section>
                                                                                                                                                                                                   
                                                                                                                                                                                                   `
                                                                                                                                                                                                    },
                                                                                                                                                                                                    {
                                                                                                                                                                                                        id:"HomeCustomSection53",
                                                                                                                                                                                                        name:"HomeCustomSection 54",
                                                                                                                                                                                                        height:"800px",
                                                                                                                                                                                                        code:` 
                                                                                                                                                                                                        <head>
                                                                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                                                                        <meta
                                                                                                                                                                                                          name="viewport"
                                                                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                        />
                                                                                                                                                                                                      
                                                                                                                                                                                                        <link
                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                        />
                                                                                                                                                                                                        <link
                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                        />
                                                                                                                                                                                                      
                                                                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                        <script
                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                        <script
                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                      
                                                                                                                                                                                                        <link
                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                        />
                                                                                                                                                                                                        <link
                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                        />
                                                                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                       
                                                                                                                                                                                                      </head>
                                                                                                                                                                                                         <style>
                                                                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                        </style>
                                                                                                                                                                                                        <style>
                                                                                                                                                                                                        * {
                                                                                                                                                                                                                      -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                      -moz-box-sizing: border-box;
                                                                                                                                                                                                                      box-sizing: border-box;
                                                                                                                                                                                                                      padding: 0px;
                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                      font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    html {
                                                                                                                                                                                                                      scroll-behavior: smooth;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    body {
                                                                                                                                                                                                                      float: left;
                                                                                                                                                                                                                      width: 100%;
                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                  background-color: #fff;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                    
                                                                                                                                                                                                    </style>
                                                                                                                                                                                                    <div class="mask-video"><video width="100%" height="600px" controls="controls">
                                                                                                                                                                                                    <source src="https://shafaqnkami.tossdown.site/34872e89-2304-4d8f-b96f-8693883c57f5.MP4" />
                                                                                                                                                                                                  </video></div>
                                                                                                                                                                                                       
                                                                                                                                                                                                       `
                                                                                                                                                                                                        },

                                                                                                                                                                                                        {
                                                                                                                                                                                                            id:"HomeCustomSection54",
                                                                                                                                                                                                            name:"HomeCustomSection 55",
                                                                                                                                                                                                            height:"800px",
                                                                                                                                                                                                            code:` 
                                                                                                                                                                                                            <head>
                                                                                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                                                                                            <meta
                                                                                                                                                                                                              name="viewport"
                                                                                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                            />
                                                                                                                                                                                                          
                                                                                                                                                                                                            <link
                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                            />
                                                                                                                                                                                                            <link
                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                            />
                                                                                                                                                                                                          
                                                                                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                            <script
                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                            <script
                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                          
                                                                                                                                                                                                            <link
                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                            />
                                                                                                                                                                                                            <link
                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                            />
                                                                                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                           
                                                                                                                                                                                                          </head>
                                                                                                                                                                                                             <style>
                                                                                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                            </style>
                                                                                                                                                                                                            <style>
                                                                                                                                                                                                            * {
                                                                                                                                                                                                                          -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                          -moz-box-sizing: border-box;
                                                                                                                                                                                                                          box-sizing: border-box;
                                                                                                                                                                                                                          padding: 0px;
                                                                                                                                                                                                                          margin: 0px;
                                                                                                                                                                                                                          font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        html {
                                                                                                                                                                                                                          scroll-behavior: smooth;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        body {
                                                                                                                                                                                                                          float: left;
                                                                                                                                                                                                                          width: 100%;
                                                                                                                                                                                                                          margin: 0px;
                                                                                                                                                                                                                      background-color: #fff;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                        @media only screen and (max-width: 394px) {
                                                                                                                                                                                                        .home_custom_section_9_heading h3 {
                                                                                                                                                                                                           
                                                                                                                                                                                                            font-size: 31px;
                                                                                                                                                                                                            
                                                                                                                                                                                                        }
                                                                                                                                                                                                        }
                                                                                                                                                                                                        
                                                                                                                                                                                                        p.category {
                                                                                                                                                                                                            font-family: Raleway;
                                                                                                                                                                                                            font-size: 12px;
                                                                                                                                                                                                            font-weight: 400;
                                                                                                                                                                                                            line-height: 14px;
                                                                                                                                                                                                            letter-spacing: 0.1em;
                                                                                                                                                                                                            text-align: left;
                                                                                                                                                                                                            color: black;
                                                                                                                                                                                                            padding: 10px 0px 0px 0px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .category-sec {
                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_custom_section_9_main{
                                                                                                                                                                                                        display: block;
                                                                                                                                                                                                        width:100%;
                                                                                                                                                                                                        padding:70px 15px;
                                                                                                                                                                                                            margin-bottom: 60px;
                                                                                                                                                                                                            background: #E8F0F4;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .custom_container{
                                                                                                                                                                                                        max-width:1140px;
                                                                                                                                                                                                        margin:0 auto;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_custom_section_9{
                                                                                                                                                                                                            display: grid;
                                                                                                                                                                                                            grid-template-columns: 1fr 1fr;
                                                                                                                                                                                                            grid-template-areas: ". .";
                                                                                                                                                                                                            grid-template-rows: auto;
                                                                                                                                                                                                            gap: 15px 0px;
                                                                                                                                                                                                            position: relative;
                                                                                                                                                                                                            z-index: 1;
                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                        height:100%;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        
                                                                                                                                                                                                        .home_custom_section_9_body{
                                                                                                                                                                                                            display: grid;
                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                            padding: 0px 90px 0px 0px;
                                                                                                                                                                                                        gap:44px 0px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_custom_section_9_heading{
                                                                                                                                                                                                        display: flex;
                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                                            text-align: center;
                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_custom_section_9_heading h3{
                                                                                                                                                                                                            font-family: 'Marcellus';
                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                            font-weight: 400;
                                                                                                                                                                                                            font-size: 40px;
                                                                                                                                                                                                            line-height: 50px;
                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                            text-align: center;
                                                                                                                                                                                                            text-transform: capitalize;
                                                                                                                                                                                                            color: #000000;
                                                                                                                                                                                                        
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_business_hour_section{
                                                                                                                                                                                                            display: grid;
                                                                                                                                                                                                            gap: 24px 0px;
                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_business_hour_section .home_business_hour_body{
                                                                                                                                                                                                        display: flex;
                                                                                                                                                                                                            justify-content: space-between;
                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_business_hour_section .home_business_hour_body span{
                                                                                                                                                                                                            font-family: 'Marcellus';
                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                            font-weight: 400;
                                                                                                                                                                                                            font-size: 16px;
                                                                                                                                                                                                            line-height: 20px;
                                                                                                                                                                                                            color: #212121;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_business_hour_section .home_business_hour_body small{
                                                                                                                                                                                                        flex-grow:5;
                                                                                                                                                                                                        line-height: 0;
                                                                                                                                                                                                         text-align: center;
                                                                                                                                                                                                        position:relative;
                                                                                                                                                                                                            padding: 0px 10px 0px 15px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_business_hour_section .home_business_hour_body small:before{ content: " "; display: block; border-top: 1px dashed rgba(0, 0, 0, 0.16); border-bottom: 1px dashed rgba(0, 0, 0, 0.16);}
                                                                                                                                                                                                        .home_custom_section_9_body .more_detail_btn_two a{
                                                                                                                                                                                                        font-family: 'Ubuntu';
                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                            font-weight: 500;
                                                                                                                                                                                                            font-size: 14px;
                                                                                                                                                                                                            line-height: 16px;
                                                                                                                                                                                                            text-transform: uppercase;
                                                                                                                                                                                                            color: #ffffff;
                                                                                                                                                                                                            width: 156px;
                                                                                                                                                                                                            height: 44px;
                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                            background:  #8859C4;
                                                                                                                                                                                                            border-radius: 3px;
                                                                                                                                                                                                            position: relative;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .more_detail_btn_two {
                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .more_detail_btn_two a:before{
                                                                                                                                                                                                            position: absolute;
                                                                                                                                                                                                            content: "";
                                                                                                                                                                                                            background-image: url('https://tossdown.site/images/1847b2a548388c3073bce37774974838_1648206321.png');
                                                                                                                                                                                                            width: 20px;
                                                                                                                                                                                                            height: 20px;
                                                                                                                                                                                                            background-size: cover;
                                                                                                                                                                                                            background-repeat: no-repeat;
                                                                                                                                                                                                            top: -11px;
                                                                                                                                                                                                            left: 8px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        @media(max-width:1024px){
                                                                                                                                                                                                        .home_custom_section_9_body {
                                                                                                                                                                                                            padding: 0px 30px 0px 0px;
                                                                                                                                                                                                            gap: 35px 0px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        
                                                                                                                                                                                                        }
                                                                                                                                                                                                        @media(max-width:767px){
                                                                                                                                                                                                        .home_custom_section_9{
                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                            gap: 0px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_custom_section_9_body {
                                                                                                                                                                                                            padding: 0px 15px 30px 15px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_location {
                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        .home_location iframe{
                                                                                                                                                                                                        height:300px !important;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        }
                                                                                                                                                                                                        @media(max-width:450px){
                                                                                                                                                                                                        .home_business_hour_section .home_business_hour_body small{
                                                                                                                                                                                                            padding: 0px 10px 0px 10px;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        }
                                                                                                                                                                                                        
                                                                                                                                                                                                        </style>
                                                                                                                                                                                                        <div class="home_custom_section_9_main">
                                                                                                                                                                                                        <div class="custom_container">
                                                                                                                                                                                                        <div class="home_custom_section_9">
                                                                                                                                                                                                        <div class="home_custom_section_9_body">
                                                                                                                                                                                                        <div class="home_custom_section_9_heading aos-init aos-animate" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true" data-aos-offset="50">
                                                                                                                                                                                                        <h3>Snk Monthly Deals</h3>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="home_business_hour_section">
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 1</span><small></small><span>PKR 3999</span></div>
                                                                                                                                                                                                        <p class="category">Hair dye</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 2</span><small></small><span>PKR 2999</span></div>
                                                                                                                                                                                                        <p class="category">Wax</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 3</span><small></small><span>PKR 7999</span></div>
                                                                                                                                                                                                        <p class="category">Streaking</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 4</span><small></small><span>PKR 7999 - 8999</span></div>
                                                                                                                                                                                                        <p class="category">Skincare</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 5</span><small></small><span>PKR 4500</span></div>
                                                                                                                                                                                                        <p class="category">Jensson Facial</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 6</span><small></small><span>PKR 4500</span></div>
                                                                                                                                                                                                        <p class="category">X-Tenso</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 7</span><small></small><span>PKR 4500</span></div>
                                                                                                                                                                                                        <p class="category">Hair protein</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 8</span><small></small><span>PKR 7999 - 8999</span></div>
                                                                                                                                                                                                        <p class="category">Hair Botox</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="category-sec">
                                                                                                                                                                                                        <div class="home_business_hour_body"><span>SnK Deal 9</span><small></small><span>PKR 7999</span></div>
                                                                                                                                                                                                        <p class="category">Gold Facial</p>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div class="home_location aos-init aos-animate" data-aos-offset="90" data-aos="zoom-in" data-aos-mirror="true" data-aos-duration="900" data-aos-once="true">
                                                                                                                                                                                                        <figure style="margin: 0px;"><img src="https://static.tossdown.com/images/3006217c-4c1e-4396-9f7b-5bdd040242e5.png" alt="" width="100%" /></figure>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                           
                                                                                                                                                                                                           `
                                                                                                                                                                                                            },

                                                                                                                                                                                                            {
                                                                                                                                                                                                                id:"HomeCustomSection54",
                                                                                                                                                                                                                name:"HomeCustomSection 55",
                                                                                                                                                                                                                height:"800px",
                                                                                                                                                                                                                code:` 
                                                                                                                                                                                                                <head>
                                                                                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                                                                                <meta
                                                                                                                                                                                                                  name="viewport"
                                                                                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                />
                                                                                                                                                                                                              
                                                                                                                                                                                                                <link
                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                />
                                                                                                                                                                                                                <link
                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                />
                                                                                                                                                                                                              
                                                                                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                <script
                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                <script
                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                              
                                                                                                                                                                                                                <link
                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                />
                                                                                                                                                                                                                <link
                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                />
                                                                                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                               
                                                                                                                                                                                                              </head>
                                                                                                                                                                                                                 <style>
                                                                                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                </style>
                                                                                                                                                                                                                <style>
                                                                                                                                                                                                                * {
                                                                                                                                                                                                                              -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                              -moz-box-sizing: border-box;
                                                                                                                                                                                                                              box-sizing: border-box;
                                                                                                                                                                                                                              padding: 0px;
                                                                                                                                                                                                                              margin: 0px;
                                                                                                                                                                                                                              font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            html {
                                                                                                                                                                                                                              scroll-behavior: smooth;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            body {
                                                                                                                                                                                                                              float: left;
                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                              margin: 0px;
                                                                                                                                                                                                                          background-color: #fff;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                           
                                                                                                                                                                                                                            .home_custom_section_three{
                                                                                                                                                                                                                                display: block;
                                                                                                                                                                                                                                width: 100%;
                                                                                                                                                                                                                                padding: 56px 15px 0px 15px;
                                                                                                                                                                                                                            background-color:#ffffff;
                                                                                                                                                                                                                                margin-bottom: 56px;
                                                                                                                                                                                                                            float:left;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            .custom_container {
                                                                                                                                                                                                                                max-width: 1140px;
                                                                                                                                                                                                                                margin: 0px auto;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            .home_custom_section_three_list {
                                                                                                                                                                                                                              display: grid;
                                                                                                                                                                                                                              grid-template-columns: 1fr 1fr;
                                                                                                                                                                                                                              grid-template-rows: auto;
                                                                                                                                                                                                                              grid-template-areas: ". .";
                                                                                                                                                                                                                              gap: 20px 24px;
                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            .home_custom_section_three_box{
                                                                                                                                                                                                                            width:100%;
                                                                                                                                                                                                                            display:block;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            .home_custom_section_three_box a{
                                                                                                                                                                                                                              position: relative;
                                                                                                                                                                                                                            width:100%;
                                                                                                                                                                                                                            display:block;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            .home_custom_section_three_box a img{
                                                                                                                                                                                                                            width:100%;
                                                                                                                                                                                                                            height:100%;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            @media (max-width: 767px) {
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            @media (max-width: 540px) {
                                                                                                                                                                                                                            .home_custom_section_three_list {
                                                                                                                                                                                                                                grid-template-columns: 1fr;
                                                                                                                                                                                                                                grid-template-areas: ".";
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            @media (max-width: 430px) {
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            
                                                                                                                                                                                                                            </style>
                                                                                                                                                                                                            <section class="home_custom_section_three">
                                                                                                                                                                                                            <div class="custom_container">
                                                                                                                                                                                                            <div class="home_custom_section_three_list">
                                                                                                                                                                                                            <div class="home_custom_section_three_box"><a><img src="https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png"></a></div>
                                                                                                                                                                                                            <div class="home_custom_section_three_box"><a href="https://tazachaiwala.staging.tossdown.site/product/chicken-rolls-195907"><img src="https://static.tossdown.com/images/ddd88a2e-0425-483f-a68c-577c62a90bac.png"></a></div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                            </section>
                                                                                                                                                                                                               
                                                                                                                                                                                                               `
                                                                                                                                                                                                                },

                                                                                                                                                                                                                {
                                                                                                                                                                                                                    id:"HomeCustomSection55",
                                                                                                                                                                                                                    name:"HomeCustomSection 56",
                                                                                                                                                                                                                    height:"800px",
                                                                                                                                                                                                                    code:` 
                                                                                                                                                                                                                    <head>
                                                                                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                                                                                    <meta
                                                                                                                                                                                                                      name="viewport"
                                                                                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                  
                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                  
                                                                                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                  
                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                   
                                                                                                                                                                                                                  </head>
                                                                                                                                                                                                                     <style>
                                                                                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                    </style>
                                                                                                                                                                                                                    <style>
                                                                                                                                                                                                                    * {
                                                                                                                                                                                                                                  -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                                  -moz-box-sizing: border-box;
                                                                                                                                                                                                                                  box-sizing: border-box;
                                                                                                                                                                                                                                  padding: 0px;
                                                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                                                                  font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                html {
                                                                                                                                                                                                                                  scroll-behavior: smooth;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                body {
                                                                                                                                                                                                                                  float: left;
                                                                                                                                                                                                                                  width: 100%;
                                                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                                                              background-color: #fff;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                               
                                                                                                                                                                                                                           
                                                                                                                                                                                                                                .home_page_second_banner_section{
                                                                                                                                                                                                                                    display: block;
                                                                                                                                                                                                                                    width: 100%;
                                                                                                                                                                                                                                    margin-bottom: 70px;
                                                                                                                                                                                                                                float:left;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                .home_page_second_banner_box img{
                                                                                                                                                                                                                                background-repeat: no-repeat;
                                                                                                                                                                                                                                background-size: cover;
                                                                                                                                                                                                                                    width: 100%;
                                                                                                                                                                                                                                height:100%;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                
                                                                                                                                                                                                                                </style>
                                                                                                                                                                                                                                <section class="home_page_second_banner_section">
                                                                                                                                                                                                                                <div class="home_page_second_banner_box"><a><img src="https://images-beta.tossdown.com/site/images/f5ded8dce2b37788d8b86fa3c539eae2_1646825405.png"></a></div>
                                                                                                                                                                                                                                </section>
                                                                                                                                                                                                                   
                                                                                                                                                                                                                   `
                                                                                                                                                                                                                    },

                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        id:"HomeCustomSection56",
                                                                                                                                                                                                                        name:"HomeCustomSection 57",
                                                                                                                                                                                                                        height:"800px",
                                                                                                                                                                                                                        code:` 
                                                                                                                                                                                                                        <head>
                                                                                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                                                                                        <meta
                                                                                                                                                                                                                          name="viewport"
                                                                                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                      
                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                      
                                                                                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                      
                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                       
                                                                                                                                                                                                                      </head>
                                                                                                                                                                                                                         <style>
                                                                                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                        </style>
                                                                                                                                                                                                                        <style>
                                                                                                                                                                                                                        * {
                                                                                                                                                                                                                                      -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                                      -moz-box-sizing: border-box;
                                                                                                                                                                                                                                      box-sizing: border-box;
                                                                                                                                                                                                                                      padding: 0px;
                                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                                      font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    html {
                                                                                                                                                                                                                                      scroll-behavior: smooth;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    body {
                                                                                                                                                                                                                                      float: left;
                                                                                                                                                                                                                                      width: 100%;
                                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                                  background-color: #fff;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                   
                                                                                                                                                                                                                              
                                                                                                                                                                                                                                    .home_page_second_banner_section{
                                                                                                                                                                                                                                        display: block;
                                                                                                                                                                                                                                        width: 100%;
                                                                                                                                                                                                                                        margin-bottom: 50px;
                                                                                                                                                                                                                                        padding: 60px 15px 60px 15px;
                                                                                                                                                                                                                                    background-image: url('https://static.tossdown.com/site/images/d30838f71050647d63e9389d786cf6b5_1641544772.png');
                                                                                                                                                                                                                                    background-repeat: no-repeat;
                                                                                                                                                                                                                                    background-size: cover;
                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .custom_container{
                                                                                                                                                                                                                                        max-width: 1140px;
                                                                                                                                                                                                                                        margin: 0px auto;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_box figure{
                                                                                                                                                                                                                                     width: 100%;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_box figure img{
                                                                                                                                                                                                                                     width: 100%;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_box{
                                                                                                                                                                                                                                    display: grid;
                                                                                                                                                                                                                                        grid-template-columns: 1.2fr 1fr;
                                                                                                                                                                                                                                        grid-template-rows: auto;
                                                                                                                                                                                                                                        grid-template-areas: ". .";
                                                                                                                                                                                                                                        gap: 40px 65px;
                                                                                                                                                                                                                                        width: 100%;
                                                                                                                                                                                                                                    align-items: center;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_figcaption{
                                                                                                                                                                                                                                    display: flex;
                                                                                                                                                                                                                                        flex-direction: column;
                                                                                                                                                                                                                                        align-items: flex-start;
                                                                                                                                                                                                                                        gap: 6px 0px;
                                                                                                                                                                                                                                        justify-content: center;
                                                                                                                                                                                                                                        height: 100%;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_figcaption h4 {
                                                                                                                                                                                                                                    font-family: Merriweather;
                                                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                                                    font-weight: bold !important;
                                                                                                                                                                                                                                    font-size: 56px;
                                                                                                                                                                                                                                    line-height: 66px;
                                                                                                                                                                                                                                    color: #FFFFFF;
                                                                                                                                                                                                                                    margin:0px !important;
                                                                                                                                                                                                                                    padding-bottom:26px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                    .application_download_links{
                                                                                                                                                                                                                                        display: flex;
                                                                                                                                                                                                                                        align-items: center;
                                                                                                                                                                                                                                       gap:0px 12px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .application_download_links a {
                                                                                                                                                                                                                                          display: flex;
                                                                                                                                                                                                                                        align-items: center;
                                                                                                                                                                                                                                    background: #FFFFFF;
                                                                                                                                                                                                                                    border-radius:4px;
                                                                                                                                                                                                                                    width: 136px;
                                                                                                                                                                                                                                    height: 48px;
                                                                                                                                                                                                                                        justify-content: center;
                                                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                                                    font-weight: 500;
                                                                                                                                                                                                                                    font-size: 14px;
                                                                                                                                                                                                                                    line-height: 21px;
                                                                                                                                                                                                                                    color: #000000;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                    @media (min-width: 768px) and (max-width: 1024px){
                                                                                                                                                                                                                                    .home_page_second_banner_box {
                                                                                                                                                                                                                                        gap: 40px 40px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_figcaption h4 {
                                                                                                                                                                                                                                    font-size: 40px;
                                                                                                                                                                                                                                    line-height: 55px;
                                                                                                                                                                                                                                    padding-bottom: 20px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    @media  (max-width:767px){
                                                                                                                                                                                                                                    .home_page_second_banner_box {
                                                                                                                                                                                                                                        grid-template-columns: 1fr;
                                                                                                                                                                                                                                        grid-template-areas: ".";
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_box figure{
                                                                                                                                                                                                                                    text-align:center;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_figcaption {
                                                                                                                                                                                                                                        align-items: center;
                                                                                                                                                                                                                                        text-align: center;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    @media  (max-width:480px){
                                                                                                                                                                                                                                    .home_page_second_banner_figcaption h4 {
                                                                                                                                                                                                                                    font-size: 36px;
                                                                                                                                                                                                                                    line-height: 52px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .home_page_second_banner_figcaption {
                                                                                                                                                                                                                                        align-items: center;
                                                                                                                                                                                                                                        text-align: center;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    </style>
                                                                                                                                                                                                                                    <section class="home_page_second_banner_section">
                                                                                                                                                                                                                                    <div class="custom_container">
                                                                                                                                                                                                                                    <div class="home_page_second_banner_box">
                                                                                                                                                                                                                                    <figure><img src="https://static.tossdown.com/site/images/1cfd4ace14b98f5e24c357505078b313_1641545082.png"></figure>
                                                                                                                                                                                                                                    <div class="home_page_second_banner_figcaption"><small><img src="https://static.tossdown.com/site/images/1f19ad2ac5b38e53c383e0c54e1f759f_1641545364.png"></small>
                                                                                                                                                                                                                                    <h4>Sustainable, Ethically Farm Raised Meat</h4>
                                                                                                                                                                                                                                    <div class="application_download_links"><a>Order Now </a></div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                    </section>
                                                                                                                                                                                                                       
                                                                                                                                                                                                                       `
                                                                                                                                                                                                                        },

                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            id:"HomeCustomSection57",
                                                                                                                                                                                                                            name:"HomeCustomSection 58",
                                                                                                                                                                                                                            height:"800px",
                                                                                                                                                                                                                            code:` 
                                                                                                                                                                                                                            <head>
                                                                                                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                                                                                                            <meta
                                                                                                                                                                                                                              name="viewport"
                                                                                                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                          
                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                          
                                                                                                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                            <script
                                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                                            <script
                                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                                          
                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                           
                                                                                                                                                                                                                          </head>
                                                                                                                                                                                                                             <style>
                                                                                                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                            </style>
                                                                                                                                                                                                                            <style>
                                                                                                                                                                                                                            * {
                                                                                                                                                                                                                                          -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                                          -moz-box-sizing: border-box;
                                                                                                                                                                                                                                          box-sizing: border-box;
                                                                                                                                                                                                                                          padding: 0px;
                                                                                                                                                                                                                                          margin: 0px;
                                                                                                                                                                                                                                          font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        html {
                                                                                                                                                                                                                                          scroll-behavior: smooth;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        body {
                                                                                                                                                                                                                                          float: left;
                                                                                                                                                                                                                                          width: 100%;
                                                                                                                                                                                                                                          margin: 0px;
                                                                                                                                                                                                                                      background-color: #fff;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                        .custom_container{
                                                                                                                                                                                                                                            max-width:1140px;
                                                                                                                                                                                                                                            margin:0px auto;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .product_category_section_seven{
                                                                                                                                                                                                                                        display: block;
                                                                                                                                                                                                                                        width: 100%;
                                                                                                                                                                                                                                        padding: 0px 15px;
                                                                                                                                                                                                                                        margin-bottom: 50px;
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .our_choose_list {
                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                                                            gap: 32px 0px;
                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .our_choose_heading{
                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .our_choose_heading small{
                                                                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                                                                        font-weight: 500;
                                                                                                                                                                                                                                        font-size: 15px;
                                                                                                                                                                                                                                        line-height: 22px;
                                                                                                                                                                                                                                        text-transform: uppercase;
                                                                                                                                                                                                                                        color: #37887D;
                                                                                                                                                                                                                                        position:relative;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .our_choose_heading small:before{
                                                                                                                                                                                                                                        position: absolute;
                                                                                                                                                                                                                                        content: "";
                                                                                                                                                                                                                                        left: -67px;
                                                                                                                                                                                                                                        bottom: 5px;
                                                                                                                                                                                                                                        background-image:url('https://static.tossdown.com/site/images/7d697e14284c11c1e54e68e2a22148ab_1641553849.png');
                                                                                                                                                                                                                                        width: 59px;
                                                                                                                                                                                                                                        height: 10px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .our_choose_heading small:after{
                                                                                                                                                                                                                                            position: absolute;
                                                                                                                                                                                                                                            content: "";
                                                                                                                                                                                                                                            right: -67px;
                                                                                                                                                                                                                                            bottom: 5px;
                                                                                                                                                                                                                                        background-image:url('https://static.tossdown.com/site/images/7d697e14284c11c1e54e68e2a22148ab_1641553849.png');
                                                                                                                                                                                                                                        width: 59px;
                                                                                                                                                                                                                                        height: 10px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        .our_choose_heading h3{
                                                                                                                                                                                                                                        font-family: Merriweather;
                                                                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                                                                        font-weight: bold;
                                                                                                                                                                                                                                        font-size: 36px;
                                                                                                                                                                                                                                        line-height: 64px;
                                                                                                                                                                                                                                        color: #153531;
                                                                                                                                                                                                                                        margin-bottom:0px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_piece_content{
                                                                                                                                                                                                                                        display: flex;
                                                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section{
                                                                                                                                                                                                                                            display: grid;
                                                                                                                                                                                                                                            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                                                                                                                                                                                                                                            grid-template-areas: ". . . . . .";
                                                                                                                                                                                                                                            grid-template-rows: auto;
                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box{
                                                                                                                                                                                                                                        display: flex;
                                                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                                                            gap: 9px 0px;
                                                                                                                                                                                                                                            padding: 30px 5px;
                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                            transition: 0.3s ease-in-out;
                                                                                                                                                                                                                                        border-top: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        border-left: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(n+1){
                                                                                                                                                                                                                                        border-right:0px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:last-child{
                                                                                                                                                                                                                                        border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(even){
                                                                                                                                                                                                                                        border-bottom:3px solid  #37887D;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(odd){
                                                                                                                                                                                                                                        border-bottom:3px solid  #153531;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:hover{
                                                                                                                                                                                                                                            box-shadow: 0px 4px 24px rgb(0 0 0 / 12%);
                                                                                                                                                                                                                                            transition: 0.3s ease-in-out;
                                                                                                                                                                                                                                           border:0px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:hover +  .single_beef_cut_box:nth-child(n+1){
                                                                                                                                                                                                                                            transition: 0.3s ease-in-out;
                                                                                                                                                                                                                                           border-left:0px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box .single_beef_cut_heading span{
                                                                                                                                                                                                                                        font-family: Merriweather;
                                                                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                                                                        font-weight: 900;
                                                                                                                                                                                                                                        font-size: 16px;
                                                                                                                                                                                                                                        line-height: 20px;
                                                                                                                                                                                                                                        color: #153531;
                                                                                                                                                                                                                                        margin:0px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box .single_beef_cut_detail{
                                                                                                                                                                                                                                        display: flex;
                                                                                                                                                                                                                                            gap: 0px 4px;
                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box .single_beef_cut_detail small{
                                                                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                                                                        font-weight: normal;
                                                                                                                                                                                                                                        font-size: 14px;
                                                                                                                                                                                                                                        line-height: 21px;
                                                                                                                                                                                                                                        text-transform: capitalize;
                                                                                                                                                                                                                                        color: #000000;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box .single_beef_cut_detail span{
                                                                                                                                                                                                                                        width: 4px;
                                                                                                                                                                                                                                        height: 4px;
                                                                                                                                                                                                                                        background: #000000;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box i{
                                                                                                                                                                                                                                        color: #153531;
                                                                                                                                                                                                                                        font-size:16px;
                                                                                                                                                                                                                                        padding-top:6px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        @media(max-width:1024px){
                                                                                                                                                                                                                                        .beef_cut_section {
                                                                                                                                                                                                                                            grid-template-columns: 1fr 1fr 1fr;
                                                                                                                                                                                                                                            grid-template-areas: ". . .";
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(3){
                                                                                                                                                                                                                                            border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        @media(max-width:540px){
                                                                                                                                                                                                                                        .our_choose_heading {
                                                                                                                                                                                                                                            gap: 5px 0px;
                                                                                                                                                                                                                                        text-align: center;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .our_choose_heading h3 {
                                                                                                                                                                                                                                            font-size: 32px;
                                                                                                                                                                                                                                            line-height: 40px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section {
                                                                                                                                                                                                                                            grid-template-columns: 1fr 1fr;
                                                                                                                                                                                                                                            grid-template-areas: ". .";
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(3) {
                                                                                                                                                                                                                                            border-right: 0px;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(4) {
                                                                                                                                                                                                                                             border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(2) {
                                                                                                                                                                                                                                             border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        @media(max-width:420px){
                                                                                                                                                                                                                                        .beef_cut_section {
                                                                                                                                                                                                                                            grid-template-columns: 1fr;
                                                                                                                                                                                                                                            grid-template-areas: ".";
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(n+1) {
                                                                                                                                                                                                                                           border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(3) {
                                                                                                                                                                                                                                          border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(2) {
                                                                                                                                                                                                                                          border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        .beef_cut_section .single_beef_cut_box:nth-child(1) {
                                                                                                                                                                                                                                             border-right: 1px solid rgba(0, 0, 0, 0.12);
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        </style>
                                                                                                                                                                                                                                        <div class="product_category_section_seven">
                                                                                                                                                                                                                                        <div class="custom_container">
                                                                                                                                                                                                                                        <div class="our_choose_list">
                                                                                                                                                                                                                                        <div class="our_choose_heading"><small>Butchery</small>
                                                                                                                                                                                                                                        <h3>Beef Cuts of Your Choice</h3>
                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                        <div class="beef_cut_piece_content">
                                                                                                                                                                                                                                        <div class="beef_cut_section"><a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Rump</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Stew</small><span></span><small>Ground Beef</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Sirtlion</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Steaks</small><span></span><small>Tenderloin</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Shortlion</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Tbone</small><span></span><small>Tenderloin</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Rib</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>rib roast</small><span></span><small>rib Steak</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Chuck</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Stew</small><span></span><small>Ground beef</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Round</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Stew</small><span></span><small>Oven roast</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a></div>
                                                                                                                                                                                                                                        <div class="beef_cut_section"><a class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Shank</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Ground Beef</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Flanck</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Steak</small><span></span><small>London broil</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Hanging Tender</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Butcher’s Steak</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Plate</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Ground</small><span></span><small>Short ribs</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Shank</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Ground beef</small><span></span><small>Braised</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a> <a  class="single_beef_cut_box">
                                                                                                                                                                                                                                        <div class="single_beef_cut_heading"><span>Brisket</span></div>
                                                                                                                                                                                                                                        <div class="single_beef_cut_detail"><small>Stew</small><span></span><small>Oven roast</small></div>
                                                                                                                                                                                                                                        <i class="far fa-arrow-right" aria-hidden="true"></i> </a></div>
                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                           
                                                                                                                                                                                                                           `
                                                                                                                                                                                                                            },

                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                id:"HomeCustomSection58",
                                                                                                                                                                                                                                name:"HomeCustomSection 59",
                                                                                                                                                                                                                                height:"800px",
                                                                                                                                                                                                                                code:` 
                                                                                                                                                                                                                                <head>
                                                                                                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                                                                                                <meta
                                                                                                                                                                                                                                  name="viewport"
                                                                                                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                              
                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                              
                                                                                                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                <script
                                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                                <script
                                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                              
                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                               
                                                                                                                                                                                                                              </head>
                                                                                                                                                                                                                                 <style>
                                                                                                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                </style>
                                                                                                                                                                                                                                <style>
    * {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.top-container {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'showcase  top-box-a  '
    'showcase  top-box-b  ';
}
.show-case-container{
    max-width: 1140px;
    margin: 0px auto;
    
}
.showcase {
  
    grid-area: showcase;
    min-height: 400px;
  background-color: #87CCE6;
  
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    /* justify-content: center; */
    /* box-shadow: var(--shadow); */
    border-radius: 10px;
    position: relative;
  
}

p.show-case-para {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #1E2531;
    margin-bottom: 13px!important
    ;
}
h3.show-case-heading {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    display: flex;
    align-items: center;
    color: #1E2531;
    margin: 0px!important;margin-bottom: 18px!important;
}
p.show-case-para2 {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
width: 48%;
}
p.para-circul {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1E2531;
}
.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.circul-box {
    margin-top: 36px;
    width: 102px;
    height: 102px;background-color: 
#F1BE1D;
border-radius: 50%
;
display: flex;
}
.show-case-upper-img{
  background-image: url('https://tossdown.site/images/b220d20010e870704ab4f42fcb4fb199_1656416217.png');
  width: 300px;
  height: 340px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0px;
  right: 0;
  margin: 0px!important;

}
.show-case-arrow-img{
  background-image: url('https://tossdown.site/images/bd762fa13978b39f0c9170cba944ca25_1656416242.png');
  width: 150px;
  height: 50px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 150px;
  right: 40px;
}

.top-box{
  background: var(--primary);
  /* display: grid; */
  display: flex;
  flex-direction: column;
 position: relative;
  padding: 3rem;
  border-radius: 10px;
}
.top-box-a{
    background-color: 
#D7CAFE;
}
.top-box-b{
    background-color: #AEF3B7;
    padding: 7rem 3rem 3rem 3rem!important;
}
.top-box-b::before{
  content: '10 % Off';
  background-color: #F1BE1D;
  position: absolute;
  padding: .5rem;
  font-family: 'Pally';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 24px;
/* identical to box height, or 160% */

display: flex;
align-items: center;
text-align: center;

color: #000000;
top: 0px;
left: 0px;
border-radius: 10px 0px;

}
h4.top-box-a-heading {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    display: flex;
    align-items: center;
    color: #1E2531;
    margin: 0px!important;
} 
p.top-box-a-para {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(30, 37, 49, 0.6);
    margin-bottom: 26px!important;
} 
a.show-case-btn {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    width: 172px;
height: 44px;
background: #1E2531;
border-radius: 22px;
justify-content: center;
text-decoration: none;
}
.top-box-a-upper-img{
  background-image: url('https://tossdown.site/images/058b276433ded0c959ad1e7fe0f5f58f_1656416270.png');
  width: 271px;
  height: 200px;
  background-repeat: no-repeat;
  position: absolute;
top: 0;
right: 0;
margin: 0px!important;
}

h4.top-box-b-heading {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    text-transform: capitalize;
    color: #1E2531;
    margin: 0px!important;
}

h4.top-box-b-heading {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    text-transform: capitalize;
    color: #1E2531;
    margin: 0px!important;
    /* padding-top: 20px; */
}
p.top-box-b-para {
    font-family: 'Pally';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(30, 37, 49, 0.6);
}
.top-box-b-upper-img{
  background-image: url('https://tossdown.site/images/5d98ecbb3930f65ad37f46e957f13af3_1656416324.png');
  width: 200px;
  height:200px;
  background-repeat: no-repeat;
  position: absolute;
top: 0;
right: 0;

margin: 0px!important;
}


@media (max-width: 1024px) {
  .top-container {  
    grid-template-columns: auto!important;
    grid-template-areas:
      'showcase'
      'top-box-a'
      'top-box-b';
  }
}

@media (max-width: 1024px) {
.show-case-upper-img{
  height: 200px;
  width: 200px;
}
.top-box-a-upper-img{
width: 240px;
height: 200px;
}
.top-box-b-upper-img{
  width: 190px;
}
}
  </style>
<section class="custom-show-case-section">
<div class="show-case-container">
<div class="top-container"><header class="showcase">
<p class="show-case-para">TRY OUR NEW</p>
<h3 class="show-case-heading">Chicken <br />Supreme Pizza</h3>
<p class="show-case-para2">Traditionally topped with pepperoni, sausage, bell peppers, onions, and olives, the supreme pizza combines some of the most popular pizza toppings into one delicious slice</p>
<figure class="show-case-upper-img"></figure>
<figure class="show-case-arrow-img"></figure>
<div class="circle">
<div class="circul-box">
<p class="para-circul">CAD 12.99</p>
</div>
</div>
</header>
<div class="top-box-a top-box">
<h4 class="top-box-a-heading">Half n Half</h4>
<p class="top-box-a-para">Choose your favourite 2 pizza varieties on a base of your choice &amp; create a pizza your way.</p>
<a  class="show-case-btn">Buy Now</a>
<figure class="top-box-a-upper-img"></figure>
</div>
<div class="top-box-b top-box">
<h4 c="" class="top-box-b-heading">Get 10 % Discount on Fridays</h4>
<p class="top-box-b-para">This deal is only valid only on Friday</p>
<figure class="top-box-b-upper-img"></figure>
</div>
</div>
</div>
</section>
                                                                                                                                                                                                                               
                                                                                                                                                                                                                               `
                                                                                                                                                                                                                                },

                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                    id:"HomeCustomSection59",
                                                                                                                                                                                                                                    name:"HomeCustomSection 60",
                                                                                                                                                                                                                                    height:"800px",
                                                                                                                                                                                                                                    code:` 
                                                                                                                                                                                                                                    <head>
                                                                                                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                                                                                                    <meta
                                                                                                                                                                                                                                      name="viewport"
                                                                                                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                  </head>
                                                                                                                                                                                                                                     <style>
                                                                                                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                    </style>
                                                                                                                                                                                                                                    <style>
                                                                                                                                                                                                                                    * {
                                                                                                                                                                                                                                                      -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                                                      -moz-box-sizing: border-box;
                                                                                                                                                                                                                                                      box-sizing: border-box;
                                                                                                                                                                                                                                                      padding: 0px;
                                                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                                                      font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                    html {
                                                                                                                                                                                                                                                      scroll-behavior: smooth;
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                    body {
                                                                                                                                                                                                                                                      float: left;
                                                                                                                                                                                                                                                      width: 100%;
                                                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                                                  background-color: #fff;
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .test-contain-hight {
                                                                                                                                                                                                                                        position: relative;
                                                                                                                                                                                                                                        height: 100%;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .custom-section-hold.visions-missions .visions-missions-description-box-hold {
                                                                                                                                                                                                                                        position: absolute;
                                                                                                                                                                                                                                        background: #FFFFFF;
                                                                                                                                                                                                                                        border-radius: 3px;
                                                                                                                                                                                                                                    max-width:455px;
                                                                                                                                                                                                                                        width: 100%;
                                                                                                                                                                                                                                        padding: 30px;
                                                                                                                                                                                                                                        right: 0px;
                                                                                                                                                                                                                                        bottom: 32px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .visions-missions-description-box-hold p {
                                                                                                                                                                                                                                        margin-bottom: 16px;
                                                                                                                                                                                                                                        font-family: 'Kanit';
                                                                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                                                                        font-weight: 300;
                                                                                                                                                                                                                                        font-size: 14px;
                                                                                                                                                                                                                                        line-height: 22px;
                                                                                                                                                                                                                                        color: #000000;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .visions-missions-description-box-hold a {
                                                                                                                                                                                                                                        margin: 26px 0px 24px 0px;
                                                                                                                                                                                                                                        padding: 15px 40px;
                                                                                                                                                                                                                                        display: inline-block;
                                                                                                                                                                                                                                        background: #FB9237;
                                                                                                                                                                                                                                        border-radius: 3px;
                                                                                                                                                                                                                                        font-family: 'Work Sans';
                                                                                                                                                                                                                                        font-style: normal;
                                                                                                                                                                                                                                        font-weight: 500;
                                                                                                                                                                                                                                        font-size: 13px;
                                                                                                                                                                                                                                        line-height: 15px;
                                                                                                                                                                                                                                        text-transform: uppercase;
                                                                                                                                                                                                                                        color: #FFFFFF;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .main-pd-visions-missions {
                                                                                                                                                                                                                                        padding-bottom: 80px;
                                                                                                                                                                                                                                        padding-top: 80px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .content-box-hold-visions-missions .inner-content-h h2 {
                                                                                                                                                                                                                                        font-family: 'Kanit';
                                                                                                                                                                                                                                        font-style: italic;
                                                                                                                                                                                                                                        font-weight: 600 !important;
                                                                                                                                                                                                                                        font-size: 72px;
                                                                                                                                                                                                                                        line-height: 65px;
                                                                                                                                                                                                                                        text-transform: uppercase;
                                                                                                                                                                                                                                        color: #000000;
                                                                                                                                                                                                                                        margin-bottom: 0px;
                                                                                                                                                                                                                                        text-align: center;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .content-box-hold-visions-missions .inner-content-h h2 span {
                                                                                                                                                                                                                                        font-family: 'Kanit';
                                                                                                                                                                                                                                        font-style: italic;
                                                                                                                                                                                                                                        font-weight: 600 !important;
                                                                                                                                                                                                                                        font-size: 72px;
                                                                                                                                                                                                                                        line-height: 62px;
                                                                                                                                                                                                                                        text-transform: uppercase;
                                                                                                                                                                                                                                        margin-bottom: 0px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .content-box-hold-visions-missions {
                                                                                                                                                                                                                                        background-color: #fff;
                                                                                                                                                                                                                                        position: absolute;
                                                                                                                                                                                                                                        left: 0px;
                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                        max-width: 738px;
                                                                                                                                                                                                                                        z-index: 1;
                                                                                                                                                                                                                                        top: -65px;
                                                                                                                                                                                                                                        padding-bottom: 35px;
                                                                                                                                                                                                                                        clip-path: polygon(0 0, 100% 0%, 82% 100%, 0% 100%);
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .custom-section-hold.visions-missions {
                                                                                                                                                                                                                                        height: 690px;
                                                                                                                                                                                                                                        background-size: cover;
                                                                                                                                                                                                                                        background-repeat: no-repeat;
                                                                                                                                                                                                                                        position: relative;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .bg-img-bottom-layer {
                                                                                                                                                                                                                                        margin-top: -5px;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    .bg-img-bottom-layer img {
                                                                                                                                                                                                                                        width: 100%;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    </style>
                                                                                                                                                                                                                                    <div class="main-pd-visions-missions">
                                                                                                                                                                                                                                    <div class="custom-section-hold visions-missions" style="background-image: url('https://tossdown.site/images/354b0857de67c2a0d253364baa516f52_1657013223.jpg');">
                                                                                                                                                                                                                                    <div class="content-box-hold-visions-missions">
                                                                                                                                                                                                                                    <div class="inner-content-h">
                                                                                                                                                                                                                                    <h2>Our Vision <br /><span> &amp; Mission </span></h2>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                    <div class="container test-contain-hight">
                                                                                                                                                                                                                                    <div class="visions-missions-description-box-hold">
                                                                                                                                                                                                                                    <p>To process and market high quality meat at competitive prices using efficient, effective and hygienic systems to the satisfaction of our valued customers.</p>
                                                                                                                                                                                                                                    <p>To be a preferred world-class meat processor through continuous process innovation and investment to deliver safe and consistent quality products to meet and exceed consumer expectations for a sustainable long term relationship.</p>
                                                                                                                                                                                                                                    <a >Learn More</a></div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                    <div class="bg-img-bottom-layer"><img src="https://tossdown.site/images/b6867819d247ffa8278c5cc2628065b6_1657013332.jpg" /></div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                   `
                                                                                                                                                                                                                                    },

                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        id:"HomeCustomSection60",
                                                                                                                                                                                                                                        name:"HomeCustomSection 61",
                                                                                                                                                                                                                                        height:"800px",
                                                                                                                                                                                                                                        code:` 
                                                                                                                                                                                                                                        <head>
                                                                                                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                                                                                                        <meta
                                                                                                                                                                                                                                          name="viewport"
                                                                                                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                      </head>
                                                                                                                                                                                                                                         <style>
                                                                                                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                        </style>
                                                                                                                                                                                                                                        <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.custom-section-hold.process-section {
    padding-bottom: 80px;
}
.custom-section-hold.process-section .container {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 80px;
}
.main-heading-home-p-hold p {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    width: 50%;
    margin: auto;
    margin-bottom: 48px;
}
.process-card-box {
    height: 540px;
    background-size: 100%;
    position: relative;
}
.plus-sign-hold {
    position: absolute;
    color: #ffffff;
    font-weight: 500;
    top: 50%;
    left: 0%;
    right: 0%;
    text-align: center;
}
.plus-sign-hold a {
    background: #FB9237;
    border-radius: 60px;
    color: #000;
    padding: 8px 18px 5px;
    margin-left: 8px;
    font-size: 16px;
}
.process-btn-holds.first-break-btn {
    width: 170px;
}
.process-btn-holds {
    position: absolute;
    color: #ffffff;
    font-weight: 500;
    bottom: 5%;
    left: 0%;
    right: 0%;
    display: flex;
    width: 200px;
    margin: auto;
}
.process-btn-holds a {
    background: #FFFFFF;
    border-radius: 3px;
    padding: 8px 30px;
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin: auto;
    white-space: break-spaces;
}
.process-left-layer {
    position: absolute;
    width: 307.5px;
    height: 95.5px;
    background: #FFFFFF;
    clip-path: polygon(0 0, 100% 0%, 82% 65%, 0% 100%);
}
.process-middle-layer {
    position: absolute;
    width: 316px;
    height: 53.42px;
    background: #FFFFFF;
    clip-path: polygon(0 0, 100% 0%, 82% 100%, 0% 100%);
}
.process-right-layer {
    right: -1px;
    position: absolute;
    width: 307.5px;
    height: 95.5px;
    background: #FFFFFF;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 65%);
}


</style>
<div class="custom-section-hold process-section">
<div class="container">
<div class="row">
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div class="main-heading-home-p-hold">
<h2>Bringing healthy &amp; Taste together</h2>
<p>To be a preferred world-class meat processor through continuous process innovation and investment to deliver safe and consistent quality products to meet and exceed consumer expectations for a sustainable long term relationship.</p>
</div>
</div>
<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
<div class="process-card-box" style="background-image: url('https://tossdown.site/images/3df130ab1e4334ea02dd868e5c5d030b_1657007962.jpg');">
<div class="process-left-layer"></div>
<div class="plus-sign-hold"><a ><i class="fa-solid fa-plus"></i></a></div>
<div class="process-btn-holds first-break-btn"><a >Our Process</a></div>
</div>
</div>
<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
<div class="process-card-box" style="background-image: url('https://tossdown.site/images/d5de4074c9947b0b88bf68412f9435f7_1657008228.jpg');">
<div class="process-middle-layer"></div>
<div class="plus-sign-hold"><a ><i class="fa-solid fa-plus"></i></a></div>
<div class="process-btn-holds"><a >Premium 100+ Meatcuts</a></div>
</div>
</div>
<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
<div class="process-card-box" style="background-image: url('https://tossdown.site/images/4cf5d4b282cf8e8dbfc786ca60611164_1657008244.jpg');">
<div class="process-right-layer"></div>
<div class="plus-sign-hold"><a href="/our-process"><i class="fa-solid fa-plus"></i></a></div>
<div class="process-btn-holds"><a href="/our-process">Hygienic packaging</a></div>
</div>
</div>
</div>
</div>
</div>
                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                       `
                                                                                                                                                                                                                                        },

                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                            id:"HomeCustomSection61",
                                                                                                                                                                                                                                            name:"HomeCustomSection 62",
                                                                                                                                                                                                                                            height:"800px",
                                                                                                                                                                                                                                            code:` 
                                                                                                                                                                                                                                            <head>
                                                                                                                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                                                                                                                            <meta
                                                                                                                                                                                                                                              name="viewport"
                                                                                                                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                            <script
                                                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                                                            <script
                                                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                          </head>
                                                                                                                                                                                                                                             <style>
                                                                                                                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                            </style>
                                                                                                                                                                                                                                           <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.custom-products-steps {
    padding-bottom: 100px;
}
.custom-products-steps .container {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 80px;
}
.products-custom-content-detail {
    position: relative;
    width: 62%;
}
.halal-icons-layer {
    position: absolute;
    right: 0px;
}
.products-custom-content-detail h2 {
    font-family: 'Kanit';
    font-style: italic;
    font-weight: 600 !important;
    font-size: 72px;
    line-height: 85px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
}

.products-custom-content-detail span {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #9D2C28;
    margin-bottom: 35px;
    display: flex;
}
.products-custom-content-detail p {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
}
.products-custom-content-detail::after {
    content: '';
    height: 225px;
    position: absolute;
    top: 25px;
    right: -148px;
    transform: rotate(20deg);
    border-right: 1.5px solid #9D2C28;
}
.products-step-hold ul li .pro-step-h {
    display: flex;
    background: #F7F7F5;
    border: 1px solid rgba(0, 40, 87, 0.05);
    border-radius: 3px;
    margin-bottom: 16px;
    padding: 4px 8px;
}
.products-step-hold ul li .pro-step-h.pro-step-h i.fa.fa-check {
    color: #069B27;
    font-size: 14px;
    font-weight: 800;
    margin: 8px 10px 7px 5px;
}
.products-step-hold ul li .pro-step-h p {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    padding-top: 3px;

</style>
<div class="custom-products-steps">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
<div class="products-custom-content-detail">
<div class="halal-icons-layer"><img src="https://tossdown.site/images/2e1e0883d91c9bcf9c7d8ca111b027ba_1656933035.png" /></div>
<h2>Pride</h2>
<span>In Our Products</span>
<p>Complete traceability along the chain from "Farm to Fork" is guaranteed, with production to the highest standards. We offer a wide portfolio of meat products. Our team of highly skilled experienced staff carefully hand select and trim each cut to exacting standards, to deliver the ultimate eating experience.</p>
</div>
</div>
<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
<div class="products-step-hold">
<ul>
<li>
<div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
<p>Quality control checks</p>
</div>
</li>
<li>
<div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
<p>Monitored optimum storage temperature</p>
</div>
</li>
<li>
<div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
<p>Latest machines and competent staff</p>
</div>
</li>
<li>
<div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
<p>Healthy and hygienic environment</p>
</div>
</li>
<li>
<div class="pro-step-h"><i class="fa fa-check" aria-hidden="true"></i>
<p>Modern packing material</p>
</div>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                           `
                                                                                                                                                                                                                                            },


                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                id:"HomeCustomSection62",
                                                                                                                                                                                                                                                name:"HomeCustomSection 63",
                                                                                                                                                                                                                                                height:"800px",
                                                                                                                                                                                                                                                code:` 
                                                                                                                                                                                                                                                <head>
                                                                                                                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                                                                                                                <meta
                                                                                                                                                                                                                                                  name="viewport"
                                                                                                                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                                <script
                                                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                                                <script
                                                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                              </head>
                                                                                                                                                                                                                                                 <style>
                                                                                                                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                                </style>
                                                                                                                                                                                                                                                <style>
                                                                                                                                                                                                                                                * {
                                                                                                                                                                                                                                                                  -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                                                                  -moz-box-sizing: border-box;
                                                                                                                                                                                                                                                                  box-sizing: border-box;
                                                                                                                                                                                                                                                                  padding: 0px;
                                                                                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                                                                                                  font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                html {
                                                                                                                                                                                                                                                                  scroll-behavior: smooth;
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                body {
                                                                                                                                                                                                                                                                  float: left;
                                                                                                                                                                                                                                                                  width: 100%;
                                                                                                                                                                                                                                                                  margin: 0px;
                                                                                                                                                                                                                                                              background-color: #fff;
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .pd-righ-zero {
                                                                                                                                                                                                                                                    padding-right: 0px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .pd-righ-left {
                                                                                                                                                                                                                                                    padding-left: 0px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .service-box-hold-second {
                                                                                                                                                                                                                                                    display: inline-flex;
                                                                                                                                                                                                                                                    width: 100%;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .service-second-img-hold {
                                                                                                                                                                                                                                                    height: 400px;
                                                                                                                                                                                                                                                    background-repeat: no-repeat;
                                                                                                                                                                                                                                                    position: relative;
                                                                                                                                                                                                                                                    width: 100%;
                                                                                                                                                                                                                                                    background-size: 100%;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-hold a {
                                                                                                                                                                                                                                                    font-family: 'Kanit';
                                                                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                                                                    font-weight: 500;
                                                                                                                                                                                                                                                    font-size: 13px;
                                                                                                                                                                                                                                                    line-height: 19px;
                                                                                                                                                                                                                                                    text-decoration-line: underline;
                                                                                                                                                                                                                                                    text-transform: uppercase;
                                                                                                                                                                                                                                                    color: #9D2C28;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-services {
                                                                                                                                                                                                                                                    position: absolute;
                                                                                                                                                                                                                                                    bottom: 0px;
                                                                                                                                                                                                                                                    padding: 22px 20px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-services ul {
                                                                                                                                                                                                                                                    display: flex;
                                                                                                                                                                                                                                                    width: 100%;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-services ul li.btn-hold a {
                                                                                                                                                                                                                                                    background: #FFFFFF;
                                                                                                                                                                                                                                                    border-radius: 3px;
                                                                                                                                                                                                                                                    padding: 8px 12px;
                                                                                                                                                                                                                                                    font-family: 'Kanit';
                                                                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                                                                    font-weight: 600;
                                                                                                                                                                                                                                                    font-size: 15px;
                                                                                                                                                                                                                                                    line-height: 22px;
                                                                                                                                                                                                                                                    text-transform: uppercase;
                                                                                                                                                                                                                                                    color: #000000;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-services ul li.plus-add-btn a {
                                                                                                                                                                                                                                                    background: #FB9237;
                                                                                                                                                                                                                                                    border-radius: 60px;
                                                                                                                                                                                                                                                    color: #000;
                                                                                                                                                                                                                                                    padding: 11px 18px 8px;
                                                                                                                                                                                                                                                    margin-left: 8px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-services ul li.plus-add-btn a i {
                                                                                                                                                                                                                                                    font-size: 18px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .custom-services-section {
                                                                                                                                                                                                                                                    padding: 50px 0px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .service-first-img-hold {
                                                                                                                                                                                                                                                    height: 400px;
                                                                                                                                                                                                                                                    background-repeat: no-repeat;
                                                                                                                                                                                                                                                    position: relative;
                                                                                                                                                                                                                                                    clip-path: polygon(27% 0, 100% 0, 100% 20%, 100% 80%, 100% 100%, 20% 100%, 0 100%, 0 28%);
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-hold {
                                                                                                                                                                                                                                                    background: #FFFFFF;
                                                                                                                                                                                                                                                    border-radius: 3px;
                                                                                                                                                                                                                                                    position: absolute;
                                                                                                                                                                                                                                                    bottom: 0px;
                                                                                                                                                                                                                                                    margin: 15px;
                                                                                                                                                                                                                                                    padding: 24px;
                                                                                                                                                                                                                                                    display: block;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-hold h6 {
                                                                                                                                                                                                                                                    font-family: 'Kanit';
                                                                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                                                                    font-weight: 500;
                                                                                                                                                                                                                                                    font-size: 13px;
                                                                                                                                                                                                                                                    line-height: 19px;
                                                                                                                                                                                                                                                    text-transform: uppercase;
                                                                                                                                                                                                                                                    color: #000000;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-hold h4 {
                                                                                                                                                                                                                                                    font-family: 'Kanit';
                                                                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                                                                    font-weight: 700;
                                                                                                                                                                                                                                                    font-size: 20px;
                                                                                                                                                                                                                                                    line-height: 24px;
                                                                                                                                                                                                                                                    text-transform: capitalize;
                                                                                                                                                                                                                                                    color: #000000;
                                                                                                                                                                                                                                                    margin-bottom: 12px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                .inner-content-hold p {
                                                                                                                                                                                                                                                    font-family: 'Kanit';
                                                                                                                                                                                                                                                    font-style: normal;
                                                                                                                                                                                                                                                    font-weight: 300;
                                                                                                                                                                                                                                                    font-size: 14px;
                                                                                                                                                                                                                                                    line-height: 21px;
                                                                                                                                                                                                                                                    color: rgba(0, 0, 0, 0.6);
                                                                                                                                                                                                                                                    margin-bottom: 12px;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                </style>
                                                                                                                                                                                                                                                <div class="custom-services-section">
                                                                                                                                                                                                                                                <div class="container">
                                                                                                                                                                                                                                                <div class="row">
                                                                                                                                                                                                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                                                                                                                                                                <div class="main-heading-home-p-hold">
                                                                                                                                                                                                                                                <h2>Our Services</h2>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                <div class="col-lg-5 col-md-5 col-sm-4 col-xs-12 pd-righ-zero">
                                                                                                                                                                                                                                                <div class="service-box-hold">
                                                                                                                                                                                                                                                <div class="service-first-img-hold" style="background-image: url('https://static.tossdown.com/images/a8652c24-0715-4307-9a6a-0b6bf65de9d2.jpg');">
                                                                                                                                                                                                                                                <div class="inner-content-services">
                                                                                                                                                                                                                                                <ul>
                                                                                                                                                                                                                                                <li class="btn-hold"><a href="/services#section_export-01">Zenith Export</a></li>
                                                                                                                                                                                                                                                <li class="plus-add-btn"><a href="/services#section_export-01"><i class="fa-solid fa-plus"></i></a></li>
                                                                                                                                                                                                                                                </ul>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                <div class="col-lg-7 col-md-7 col-sm-8 col-xs-12 pd-righ-left">
                                                                                                                                                                                                                                                <div class="service-box-hold-second">
                                                                                                                                                                                                                                                <div class="service-second-img-hold" style="background-image: url('https://static.tossdown.com/images/298eee5a-9b89-4b52-a67c-df57e406a427.jpg'); margin-left: -1px;">
                                                                                                                                                                                                                                                <div class="inner-content-hold">
                                                                                                                                                                                                                                                <h6>Outlets</h6>
                                                                                                                                                                                                                                                <h4>Modern State of the Art Meat Shops</h4>
                                                                                                                                                                                                                                                <p>Zenith is a leading meat processing company and verified for its quality products by the Hazard analysis and critical....</p>
                                                                                                                                                                                                                                                <h6><a href="/location">Learn More</a></h6>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                <div class="service-second-img-hold" style="background-image: url('https://tossdown.site/images/42c103f3498395f43fd8884e0f02a97a_1656935468.jpg');">
                                                                                                                                                                                                                                                <div class="inner-content-services">
                                                                                                                                                                                                                                                <ul>
                                                                                                                                                                                                                                                <li class="btn-hold"><a href="/services#section_qurbani-00">Zenith Qurbani</a></li>
                                                                                                                                                                                                                                                <li class="plus-add-btn"><a href="/services#section_qurbani-00"><i class="fa-solid fa-plus"></i></a></li>
                                                                                                                                                                                                                                                </ul>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                               `
                                                                                                                                                                                                                                                },

                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                    id:"HomeCustomSection63",
                                                                                                                                                                                                                                                    name:"HomeCustomSection 64",
                                                                                                                                                                                                                                                    height:"800px",
                                                                                                                                                                                                                                                    code:` 
                                                                                                                                                                                                                                                    <head>
                                                                                                                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                                                                                                                    <meta
                                                                                                                                                                                                                                                      name="viewport"
                                                                                                                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                  </head>
                                                                                                                                                                                                                                                     <style>
                                                                                                                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                                    </style>
                                                                                                                                                                                                                                                    <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.pd-righ-zero {
    padding-right: 0px;
}
.pd-righ-left {
    padding-left: 0px;
}
.service-box-hold-second {
    display: inline-flex;
    width: 100%;
}
.service-second-img-hold {
    height: 400px;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    background-size: 100%;
}
.inner-content-hold a {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #9D2C28;
}
.inner-content-services {
    position: absolute;
    bottom: 0px;
    padding: 22px 20px;
}
.inner-content-services ul {
    display: flex;
    width: 100%;
}
.inner-content-services ul li.btn-hold a {
    background: #FFFFFF;
    border-radius: 3px;
    padding: 8px 12px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}
.inner-content-services ul li.plus-add-btn a {
    background: #FB9237;
    border-radius: 60px;
    color: #000;
    padding: 11px 18px 8px;
    margin-left: 8px;
}
.inner-content-services ul li.plus-add-btn a i {
    font-size: 18px;
}
.custom-services-section {
    padding: 50px 0px;
}
.service-first-img-hold {
    height: 400px;
    background-repeat: no-repeat;
    position: relative;
    clip-path: polygon(27% 0, 100% 0, 100% 20%, 100% 80%, 100% 100%, 20% 100%, 0 100%, 0 28%);
}
.inner-content-hold {
    background: #FFFFFF;
    border-radius: 3px;
    position: absolute;
    bottom: 0px;
    margin: 15px;
    padding: 24px;
    display: block;
}
.inner-content-hold h6 {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    color: #000000;
}
.inner-content-hold h4 {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 12px;
}
.inner-content-hold p {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 12px;
}


</style>
<div class="custom-services-section">
<div class="container">
<div class="row">
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div class="main-heading-home-p-hold">
<h2>Our Services</h2>
</div>
</div>
<div class="col-lg-5 col-md-5 col-sm-4 col-xs-12 pd-righ-zero">
<div class="service-box-hold">
<div class="service-first-img-hold" style="background-image: url('https://static.tossdown.com/images/a8652c24-0715-4307-9a6a-0b6bf65de9d2.jpg');">
<div class="inner-content-services">
<ul>
<li class="btn-hold"><a >ipsum Export</a></li>
<li class="plus-add-btn"><a ><i class="fa-solid fa-plus"></i></a></li>
</ul>
</div>
</div>
</div>
</div>
<div class="col-lg-7 col-md-7 col-sm-8 col-xs-12 pd-righ-left">
<div class="service-box-hold-second">
<div class="service-second-img-hold" style="background-image: url('https://static.tossdown.com/images/298eee5a-9b89-4b52-a67c-df57e406a427.jpg'); margin-left: -1px;">
<div class="inner-content-hold">
<h6>Outlets</h6>
<h4>Modern State of the Art Meat Shops</h4>
<p>ipsum is a leading meat processing company and verified for its quality products by the Hazard analysis and critical....</p>
<h6><a >Learn More</a></h6>
</div>
</div>
<div class="service-second-img-hold" style="background-image: url('https://tossdown.site/images/42c103f3498395f43fd8884e0f02a97a_1656935468.jpg');">
<div class="inner-content-services">
<ul>
<li class="btn-hold"><a >abc Qurbani</a></li>
<li class="plus-add-btn"><a ><i class="fa-solid fa-plus"></i></a></li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                   `
                                                                                                                                                                                                                                                    },


                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                        id:"HomeCustomSection64",
                                                                                                                                                                                                                                                        name:"HomeCustomSection 65",
                                                                                                                                                                                                                                                        height:"800px",
                                                                                                                                                                                                                                                        code:` 
                                                                                                                                                                                                                                                        <head>
                                                                                                                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                                                                                                                        <meta
                                                                                                                                                                                                                                                          name="viewport"
                                                                                                                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                                        type="text/javascript"
                                                                                                                                                                                                                                                        src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                      ></script>
                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                      <link
                                                                                                                                                                                                                                                        rel="stylesheet"
                                                                                                                                                                                                                                                        type="text/css"
                                                                                                                                                                                                                                                        href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                      />
                                                                                                                                                                                                                                                      <link
                                                                                                                                                                                                                                                        rel="stylesheet"
                                                                                                                                                                                                                                                        type="text/css"
                                                                                                                                                                                                                                                        href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                      />
                                                                                                                                                                                                                                                      </head>
                                                                                                                                                                                                                                                         <style>
                                                                                                                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                                        </style>
                                                                                                                                                                                                                                                        <style>
* {
                  -web-kit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0px;
                  margin: 0px;
                  font-family: "Poppins", sans-serif;
                }
                html {
                  scroll-behavior: smooth;
                }
                body {
                  float: left;
                  width: 100%;
                  margin: 0px;
              background-color: #fff;
                }
.delivery-section-hold {
    padding: 105px 0px;
}
.delivery-bg-colors{
    background: linear-gradient(90deg, #40A899 52.89%, #92ECC6 100%);
    border-radius: 30px;
}
.delivery-bg-img-holds {
    background-image: url(https://tossdown.site/images/4b09a7601ce7c291fb36d1835768c3f6_1653294552.png);
    background-size: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}
.pattern-img {
    position: absolute;
    z-index: 0;
    top: 60px;
    left: -19px;
}
.delivery-img-holds {
    z-index: 11;
    text-align: right;
    position: relative;
    margin-bottom: -1px;
}
.delivery-content-hold ul li {
    display: inline-block;
    margin: 0px 5px;
}
.delivery-content-hold {
    margin-left: 35px;
}
.delivery-content-hold h3 {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #FFFFFF;
    margin-bottom: 16px;
    width: 80%;
}
.delivery-content-hold p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    letter-spacing: 0.5px;
    margin-bottom: 40px;
    width: 95%;
}
.delivery-content-hold ul li a img {
    display: list-item;
    width: 152px;
    height: 52px;
}

</style>
<div class="delivery-section-hold">
<div class="container delivery-bg-colors">
<div class="row delivery-bg-img-holds">
<div class="col-lg-5 col-md-5 col-sm-6 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
<div class="delivery-content-hold">
<h3>Our Crew is One-Click Away!</h3>
<p>Pin your location and we’ll be right there as per your convenient schedule.</p>
<ul>
<li><a ><img src="https://tossdown.site/images/b045d7cf46b00a20a054e459faaa1622_1653489124.png" /></a></li>
<li><a ><img src="https://tossdown.site/images/4145f7556c74fced4ed01cd3bde9232d_1653489160.png" /></a></li>
</ul>
</div>
</div>
<div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
<div class="pattern-img" data-aos="fade-right" data-aos-duration="900" data-aos-delay="200" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"><img src="https://tossdown.site/images/abdb7cd7f4e4fbe894b3c5095324d9db_1653294423.png" /></div>
<div class="delivery-img-holds" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true"><img src="https://tossdown.site/images/6b83673b9ce504c7aa8bc97eec690c0c_1653294363.png" /></div>
</div>
</div>
</div>
</div>
                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                       `
                                                                                                                                                                                                                                                        },

                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                            id:"HomeCustomSection65",
                                                                                                                                                                                                                                                            name:"HomeCustomSection 66",
                                                                                                                                                                                                                                                            height:"800px",
                                                                                                                                                                                                                                                            code:` 
                                                                                                                                                                                                                                                            <head>
                                                                                                                                                                                                                                                            <meta charset="utf-8" />
                                                                                                                                                                                                                                                            <meta
                                                                                                                                                                                                                                                              name="viewport"
                                                                                                                                                                                                                                                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                                              href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                                              integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                                              href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                                              integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                                              crossorigin="anonymous"
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                                            <script
                                                                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                                                                              src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                                                                            <script
                                                                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                            <script
                                                                                                                                                                                                                                                            type="text/javascript"
                                                                                                                                                                                                                                                            src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                          ></script>
                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                          <link
                                                                                                                                                                                                                                                            rel="stylesheet"
                                                                                                                                                                                                                                                            type="text/css"
                                                                                                                                                                                                                                                            href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                          />
                                                                                                                                                                                                                                                          <link
                                                                                                                                                                                                                                                            rel="stylesheet"
                                                                                                                                                                                                                                                            type="text/css"
                                                                                                                                                                                                                                                            href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                          />
                                                                                                                                                                                                                                                          </head>
                                                                                                                                                                                                                                                             <style>
                                                                                                                                                                                                                                                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                                            </style>
                                                                                                                                                                                                                                                            <style>
                                                                                                                                                                                                                                                            * {
                                                                                                                                                                                                                                                                              -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                                                                              -moz-box-sizing: border-box;
                                                                                                                                                                                                                                                                              box-sizing: border-box;
                                                                                                                                                                                                                                                                              padding: 0px;
                                                                                                                                                                                                                                                                              margin: 0px;
                                                                                                                                                                                                                                                                              font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                            html {
                                                                                                                                                                                                                                                                              scroll-behavior: smooth;
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                            body {
                                                                                                                                                                                                                                                                              float: left;
                                                                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                                                                              margin: 0px;
                                                                                                                                                                                                                                                                          background-color: #fff;
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .home-page-main-heading {
                                                                                                                                                                                                                                                                text-align: center;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .head-pd-bottom {
                                                                                                                                                                                                                                                                padding-bottom: 20px;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .home-page-main-heading p {
                                                                                                                                                                                                                                                                font-family: 'Manrope';
                                                                                                                                                                                                                                                                font-style: normal;
                                                                                                                                                                                                                                                                font-weight: 600;
                                                                                                                                                                                                                                                                font-size: 14px;
                                                                                                                                                                                                                                                                line-height: 24px;
                                                                                                                                                                                                                                                                letter-spacing: 0.5px;
                                                                                                                                                                                                                                                                color: #71717A;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .bg-colors-hold-section {
                                                                                                                                                                                                                                                                background: #F8F6F4;
                                                                                                                                                                                                                                                                padding: 88px 0px 100px;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .choose-reason-section {
                                                                                                                                                                                                                                                                text-align: center;
                                                                                                                                                                                                                                                                display: block;
                                                                                                                                                                                                                                                                margin-bottom: 60px;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .choose-reason-section img {
                                                                                                                                                                                                                                                                background: #FFFFFF;
                                                                                                                                                                                                                                                                box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
                                                                                                                                                                                                                                                                padding: 14px;
                                                                                                                                                                                                                                                                border-radius: 50%;
                                                                                                                                                                                                                                                                margin-bottom: 24px;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .choose-reason-section span {
                                                                                                                                                                                                                                                                display: block;
                                                                                                                                                                                                                                                                font-family: 'Epilogue';
                                                                                                                                                                                                                                                                font-style: normal;
                                                                                                                                                                                                                                                                font-weight: 600;
                                                                                                                                                                                                                                                                font-size: 16px;
                                                                                                                                                                                                                                                                line-height: 16px;
                                                                                                                                                                                                                                                                color: #000000;
                                                                                                                                                                                                                                                                margin-bottom: 13px;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            .choose-reason-section p {
                                                                                                                                                                                                                                                                font-family: 'Manrope';
                                                                                                                                                                                                                                                                font-style: normal;
                                                                                                                                                                                                                                                                font-weight: 600;
                                                                                                                                                                                                                                                                font-size: 14px;
                                                                                                                                                                                                                                                                line-height: 20px;
                                                                                                                                                                                                                                                                text-align: center;
                                                                                                                                                                                                                                                                color: #71717A;
                                                                                                                                                                                                                                                                margin: 0px 15px;
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            </style>
                                                                                                                                                                                                                                                            <div class="bg-colors-hold-section" id="why-cawa">
                                                                                                                                                                                                                                                            <div class="container">
                                                                                                                                                                                                                                                            <div class="row">
                                                                                                                                                                                                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="home-page-main-heading head-pd-bottom">
                                                                                                                                                                                                                                                            <h2><strong>Reasons to Choose Lorem ipsum</strong></h2>
                                                                                                                                                                                                                                                            <p>A plenty reasons to choose Lorem ipsum as your wash partner,have a detailed look.</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/f75290520f7ae6e0a8f38b4c86a1163a_1653291249.png" /> <span>Mobile App</span>
                                                                                                                                                                                                                                                            <p>Cutting-edge mobile app experience</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/c715d93aa2929512bbd7bcb8d4de5694_1653291737.png" /> <span>Unique Technique</span>
                                                                                                                                                                                                                                                            <p>Unique washing techniques: spray, wipe, buff and done.</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/73eed977eddcdb8a5be089c1608f458a_1653291774.png" /> <span>Skilled Crew</span>
                                                                                                                                                                                                                                                            <p>Well skilled internationally trained crew.</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/97b9360ba3591b9f96861a66bf0bca9b_1653291796.png" /> <span>Do It Any Where</span>
                                                                                                                                                                                                                                                            <p>We can do it at your home, office, gym or shopping mall.</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/6ca1bf5ba1e6052dc479cbc2b06141c1_1653291835.png" /> <span>250ml Water</span>
                                                                                                                                                                                                                                                            <p>250ml (for waterless wash) vs 90 liters (for traditional wash)</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/ec870944809fcca9a543533f1a24c06a_1653291883.png" /> <span>Pocket Solution</span>
                                                                                                                                                                                                                                                            <p>Pocket/environment-friendly car wash solution.</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/d0f52f3739215d3d16d0805bc5d3fbef_1653291915.png" /> <span>Smooth Process</span>
                                                                                                                                                                                                                                                            <p>Smooth booking &amp; payment process (online)</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="choose-reason-section"><img src="https://tossdown.site/images/25334335000773baabe4e0b32465758f_1653291939.png" /> <span>Quality Products</span>
                                                                                                                                                                                                                                                            <p>Certified International quality standard products from Europe</p>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-duration="900" data-aos-delay="50" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
                                                                                                                                                                                                                                                            <div class="content-hold"><a >Book Now <i class="fa-solid fa-arrow-right" style="margin-left: 10px;"></i></a></div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                           `
                                                                                                                                                                                                                                                            },

                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                id:"HomeCustomSection66",
                                                                                                                                                                                                                                                                name:"HomeCustomSection 67",
                                                                                                                                                                                                                                                                height:"800px",
                                                                                                                                                                                                                                                                code:` 
                                                                                                                                                                                                                                                                <head>
                                                                                                                                                                                                                                                                <meta charset="utf-8" />
                                                                                                                                                                                                                                                                <meta
                                                                                                                                                                                                                                                                  name="viewport"
                                                                                                                                                                                                                                                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                                  href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                                                  integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                                  href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                                                  integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                                                  crossorigin="anonymous"
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                                                <script
                                                                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                                                                  src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                                                                <script
                                                                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                                                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                <script
                                                                                                                                                                                                                                                                type="text/javascript"
                                                                                                                                                                                                                                                                src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                              ></script>
                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                              <link
                                                                                                                                                                                                                                                                rel="stylesheet"
                                                                                                                                                                                                                                                                type="text/css"
                                                                                                                                                                                                                                                                href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                              />
                                                                                                                                                                                                                                                              <link
                                                                                                                                                                                                                                                                rel="stylesheet"
                                                                                                                                                                                                                                                                type="text/css"
                                                                                                                                                                                                                                                                href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                              />
                                                                                                                                                                                                                                                              <script
                                                                                                                                                                                                                                                              type="text/javascript"
                                                                                                                                                                                                                                                              src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                            ></script>
                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            <link
                                                                                                                                                                                                                                                              rel="stylesheet"
                                                                                                                                                                                                                                                              type="text/css"
                                                                                                                                                                                                                                                              href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                              </head>
                                                                                                                                                                                                                                                                 <style>
                                                                                                                                                                                                                                                                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                                                </style>
                                                                                                                                                                                                                                                                <style>
  * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-family: "Poppins", sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    width: 100%;
    margin: 0px;
    background-color: #fff;
  }
  .custom_container {
    max-width: 1200px;
    margin: 0px auto;
  }
  .degital_marketing_home_section_one {
    display: block;
    width: 100%;
    margin-bottom: 45px;
    padding: 0px 15px;
    padding-top: 70px;
  }
  .marketing_home_section_one_content_box {
    display: block;
    width: 100%;
    position: relative;
  }
  .marketing_home_section_one_content_box figure {
    position: absolute;
    content: "";
    right: 0px;
    top: 0px;
    margin: 0px !important;
    width: 70px;
  }
  .marketing_home_section_one_content_box figure img {
    width: 100%;
  }
  .marketing_home_section_one_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px 0px;
    text-align: center;
    width: 100%;
  }
  .marketing_home_section_one_content h2 {
    font-style: normal;
    font-weight: bold !important;
    font-size: 74px;
    line-height: 74px;
    text-align: center;
    color: #000000;
    margin: 0px !important;
    width: 75%;
  }
  .marketing_home_section_one_content p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 15px;
  }

  .marketing_home_section_one_content .inner_detail_content {
    color: #ed2a3b !important;
    margin: 0px !important;
    display: block;
  }
  .marketing_home_section_one_content a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff !important;
    text-decoration: none;
    background: #000000;
    border-radius: 60px;
    width: 156px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .marketing_home_section_one_content a:hover {
    text-decoration: none;
  }
  .heading_dot_color {
    color: #ed2a3b !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .marketing_home_section_one_content h2 {
      font-size: 60px;
      line-height: 62px;
    }
  }
  @media (max-width: 767px) {
    .marketing_home_section_one_content h2 {
      font-size: 60px;
      line-height: 62px;
    }
    .marketing_home_section_one_content_box figure {
      width: 50px;
    }
  }
  @media (max-width: 540px) {
    .marketing_home_section_one_content_box figure {
      width: 40px;
      right: -10px;
    }
    .marketing_home_section_one_content h2 {
      font-size: 40px;
      line-height: 44px;
    }
  }
  @media (max-width: 370px) {
    .marketing_home_section_one_content h2 {
      font-size: 36px;
      line-height: 39px;
      width: 80%;
    }
  }

     .degital_marketing_home_section_seven {
      display: block;
      width: 100%;
      padding: 0px 15px;
      margin-bottom: 70px;
    }
    .custom_video_container {
      max-width: 800px;
      margin: 0 auto;
    }
    .video_section_thumbnail {
      display: block;
      width: 100%;
     position: relative;
    }
    .thumbnail_custom_extra_image {
        position: absolute;
        top: -35px;
        left: -28px;
        z-index: -6;
        content: "";
        background-image: url('https://tossdown.site/images/d4d21f1aa921686ac65e37047303f9ab_1639744477.png');
        width: 95px;
        height: 95px;
        background-size: cover;
    }
    .video_section_thumbnail figure {
      display: block;
      width: 100%;
      background: linear-gradient(
        180deg,
        #ebe9e9 0%,
        #f9f9f9 17.64%,
        #f5f5f5 79.62%,
        #ebe9e9 100%
      );
      box-shadow: inset 0px 5.11455px 80px rgba(0, 0, 0, 0.1);
      border-radius: 33.2446px;
      padding: 40px;
      filter: drop-shadow(0px 4px 194px rgba(0, 0, 0, 0.16));
      position: relative;
      margin: 0px !important;
    }
    .video_section_thumbnail figure img {
      width: 100%;
      background-size: cover;
    }
    .thumbnail_play_btn {
      position: absolute;
      content: "";
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      top: 0px;
      left: 0px;
    }
    .thumbnail_play_btn a {
      width: 77.72px;
      height: 77.72px;
      background: #ffffff;
      box-shadow: 0px 5.86554px 35.1932px rgba(0, 0, 0, 0.25);
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }
    .thumbnail_play_btn a i {
      color: #ed2a3b;
      font-size: 24px;
    }
    

    .home_banner_slider-dots{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        bottom: -36px;
    }
     .home_banner_slider-dots ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
    }
      .home_banner_slider-dots ul li {
              position: relative;
              display: inline-block;
             width: 20px;
             height: 20px;
             margin: 0 5px;
             padding: 0;
             cursor: pointer;
    }
               .home_banner_slider-dots ul li button {
                 font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
                }
     .home_banner_slider-dots ul li button:before {
      font-family: 'slick';
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        content: '•';
        text-align: center;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
      border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 50%;
    }
     .home_banner_slider-dots ul li.slick-active button:before{
                      background: #ED2A3B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-color: #ED2A3B;
                   }
    
    .home_banner_slider_next_arrow{
      right: -10px !important;
    }
    .home_banner_slider_prev_arrow {
     left: -10px !important;
    }
</style>

<div class="degital_marketing_home_section_one">
  <div class="custom_container">
    <div class="marketing_home_section_one_content_box">
      <figure>
        <img
          class="lazy"
          data-src="https://static.tossdown.com/site/images/46ccf08cb3e474685fea354f3d5fac23_1639636453.png"
          src="https://static.tossdown.com/site/images/46ccf08cb3e474685fea354f3d5fac23_1639636453.png"
        />
      </figure>
      <div class="marketing_home_section_one_content">
        <h2>We Have Got You Covered<span class="heading_dot_color">.</span></h2>
        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
          <span class="inner_detail_content"
            >helping businesses grow online sales</span
          >
        </p>
        <a>Get A Quote</a>
      </div>
    </div>
  </div>
</div>
<div class="degital_marketing_home_section_seven">
    <div class="custom_video_container">
    <div class="video_section_thumbnail">
    <figure class="home_banner_slider">
     <img   src="https://tossdown.site/images/6d4ac1c651f1d7264a64bd7e27f127dc_1642423934.png" />
     <img  src="https://tossdown.site/images/bf5778decbde2e744f51b8f3f22bc405_1642424029.png" />
      <img src="https://tossdown.site/images/c952c54cc951b17a72f1a4fd7defc6d0_1642424084.png" /> 
      <img  src="https://tossdown.site/images/2beba302aecf08a86417233b38ec9000_1642424176.png" /> 
      <img  src="https://tossdown.site/images/2a54d99aef4caeb88aa3dbeef6d928ee_1642424260.png" /> 
      </figure>
    <div class="home_banner_slider-dots"></div>
    <div class="thumbnail_custom_extra_image"></div>
    </div>
   </div>
    </div>
    <script>
    $(document).ready(function() {
    
    $('.home_banner_slider').slick({
        slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true,
      autoplaySpeed: 1000,
      dots: true,
     appendDots: $('.home_banner_slider-dots'),
       fade: true,
      cssEase: 'linear',
      arrows: false,
      prevArrow: "<button class='slick-prev home_banner_slider_prev_arrow'><i class='mdi mdi-chevron-left'></i></button>",
        nextArrow: "<button class='slick-next home_banner_slider_next_arrow'><i class='mdi mdi-chevron-right'></i></button>",
     
      });
    });
      </script>

                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                               `
                                                                                                                                                                                                                                                                },

                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                    id:"HomeCustomSection67",
                                                                                                                                                                                                                                                                    name:"HomeCustomSection 68",
                                                                                                                                                                                                                                                                    height:"800px",
                                                                                                                                                                                                                                                                    code:` 
                                                                                                                                                                                                                                                                    <head>
                                                                                                                                                                                                                                                                    <meta charset="utf-8" />
                                                                                                                                                                                                                                                                    <meta
                                                                                                                                                                                                                                                                      name="viewport"
                                                                                                                                                                                                                                                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                                      href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                                                      integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                                      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                                                      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                                                      crossorigin="anonymous"
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                                                                      src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                    <script
                                                                                                                                                                                                                                                                    type="text/javascript"
                                                                                                                                                                                                                                                                    src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                                  ></script>
                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                  <link
                                                                                                                                                                                                                                                                    rel="stylesheet"
                                                                                                                                                                                                                                                                    type="text/css"
                                                                                                                                                                                                                                                                    href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                                  />
                                                                                                                                                                                                                                                                  <link
                                                                                                                                                                                                                                                                    rel="stylesheet"
                                                                                                                                                                                                                                                                    type="text/css"
                                                                                                                                                                                                                                                                    href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                                  />
                                                                                                                                                                                                                                                                  <script
                                                                                                                                                                                                                                                                  type="text/javascript"
                                                                                                                                                                                                                                                                  src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                                ></script>
                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                <link
                                                                                                                                                                                                                                                                  rel="stylesheet"
                                                                                                                                                                                                                                                                  type="text/css"
                                                                                                                                                                                                                                                                  href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                  </head>
                                                                                                                                                                                                                                                                     <style>
                                                                                                                                                                                                                                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                                                    </style>
                                                                                                                                                                                                                                                                    <style>
                                                                                                                                                                                                                                                                    * {
                                                                                                                                                                                                                                                                      -web-kit-box-sizing: border-box;
                                                                                                                                                                                                                                                                      -moz-box-sizing: border-box;
                                                                                                                                                                                                                                                                      box-sizing: border-box;
                                                                                                                                                                                                                                                                      padding: 0px;
                                                                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                                                                      font-family: "Poppins", sans-serif;
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                    html {
                                                                                                                                                                                                                                                                      scroll-behavior: smooth;
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                    body {
                                                                                                                                                                                                                                                                      float: left;
                                                                                                                                                                                                                                                                      width: 100%;
                                                                                                                                                                                                                                                                      margin: 0px;
                                                                                                                                                                                                                                                                      background-color: #fff;
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                    .custom_container {
                                                                                                                                                                                                                                                                      max-width: 1200px;
                                                                                                                                                                                                                                                                      margin: 0px auto;
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                        .degital_marketing_home_section_three {
                                                                                                                                                                                                                                                                            display: block;
                                                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                                                            margin-bottom: 70px;
                                                                                                                                                                                                                                                                            padding: 0px 15px;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .marketing_home_section_three_container {
                                                                                                                                                                                                                                                                            display: grid;
                                                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                                                            grid-template-columns: 1fr 1fr;
                                                                                                                                                                                                                                                                            grid-template-areas: ". .";
                                                                                                                                                                                                                                                                            grid-template-rows: auto;
                                                                                                                                                                                                                                                                            gap: 20px 20px;
                                                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                          .marketing_home_section_three_left_content_box {
                                                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                                                                                            gap: 15px 0px;
                                                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .marketing_home_section_three_left_content_box small {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: 600;
                                                                                                                                                                                                                                                                            font-size: 16px;
                                                                                                                                                                                                                                                                            line-height: 24px;
                                                                                                                                                                                                                                                                            letter-spacing: 0.1em;
                                                                                                                                                                                                                                                                            text-transform: uppercase;
                                                                                                                                                                                                                                                                            color: #000000;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .marketing_home_section_three_left_content_box h2 {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: bold !important;
                                                                                                                                                                                                                                                                            font-size: 56px;
                                                                                                                                                                                                                                                                            line-height: 56px;
                                                                                                                                                                                                                                                                            color: #000000;
                                                                                                                                                                                                                                                                            margin: 0px !important;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .marketing_home_section_three_left_content_box p {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: normal;
                                                                                                                                                                                                                                                                            font-size: 18px;
                                                                                                                                                                                                                                                                            line-height: 28px;
                                                                                                                                                                                                                                                                            color: #2f3134;
                                                                                                                                                                                                                                                                            margin-bottom: 30px;
                                                                                                                                                                                                                                                                            padding: 0px 20px 0px 0px;
                                                                                                                                                                                                                                                                            position: relative;
                                                                                                                                                                                                                                                                            z-index: 111;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                          .marketing_home_section_three_left_content_box .inner_detail_content_three {
                                                                                                                                                                                                                                                                            color: #ed2a3b !important;
                                                                                                                                                                                                                                                                            margin: 0px !important;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .home_section_three_left_content_links {
                                                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                                                            gap: 0px 10px;
                                                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .home_section_three_left_content_links .getting_quote {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: 600;
                                                                                                                                                                                                                                                                            font-size: 14px;
                                                                                                                                                                                                                                                                            line-height: 18px;
                                                                                                                                                                                                                                                                            color: #ffffff !important;
                                                                                                                                                                                                                                                                            text-decoration: none;
                                                                                                                                                                                                                                                                            background: #000000;
                                                                                                                                                                                                                                                                            border-radius: 60px;
                                                                                                                                                                                                                                                                            width: 156px;
                                                                                                                                                                                                                                                                            height: 48px;
                                                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                                                                                                            cursor: pointer;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .home_section_three_left_content_links .talk_to_expert {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: 600;
                                                                                                                                                                                                                                                                            font-size: 14px;
                                                                                                                                                                                                                                                                            line-height: 18px;
                                                                                                                                                                                                                                                                            color: #000000 !important;
                                                                                                                                                                                                                                                                            text-decoration: none;
                                                                                                                                                                                                                                                                            background: #ffffff;
                                                                                                                                                                                                                                                                            border-radius: 60px;
                                                                                                                                                                                                                                                                            width: 156px;
                                                                                                                                                                                                                                                                            height: 48px;
                                                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                                                                                                            cursor: pointer;
                                                                                                                                                                                                                                                                            border: 1px solid #000000;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .home_section_three_left_content_links .talk_to_expert:hover {
                                                                                                                                                                                                                                                                            background: #000000;
                                                                                                                                                                                                                                                                            color: #ffffff !important;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .home_section_three_left_content_links a:hover {
                                                                                                                                                                                                                                                                            text-decoration: none;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .marketing_home_section_three_right_content {
                                                                                                                                                                                                                                                                            position: relative;
                                                                                                                                                                                                                                                                            display: block;
                                                                                                                                                                                                                                                                            width: 100%;
                                                                                                                                                                                                                                                                            padding: 0px 40px 0px 10px;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .marketing_home_section_three_right_content
                                                                                                                                                                                                                                                                            .section_three_right_content_bg_image {
                                                                                                                                                                                                                                                                            position: absolute;
                                                                                                                                                                                                                                                                            bottom: 25px;
                                                                                                                                                                                                                                                                            right: 0px;
                                                                                                                                                                                                                                                                            margin: 0px !important;
                                                                                                                                                                                                                                                                            z-index: -1;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_bg {
                                                                                                                                                                                                                                                                            position: relative;
                                                                                                                                                                                                                                                                            margin: 0px !important;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_inner_bg {
                                                                                                                                                                                                                                                                            position: absolute;
                                                                                                                                                                                                                                                                            top: -42px;
                                                                                                                                                                                                                                                                            left: -36px;
                                                                                                                                                                                                                                                                            z-index: -1;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                          .marketing_home_section_three_right_content_container {
                                                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                                                            gap: 15px 15px;
                                                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_container_box_one {
                                                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                                                                                            gap: 15px 0px;
                                                                                                                                                                                                                                                                            position: relative;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_container_box_data {
                                                                                                                                                                                                                                                                            background: #ffffff;
                                                                                                                                                                                                                                                                            box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
                                                                                                                                                                                                                                                                            border-radius: 16px;
                                                                                                                                                                                                                                                                            display: flex;
                                                                                                                                                                                                                                                                            flex-direction: column;
                                                                                                                                                                                                                                                                            align-items: center;
                                                                                                                                                                                                                                                                            justify-content: center;
                                                                                                                                                                                                                                                                            height: 100%;
                                                                                                                                                                                                                                                                            padding: 25px 25px;
                                                                                                                                                                                                                                                                            gap: 5px 0px;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_container_box_data figure {
                                                                                                                                                                                                                                                                            margin: 0px !important;
                                                                                                                                                                                                                                                                              width: 48px;
                                                                                                                                                                                                                                                                              height: 48px;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_container_box_data figure img{
                                                                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                                                                              height: 100%;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_container_box_data h6 {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: bold;
                                                                                                                                                                                                                                                                            font-size: 18px;
                                                                                                                                                                                                                                                                            line-height: 32px;
                                                                                                                                                                                                                                                                            text-align: center;
                                                                                                                                                                                                                                                                            text-transform: capitalize;
                                                                                                                                                                                                                                                                            color: #000000;
                                                                                                                                                                                                                                                                            margin: 0px !important;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_container_box_data small {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: normal;
                                                                                                                                                                                                                                                                            font-size: 14px;
                                                                                                                                                                                                                                                                            line-height: 24px;
                                                                                                                                                                                                                                                                            text-align: center;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          .section_three_right_content_container_box_data a {
                                                                                                                                                                                                                                                                            font-style: normal;
                                                                                                                                                                                                                                                                            font-weight: 600;
                                                                                                                                                                                                                                                                            font-size: 15px;
                                                                                                                                                                                                                                                                            line-height: 19px;
                                                                                                                                                                                                                                                                            text-decoration-line: underline;
                                                                                                                                                                                                                                                                            color: #000000;
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                          @media (min-width: 768px) and (max-width: 1024px) {
                                                                                                                                                                                                                                                                              .marketing_home_section_three_container {
                                                                                                                                                                                                                                                                                grid-template-columns: 1fr;
                                                                                                                                                                                                                                                                                grid-template-areas: ".";
                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                              .marketing_home_section_three_right_content
                                                                                                                                                                                                                                                                                .section_three_right_content_bg_image {
                                                                                                                                                                                                                                                                                bottom: -10px;
                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                              .marketing_home_section_three_right_content {
                                                                                                                                                                                                                                                                                padding: 0px 10px 0px 0px;
                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                              .marketing_home_section_three_right_content
                                                                                                                                                                                                                                                                                .section_three_right_content_bg_image {
                                                                                                                                                                                                                                                                                bottom: 0px;
                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                            @media (max-width: 767px) {
                                                                                                                                                                                                                                                                                .section_three_right_content_container_box_data figure {
                                                                                                                                                                                                                                                                                    width: 80px;
                                                                                                                                                                                                                                                                                    height: 80px;
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                  .marketing_home_section_three_container {
                                                                                                                                                                                                                                                                                    grid-template-columns: 1fr;
                                                                                                                                                                                                                                                                                    grid-template-areas: ".";
                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                  .marketing_home_section_three_right_content {
                                                                                                                                                                                                                                                                                    padding: 0px 10px 0px 10px;
                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                  .marketing_home_section_three_right_content
                                                                                                                                                                                                                                                                                    .section_three_right_content_bg_image {
                                                                                                                                                                                                                                                                                    bottom: 0px;
                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                  .marketing_home_section_three_right_content
                                                                                                                                                                                                                                                                                    .section_three_right_content_bg_image {
                                                                                                                                                                                                                                                                                    display: none;
                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                  .section_three_right_content_inner_bg {
                                                                                                                                                                                                                                                                                    display: none;
                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                  @media (max-width: 540px) {
                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                      .marketing_home_section_three_right_content
                                                                                                                                                                                                                                                                                        .section_three_right_content_bg_image {
                                                                                                                                                                                                                                                                                        display: none;
                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                      .marketing_home_section_three_right_content {
                                                                                                                                                                                                                                                                                        padding: 0px !important;
                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                      .marketing_home_section_three_right_content_container {
                                                                                                                                                                                                                                                                                        flex-direction: column;
                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                      .marketing_home_section_three_left_content_box {
                                                                                                                                                                                                                                                                                        align-items: center;
                                                                                                                                                                                                                                                                                        text-align: center;
                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                      .section_three_right_content_inner_bg {
                                                                                                                                                                                                                                                                                        display: none;
                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                 
                                                                                                                                                                                                                                                                                      .marketing_home_section_three_left_content_box h2 {
                                                                                                                                                                                                                                                                                        font-size: 40px;
                                                                                                                                                                                                                                                                                        line-height: 44px;
                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                  @media (max-width: 370px) {
                                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                    .home_section_three_left_content_links {
                                                                                                                                                                                                                                                                                      flex-direction: column;
                                                                                                                                                                                                                                                                                      gap: 10px 0px;
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                          </style>
                                                                                                                                                                                                                                                                          <div class="degital_marketing_home_section_three">
                                                                                                                                                                                                                                                                          <div class="custom_container">
                                                                                                                                                                                                                                                                          <div class="marketing_home_section_three_container">
                                                                                                                                                                                                                                                                          <div class="marketing_home_section_three_left_content">
                                                                                                                                                                                                                                                                          <div class="marketing_home_section_three_left_content_box"><small>our services</small>
                                                                                                                                                                                                                                                                          <h2>All in One Solution For You<span class="heading_dot_color">.</span></h2>
                                                                                                                                                                                                                                                                          <p>We are a digital marketing agency that helps you grow your online sales. All-in-one solution <span class="inner_detail_content_three">for your social media campaigns and remarketing,</span> we make sure your business never stops.</p>
                                                                                                                                                                                                                                                                          <div class="home_section_three_left_content_links"><a  class="getting_quote">Get A Quote</a> <a class="talk_to_expert">Talk To An Expert</a></div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          <div class="marketing_home_section_three_right_content">
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_bg_image">
                                                                                                                                                                                                                                                                          <figure class="section_three_right_content_bg"><img  src="https://tossdown.site/images/11550c278a4063d0492b8907263f5e42_1639643262.png" /></figure>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          <div class="marketing_home_section_three_right_content_container">
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_container_box_one">
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_container_box_data">
                                                                                                                                                                                                                                                                          <figure><img class="lazy" src="https://tossdown.site/images/aa3647d69f7cc4861dc5e4a993e3a179_1639987630.png" /></figure>
                                                                                                                                                                                                                                                                          <h6>E-Commerce</h6>
                                                                                                                                                                                                                                                                          <small>Ecommerce is a tiresome thing if you are doing it yourself. Don’t need to worry now. We are your Ecommerce Genie...</small> <a >View Details</a></div>
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_container_box_data">
                                                                                                                                                                                                                                                                          <figure><img class="lazy" src="https://tossdown.site/images/4402f48893a4a9cec336c8b9ec47da28_1639987886.png" /></figure>
                                                                                                                                                                                                                                                                          <h6>Email Marketing</h6>
                                                                                                                                                                                                                                                                          <small>Loram Ipsum is an agency that helps you grow your business through targeted &amp; personalized campaigns...</small> <a >View Details</a></div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_container_box_one">
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_inner_bg"><img class="lazy" src="https://tossdown.site/images/41b88a197990ecf5054ae7c40fa960fd_1639644068.png" /></div>
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_container_box_data">
                                                                                                                                                                                                                                                                          <figure><img class="lazy" src="https://tossdown.site/images/43fb44fa43f524efccd629b792b531b7_1639987903.png" /></figure>
                                                                                                                                                                                                                                                                          <h6>Social Media</h6>
                                                                                                                                                                                                                                                                          <small>We offer a wide range of social media services to suit your needs including organic posting,remarketing...</small> <a >View Details</a></div>
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_container_box_data">
                                                                                                                                                                                                                                                                          <figure><img class="lazy" src="https://tossdown.site/images/b06cfac13a6838c10d1cae6e1c5c5cda_1639987918.png" /></figure>
                                                                                                                                                                                                                                                                          <h6>Performance Marketing</h6>
                                                                                                                                                                                                                                                                          <small>Lets create a sales and marketing machine that delivers you endless clients for years to come...</small> <a >View Details</a></div>
                                                                                                                                                                                                                                                                          <div class="section_three_right_content_container_box_data">
                                                                                                                                                                                                                                                                          <figure><img class="lazy" src="https://tossdown.site/images/7faaee425738a95140851cfcae457ce7_1639987928.png" /></figure>
                                                                                                                                                                                                                                                                          <h6>SEO</h6>
                                                                                                                                                                                                                                                                          <small>Our immediate goal is to create an Organic SEO campaign to get your marketing efforts on a good path and begin to show positive ROI...</small> <a >View Details</a></div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                          </div>
    
                                                                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                                   `
                                                                                                                                                                                                                                                                    },

                                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                                        id:"HomeCustomSection67",
                                                                                                                                                                                                                                                                        name:"HomeCustomSection 68",
                                                                                                                                                                                                                                                                        height:"800px",
                                                                                                                                                                                                                                                                        code:` 
                                                                                                                                                                                                                                                                        <head>
                                                                                                                                                                                                                                                                        <meta charset="utf-8" />
                                                                                                                                                                                                                                                                        <meta
                                                                                                                                                                                                                                                                          name="viewport"
                                                                                                                                                                                                                                                                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                                          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                                                                                                                                                                                                                                                                          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                                                                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                                          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
                                                                                                                                                                                                                                                                          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
                                                                                                                                                                                                                                                                          crossorigin="anonymous"
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                                                                          src="//code.jquery.com/jquery-migrate-1.2.1.min.js"
                                                                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                                                          type="text/javascript"
                                                                                                                                                                                                                                                                          src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                                        ></script>
                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        <link
                                                                                                                                                                                                                                                                          rel="stylesheet"
                                                                                                                                                                                                                                                                          type="text/css"
                                                                                                                                                                                                                                                                          href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                                                                                                                                                                                                                                                                        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                                                                                                                                                                                                                                                        <script
                                                                                                                                                                                                                                                                        type="text/javascript"
                                                                                                                                                                                                                                                                        src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                                      ></script>
                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                      <link
                                                                                                                                                                                                                                                                        rel="stylesheet"
                                                                                                                                                                                                                                                                        type="text/css"
                                                                                                                                                                                                                                                                        href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                                      />
                                                                                                                                                                                                                                                                      <link
                                                                                                                                                                                                                                                                        rel="stylesheet"
                                                                                                                                                                                                                                                                        type="text/css"
                                                                                                                                                                                                                                                                        href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                                      />
                                                                                                                                                                                                                                                                      <script
                                                                                                                                                                                                                                                                      type="text/javascript"
                                                                                                                                                                                                                                                                      src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"
                                                                                                                                                                                                                                                                    ></script>
                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    <link
                                                                                                                                                                                                                                                                      rel="stylesheet"
                                                                                                                                                                                                                                                                      type="text/css"
                                                                                                                                                                                                                                                                      href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                      </head>
                                                                                                                                                                                                                                                                         <style>
                                                                                                                                                                                                                                                                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                                                                                                                                                                                                                                                                        </style>
                                                                                                                                                                                                                                                                        <style>
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  width: 100%;
  margin: 0px;
  background-color: #fff;
}
.custom_container {
  max-width: 1200px;
  margin: 0px auto;
}

 
      
    .degital_marketing_home_section_four {
        display: block;
        width: 100%;
        margin-bottom: 45px;
        padding: 0px 15px;
      }
      .marketing_home_section_four_container {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: ". .";
        grid-template-rows: auto;
        gap: 20px 100px;
        align-items: center;
        position: relative;
        padding: 150px 0px;
      }
      
      .marketing_home_section_four_right_content_box {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;
        width: 100%;
      }
      .marketing_home_section_four_right_content_box small {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
      }
      .marketing_home_section_four_right_content_box h2 {
        font-style: normal;
        font-weight: bold !important;
        font-size: 56px;
        line-height: 56px;
        color: #000000;
        margin: 0px !important;
      }
      .marketing_home_section_four_right_content_box p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #2f3134;
        margin-bottom: 30px;
        padding: 0px 20px 0px 0px;
        position: relative;
        z-index: 111;
      }
      
      .home_section_four_right_content_links {
        display: flex;
        gap: 0px 10px;
        align-items: center;
      }
      .home_section_four_right_content_links .getting_quote {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff !important;
        text-decoration: none;
        background: #000000;
        border-radius: 60px;
        width: 156px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      
      .home_section_four_right_content_links a:hover {
        text-decoration: none;
      }
      
      .marketing_home_section_four_left_content {
        position: relative;
        display: block;
        width: 100%;
      }
      .marketing_home_section_four_left_content .section_four_left_content_bg_image {
        position: absolute;
        bottom: 0px;
        left: 0px;
        margin: 0px !important;
        z-index: -1;
        transform: translate(0%, 18%);
      }
      .section_four_left_content_bg {
        position: relative;
        margin: 0px !important;
      }
      
      .section_four_left_content_container_box_one {
        display: flex;
        gap: 15px 15px;
        position: relative;
      }
      .section_four_left_content_container_box_data {
        background: #ffffff;
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 60px 15px;
        gap: 5px 0px;
        text-align: left;
        position: relative;
      }
      .section_four_left_content_container_box_data.child_one::after {
        position: absolute;
        content: "";
        background-image: url("https://tossdown.site/images/c8c546150891ca3572f43e40bf4f1871_1639653974.png");
        top: 20px;
        right: 15px;
        z-index: 999;
        width: 49px;
        background-repeat: no-repeat;
        height: 49px;
      }
      .section_four_left_content_container_box_data.child_two::after {
        position: absolute;
        content: "";
        background-image: url("https://tossdown.site/images/cbb385a4ca037204026524f7f2becb04_1639653676.png");
        top: 20px;
        right: 15px;
        z-index: 999;
        width: 52px;
        background-repeat: no-repeat;
        height: 38px;
      }
      .section_four_left_content_container_box_data:last-child {
        margin-top: 145px;
      }
      .section_four_left_content_container_box_data figure {
        margin: 0px !important;
      }
      .section_four_left_content_container_box_data h6 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
        text-align: left;
        text-transform: capitalize;
        color: #000000;
        margin: 0px !important;
      }
      .section_four_left_content_container_box_data small {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #000000;
        margin-bottom: 10px;
      }
      .section_four_left_content_container_box_data a {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        text-decoration-line: underline;
        color: #000000;
      }
      
      .section_four_content_inner_bg {
        position: absolute;
        top: 0px;
        right: 0px;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
 
          .marketing_home_section_four_container {
            grid-template-columns: 1fr;
            grid-template-areas: ".";
            padding: 80px 0px;
          }
          .marketing_home_section_four_left_content {
            order: 2;
          }
          .section_four_left_content_container_box_data:last-child {
            margin-top: 0px !important;
          }
          .marketing_home_section_four_left_content
            .section_four_left_content_bg_image {
            position: absolute;
            bottom: -95px;
            left: 0px;
            z-index: -1;
            transform: unset !important;
            min-width: 100%;
            display: flex;
            align-content: center;
            justify-content: center;
          }
        }

        @media (max-width: 767px) {

            
              .marketing_home_section_four_container {
                grid-template-columns: 1fr;
                grid-template-areas: ".";
                padding: 80px 0px;
              }
              .marketing_home_section_four_left_content {
                order: 2;
              }
              .section_four_left_content_container_box_data:last-child {
                margin-top: 0px !important;
              }
              .marketing_home_section_four_left_content
                .section_four_left_content_bg_image {
                display: none;
              }
      
            
            }

            @media (max-width: 540px) {
                  .marketing_home_section_four_right_content_box {
                    align-items: center;
                    text-align: center;
                  }
                  .section_four_left_content_container_box_one {
                    flex-direction: column;
                  }
                  .section_four_left_content_container_box_data {
                    text-align: center;
                    align-items: center;
                  }
                  .section_four_left_content_container_box_data small {
                    text-align: center;
                  }
      
                  .marketing_home_section_four_right_content_box h2 {
                    font-size: 40px;
                    line-height: 44px;
                  }
                  .marketing_home_section_four_container {
                    padding-bottom: 30px !important;
                  }
                }
                @media (max-width: 370px) {
                    .section_four_content_inner_bg {
                      top: -10px;
                    }
                  }

      </style>
      <div class="degital_marketing_home_section_four">
    <div class="custom_container">
    <div class="marketing_home_section_four_container">
    <div class="section_four_content_inner_bg"><img class="lazy" src="https://tossdown.site/images/41b88a197990ecf5054ae7c40fa960fd_1639644068.png" /></div>
    <div class="marketing_home_section_four_left_content">
    <div class="section_four_left_content_bg_image">
    <figure class="section_four_left_content_bg"><img class="lazy" src="https://tossdown.site/images/b6c9ac08602cd50aa51b88d56ef5719d_1639649549.png" /></figure>
    </div>
    <div class="section_four_left_content_container_box_one">
    <div class="section_four_left_content_container_box_data child_one">
    <figure><img class="lazy" src="https://tossdown.site/images/4316df8639158892d056d8754cb6a5fe_1639653531.png" /></figure>
    <h6>find your plan</h6>
    <small>Simple, transparent pricing for startups to enterprises.</small> <a >View Details</a></div>
    <div class="section_four_left_content_container_box_data child_two">
    <figure><img class="lazy" src="https://tossdown.site/images/0493654d5f12af5311ec0cdfcb383e06_1639653611.png" /></figure>
    <h6>Talk to an expert</h6>
    <small>Simple, transparent pricing for startups to enterprises.</small> <a >View Details</a></div>
    </div>
    </div>
    <div class="marketing_home_section_four_right_content">
    <div class="marketing_home_section_four_right_content_box"><small>Loram Ipsum</small>
    <h2>Ready to get started<span class="heading_dot_color">?</span></h2>
    <p></p>
    <div class="home_section_four_right_content_links"><a  class="getting_quote">Get A Quote</a></div>
    </div>
    </div>
    </div>
    </div>
    </div>
        
                                                                                                                                                                                                                                                                       
                                                                                                                                                                                                                                                                       `
                                                                                                                                                                                                                                                                        },

                                                                                                                                                                                    ]
    export default HomePageCustomSectionsObjects