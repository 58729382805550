import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {

  return (
    <>
      {/* header start  */}

    
      {/* <header className="dashboard-header p-fixed">
        <div className="custom-container-one">
          <div className="header-section d-f justify-sb align-c w-100">
            <div className="d-f align-c">
              <div className="header-logo d-f">
                <img src="image/last.png" alt="logo" />
              </div>
              <div className="header-navigation d-f align-c p-l-40">
                <Link to="/" className="p-r-20">
                  Home
                </Link>
               
              </div>
            </div>
            <div className="header-hamburger-btn d-f">
              <img
                className="hamburger_img cursor-p"
                src="image/hamburger.svg"
                alt="hamburger"
              />
            </div>
          </div>
        </div>
      </header> */}

   <section className="dashboard-header"> 
    <div className="header-container">
        <div className="web_header_main">
          <div className="web_header_0">
            <ul className="web_header_0_list_0">
              <li className="li_0 web_header_logo">
                <a className="header-hamburger-btn">
                 <i className="ri-menu-fill hamburger_img"></i></a>
                </li>
                <ul className="web_header_0_list_1">
                <li className="li_0"><Link to="/"> Home</Link> </li>
                 {/* <li> <a href="#" className="p-r-20">component</a>
                <Link to="/Documentation" className="p-r-20">
                  Documentation
                </Link>
                <a href="#">Faq's</a> </li> */}
               </ul>
            </ul>
             <ul className="web_header_0_list_2">
                <li className="li_0"> </li>
            </ul>
         </div>
       </div>
    </div> 
 </section>

      {/* header end   */}
    </>
  );
};

export default Header;
