const NewCustomSectionObjects=[
    {
    id:"newcustomsection0",
    name:"New Custom Section One",
    height:"450px",
    head:`
    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<!-- fontawsome link start-->
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
    <!-- fontawsome link end -->
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
   </head>
   <style>
   @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

   *{
   -web-kit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing:border-box;
  padding:0px;
  margin:0px;
}
  html {
    scroll-behavior: smooth;
   }
  body{
      float: left;
      width: 100%;
      margin:0px;
      font-family: 'Work Sans', sans-serif;
  }
</style>

    `,
    code:` 
    
  <style>
    .home_custom_section_two {
        background: #F7F7F5;
        float: left;
        width: 100%;
        display: block;
        padding: 50px 10px 50px 10px;
       margin-bottom:48px;
    }
    .custom_container {
        max-width: 1140px;
        margin: 0 auto;
    }
    .home_custom_section_two_content {
        display: grid;
        width: 100%;
    }
    .home_custom_section_two_des {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px 38px;
        align-items: center;
        height: 100%;
        padding-bottom: 24px;
    }
    .home_custom_section_two_des strong {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 26px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: left;
        color: #000000;
    }
    .home_custom_section_two_des span {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
    }
    .home_custom_section_two_btn {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .home_custom_section_two_btn a {
        width: 204px;
        height: 56px;
        background: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 56px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        color: #E4C899;
       padding:0px 24px 0px 24px;
        text-decoration: none;
    }
    .home_custom_section_two_btn a:hover{
        color: #E4C899;
        text-decoration: none;
    }
    @media(max-width:768px){
    .home_custom_section_two_des {
        grid-template-columns: 1fr;
        text-align: center;
    }
    .home_custom_section_two_des strong {
        text-align: center;
        color: #000000;
    }
    }
    @media(max-width:540px){
    
    .home_custom_section_two {
        padding: 30px 10px 30px 10px;
    margin-bottom:30px;
    }
    .home_custom_section_two_des strong {
        font-size: 18px;
        line-height: 26px;
    }
    .home_custom_section_two_des {
        gap: 8px 38px;
    }
    .home_custom_section_two_btn a {
        width: 177px;
        height: 47px;
    }
    }
    </style>
<div class="home_custom_section_two">
<div class="custom_container">
<div class="home_custom_section_two_content">
<div class="home_custom_section_two_des">
    <strong>Your source for local updates, businesses, events and more!</strong> 
    <span>Featuring everything from restaurants, bakeries, grocery stores and all that's best in the gorgeous city of St. Catharines.</span>
</div>
<div class="home_custom_section_two_btn">
    <a>Join the club <i class="far fa-arrow-right" aria-hidden="true"></i></a>
</div>
</div>
</div>
</div>
    
    `
        },

    ]
    export default NewCustomSectionObjects