import React, { useState, useEffect } from 'react';
import Editor from './Editor';
const CustomCodeEditor = ({id,height,heading,codedata,head}) => {

  const [snippet, setSnippte] = useState(codedata) 
  const [srcDoc, setSrcDoc] = useState('');
 
  useEffect(() => {
  
 var iframe = document.getElementById(id)
 iframe.onload = function(){
          iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
         iframe.contentWindow.document.querySelector("body").style.overflowY="auto"; 
         iframe.contentWindow.document.body.insertAdjacentHTML("beforeend", `<style>body{padding:0px 10px;} body::-webkit-scrollbar-track {
          border: 1px solid #CCD1DB;
          padding: 2px 0;
          background-color: #fff;
        }
        body::-webkit-scrollbar {
          width: 8px;
        }
        body::-webkit-scrollbar-thumb {
          border-radius: 2px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          background-color: #f5f5f5;
          border: 1px solid #CCD1DB;
        }</style>`)
        }


  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSrcDoc(`
   
        <html>
             ${head}
          <body>${snippet}</body>
        </html>
      `)
    }, 250)

    return () => clearTimeout(timeout)
  
  
  }, [snippet])

  

  return (
    <>
    <div className="code-editor">


      <div className="pane top-pane code-area">
        <Editor
          language="xml"
          displayName={heading}
         // value={`${head} ${snippet}`}
           head={head}
           value={snippet} 
         onChange={setSnippte}
        />
      </div>
      <div className={heading} style={{height}} id="code-result-screen">
        <iframe
          srcDoc={srcDoc}
          title="output"
          //sandbox="allow-scripts"
          frameBorder="0"
          width="100%"
          id={id}
        ></iframe>
      </div>
      </div>
    </>

)



}

export default CustomCodeEditor
