const InstagramFeedsObjects=[
    {
    id:"InstagramFeed0",
    name:"InstagramFeed 0ne",
    height:"500px",
    head:`
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
      
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
    <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
    <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
    
    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
      </head> 
    
    `,
    code:` 
    
    
      <style>
      @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
      </style>
      <style>
      * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      margin: 0px;
      width: 100%;
      background-color: #fff;
    }
      .insta_custom_section_main {
          float: left;
          display: block;
          width: 100%;
      margin-bottom: 50px;
      }
      .insta_feeds_heading {
          display: flex;
          justify-content: center;
          padding-bottom:40px;
      }
      .insta_feeds_heading h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 46px;
      text-transform: uppercase;
      color: #262B40;
      font-family: 'Poppins', sans-serif;
      }
      .insta_custom_section {
          display: block;
          align-items: center;
          width: 100%;
         justify-content: center;
          padding: 0px;
      }
      .insta_custom_section .insta_custom_box{
          width: 25%;
          padding: 0px 10px 15px 10px;
          float: left;
          margin: 0px !important;
      }
      .insta_custom_section .insta_custom_box figure{
      width:100%; 
    }
      .insta_custom_section .insta_custom_box figure a img{
      height:auto;
      width:100%;
      }


      </style>
      <!-- <?php
         $url = "https://graph.instagram.com/me/media?fields=id,username,caption,media_url,permalink,media_type,thumbnail_url&access_token=IGQVJWZAnJjV1FIRGE3c1pHX191RTk2eTlFLW43Nlc5ZAHhFcmo3Rk03SGROT0tQbF9RV21WeUpLVU1KZAEtOSEtxbEN1WFdEWGlKazBLVHVhdktpQUdxelFJWVhFWHhZAQWMyTHo1NExTdTk3Y1pTTlhIbwZDZD";
              ini_set('max_execution_time', '0');
              ini_set("memory_limit",-1);
                             $curl = curl_init();
                             curl_setopt($curl, CURLOPT_URL, $url);
                             curl_setopt($curl, CURLOPT_RETURNTRANSFER, 1);
                             curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, 0);
                             curl_setopt($curl, CURLOPT_HEADER, 0);
                             curl_setopt($curl, CURLOPT_POST, 0);
                             curl_setopt($ch, CURLOPT_TIMEOUT, 20);
                             $instaResult = curl_exec($curl);
                             curl_close($curl);
                             $instaMedia = json_decode($instaResult, true);
         ?> 
      <div class="insta_custom_section_main">
      <div class="insta_feeds_heading">
      <h3>Get social with Us</h3>
      </div>
      <div class="insta_custom_section insta_custom_section_slide"><?php $i = 0; ?> <?php foreach ($instaMedia['data'] as $key => $value) { ?> <?php if($value['media_type'] == "IMAGE" ||  $value['media_type'] == "CAROUSEL_ALBUM" ) {?> <?php if ($i++ < 4 ) { ?>
      <div class="insta_custom_box ">
      <figure><?php echo '<a href="'.$value['permalink'].'" target="_blank" rel="noopener">'; ?> <?php echo '<img src="'.$value['media_url'].'">'; ?> <?php echo '</a>'; ?></figure>
      </div>
      <?php } else { ?> <?php break; ?> <?php } ?> <?php } ?> <?php } ?> <?php foreach ($instaMedia['data'] as $second_key => $second_value) { ?> <?php if($second_value['media_type'] == "IMAGE" ||  $value['media_type'] == "CAROUSEL_ALBUM") {?> <?php if ($second_key > 3 && $second_key < 5) { ?>
      <div class="insta_custom_box ">
      <figure><?php echo '<a href="'.$second_value['permalink'].'" target="_blank" rel="noopener">'; ?> <?php echo '<img src="'.$second_value['media_url'].'" >'; ?> <?php echo '</a>'; ?></figure>
      </div>
      <?php } ?> <?php } ?> <?php } ?></div>
      </div> -->
     

      <div class="insta_custom_section_main">
        <div class="insta_feeds_heading">
        <h3>Get social with Us</h3>
        </div>
        <div class="insta_custom_section insta_custom_section_slide">
        <div class="insta_custom_box ">
        <figure><a><img src='https://static.tossdown.com/images/e1c1718c-a9d3-4cea-8ade-e263e3d45536.jpg'></a></figure>
        </div>
        <div class="insta_custom_box ">
          <figure><a><img src='https://static.tossdown.com/images/81ff3681-adb7-463a-9910-bdb6983c9da7.jpg'></a></figure>
          </div>
          <div class="insta_custom_box ">
            <figure><a><img src='https://static.tossdown.com/images/a960362c-64d4-44e1-89a4-a0409b40e9ad.jpg'></a></figure>
            </div>
            <div class="insta_custom_box ">
              <figure><a><img src='https://static.tossdown.com/images/e1c1718c-a9d3-4cea-8ade-e263e3d45536.jpg'></a></figure>
              </div>
              <div class="insta_custom_box ">
                <figure><a><img src='https://static.tossdown.com/images/e1c1718c-a9d3-4cea-8ade-e263e3d45536.jpg'></a></figure>
                </div>
                <div class="insta_custom_box ">
                  <figure><a><img src='https://static.tossdown.com/images/81ff3681-adb7-463a-9910-bdb6983c9da7.jpg'></a></figure>
                  </div>
                  <div class="insta_custom_box ">
                    <figure><a><img src='https://static.tossdown.com/images/81ff3681-adb7-463a-9910-bdb6983c9da7.jpg'></a></figure>
                    </div>
     
        </div>
       </div> 

      <script>
            $(document).ready(function () {
              $(".insta_custom_section_slide").slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: false,
                arrows: false,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 2000,
                      dots: false,
                      arrows: false,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 2000,
                           dots: false,
                           arrows: false,
                    },
                  },
                  {
                    breakpoint: 540,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 2000,
                          dots: false,
                          arrows: false,
                    },
                  },
                  {
                    breakpoint: 400,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 2000,
                           dots: false,
                           arrows: false,
                    },
                  },
                ],
              });
            });
          </script>  
                

`
    },

    {
        id:"InstagramFeed1",
        name:"InstagramFeed Two",
        height:"500px",
        head:`
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
          
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
        <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
        <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
        
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
          </head> 
        
        `,

        code:` 
       
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
          </style>
          <style>
          * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          margin: 0px;
          width: 100%;
          background-color: #fff;
        }
          .insta_custom_section_main {
              float: left;
              display: block;
              width: 100%;
          margin-bottom: 50px;
          padding: 0px 15px;
          }
          .custom_container{
              max-width:1140px;
              margin:0 auto;
            }
            .insta_custom_section_list {
                float: left;
                display: block;
                width: 100%;
               padding:0px 50px;
            position:relative;
            }
            
            .insta_feeds_heading {
                display: flex;
                justify-content: center;
                padding-bottom:40px;
    width: 100%;
            }
            .insta_feeds_heading h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 46px;
            text-transform: uppercase;
            color: #262B40;
            font-family: 'Poppins', sans-serif;
            }
            .insta_custom_section {
                display: block;
                align-items: center;
                width: 100%;
               justify-content: center;
                padding: 0px;
                margin: 0px !important;
            }
            .insta_custom_section .insta_custom_box{
                width: 25%;
                padding: 0px 10px 0px 10px;
                float: left;
                margin: 0px !important;
            }
            .insta_custom_section .insta_custom_box figure{
            width:100%; 
          }
            .insta_custom_section .insta_custom_box figure a img{
            height:auto;
            width:100%;
            }
            .insta_slider_arrow_box {
              display: flex;
                  justify-content: space-between;
                  height: 100%;
                  align-items: center;
                  position: absolute;
                  top: 0px;
                  left: 0px;
               
                  width: 100%;
              }
              .insta_slider_arrow_box  a.insta_pre_arrow.slick-arrow {
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
              z-index:99;
              }
              .insta_slider_arrow_box  a.insta_pre_arrow.slick-arrow i{
              color:#000;
              }
              @media (max-width: 1024px) {
                  .insta_custom_section_list {
                  width:100%;
                  padding: 0px 30px;
                  }
                  .insta_slider_arrow_box  a.insta_pre_arrow.slick-arrow {
                   background: #FFFFFF;
                  box-sizing: border-box;
                  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
                  border-radius: 2px;
                  }
                  
                  
                  }
            </style>
  <div class="insta_feeds_heading">
  <h3>Get social with Us</h3>
  </div>
  <!-- <?php
       $url = "https://graph.instagram.com/me/media?fields=id,username,caption,media_url,permalink,media_type,thumbnail_url&access_token=IGQVJWZAnJjV1FIRGE3c1pHX191RTk2eTlFLW43Nlc5ZAHhFcmo3Rk03SGROT0tQbF9RV21WeUpLVU1KZAEtOSEtxbEN1WFdEWGlKazBLVHVhdktpQUdxelFJWVhFWHhZAQWMyTHo1NExTdTk3Y1pTTlhIbwZDZD";
            ini_set('max_execution_time', '0');
            ini_set("memory_limit",-1);
                           $curl = curl_init();
                           curl_setopt($curl, CURLOPT_URL, $url);
                           curl_setopt($curl, CURLOPT_RETURNTRANSFER, 1);
                           curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, 0);
                           curl_setopt($curl, CURLOPT_HEADER, 0);
                           curl_setopt($curl, CURLOPT_POST, 0);
                           curl_setopt($ch, CURLOPT_TIMEOUT, 20);
                           $instaResult = curl_exec($curl);
                           curl_close($curl);
                           $instaMedia = json_decode($instaResult, true);
       ?>
  <div class="insta_custom_section_main">
  <div class="custom_container">
  <div class="insta_custom_section_list">
  <div class="insta_slider_arrow_box"></div>
  <div class="insta_custom_section insta_custom_section_slide"><?php $i = 0; ?> <?php foreach ($instaMedia['data'] as $key => $value) { ?> <?php if($value['media_type'] == "IMAGE" ||  $value['media_type'] == "CAROUSEL_ALBUM" ) {?> <?php if ($i++ < 4 ) { ?>
  <div class="insta_custom_box ">
  <figure><?php echo '<a href="'.$value['permalink'].'" target="_blank" rel="noopener">'; ?> <?php echo '<img src="'.$value['media_url'].'">'; ?> <?php echo '</a>'; ?></figure>
  </div>
  <?php } else { ?> <?php break; ?> <?php } ?> <?php } ?> <?php } ?> <?php foreach ($instaMedia['data'] as $second_key => $second_value) { ?> <?php if($second_value['media_type'] == "IMAGE" ||  $value['media_type'] == "CAROUSEL_ALBUM") {?> <?php if ($second_key > 3 && $second_key < 5) { ?>
  <div class="insta_custom_box ">
  <figure><?php echo '<a href="'.$second_value['permalink'].'" target="_blank" rel="noopener">'; ?> <?php echo '<img src="'.$second_value['media_url'].'" >'; ?> <?php echo '</a>'; ?></figure>
  </div>
  <?php } ?> <?php } ?> <?php } ?></div>
  </div>
  </div>
  </div> -->
  
  <div class="insta_custom_section_main">
      <div class="custom_container">
      <div class="insta_custom_section_list">
      <div class="insta_slider_arrow_box"></div>
      
        <div class="insta_custom_section insta_custom_section_slide">
        <div class="insta_custom_box ">
        <figure><a><img src='https://static.tossdown.com/images/e1c1718c-a9d3-4cea-8ade-e263e3d45536.jpg'></a></figure>
        </div>
        <div class="insta_custom_box ">
          <figure><a><img src='https://static.tossdown.com/images/81ff3681-adb7-463a-9910-bdb6983c9da7.jpg'></a></figure>
          </div>
          <div class="insta_custom_box ">
            <figure><a><img src='https://static.tossdown.com/images/a960362c-64d4-44e1-89a4-a0409b40e9ad.jpg'></a></figure>
            </div>
            <div class="insta_custom_box ">
              <figure><a><img src='https://static.tossdown.com/images/e1c1718c-a9d3-4cea-8ade-e263e3d45536.jpg'></a></figure>
              </div>
              <div class="insta_custom_box ">
                <figure><a><img src='https://static.tossdown.com/images/e1c1718c-a9d3-4cea-8ade-e263e3d45536.jpg'></a></figure>
                </div>
                <div class="insta_custom_box ">
                  <figure><a><img src='https://static.tossdown.com/images/81ff3681-adb7-463a-9910-bdb6983c9da7.jpg'></a></figure>
                  </div>
                  <div class="insta_custom_box ">
                    <figure><a><img src='https://static.tossdown.com/images/81ff3681-adb7-463a-9910-bdb6983c9da7.jpg'></a></figure>
                    </div>
             </div>
        </div>
        </div>
       </div>
  <script>
                  $(document).ready(function () {
                    $(".insta_custom_section_slide").slick({
                      slidesToShow: 5,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 2000,
                      dots: false,
                      arrows: true,
                      appendArrows: $('.insta_slider_arrow_box'),
                      nextArrow: '<a class="insta_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
                      prevArrow: '<a class="insta_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
                      responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 2000,
                            dots: false,
                           arrows: true,
                          },
                        },
                        {
                          breakpoint: 767,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 2000,
                                 dots: false,
                               arrows: true,
                          },
                        },
                        {
                          breakpoint: 540,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 2000,
                                dots: false,
                             arrows: true,
                          },
                        },
                        {
                          breakpoint: 400,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: false,
                            autoplaySpeed: 2000,
                                 dots: false,
                                arrows: true,
                          },
                        },
                      ],
                    });
                  });
                </script>
    
    `
        },
    


    
    ]
    export default InstagramFeedsObjects