const HeaderObjects=[
    {
    id:"Header0",
    name:"Header One",
    height:"300px",
    head:`  
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
    
  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    margin: 0px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
  }
   </style>
    `,
    code:` 
    <style>
   .web_header{
    display: block;
    width: 100%;
    background-color: #F5E7DA !important;

   }
   .mobile_header{
    display: none;
    width: 100%;
    background-color: #F5E7DA !important;
   }
   .custom_container{
    max-width: 1140px;
    margin: 0px auto;
    }
/* web Header style start */
.web_header_main{
display: flex;
flex-direction: column;
width: 100%;
padding: 0px 15px;
}   
.web_header_0{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0px;
    align-items: center;
    }
    .web_header_0 ul{
        display: flex;
        align-items: center;
    }
    .web_header_0 ul li{
       list-style: none !important;
    }
    
     .web_header_0_list_0 li a{
        text-decoration: none !important;
    }
    .web_header_signup_btn{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    width: 102px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* mobile header css */

.mobile_header_main{
display: flex;
flex-direction: column;
width: 100%;
padding: 0px 15px;
}   
.mobile_header_0{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0px;
    align-items: center;
    }
    .mobile_header_0 ul{
        display: flex;
        align-items: center;
    }
    .mobile_header_0 ul li{
       list-style: none !important;
    }
    
     .mobile_header_0_list_0 li a{
        text-decoration: none !important;
    }
  
.mobile_res_toggle_content{
position: fixed;
top: 0;
left: 0;
height: 100vh;
transform: translatex(-100%);
transition: transform 0.3s ease-in-out;
z-index: 999;
width: 250px;
height: 100vh;
background-color: #fff !important;
padding: 20px 10px;
overflow-y: scroll;
box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
}
.mobile_res_toggle_content.responsive_bar{
    transform: translatex(0%);
}
.mobile_res_toggle_content_cancle {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;
}
.mobile_res_toggle_list_1{
    display: flex;
    flex-direction: column;
 
}
.mobile_res_toggle_list_1 li{
    list-style: none;
    border-bottom: 1px solid #cfcfcf;
   padding-bottom: 8px;
   margin-bottom: 15px;
}
.mobile_res_toggle_list_1 li:last-child{
    border-bottom: unset;
}

.mobile_header_search_section {
    display: flex;
    width: 100%;
    position: relative;
    padding-bottom: 6px;
    border-bottom: 1px solid #000 !important;
    margin-bottom: 10px;
}
.mobile_header_search_section i {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 16px;
}
.mobile_header_search_section input {
    background: transparent !important;
    border: unset;
    width: 100%;
    padding: 0px 10px 0px 30px;
    font-size: 16px;
    font-weight: normal;
}
input:focus-visible{
    outline: unset !important; 
} 
@media(max-width:991px){
.web_header{
    display: none;
}
.mobile_header{
display: block;
}
}

</style>
   <section class="web_header"> 
    <div class="custom_container">
        <div class="web_header_main">
          <div class="web_header_0">
            <ul class="web_header_0_list_0">
              <li class="li_0 web_header_logo"><a href="index.html"><img style="width:153px;" src="https://static.tossdown.com/images/22f8d2b5-aa82-4af9-985c-9467a65e4006.png"></a></li>
            </ul>
              <ul class="web_header_0_list_1">
                <li class="li_0"><a style="padding-right: 40px;font-size: 14px;font-weight: 500;">Home</a> </li>
                <li class="li_1"><a style="padding-right: 40px;font-size: 14px;font-weight: 500;">Shop</a> </li>
                <li class="li_2"><a style="padding-right: 40px;font-size: 14px;font-weight: 500;">Learn</a> </li>
                <li class="li_3"><a style="">Contact</a> </li>
             </ul>

             <ul class="web_header_0_list_2">
                <li class="li_0"><a style="padding-right: 20px;font-size:20px;"><i class="fal fa-search"></i></a> </li>
                <li class="li_1"><a style="padding-right: 20px;font-size:20px;"><i class="fal fa-heart"></i></a> </li>
                <li class="li_2"><a style="padding-right: 20px;font-size:20px;"><i class="fal fa-shopping-cart"></i></a> </li>
                <li class="li_3"><a><span class="web_header_signup_btn">signup</span></a> </li>
            </ul>
         </div>
       </div>
    </div> 
 </section>
 
 <section class="mobile_header"> 
    <div class="custom_container">
        <div class="mobile_header_main">
          <div class="mobile_header_0">
            <ul class="mobile_header_0_list_1">
                <li class="li_0"><a class="mobile_res_toggle_btn" style="font-size: 20px;font-weight: 500;padding-right: 12px;"><i class="fal fa-bars"></i></a> </li> 
             </ul>

            <ul class="mobile_header_0_list_0">
              <li class="li_0 mobile_header_logo"><a href="index.html"><img style="width:153px;max-width:100%;padding-right:12px;" src="https://static.tossdown.com/images/22f8d2b5-aa82-4af9-985c-9467a65e4006.png"></a></li>
            </ul>
            

             <ul class="mobile_header_0_list_2">
                <li class="li_1"><a style="padding-right: 10px;font-size:16px;"><i class="fal fa-heart"></i></a> </li>
                <li class="li_2"><a style="padding-right: 10px;font-size:16px;"><i class="fal fa-shopping-cart"></i></a> </li>
                <li class="li_3"><a style="font-size:16px;"><i class="fal fa-user"></i></a> </li>
            </ul>
         </div>
         <div class="mobile_header_1">
            <ul class="mobile_header_1_list_0">
            <form>
                <div class="mobile_header_search_section">
                <i class="fal fa-search"></i>
                <input type="search" placeholder="search here">
                </div>
            </form>
            </ul>
         </div>
       </div>
    </div> 

<div class="mobile_res_toggle_content">
    <div class="mobile_res_toggle_content_cancle"><a class="mobile_res_toggle_btn" style="font-size:20px;"><i class="fas fa-times" style="font-weight: 300;"></i></a></div>
  
    <ul class="mobile_res_toggle_list_1">
        <li class="li_0"><a style="font-size: 14px;font-weight: 500;">Home</a> </li>
        <li class="li_1"><a style="font-size: 14px;font-weight: 500;">Shop</a> </li>
        <li class="li_2"><a style="font-size: 14px;font-weight: 500;">Learn</a> </li>
        <li class="li_3"><a style="font-size: 14px;font-weight: 500;">Contact</a> </li>
     </ul>


</div>



 </section>
 
<script>
$('.mobile_res_toggle_btn').click(function () {
    $('.mobile_res_toggle_content').toggleClass('responsive_bar');
});


</script>
                          
 `
    },

    ]
    export default HeaderObjects