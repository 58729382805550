const ConatctPageObjects=[
    {
    id:"contact0",
    name:"Contact One",
    height:"768px",
    code:` 
    <head>

    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
      
    </head>
     <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    </style>
  <style>
    * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      font-family: "Poppins", sans-serif;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      width: 100%;
      margin: 0px;
    }
    .contact_container {
      max-width: 800px;
      margin: 0px auto;
    }

    /* page title start */
    .page_title_section {
        text-align: center;
        width: 100%;
        display: block;
        margin-bottom:50px;
        position: relative;
        }
        .page_title_section img{
        width: 100%;
    }
     .page_title_main{
    display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
           position: absolute;
        top: 0px;
    }
    .page_title{
      max-width: 500px;
      margin:0 auto;
    display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
         .page_title_main .page_title h2 {
          font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 40px;
    line-height: 59px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin:0px;    
    
    }
     .page_title_main .page_title p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
    padding-top:8px;
    }
    
    @media(max-width:540px){
     .page_title_main .page_title h2 {
        font-size: 28px !important;
    }
    
    .page_title_section{
       margin-bottom:30px;
    }
    }
    /* page title end */

    /* contact section one start */
    .contact_section_one {
      display: block;
      width: 100%;
      padding:0px 15px 60px 15px;
    }
    .contact_section_one_parent {
      display: flex;
      width: 100%;
      align-items: flex-end;
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
      position: relative;
      justify-content: flex-end;
    }

    .contact_section_one_box{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;
      width: 100%;
      gap: 25px 0px;
      padding: 60px 20px;
      border-radius: 4px;
      max-width: 600px;
    }
    .contact_section_one_box_heading {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;
      width: 100%;
      gap: 5px 0px;
    }
    .contact_section_one_box_heading h3 {
      font-size: 29px;
      line-height: 36px;
      color: #000;
      font-weight: 600;
      margin: 0px;
    }

    .contact_page_form_content {
      display: grid;
      width: 100%;
      grid-template-columns: auto;
      grid-template-rows: auto;
      gap: 20px 0px;
    }
    .contact_us_form_box {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 20px 20px;
    }
    .contact_us_form_fields {
      display: grid;
      width: 100%;
      grid-template-columns: auto;
      grid-template-rows: auto;
      gap: 10px 0px;
      position: relative;
    }
    .contact_us_form_fields span {
      position: absolute;
      left: 0px;
      padding: 5px 0px;
      transition: 0.5s;
      pointer-events: none;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
    .contact_us_form_fields input {
      padding: 5px 0px;
      resize: none;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.9);
      font-family: "Poppins";
      width: 100%;
      border: none;
      outline: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
      height: 30px;
    }
    .contact_us_form_fields input:focus ~ span,
    .contact_us_form_fields textarea:focus ~ span {
      transform: translateY(-20px);
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      color: #095a55;
    }
    .contact_us_form_fields input:valid ~ span,
    .contact_us_form_fields textarea:valid ~ span {
      transform: translateY(-20px);
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      color: #095a55;
    }
    .contact_us_form_fields textarea {
      padding: 5px 0px;
      resize: none;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.9);
      font-family: "Poppins";
      width: 100%;
      border: none;
      outline: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    }

    .contact_us_form_fields label.error {
      color: #cc0000;
      text-transform: capitalize;
      margin: 0px !important;
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 11px;
    }
    .contact_us_form_submit_btn {
      display: flex;
      width: 100%;
    }
    .contact_us_form_submit_btn button {
      color: #ffffff;
      background: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 211px;
      height: 56px;
      cursor: pointer;
      border: 2px solid #095a55;
      background-color: #095a55;
      border-radius: 4px;
    }

   
    .contact_section_one_data_parent {
      position: absolute;
      top: 0px;
      left: 0px;
      transform: translatex(-50%);
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }
    .contact_section_one_data_box{
      background: #095a55;
      box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.3);
      height: Calc(100% - 80px);
      max-width: 300px;
      width: 300px;
      padding: 18px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .contact_section_one_data_content{
    display: flex;
    flex-direction: column;
    }
    .contact_section_one_data_box_heading{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;
      width: 100%;
      padding-bottom: 20px;
    }
    .contact_section_one_data_box_heading h3 {
      font-size: 29px;
      line-height: 36px;
      color: #ffffff;
      font-weight: 600;
      margin: 0px;
    }
    .contact_section_one_data_detail_list {
      display: grid;
      width: 100%;
      grid-template-columns: auto;
      gap: 25px 0px;
      padding-bottom: 20px;
      z-index: 999;
    }

    .contact_section_one_data_single_list {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .contact_section_one_data_single_list i {
      padding-right: 10px;
      font-size: 18px;
      color: #ffffff;
    }
    .contact_section_one_data_single_list small{
      font-size: 14px;
      font-weight: normal;
      margin: 0px;
      color: #ffffff;
      text-transform: capitalize;
      line-height: 24px;
    }
    .contact_section_one_data_single_list a{
      font-size: 14px;
      font-weight: normal;
      margin: 0px;
      color: #ffffff;
      text-transform: capitalize;
      line-height: 24px;
      text-decoration: none;
    }
    .contact_section_one_data_single_list a.gmail_link{
text-transform: lowercase !important;
    }
    .contact_section_one_data_single_list a:hover{
      color: #ffffff;
    }
    .contact_section_one_data_box_socail_link{
      display: flex;
      align-content: center;
      width: 100%;
      z-index: 9999;
    }
    .contact_section_one_data_box_socail_link a{
      color: #fff;
      text-decoration: none;
      padding-right: 20px;
      cursor: pointer;
    }
    .contact_section_one_data_box_socail_link a:hover{
      color: #ffffff;
    }
    .contact_section_one_data_box_socail_link i {
      color: #ffffff;
      font-size: 20px;
    }
    .contact_section_one_data_box_socail_link a:last-child {
      padding-right: 0px;
    }

  

    @media (max-width: 1150px) {
      .contact_section_one_parent {
        display: grid;
        grid-template-columns: 1fr auto;
        direction: rtl;
        width: 100%;
      }
      .contact_section_one_data_parent {
        position: unset;
        transform: unset;
        direction: ltr;
      }
      .contact_section_one_box {
        padding: 35px 20px;
        direction: ltr;
      }
      .contact_section_one_data_box{
        height: 100%;
        padding: 35px 20px;
      }
      
    }
    @media (max-width: 1024px) {
      .contact_us_form_box {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    
    }
    @media (max-width: 768px) {
      .contact_section_one_parent{
 grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
      .contact_section_one_box {
    margin: 0 auto;
}
      .contact_section_one_data_parent {
        background: #095a55;
        box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.3);
      }
      .contact_section_one_data_box {
        width: 100%;
        max-width: 600px;
        background: unset;
        box-shadow: unset;
        margin: 0 auto;
        background: unset;
        box-shadow: unset;
      }
      .contact_section_one_data_box_socail_link {
        padding-top: 40px;
      }
      .js-tilt-glare {
    display: none;
}
    }
    @media (max-width: 540px) {
      .contact_us_form_box {
        grid-template-columns: 1fr;
      }
     
      .contact_section_one_box_heading h3 {
        font-size: 24px;
      }
    }
     /* contact section one end */

  </style>


   
    <section class="page_title_section">
      <img src="https://static.tossdown.com/images/ebfeb2d3-e524-4c8e-80d1-42bfd8c85c21.png">
      <div class="page_title_main">
      <div class="page_title">
      <h2>GET IN TOUCH</h2>
      </div>
      </div>
      </section>

    <section class="contact_section_one">
      <div class="contact_container">
        <div class="contact_section_one_parent">
          <div class="contact_section_one_box">
            <div class="contact_section_one_box_heading">
              <h3>Have Any Question?</h3>
            </div>
            <form
              method="POST"
              action="website/new_contact_us"
              id="quick_contact_new_recap"
              novalidate="novalidate"
            >
              <div class="contact_page_form_content">
                <div class="contact_us_form_fields">
                  <input type="text" required="" name="name" value="" />
                  <span>Full name</span>
                  <label for="name" generated="true" class="error"></label>
                </div>
                <div class="contact_us_form_box">
                  <div class="contact_us_form_fields">
                    <input type="text" required="" name="email" value="" />
                    <span>Email</span>
                    <label class="error" generated="true" for="email"></label>
                  </div>
                  <div class="contact_us_form_fields">
                    <input type="text" required="" name="mobile" value="" />
                    <span>Phone</span>
                    <label for="mobile" generated="true" class="error"></label>
                  </div>
                </div>
                <div class="contact_us_form_fields">
                  <textarea
                    cols="20"
                    rows="4"
                    name="message"
                    required
                  ></textarea>
                  <span>Write your message here.....</span>
                  <label for="message" generated="true" class="error"></label>
                </div>
                <!-- <input type="hidden" name="contact_us_id" value="1" /> -->
                <!-- <?php
         echo '<div id="recaptcha" class="g-recaptcha"
                   data-sitekey="'.$recaptcha_site_key.'"
                   data-callback="onSubmit"
                   data-size="invisible">';
         echo '</div>';
         ?> -->

                <div class="contact_us_form_submit_btn">
                  <button id="submit-recaptcha" type="submit">
                    Submit Message
                  </button>
                </div>
              </div>
            </form>
            <!-- <script>onload();</script> -->
          </div>

          <div class="contact_section_one_data_parent">
            <div class="contact_section_one_data_box contact_section_one_data_box_effect">
              <div class="contact_section_one_data_content">
                <div class="contact_section_one_data_box_heading">
                  <h3>Contact Info</h3>
                </div>
                <div class="contact_section_one_data_detail_list">
                  <div class="contact_section_one_data_single_list">
                    <i class="fas fa-map-marker-alt"></i>
                    <small>2965 Hazelton Place Mississauga, ON L5M6J3</small>
                  </div>
                  <div class="contact_section_one_data_single_list">
                    <i class="fas fa-envelope"></i>
                    <a class="gmail_link" href="info@gmail.com">info@gmail.com</a>
                  </div>
                  <div class="contact_section_one_data_single_list">
                    <i class="fas fa-phone-alt"></i>
                    <a href="0302-4365-245">0302-4365-245</a>
                  </div>
                </div>
              </div>
              <div class="contact_section_one_data_box_socail_link">
                <a><i class="fab fa-facebook-f"></i></a>
                <a><i class="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   <!-- tilt js cdn for cool effect -->
   <script src="https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.4.1/vanilla-tilt.min.js"></script> 
   <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script type="text/javascript">
      //to apply on all child use All with query selector
      VanillaTilt.init(document.querySelector(".contact_section_one_data_box_effect"), {
        max: 25,
        speed: 400,
        glare: true,
        "max-glare": 1,
      });

      //It also supports NodeList
      // VanillaTilt.init(document.querySelectorAll(".your-element"));
    </script>
    
    `
    },
    {
      id:"contact1",
      name:"Contact Two",
      height:"768px",
        code:`
      
        <head>

        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        /> 
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
        </head>
         <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        </style>
      <style>
        * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
          font-family: "Poppins", sans-serif;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          width: 100%;
          margin: 0px;
          background: #fff;
        }
        .contact_container {
          max-width:1024px;
          margin: 0px auto;
        }
    
        /* page title start */
        .page_title_section {
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom:50px;
            position: relative;
            }
            .page_title_section img{
            width: 100%;
        }
         .page_title_main{
        display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
               position: absolute;
            top: 0px;
        }
        .page_title{
          max-width: 500px;
          margin:0 auto;
        display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
        }
             .page_title_main .page_title h2 {
              font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 40px;
        line-height: 59px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin:0px;    
        
        }
         .page_title_main .page_title p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.8);
        padding-top:8px;
        }
        
        @media(max-width:540px){
         .page_title_main .page_title h2 {
            font-size: 28px !important;
        }
        
        .page_title_section{
           margin-bottom:30px;
        }
        }
        /* page title end */
    
        /* contact section one start */
        .contact_section_one {
          display: block;
          width: 100%;
          padding:0px 15px 60px 15px;
        }
        .contact_section_one_parent {
          display: grid;
        grid-template-columns: 1.5fr 1fr;
        gap: 30px 100px;
        align-items: center;
        }
    
        .contact_section_one_box{
          display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
        width: 100%;
        gap: 37px 0px;
        align-items: center;
        }
        .contact_section_one_box_heading {
          display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
        width: 100%;
        gap: 10px 0px;
        align-items: center;
        }
        .contact_section_one_box_heading h3 {
          font-size: 29px;
          line-height: 36px;
          color: #000;
          font-weight: 600;
          margin: 0px;
        }
        .contact_section_one_box_heading p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.6);
    }
    
        .contact_page_form_content {
          display: grid;
        width: 100%;
        grid-template-columns: auto;
        grid-template-rows: auto;
        }
        .contact_us_form_box {
          display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 0px 10px;
        }
        .contact_us_form_fields {
          display: grid;
        width: 100%;
        grid-template-columns: auto;
        grid-template-rows: auto;
        gap: 7px 0px;
        padding-bottom: 10px;
        }
        .contact_us_form_fields span {
          font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        color: #000000;
        }
        .contact_us_form_fields input {
          background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 52px;
        padding: 0px 15px 0px 15px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.8);
        width: 100%;
        }
        
        .contact_us_form_fields textarea {
          background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.8);
        width: 100%;
        padding: 13px;
        }
    
        .contact_us_form_fields label.error {
          color: #cc0000;
          text-transform: capitalize;
          margin: 0px !important;
          font-family: "Poppins";
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 11px;
        }
        .contact_us_form_submit_btn {
          display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-top: 8px;
        }
        .contact_us_form_submit_btn button i {
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
        padding-right: 8px;
    }
        .contact_us_form_submit_btn button {
          border: 1px solid #004029;
        background: #004029;
        border-radius: 52px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        cursor: pointer;
        }
    
        .contact_detail_box {
          background: #FCFAF7;
          border-radius: 0px 4px 4px 0px;
          padding: 50px 45px;
          display: flex;
          flex-direction: column;
      }
      .contact_detail_box_heading h2 {
          font-family: 'Poppins';
          font-style: normal;
             font-weight: 700 !important;
          font-size: 30px;
          line-height: 40px;
          color: #000000;
          padding-bottom: 29px;
          margin: 0px;
      }
      .contact_detail_box_data {
          display: flex;
          flex-direction: column;
      }
      .contact_detail_box_single_data_box {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          padding-bottom: 13px;
          margin-bottom: 16px;
      }
      .contact_detail_box_single_data_box:last-child{
        margin-bottom: 0px;
      }
      .contact_detail_box_single_data_box h3 {
          font-family: 'Poppins';
          font-style: normal;
             font-weight: 600 !important;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          padding-bottom: 14px;
          margin: 0px;
      }
      .contact_detail_box_single_data_box span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.6);
          padding-bottom: 4px;
      }
      .contact_detail_box_single_data_box a {
          display: flex;
          align-items: center;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.6);
      }
      .contact_detail_box_single_data_box a i {
          color: #000000;
          padding-right: 8px;
          font-weight: 400;
          font-size: 14px;
          padding-top: 2px;
      }
      @media (max-width: 1024px) {
        .contact_section_one_parent {
          gap: 30px 50px;
      }
      .contact_detail_box {
          padding: 40px 25px;
      }
      .contact_detail_box_single_data_box a {
          font-size: 12px;
      }
      .contact_detail_box_single_data_box a i{
          font-size: 12px;
      }
      }
          @media (max-width: 768px) {
            .contact_section_one_parent {
          grid-template-columns: 1fr;
      }
      }
          @media (max-width: 540px) {
            .contact_us_form_box {
              grid-template-columns: 1fr;
            }
          }
    
    
         /* contact section one end */
    
      </style>
    
     
       
        <section class="page_title_section">
          <img src="https://static.tossdown.com/images/ebfeb2d3-e524-4c8e-80d1-42bfd8c85c21.png">
          <div class="page_title_main">
          <div class="page_title">
          <h2>GET IN TOUCH</h2>
          </div>
          </div>
          </section>
    
        <section class="contact_section_one">
          <div class="contact_container">
            <div class="contact_section_one_parent">
              <div class="contact_section_one_box">
                <div class="contact_section_one_box_heading">
                  <h3>Contact Us</h3>
                  <p>Our team would love to hear from you!</p>
                </div>
                <form
                  method="POST"
                  action="website/new_contact_us"
                  id="quick_contact_new_recap"
                  novalidate="novalidate"
                >
                  <div class="contact_page_form_content">
                    <div class="contact_us_form_box">
                      <div class="contact_us_form_fields">
                        <span>Name</span>
                        <input type="text" placeholder="Enter your full name" required="" name="name" value="" />
                        <label for="name" generated="true" class="error"></label>
                      </div>
                      <div class="contact_us_form_fields">
                        <span>Phone Number</span>
                        <input type="text" placeholder="Enter your phone number" required="" name="mobile" value="" />
                        <label for="mobile" generated="true" class="error"></label>
                      </div>
                    </div>
                    <div class="contact_us_form_fields">
                      <span>Email</span>
                      <input type="text" placeholder="Enter your email address" required="" name="email" value="" />
                      <label class="error" generated="true" for="email"></label>
                    </div>
                   
                    <div class="contact_us_form_fields">
                      <span>Message</span>
                      <textarea
                        cols="20"
                        rows="4"
                        name="Enter your message"
                        required
                      ></textarea>
                      <label for="message" generated="true" class="error"></label>
                    </div>
                    <!-- <input type="hidden" name="contact_us_id" value="1" /> -->
                    <!-- <?php
             echo '<div id="recaptcha" class="g-recaptcha"
                       data-sitekey="'.$recaptcha_site_key.'"
                       data-callback="onSubmit"
                       data-size="invisible">';
             echo '</div>';
             ?> -->
    
                    <div class="contact_us_form_submit_btn">
                      <button id="submit-recaptcha" type="submit">
                        <i class="fad fa-paper-plane" aria-hidden="true"></i>
                        Submit Message
                      </button>
                    </div>
                  </div>
                </form>
                <!-- <script>onload();</script> -->
              </div>
              <div class="contact_detail_box">
                <div class="contact_detail_box_heading">
                <h2>GET IN TOUCH</h2>
                </div>
                <div class="contact_detail_box_data">
                <div class="contact_detail_box_single_data_box">
                <h3>USA</h3>
                <span>Isma Chaudhry</span> <a><i class="fal fa-envelope" aria-hidden="true"></i> test@gmail.com</a></div>
                <div class="contact_detail_box_single_data_box">
                <h3>CANADA</h3>
                <span>Kamran Malik</span> <a><i class="fal fa-envelope" aria-hidden="true"></i>test@gmail.com</a></div>
                <div class="contact_detail_box_single_data_box">
                <h3>UK</h3>
                <span>Tuba Bajwa</span> <a><i class="fal fa-envelope" aria-hidden="true"></i>test@sky.com</a></div>
                <div class="contact_detail_box_single_data_box">
                <h3>PAKISTAN</h3>
                <span>Nadeem Malik</span> <a><i class="fal fa-envelope" aria-hidden="true"></i>test@hotmail.com</a></div>
                </div>
                </div>
    
            </div>
          </div>
        </section>


        `,
        },
        {
          id:"contact3",
          name:"Contact Three",
          height:"768px",
            code:`


            <head>

            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            /> 
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
            </head>
             <style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            </style>
          <style>
            * {
              -web-kit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
              font-family: "Poppins", sans-serif;
            }
            html {
              scroll-behavior: smooth;
            }
            body {
              float: left;
              width: 100%;
              margin: 0px;
              background: #fff;
            }
            .contact_container {
              max-width:768px;
              margin: 0px auto;
            }
        
            /* page title start */
            .page_title_section {
                text-align: center;
                width: 100%;
                display: block;
                margin-bottom:50px;
                position: relative;
                }
                .page_title_section img{
                width: 100%;
            }
             .page_title_main{
            display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                height: 100%;
                   position: absolute;
                top: 0px;
            }
            .page_title{
              max-width: 500px;
              margin:0 auto;
            display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-direction: column;
            }
                 .page_title_main .page_title h2 {
                  font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 40px;
            line-height: 59px;
            text-transform: capitalize;
            color: #FFFFFF;
            margin:0px;    
            
            }
             .page_title_main .page_title p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.8);
            padding-top:8px;
            }
            
            @media(max-width:540px){
             .page_title_main .page_title h2 {
                font-size: 28px !important;
            }
            
            .page_title_section{
               margin-bottom:30px;
            }
            }
            /* page title end */
        
            /* contact section one start */
            .contact_section_one {
              display: block;
              width: 100%;
              padding:0px 15px 60px 15px;
            }
            .contact_section_one_parent {
              display: grid;
            gap: 30px;
            align-items: center;
            }
        
            .contact_section_one_box{
              display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto;
            width: 100%;
            gap: 37px 0px;
            align-items: center;
            }
            .contact_section_one_box_heading {
              display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto;
            width: 100%;
            gap: 10px 0px;
            align-items: center;
            text-align: center;
            }
            .contact_section_one_box_heading h3 {
              font-size: 29px;
              line-height: 36px;
              color: #000;
              font-weight: 600;
              margin: 0px;
            }
            .contact_section_one_box_heading p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-wrap: wrap;
        }
        .contact_section_one_box_heading p span a{
          font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: rgba(0, 0, 0, 0.9);
            text-decoration: none;
        }
        .contact_section_one_box_heading p span a:hover{
          color: rgba(0, 0, 0, 0.9);
        }
            .contact_page_form_content {
              display: grid;
            width: 100%;
            grid-template-columns: auto;
            grid-template-rows: auto;
            }
            .contact_us_form_box {
              display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            gap: 0px 10px;
            }
            .contact_us_form_fields {
              display: grid;
            width: 100%;
            grid-template-columns: auto;
            grid-template-rows: auto;
            gap: 7px 0px;
            padding-bottom: 10px;
            }
            .contact_us_form_fields span {
              font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            color: #000000;
            }
            .contact_us_form_fields input {
              background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            height: 52px;
            padding: 0px 15px 0px 15px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            color: rgba(0, 0, 0, 0.8);
            width: 100%;
            border-radius: 78px;  
          }
            
            .contact_us_form_fields textarea {
              background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            color: rgba(0, 0, 0, 0.8);
            width: 100%;
            padding: 13px;
            border-radius: 26px;
            }
        
            .contact_us_form_fields label.error {
              color: #cc0000;
              text-transform: capitalize;
              margin: 0px !important;
              font-family: "Poppins";
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 11px;
            }
            .contact_us_form_submit_btn {
              display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            }
            .contact_us_form_submit_btn button i {
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
            padding-right: 8px;
        }
            .contact_us_form_submit_btn button {
              border: 1px solid #004029;
            background: #004029;
            border-radius: 52px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 250px;
            height: 56px;
            cursor: pointer;
            }
        
        
          @media (max-width: 1024px) {
            
          }
              @media (max-width: 768px) {
              
          }
              @media (max-width: 540px) {
                .contact_us_form_box {
                  grid-template-columns: 1fr;
                }
              }
        
        
             /* contact section one end */
        
          </style>
        
         
           
            <section class="page_title_section">
              <img src="https://static.tossdown.com/images/ebfeb2d3-e524-4c8e-80d1-42bfd8c85c21.png">
              <div class="page_title_main">
              <div class="page_title">
              <h2>GET IN TOUCH</h2>
              </div>
              </div>
              </section>
        
            <section class="contact_section_one">
              <div class="contact_container">
                <div class="contact_section_one_parent">
                  <div class="contact_section_one_box">
                    <div class="contact_section_one_box_heading">
                      <h3>Taking your Business to a Next Level.</h3>
                      <p>Fill out the form below to reach us or <span>call us at: <a href="+1 972 704 6869">+1 972 704 6869</a></span></p>
                    </div>
                    <form
                      method="POST"
                      action="website/new_contact_us"
                      id="quick_contact_new_recap"
                      novalidate="novalidate"
                    >
                      <div class="contact_page_form_content">
                        <div class="contact_us_form_box">
                          <div class="contact_us_form_fields">
                            <span>Name</span>
                            <input type="text" placeholder="Enter your full name" required="" name="name" value="" />
                            <label for="name" generated="true" class="error"></label>
                          </div>
                          <div class="contact_us_form_fields">
                            <span>Phone Number</span>
                            <input type="text" placeholder="Enter your phone number" required="" name="mobile" value="" />
                            <label for="mobile" generated="true" class="error"></label>
                          </div>
                        </div>
                        <div class="contact_us_form_fields">
                          <span>Email</span>
                          <input type="text" placeholder="Enter your email address" required="" name="email" value="" />
                          <label class="error" generated="true" for="email"></label>
                        </div>
                       
                        <div class="contact_us_form_fields">
                          <span>Message</span>
                          <textarea
                          placeholder="Type your message here...."
                            cols="20"
                            rows="4"
                            name="Enter your message"
                            required
                          ></textarea>
                          <label for="message" generated="true" class="error"></label>
                        </div>
                        <!-- <input type="hidden" name="contact_us_id" value="1" /> -->
                        <!-- <?php
                 echo '<div id="recaptcha" class="g-recaptcha"
                           data-sitekey="'.$recaptcha_site_key.'"
                           data-callback="onSubmit"
                           data-size="invisible">';
                 echo '</div>';
                 ?> -->
        
                        <div class="contact_us_form_submit_btn">
                          <button id="submit-recaptcha" type="submit">
                            <i class="fad fa-paper-plane" aria-hidden="true"></i>
                            Submit Message
                          </button>
                        </div>
                      </div>
                    </form>
                    <!-- <script>onload();</script> -->
                  </div>
                 
                </div>
              </div>
            </section>
            
            `,
            },    
    
    ]
    export default ConatctPageObjects  