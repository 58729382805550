import React from "react";

const Home = (props) => {


  return (
    <>

        <div className="content-section-main">
        <div className="content-container">
        <div className="responsive_content_manage">
          <div className="pagination-list">
            <h5>{props.name}</h5>
          </div>
          <div className="home-page-box">
            <h1>COMPONENTS OVERVIEW</h1>
            <p>
              Our component explorer gives you an easy access to 1000+ (free and
              paid) Tailwind CSS components to build beautiful, repsonsive and
              accessible user interfaces in record time.
            </p>
          </div>
          <div className="home-page-components">
            <div className="home-page-single-component">
              <img src="image/boxed_layout.svg" alt="img 1" />
            </div>
            <div className="home-page-single-component">
              <img src="image/boxed_layout.svg" alt="img 2" />
            </div>
            <div className="home-page-single-component">
              <img src="image/boxed_layout.svg" alt="img 3" />
            </div>
          </div>
        </div>
        </div>
        </div>

    </>
  );
};

export default Home;
