const FooterObjects=[
{
id:"footer0",
name:"Footer One",
height:"452px",
head:`
<head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>

`,

code:` 


<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>

<style>
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  margin: 0px;
  width: 100%;
}
.custom-container{
    max-width: 1140px;
    margin: 0 auto;
  }
  .footer-section{
  background: #f5f5f5;
    color: #fff;
    float: left;
    width: 100%;
    padding: 70px 15px 50px 15px;
  }
  
  .footer-data-box{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". . . . .";
    gap: 30px 60px;
  }
  .footer-detail-box-one{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .footer-detail-box-one figure{
  margin: 0px !important;
  width: 85px !important;
  padding-bottom: 24px;
  }
  
  .footer-detail-box-one figure img{
    width: 100%;
  }
  .footer-detail-box-one p{
    font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin: 0px !important;
  font-family: Montserrat;
  padding-bottom: 24px;
  }
  .footer-socail-links{
      display: grid;
      gap: 20px 0px;
  }
  .footer-socail-links a{
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.7);
      transform: rotate( 
  -45deg);
      justify-content: center;
  border: 1px dashed rgba(0, 0, 0, 0.7);
  }
  .footer-socail-links a i{
  font-size: 20px;
      transform: rotate( 
  45deg);
  color: rgba(0, 0, 0, 0.7);
    
  }
  
  .footer-detail-box-two{
    display: flex;
  flex-direction: column;
  width: 100%;
  }
  .footer-detail-box-heading {
    position: relative;
  padding-bottom:15px;
  }
  .footer-detail-box-heading h3{
    font-family: Montserrat;
    margin: 0px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  }
  .footer-detail-box-content-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }
  .footer-detail-box-content-list li{
    list-style: none;
    display: flex;
    align-items: center;
  padding-bottom:8px;
  }
  .footer-detail-box-content-list li:last-child{
    padding-bottom: 0px;
  }
  .footer-detail-box-content-list li a{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  }
  .footer-detail-box-content-list li a:hover{
    color: rgba(0, 0, 0, 0.7);
  }
  
  .footer-contact-list{
    display: grid;
    gap: 8px 0px;
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }
  .footer-contact-list li{
    list-style: none;
    display: flex;
  }
  .footer-contact-list li:last-child{
    padding-bottom: 0px;
  }
  .footer-contact-list li i{
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.7);
  padding-right:8px;
  }
  .footer-contact-list li a{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  }
  
@media(max-width: 1024px) {
    .footer-data-box {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: ". . .";
  }
  
  }
  @media(max-width: 768px) {
    .footer-data-box {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: ". .";

  }
  
  }
  @media(max-width: 540px) {
    .footer-data-box{
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    gap: 30px 0px;
     }
  .footer-detail-box-one {
      justify-content: center;
      align-items: center;
  }
  .footer-detail-box-one p {
    padding-right: 0px;
   text-align: center;
}
  .footer-detail-box-heading {
padding-bottom: 0px; 
}
.footer-contact-list {
padding-top: 15px !important;
}
.footer-detail-box-content-list {
padding-top: 15px !important;
}
  .footer-socail-links {
        padding-top: 15px;
      justify-content: center;
      align-items: center;
  }
  .panel {
      display: none;
      overflow: hidden;
      transition:0.5s ease-out;
      text-align: left;
  }
  

  .footer-detail-box-heading.accordion:after {
    content: '\\002B';
    color: rgba(0, 0, 0, 0.7);
  font-family: "revicons";
  font-size: 26px;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0px;
  top: 0px;
  right: 0px;
  }
  .footer-detail-box-heading.active:after {
    content: "\\2212" !important;
    color: rgba(0, 0, 0, 0.7);
  font-family: "revicons";
  font-size: 21px;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0px;
  top: 0px;
  right: 0px;
  }


  }


  @media(min-width:540px){
  .modcontent.panel {
      display: block !important;
  }
  }
</style>  


<section class="footer-section">
<div class="custom-container">
<div class="footer-data-box">
<div class="footer-data-box-detail">
<div class="footer-detail-box-one">
<figure><img src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"></figure>
</div>
</div>
<div class="footer-data-box-detail">
<div class="footer-detail-box-two">
<div class="footer-detail-box-heading accordion modtitle">
<h3 class="footer-collapse-icons">Site links</h3>
</div>
<div class="modcontent panel">
<ul class="footer-detail-box-content-list">
<li><a>Home</a></li>
<li><a >Custom order</a></li>
<li><a >Shop</a></li>
<li><a>Offers</a></li>
<li><a >Location</a></li>
<li><a >About</a></li>
</ul>
</div>
</div>
</div>
<div class="footer-data-box-detail">
<div class="footer-detail-box-two">
<div class="footer-detail-box-heading accordion modtitle">
<h3 class="footer-collapse-icons">Useful links</h3>
</div>
<div class="modcontent panel">
<ul class="footer-detail-box-content-list">
<li><a>Login</a></li>
<li><a>privacy policy</a></li>
<li><a>Delivery policy</a></li>
<li><a>Faq’s</a></li>
<li><a href="/contact">Contact</a></li>
</ul>
</div>
</div>
</div>
<div class="footer-data-box-detail">
<div class="footer-detail-box-two">
<div class="footer-detail-box-heading accordion modtitle">
<h3 class="footer-collapse-icons">Contact us</h3>
</div>
<div class="modcontent panel accordion modtitle">
<ul class="footer-contact-list ">
<li><i class="fal fa-phone-alt" aria-hidden="true"></i><a href="tel:+92 1122333">+92 1122333</a></li>
<li><i class="fal fa-envelope" aria-hidden="true"></i><a href="mailto:info@gmail.com">info@gmail.com</a></li>
</ul>
</div>
</div>
</div>
<div class="footer-data-box-detail">
<div class="footer-detail-box-two ">
<div class="">
<div class="footer-socail-links"><a><i class="fab fa-facebook-f" aria-hidden="true"></i></a> <a><i class="fab fa-instagram" aria-hidden="true"></i></a></div>
</div>
</div>
</div>
</div>
</div>
</section>

   <script>
    var acc = document.getElementsByClassName("accordion");
    var i;
    
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
    </script> 

`
},
{
    id:"footer1",
    name:"Footer Two",
    height:"452px",
    head:`
    <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        /> 
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
        
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
    
      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
      <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
      
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
      
    </head>
    
    `,

    code:`

    <style>
      @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
      </style>
    
    <style>
    * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      margin: 0px;
      width: 100%;
    }
    .custom-container{
        max-width: 1140px;
        margin: 0 auto;
      }
      .footer-section{
        background: #000000;
  color: #fff;
  float: left;
  width: 100%;
  padding: 60px 15px;
      }
      
      .footer-data-box{
        display: grid;
  width: 100%;
  grid-template-columns: 1.6fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". . . . .";
  gap: 30px 20px;
      }
      .footer-detail-box-one{
      display: flex;
      flex-direction: column;
      width: 100%;
      }
      .footer-detail-box-one figure{
      margin: 0px !important;
      width: 85px !important;
      padding-bottom: 24px;
      }
      
      .footer-detail-box-one figure img{
        width: 100%;
      }
      .footer-detail-box-one p{
        font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0px !important;
  padding-right: 23px;
  text-align: left;
  padding-bottom: 24px;
      }
      .footer-socail-links{
        display: flex;
      align-items: center;
      padding-bottom: 20px;
      }
      .footer-socail-links a{
        background: rgba(255, 255, 255, 0.2);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  margin-right: 10px;
      }
      .footer-socail-links a:last-child{
        margin-right: 0px;
      }
      .footer-socail-links a i{
        color: #FFFFFF;
        font-size: 16px;
      }
      
      .footer-detail-box-two{
        display: flex;
      flex-direction: column;
      width: 100%;
      }
      .footer-detail-box-heading {
        position: relative;
        padding-bottom:12px;
      }
      .footer-detail-box-heading h3{
        margin: 0px !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #FFFFFF;
      }

      .footer-detail-box-content-list{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px !important;
        margin: 0px !important;
      }
      .footer-detail-box-content-list li{
        list-style: none;
        display: flex;
        align-items: center;
      padding-bottom:8px;
      }
      .footer-detail-box-content-list li:last-child{
        padding-bottom: 0px;
      }
      .footer-detail-box-content-list li a{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.6);
      text-decoration: none;
      }
      .footer-detail-box-content-list li a:hover{
        color: rgba(255, 255, 255, 0.6);
      }
      
      .footer-contact-list{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px !important;
        margin: 0px !important;
      }
      .footer-contact-list li{
        list-style: none;
        display: flex;
      padding-bottom:8px;
      }
      .footer-contact-list li:last-child{
        padding-bottom: 0px;
      }
      .footer-contact-list li i{
        padding-right: 8px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.6);
      }
      .footer-contact-list li a{
        font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.6);
      text-decoration: none;
      }
      
    @media(max-width: 1024px) {
        .footer-data-box {
          grid-template-columns: 1.6fr 1fr 1fr;
          grid-template-areas: ". . .";
      }
      
      }
      @media(max-width: 768px) {
        .footer-data-box {
          grid-template-columns: 1.6fr 1fr;
  grid-template-areas: ". .";
    
      }
      
      }
      @media(max-width: 540px) {
        .footer-data-box{
        grid-template-columns: 1fr;
        grid-template-areas: ".";
        gap: 30px 0px;
         }
      .footer-detail-box-one {
          justify-content: center;
          align-items: center;
      }
      .footer-detail-box-one p {
       padding-right: 0px;
      text-align: center;
}
      .footer-detail-box-heading {
 padding-bottom: 0px; 
}
.footer-contact-list {
  padding-top: 15px !important;
}
.footer-detail-box-content-list {
  padding-top: 15px !important;
}
      .footer-socail-links {
       
          justify-content: center;
          align-items: center;
      }
      .panel {
          display: none;
          overflow: hidden;
          transition:0.5s ease-out;
          text-align: left;
      }
      
    
      .footer-detail-box-heading.accordion:after {
        content: '\\002B';
        color: #fff;
      font-family: "revicons";
      font-size: 26px;
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0px;
      top: 0px;
      right: 0px;
      }
      .footer-detail-box-heading.active:after {
        content: "\\2212" !important;
        color: #fff;
      font-family: "revicons";
      font-size: 21px;
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0px;
      top: 0px;
      right: 0px;
      }
    
    
      }
    
    
      @media(min-width:540px){
      .modcontent.panel {
          display: block !important;
      }
      }
    </style>  
    
  
    <section class="footer-section">
      <div class="custom-container">
      <div class="footer-data-box">
      <div class="footer-data-box-detail ">
      <div class="footer-detail-box-one">
      <figure><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png"></figure>
      <p>Lorem ipsum dolor sit amet, conse
      ctetur adipiscing elit. Aenean sita
      met lectus lectus. Fusce vel dignis
      sim ipsum, sed mollis ex. </p>
      <div class="footer-socail-links">
        <a  target="blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a> 
        <a  target="blank"><i class="fab fa-instagram" aria-hidden="true"></i></a>
      </div>
      </div>
      </div>
      <div class="footer-data-box-detail">
      <div class="footer-detail-box-two">
      <div class="footer-detail-box-heading accordion modtitle">
      <h3 class="footer-collapse-icons">Site Links</h3>
      </div>
      <div class="modcontent panel">
      <ul class="footer-detail-box-content-list">
      <li><a>Link 1</a></li>
      <li><a>Link 2</a></li>
      <li><a>Link 3</a></li>
      <li><a>Link 4</a></li>
      <li><a>Link 5</a></li>
      </ul>
      </div>
      </div>
      </div>
      <div class="footer-data-box-detail">
      <div class="footer-detail-box-two">
      <div class="footer-detail-box-heading accordion modtitle">
      <h3 class="footer-collapse-icons">Product categories</h3>
      </div>
      <div class="modcontent panel">
      <ul class="footer-detail-box-content-list">
      <li><a>Category name</a></li>
      <li><a>Category name</a></li>
      <li><a>Category name</a></li>
      <li><a>Category name</a></li>
      <li><a>Category name</a></li>
      <li><a>Category name</a></li>
      </ul>
      </div>
      </div>
      </div>
      <div class="footer-data-box-detail">
      <div class="footer-detail-box-two">
      <div class="footer-detail-box-heading accordion modtitle">
      <h3 class="footer-collapse-icons">Useful links</h3>
      </div>
      <div class="modcontent panel accordion modtitle">
      <ul class="footer-detail-box-content-list">
      <li><a>Refund and return policy</a></li>
      <li><a>Privacy policy</a></li>
      <li><a>FAQ’s</a></li>
      <li><a>Cancelation policy</a></li>
      <li><a>Delivery policy</a></li>
      </ul>
      </div>
      </div>
      </div>
      <div class="footer-data-box-detail">
      <div class="footer-detail-box-two ">
      <div class="footer-detail-box-heading accordion modtitle">
      <h3 class="footer-collapse-icons">Contact Us</h3>
      </div>
      <div class="modcontent panel accordion modtitle">
      <ul class="footer-contact-list ">
      <li><i class="fal fa-map-marker-alt" aria-hidden="true"></i><a>Lorem ipsum dolor sit amet, conse
      ctetur adipiscing elit. </a></li>
      <li><i class="fal fa-phone" aria-hidden="true"></i><a href="tel:XXXX XXXXXXX">XXXX XXXXXXX</a></li>
      <li><i class="fal fa-envelope" aria-hidden="true"></i><a href="mailto:abcd@xyz.com">abcd@xyz.com</a></li>
      </ul>
      </div>
      </div>
      </div>
      </div>
      </div>
      </section>
    
       <script>
        var acc = document.getElementsByClassName("accordion");
        var i;
        
        for (i = 0; i < acc.length; i++) {
          acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.display === "block") {
              panel.style.display = "none";
            } else {
              panel.style.display = "block";
            }
          });
        }
        </script> 
  
    `,
    },
    {
        id:"footer2",
        name:"Footer Three",
        height:"350px",
        head:`
        <head>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            /> 
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
            
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
        
          <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
          <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
          
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
          
        </head>
        
        `,

        code:`
       
    <style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>
<style>
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  margin: 0px;
  width: 100%;
}
.custom_container{
    max-width:1140px;
    margin:0 auto;
    }
footer{
    display:block;
    float:left;
    background: #EBEBEB;
        width: 100%;
        height: 100%;
    }
    .footer_main{
    padding:60px 10px 60px 10px;
    position:relative;
    }
    .footer_content_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    }
    .footer_log{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom:24px;
    }
    .footer_log strong img{
    width:133px;
    }
  
    .footer_nav_box{
        display: flex;
        align-items: center;
    padding-bottom:29px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer_nav_box a{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
    padding-right:32px;
    }
    .footer_nav_box a:last-child{
    padding-right:0px;
    }
    .footer_social_links{
    display: flex;
    align-items: center;
    }
    .footer_social_links a{
    Width:36px;
    Height:36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:6px;
    background: #FFFFFF;
    }
    .footer_social_links a i{
    color:#000;
    }
    .footer_social_links a:last-child{
    margin-right:0px;
    }
    @media(max-width:540px){
    .footer_nav_box a {
        padding-right: 15px;
    }
    }
    @media(max-width:440px){
    .footer_nav_box a{
        padding-right: 20px;
        padding-bottom: 12px;
    }
    .footer_nav_box {
        padding-bottom: 30px;
    }
    }


</style>

        <footer>
<div class="custom_container">
<div class="footer_main">
<div class="footer_content_section">
<div class="footer_log"><img src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png"></div>
<div class="footer_nav_box">
<a>Link 1</a>
<a>Link 2</a>
<a>Link 3</a>
<a>Link 4</a>
<a>Link 5</a>
</div>
<div class="footer_social_links">
<a><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
<a><i class="fab fa-instagram" aria-hidden="true"></i></a>
</div>
</div>
</div>
</div>
</footer>
        
        `,
        },   
        
        {
          id:"footer3",
          name:"Footer Four",
          height:"452px",
          head:`
          <head>
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              /> 
              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
              <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
              
              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
          
            <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
            <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
            
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
            
          </head>
          
          `,

          code:`
         
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            </style>
            <script src="https://www.google.com/recaptcha/api.js"></script>
            <script>
             function NewsletteronSubmit(token) {
                 $("#quick_newslatter_recaptcha").submit();
             }
           
             function validate_newslatter(event) {;
                 event.preventDefault();
           
                 $("#quick_newslatter_recaptcha").validate({
                     rules: {
           
                         email: {
                             required: true,
                             email: true
                         },               
                        
                     }
           
                 });
           
                  if ($('#quick_newslatter_recaptcha').valid()) {
                      grecaptcha.execute();
                  }
           
             }
           
              function onload() {
             
                  var element = document.getElementById('newsletter-submit-recaptcha');
                  element.onclick = validate_newslatter;
              }
              
            </script>
          <style>
          * {
            -web-kit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
          }
          html {
            scroll-behavior: smooth;
          }
          body {
            float: left;
            margin: 0px;
            width: 100%;
          }
          .custom-container{
              max-width: 1140px;
              margin: 0 auto;
            }
            .footer-section{
              background: #000000;
        color: #fff;
        float: left;
        width: 100%;
        padding: 70px 15px 90px 15px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('https://static.tossdown.com/images/74cd88f9-089e-4cb4-8a81-a5219cd3003a.png');
            }
            
            .footer-data-box{
              display: grid;
        width: 100%;
        grid-template-columns: 1.2fr 1fr 1fr 1.3fr 2fr;
        grid-template-rows: auto;
        grid-template-areas: ". . . . .";
        gap: 30px 20px;
            }
            .footer-detail-box-one{
            display: flex;
            flex-direction: column;
            width: 100%;
            }
            .footer-detail-box-one figure{
            margin: 0px !important;
            width: 85px !important;
            padding-bottom: 24px;
            }
            
            .footer-detail-box-one figure img{
              width: 100%;
            }
            .footer-detail-box-one p{
              font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.6);
        margin: 0px !important;
        padding-right: 23px;
        text-align: left;
        padding-bottom: 24px;
            }
            .footer-socail-links{
              display: flex;
            align-items: center;
            padding-bottom: 20px;
            }
            .footer-socail-links a{
              background: rgba(255, 255, 255, 0.2);
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        margin-right: 10px;
            }
            .footer-socail-links a:last-child{
              margin-right: 0px;
            }
            .footer-socail-links a i{
              color: #FFFFFF;
              font-size: 16px;
            }
            
            .footer-detail-box-two{
              display: flex;
            flex-direction: column;
            width: 100%;
            }
            .footer-detail-box-heading {
              position: relative;
              padding-bottom:24px;
            }
            .footer-detail-box-heading h3{
              margin: 0px !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #FFFFFF;
            }
    
            .footer-detail-box-content-list{
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 0px !important;
              margin: 0px !important;
            }
            .footer-detail-box-content-list li{
              list-style: none;
              display: flex;
              align-items: center;
            padding-bottom:21px;
            }
            .footer-detail-box-content-list li:last-child{
              padding-bottom: 0px;
            }
            .footer-detail-box-content-list li a{
              font-family: 'Poppins';
              font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.6);
            text-decoration: none;
            }
            .footer-detail-box-content-list li a:hover{
              color: rgba(255, 255, 255, 0.6);
            }
            
            .footer-contact-list{
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 0px !important;
              margin: 0px !important;
            }
            .footer-contact-list li{
              list-style: none;
              display: flex;
            padding-bottom:21px;
            }
            .footer-contact-list li:last-child{
              padding-bottom: 0px;
            }
            .footer-contact-list li i{
              padding-right: 8px;
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.6);
            }
            .footer-contact-list li a{
              font-family: 'Poppins';
              font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.6);
            text-decoration: none;
            }
            
            .footer_newsletter_section {
        display: flex;
        flex-direction: column;
    }
    .footer_newsletter_section h3 {
      font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-bottom: 24px;
    }
    .footer_newsletter_form_fields_content {
        position: relative;
        height: 48px;
        width: 100%;
        margin-bottom: 10px;
    }
    .footer_newsletter_form_fields_content input {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.5);
        padding: 0px 50px 0px 20px;
        background: #FFFFFF;
        border-radius: 2px;
       border: unset;
      }
    .footer_newsletter_form_fields_content input:focus-visible{
                outline: none !important;
              }
              .footer_newsletter_form_fields_content input::placeholder{
                font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.5);
               display: flex;
                  align-items: center;
              }
    
              .footer_newsletter_form_fields_content button {
        background: unset;
        border: unset;
        position: absolute;
        content: "";
        top: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 2px;
    }     
    .footer_newsletter_form_fields_content button i {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width: 40px;
        background: #EC1848;
        border-radius: 2px;
        font-size: 15px;
        line-height: 19px;
        text-transform: capitalize;
        color: #ffffff;
        border: unset;
    }
    .footer_newsletter_form_fields_content button:hover{
              color: #FFFFFF;
              }
              .footer_newsletter_form_fields_content button:focus {
                  outline: 0;
                  box-shadow: unset !important;
              }
              .footer_newsletter_section form label.error{
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              color: #CC0000;
                text-align: left;
                  margin: 0px !important;
                  font-family: 'Poppins';
        
              }
    
          @media(max-width: 1024px) {
              .footer-data-box {
                grid-template-columns: 1.4fr 1.5fr 1.2fr;
        grid-template-areas: ". . .";
            }
            
            }
            @media(max-width: 768px) {
              .footer-data-box {
                grid-template-columns: 1.6fr 1fr;
        grid-template-areas: ". .";
          
            }
            
            }
            @media(max-width: 540px) {
              .footer-data-box{
              grid-template-columns: 1fr;
              grid-template-areas: ".";
              gap: 30px 0px;
               }
            .footer-detail-box-one {
                justify-content: center;
                align-items: center;
            }
            .footer-detail-box-one p {
             padding-right: 0px;
            text-align: center;
    }
            .footer-detail-box-heading {
       padding-bottom: 0px; 
    }
    .footer-contact-list {
        padding-top: 15px !important;
    }
    .footer-detail-box-content-list {
        padding-top: 15px !important;
    }
            .footer-socail-links {
             
                justify-content: center;
                align-items: center;
            }
            .footer_newsletter_section h3 {
        text-align: center;
    }
            .panel {
                display: none;
                overflow: hidden;
                transition:0.5s ease-out;
                text-align: left;
            }
            
          
            .footer-detail-box-heading.accordion:after {
              content: '\\002B';
              color: #fff;
            font-family: "revicons";
            font-size: 26px;
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0px;
            top: 0px;
            right: 0px;
            }
            .footer-detail-box-heading.active:after {
              content: '\\2212' !important;
              color: #fff;
            font-family: "revicons";
            font-size: 21px;
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0px;
            top: 0px;
            right: 0px;
            }
          
          
            }
          
          
            @media(min-width:540px){
            .modcontent.panel {
                display: block !important;
            }
            }
          </style>  
          
       
          <section class="footer-section">
            <div class="custom-container">
            <div class="footer-data-box">
            <div class="footer-data-box-detail ">
            <div class="footer-detail-box-one">
            <figure><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png"></figure>
          
            <div class="footer-socail-links">
              <a  target="blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a> 
              <a  target="blank"><i class="fab fa-instagram" aria-hidden="true"></i></a>
            </div>
            </div>
            </div>
            <div class="footer-data-box-detail">
            <div class="footer-detail-box-two">
            <div class="footer-detail-box-heading accordion modtitle">
            <h3 class="footer-collapse-icons">Site Links</h3>
            </div>
            <div class="modcontent panel">
            <ul class="footer-detail-box-content-list">
            <li><a>Link 1</a></li>
            <li><a>Link 2</a></li>
            <li><a>Link 3</a></li>
            <li><a>Link 4</a></li>
            <li><a>Link 5</a></li>
            </ul>
            </div>
            </div>
            </div>
            <div class="footer-data-box-detail">
            <div class="footer-detail-box-two">
            <div class="footer-detail-box-heading accordion modtitle">
            <h3 class="footer-collapse-icons">Useful Links</h3>
            </div>
            <div class="modcontent panel">
            <ul class="footer-detail-box-content-list">
            <li><a>Login/Signup</a></li>
            <li><a>FAQ’s</a></li>
            <li><a>privacy policy</a></li>
            <li><a>Cancel policy</a></li>
            
            </ul>
            </div>
            </div>
            </div>
            <div class="footer-data-box-detail">
            <div class="footer-detail-box-two">
            <div class="footer-detail-box-heading accordion modtitle">
            <h3 class="footer-collapse-icons">Contact Us</h3>
            </div>
            <div class="modcontent panel accordion modtitle">
              <ul class="footer-contact-list ">
                
                <li><i class="fal fa-phone" aria-hidden="true"></i><a href="tel:XXXX XXXXXXX">XXXX XXXXXXX</a></li>
                <li><i class="fal fa-envelope" aria-hidden="true"></i><a href="mailto:abcd@xyz.com">abcd@xyz.com</a></li>
                <li><i class="fal fa-map-marker-alt" aria-hidden="true"></i><a>Lorem ipsum dolor sit amet, conse
                  ctetur adipiscing elit. </a></li>
                </ul>
            </div>
            </div>
            </div>
                 <div class="footer-data-box-detail">
                 <div class="footer-detail-box-two ">
            
                <div class="footer_newsletter_section">
                <h3>Signup To Newsletter To Get Offers & Discounts!</h3>
                <form method="POST" action="/website/insert_signup_newsletter" id="quick_newslatter_recaptcha" novalidate="novalidate">
                  <div class="footer_newsletter_form_fields_content">
                   <input id="get-user-subscribe-email" type="text" required="required" name="email" placeholder="Enter your email" />
                  <!-- <input type="hidden" name="contact_us_id" value="1" /> 
                  <?php
                      echo '<div id="recaptcha" class="g-recaptcha"
                                data-sitekey="'.$recaptcha_site_key.'"
                                data-callback="NewsletteronSubmit"
                                data-size="invisible">';
                       echo '</div>';
                      ?>  -->
                  <button id="newsletter-submit-recaptcha" type="submit" class="submitted">
                    <i class="fas fa-paper-plane"></i> 
                  </button>
                  </div>
                  <label for="get-user-subscribe-email" generated="true" class="error">This field is required.</label>
                 
                  </form>
                <!-- <script>onload();</script> -->
                </div>
    
            </div>
                </div>
            </div>
            </div>
            </section>
          
             <script>
              var acc = document.getElementsByClassName("accordion");
              var i;
              
              for (i = 0; i < acc.length; i++) {
                acc[i].addEventListener("click", function() {
                  this.classList.toggle("active");
                  var panel = this.nextElementSibling;
                  if (panel.style.display === "block") {
                    panel.style.display = "none";
                  } else {
                    panel.style.display = "block";
                  }
                });
              }
              </script> 
          
          `,
          },    
  
          {
            id:"footer4",
            name:"Footer Five",
            height:"550px",
            head:`
            <head>
                <meta charset="utf-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                /> 
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
            
              <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
              <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
              
              <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
              <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
              
            </head>
            
            `,

            code:`
          
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        </style>
       

      <style>
      * {
        -web-kit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
      }
      html {
        scroll-behavior: smooth;
      }
      body {
        float: left;
        margin: 0px;
        width: 100%;
      }
      .custom-container{
          max-width: 1140px;
          margin: 0 auto;
        }
        .footer_detail_box_logo_bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 55px;
}
.footer_detail_box_logo_bar small {
    flex-grow: 5;
    line-height: 0;
    text-align: center;
    position: relative;
    padding: 0px 10px 0px 15px;
}
.footer_detail_box_logo_bar small:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #232323;
}
.footer_detail_box_logo_bar figure {
    margin: 0px !important;
    width: 120px !important;
}
.footer_detail_box_logo_bar figure img {
    width: 100%;
}
        .footer-section{
          background: #000000;
    color: #fff;
    float: left;
    width: 100%;
    padding: 50px 15px;
        }
        
        .footer-data-box{
          display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". . .";
    gap: 30px 30px;
}
.footer-socail-links-heading {
    padding-bottom: 10px;
    padding-top: 20px;
}
.footer-socail-links-heading h5 {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.4);
}
        .footer-socail-links{
          display: flex;
        align-items: center;
        padding-bottom: 20px;
        }
        .footer-socail-links a{
          display: flex;
    align-items: center;
    margin-right: 14px;
    background: transparent;
    width: 36px;
    height: 36px;
    justify-content: center;
    border: 0.5px solid rgba(255, 255, 255, 0.16);
    border-radius: 50%;
        }
        .footer-socail-links a:last-child{
          margin-right: 0px;
        }
        .footer-socail-links a i{
          color: #FFFFFF;
          font-size: 16px;
        }
        
        .footer-detail-box-two{
          display: flex;
        flex-direction: column;
        width: 100%;
        }
        .footer-detail-box-heading {
          position: relative;
          padding-bottom:21px;
        }
        .footer-detail-box-heading h3{
          margin-bottom: 0px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
        }

        .footer-detail-box-content-list{
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0px !important;
          margin: 0px !important;
        }
        .footer-detail-box-content-list li{
          list-style: none;
          display: flex;
          align-items: center;
          padding-bottom: 12px;
        }
        li.footer-hours-list {
    justify-content: space-between;
}
li.footer-hours-list small {
    flex-grow: 5;
    line-height: 0;
    text-align: center;
    position: relative;
    padding: 0px 10px 0px 10px;
}
li.footer-hours-list small:before {
    content: " ";
    display: block;
    border-bottom: 1px dashed #fff;
}
        .footer-detail-box-content-list li:last-child{
          padding-bottom: 0px;
        }
        .footer-detail-box-content-list li a{
          font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
        }
        .footer-detail-box-content-list li a:hover{
          color: #FFFFFF;
        }
        
        .footer-contact-list{
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0px !important;
          margin: 0px !important;
        }
        .footer-contact-list li{
          list-style: none;
          display: flex;
          padding-bottom: 12px;
        }
        .footer-contact-list li:last-child{
          padding-bottom: 0px;
        }
        .footer-contact-list li i{
          padding-right: 8px;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
        }
        .footer-contact-list li a{
          font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
        }
        
      

      @media(max-width: 1024px) {
          .footer-data-box {
            grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . .";
        }
        
        }
        @media(max-width: 768px) {
          .footer-data-box {
            grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
      
        }
        
        }
        @media(max-width: 540px) {
          .footer-data-box{
          grid-template-columns: 1fr;
          grid-template-areas: ".";
          gap: 30px 0px;
           }
       
        .footer-detail-box-heading {
   padding-bottom: 0px; 
}
.footer-contact-list {
    padding-top: 15px !important;
}
.footer-detail-box-content-list {
    padding-top: 15px !important;
}
        .footer-socail-links {
         
            justify-content: center;
            align-items: center;
        }
       
        .panel {
            display: none;
            overflow: hidden;
            transition:0.5s ease-out;
            text-align: left;
        }
        
      
        .footer-detail-box-heading.accordion:after {
          content: '\\002B';
          color: #fff;
        font-family: "revicons";
        font-size: 26px;
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0px;
        top: 0px;
        right: 0px;
        }
        .footer-detail-box-heading.active:after {
          content: "\\2212" !important;
          color: #fff;
        font-family: "revicons";
        font-size: 21px;
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0px;
        top: 0px;
        right: 0px;
        }
      
      
        }
      
      
        @media(min-width:540px){
        .modcontent.panel {
            display: block !important;
        }
        }
      </style>  
      
   
      <section class="footer-section">
        <div class="custom-container">
          <div class="footer_detail_box_logo_bar"><small></small>
            <figure><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png"></figure>
            <small></small></div>
        <div class="footer-data-box">
        <div class="footer-data-box-detail ">
          <div class="footer-detail-box-two">
            <div class="footer-detail-box-heading accordion modtitle">
            <h3 class="footer-collapse-icons">Site Links</h3>
            </div>
            <div class="modcontent panel">
            <ul class="footer-detail-box-content-list">
            <li><a>Link 1</a></li>
            <li><a>Link 2</a></li>
            <li><a>Link 3</a></li>
            <li><a>Link 4</a></li>
            <li><a>Link 5</a></li>
            </ul>
            </div>
            </div>
        </div>
        <div class="footer-data-box-detail">
        <div class="footer-detail-box-two">
        <div class="footer-detail-box-heading accordion modtitle">
        <h3 class="footer-collapse-icons">Explore menu</h3>
        </div>
        <div class="modcontent panel">
        <ul class="footer-detail-box-content-list">
        <li><a>Link 1</a></li>
        <li><a>Link 2</a></li>
        <li><a>Link 3</a></li>
        <li><a>Link 4</a></li>
        <li><a>Link 5</a></li>
        </ul>
        </div>
        </div>
        </div>
       
        <div class="footer-data-box-detail">
        <div class="footer-detail-box-two">
        <div class="footer-detail-box-heading accordion modtitle">
        <h3 class="footer-collapse-icons">Contact Us</h3>
        </div>
        <div class="modcontent panel accordion modtitle">
          <ul class="footer-contact-list ">
            
            <li><i class="fal fa-phone" aria-hidden="true"></i><a href="tel:XXXX XXXXXXX">XXXX XXXXXXX</a></li>
            <li><i class="fal fa-envelope" aria-hidden="true"></i><a href="mailto:abcd@xyz.com">abcd@xyz.com</a></li>
            <li><i class="fal fa-map-marker-alt" aria-hidden="true"></i><a>Lorem ipsum dolor sit amet, conse
              ctetur adipiscing elit. </a></li>
            </ul>
        </div>
        <div class="footer-socail-links-heading">
          <h5>KEEP IN TOUCH</h5>
        </div>
          <div class="footer-socail-links">
            <a target="blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
             <a target="blank"><i class="fab fa-instagram-square" aria-hidden="true"></i></a> 
            </div>
          
        </div>
        </div>

        <div class="footer-data-box-detail">
          <div class="footer-detail-box-two">
          <div class="footer-detail-box-heading accordion modtitle">
          <h3 class="footer-collapse-icons">Opening hours</h3>
          </div>
          <div class="modcontent panel">
         
          
          <ul class="footer-detail-box-content-list">
            <li class="footer-hours-list"><a>Monday</a><small></small> <a>11am–11pm</a></li>
            <li class="footer-hours-list"><a>Tuesday</a><small></small> <a>11am–11pm</a></li>
            <li class="footer-hours-list"><a>Wednesday</a><small></small> <a>11am–11pm</a></li>
            <li class="footer-hours-list"><a>Thursday</a><small></small> <a>11:30am–11pm</a></li>
            <li class="footer-hours-list"><a>Friday</a><small></small> <a>11am–1am</a></li>
            <li class="footer-hours-list"><a>Saturday</a> <small></small><a>11am–1am</a></li>
            <li class="footer-hours-list"><a>Sunday</a> <small></small><a>11am–11pm</a></li>
            </ul>
        
        
        </div>
          </div>
          </div>

         
        </div>
        </div>
        </section>
      
         <script>
          var acc = document.getElementsByClassName("accordion");
          var i;
          
          for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
              this.classList.toggle("active");
              var panel = this.nextElementSibling;
              if (panel.style.display === "block") {
                panel.style.display = "none";
              } else {
                panel.style.display = "block";
              }
            });
          }
          </script> 
   
            
            `,
            },  


            {
              id:"footer5",
              name:"Footer Six",
              height:"550px",

              head:`
<head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>

`,
              code:`
             
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
          </style>
         
  
        <style>
        * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          margin: 0px;
          width: 100%;
        }
  
    
        i.fas.fa-star {
            color: #F7941D;
        }
          section.copy_right_manin {
            background: #171717;
            color: #ffffff;
            float: left;
            width: 100%;
        }
        .copy_right_text1 span {
            color: #ffffff;
        }
        .copy_right_text1 span a {
            color: #ffffff;
        }
            .custom-container{
                max-width: 1140px;
                margin: 0 auto;
              }
              .footer-section{
                background: #171717;
          color: #fff;
          float: left;
          width: 100%;
          padding: 60px 15px 0px 15px;
              }
              
              .footer-data-box{
                display: grid;
          width: 100%;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: auto;
          grid-template-areas: ". . . .";
          gap: 30px 20px;
        padding-bottom:65px;
              }
              .footer-detail-box-one{
              display: flex;
           justify-content: space-between;
              width: 100%;
              }
              .footer-detail-box-one figure{
              margin: 0px !important;
              width: 120px !important;
              padding-bottom: 24px;
              }
              
              .footer-detail-box-one figure img{
                width: 100%;
              }
              .footer-detail-box-one p{
              
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.6);
          margin: 0px !important;
          padding-right: 23px;
          text-align: left;
          padding-bottom: 24px;
              }
              .footer-socail-links{
                display: flex;
              align-items: center;
              padding-bottom: 20px;
              }
              .footer-socail-links a{
                
          margin-right: 10px;
              }
              .footer-socail-links a:last-child{
                margin-right: 0px;
              }
              .footer-socail-links a i{
                color: #F7941D;
                font-size: 19px;
              }
              .contact h3 {
        
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: #F7941D;
        }
        .contact {
            margin-top: 22px;
        }
              .footer-detail-box-two{
                display: flex;
              flex-direction: column;
              width: 100%;
              }
              .footer-detail-box-heading {
                position: relative;
                padding-bottom:12px;
              }
              .footer-detail-box-heading h3{
             margin: 0px !important;
        
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: #F7941D;
              }
        
              .footer-detail-box-content-list{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0px !important;
                margin: 0px !important;
              }
              .footer-detail-box-content-list li{
                list-style: none;
                display: flex;
                align-items: center;
              padding-bottom:8px;
              }
              .footer-detail-box-content-list li:last-child{
                padding-bottom: 0px;
              }
        .fa-phone::before {
            color: #ffffff !important;
        }
              .footer-detail-box-content-list li a{
            text-decoration: none;
        
            font-style: normal;
            font-weight: 800;
            font-size: 13px;
            line-height: 17px;
            text-transform: uppercase;
            color: #FFFFFF;
              text-decoration: none;
              }
              .footer-detail-box-content-list li a:hover{
                color: #F7941D;
              }
              
              .footer-contact-list{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0px !important;
                margin: 0px !important;
              }
              .footer-contact-list li{
                list-style: none;
                display: flex;
              padding-bottom:8px;
              }
              .footer-contact-list li:last-child{
                padding-bottom: 0px;
              }
              .footer-contact-list li i{
                padding-right: 8px;
          font-size: 14px;
          line-height: 21px;
          color:#ffffff;
              }
              .footer-contact-list li a{
        
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 17px;
        text-transform: capitalize;
        
        color: #FFFFFF;
              text-decoration: none;
              }
        .footer_divider_lines {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }
        .footer_divider_lines small {
            flex-grow: 5;
            line-height: 0;
            text-align: center;
            position: relative;
            padding: 0px 10px 0px 15px;
        }
        .footer_divider_lines small:before {
            content: " ";
            display: block;
            border-bottom: 1px solid #FFFFFF14;
        }
              
            @media(max-width: 1024px) {
                .footer-data-box {
                  grid-template-columns: 1.6fr 1fr 1fr;
                  grid-template-areas: ". . .";
              }
              
              }
              @media(max-width: 768px) {
                .footer-data-box {
                  grid-template-columns: 1.6fr 1fr;
          grid-template-areas: ". .";
            
              }
              
              }
              @media(max-width: 540px) {
                .footer-data-box{
                grid-template-columns: 1fr;
                grid-template-areas: ".";
                gap: 30px 0px;
                 }
              .footer-detail-box-one {
                  justify-content: center;
                  align-items: center;
        flex-direction: column;
              }
              .footer-detail-box-one p {
               padding-right: 0px;
              text-align: center;
        }
              .footer-detail-box-heading {
         padding-bottom: 0px; 
        }
        .footer-contact-list {
          padding-top: 15px !important;
        }
        .footer-detail-box-content-list {
          padding-top: 15px !important;
        }
              .footer-socail-links {
               
                  justify-content: center;
                  align-items: center;
              }
              .panel {
                  display: none;
                  overflow: hidden;
                  transition:0.5s ease-out;
                  text-align: left;
              }
              
            
              .footer-detail-box-heading.accordion:after {
                content: '\\002B';
                color: #fff;
              font-family: "revicons";
              font-size: 26px;
              position: absolute;
              height: 100%;
              display: flex;
              align-items: center;
              margin: 0px;
              top: 0px;
              right: 0px;
              }
              .footer-detail-box-heading.active:after {
                content: "\\2212" !important;
                color: #fff;
              font-family: "revicons";
              font-size: 21px;
              position: absolute;
              height: 100%;
              display: flex;
              align-items: center;
              margin: 0px;
              top: 0px;
              right: 0px;
              }
            
            
              }
            
            
              @media(min-width:540px){
              .modcontent.panel {
                  display: block !important;
              }
              }
            </style>
      <section class="footer-section">
        <div class="custom-container">
        <div class="footer-data-box">
        <div class="footer-data-box-detail">
        <div class="footer-detail-box-two">
        <div class="footer-detail-box-heading accordion modtitle">
        <h3 class="footer-collapse-icons">Site Links</h3>
        </div>
        <div class="modcontent panel">
        <ul class="footer-detail-box-content-list">
        <li><a >HOME</a></li>
        <li><a>OUR MENU</a></li>
        <li><a >Appy Deals</a></li>
        <li><a>Location</a></li>
        <li><a >About</a></li>
        <li><a >Contact</a></li>
        </ul>
        </div>
        </div>
        </div>
        <div class="footer-data-box-detail">
        <div class="footer-detail-box-two">
        <div class="footer-detail-box-heading accordion modtitle">
        <h3 class="footer-collapse-icons">Explore menu</h3>
        </div>
        <div class="modcontent panel">
        <ul class="footer-detail-box-content-list">
        <li><a >Appetizers</a></li>
        <li><a >Original burgers</a></li>
        <li><a>sandwiches</a></li>
        <li><a >Pasta</a></li>
        <li><a >Premium burgers</a></li>
        <li><a >Shakes</a></li>
        </ul>
        </div>
        </div>
        </div>
        <div class="footer-data-box-detail">
        <div class="footer-detail-box-two">
        <div class="footer-detail-box-heading accordion modtitle">
        <h3 class="footer-collapse-icons">Usefull links</h3>
        </div>
        <div class="modcontent panel accordion modtitle">
        <ul class="footer-detail-box-content-list">
        <li><a >Login/Signup</a></li>
        <li><a>My orders</a></li>
        <li><a>delivery policy</a></li>
        <li><a>Privacy policy</a></li>
        <li><a>Careers</a></li>
        <li><a>BLog</a></li>
        </ul>
        </div>
        </div>
        </div>
        <div class="footer-data-box-detail">
        <div class="footer-detail-box-two ">
        <div class="footer-detail-box-heading accordion modtitle">
        <h3 class="footer-collapse-icons">Contact Us</h3>
        </div>
        <div class="modcontent panel accordion modtitle">
        <ul class="footer-contact-list ">
        <li><i class="fas fa-phone" aria-hidden="true"></i><a>0322-1132459</a></li>
        <li><i class="fas fa-map-marker-alt" aria-hidden="true"></i><a>It is a long established fact that a reader will be distracted.</a></li>
        </ul>
        <div class="contact">
        <h3>Opening Hours</h3>
        </div>
        <ul class="footer-contact-list ">
        <li><i class="fas fa-clock" aria-hidden="true"></i>12pm to 1am (Weekends till 2 am)</li>
        </ul>
        </div>
        </div>
        </div>
        </div>
        <div class="footer-data-box-detail ">
        <div class="footer-detail-box-one">
        <figure><a href="/"><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png"></a></figure>
        <div class="footer-socail-links"><a target="blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a> <a target="blank"><i class="fab fa-instagram" aria-hidden="true"></i></a></div>
        </div>
        </div>
        </div>
        <div class="footer_divider_lines"><small></small> <span><i class="fas fa-star" aria-hidden="true"></i></span> <small></small></div>
        </section>
                  
        
           <script>
            var acc = document.getElementsByClassName("accordion");
            var i;
            
            for (i = 0; i < acc.length; i++) {
              acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                  panel.style.display = "none";
                } else {
                  panel.style.display = "block";
                }
              });
            }
            </script> 
     
              
              `,
              },  


              {
                id:"footer6",
                name:"Footer Seven",
                height:"550px",
                head:`
                <head>
                    <meta charset="utf-8" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                    /> 
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                    
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                
                  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                  
                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                  
                </head>
                
                `,

                code:`
               
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            </style>
           
    
            <style>
            * {
                             -web-kit-box-sizing: border-box;
                             -moz-box-sizing: border-box;
                             box-sizing: border-box;
                             padding: 0px;
                             margin: 0px;
                             font-family: "Poppins", sans-serif;
                           }
                           html {
                             scroll-behavior: smooth;
                           }
                           body {
                             float: left;
                             width: 100%;
                             margin: 0px;
                         
                           }
                   .custom-container{
                       max-width: 1140px;
                       margin: 0 auto;
                     }
                     .footer-section{
                    background: #FAF5EB;
                 color: #fff;
                 float: left;
                 width: 100%;
                 padding: 56px 15px 56px 15px;
              
                     }
                     
                     .footer-data-box{
                           display: grid;
             width: 100%;
             grid-template-columns: 1.7fr 1fr 1fr 1.7fr;
             grid-template-rows: auto;
             grid-template-areas: ". . . .";
             gap: 30px 30px;
                     }
                     .footer-detail-box-one{
                     display: flex;
                     flex-direction: column;
                     width: 100%;
                     }
                     .footer-detail-box-one figure{
                     margin: 0px !important;
                     width: 150px !important;
                     padding-bottom: 12px;
                     }
                     
                     .footer-detail-box-one figure img{
                       width: 100%;
                     }
                     .footer-detail-box-content-list li{
                      list-style: none;
                    
                    }
                     .footer-detail-box-one p{
                    
             font-family: 'Cabin', sans-serif;
           
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 23px;
           color: rgba(0, 0, 0, 0.6);
                     }
                     .footer-socail-links{
                       display: flex;
                     align-items: center;
           
                     }
                     .footer-socail-links a{
                   
                 display: flex;
                 align-items: center;
           
                 justify-content: center;
                 margin-right: 29px;
                     }
                     .footer-socail-links a:last-child{
                       margin-right: 0px;
                     }
                     .footer-socail-links a i{
                       color: #B1382E;
                       font-size: 18px;
                     }
                     
                     .footer-detail-box-two{
                       display: flex;
                     flex-direction: column;
                     width: 100%;
                     }
                     .footer-detail-box-heading {
                       position: relative;
                       padding-bottom:25px;
                     }
                     .footer-detail-box-heading h3{
                       margin: 0px !important;
           
             font-family: 'Cabin', sans-serif;
           
           font-style: normal;
           font-weight: 500;
           font-size: 16px;
           line-height: 20px;
           text-transform: capitalize;
           color: #03242D;
                     }
             
                     .footer-detail-box-content-list{
                       display: flex;
                       flex-direction: column;
                       width: 100%;
                       padding: 0px !important;
                       margin: 0px !important;
                     }
                     .footer-detail-box-content-list li{
                       list-style: none;
                       display: flex;
                       align-items: center;
                     padding-bottom:12px;
                     }
                     .footer-detail-box-content-list li:last-child{
                       padding-bottom: 0px;
                     }
                     .footer-detail-box-content-list li a{
            
             font-family: 'Cabin', sans-serif;
           
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 17px;
           text-transform: capitalize;
           color: rgba(0, 0, 0, 0.6);
                     text-decoration: none;
                     }
                     .footer-detail-box-content-list li a:hover{
                  color: rgba(0, 0, 0, 0.6);
                     }
                     
                     .footer-contact-list{
                       display: flex;
                       flex-direction: column;
                       width: 100%;
                       padding: 0px !important;
                       margin: 0px !important;
                     }
                     .footer-contact-list li{
                       list-style: none;
                       display: flex;
                     padding-bottom:12px;
                     }
                     .footer-contact-list li:last-child{
                       padding-bottom: 0px;
                     }
                     .footer-contact-list li i{
                       padding-right: 8px;
           font-size: 14px;
           line-height: 17px;
              color: rgba(0, 0, 0, 0.6);
                     }
                     .footer-contact-list li a{
                            
             font-family: 'Cabin', sans-serif;
           
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 17px;
           text-transform: capitalize;
           color: rgba(0, 0, 0, 0.6);
                     text-decoration: none;
                     }
                     
                     .footer_newsletter_section {
                 display: flex;
                 flex-direction: column;
             }
             .footer_newsletter_section h3 {
            
             font-family: 'Cabin', sans-serif;
           
           font-style: normal;
           font-weight: 700;
           font-size: 21px !important;
           line-height: 26px;
           text-transform: capitalize;
           color: #03242D;
                 margin-bottom: 24px;
             }
             .footer_newsletter_form_fields_content {
                 position: relative;
                 height: 48px;
                 width: 100%;
                 margin-bottom: 10px;
             }
             .footer_newsletter_form_fields_content input {
                   display: flex;
             align-items: center;
             height: 100%;
             width: 100%;
           
             font-family: 'Cabin', sans-serif;
           
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 17px;
             text-transform: capitalize;
             color: rgba(0, 0, 0, 0.6);
             padding: 0px 50px 0px 0px;
             background: transparent;
             border-bottom: 1px solid #000000 !important;
             border: unset;
               }
             .footer_newsletter_form_fields_content input:focus-visible{
                         outline: none !important;
                       }
                       .footer_newsletter_form_fields_content input::placeholder{
           
             font-family: 'Cabin', sans-serif;
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 17px;
           text-transform: capitalize;
           color: rgba(0, 0, 0, 0.6);
                        display: flex;
                           align-items: center;
                       }
             
                       .footer_newsletter_form_fields_content button {
                 background: unset;
                 border: unset;
                 position: absolute;
                 content: "";
                 top: 0px;
                 right: 0px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 height: 100%;
                 padding: 2px;
           
             font-family: 'Cabin', sans-serif;
           
           font-style: normal;
           font-weight: 500;
           font-size: 14px;
           line-height: 17px;
           color: #B1382E;
             }     
             .footer_newsletter_form_fields_content button i {
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 height: 100%;
                 min-width: 40px;
                 background: #EC1848;
                 border-radius: 2px;
                 font-size: 15px;
                 line-height: 19px;
                 text-transform: capitalize;
                 color: #ffffff;
                 border: unset;
             }
             .footer_newsletter_form_fields_content button:hover{
           color: #B1382E;
                       }
                       .footer_newsletter_form_fields_content button:focus {
                           outline: 0;
                           box-shadow: unset !important;
                       }
                       .footer_newsletter_section form label.error{
                       font-style: normal;
                       font-weight: 600;
                       font-size: 14px;
                       line-height: 24px;
                       color: #CC0000;
                         text-align: left;
                           margin: 0px !important;
                    
             font-family: 'Cabin', sans-serif;
           
                 
                       }
           .footer-socail-links_main {
             display: flex;
             flex-direction: column;
             padding-top: 8px;
           }
           .footer-socail-links_main h3 {
           
             font-family: 'Cabin', sans-serif;
           
             font-style: normal;
             font-weight: 700 !important;
             font-size: 21px;
             line-height: 26px;
             text-transform: capitalize;
             color: #03242D;
             padding-bottom: 17px;
           }
             
                   @media(max-width: 1024px) {
                       .footer-data-box {
                         grid-template-columns: 1.4fr 1.5fr 1.2fr;
                 grid-template-areas: ". . .";
                     }
                     
                     }
                     @media(max-width: 768px) {
                       .footer-data-box {
                         grid-template-columns: 1.6fr 1fr;
                 grid-template-areas: ". .";
                   
                     }
                     
                     }
                     @media(max-width: 540px) {
                       .footer-data-box{
                       grid-template-columns: 1fr;
                       grid-template-areas: ".";
                       gap: 30px 0px;
                        }
                     .footer-detail-box-one {
                         justify-content: center;
                         align-items: center;
                     }
                     .footer-detail-box-one p {
                      padding-right: 0px;
                     text-align: center;
             }
                     .footer-detail-box-heading {
                padding-bottom: 0px; 
             }
             .footer-contact-list {
                 padding-top: 15px !important;
             }
             .footer-detail-box-content-list {
                 padding-top: 15px !important;
             }
                     .footer-socail-links {
                      
                         justify-content: center;
                         align-items: center;
                     }
                     .footer_newsletter_section h3 {
                 text-align: center;
             }
                     .panel {
                         display: none;
                         overflow: hidden;
                         transition:0.5s ease-out;
                         text-align: left;
                     }
                     
                   
                     .footer-detail-box-heading.accordion:after {
                       content: '\\002B';
                       color: #03242D;
                     font-family: "revicons";
                     font-size: 26px;
                     position: absolute;
                     height: 100%;
                     display: flex;
                     align-items: center;
                     margin: 0px;
                     top: 0px;
                     right: 0px;
                     }
                     .footer-detail-box-heading.active:after {
                       content: '\\2212' !important;
                       color: #03242D;
                     font-family: "revicons";
                     font-size: 21px;
                     position: absolute;
                     height: 100%;
                     display: flex;
                     align-items: center;
                     margin: 0px;
                     top: 0px;
                     right: 0px;
                     }
                   .footer-socail-links_main {
             justify-content: center;
             align-items: center;
           }
           #get-user-subscribe-email {
             width: 100% !important;
             margin-bottom: 0px;
             height: 100% !important;
           }
           .footer_newsletter_form_fields_content {
             max-width: 450px;
             margin: 0 auto;
           }
                   
                     }
                   
                   
                     @media(min-width:540px){
                     .modcontent.panel {
                         display: block !important;
                     }
                     }
                   </style>
           <section class="footer-section">
           <div class="custom-container">
           <div class="footer-data-box">
           <div class="footer-data-box-detail ">
           <div class="footer-detail-box-one">
           <figure><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png" /></figure>
           <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
           </div>
           </div>
           <div class="footer-data-box-detail">
           <div class="footer-detail-box-two">
           <div class="footer-detail-box-heading accordion modtitle">
           <h3 class="footer-collapse-icons">Site Links</h3>
           </div>
           <div class="modcontent panel">
           <ul class="footer-detail-box-content-list">
           <li><a href="/">Home</a></li>
           <li><a target="blank">Glow Better</a></li>
           <li><a  target="blank">Advanced Asthetics</a></li>
           <li><a >Blog</a></li>
           <li><a>About</a></li>
           <li><a>Contact</a></li>
           <li><a >Privacy Policy</a></li>
           </ul>
           </div>
           </div>
           </div>
           <div class="footer-data-box-detail">
           <div class="footer-detail-box-two">
           <div class="footer-detail-box-heading accordion modtitle">
           <h3 class="footer-collapse-icons">Products</h3>
           </div>
           <div class="modcontent panel">
           <ul class="footer-detail-box-content-list">
           <li><a >Oils &amp; Treatments</a></li>
           <li><a >Age Defying</a></li>
           <li><a >Bundles</a></li>
           <li><a >Packages</a></li>
           <li><a >Free Samples</a></li>
           </ul>
           </div>
           </div>
           </div>
           <div class="footer-data-box-detail">
           <div class="footer-detail-box-two ">
           <div class="footer_newsletter_section">
           <h3>Get Updates &amp; More</h3>
           <form method="POST" action="/website/insert_signup_newsletter" id="quick_newslatter_recaptcha" novalidate="novalidate">
           <div class="footer_newsletter_form_fields_content"><input id="get-user-subscribe-email" type="text" required="required" name="email" placeholder="Enter Email....." /> 
             <input type="hidden" name="contact_us_id" value="1" /> 
             <!-- <?php
                               echo '<div id="recaptcha" class="g-recaptcha"
                                         data-sitekey="'.$recaptcha_site_key.'"
                                         data-callback="NewsletteronSubmit"
                                         data-size="invisible">';
                                echo '</div>';
                               ?> -->
                                <button id="newsletter-submit-recaptcha" type="submit" class="submitted"> submit </button></div>
           <label for="get-user-subscribe-email" generated="true" class="error"></label></form>
           <script>onload();</script>
           </div>
           <div class="footer-socail-links_main">
           <h3>Follow Us</h3>
           <div class="footer-socail-links"><a target="blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
             <a  target="blank"><i class="fab fa-instagram-square" aria-hidden="true"></i></a> 
             <a  target="blank"><i class="fab fa-twitter" aria-hidden="true"></i></a> 
             <a  target="blank"><i class="fab fa-pinterest" aria-hidden="true"></i></a></div>
           </div>
           </div>
           </div>
           </div>
           </div>
           </section>
           <script>
                       var acc = document.getElementsByClassName("accordion");
                       var i;
                       
                       for (i = 0; i < acc.length; i++) {
                         acc[i].addEventListener("click", function() {
                           this.classList.toggle("active");
                           var panel = this.nextElementSibling;
                           if (panel.style.display === "block") {
                             panel.style.display = "none";
                           } else {
                             panel.style.display = "block";
                           }
                         });
                       }
                       </script>
       
                
                `
                },  


                {
                  id:"footer7",
                  name:"Footer Eight",
                  height:"550px",

                  head:`
<head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>

  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
  
</head>

`,
                  code:`
                  
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
              </style>
             
      
              <style>
              * {
              -web-kit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0px;
              margin: 0px;
            }
            html {
              scroll-behavior: smooth;
            }
            body {
              float: left;
              margin: 0px;
              width: 100%;
            }
              .custom_container{
                max-width:1140px;
                margin: 0 auto;
              }
              .footer_container{
                max-width:1350px;
                margin: 0 auto;
               padding: 72px 100px 73px 130px;
              position: relative;
              }
              .footer_section{
              background: #262161;
                color: #fff;
                float: left;
                width: 100%;
               
              }
              .footer_layer_1 {
                  position: absolute;
                  left: 0;
                  top: 30px;
              }
              .footer_layer_2 {
                  position: absolute;
                  right: 0;
                  bottom: 30px;
              }
              
              
              .footer_data_box{
                display: grid;
                width: 100%;
                grid-template-columns: 1.5fr 1fr 1fr 1fr;
                grid-template-rows: auto;
                grid-template-areas: ". . . .";
                gap: 30px 20px;
                  position: relative;
              }
              .footer_detail_box_one{
              display: flex;
              flex-direction: column;
              width: 100%;
              }
              .footer_detail_box_one figure{
              margin: 0px !important;
                  width: 110px !important;
              padding-bottom: 25px;
              }
              
              .footer_detail_box_one figure img{
                width: 100%;
              }
              .footer_detail_box_one p{
              margin: 0px !important;
                  padding-bottom: 32px;
                  text-align: left;
                  max-width: 230px;
                  font-family: 'Manrope' !important;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 24px;
                  color: #FFFFFF;
              }
              .footer_socail_links{
              display: flex;
              align-items: center;
              }
              .footer_socail_links a{
                display: flex;
                align-items: center;
                margin-right: 14px;
              background: #F6921E;
              width :36px;
              height :36px;
              justify-content: center;
              border-radius: 50%;
              }
              .footer_socail_links a i{
                color: #FFFFFF;
                font-size: 16px;
              }
              
              .footer_detail_box_two{
                display: flex;
              flex-direction: column;
              width: 100%;
              }
              .footer_detail_box_heading {
                position: relative;
                padding-bottom: 19px;
              }
              .footer_detail_box_heading h3{
                margin-bottom: 0px !important;
              font-family: 'Austin News Headline Web';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 18px;
              color: #FFFFFF;
              
              }
              .footer_detail_box_content_list{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0px !important;
                margin: 0px !important;
              }
              .footer_detail_box_content_list li{
                list-style: none;
                display: flex;
                align-items: center;
                gap: 0px 5px;
                padding-bottom: 13px;
              }
              .footer_detail_box_content_list li:last-child{
                padding-bottom:0px ;
              }
              .footer_detail_box_content_list li a{
              font-family: 'Manrope' !important;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 18px;
              color: #FFFFFF;
              }
              .footer_detail_box_content_list li a:hover{
              color: rgba(255, 255, 255, 0.6);
              }
              
              .footer_contact_list{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0px !important;
                margin: 0px !important;
              }
              .footer_contact_list li{
                list-style: none;
                display: flex;
                padding-bottom: 13px;
              }
              .f-contact-heading {
                  padding-top: 26px;
                  padding-bottom: 16px;
              }
              .f-contact-heading span {
              font-family: 'Austin News Headline Web';
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;
              color: #FFFFFF;
              }
              .footer-social-links {
                  display: flex;
              }
              .footer-links i {
                height: 36px;
                  width: 36px;
                  font-size: 16px;
                  color: #FFFFFF;
                  border: 1px solid #ffffff;
                  border-radius: 50%;
                  margin-right: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
              .footer_contact_list li:last-child{
                padding-bottom: 0px;
              }
              .footer_contact_list li i{
              padding-right: 8px;
               color: #ffffff;
                  font-size: 16px;
              line-height: 17px;
              }
              .footer_contact_list li a{
              font-family: 'Manrope' !important;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 18px;
              text-transform: capitalize;
              color: #FFFFFF;
              }
              .footer_contact_list li a:hover{
              color: rgba(255, 255, 255, 0.6);
              }
              @media (max-width: 1140px) {
              .footer_container {
                     padding: 47px 55px 64px 80px;
              }
              .bulb_two {
                   left: 20px !important;
              }
              .bulb_one {
                left: unset !important;
                  right: 20px;
              }
              }
              @media (min-width: 767px) and (max-width: 1024px) {
                .footer_data_box {
                  grid-template-columns: 1.6fr 1fr 1fr;
                  grid-template-areas: ". . .";
              }
              .footer_container {
                  max-width: 830px;
              }
              }
              
              
              @media (max-width: 767px) {
              .footer_part_two_main {
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  align-items: center;
                  text-align: center;
                  flex-direction: column;
              }
              .footer_container {
                  max-width: 635px;
              }
              p.card-not_available.mb-0 {
                  text-align: center !important;
              }  
              .footer_data_box {
                  grid-template-columns: 1.6fr 1fr;
                  grid-template-areas: ". .";
              }
              }
              
              @media (max-width: 540px) {
              .footer_container {
                  padding: 60px 10px 64px 10px;
              }
              .bulb_two {
                  left: 20px;
              }
              .bulb_one {
                left: unset !important;
                  right: 20px;
              }
             
              p.add-to-cart-button a {
                  width: 115px !important;
                  max-width: 115px !important;
                  height: 40px !important;
              }
              p.card-not_available.mb-0{
                  padding: 0px 0px 17px 0px !important;
              }
              .footer_detail_box_one p{
                  text-align: center;
                  padding-right: 0px;
              }
              .footer_detail_box_one {
              
                  justify-content: center;
                  align-items: center;
              }
                .footer_data_box {
                  grid-template-columns: 1fr;
                  grid-template-areas: ".";
              gap:20px 0px;
              }
              .footer_detail_box_heading {
                padding-bottom: 0px;
              }
              .footer_detail_box_content_list {
                  padding-top: 15px !important;
              }
              .footer_contact_list {
                  padding-top: 15px !important;
              }
              .f-contact-heading {
                  padding-top: 26px;
                  padding-bottom: 16px;
                  display: flex;
                  justify-content: center;
              }
              .footer-social-links {
                  display: flex;
                  justify-content: center;
              }
              .footer_socail_links {
                  padding-bottom: 15px;
                  padding-top: 15px;
              }
              .footer_social_links{
              justify-content: center;
                  align-items: center;
              }
              .footer_socail_links a:last-child{
              margin-right:0px;
              }
              .panel {
                  display: none;
                  overflow: hidden;
                  transition:0.5s ease-out;
                  text-align: left;
              }
              
              .footer_detail_box_heading.accordion:after {
                content: '\\002B';
              color: #ffffff;
              font-family: 'revicons';
              font-size: 26px;
              position: absolute;
              height: 100%;
              display: flex;
              align-items: center;
              margin: 0px;
              top: 0px;
              right: 0px;
              }
              .footer_detail_box_heading.active:after {
                  content: "\\2212" !important;
              color: #ffffff;
              font-family: 'revicons';
              font-size: 21px;
              position: absolute;
              height: 100%;
              display: flex;
              align-items: center;
              margin: 0px;
              top: 0px;
              right: 0px;
              }
              
              }
              
              @media (min-width:540px){
              .modcontent.panel {
                  display: block !important;
              }
              }
              </style>
                     <section class="footer_section">
                     <div class="footer_container">
                     <div class="footer_data_box">
                     <div class="footer_data_box_detail ">
                     <div class="footer_detail_box_one">
                     <figure><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png"></figure>
                     <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                     </div>
                     </div>
                     <div class="footer_data_box_detail">
                     <div class="footer_detail_box_two">
                     <div class="footer_detail_box_heading accordion modtitle">
                     <h3 class="footer_collapse_icons">Site Links</h3>
                     </div>
                     <div class="modcontent panel">
                     <ul class="footer-detail-box-content-list">
                           <li><a>Link 1</a></li>
                           <li><a>Link 2</a></li>
                           <li><a>Link 3</a></li>
                           <li><a>Link 4</a></li>
                           <li><a>Link 5</a></li>
                           </ul>
                     </div>
                     </div>
                     </div>
                     <div class="footer_data_box_detail">
                     <div class="footer_detail_box_two">
                     <div class="footer_detail_box_heading accordion modtitle">
                     <h3 class="footer_collapse_icons">Our Services</h3>
                     </div>
                     <div class="modcontent panel">
                     <ul class="footer-detail-box-content-list">
                           <li><a>Link 1</a></li>
                           <li><a>Link 2</a></li>
                           <li><a>Link 3</a></li>
                           <li><a>Link 4</a></li>
                           <li><a>Link 5</a></li>
                           </ul>
                     </div>
                     </div>
                     </div>
                     <div class="footer_data_box_detail">
                     <div class="footer_detail_box_two ">
                     <div class="footer_detail_box_heading accordion modtitle">
                     <h3 class="footer_collapse_icons">Contact Us</h3>
                     </div>
                     <div class="modcontent panel accordion modtitle">
                     <ul class="footer_contact_list ">
                     <li><i class="fas fa-phone-alt" aria-hidden="true"></i><a>0311-6229222</a></li>
                     <li><i class="fas fa-envelope" aria-hidden="true"></i><a>test@gmail.com</a></li>
                     </ul>
                     </div>
                     </div>
                     <div class="f-contact-heading"><span>Follow us</span></div>
                     <div class="footer-social-links">
                     <div class="footer-links"><a target="blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></div>
                     <div class="footer-links"><a target="blank"><i class="fab fa-instagram" aria-hidden="true"></i></a></div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </section>
             <script>
                         var acc = document.getElementsByClassName("accordion");
                         var i;
                         
                         for (i = 0; i < acc.length; i++) {
                           acc[i].addEventListener("click", function() {
                             this.classList.toggle("active");
                             var panel = this.nextElementSibling;
                             if (panel.style.display === "block") {
                               panel.style.display = "none";
                             } else {
                               panel.style.display = "block";
                             }
                           });
                         }
                         </script>
         
                  
                  `
                  },  
  
                  {
                    id:"footer8",
                    name:"Footer Nine",
                    height:"550px",
                    head:`
                    <head>
                        <meta charset="utf-8" />
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1, shrink-to-fit=no"
                        /> 
                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                        
                        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                    
                      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                      <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                      
                      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                      
                    </head>
                    
                    `,

                    code:`
                    
              <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                </style>
               
        
                <style>
                .footer_container{
                  max-width:700px;
                  margin: 0 auto;
                }
                .footer_section{
                background: #000000;
                  color: #fff;
                  float: left;
                  width: 100%;
                  padding: 48px 15px;
                }
                
                .footer_data_box{
                  display: grid;
                  width: 100%;
                  grid-template-columns:1.1fr 1.3fr 1.2fr 0.5fr;
                  grid-template-rows: auto;
                  grid-template-areas: ". . . . .";
                  gap: 30px 40px;
                }
                .footer_detail_box_one{
                display: flex;
                flex-direction: column;
                width: 100%;
                }
                .footer_header{
                display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }
                .footer_header figure{
                margin: 0px !important;
                width:173px !important;
                padding-bottom: 36px;
                }
                
                .footer_header figure img{
                  width: 100%;
                }
                .footer_detail_box_one p{
                font-family: 'Epilogue';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: rgba(255, 255, 255, 0.7);
                    margin: 0px !important;
                    padding-bottom: 32px;
                    padding-right: 23px;
                    text-align: left;
                }
                .footer_socail_links{
                display: flex;
                align-items: center;
                }
                .footer_socail_links a{
                  display: flex;
                  align-items: center;
                  margin-right: 14px;
                background: #FFFFFF;
                border-radius: 2px;
                width :28px;
                height :28px;
                justify-content: center;
                }
                .footer_socail_links a i{
                  color: #000000;
                  font-size: 14px;
                }
                
                .footer_detail_box_two{
                  display: flex;
                flex-direction: column;
                width: 100%;
                }
                .footer_detail_box_heading {
                  position: relative;
                  padding-bottom: 24px;
                }
                .footer_detail_box_heading h3{
                  margin-bottom: 0px !important;
                font-family: 'Epilogue';
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                color: #FFFFFF;
                }
                .footer_detail_box_content_list{
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  padding: 0px !important;
                  margin: 0px !important;
                }
                .footer_detail_box_content_list li{
                  list-style: none;
                  display: flex;
                  align-items: center;
                  gap: 0px 5px;
                  padding-bottom: 16px;
                }
                .footer_detail_box_content_list li:last-child{
                  padding-bottom:0px ;
                }
                .footer_detail_box_content_list li a{
                font-family: 'Epilogue';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: rgba(255, 255, 255, 0.7);
                }
                .footer_detail_box_content_list li a:hover{
                color: rgba(255, 255, 255, 0.7);
                }
                
                .footer_contact_list{
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  padding: 0px !important;
                  margin: 0px !important;
                }
                .footer_contact_list li{
                  list-style: none;
                  display: flex;
                  padding-bottom: 16px;
                }
                .footer_contact_list li:last-child{
                  padding-bottom: 0px;
                }
                .footer_contact_list li i{
                padding-right: 8px;
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 18px;
                line-height: 17px;
                }
                .footer_contact_list li a{
                font-family: 'Epilogue';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: rgba(255, 255, 255, 0.7);
                }
                .footer_contact_list li a:hover{
                color: rgba(255, 255, 255, 0.7);
                }
                @media (min-width: 768px) and (max-width: 1024px) {
                 
                }
                @media (max-width: 992px) {
                section.sub_page_banner01 {
                    max-width: 695px;
                }
                }
                
                @media (max-width: 767px) {
                section.sub_page_banner01 {
                      max-width: 540px;
                justify-content: center;
                }
                section.sub_page_banner01 .sub_banner_inner_content h1.color_fff.title_font_color.font_size_40.primary_font.line_height_82.font_weight_700:before {
                    width: 100%;
                }
                  .footer_data_box {
                    gap:30px 20px;
                }
                }
                
                @media (max-width: 540px) {
                
                .footer_detail_box_one {
                
                    justify-content: center;
                    align-items: center;
                }
                  .footer_data_box {
                    grid-template-columns: 1fr;
                    grid-template-areas: ".";
                gap:20px 0px;
                }
                .footer_detail_box_heading {
                  padding-bottom: 0px;
                }
                .footer_detail_box_content_list {
                    padding-top: 15px !important;
                }
                .footer_contact_list {
                    padding-top: 15px !important;
                }
                .footer_section {
                    padding: 40px 15px;
                }
                .footer_socail_links {
                    padding-bottom: 15px;
                    padding-top: 15px;
                }
                .footer_social_links{
                justify-content: center;
                    align-items: center;
                }
                .footer_socail_links a:last-child{
                margin-right:0px;
                }
                .panel {
                    display: none;
                    overflow: hidden;
                    transition:0.5s ease-out;
                    text-align: left;
                }
                
                .footer_detail_box_heading.accordion:after {
                  content: '\\002B';
                color: #ffffff;
                font-family: 'revicons';
                font-size: 26px;
                position: absolute;
                height: 100%;
                display: flex;
                align-items: center;
                margin: 0px;
                top: 0px;
                right: 0px;
                }
                .footer_detail_box_heading.active:after {
                    content: "\\2212" !important;
                color: #ffffff;
                font-family: 'revicons';
                font-size: 21px;
                position: absolute;
                height: 100%;
                display: flex;
                align-items: center;
                margin: 0px;
                top: 0px;
                right: 0px;
                }
                
                }
                
                @media (min-width:540px){
                .modcontent.panel {
                    display: block !important;
                }
                }
                section.copy_right_manin {
                color: rgba(255, 255, 255, 0.7);
                  background:  #000000;
                   border-top:1px solid rgba(255, 255, 255, 0.1);
                    float: left;
                    width: 100%;
                display:none;
                }
                .copy_right_text1 span {
                color: rgba(255, 255, 255, 0.7);
                }
                .copy_right_text1 span a {
                color: rgba(255, 255, 255, 0.7);
                }
                </style>
                <style>
                .home_newsletter_section{
                background: #1B1B1B;
                  color: #fff;
                  float: left;
                  width: 100%;
                  padding: 26px 15px;
                }
                .newsletter_container{
                max-width:992px;
                margin:0 auto;
                }
                .home_newsletter_box_one {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                flex-wrap: wrap;
                }
                .home_newsletter_box_one_content a {
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    border-radius: 6px;
                    padding: 16px 30px;
                    display: flex;
                    align-items: center;
                }
                .home_newsletter_box_one_content span {
                    font-family: 'Epilogue';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: #FFFFFF;
                    padding-left: 8px;
                }
                .home_newsletter_box_one_content i {
                    color: #ffffff;
                }
                @media(max-width:767px){
                .home_newsletter_box_one_content {
                    flex: 0 0 50%;
                 padding: 0px 15px 15px 15px;
                    max-width: 50%;
                }
                .home_newsletter_box_one {
                    justify-content: center;
                }
                .home_newsletter_section {
                    padding: 41px 15px 26px 15px;
                }
                }
                @media (max-width: 540px){
                .home_newsletter_box_one_content {
                    flex: 0 0 100%;
                    padding: 0px 15px 15px 15px;
                    max-width: 100%;
                }
                }
                </style>
                <section class="home_newsletter_section">
                <div class="newsletter_container">
                <div class="home_newsletter_box_one">
                <div class="home_newsletter_box_one_content"><a > <i class="fal fa-phone-alt"></i> <span>+1 437-992-4212</span></a></div>
                <div class="home_newsletter_box_one_content"><a > <i class="fal fa-envelope"></i> <span>test@gmail.com</span></a></div>
                <div class="home_newsletter_box_one_content"><a><i class="fas fa-map-marker-alt"></i><span>Mississauga, ON, Canada</span> </a></div>
                </div>
                </div>
                </section>
                <section class="footer_section">
                <div class="footer_container">
                <div class="footer_header">
                <figure><a ><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png" /></a></figure>
                </div>
                <div class="footer_data_box">
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_two">
                <div class="footer_detail_box_heading accordion modtitle">
                <h3 class="footer_collapse_icons">Site Links</h3>
                </div>
                <div class="modcontent panel">
                <ul class="footer_detail_box_content_list">
                <li><a >Home</a></li>
                <li><a >Shop</a></li>
                <li><a >Contact</a></li>
                </ul>
                </div>
                </div>
                </div>
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_two">
                <div class="footer_detail_box_heading accordion modtitle">
                <h3 class="footer_collapse_icons">Products</h3>
                </div>
                <div class="modcontent panel">
                <ul class="footer_detail_box_content_list">
                <li><a >Adjustable dumbbell</a></li>
                <li><a >Dumbbell rack</a></li>
                <li><a >Adjustable Bench</a></li>
                </ul>
                </div>
                </div>
                </div>
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_two">
                <div class="footer_detail_box_heading accordion modtitle">
                <h3 class="footer_collapse_icons">Useful links</h3>
                </div>
                <div class="modcontent panel accordion modtitle">
                <ul class="footer_detail_box_content_list">
                <li><a >Privacy policy</a></li>
                <li><a >Login/signup</a></li>
                <li><a >FAQ’s</a></li>
                </ul>
                </div>
                </div>
                </div>
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_two footer_social_links">
                <div class="footer_detail_box_heading ">
                <h3 class="footer_collapse_icons">Follow us</h3>
                </div>
                <div class="">
                <div class="footer_socail_links"><a  target="blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a> <a  aria-hidden="true"></i></a></div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </section>
               
                
                <script>
                    var acc = document.getElementsByClassName("accordion");
                    var i;
                    
                    for (i = 0; i < acc.length; i++) {
                      acc[i].addEventListener("click", function() {
                        this.classList.toggle("active");
                        var panel = this.nextElementSibling;
                        if (panel.style.display === "block") {
                          panel.style.display = "none";
                        } else {
                          panel.style.display = "block";
                        }
                      });
                    }
                    </script>
           
                    
                    `
                    },
                    
                    {
                      id:"footer9",
                      name:"Footer ten",
                      height:"550px",
                      head:`
                      <head>
                          <meta charset="utf-8" />
                          <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1, shrink-to-fit=no"
                          /> 
                          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                          
                          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                      
                        <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                        <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                        
                        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                        
                      </head>
                      
                      `,

                      code:`
                     
                <style>
                  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                  </style>
                 
          
                  <style>
                  .dropdown-header-list {
                      display: none !important;
                  }
                  .custom_container{
                    max-width:1140px;
                    margin: 0 auto;
                  }
                  .footer_container{
                    max-width:1350px;
                    margin: 0 auto;
                   padding: 47px 100px 64px 100px;
                  position:relative;
                  }
                  .footer_section{
                  background: #131B11;
                    color: #fff;
                    float: left;
                    width: 100%;
                   
                  }
                  
                  .footer_data_box{
                    display: grid;
                    width: 100%;
                    grid-template-columns: 1.4fr 1.1fr 1.1fr 1.2fr 1fr;
                    grid-template-rows: auto;
                    grid-template-areas: ". . . . .";
                    gap: 30px 20px;
                      position: relative;
                  }
                  .footer_detail_box_one{
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  }
                  .footer_detail_box_one figure{
                  margin: 0px !important;
                      width: 199px !important;
                  padding-bottom: 25px;
                  }
                  
                  .footer_detail_box_one figure img{
                    width: 100%;
                  }
                  .footer_detail_box_one p{
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 21px;
                  color: rgba(255, 255, 255, 0.6);
                      margin: 0px !important;
                      padding-bottom: 32px;
                      padding-right: 23px;
                      text-align: left;
                  }
                  .footer_socail_links{
                  display: flex;
                  align-items: center;
                  }
                  .footer_socail_links a{
                    display: flex;
                    align-items: center;
                    margin-right: 14px;
                  background: #F6921E;
                  width :36px;
                  height :36px;
                  justify-content: center;
                  border-radius: 50%;
                  }
                  .footer_socail_links a i{
                    color: #FFFFFF;
                    font-size: 16px;
                  }
                  
                  .footer_detail_box_two{
                    display: flex;
                  flex-direction: column;
                  width: 100%;
                  }
                  .footer_detail_box_heading {
                    position: relative;
                    padding-bottom: 19px;
                  }
                  .footer_detail_box_heading h3{
                    margin-bottom: 0px !important;
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  text-transform: capitalize;
                  color: #FFFFFF;
                  }
                  .footer_detail_box_content_list{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0px !important;
                    margin: 0px !important;
                  }
                  .footer_detail_box_content_list li{
                    list-style: none;
                    display: flex;
                    align-items: center;
                    gap: 0px 5px;
                    padding-bottom: 13px;
                  }
                  .footer_detail_box_content_list li:last-child{
                    padding-bottom:0px ;
                  }
                  .footer_detail_box_content_list li a{
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: rgba(255, 255, 255, 0.6);
                  }
                  .footer_detail_box_content_list li a:hover{
                  color: rgba(255, 255, 255, 0.6);
                  }
                  
                  .footer_contact_list{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0px !important;
                    margin: 0px !important;
                  }
                  .footer_contact_list li{
                    list-style: none;
                    display: flex;
                    padding-bottom: 13px;
                  }
                  .footer_contact_list li:last-child{
                    padding-bottom: 0px;
                  }
                  .footer_contact_list li i{
                  padding-right: 8px;
                   color: rgba(255, 255, 255, 0.6);
                      font-size: 16px;
                  line-height: 17px;
                  }
                  .footer_contact_list li a{
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: rgba(255, 255, 255, 0.6);
                  }
                  .footer_contact_list li a:hover{
                  color: rgba(255, 255, 255, 0.6);
                  }
                  @media (max-width: 1140px) {
                  .footer_container {
                         padding: 47px 55px 64px 80px;
                  }
                  .bulb_two {
                       left: 20px !important;
                  }
                  .bulb_one {
                    left: unset !important;
                      right: 20px;
                  }
                  }
                  @media (min-width: 767px) and (max-width: 1024px) {
                    .footer_data_box {
                      grid-template-columns: 1.6fr 1fr 1fr;
                      grid-template-areas: ". . .";
                  }
                  .footer_container {
                      max-width: 830px;
                  }
                  }
                  
                  
                  @media (max-width: 767px) {
                  .footer_container {
                      max-width: 635px;
                  }
                  p.card-not_available.mb-0 {
                      text-align: center !important;
                  }  
                  .footer_data_box {
                      grid-template-columns: 1.6fr 1fr;
                      grid-template-areas: ". .";
                  }
                  }
                  
                  @media (max-width: 540px) {
                  .footer_container {
                      padding: 60px 10px 64px 10px;
                  }
                  .bulb_two {
                      left: 20px;
                  }
                  .bulb_one {
                    left: unset !important;
                      right: 20px;
                  }
                  .row.feature_section_row_2 .inner_class_mobile_responsive_grid.col-sm-6.col-xs-6.col-md-6.col-lg-4.mt-2.mb-2{
                      padding: 0px 6px !important;
                  }
                  .row.feature_section_row_2 {
                      margin-right: -6px !important;
                      margin-left: -6px !important;
                  }
                  p.add-to-cart-button a {
                      width: 115px !important;
                      max-width: 115px !important;
                      height: 40px !important;
                  }
                  p.card-not_available.mb-0{
                      padding: 0px 0px 17px 0px !important;
                  }
                  .footer_detail_box_one p{
                      text-align: center;
                      padding-right: 0px;
                  }
                  .footer_detail_box_one {
                  
                      justify-content: center;
                      align-items: center;
                  }
                    .footer_data_box {
                      grid-template-columns: 1fr;
                      grid-template-areas: ".";
                  gap:20px 0px;
                  }
                  .footer_detail_box_heading {
                    padding-bottom: 0px;
                  }
                  .footer_detail_box_content_list {
                      padding-top: 15px !important;
                  }
                  .footer_contact_list {
                      padding-top: 15px !important;
                  }
                  
                  .footer_socail_links {
                      padding-bottom: 15px;
                      padding-top: 15px;
                  }
                  .footer_social_links{
                  justify-content: center;
                      align-items: center;
                  }
                  .footer_socail_links a:last-child{
                  margin-right:0px;
                  }
                  .panel {
                      display: none;
                      overflow: hidden;
                      transition:0.5s ease-out;
                      text-align: left;
                  }
                  
                  .footer_detail_box_heading.accordion:after {
                    content: '\\002B';
                  color: #ffffff;
                  font-family: 'revicons';
                  font-size: 26px;
                  position: absolute;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  margin: 0px;
                  top: 0px;
                  right: 0px;
                  }
                  .footer_detail_box_heading.active:after {
                      content: "\\2212" !important;
                  color: #ffffff;
                  font-family: 'revicons';
                  font-size: 21px;
                  position: absolute;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  margin: 0px;
                  top: 0px;
                  right: 0px;
                  }
                  
                  }
                  
                  @media (min-width:540px){
                  .modcontent.panel {
                      display: block !important;
                  }
                  }
                  section.copy_right_manin {
                  color: rgba(255, 255, 255, 0.6);
                    background:  #131B11;
                     border-top:1px solid rgba(255, 255, 255, 0.08);
                      float: left;
                      width: 100%;
                  }
                  
                  
                  .copy_right_text1 span {
                  color: rgba(255, 255, 255, 0.6);
                  }
                  .copy_right_text1 span a {
                  color: rgba(255, 255, 255, 0.6);
                  }
                  .footer_section_top_head{
                      display: flex;
                      width: 100%;
                  }
                  .bulb_one {
                      position: absolute;
                      top: 0px;
                      left: 45px;
                  }
                  .bulb_two {
                       position: absolute;
                      top: 0px;
                      left: 8px;
                  }
                  .bulb_two .bulb_two_main{
                       position: relative;
                  }
                  
                  .bulb_one .bulb_one_main{
                       position: relative;
                  }
                  
                  .bulb_one_main:before{
                    position: absolute;
                      content: "";
                      bottom: 0px;
                      left: 2px;
                      width: 30px;
                      height: 68px;
                      background-repeat: no-repeat;
                    animation: blink-animation_one 2.5s steps(5, start) infinite;
                          -webkit-animation: blink-animation_one 2.5s ease-in-out infinite alternate;
                      color: transparent;
                  background: linear-gradient(180deg, #C08640 0%, #DCAE5C 44.23%, #FFFF8E 100%);
                  mix-blend-mode: normal;
                  opacity: 0.7;
                  filter: blur(21px);
                      z-index: 99;
                  }
                   @keyframes blink-animation_one {
                         0% {
                    opacity: 0;
                          }
                    100% {
                   opacity: 1;
                          }
                        }
                        @-webkit-keyframes blink-animation_one{
                          0% {
                    opacity: 0;
                  transition-delay: 5s;
                          }
                    100% {
                   opacity: 1;
                  transition-delay: 5s;
                          }
                        }
                  
                  
                  
                  .bulb_two_main:before{
                    position: absolute;
                      content: "";
                      bottom: 0px;
                      left: -3px;
                      width: 35px;
                      height: 35px;
                      background-repeat: no-repeat;
                    animation: blink-animation 2s steps(5, start) infinite;
                          -webkit-animation: blink-animation 2s ease-in-out infinite alternate;
                      color: transparent;
                  background: linear-gradient(180deg, #C08640 0%, #DCAE5C 44.23%, #FFFF8E 100%);
                  mix-blend-mode: normal;
                  opacity: 0.7;
                  filter: blur(21px);
                  }
                   @keyframes blink-animation {
                         0% {
                    opacity: 0;
                  
                          }
                    100% {
                   opacity: 1;
                  
                          }
                        }
                        @-webkit-keyframes blink-animation {
                          0% {
                    opacity: 0;
                  
                          }
                    100% {
                   opacity: 1;
                  
                          }
                        }
                  
                  .footer_overlay_img {
                      position: absolute;
                      left: 0px;
                      width: 100%;
                      height: 100%;
                      top: 0px;
                  }
                  
                  .footer_overlay_img img{
                      height: 100%;
                  }
                  </style>
                  <div class="footer_section_top_head"><img src="https://static.tossdown.com/images/b78eeaf9-fbb2-409e-8608-0f66c96b91f0.png" style="width: 100%;" /></div>
                  <section class="footer_section">
                  <div class="footer_container">
                  <div class="footer_overlay_img"><img src="https://static.tossdown.com/images/9811ea76-a289-4018-8f3f-ed7ce8e910d8.png" /></div>
                  <div class="bulb_one">
                  <div class="bulb_one_main"><img src="https://static.tossdown.com/images/819d5894-9ee1-4ca7-b762-cb2867ef2bbb.png" data-aos-delay="250" data-aos-duration="900" data-aos="zoom-in" data-aos-mirror="true" data-aos-once="true" data-aos-offset="50" /></div>
                  </div>
                  <div class="bulb_two">
                  <div class="bulb_two_main"><img src="https://static.tossdown.com/images/90f0dfc5-9a53-4a89-8b6c-2094e3431fb5.png" data-aos-delay="200" data-aos-duration="900" data-aos="zoom-in" data-aos-mirror="true" data-aos-once="true" data-aos-offset="50" /></div>
                  </div>
                  <div class="footer_data_box">
                  <div class="footer_data_box_detail ">
                  <div class="footer_detail_box_one">
                  <figure><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png" /></figure>
                  <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit. Pellent esque fermentum.</p>
                  </div>
                  </div>
                  <div class="footer_data_box_detail">
                  <div class="footer_detail_box_two">
                  <div class="footer_detail_box_heading accordion modtitle">
                  <h3 class="footer_collapse_icons">Site Links</h3>
                  </div>
                  <div class="modcontent panel">
                  <ul class="footer_detail_box_content_list">
                  <li><a >Home</a></li>
                  <li><a >View Menu</a></li>
                  <li><a >Location</a></li>
                  <li><a>About</a></li>
                  <li><a >Contact</a></li>
                  </ul>
                  </div>
                  </div>
                  </div>
                  <div class="footer_data_box_detail">
                  <div class="footer_detail_box_two">
                  <div class="footer_detail_box_heading accordion modtitle">
                  <h3 class="footer_collapse_icons">Product categories</h3>
                  </div>
                  <div class="modcontent panel">
                  <ul class="footer_detail_box_content_list">
                  <li><a>All Categories</a></li>
                  <li><a >Light Fare</a></li>
                  <li><a >Main Menu</a></li>
                  <li><a >Platters</a></li>
                  <li><a >Weekly Specials</a></li>
                  </ul>
                  </div>
                  </div>
                  </div>
                  <div class="footer_data_box_detail">
                  <div class="footer_detail_box_two">
                  <div class="footer_detail_box_heading accordion modtitle">
                  <h3 class="footer_collapse_icons">Useful links</h3>
                  </div>
                  <div class="modcontent panel accordion modtitle">
                  <ul class="footer_detail_box_content_list">
                  <li><a>Refund and return policy</a></li>
                  <li><a>Privacy policy</a></li>
                  <li><a>FAQ’s</a></li>
                  <li><a>Cancelation policy</a></li>
                  <li><a>Delivery policy</a></li>
                  </ul>
                  </div>
                  </div>
                  </div>
                  <div class="footer_data_box_detail">
                  <div class="footer_detail_box_two ">
                  <div class="footer_detail_box_heading accordion modtitle">
                  <h3 class="footer_collapse_icons">Contact Us</h3>
                  </div>
                  <div class="modcontent panel accordion modtitle">
                  <ul class="footer_contact_list ">
                  <li><i class="fal fa-phone" aria-hidden="true"></i><a >(905) 123-1234</a></li>
                  <li><i class="fal fa-map-marker-alt" aria-hidden="true"></i><a>It is a long established fact that a reader will be here.</a></li>
                  </ul>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </section>
                  <script>
                      var acc = document.getElementsByClassName("accordion");
                      var i;
                      
                      for (i = 0; i < acc.length; i++) {
                        acc[i].addEventListener("click", function() {
                          this.classList.toggle("active");
                          var panel = this.nextElementSibling;
                          if (panel.style.display === "block") {
                            panel.style.display = "none";
                          } else {
                            panel.style.display = "block";
                          }
                        });
                      }
                      </script>
                      
                      `
                      },

                      {
                        id:"footer10",
                        name:"Footer 11",
                        height:"550px",
                        head:`
                        <head>
                            <meta charset="utf-8" />
                            <meta
                              name="viewport"
                              content="width=device-width, initial-scale=1, shrink-to-fit=no"
                            /> 
                            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                            
                            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                        
                          <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                          <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                          
                          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                          
                        </head>
                        
                        `,

                        code:`
                       
                  <style>
                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                    </style>
                   
                    <style>
                    .custom_container{
                       max-width:1140px; 
                       margin:0 auto;
                       }
                    footer{
                      display:block;
                      float:left;
                      background-image: url('https://static.tossdown.com/images/33475dff-df24-49f1-bf1b-eff63fd036e1.png');
                             background-size: cover;
                          background-repeat: no-repeat;
                          width: 100%;
                          height: 100%;
                      }
                      .footer_main{
                      padding:110px 10px 48px 10px;
                      position:relative;
                      }
                      .footer_content_section{
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      }
                      .footer_log{
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      padding-bottom:32px;
                      }
                      .footer_log strong img{
                      padding-bottom:8px;
                      }
                      .footer_log strong{
                          font-family: 'croisntn';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 27.0685px;
                      line-height: 32px;
                      text-transform: uppercase;
                      color: #FFFFFF;
                      }
                      .footer_log small{
                       font-family: 'croisntn';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15.79px;
                      line-height: 19px;
                      text-transform: capitalize;
                      color: #FFFFFF;
                      }
                      .footer_nav_box{
                          display: flex;
                          align-items: center;
                      padding-bottom:48px;
                          flex-wrap: wrap;
                          justify-content: center;
                      }
                      .footer_nav_box a{
                      font-family: 'Ubuntu';
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 16px;
                      text-transform: uppercase;
                      color: #FFFFFF;
                      padding-right:40px;
                      }
                      .footer_nav_box a:last-child{
                      padding-right:0px;
                      }
                      .footer_social_links{
                      display: flex;
                      align-items: center;
                      }
                      .footer_social_links a{
                      Width:36px;
                      Height:36px;
                      border-radius: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-right:4px;
                      }
                      .footer_social_links a.facebook{
                      background: #103F8B;
                      color:#ffffff;
                      }
                      .footer_social_links a.instagram{
                      background: linear-gradient(180deg, #6943AA 35.42%, #CC623F 65.1%, #D78128 100%);
                      color:#ffffff;
                      }
                      .footer_social_links a:last-child{
                      margin-right:0px;
                      }
                      .footer_social_links a i{
                      color: #FFFFFF;
                      }
                      @media(max-width:540px){
                     
                      
                      .footer_main {
                          padding: 80px 10px 35px 10px;
                      }
                      .footer_nav_box a {
                          padding-right: 30px;
                      }
                      }
                      @media(max-width:440px){
                      .footer_nav_box a{
                          padding-right: 20px;
                          padding-bottom: 12px;
                      }
                      .footer_nav_box {
                          padding-bottom: 30px;
                      }
                      }
                      .footer_overlay_image {
                        position: absolute;
                        top: -15px;
                        right: 0px;
                        z-index: -1;
                    }
                    .footer_overlay_image img {
                        width:250px;
                    }
                    @media(max-width:767px){
                    .footer_overlay_image {
                        top: -30px;
                    }
                      
                          </style>
                      <footer>
                      <div class="custom_container">
                      <div class="footer_main">
                      <div class="footer_overlay_image"><img src="https://static.tossdown.com/images/b67f2dfd-d4e2-407b-8cdf-baa22f4bbe33.png" /></div>
                      <div class="footer_content_section">
                      <div class="footer_log"><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png" /></div>
                      <div class="footer_nav_box"><a >Home</a><a >Menu</a><a >Our Store</a><a >About Us</a><a >Contact Us</a></div>
                      <div class="footer_social_links"><a class="facebook" target="blank" ><i class="fab fa-facebook-f"></i></a><a class="instagram" target="blank" ><i class="fab fa-instagram"></i></a></div>
                      </div>
                      </div>
                      </div>
                      </footer>
                        
                        `
                        },
                        {
                          id:"footer11",
                          name:"Footer 12",
                          height:"550px",
                          head:`
                          <head>
                              <meta charset="utf-8" />
                              <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1, shrink-to-fit=no"
                              /> 
                              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                              <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                              
                              <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                          
                            <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                            <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                            
                            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                            
                          </head>
                          
                          `,

                          code:`
                        
                    <style>
                      @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                      </style>
                     
                      <style>
                      .custom_container {
                        max-width: 1200px!important;
                        margin: 0 auto;
                      }
                      .footer_section {
                        background: #F9F6F1;
                        color: #fff;
                        float: left;
                        width: 100%;
                        padding: 50px 15px;
                    margin-bottom: 15px;
                      }
                
                      .footer_data_box {
                        display: grid;
                        width: 100%;
                        grid-template-columns: 2fr 1fr 1fr 2fr;
                        grid-template-rows: auto;
                        grid-template-areas: ". . . .";
                        gap: 30px 20px;
                      }
                      .footer_detail_box_one {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                      }
                      .footer_detail_box_one figure {
                        margin: 0px !important;
                        width: 120px !important;
                        padding-bottom: 20px;
                      }
                .footer_data_box_logo {
                    margin-bottom: 48px;
                    text-align: center;
                }
                      .footer_detail_box_one figure img {
                        width: 100%;
                      }
                      .footer_detail_box_one p {
                     font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    color: #00000080;
                    margin: 0px !important;
                    padding-bottom: 20px;
                    font-family: 'Inter';
                      }
                .footer-detail-box-desciption p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                        color: rgba(0, 0, 0, 0.6);
                    margin: 0px !important;
                    font-family: 'Inter';
                }
                      .footer_socail_links {
                        display: flex;
                        align-items: center;
                      }
                      .footer_socail_links a {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        border-radius: 50%;
                        justify-content: center;
                        margin-right: 0px;
                      }
                      .footer_socail_links a:last-child {
                        margin-right: 0px;
                      }
                      .footer_socail_links a i {
                        color: #000000;
                        font-size: 24px;
                      }
                
                      .footer_detail_box_two {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                      }
                      .footer_detail_box_heading {
                        position: relative;
                        margin-bottom: 20px;
                border-bottom: 1px solid #00000017;
                    padding-bottom: 16px;
                      }
                .footer_detail_box_heading h3 {
                    margin-bottom: 0px !important;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 23px;
                    color: #000000;
                    letter-spacing: 2px;
                    font-family: 'Manrope';
                }
                      .footer_detail_box_content_list {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 0px !important;
                        margin: 0px !important;
                    margin-bottom: 10px !important;
                      }
                      .footer_detail_box_content_list li {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        padding-bottom: 15px;
                      }
                      .footer_detail_box_content_list li i {
                        padding-right: 6px;
                        color: #000000;
                        font-size: 15px;
                      }
                      .footer_detail_box_content_list li:last-child {
                        padding-bottom: 0px;
                      }
                      .footer_detail_box_content_list li a {
                    text-transform: capitalize;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    margin: 0px !important;
                    font-family: 'Inter';
                    color: rgba(0, 0, 0, 0.6);
                      }
                
                      .footer_contact_list {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 0px !important;
                        margin: 0px !important;
                      }
                      .footer_contact_list li {
                        list-style: none;
                        display: flex;
                        padding-bottom: 15px;
                      }
                      .footer_contact_list li:last-child {
                        padding-bottom: 0px;
                      }
                      .footer_contact_list li i {
                        padding-right: 6px;
                        color: #000000;
                        font-size: 15px;
                        line-height: 24px;
                      }
                      .footer_contact_list li a {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 24px;
                        text-transform: capitalize;
                        color: #000000 !important;
                      }
                .modcontent.panel .form-section input::placeholder {
                    font-size: 12px;
                }
                .modcontent.panel .form-section input {
                      border: 1px solid #00000017;
                    border-radius: 0px !important;
                    background-color: unset;
                    height: 55px;
                    z-index: 1;
                    font-size: 14px;
                }
                      @media (min-width: 768px) and (max-width: 1024px) {
                        .footer_data_box {
                          grid-template-columns: 1fr 1fr 1fr;
                          grid-template-areas: ". . .";
                        }
                      }
                      @media (max-width: 767px) {
                        .footer_data_box {
                          grid-template-columns: 1fr 1fr;
                          grid-template-areas: ". .";
                        }
                      }
                
                      @media (max-width: 540px) {
                        .footer_data_box {
                          grid-template-columns: 1fr;
                          grid-template-areas: ".";
                          gap: 0px 20px;
                        }
                .footer_section {
                    background: #F9F6F1;
                    color: #fff;
                    float: left;
                    width: 100%;
                    padding: 40px 15px 30px !important;
                    margin-bottom: 15px;
                }
                .footer_detail_box_heading h3{
                    font-size: 13px !important;
                }
                .footer_detail_box_heading {
                    margin-bottom: 12px !important;
                    padding-bottom: 12px !important;
                }
                        .panel {
                          display: none;
                          overflow: hidden;
                          transition: 0.5s ease-out;
                          text-align: left;
                        }
                
                        .footer_detail_box_heading.accordion:after {
                          content: "\\002B";
                    color: #000;
                    font-family: "revicons";
                    font-size: 25px;
                    position: absolute;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    margin: 0px;
                    top: -5px;
                    right: 0px;
                    font-weight: 600;
                        }
                        .footer_detail_box_heading.active:after {
                          content: "\\2212" !important;
                          color: #000;
                          font-family: "revicons";
                          font-size: 21px;
                          position: absolute;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          margin: 0px;
                          top: -5px;
                          right: 0px;
                    font-weight: 600;
                        }
                      }
                      @media (min-width: 540px) {
                        .modcontent.panel {
                          display: block !important;
                        }
                      }
               
                    </style>
                <section class="footer_section">
                <div class="custom_container">
                <div class="footer_data_box_logo"><img class="footer-logo" src="https://static.tossdown.com/images/2760b153-df8d-4fce-b9ef-cdd1d1817fac.png" style="width: 240px;" /></div>
                <div class="footer_data_box">
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_one">
                <div class="footer_detail_box_heading accordion modtitle">
                <h3 class="footer_collapse_icons">About </h3>
                </div>
                <div class="modcontent panel">
                <div class="footer-detail-box-desciption">
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div class="footer_socail_links" style="margin-top: 21px;"><a target="_blank" rel="noopener"><i class="fab fa-facebook-f"></i></a> <a target="_blank" rel="noopener"><i class="fab fa-instagram"></i></a></div>
                </div>
                </div>
                </div>
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_two">
                <div class="footer_detail_box_heading accordion modtitle">
                <h3 class="footer_collapse_icons">Site Links</h3>
                </div>
                <div class="modcontent panel">
                <ul class="footer_detail_box_content_list">
                <li><a >Home</a></li>
                <li><a >Shop</a></li>
                <li><a >Locations</a></li>
                <li><a >About</a></li>
                <li><a >Contact</a></li>
                </ul>
                </div>
                </div>
                </div>
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_two">
                <div class="footer_detail_box_heading accordion modtitle">
                <h3 class="footer_collapse_icons">Useful Links</h3>
                </div>
                <div class="modcontent panel">
                <ul class="footer_detail_box_content_list">
                <li><a >FAQ's</a></li>
                <li><a >Privacy Policy</a></li>
                <li><a >Terms &amp; Conditions</a></li>
                <li><a >Login | Signup</a></li>
                </ul>
                </div>
                </div>
                </div>
                <div class="footer_data_box_detail">
                <div class="footer_detail_box_two">
                <div class="footer_detail_box_heading accordion modtitle">
                <h3 class="footer_collapse_icons">Subscribe Our Newsletter</h3>
                </div>
                <div class="modcontent panel accordion modtitle">
                <div class="footer-detail-box-desciption">
                <p>Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.</p>
                </div>
                <div class="form-section" style="margin-top: 20px;">
                <div class="form-group">
                <div class="input-group" id="franchise_email_div" style="width: 100%;"><input id="get-user-subscribe-email" type="text" required="required" name="email" class="form-control form" placeholder="Your Email..." /> <a class="submitted btn"  style="font-size: 14px; margin-left: 13px; color: #000000; background-color: #ffcb49; border-radius: unset; margin-top: 3px; height: 49px; position: absolute; right: 4px; padding-top: 13px; font-weight: bold; z-index: 111;">Join</a></div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </section>
                <script>
                      var acc = document.getElementsByClassName("accordion");
                      var i;
                
                      for (i = 0; i < acc.length; i++) {
                        acc[i].addEventListener("click", function () {
                          this.classList.toggle("active");
                          var panel = this.nextElementSibling;
                          if (panel.style.display === "block") {
                            panel.style.display = "none";
                          } else {
                            panel.style.display = "block";
                          }
                        });
                      }
                    </script>
                          
                          `
                          },
                          {
                            id:"footer12",
                            name:"Footer 13",
                            height:"550px",
                            head:`
                            <head>
                                <meta charset="utf-8" />
                                <meta
                                  name="viewport"
                                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                /> 
                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                                
                                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                            
                              <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                              <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                              
                              <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                              <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                              
                            </head>
                            
                            `,

                            code:`
                           
                      <style>
                        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                        </style>
                       
                        <style>
                        .footer_data_box_detail.pd-top-hold-last-col {
                            padding-top: 50px;
                        }
                        .social-media-layyer {
                            position: absolute;
                            right: 0px;
                            max-width: 503px;
                            height: 69px;
                            background: #FFFFFF;
                            width: 100%;
                            clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
                            text-align: center;
                            top: -1px;
                        }
                        .social-media-layyer ul {
                            margin-top: 26px;
                        }
                        .social-media-layyer ul li {
                            display: inline;
                            width: 36px;
                            height: 36px;
                            left: 1237px;
                            top: 6287px;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            padding: 11px 13px 6px;
                            border-radius: 30px;
                            margin: 2px;
                        }
                        .social-media-layyer ul li a i {
                            font-size: 17px;
                            color: #212121;
                        }
                        
                        
                        .copy_right_text1 span a {
                            font-size: 14px;
                            line-height: 18px;
                            color: rgba(255, 255, 255, 0.6);
                            font-family: 'Kanit';
                            font-style: normal;
                            font-weight: 300;
                        }
                        .copy_right_text1 span {
                            font-size: 14px;
                            line-height: 18px;
                            color: rgba(255, 255, 255, 0.6);
                            font-family: 'Kanit';
                            font-style: normal;
                            font-weight: 300;
                        }
                        .copy_right_text1 {
                            font-size: 14px;
                            line-height: 18px;
                            color: rgba(255, 255, 255, 0.6);
                            font-family: 'Kanit';
                            font-style: normal;
                            font-weight: 300;
                        }
                        .footer-last-description p {
                            font-family: 'Kanit';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 22px;
                            color: rgba(255, 255, 255, 0.6);
                        }
                        .copy_right_text1 {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: rgba(255, 255, 255, 0.8);
                        }
                        .footer_section {
                            background: unset;
                            color: #fff;
                            float: left;
                            width: 100%;
                            margin-bottom: 0px;
                            position: relative;
                            z-index: 111;
                            background-color: #212121;
                        }
                        a.location-des {
                            color: #fff;
                            text-decoration: underline;
                            margin-top: -10px;
                            display: block;
                        }
                        section.footer_section .custom_container {
                            padding: 60px 15px 60px !important;
                            border-bottom: 1px solid #ffffff4a;
                        }
                              .custom_container {
                                max-width: 1200px;
                                margin: 0 auto;
                              }
                              .footer_data_box {
                            display: grid;
                            width: 100%;
                            grid-template-columns: 1.6fr 1fr 1.6fr 1.3fr;
                            grid-template-areas: ". . . .";
                            gap: 36px 130px;
                        }
                        
                              .footer_data_box {
                             display: grid;
                            width: 100%;
                            grid-template-columns: 1.6fr 1fr 1fr 1.3fr;
                            grid-template-areas: ". . . .";
                            gap: 36px 130px;
                              }
                              .footer_detail_box_one {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                              }
                              .footer_detail_box_one figure {
                                margin: 0px !important;
                                width: 120px !important;
                                padding-bottom: 20px;
                              }
                        
                              .footer_detail_box_one figure img {
                                width: 100%;
                              }
                              .footer_detail_box_one p {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 22px;
                                color: #000000;
                                margin: 0px !important;
                                padding-bottom: 20px;
                              }
                        .footer_socail_links ul li {
                            display: flex;
                            margin-bottom: 15px;
                        }
                              .footer_socail_links {
                                display: flex;
                                align-items: center;
                              }
                              .footer_socail_links a {
                                background: #F19F1F;
                            width: 36px;
                            height: 36px;
                            display: flex;
                            align-items: center;
                            border-radius: 50%;
                            justify-content: center;
                            margin-right: 10px;
                              }
                              .footer_socail_links a:last-child {
                                margin-right: 0px;
                              }
                              .footer_socail_links a i {
                                color: #000000;
                                font-size: 16px;
                              }
                        
                              .footer_detail_box_two {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                              }
                              .footer_detail_box_heading {
                                position: relative;
                                margin-bottom: 20px;
                              }
                              .footer_detail_box_heading h3 {
                            font-family: 'Kanit';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            text-transform: uppercase;
                            color: rgba(255, 255, 255, 0.4);
                              }
                              .footer_detail_box_content_list {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding: 0px !important;
                                margin: 0px !important;
                              }
                              .footer_detail_box_content_list li {
                                list-style: none;
                                display: flex;
                                align-items: center;
                                padding-bottom: 15px;
                              }
                              .footer_detail_box_content_list li i {
                                padding-right: 6px;
                                color: #000000;
                                font-size: 15px;
                              }
                              .footer_detail_box_content_list li:last-child {
                                padding-bottom: 0px;
                              }
                              .footer_detail_box_content_list li a {
                        font-family: 'Kanit';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 19px;
                            text-transform: uppercase;
                            color: #FFFFFF;
                              }
                        
                              .footer_contact_list {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding: 0px !important;
                                margin: 0px !important;
                              }
                              .footer_contact_list li {
                                list-style: none;
                                display: flex;
                                padding-bottom: 15px;
                              }
                              .footer_contact_list li:last-child {
                                padding-bottom: 0px;
                              }
                              .footer_contact_list li i {
                                padding-right: 6px;
                                font-size: 15px;
                                line-height: 24px;
                              }
                              .footer_contact_list li a {
                             color: rgba(255, 255, 255, 0.8) !important;
                            font-family: 'Kanit';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 19px;
                            text-transform: capitalize;
                              }
                        
                              @media (min-width: 768px) and (max-width: 1024px) {
                                .footer_data_box {
                                  grid-template-columns: 1fr 1fr 1fr;
                                  grid-template-areas: ". . .";
                                }
                              }
                              @media (max-width: 767px) {
                                .footer_data_box {
                                  grid-template-columns: 1fr 1fr;
                                  grid-template-areas: ". .";
                                }
                        .footer-last-head-box {
                            margin-bottom: 24px;
                        text-align: center;
                        }
                        .footer_section {
                            padding: 70px 15px 24px;
                              }
                              }
                        
                              @media (max-width: 540px) {
                                .footer_data_box {
                                  grid-template-columns: 1fr;
                                  grid-template-areas: ".";
                                  gap: 0px 20px;
                                }
                        
                                .panel {
                                  display: none;
                                  overflow: hidden;
                                  transition: 0.5s ease-out;
                                  text-align: left;
                                }
                        
                                .footer_detail_box_heading.accordion:after {
                                  content: "\\002B";
                                  font-family: "revicons";
                                  font-size: 26px;
                                  position: absolute;
                                  height: 100%;
                                  display: flex;
                                  align-items: center;
                                  margin: 0px;
                                  top: 0px;
                                  right: 0px;
                                }
                                .footer_detail_box_heading.active:after {
                                  content: "\\2212" !important;
                                  font-family: "revicons";
                                  font-size: 21px;
                                  position: absolute;
                                  height: 100%;
                                  display: flex;
                                  align-items: center;
                                  margin: 0px;
                                  top: 0px;
                                  right: 0px;
                                }
                              }
                              @media (min-width: 540px) {
                                .modcontent.panel {
                                  display: block !important;
                                }
                              }
                            </style>
                        <section class="footer_section">
                        <div class="social-media-layyer">
                        <ul>
                        <li><a  target="_blank" rel="noopener"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                        <li><a  target="_blank" rel="noopener"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
                        </ul>
                        </div>
                        <div class="custom_container">
                        <div class="footer_data_box">
                        <div class="footer_data_box_detail">
                        <div class="footer_detail_box_two footer-last-head-box">
                        <div class="footer_detail_box_heading "><a class="footer-logo"><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png" /></a></div>
                        <div class="modcontent">
                        <div class="footer-last-description">
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="footer_data_box_detail">
                        <div class="footer_detail_box_two">
                        <div class="footer_detail_box_heading accordion modtitle">
                        <h3 class="footer_collapse_icons">Site Links</h3>
                        </div>
                        <div class="modcontent panel">
                        <ul class="footer_detail_box_content_list">
                        <li><a >Home</a></li>
                        <li><a >Our Products</a></li>
                        <li><a >Services</a></li>
                        <li><a >Our Process</a></li>
                        <li><a >Vision &amp; Mission</a></li>
                        <li><a >about</a></li>
                        <li><a >Contact</a></li>
                        <li><a >Location</a></li>
                        </ul>
                        </div>
                        </div>
                        </div>
                        <div class="footer_data_box_detail">
                        <div class="footer_detail_box_one">
                        <div class="footer_detail_box_two">
                        <div class="footer_detail_box_heading accordion modtitle">
                        <h3 class="footer_collapse_icons contact-heading">contact us</h3>
                        </div>
                        <div class="modcontent panel accordion modtitle">
                        <ul class="footer_contact_list">
                        <li><i class="fal fa-envelope" aria-hidden="true"></i><a>test@gmail.com.pk</a></li>
                        <li><i class="fal fa-phone" aria-hidden="true"></i><a>0311 1936484</a></li>
                        </ul>
                        </div>
                        </div>
                        <div class="footer_detail_box_two">
                        <div class="footer_detail_box_heading accordion modtitle" style="margin-top: 24px;">
                        <h3 class="footer_collapse_icons contact-heading">Find Us</h3>
                        </div>
                        <div class="modcontent panel accordion modtitle"><a class="location-des">Locations</a></div>
                        </div>
                        </div>
                        </div>
                        <div class="footer_data_box_detail pd-top-hold-last-col">
                        <div class="footer_detail_box_two">
                        <div class="footer_detail_box_heading accordion modtitle">
                        <h3 class="footer_collapse_icons">opening hours</h3>
                        </div>
                        <div class="modcontent panel">
                        <ul class="footer_detail_box_content_list">
                        <li><a>Mon - Thu – 01:00PM to 11:30PM</a></li>
                        <li><a>Fri - Sun – 01:00PM to 11:30PM</a></li>
                        </ul>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </section>
                        
                        <script>
                              var acc = document.getElementsByClassName("accordion");
                              var i;
                              for (i = 0; i < acc.length; i++) {
                                acc[i].addEventListener("click", function () {
                                  this.classList.toggle("active");
                                  var panel = this.nextElementSibling;
                                  if (panel.style.display === "block") {
                                    panel.style.display = "none";
                                  } else {
                                    panel.style.display = "block";
                                  }
                                });
                              }
                            </script>
                            
                            `
                            },
                            {
                              id:"footer13",
                              name:"Footer 14",
                              height:"550px",
                              head:`
                              <head>
                                  <meta charset="utf-8" />
                                  <meta
                                    name="viewport"
                                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                  /> 
                                  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                  <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                                  
                                  <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                              
                                <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                                <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                                
                                <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                                <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                                
                              </head>
                              
                              `,

                              code:`
                             
                        <style>
                          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                          </style>
                         
                          <style>

                          .custom_container{
                            max-width:1140px;
                            margin: 0 auto;
                          }
                          .footer_section{
                          background:#403E3F;
                            color: #F1F2ED;
                            float: left;
                            width: 100%;
                            padding: 100px 15px 30px 15px;
                           position: relative;
                          }
                          .footer_section::before {
                              content: '';
                              background-image: url('https://tossdown.site/images/1e655df7f2cbe10c6246485099a20b24_1659435523.png');
                              background-position: center;
                              background-size: cover;
                              background-repeat: no-repeat;
                              position: absolute;
                              bottom: 0px;
                              right: 0px;
                              width: 400px;
                              height: 100%;
                          }
                          
                          
                          .for-margin1 {
                          margin-left:32px;
                              }
                          .footer_data_box{
                              display: grid;
                              width: 100%;
                              grid-template-columns: 1fr 1.1fr 1fr ;
                              grid-template-rows: auto;
                              grid-template-areas: ". . . .";
                              gap: 30px 45px;
                          }
                          .footer_detail_box_one {
                              display: flex;
                              flex-direction: column;
                              width: 100%;
                              align-items: center;
                              justify-content: center;
                              text-align: center;
                              margin-top: -73px;
                              margin-bottom: 49px;
                          }
                          .icons-contain {
                              display: flex;
                              justify-content: center;
                              width: 101px;
                              gap: 13px;
                          
                          
                          }
                          i.fab.fa-facebook-f {
                              width: 40px;
                              height: 40px;
                          color:#fff;
                              display: flex;
                              align-items: center;
                              border: 0.5px solid rgba(241, 242, 237, 0.16);
                              justify-content: center;
                              border-radius: 50%;
                          }
                          i.fab.fa-instagram {
                          
                          color:#fff;
                              border: 0.5px solid rgba(241, 242, 237, 0.16);
                              width: 40px;
                              height: 40px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              border-radius: 50%;
                          }
                          .footer_detail_box_one .footer_logo {
                          margin: 0px !important;
                              width: 210px !important;
                          padding-bottom: 30px;
                          }
                          
                          .footer_detail_box_one .footer_logo img{
                            width: 156px;
                          }
                          .footer_detail_box_one p{
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 13px;
                          line-height: 21px;
                          color: rgba(255, 255, 255, 0.6);
                              margin: 0px !important;
                              padding-bottom: 24px;
                              padding-right: 0px;
                          
                          }
                          .footer_socail_links{
                          display: flex;
                          align-items: center;
                          }
                          .footer_socail_links a{
                            display: flex;
                            align-items: center;
                            margin-right: 8px;
                          background: #FFFFFF;
                          border-radius: 3px;
                          width :28px;
                          height :28px;
                          justify-content: center;
                          }
                          .footer_socail_links a i{
                            color: #000000;
                            font-size: 14px;
                          }
                          
                          .footer_detail_box_two{
                            display: flex;
                          flex-direction: column;
                          width: 100%;
                          align-items: center;
                          
                          }
                          .footer_detail_box_heading {
                            position: relative;
                            padding-bottom: 24px;
                          }
                          .for-margin2 {
                              margin-left: 0px;
                          }
                          .footer_detail_box_heading h3{
                            margin-bottom: 0px !important;
                          font-family: 'Ibarra Real Nova'!important;
                          font-style: normal;
                          font-weight: 700;
                          font-size: 16px;
                          line-height: 20px;
                          text-transform: uppercase;
                          color: #F1F2ED;
                          
                          
                          }
                          .footer_detail_box_content_list{
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            padding: 0px !important;
                            margin: 0px !important;
                          }
                          .footer_detail_box_content_list li{
                            list-style: none;
                            display: flex;
                            align-items: center;
                            gap: 0px 5px;
                            padding-bottom: 2px;
                          }
                          .footer_detail_box_content_list li:last-child{
                            padding-bottom:0px ;
                          }
                          li.footer_hours_list {
                              justify-content: space-between;
                          }
                          
                          .footer_detail_box_content_list li a {
                          
                              font-family: 'Sakkal Majalla';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 20px;
                              line-height: 28px;
                              text-align: center;
                             color: rgba(255, 255, 255, 0.6);
                          }
                          
                          
                          .footer_detail_box_content_list li a:hover{
                          color: rgba(255, 255, 255, 0.6);
                          }
                          
                          .footer_contact_list{
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            padding: 0px !important;
                            margin: 0px !important;
                          }
                          .footer_contact_list li{
                            list-style: none;
                            display: flex;
                            padding-bottom: 16px;
                          }
                          .footer_contact_list li:last-child{
                            padding-bottom: 0px;
                          }
                          .footer_contact_list li i{
                              color: rgba(255, 255, 255, 0.6);
                              font-size: 18px;
                          line-height: 17px;
                          }
                          .footer_contact_list li a{
                          font-family: 'Barlow';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 17px;
                          text-transform: capitalize;
                          color: rgba(255, 255, 255, 0.7);
                          }
                          .footer_contact_list li a:hover{
                          color: rgba(255, 255, 255, 0.7);
                          }
                          @media (min-width: 768px) and (max-width: 1024px) {
                            .footer_data_box {
                              grid-template-columns: 1fr 1.1fr ;
                              grid-template-areas: ". . ";
                          }
                          
                          }
                          
                          
                          @media (max-width: 767px) {
                          p.add-to-cart-button-suggestion {
                              justify-content: center;
                          }
                            .footer_data_box {
                              grid-template-columns: 1.1fr 1fr;
                              grid-template-areas: ". .";
                          }
                          }
                          
                          @media (max-width: 540px) {
                          .for-margin1 {
                          margin-left:0px!important;
                              }
                          p.add-to-cart-button a {
                              width: 125px !important;
                              max-width: 125px !important;
                          }
                          
                          .footer_detail_box_two {
                              display: flex;
                              flex-direction: column;
                              width: 100%;
                              align-items: unset;
                          }
                          
                          
                            .footer_data_box {
                              grid-template-columns: 1fr;
                              grid-template-areas: ".";
                          gap:50px 0px;
                          }
                          .footer_detail_box_heading {
                            padding-bottom: 0px;
                          }
                          .footer_detail_box_content_list {
                              padding-top: 15px !important;
                          }
                          .footer_contact_list {
                              padding-top: 15px !important;
                          }
                          .footer_section {
                              padding: 40px 15px;
                          }
                          .footer_socail_links {
                              padding-bottom: 15px;
                          }
                          .panel {
                              display: none;
                              overflow: hidden;
                              transition:0.5s ease-out;
                              text-align: left;
                          }
                          
                          .footer_detail_box_heading.accordion:after {
                            content: '\\002B';
                          color: #ffffff;
                          font-family: 'revicons';
                          font-size: 26px;
                          position: absolute;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          margin: 0px;
                          top: 0px;
                          right: 0px;
                          }
                          .footer_detail_box_heading.active:after {
                              content: "\\a2212" !important;
                          color: #ffffff;
                          font-family: 'revicons';
                          font-size: 21px;
                          position: absolute;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          margin: 0px;
                          top: 0px;
                          right: 0px;
                          }
                          .footer_socail_links {
                              justify-content: center;
                          }
                          .footer_detail_box_one p {
                              text-align: center;
                          }
                          .footer_detail_box_one {
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                              justify-content: center;
                              text-align: center;
                          }
                          
                          }
                          
                          @media (min-width:540px){
                          .modcontent.panel {
                              display: block !important;
                          }
                          }
                          
                          
                          </style>
                          <section class="footer_section">
                          <div class="custom_container">
                          <div class="footer_data_box">
                          <div class="footer_data_box_detail">
                          <div class="footer_detail_box_two">
                          <div class="footer_detail_box_heading accordion modtitle for-margin1">
                          <h3 class="footer_collapse_icons">Site Links</h3>
                          </div>
                          <div class="modcontent panel">
                          <ul class="footer_detail_box_content_list">
                          <li><a >Home</a></li>
                          <li><a >Shop</a></li>
                          <li><a >About</a></li>
                          <li><a >Contact</a></li>
                          <li><a >Reviews</a></li>
                          </ul>
                          </div>
                          </div>
                          </div>
                          <div class="footer_data_box_detail  ">
                          <div class="footer_detail_box_one"><a class="footer_logo" ><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png" /></a>
                          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                          <div class="icons-contain"><a > <i class="fab fa-instagram"></i></a></div>
                          </div>
                          </div>
                          <div class="footer_data_box_detail">
                          <div class="footer_detail_box_two">
                          <div class="footer_detail_box_heading accordion modtitle  for-margin2">
                          <h3 class="footer_collapse_icons">QUICK LINKS</h3>
                          </div>
                          <div class="modcontent panel accordion modtitle">
                          <ul class="footer_detail_box_content_list">
                          <li><a >New Artworks</a></li>
                          <li><a >Best Sellers</a></li>
                          <li><a >Custom Artworks</a></li>
                          </ul>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </section>
                          
                          
                          <script>
                              var acc = document.getElementsByClassName("accordion");
                              var i;
                              
                              for (i = 0; i < acc.length; i++) {
                                acc[i].addEventListener("click", function() {
                                  this.classList.toggle("active");
                                  var panel = this.nextElementSibling;
                                  if (panel.style.display === "block") {
                                    panel.style.display = "none";
                                  } else {
                                    panel.style.display = "block";
                                  }
                                });
                              }
                              </script>
                              
                              `
                              },

                              {
                                id:"footer13",
                                name:"Footer 14",
                                height:"550px",
                                head:`
                                <head>
                                    <meta charset="utf-8" />
                                    <meta
                                      name="viewport"
                                      content="width=device-width, initial-scale=1, shrink-to-fit=no"
                                    /> 
                                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
                                    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
                                    
                                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
                                
                                  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
                                  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
                                  
                                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
                                  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
                                  
                                </head>
                                
                                `,

                                code:`
                               
                          <style>
                            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                            </style>
                           
                            <style>
                            .footer-last-description p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                            }
                            .copy_right_text1 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                   color: rgba(255, 255, 255, 0.8) !important;
                            }
                            .footer_section {
                                background: #3E4B5D;
                                color: #fff;
                                float: left;
                                width: 100%;
                                padding: 100px 15px;
                                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                                margin-bottom: 0px;
                                position: relative;
                                z-index: 111;
                                clip-path: ellipse(145% 100% at 48.5% 100%);
                            }
                                  .custom_container {
                                    max-width: 1200px;
                                    margin: 0 auto;
                                  }
                                  .footer_data_box {
                                display: grid;
                                width: 100%;
                                grid-template-columns: 1.9fr 1fr 1fr 1fr 1fr;
                                grid-template-areas: ". . . . .";
                                gap: 36px 130px;
                            }
                            
                                  .footer_data_box {
                                 display: grid;
                                width: 100%;
                                grid-template-columns: 1.6fr 1fr 1fr 1fr;
                                grid-template-areas: ". . . . .";
                                gap: 36px 130px;
                                  }
                                  .footer_detail_box_one {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                  }
                                  .footer_detail_box_one figure {
                                    margin: 0px !important;
                                    width: 120px !important;
                                    padding-bottom: 20px;
                                  }
                            
                                  .footer_detail_box_one figure img {
                                    width: 100%;
                                  }
                                  .footer_detail_box_one p {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: #000000;
                                    margin: 0px !important;
                                    padding-bottom: 20px;
                                  }
                            .footer_socail_links ul li {
                                display: flex;
                                margin-bottom: 15px;
                            }
                                  .footer_socail_links {
                                    display: flex;
                                    align-items: center;
                                  }
                            .media-links-hold {
                                margin-top: 24px;
                            }
                                  .footer_socail_links a {
                                    background: #fff;
                                width: 36px;
                                height: 36px;
                                display: flex;
                                align-items: center;
                                border-radius: 50%;
                                justify-content: center;
                                margin-right: 10px;
                                  }
                                  .footer_socail_links a:last-child {
                                    margin-right: 0px;
                                  }
                                  .footer_socail_links a i {
                                    color:#3e4b5d;
                                    font-size: 16px;
                                  }
                            
                                  .footer_detail_box_two {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                  }
                                  .footer_detail_box_heading {
                                        position: relative;
                                margin-bottom: 22px;
                            }
                                  .footer_detail_box_heading h3 {
                                font-family: 'Epilogue';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 14px;
                                text-transform: uppercase;
                                color: #FFFFFF;
                                  }
                                  .footer_detail_box_content_list {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    padding: 0px !important;
                                    margin: 0px !important;
                                  }
                                  .footer_detail_box_content_list li {
                                    list-style: none;
                                    display: flex;
                                    align-items: center;
                                    padding-bottom: 15px;
                                  }
                                  .footer_detail_box_content_list li {
                                list-style: none;
                                display: flex;
                                align-items: center;
                                padding-bottom: 15px;
                                font-family: 'Manrope';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 19px;
                                color: rgba(255, 255, 255, 0.6);
                            }
                                  .footer_detail_box_content_list li:last-child {
                                    padding-bottom: 0px;
                                  }
                                  .footer_detail_box_content_list li a {
                              text-transform: capitalize;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: rgba(255, 255, 255, 0.8);
                                  }
                            
                                  .footer_contact_list {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    padding: 0px !important;
                                    margin: 0px !important;
                                  }
                                  .footer_contact_list li {
                                    list-style: none;
                                    display: flex;
                                    padding-bottom: 15px;
                                  }
                                  .footer_contact_list li:last-child {
                                    padding-bottom: 0px;
                                  }
                                  .footer_contact_list li i {
                                    padding-right: 6px;
                                    font-size: 15px;
                                    line-height: 24px;
                                  }
                                  .footer_contact_list li a {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: rgba(255, 255, 255, 0.8) !important ;
                                  }
                            
                                  @media (min-width: 768px) and (max-width: 1024px) {
                                    .footer_data_box {
                                      grid-template-columns: 1fr 1fr 1fr;
                                      grid-template-areas: ". . .";
                                    }
                                  }
                                  @media (max-width: 767px) {
                            .download-app-section {
                                text-align: center;
                                margin-top: 20px;
                                margin-bottom: -8px;
                            }
                            .footer_contact_list {
                                align-items: center;
                            }
                            .modcontent.resp-only-media-screens {
                                text-align: center;
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                margin-top: 16px;
                            }
                            .footer_detail_box_two.mob-hid-only {
                                display: none;
                            }
                                .footer_detail_box_heading {
                                        position: relative;
                                margin-bottom: 5px;
                                border-bottom: 1px solid rgba(255, 255, 255, 0.12);
                                margin-top: 15px;
                                padding-bottom: 20px;
                            }
                                    .footer_data_box {
                                      grid-template-columns: 1fr 1fr;
                                      grid-template-areas: ". .";
                                    }
                            .footer-last-head-box {
                                margin-bottom: 24px;
                            text-align: center;
                            }
                            .footer_section {
                                padding: 70px 15px 24px;
                                  }
                                  }
                            
                                  @media (max-width: 540px) {
                                    .footer_data_box {
                                      grid-template-columns: 1fr;
                                      grid-template-areas: ".";
                                      gap: 0px 20px;
                                    }
                            
                                    .panel {
                                      display: none;
                                      overflow: hidden;
                                      transition: 0.5s ease-out;
                                      text-align: left;
                                    }
                            
                                    .footer_detail_box_heading.accordion:after {
                                      content: "\\002B";
                                      font-family: "revicons";
                                      font-size: 26px;
                                      position: absolute;
                                      height: 100%;
                                      display: flex;
                                      align-items: center;
                                      margin: 0px;
                                      top: 0px;
                                      right: 0px;
                                    }
                                    .footer_detail_box_heading.active:after {
                                      content: "\\2212" !important;
                                      font-family: "revicons";
                                      font-size: 21px;
                                      position: absolute;
                                      height: 100%;
                                      display: flex;
                                      align-items: center;
                                      margin: 0px;
                                      top: 0px;
                                      right: 0px;
                                    }
                                  }
                                  @media (min-width: 540px) {
                                    .modcontent.panel {
                                      display: block !important;
                            padding: 10px 0px;
                                    }
                                  }
                                </style>
                            <section class="footer_section">
                            <div class="custom_container">
                            <div class="footer_data_box ">
                            <div class="footer_data_box_detail">
                            <div class="footer_detail_box_two footer-last-head-box">
                            <div class="footer_detail_box_heading " style="border: unset;"><a class="footer-logo"><img src="https://static.tossdown.com/images/089409d2-f93f-437b-9641-bd820d446f92.png" /></a></div>
                            <div class="modcontent">
                            <div class="footer-last-description">
                            <p>Pakistan’s First On-Demand Waterless Car Wash App</p>
                            </div>
                            </div>
                            </div>
                            </div>
                            <div class="footer_data_box_detail">
                            <div class="footer_detail_box_two">
                            <div class="footer_detail_box_heading accordion modtitle">
                            <h3 class="footer_collapse_icons">Site Links</h3>
                            </div>
                            <div class="modcontent panel">
                            <ul class="footer_detail_box_content_list">
                            <li><a>Home</a></li>
                            <li><a>How it works</a></li>
                            <li><a>Pricing</a></li>
                            <li><a>What we offer</a></li>
                            <li><a>Why ?</a></li>
                            </ul>
                            </div>
                            </div>
                            </div>
                            <div class="footer_data_box_detail">
                            <div class="footer_detail_box_two">
                            <div class="footer_detail_box_heading accordion modtitle">
                            <h3 class="footer_collapse_icons">Useful links</h3>
                            </div>
                            <div class="modcontent panel">
                            <ul class="footer_detail_box_content_list">
                            <li><a >Privacy policy</a></li>
                            <li><a >Service policy</a></li>
                            <li><a>FAQ’s</a></li>
                            </ul>
                            </div>
                            </div>
                            </div>
                            <div class="footer_data_box_detail">
                            <div class="footer_detail_box_one">
                            <div class="footer_detail_box_two">
                            <div class="footer_detail_box_heading accordion modtitle">
                            <h3 class="footer_collapse_icons contact-heading">Contact Us</h3>
                            </div>
                            <div class="modcontent panel accordion modtitle">
                            <ul class="footer_contact_list">
                            <li><i class="fal fa-phone-alt" aria-hidden="true"></i><a >03001118950</a></li>
                            <li><i class="fal fa-envelope" aria-hidden="true"></i><a >test@gmail.com</a></li>
                            </ul>
                            </div>
                            </div>
                            </div>
                            <div class="footer_detail_box_two mob-hid-only">
                            <div class="footer_detail_box_heading media-links-hold accordion modtitle">
                            <h3 class="footer_collapse_icons">Follow us on</h3>
                            </div>
                            <div class="modcontent panel accordion modtitle active ">
                            <div class="footer_socail_links">
                            <ul>
                            <li><a class="social-media-color"  target="_blank" rel="noopener"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
                            <a class="social-media-color" target="_blank" rel="noopener"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
                            </ul>
                            </div>
                            </div>
                            </div>
                            </div>
                            <div class="footer_data_box_detail">
                            <div class="footer_detail_box_one">
                            <div class="footer_detail_box_two">
                            <div class="footer_detail_box_heading  download-app-section" style="border: unset;">
                            <h3 class="footer_collapse_icons contact-heading">Download app</h3>
                            </div>
                            <div class="modcontent">
                            <ul class="footer_contact_list google-apps-links">
                            <li><a><img src="https://tossdown.site/images/e5f93b097917ed83ca8947b084089c1e_1653489300.png" /></a></li>
                            <li><a><img src="https://tossdown.site/images/4145f7556c74fced4ed01cd3bde9232d_1653489160.png" /></a></li>
                            </ul>
                            </div>
                            <div class="modcontent resp-only-media-screens">
                            <div class="footer_socail_links">
                            <ul>
                            <li><a class="social-media-color"  target="_blank" rel="noopener"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
                             <a class="social-media-color"  target="_blank" rel="noopener"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
                            </ul>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </section>
                           
                            <script>
                                  var acc = document.getElementsByClassName("accordion");
                                  var i;
                            
                                  for (i = 0; i < acc.length; i++) {
                                    acc[i].addEventListener("click", function () {
                                      this.classList.toggle("active");
                                      var panel = this.nextElementSibling;
                                      if (panel.style.display === "block") {
                                        panel.style.display = "none";
                                      } else {
                                        panel.style.display = "block";
                                      }
                                    });
                                  }
                                </script>
                                
                                `
                                },
]
export default FooterObjects