import React from "react";

const ResponsiveToolFooter = () => {
  
  let year= new Date().getFullYear();

  return (
    <>
      <div className="res_dashboard-footer">
        <div className="custom-container-one">
            <div className="dashboard_foote-data">
              <p>
                © {year} <span>made with ❤️ by Sheharyar</span>
              </p>
            </div>
        </div>
      </div>
    </>
  );
};

export default ResponsiveToolFooter;
