const BlogSectionObjects=[
    {
    id:"blog0",
    name:"Blog One",
    height:"510px",
    head:`
    
    <head>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          /> 
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
          
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
          </head>
      
          <style>
      @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
      
          * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          margin: 0px;
          width: 100%;
          background:#fff;
          padding:40px 0px;
        }
          </style>


    `,

    code:` 
    
    <style>
    .home_section_six {
      display: block;
      width: 100%;
      padding: 0px 10px 0px 10px;
      float: left;
      margin-bottom: 16px;
    }
    .custom_container {
      max-width: 1140px;
      margin: 0 auto;
    }
    .home_section_six_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 24px;
    }
    .home_section_six_header h3 {
      font-family: "Work Sans" !important;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 34px;
      color: #000000;
      margin: 0px;
    }
    .home_section_six_header_btn {
      display: flex;
      align-items: center;
    }
    .home_section_six_header_btn a {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #000000;
    }
    .home_section_six_header_btn a i {
      padding-left: 18px;
    }
  
    .home_section_six_box {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 100%;
      grid-template-rows: auto;
      grid-template-areas: ". . . .";
      gap: 16px 16px;
    }
    .home_section_six_single_box_main {
      display: flex;
      flex-direction: column;
    }
    .home_section_six_single_box_figure {
      display: flex;
      width: 100%;
  }
    .home_section_six_single_box_figure img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    .home_section_six_single_box {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 16px 12px 21px 12px;
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      justify-content: space-between;
    }
  
    .home_section_six_single_box h3 {
      font-family: "Work Sans" !important;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 16px;
    }
    .home_section_six_single_box_des {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
    }
    .home_section_six_single_box_des_tags {
      display: flex;
      align-items: center;
    }
    .home_section_six_single_box_des_tags span {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      border-right: 1px solid #000000;
      color: #000000;
      padding-right: 6px;
      margin-right: 6px;
    }
    .home_section_six_single_box_des_tags small {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      color: #000000;
    }
    .home_section_six_single_box_des_date_time span {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      color: #000000;
    }
    @media (max-width: 1024px) {
      .home_section_six_box {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: ". . .";
      }
    }
  
    @media (max-width: 768px) {
      .home_section_six_box {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: ". .";
        gap: 12px 12px;
      }
    }
    @media (max-width: 540px) {
      .home_section_six_header {
        flex-direction: column;
      }
      .home_section_six_header h3 {
        padding-bottom: 12px;
        font-size: 24px;
        line-height: 31px;
      }
      .home_section_six_single_box {
        padding: 10px 8px 12px 8px;
        text-align: center;
      }
      .home_section_six_single_box_des {
        flex-direction: column;
      }
      .home_section_six_single_box_des_tags {
        flex-direction: column;
        border-bottom: 1px solid #000000;
        padding-bottom: 9px;
        width: 100%;
      }
      .home_section_six_single_box_des_tags span {
        border-right: unset;
        padding-bottom: 5px;
        margin: 0px;
        padding-right: 0px;
      }
      .home_section_six_single_box h3 {
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        width: 100% !important;
        max-width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 19px;
      }
    }
  </style>
  
  <section class="home_section_six">
    <div class="custom_container">
      <div class="home_section_six_header">
        <h3>Blogs</h3>
        <div class="home_section_six_header_btn">
          <a target="blank"
            >REad More <i class="far fa-arrow-right" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div class="home_section_six_box">
        <a class="home_section_six_single_box_main">
          <div class="home_section_six_single_box_figure">
            <img src="https://static.tossdown.com/site/08fdd2ad-1a21-49e4-aa2e-40b9cd00df11.png" />
          </div>
          <div class="home_section_six_single_box">
            <h3>pakistan launches its new store in anarkali</h3>
            <div class="home_section_six_single_box_des">
              <div class="home_section_six_single_box_des_tags">
                <small>Ontario</small>
              </div>
              <div class="home_section_six_single_box_des_date_time">
                <span> September 29, 2022</span>
              </div>
            </div>
          </div>
        </a>
        <a class="home_section_six_single_box_main">
          <div class="home_section_six_single_box_figure">
            <img src="https://static.tossdown.com/site/08fdd2ad-1a21-49e4-aa2e-40b9cd00df11.png" />
          </div>
          <div class="home_section_six_single_box">
            <h3>pakistan launches its new store in anarkali</h3>
            <div class="home_section_six_single_box_des">
              <div class="home_section_six_single_box_des_tags">
                <small>Ontario</small>
              </div>
              <div class="home_section_six_single_box_des_date_time">
                <span> September 29, 2022</span>
              </div>
            </div>
          </div>
        </a>
        <a class="home_section_six_single_box_main">
          <div class="home_section_six_single_box_figure">
            <img src="https://static.tossdown.com/site/08fdd2ad-1a21-49e4-aa2e-40b9cd00df11.png"/>
          </div>
          <div class="home_section_six_single_box">
            <h3>WATCH: ideas hottest new brunch spot is a must-try</h3>
            <div class="home_section_six_single_box_des">
              <div class="home_section_six_single_box_des_tags">
                <span>Ontario</span><small>Food</small>
              </div>
              <div class="home_section_six_single_box_des_date_time">
                <span>5 Days ago</span>
              </div>
            </div>
          </div>
        </a>
        <a class="home_section_six_single_box_main">
          <div class="home_section_six_single_box_figure">
            <img src="https://static.tossdown.com/site/08fdd2ad-1a21-49e4-aa2e-40b9cd00df11.png"/>
          </div>
          <div class="home_section_six_single_box">
            <h3>WATCH: ideas hottest new brunch spot is a must-try</h3>
            <div class="home_section_six_single_box_des">
              <div class="home_section_six_single_box_des_tags">
                <span>Ontario</span><small>Food</small>
              </div>
              <div class="home_section_six_single_box_des_date_time">
                <span>5 Days ago</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>

`
    },

    {
      id:"blog0",
      name:"Blog One",
      height:"510px",
      head:`
      
      <head>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            /> 
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
            
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
            </head>
        
            <style>
        @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        
            * {
            -web-kit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
          }
          html {
            scroll-behavior: smooth;
          }
          body {
            float: left;
            margin: 0px;
            width: 100%;
            background:#fff;
            padding:40px 0px;
          }
            </style>
  
  
      `,
  
      code:` 
      
      <script src="https://www.google.com/recaptcha/api.js"></script>
      <script>
        function NewsletteronSubmit(token) {
          $("#quick_newslatter_recaptcha").submit();
        }
      
        function validate_newslatter(event) {
          event.preventDefault();
      
          $("#quick_newslatter_recaptcha").validate({
            rules: {
              email: {
                required: true,
                email: true,
              },
            },
          });
      
          if ($("#quick_newslatter_recaptcha").valid()) {
            grecaptcha.execute();
          }
        }
      
        function newsletteronload() {
          var element = document.getElementById("newsletter-submit-recaptcha");
          element.onclick = validate_newslatter;
        }
      </script>
      <style>
        .newsletter_custom_box {
          display: flex;
          align-items: center;
          text-align: center;
          height: 100%;
          background: #000000;
          flex-direction: column;
          justify-content: center;
          padding: 45px 12px;
        }
        .newsletter_custom_box h3 {
          font-family: "Work Sans" !important;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
          padding-bottom: 24px;
        }
        .newsletter_custom_box form {
          width: 100%;
          position: relative;
        }
      
        .newsletter_form_fields_content {
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .newsletter_form_fields {
          width: 100%;
          display: grid;
          padding-bottom: 8px;
        }
        .newsletter_form_fields_content input {
          background: #ffffff;
          border-radius: 40px;
          border: unset;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #000000;
          padding: 0px 15px;
          margin-bottom: 4px !important;
          min-width: 100% !important;
          padding-bottom: 4px;
        }
      
        .newsletter_form_fields_content input:focus-visible {
          outline: none !important;
        }
        .newsletter_form_fields_content input::placeholder {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #000000;
          display: flex;
          align-items: center;
        }
        .newsletter_form_fields_content button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 100%;
          background: #e4c899;
          border-radius: 40px;
          border: unset;
          padding: 0px 10px;
        }
        .newsletter_form_fields_content button span {
          font-family: "Josefin Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          color: #000000;
        }
        .newsletter_form_fields_content button i {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          color: #000000;
          padding-left: 24px;
        }
        .newsletter_form_fields_content button:hover {
          color: #000000;
        }
        .newsletter_form_fields_content button:focus {
          outline: 0;
          box-shadow: unset !important;
        }
      
        .newsletter_form_fields_content label.error {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #cc0000;
          text-align: left;
          margin: 0px !important;
        }
      
        @media (max-width: 1024px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 540px) {
          .newsletter_custom_box h3 {
            font-size: 24px;
            line-height: 26px;
          }
        }
      </style>
      <style>
        .home_section_ten {
          display: block;
          width: 100%;
          padding: 0px 10px 0px 10px;
          float: left;
          margin-bottom: 48px;
        }
        .custom_container {
          max-width: 1140px;
          margin: 0 auto;
        }
        .home_section_ten_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-bottom: 24px;
      }
      .home_section_ten_header h3 {
          font-family: "Work Sans" !important;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 34px;
          color: #000000;
          margin: 0px;
      }
      .home_section_ten_header_btn {
          display: flex;
          align-items: center;
      }
      .home_section_ten_header_btn a {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #000000;
      }
      .home_section_ten_header_btn a i {
          padding-left: 18px;
      }
      
        .home_section_ten_box {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          width: 100%;
          grid-template-rows: auto;
          grid-template-areas: ". . . .";
          gap: 16px 16px;
        }
        .home_section_ten_single_box_main {
          display: flex;
          flex-direction: column;
        }
        .home_section_ten_single_box_figure {
          display: flex;
          width: 100%;
      }
        .home_section_ten_single_box_figure img {
          width: 100%;
          height: 150px;
          object-fit: cover;
        }
        .home_section_ten_single_box {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 16px 12px 21px 12px;
          background: #ffffff;
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
          justify-content: space-between;
        }
      
        .home_section_ten_single_box h3 {
          font-family: "Work Sans" !important;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 16px;
        }
        .home_section_ten_single_box_des {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          flex-wrap: wrap;
        }
        .home_section_ten_single_box_des_tags {
          display: flex;
          align-items: center;
        }
        .home_section_ten_single_box_des_tags span {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 12px;
          border-right: 1px solid #000000;
          color: #000000;
          padding-right: 6px;
          margin-right: 6px;
        }
        .home_section_ten_single_box_des_tags small {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 12px;
          color: #000000;
        }
        .home_section_ten_single_box_des_date_time span {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 12px;
          color: #000000;
        }
        @media (max-width: 1024px) {
          .home_section_ten_box {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas: ". . .";
          }
        }
      
        @media (max-width: 768px) {
          .home_section_ten_box {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: ". .";
            gap: 12px 12px;
          }
        }
        @media (max-width: 540px) {
          .home_section_ten {
            margin-bottom: 30px;
          }
          .home_section_ten_header {
            flex-direction: column;
          }
          .home_section_ten_header h3 {
            padding-bottom: 12px;
            font-size: 24px;
            line-height: 31px;
          }
          .home_section_ten_single_box {
            padding: 10px 8px 12px 8px;
            text-align: center;
          }
          .home_section_ten_single_box_des {
            flex-direction: column;
          }
          .home_section_ten_single_box_des_tags {
            flex-direction: column;
            border-bottom: 1px solid #000000;
            padding-bottom: 9px;
            width: 100%;
          }
          .home_section_ten_single_box_des_tags span {
            border-right: unset;
            padding-bottom: 5px;
            margin: 0px;
            padding-right: 0px;
          }
          .home_section_ten_single_box h3 {
            display: -webkit-box !important;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            width: 100% !important;
            max-width: 100% !important;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 19px;
          }
        }
      </style>
      <section class="home_section_ten">
        <div class="custom_container">
          <div class="home_section_ten_header">
              <h3>Blogs</h3>
              <div class="home_section_ten_header_btn">
                <a target="blank">REad More <i class="far fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          <div class="home_section_ten_box">
            <div class="home_section_ten_single_box_main">
              <div class="home_section_ten_single_box_figure">
                <img
                  src="https://static.tossdown.com/site/08fdd2ad-1a21-49e4-aa2e-40b9cd00df11.png"
                />
              </div>
              <div class="home_section_ten_single_box">
                <h3>
                  New restaurant’s fresh menu brings international flavour to Oakville
                </h3>
                <div class="home_section_ten_single_box_des">
                  <div class="home_section_ten_single_box_des_tags">
                    <span>Ontario</span><small>New Openings</small>
                  </div>
                  <div class="home_section_ten_single_box_des_date_time">
                    <span>5 Days ago</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="home_section_ten_single_box_main">
              <div class="home_section_ten_single_box_figure">
                <img
                  src="https://static.tossdown.com/site/08fdd2ad-1a21-49e4-aa2e-40b9cd00df11.png"
                />
              </div>
              <div class="home_section_ten_single_box">
                <h3>
                  Oakville will determine future of parkland at upcoming meeting
                </h3>
                <div class="home_section_ten_single_box_des">
                  <div class="home_section_ten_single_box_des_tags">
                    <span>Ontario</span><small>Latest News</small>
                  </div>
                  <div class="home_section_ten_single_box_des_date_time">
                    <span>5 Days ago</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="home_section_ten_single_box_main">
              <div class="home_section_ten_single_box_figure">
                <img
                  src="https://static.tossdown.com/site/554500bd-2182-488a-9fd3-c13bf62c47c9.png"
                />
              </div>
              <div class="home_section_ten_single_box">
                <h3>WATCH: Mississauga’s hottest new brunch spot is a must-try</h3>
                <div class="home_section_ten_single_box_des">
                  <div class="home_section_ten_single_box_des_tags">
                    <span>Ontario</span><small>Food</small>
                  </div>
                  <div class="home_section_ten_single_box_des_date_time">
                    <span>5 Days ago</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="home_section_ten_single_box_main">
              <div class="newsletter_custom_box">
                <h3>Subscribe to our newsletter</h3>
                <form
                  method="POST"
                  action="/website/insert_signup_newsletter"
                  id="quick_newslatter_recaptcha"
                  novalidate="novalidate"
                >
                  <div class="newsletter_form_fields_content">
                    <div class="newsletter_form_fields">
                      <input
                        id="get-user-subscribe-email"
                        type="text"
                        name="email"
                        placeholder="Email"
                      />
                      <label
                        for="get-user-subscribe-email"
                        generated="true"
                        class="error"
                      ></label>
                    </div>
                    <!-- <input type="hidden" name="contact_us_id" value="1" />  -->
                    <!-- <?php
                    echo '<div id="recaptcha" class="g-recaptcha"
                              data-sitekey="'.$recaptcha_site_key.'"
                              data-callback="NewsletteronSubmit"
                              data-size="invisible">';
                     echo '</div>';
                    ?>  -->
                    <button
                      id="newsletter-submit-recaptcha"
                      type="submit"
                      class="submitted btn"
                    >
                      <span class="newsletter_submit_btn_text"
                        >Subscribe
                        <i class="far fa-arrow-right" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </form>
                <!-- <script>
                  newsletteronload();
                </script> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      
  
  `
      },
  

    ]
    export default BlogSectionObjects