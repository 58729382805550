const TestimonialObjects=[
    {
    id:"Testimonial0",
    name:"Testimonial 0ne",
    height:"500px",

    head:`
    <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    /> 
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
    
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
  <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
  <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
  
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
  <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
    </head>
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');

    * {
    -web-kit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    float: left;
    margin: 0px;
    width: 100%;
    background:#fff;
    padding:40px 0px;
  }
    </style>
    
   `
    ,
    code:` 
   
   
    <style>
  
    .testimonial_section {
       display: block;
       width: 100%;
       padding:70px 15px 70px 15px;
       margin-bottom: 80px;
       float: left;
      background: #D8EAFF;
     }
     .custom_container {
       max-width: 1140px;
       margin: 0px auto;
     }
     .testimonial_heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom:32px;
    }
    .testimonial_heading h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #2D2A38;
    margin-bottom: 0px;
    text-transform: capitalize;
    position: relative;
    }
    .testimonial_heading a{
     font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #2D2A38;
    
    }
    
    .testimonial_main {
    float:left;
    width:100%;
    }
    
    .testimonial_slider_arrow_box {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    
    width: 100%;
    }
    .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:99;
    }
    .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow i{
    color:#000;
    }
    
    
     .testimonial_list {
       float: left;
       width:100%;
       padding: 0px 50px;
       position: relative;
     }
     .testimonial_list ul {
       margin: 0px -15px;
       padding: 0px;
     }
     .testimonial_list ul li {
       list-style: none;
       width: 25%;
       padding: 15px 15px 15px 15px;
       float: left;
         margin: 0px !important;
     }
    .testimonial_data_box {
       width: 100%;
    display: flex;
    background: #FFFFFF;
    border-radius: 2px;
    flex-direction: column;
    padding: 40px 34px 40px 16px;
    }
     .testimonial_figure_box {
       width: 100%;
       display: flex;
    padding-bottom: 22px;
     }
     .testimonial_figure_box figure {
       margin-bottom: 0px;
    margin-right: 12px;
    width: 36px;
    height: 36px;
     }
    
     .testimonial_figure_box figure img {
    width:100%;
    height:100%;
    object-fit: cover;
     }
    .testimonial_reviewer_data {
    display: flex;
    flex-direction: column;
    }
    .testimonial_reviewer_data h4 {
     font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #03242D;
    margin: 0px;
    padding-bottom: 4px;
    }
    .testimonial_rating_stars {
    display: flex;
    align-items: center;
    }
    .testimonial_rating_stars i {
    color: #A5170D;
    padding-right: 4px;
    font-size: 14px;
    }
    .testimonial_rating_stars i:last-child{
    padding-right: 0px;
    }
    .testimonial_description {
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    }
    .testimonial_description p{
     font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #03242D;
    }
    .testimonial_date_field span {
     font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    }


    .testimonial_list ul.slick-dots {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0px !important;
        bottom: -30px;
    }
    .testimonial_list ul.slick-dots li {
        width: unset !important;
        height: unset !important;
        padding:0px 5px;  
    }
    .testimonial_list ul.slick-dots li:last-child{
padding-right:0px;
}
    
.testimonial_list .slick-dots li button {
        width: 10px !important;
        height: 10px !important;
        padding: 5px;
        cursor: pointer;
        color: white;
        border: 0;
        outline: none;
    background: #fff !important;
        border-radius: 50% !important;
    border: 1px solid #fff !important;
    position:relative;
    color: transparent;
    }
    .testimonial_list .slick-dots li.slick-active button {
        background:#B1382E !important;
    border: 1px solid #B1382E !important;
    }
    .testimonial_list .slick-dots li button:before{
        display: none !important;
    }

    @media (max-width: 1024px) {
    .testimonial_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0px 0px;
    
    }
    .testimonial_list {
    width:100%;
    padding:0px 30px;
    }
    .testimonial_list ul {
    margin: 0px -10px;
    }
    .testimonial_list ul li {
    padding: 15px 10px 15px 10px;
    }
    .testimonial_data_box {
    padding: 30px 20px 30px 16px;
    }
    .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow {
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    }
    
    
    }
     @media (max-width: 540px) {
    .testimonial_heading {
        flex-direction: column;
    }
    .testimonial_heading h3 {
        font-size: 24px;
        padding-bottom: 15px;
    }
     }
    
    </style>
    <section class="testimonial_section">
    <div class="custom_container">
    <div class="testimonial_heading">
    <h3>What Customers Say?</h3>
    <a>All Reviews</a></div>
    <div class="testimonial_main">
    <div class="testimonial_list">
    <div class="testimonial_slider_arrow_box"></div>
    <ul class="testimonial_slider">
    <li>
    <div class="testimonial_data_box">
    <div class="testimonial_figure_box">
    <figure><img class="lazy" src="https://static.tossdown.com/images/092cfb57-6805-46d6-a38c-7e5918039120.png" /></figure>
    <div class="testimonial_reviewer_data">
    <h4>Jessica Martin</h4>
    <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
    </div>
    </div>
    <div class="testimonial_description">
    <p>Lorem ipsum dolor sit amet, consectetur adipis -cing elit. Sed eget gravida magna. Etiam lacinia vel odio quis sagittis.</p>
    </div>
    <div class="testimonial_date_field"><span>4th August,2022</span></div>
    </div>
    </li>
    <li>
    <div class="testimonial_data_box">
    <div class="testimonial_figure_box">
    <figure><img class="lazy" src="https://static.tossdown.com/images/1f828e5f-5406-4b26-97f5-d018200df8db.png" /></figure>
    <div class="testimonial_reviewer_data">
    <h4>Kylie Jenner</h4>
    <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
    </div>
    </div>
    <div class="testimonial_description">
    <p>Lorem ipsum dolor sit amet, consectetur adipis -cing elit. Sed eget gravida magna. Etiam lacinia vel odio quis sagittis.</p>
    </div>
    <div class="testimonial_date_field"><span>4th August,2022</span></div>
    </div>
    </li>
    <li>
    <div class="testimonial_data_box">
    <div class="testimonial_figure_box">
    <figure><img class="lazy" src="https://static.tossdown.com/images/1a548b8a-e910-47a8-8635-eec52607e9ff.png" /></figure>
    <div class="testimonial_reviewer_data">
    <h4>Jessica Martin</h4>
    <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
    </div>
    </div>
    <div class="testimonial_description">
    <p>Lorem ipsum dolor sit amet, consectetur adipis -cing elit. Sed eget gravida magna. Etiam lacinia vel odio quis sagittis.</p>
    </div>
    <div class="testimonial_date_field"><span>4th August,2022</span></div>
    </div>
    </li>
    <li>
    <div class="testimonial_data_box">
    <div class="testimonial_figure_box">
    <figure><img class="lazy" src="https://static.tossdown.com/images/1a548b8a-e910-47a8-8635-eec52607e9ff.png" /></figure>
    <div class="testimonial_reviewer_data">
    <h4>Jessica Martin</h4>
    <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
    </div>
    </div>
    <div class="testimonial_description">
    <p>Lorem ipsum dolor sit amet, consectetur adipis -cing elit. Sed eget gravida magna. Etiam lacinia vel odio quis sagittis.</p>
    </div>
    <div class="testimonial_date_field"><span>4th August,2022</span></div>
    </div>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </section>
    <script>
     $(document).ready(function () {
       $(".testimonial_slider").slick({
         slidesToShow:3,
         slidesToScroll: 1,
         autoplay: false,
         autoplaySpeed: 2000,
         dots: true,
        arrows: true,
    appendArrows: $('.testimonial_slider_arrow_box'),
    nextArrow: '<a class="testimonial_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
    prevArrow: '<a class="testimonial_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
         responsive: [
           {
             breakpoint: 1024,
             settings: {
               slidesToShow:3,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 1500,
               dots: false,
               arrows: true,
             },
           },
           {
             breakpoint: 768,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 1500,
                    dots: false,
                 arrows: true,
             },
           },
           {
             breakpoint: 540,
             settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 1500,
                   dots: false,
               arrows: true,
             },
           },
           {
             breakpoint: 480,
             settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               autoplay: false,
             autoplaySpeed: 1500,
                    dots: false,
                arrows: true,
             },
           },
         ],
       });
     });
    
    
    </script>

`
    },

    {
      id:"Testimonial1",
      name:"Testimonial Two",
      height:"500px",

      head:`

      <head>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      /> 
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
      
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
    <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
    <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
    
    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
      </head>
      <style>
      @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');

      * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      margin: 0px;
      width: 100%;
      background:#fff;
      padding:40px 0px;
    }
      </style>
      
     `
      ,

      code:` 
      
    
      <style>
      
      .review_section_one .review-main-heading-section h2 {
          color: #fff;
      }
      .review_section_one .review-main-heading-section span {
          color: #fff;
      }
      .review_section_one {
          background: #029193;
          padding: 72px 0px;
      }
      .review-main-heading-section h2 {
          font-style: normal;
          font-weight: 500 !important;
          font-size: 44px;
          line-height: 44px;
          text-align: center;
          color: #FFFDFD;
      }
      .review-main-heading-section span {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          text-transform: uppercase;
          color: #FFFDFD;
          margin-bottom: 12px;
          display: block;
      font-family: 'fsmeridianreg';
      }
      .review-main-heading-section {
          text-align: center;
      }
      .starts-content {
          display: flex;
          justify-content: center;
          margin-bottom: 12px;
      }
      .starts-content span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          text-align: left;
          color: #FFFFFF;
      }
      .starts-content .star {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          text-align: left;
          color: #FFFFFF;
          margin-left: 10px;
      }
      .reviews-cards-one .content span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
          color: #FFFFFF;
          margin-bottom: 16px;
          display: block;
      }
      .reviews-cards-one .content p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 23px;
          text-align: center;
          color: #FFFFFF;
          font-family: 'fsmeridianreg';
          margin: 0px 30px;
      }
      .reviews-cards-one.multi-reviews {
          text-align: center;
      }
      .rating-grade span {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          text-align: center;
          color: #FFFFFF;
          margin-bottom: 8px;
      }
      .reviews-cards-one.multi-reviews .rating-grade {
          margin-bottom: 12px;
      }
      .total-review {
          margin-bottom: 12px;
      }
      .total-ave span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #FFFFFF;
      }
      .total-review span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #FFFFFF;
      }
      .total-review {
          margin-bottom: 12px;
      }
      .border-right-side {
          border-right: 1px solid rgba(255, 255, 255, 0.16);
      }
      .rating-grade .star span {
          margin-top: 8px;
          color: #F37321;
          font-size: 14px;
          font-weight: 600;
      }
      .all-view-btn-holds {
          text-align: center;
          margin-top: 65px;
      }
      .all-view-btn-holds a:hover {
          background-color: #9f0028;
          border: 1px solid #9f0028;
      }
      .all-view-btn-holds a {
          width: 155px;
          height: 42px;
          box-shadow: 0px 6px 24px rgb(0 0 0 / 8%);
          border-radius: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
         font-family: 'fsmeridianreg';
          border: 1px solid #FFFFFF;
          color: #fff !important;
      }
      </style>
      <div class="review_section_one">
      <div class="container">
      <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="review-main-heading-section"><span>Lorem Ipsum</span>
      <h2>Reviews</h2>
      </div>
      </div>
      </div>
      <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 border-right-side">
      <div class="reviews-cards-one multi-reviews">
      <div class="rating-grade"><span>Excellent</span>
      <div class="star"><span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span></div>
      </div>
      <div class="total-ave"><span>5.00 Average</span></div>
      <div class="total-review"><span>10 reviews</span></div>
      <div class="total-review"><img src="https://tossdown.site/images/03593ea92582b14e4b6e675b2159d4f2_1658835974.png" /></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 border-right-side">
      <div class="reviews-cards-one">
      <div class="starts-content"><span>Anonymous</span>
      <div class="star"><span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span></div>
      </div>
      <div class="content"><span><img src="https://tossdown.site/images/74c75e5d58196f7eead5b4c70134f1a1_1658836668.png" /> Verified customer</span>
      <p>Very reasonable price, and look great. Deli -very was fast, and the delivery men were fantastic. They were careful with the mirror doors and took the time to check them before they left.</p>
      </div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="reviews-cards-one ">
      <div class="starts-content"><span>Rameel</span>
      <div class="star"><span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span></div>
      </div>
      <div class="content"><span><img src="https://tossdown.site/images/74c75e5d58196f7eead5b4c70134f1a1_1658836668.png" /> Verified customer</span>
      <p>I was very dubious ordering this wardrobe. The price seemed too good to be true. After getting a quote from Slide robes for over £1000 (having had their wardrobes in the past) I decided</p>
      </div>
      </div>
      </div>
      <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="all-view-btn-holds"><a>VIEW ALL</a></div>
      </div> --></div>
      </div>
      </div>
  
  `
      },

      {
        id:"Testimonial2",
        name:"Testimonial Three",
        height:"500px",
        head:`

        <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        /> 
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
        
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
      <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
      <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
      
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
      <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
        </head>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
  
        * {
        -web-kit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
      }
      html {
        scroll-behavior: smooth;
      }
      body {
        float: left;
        margin: 0px;
        width: 100%;
        background:#fff;
        padding:40px 0px;
      }
        </style>
        
       `
        ,

        code:` 
        <style>
       .all-view-btn-holds.review a:hover {
           background-color: #9f0028;
           border: 1px solid #9f0028 !important;
           color: #fff !important;
       }
       .all-view-btn-holds.review a {
           color: #052C25 !important;
           border: 1px solid #052C25 !important;
       }
       .all-view-btn-holds.review {
           margin-top: 30px !important;
       }
       .review_section_second {
           padding: 72px 0px;
       }
       .review-main-heading-section {
           text-align: center;
           margin-bottom: 32px;
           display: block;
       }
       .review-main-heading-section span {
           font-style: normal;
           font-weight: 400;
           font-size: 16px;
           line-height: 16px;
           text-transform: uppercase;
           color: #212121;
           font-family: 'Montserrat';
       }
       .review-main-heading-section h2 {
           font-style: normal;
           font-weight: 500;
           font-size: 44px;
           line-height: 44px;
           text-align: center;
           color: #212121;
           font-family: 'Montserrat';
       }
       .customer-info {
           display: flex;
           margin-bottom: 16px;
       }
       .customer-info .user-details {
           margin-left: 14px;
       }
       .customer-review-cards .user-details span {
           font-style: normal;
           font-weight: 500;
           font-size: 15px;
           line-height: 15px;
           text-align: center;
           color: #212121;
           font-family: 'Montserrat';
       }
       .customer-review-cards .user-details p {
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 14px;
           color: #9FA19D;
           font-family: 'Montserrat';
       }
       .customer-review-cards {
           background: #FFFFFF;
           padding: 35px 35px 35px 20px;
           margin-bottom: 30px;
       
       }
       .customer-review-cards .customer-more-details .star span {
           margin-top: 8px;
           color: #F37321;
           font-size: 14px;
           margin-bottom: 12px;
       }
       .customer-review-cards .customer-more-details p {
           font-style: normal;
           font-weight: 500;
           font-size: 14px;
           line-height: 21px;
           color: #464646;
       }
       
       </style>
       <div class="review_section_second">
       <div class="container">
       <div class="row">
       <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
       <div class="review-main-heading-section"><span>Lorem Ipsum</span>
       <h2>Happy customer reviews</h2>
       </div>
       </div>
       </div>
       <div class="row">
       <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
       <div class="customer-review-cards">
       <div class="customer-info">
       <div class="pofile-img"><img src="https://tossdown.site/images/84d97606306488b06cb3c80055588fe7_1658839735.png" /></div>
       <div class="user-details"><span>Food valley</span>
       <p>8 June, 2022</p>
       </div>
       </div>
       <div class="customer-more-details">
       <div class="star"><span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span></div>
       <p>Very reasonable price, and look great. Deli -very was fast, and the delivery men were fantastic. They were careful with the mirror doors and took the time to check them before they left.</p>
       </div>
       </div>
       </div>
       <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
       <div class="customer-review-cards">
       <div class="customer-info">
       <div class="pofile-img"><img src="https://tossdown.site/images/feac4c10b138ff067740ff713a924486_1658839269.png" /></div>
       <div class="user-details"><span>Umair Rajput</span>
       <p>8 June, 2022</p>
       </div>
       </div>
       <div class="customer-more-details">
       <div class="star"><span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span></div>
       <p>Very reasonable price, and look great. Deli -very was fast, and the delivery men were fantastic. They were careful with the mirror doors and took the time to check them before they left.</p>
       </div>
       </div>
       </div>
       <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
       <div class="customer-review-cards">
       <div class="customer-info">
       <div class="pofile-img"><img src="https://tossdown.site/images/d0b4e9c19f22d07ea24c911c78b1a974_1658839287.png" /></div>
       <div class="user-details"><span>Connor davies</span>
       <p>8 June, 2022</p>
       </div>
       </div>
       <div class="customer-more-details">
       <div class="star"><span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span></div>
       <p>Very reasonable price, and look great. Deli -very was fast, and the delivery men were fantastic. They were careful with the mirror doors and took the time to check them before they left.</p>
       </div>
       </div>
       </div>
       <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
       <div class="customer-review-cards">
       <div class="customer-info">
       <div class="pofile-img"><img src="https://tossdown.site/images/84d97606306488b06cb3c80055588fe7_1658839735.png" /></div>
       <div class="user-details"><span>Food valley</span>
       <p>8 June, 2022</p>
       </div>
       </div>
       <div class="customer-more-details">
       <div class="star"><span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span></div>
       <p>Very reasonable price, and look great. Deli -very was fast, and the delivery men were fantastic. They were careful with the mirror doors and took the time to check them before they left.</p>
       </div>
       </div>
       </div>
       <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
       <div class="customer-review-cards">
       <div class="customer-info">
       <div class="pofile-img"><img src="https://tossdown.site/images/feac4c10b138ff067740ff713a924486_1658839269.png" /></div>
       <div class="user-details"><span>Umair Rajput</span>
       <p>8 June, 2022</p>
       </div>
       </div>
       <div class="customer-more-details">
       <div class="star"><span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span></div>
       <p>Very reasonable price, and look great. Deli -very was fast, and the delivery men were fantastic. They were careful with the mirror doors and took the time to check them before they left.</p>
       </div>
       </div>
       </div>
       <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
       <div class="customer-review-cards">
       <div class="customer-info">
       <div class="pofile-img"><img src="https://tossdown.site/images/d0b4e9c19f22d07ea24c911c78b1a974_1658839287.png" /></div>
       <div class="user-details"><span>Connor davies</span>
       <p>8 June, 2022</p>
       </div>
       </div>
       <div class="customer-more-details">
       <div class="star"><span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span> <span class="fa fa-star" aria-hidden="true"></span></div>
       <p>Very reasonable price, and look great. Deli -very was fast, and the delivery men were fantastic. They were careful with the mirror doors and took the time to check them before they left.</p>
       </div>
       </div>
       </div>
       <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
   
       </div>
       </div>
       </div>
       </div>
    
    `
        },

        {
          id:"Testimonial3",
          name:"Testimonial Four",
          height:"500px",

          head:`

          <head>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          /> 
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
          
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
        <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
        <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
        
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
          </head>
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Prata&display=swap');
    
          * {
          -web-kit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
        }
        html {
          scroll-behavior: smooth;
        }
        body {
          float: left;
          margin: 0px;
          width: 100%;
          background:#fff;
          padding:40px 0px;
        }
          </style>
          
         `
          ,

          code:` 

      
          <style>
        
           .testimonial_section {
              display: block;
              width: 100%;
              margin-bottom: 70px;
              float: left;
       padding:0px 15px;
            }
            .custom_container {
              max-width: 1140px;
              margin: 0px auto;
            }
            .testimonial_heading {
           display: flex;
           width: 100%;
           align-items: center;
           justify-content: space-between;
           padding-bottom:13px;
           }
           .testimonial_heading h3 {
        font-family: 'Montserrat';
       font-style: normal;
       font-weight: 700;
       font-size: 32px;
       line-height: 48px;
       color: #25292D;
           position: relative;
           }
           .testimonial_heading a{
            font-family: 'Poppins', sans-serif;
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 17px;
           text-decoration-line: underline;
           color: #2D2A38;
           
           }
           
           .testimonial_main {
           float:left;
           padding-bottom: 87px;
           width: 100%;
           border-bottom: 1px solid rgba(0, 0, 0, 0.1);
           }
           
           .testimonial_slider_arrow_box {
       display: flex;
           align-items: center;
           }
           .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow {
           width: 44px;
           height: 44px;
           display: flex;
           align-items: center;
           justify-content: center;
           z-index:99;
       background: #FFFFFF;
           box-shadow: 0px 4px 48px rgb(0 0 0 / 6%);
           border-radius: 12px;
       margin-right:8px;
           text-decoration: none !important;
           }
          .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow:hover{
       background: #FBF7F4;
       }
           .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow:last-child{
       margin-right:0px;
       }
           .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow i{
           color:#000;
           }
           
           
            .testimonial_list {
              float: left;
              width:100%;
              position: relative;
            }
            .testimonial_list ul {
              margin: 0px -15px;
              padding: 0px;
       
            }
            .testimonial_list ul li {
              list-style: none;
           width: 25%;
                  height: 100%;
            padding: 32px 15px 45px 15px;
              float: left;
                margin: 0px !important;
            }
           .testimonial_data_box {
              width: 100%;
           display: flex;
           background: #FFFFFF;
           border-radius: 2px;
           flex-direction: column;
             padding: 31px 23px 40px 35px;
           background: #FFFFFF;
           box-shadow: 0px 4px 48px rgb(0 0 0 / 6%);
           border-radius: 20px;
           height: 100%;
           }
            .testimonial_figure_box {
                width: 100%;
           align-items: center;
           display: flex;
           padding-bottom: 23px;
            }
            .testimonial_figure_box figure {
              margin-bottom: 0px;
           margin-right: 13px;
           width: 52px;
           height: 52px;
            }
           
            .testimonial_figure_box figure img {
           width:100%;
           height:100%;
           object-fit: cover;
       margin: 0px !important;
            }
           .testimonial_reviewer_data {
           display: flex;
           flex-direction: column;
           }
           .testimonial_reviewer_data h4 {
         font-family: 'Montserrat';
       font-style: normal;
       font-weight: 600;
       font-size: 15px;
       line-height: 18px;
       color: #25292D;
           margin: 0px;
           padding-bottom: 6px;
           }
         .testimonial_reviewer_data small {
       font-family: 'Mulish';
       font-style: normal;
       font-weight: 600;
       font-size: 12px;
       line-height: 15px;
       color: #898989;
       }
           .testimonial_rating_stars {
           display: flex;
           align-items: center;
       padding-bottom:7px;
           }
           .testimonial_rating_stars i {
             color: #E51451;
           padding-right: 2px;
           font-size: 10px;
           }
           .testimonial_rating_stars i:last-child{
           padding-right: 0px;
           }
           .testimonial_description {
           padding-bottom: 29px;
           display: flex;
           flex-direction: column;
           width: 100%;
           }
           .testimonial_description p{
        font-family: 'Mulish';
       font-style: normal;
       font-weight: 400;
       font-size: 14px;
       line-height: 22px;
       color: #25292D;
       
           }
       .testimonial_detail_fields {
           display: flex;
           justify-content: space-between;
           align-items: center;
           width: 100%;
       }
       .testimonial_detail_fields a {
           border: 1px solid #E51451;
           border-radius: 12px;
           font-family: 'Montserrat';
           font-style: normal;
           font-weight: 600;
           font-size: 12px;
           line-height: 15px;
           color: #E51451;
           padding: 9px 12px;
       }
       .testimonial_detail_fields a i {
           padding-right: 6px;
           color: #E51451;
           font-size: 13px;
           line-height: 15px;
           font-weight: 600;
       }
           .testimonial_date_field span {
          font-family: 'Mulish';
       font-style: normal;
       font-weight: 600;
       font-size: 13px;
       line-height: 16px;
       color: #898989;
           }
       
       
           .testimonial_list ul.slick-dots {
               display: flex;
               width: 100%;
               justify-content: center;
               align-items: center;
               margin: 0px !important;
                 bottom: -40px;
           position: absolute;
           }
           .testimonial_list ul.slick-dots li {
               width: unset !important;
               height: unset !important;
               padding:0px 5px;  
           }
           .testimonial_list ul.slick-dots li:last-child{
       padding-right:0px;
       }
           
       .testimonial_list .slick-dots li button {
             width: 10px !important;
           height: 10px !important;
           cursor: pointer;
           outline: none;
           background: transparent !important;
           border-radius: 50% !important;
           border: 2px solid #25292D !important;
           position: relative;
           color: transparent;
           }
           .testimonial_list .slick-dots li.slick-active button {
               background:#25292D !important;
           border: 1px solid #25292D !important;
           }
           .testimonial_list .slick-dots li button:before{
               display: none !important;
           }
       
           @media (max-width: 1024px) {
           .testimonial_main {
           display: flex;
           flex-direction: column;
           justify-content: center;
           width: 100%;
           margin: 0px 0px;
           
           }
       
           .testimonial_slider_arrow_box  a.testimonial_pre_arrow.slick-arrow {
           background: #FFFFFF;
           box-sizing: border-box;
           box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
           border-radius: 2px;
           }
       .testimonial_detail_fields {
           align-items: flex-start;
           flex-direction: column;
       }
          .testimonial_detail_fields a {
           margin-bottom: 10px;
       }
       .testimonial_heading {
               flex-direction: column;
           }
           .testimonial_heading h3 {
               padding-bottom: 15px;
           text-align: center;
           }  
       
           }
            @media (max-width: 768px) {
           .testimonial_heading h3 {
           font-size: 24px;
           line-height: 28px;
           }   
       .testimonial_list ul li {
           padding: 32px 8px 45px 8px;
       }
       .testimonial_list ul {
           margin: 0px -8px;
       }
       .testimonial_data_box {
           padding: 31px 23px 40px 25px;
       }
           
            }
           
           </style>
       <section class="testimonial_section">
       <div class="custom_container">
       <div class="testimonial_heading">
       <h3>What Food Brands are Saying About Us</h3>
       <div class="testimonial_slider_arrow_box"></div>
       </div>
       <div class="testimonial_main">
       <div class="testimonial_list">
       <ul class="testimonial_slider">
       <li>
       <div class="testimonial_data_box">
       <div class="testimonial_figure_box">
       <figure><img class="lazy" src="https://static.tossdown.com/images/092cfb57-6805-46d6-a38c-7e5918039120.png" /></figure>
       <div class="testimonial_reviewer_data">
       <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
       <h4>Jane Cooper</h4>
       <small>The Big Apple</small></div>
       </div>
       <div class="testimonial_description">
       <p>Taking our business online always seemed like a daunting task. tossdown helped us ease into the digital world of eCommerce and we haven’t looked back since! tossdown developed our e-commerce website and mobile apps. The team is very professional and they have helped us every step of the way, from training and innovation to exceptional support.</p>
       </div>
       <div class="testimonial_detail_fields"><a><i class="fal fa-external-link"></i> Visit Website</a>
       <div class="testimonial_date_field"><span>8/22/2022</span></div>
       </div>
       </div>
       </li>
       <li>
       <div class="testimonial_data_box">
       <div class="testimonial_figure_box">
       <figure><img class="lazy" src="https://static.tossdown.com/images/1f828e5f-5406-4b26-97f5-d018200df8db.png" /></figure>
       <div class="testimonial_reviewer_data">
       <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
       <h4>Guy Hawkins</h4>
       <small>Naan Guys</small></div>
       </div>
       <div class="testimonial_description">
       <p>Our experience with tossdown has been pleasant and effortless! Our restaurant is powered by tossdown’s platform which includes online ordering, mobile apps, and a point-of-sale system. Their team is quick to respond to calls and support queries. They also manage our digital marketing channels to ensure continued success for our business.</p>
       </div>
       <div class="testimonial_detail_fields"><a><i class="fal fa-external-link"></i> Visit Website</a>
       <div class="testimonial_date_field"><span>8/22/2022</span></div>
       </div>
       </div>
       </li>
       <li>
       <div class="testimonial_data_box">
       <div class="testimonial_figure_box">
       <figure><img class="lazy" src="https://static.tossdown.com/images/1a548b8a-e910-47a8-8635-eec52607e9ff.png" /></figure>
       <div class="testimonial_reviewer_data">
       <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
       <h4>Jacob Jones</h4>
       <small>Tez</small></div>
       </div>
       <div class="testimonial_description">
       <p>We're really happy with tossdown. The team has a knack for innovation and is extremely responsive. They have good expertise in the food industry which has been very useful in solving crucial problems for our business. Our warehouse-to-door delivery business completely runs on this well-supported technology platform.</p>
       </div>
       <div class="testimonial_detail_fields"><a><i class="fal fa-external-link"></i> Visit Website</a>
       <div class="testimonial_date_field"><span>8/22/2022</span></div>
       </div>
       </div>
       </li>
       <li>
       <div class="testimonial_data_box">
       <div class="testimonial_figure_box">
       <figure><img class="lazy" src="https://static.tossdown.com/images/1a548b8a-e910-47a8-8635-eec52607e9ff.png" /></figure>
       <div class="testimonial_reviewer_data">
       <div class="testimonial_rating_stars"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fad fa-star-half-alt"></i></div>
       <h4>Jacob Jones</h4>
       <small>Tez</small></div>
       </div>
       <div class="testimonial_description">
       <p>We're really happy with tossdown. The team has a knack for innovation and is extremely responsive. They have good expertise in the food industry which has been very useful in solving crucial problems for our business. Our warehouse-to-door delivery business completely runs on this well-supported technology platform.</p>
       </div>
       <div class="testimonial_detail_fields"><a><i class="fal fa-external-link"></i> Visit Website</a>
       <div class="testimonial_date_field"><span>8/22/2022</span></div>
       </div>
       </div>
       </li>
       </ul>
       </div>
       </div>
       </div>
       </section>
       
       <script>
           $(document).ready(function () {
             $(".testimonial_slider").slick({
               slidesToShow:3,
               slidesToScroll: 1,
               autoplay: false,
               autoplaySpeed: 2000,
               dots: true,
              arrows: true,
          appendArrows: $('.testimonial_slider_arrow_box'),
          nextArrow: '<a class="testimonial_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
          prevArrow: '<a class="testimonial_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
               responsive: [
                 {
                   breakpoint: 1024,
                   settings: {
                     slidesToShow:2,
                     slidesToScroll: 1,
                     autoplay: false,
                     autoplaySpeed: 1500,
                    dots: true,
                     arrows: true,
                   },
                 },
                 {
                   breakpoint: 768,
                   settings: {
                     slidesToShow: 2,
                     slidesToScroll: 1,
                     autoplay: false,
                     autoplaySpeed: 1500,
                           dots: true,
                       arrows: true,
                   },
                 },
                 {
                   breakpoint: 540,
                   settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1,
                     autoplay: false,
                     autoplaySpeed: 1500,
                         dots: true,
                     arrows: true,
                   },
                 },
                 {
                   breakpoint: 480,
                   settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1,
                     autoplay: false,
                   autoplaySpeed: 1500,
                     dots: true,
                      arrows: true,
                   },
                 },
               ],
             });
           });
          </script>
      
      `
          },
          {
            id:"Testimonial4",
            name:"Testimonial Five",
            height:"500px",
            head:`

            <head>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            /> 
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
            <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
            
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
          <script type="text/javascript" src="//code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
          <script type="text/javascript" src="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.min.js"></script>
          
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"/>
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.5.9/slick-theme.css"/> 
            </head>
        
            <style>
        @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        
            * {
            -web-kit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
          }
          html {
            scroll-behavior: smooth;
          }
          body {
            float: left;
            margin: 0px;
            width: 100%;
            background:#fff;
            padding:40px 0px;
          }
            </style>
            
           `
            ,

            code:` 
            <style>
  .testimonial_section {
    display: block;
    width: 100%;
    padding: 45px 10px 45px 10px;
    margin-bottom: 44px;
    float: left;
    background: #f7f7f5;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .custom_container {
    max-width: 1140px;
    margin: 0px auto;
  }

  .testimonial_main {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .testimonial_custom_sliding_box {
    float: left;
    width: 25%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .testimonial_custom_sliding_box small {
    padding-bottom: 16px;
  }
  .testimonial_custom_sliding_box h5 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    padding-bottom: 16px;
    margin: 0px;
  }
  .testimonial_custom_sliding_box p {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 24px;
    margin: 0px;
  }
  .testimonial_custom_sliding_box_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
  }
  .testimonial_custom_sliding_box_btn a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 36px;
    background: #eae5db;
    border-radius: 56px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #000000;
    text-decoration: none;
  }
  .testimonial_arrow_box_main {
    display: flex;
    justify-content: center;
  }
  .testimonial_arrow_box {
    display: flex;
  }
  .testimonial_arrow_box a.category_pre_arrow.slick-arrow {
    justify-content: center;
    background: #eae5db;
    display: flex;
  }
  .testimonial_arrow_box a.category_pre_arrow.slick-arrow:first-child {
    border-top-left-radius: 56px;
    border-bottom-left-radius: 56px;
    padding: 12px 8px 12px 16px;
  }
  .testimonial_arrow_box a.category_pre_arrow.slick-arrow:last-child {
    border-top-right-radius: 56px;
    border-bottom-right-radius: 56px;
    padding: 12px 16px 12px 8px;
  }
  .testimonial_arrow_box a.category_pre_arrow.slick-arrow i {
    font-size: 14px;
  }
  .testimonial_main_list {
    float: left;
    width: 75%;
    padding: 0px 20px;
  }
  .testimonial_main_list ul {
    margin: 0px -16px;
    padding: 0px;
  }
  .testimonial_main_list ul li {
    list-style: none;
    width: 33.333%;
    padding: 0px 16px 0px 16px;
    float: left;
    margin: 0px !important;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
  }

  .testimonial_main_figure_box {
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
  }
  .testimonial_main_figure_box figure {
    margin-bottom: 0px;
  }
  .testimonial_main_figure_box figure a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .testimonial_main_figure_box figure a img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  .testimonial_main_figure_tags {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 15px 15px 24px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .testimonial_rating_stars {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    justify-content: center;
  }
  .testimonial_rating_stars i {
    color: #e4c899;
    padding-right: 5px;
    font-size: 14px;
  }
  .testimonial_rating_stars i:last-child {
    padding-right: 0px;
  }
  .testimonial_main_figure_tags h5 {
    font-family: "Work Sans" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 8px;
    margin: 0px;
  }
  .testimonial_main_figure_tags p {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    padding-bottom: 12px;
    margin: 0px;
  }
  .testimonial_main_figure_tags small {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin: 0px;
    padding-bottom: 17px;
  }
  .testimonial_main_figure_tags_social_btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testimonial_main_figure_tags_social_btn a {
    padding-right: 13px;
    font-size: 16px;
    color: #000;
  }
  .testimonial_main_figure_tags_social_btn a:last-child {
    padding-right: 0px;
  }
  .testimonial_main_figure_tags_social_btn a i {
    font-size: 16px;
    color: #000;
  }
  @media (max-width: 1024px) {
    .testimonial_main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin: 0px 0px;
    }
    .testimonial_custom_sliding_box {
      margin-bottom: 30px;
      padding: 0px;
      width: 100%;
    }
    .testimonial_main_list {
      width: 100%;
      padding: 0px 10px;
    }
  }
  @media (max-width: 768px) {
    .testimonial_main_figure_tags {
      text-align: center;
    }
  }
  @media (max-width: 540px) {
    .testimonial_custom_sliding_box h5 {
      font-size: 24px;
      line-height: 31px;
      padding-bottom: 8px;
    }
    .testimonial_custom_sliding_box p {
      padding-bottom: 16px;
    }
    .testimonial_custom_sliding_box {
      margin-bottom: 16px;
    }
    .testimonial_section {
      margin-bottom: 30px;
      padding: 30px 10px;
    }
  }
</style>
<section class="testimonial_section">
  <div class="custom_container">
    <div class="testimonial_main">
      <div class="testimonial_custom_sliding_box">
        <small>
            <img src="https://static.tossdown.com/site/518df6fb-dae5-4da4-819d-d4c3b0ef150c.png"/></small>
        <h5>Most Loved</h5>
        <p>Community favorites with reviews you can trust.</p>
        <div class="testimonial_custom_sliding_box_btn"><a>All Reviews</a></div>
        <div class="testimonial_arrow_box_main">
          <div class="testimonial_arrow_box"></div>
        </div>
      </div>
      <div class="testimonial_main_list">
        <ul class="testimonial_main_slider">
          <li>
            <div class="testimonial_main_figure_box">
              <figure>
                <a>
                  <img
                    class="lazy"
                    src="https://static.tossdown.com/images/bfa2095e-b6ef-4132-b4a2-f7fc5001f3c1.png"
                  />
                </a>
              </figure>
              <div class="testimonial_main_figure_tags">
                <div class="testimonial_rating_stars">
                  <i class="fas fa-star"></i> <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i> <i class="fas fa-star"></i>
                  <i class="fad fa-star-half-alt"></i>
                </div>
                <h5>Lorem Ipsum</h5>
                <p>343 Glendale Ave Unit 0315 St Lorem Ipsum, ON</p>
                <small>4.5/5 642 Reviews</small>
                <div class="testimonial_main_figure_tags_social_btn">
                  <a target="blank" ><i class="fab fa-facebook-f"></i></a>
                  <a
                    target="blank"
                    ><i class="fab fa-instagram-square"></i
                  ></a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="testimonial_main_figure_box">
              <figure>
                <a>
                  <img
                    class="lazy"
                    src="https://static.tossdown.com/images/bfa2095e-b6ef-4132-b4a2-f7fc5001f3c1.png"
                  />
                </a>
              </figure>
              <div class="testimonial_main_figure_tags">
                <div class="testimonial_rating_stars">
                  <i class="fas fa-star"></i> <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i> <i class="fas fa-star"></i>
                  <i class="fad fa-star-half-alt"></i>
                </div>
                <h5>Lorem Ipsum</h5>
                <p>343 Glendale Ave Unit 0315 Lorem Ipsum, ON</p>
                <small>4.5/5 642 Reviews</small>
                <div class="testimonial_main_figure_tags_social_btn">
                  <a target="blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a target="blank"
                    ><i class="fab fa-instagram-square"></i
                  ></a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="testimonial_main_figure_box">
              <figure>
                <a>
                  <img
                    class="lazy"
                    src="https://static.tossdown.com/images/bfa2095e-b6ef-4132-b4a2-f7fc5001f3c1.png"
                  />
                </a>
              </figure>
              <div class="testimonial_main_figure_tags">
                <div class="testimonial_rating_stars">
                  <i class="fas fa-star"></i> <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i> <i class="fas fa-star"></i>
                  <i class="fad fa-star-half-alt"></i>
                </div>
                <h5>Lorem Ipsum</h5>
                <p>343 Glendale Ave Unit 0315 Lorem Ipsum, ON</p>
                <small>4.5/5 642 Reviews</small>
                <div class="testimonial_main_figure_tags_social_btn">
                  <a target="blank"><i class="fab fa-facebook-f"></i></a>
                  <a target="blank"><i class="fab fa-instagram-square"></i></a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<script>
  $(document).ready(function () {
    $(".testimonial_main_slider").slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      autoplaySpeed: 2000,
      dots: false,
      arrows: true,
      appendArrows: $(".testimonial_arrow_box"),
      nextArrow:
        '<a class="category_pre_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
      prevArrow:
        '<a class="category_pre_arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            dots: false,
            arrows: true,
          },
        },
      ],
    });
  });
</script>

        
        `
            },
    ]
    export default TestimonialObjects