import React from "react";

import SidebarContent from "../Components/Sidebar-content/Sidebar-content";

const Sidebar = () => {
  return (
    <>
      <SidebarContent />
    </>
  );
};

export default Sidebar;
