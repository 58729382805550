import React from 'react'
import { Link } from "react-router-dom";
import CustomCodeEditor from '../../CodeEditor/CustomCodeEditor';
import NewsletterObjects from '../../Local Json/Newsletter Object/NewsletterObjects';


const NewsletterDesign = (props) => {
  return (
    <>

<div className="content-section-main">
        <div className="content-container">
        <div className="responsive_content_manage">
          <div className="pagination-list">
          <h5>/ <Link to="/">Home</Link> / Ecommerce Components {props.name}</h5>
          </div>
          <div className="home-page-box">
            <h1>COMPONENTS OVERVIEW</h1>
            <p>
              Our component explorer gives you an easy access to 1000+ (free and
              paid) Tailwind CSS components to build beautiful, repsonsive and
              accessible user interfaces in record time.
            </p>
          </div>
          <div className="all-footer-design-list">
         
          
           {NewsletterObjects.map((value,index)=>{
            return(
      <CustomCodeEditor id={value.id} key={index} codedata={value.code} heading={value.name} head={value.head}  />
      )

           }) 
            }


          </div>
        </div>
        </div>
        </div>



    </>
  )
}

export default NewsletterDesign
