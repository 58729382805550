const NewsletterObjects=[
{
id:"Newsletter0",
name:"Newsletter One",
height:"420px",
head:`
<head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
        
    </head>

    <style>
      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
   
      * {
      -web-kit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
      float: left;
      margin: 0px;
      width: 100%;
      padding:40px 0px;
    }
    
      </style>

`,
code:` 


            <script src="https://www.google.com/recaptcha/api.js"></script>
            <script>
             function NewsletteronSubmit(token) {
                 $("#quick_newslatter_recaptcha").submit();
             }
           
             function validate_newslatter(event) {;
                 event.preventDefault();
           
                 $("#quick_newslatter_recaptcha").validate({
                     rules: {
           
                         email: {
                             required: true,
                             email: true
                         },               
                        
                     }
           
                 });
           
                  if ($('#quick_newslatter_recaptcha').valid()) {
                      grecaptcha.execute();
                  }
           
             }
           
              function onload() {
             
                  var element = document.getElementById('newsletter-submit-recaptcha');
                  element.onclick = validate_newslatter;
              }
              
            </script>
      <style>
    
      .home_newsletter_section{
      display: block;
          width: 100%;
          padding: 32px 15px 32px 15px;
          margin-bottom: 25px;
          background: #fff;
      }
      .custom_container {
          max-width: 1140px;
          margin: 0px auto;
      }
      .home_newsletter_box{
          display: grid;
          grid-template-columns: 1.2fr 1fr;
          grid-template-rows: auto;
          grid-template-areas: ". .";
          gap: 20px 20px;
          width: 100%;
      }
      .home_newsletter_box_one{
          width: 100%;
          display: grid;
          gap: 20px 20px;
          align-items: center;
          grid-template-columns: auto auto;
      }
      .home_newsletter_box_one_content{
          display: flex;
          align-items: center;
      }
      .home_newsletter_box_one_content i{
          color: #000000;
          border: 1px solid rgba(0, 0, 0, 0.12);
          box-sizing: border-box;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          margin-right: 12px;
      }
      .home_newsletter_box_one_inner_content{
          display:grid;
          gap: 2px 2px;
      }
      .home_newsletter_box_one_inner_content a{
      font-family: Playfair Display;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #212121;
      }
      
      .home_newsletter_box_one_inner_content span{
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.7);
      } 
      .newsletter_form_fields{
        display:grid;
          gap: 6px 0px;
      }
      .newsletter_form_fields small{
      font-family: Playfair Display;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      }
      .newsletter_form_fields label{
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #EA2227;
          margin: 0px !important;
      }
      .newsletter_form_fields_content{
       display: flex;
          align-items: center;
          position: relative;
      }
      .newsletter_single_form_field {
    width: 100%;
    position: relative;
    display: grid;
}
      .newsletter_form_fields_content input{
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0.12);
          box-sizing: border-box;
          height: 48px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.7);
          display: flex;
          border-radius: 4px 0px 0px 4px;
          padding: 0px 133px 0px 15px;
          align-items: center;
          width: 100%;
       margin: 0px !important;
      }
      .newsletter_form_fields_content input::placeholder{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.7);
       display: flex;
          align-items: center;
      }
      .newsletter_form_fields_content button{
      background: #E32528;
      border-radius: 0px 4px 4px 0px;
      height: 48px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
       display: flex;
          align-items: center;
      justify-content: center;
      width: 126px;
          position: absolute;
          right: 0px;
      top: 0px;
      border: unset !important;
      }
      .newsletter_form_fields_content button i{
        display: none;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      }
      .newsletter_form_fields_content button span.newsletter_submit_btn_text{
        font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      }

      .newsletter_form_fields_content button:hover{
      color: #FFFFFF;
      }
      .newsletter_form_fields_content button:focus {
          outline: 0;
          box-shadow: unset !important;
      }
      
      @media (max-width:1024px){
      .home_newsletter_box {
          grid-template-columns: 1fr;
          grid-template-areas: ".";
      }
      
      }
      @media (max-width:768px){
      .home_newsletter_box {
          grid-template-columns: 1fr;
          grid-template-areas: ".";
      }
      
      }
      @media (max-width:540px){
      .home_newsletter_box_one {
          grid-template-columns: auto;
           padding: 0px 0px 0px 0px;
      }
      .home_newsletter_box_one_content {
        flex-direction: column;
        text-align: center;
      }
      
      .newsletter_form_fields_content {
          display: grid;
          flex-direction: column;
             gap: 12px;
      }
      .newsletter_form_fields_content button i{
        display: block;
    
      }
      .newsletter_form_fields_content button span.newsletter_submit_btn_text{
        display: none;
      }
      .newsletter_form_fields small {
        text-align: center;
      }
      .home_newsletter_box_one_content i {
        margin-right: 0px;
        margin-bottom: 5px;
      }
      .newsletter_form_fields_content button {
    width: 60px;
  }
  .newsletter_form_fields_content input {
    padding: 0px 65px 0px 15px;
  }
      }
      
      </style>
    <section class="home_newsletter_section">
      <div class="custom_container">
      <div class="home_newsletter_box">
      <div class="home_newsletter_box_one">
      <div class="home_newsletter_box_one_content"><i class="fal fa-phone-alt"></i>
      <div class="home_newsletter_box_one_inner_content"><a>XXXX XXXXXXX</a> <span>From 9AM to 6PM</span></div>
      </div>
      <div class="home_newsletter_box_one_content"><i class="fal fa-envelope"></i>
      <div class="home_newsletter_box_one_inner_content"><a>
      abcd@xyz.com</a> <span>From 9AM to 6PM</span></div>
      </div>
      </div>
      <div class="home_newsletter_box_two">
        <form method="POST" action="/website/insert_signup_newsletter" id="quick_newslatter_recaptcha" novalidate="novalidate">
      <div class="newsletter_form_fields"><small>Subscribe To Newsletter To Get Latest Offers &amp; Updates</small>
      <div class="newsletter_form_fields_content">
        <div class="newsletter_single_form_field">
       <input id="get-user-subscribe-email" type="text" required="required" name="email" placeholder="Enter your email" />
       <label for="get-user-subscribe-email" generated="true" class="error">This field ia required.</label>
        </div>
      <!-- <input type="hidden" name="contact_us_id" value="1" /> 
      <?php
          echo '<div id="recaptcha" class="g-recaptcha"
                    data-sitekey="'.$recaptcha_site_key.'"
                    data-callback="NewsletteronSubmit"
                    data-size="invisible">';
           echo '</div>';
          ?>  -->
      <button id="newsletter-submit-recaptcha" type="submit" class="submitted btn">
        <i class="fas fa-paper-plane"></i> 
        <span class="newsletter_submit_btn_text">Subscribe</span>
      </button>
      </div>
      </div>
      </form>
      <!-- <script>onload();</script> -->
      </div>
      </div>
      </div>
      </section> 

 `
},

{
id:"Newsletter1",
name:"Newsletter Two",
height:"400px",
head:`
<head>
<meta charset="utf-8" />
<meta
  name="viewport"
  content="width=device-width, initial-scale=1, shrink-to-fit=no"
/>
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
    
</head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap');
* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  margin: 0px;
  width: 100%;
  padding:40px 0px;
}

</style>

`,
code:`

  

  
            <script src="https://www.google.com/recaptcha/api.js"></script>
            <script>
             function NewsletteronSubmit(token) {
                 $("#quick_newslatter_recaptcha").submit();
             }
           
             function validate_newslatter(event) {;
                 event.preventDefault();
           
                 $("#quick_newslatter_recaptcha").validate({
                     rules: {
           
                         email: {
                             required: true,
                             email: true
                         },               
                        
                     }
           
                 });
           
                  if ($('#quick_newslatter_recaptcha').valid()) {
                      grecaptcha.execute();
                  }
           
             }
           
              function onload() {
             
                  var element = document.getElementById('newsletter-submit-recaptcha');
                  element.onclick = validate_newslatter;
              }
              
            </script>
            
      <style>
    
      .newsletter_custom_section {
        display: block;
        width: 100%;
        padding: 0px 15px;
      }
      .custom_container {
          max-width: 1140px;
          margin: 0px auto;
      }
      .newsletter_custom_box {
        background: #000000;
        border-radius: 30px;
        padding: 40px 35px;
        display: grid;
        gap: 20px 20px;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: ". .";
        grid-template-rows: auto;
      }
      .newsletter_custom_box h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 43px;
        text-transform: capitalize;
        color: #ffffff;
        margin: 0px !important;
        font-family: 'Space Grotesk', sans-serif;
      }
      
      .home_newsletter_box{
          display: grid;
          grid-template-columns: 1.2fr 1fr;
          grid-template-rows: auto;
          grid-template-areas: ". .";
          gap: 20px 20px;
          width: 100%;
      }

   
      .newsletter_form_fields_content{
       display: flex;
          align-items: center;
          position: relative;
      }
    
      .newsletter_form_fields_content input{
        border: 1px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    border-radius: 54px;
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.9);
    padding: 0px 160px 0px 20px;
    background-color: #000000;
    font-family: 'Space Grotesk', sans-serif;
       margin: 0px !important;
      }
      .newsletter_form_fields_content input:focus-visible{
        outline: none !important;
      }
      .newsletter_form_fields_content input::placeholder{
        font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.9);
       display: flex;
          align-items: center;
      }
      .newsletter_form_fields_content button{
        display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 153px;
    background: #ed2a3b;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
    border-radius: 44px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-transform: capitalize;
    color: #ffffff;
    position: absolute;
    content: "";
    top: 0px;
    right: 0px;
    font-family: 'Space Grotesk', sans-serif;
      border: unset !important;
      }
      .newsletter_form_fields_content button i{
        display: none;
        font-size: 15px;
    line-height: 19px;
      color: #FFFFFF;
      }
       
      .newsletter_form_fields_content label.error{
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #EA2227;
          margin: 0px !important;
          position: absolute;
    bottom: -30px;
    left: 10px;
    font-family: 'Space Grotesk', sans-serif;
      }
      .newsletter_form_fields_content button span.newsletter_submit_btn_text{
        font-style: normal;
      font-weight: 600;
      font-size: 15px;
    line-height: 19px;
      color: #FFFFFF;
      }

      .newsletter_form_fields_content button:hover{
      color: #FFFFFF;
      }
      .newsletter_form_fields_content button:focus {
          outline: 0;
          box-shadow: unset !important;
      }
      
      @media (max-width:1024px){
      
        .newsletter_custom_box {
         grid-template-columns: 1fr 1.5fr;
  
      }
      
      }
      @media (max-width:768px){
        .newsletter_custom_box {
          grid-template-columns: 1fr;
          grid-template-areas: ".";
      }
      .newsletter_custom_box h3 {
    text-align: center;
}
      
      }
      @media (max-width:540px){
        .newsletter_custom_box {
    padding: 40px 15px;
}
      
      .newsletter_form_fields_content button i{
        display: block;
    
      }
      .newsletter_form_fields_content button span.newsletter_submit_btn_text{
        display: none;
      }
     
      .newsletter_form_fields_content button {
        width: 100px;
    border-radius: 54px;
  }
  .newsletter_form_fields_content input {
    padding: 0px 105px 0px 15px;
  }
      }
      
      </style>

  <div class="newsletter_custom_section">
    <div class="custom_container">
    <div class="newsletter_custom_box">
    <h3>Subscribe to our newsletter</h3>
    <form method="POST" action="/website/insert_signup_newsletter" id="quick_newslatter_recaptcha" novalidate="novalidate">
     
      <div class="newsletter_form_fields_content">
       <input id="get-user-subscribe-email" type="text" required="required" name="email" placeholder="Enter your email" />
      <!-- <input type="hidden" name="contact_us_id" value="1" /> 
      <?php
          echo '<div id="recaptcha" class="g-recaptcha"
                    data-sitekey="'.$recaptcha_site_key.'"
                    data-callback="NewsletteronSubmit"
                    data-size="invisible">';
           echo '</div>';
          ?>  -->
      <button id="newsletter-submit-recaptcha" type="submit" class="submitted btn">
        <i class="fas fa-paper-plane"></i> 
        <span class="newsletter_submit_btn_text">Subscribe</span>
      </button>
      <label for="get-user-subscribe-email" generated="true" class="error">This field ia required.</label>
      </div>
     
      </form>
    <!-- <script>onload();</script> -->
    </div>
    </div>
    </div>

    
    `,
},

{
id:"Newsletter2",
name:"Newsletter Three",
height:"300px",
head:`
<head>
<meta charset="utf-8" />
<meta
  name="viewport"
  content="width=device-width, initial-scale=1, shrink-to-fit=no"
/>
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
    
</head>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap');

* {
-web-kit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
padding: 0px;
margin: 0px;
}
html {
scroll-behavior: smooth;
}
body {
float: left;
margin: 0px;
width: 100%;
padding:40px 0px;
}
</style>

`,

code:`

      
                <script src="https://www.google.com/recaptcha/api.js"></script>
                <script>
                 function NewsletteronSubmit(token) {
                     $("#quick_newslatter_recaptcha").submit();
                 }
               
                 function validate_newslatter(event) {;
                     event.preventDefault();
               
                     $("#quick_newslatter_recaptcha").validate({
                         rules: {
               
                             email: {
                                 required: true,
                                 email: true
                             },               
                            
                         }
               
                     });
               
                      if ($('#quick_newslatter_recaptcha').valid()) {
                          grecaptcha.execute();
                      }
               
                 }
               
                  function onload() {
                 
                      var element = document.getElementById('newsletter-submit-recaptcha');
                      element.onclick = validate_newslatter;
                  }
                  
                </script>
              
          <style>
          .newsletter_custom_section {
            display: block;
            width: 100%;
            padding: 0px 15px;
            background: #F5F5F5;
          }
          .custom_container {
              max-width: 1140px;
              margin: 0px auto;
          }
          .newsletter_custom_box {
            padding: 40px 0px;
            display: grid;
            gap: 16px 20px;
            align-items: center;
            grid-template-columns: 1fr;
            grid-template-areas: ".";
            grid-template-rows: auto;
            text-align: center;
            max-width: 540px;
            margin: 0 auto;
          }
          .newsletter_custom_box h3 {
            font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
        text-transform: uppercase;
        color: #000000;
        margin: 0px !important;
        text-align: center;
          }
          .newsletter_custom_box form {
        width: 100%;
        position: relative;
    }
       
    
          .newsletter_form_fields_content{
           display: flex;
              align-items: flex-start;
              position: relative;
          }
          .newsletter_form_fields {
        width: 100%;
        display: grid;
        gap: 10px 0px;
    }
          .newsletter_form_fields_content input{
            background: #FFFFFF;
        box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
        border-radius: 4px;
        border: unset;
        display: flex;
        align-items: center;
        height: 48px;
        width: 100%;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        color: #616161;
        padding: 5px 15px;
       margin: 0px;
      }
    
          .newsletter_form_fields_content input:focus-visible{
            outline: none !important;
          }
          .newsletter_form_fields_content input::placeholder{
            font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        color: #616161;
           display: flex;
              align-items: center;
          }
          .newsletter_form_fields_content button{
            display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        max-width: 153px;
        width: 100%;
        background: #194E8C;
        border-radius: 4px;
        border: unset;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-left: 8px;
          }
          .newsletter_form_fields_content button i{
            display: none;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
          }
           
          .newsletter_form_fields_content label.error{
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #CC0000;
            text-align: left;
              margin: 0px !important;
              font-family: 'Source Sans Pro';
    
          }
          .newsletter_form_fields_content button span.newsletter_submit_btn_text{
            font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #FFFFFF;
          }
    
          .newsletter_form_fields_content button:hover{
          color: #FFFFFF;
          }
          .newsletter_form_fields_content button:focus {
              outline: 0;
              box-shadow: unset !important;
          }
          
          @media (max-width:1024px){
          
          
          }
          @media (max-width:768px){
          
          
          }
          @media (max-width:540px){
          
          .newsletter_form_fields_content button i{
            display: block;
        
          }
          .newsletter_form_fields_content button span.newsletter_submit_btn_text{
            display: none;
          }
         
          .newsletter_form_fields_content button {
            width: 80px;
      }
     
          }
          
          </style>
    
      <div class="newsletter_custom_section">
        <div class="custom_container">
        <div class="newsletter_custom_box">
        <h3>Subscribe to our newsletter</h3>
        <form method="POST" action="/website/insert_signup_newsletter" id="quick_newslatter_recaptcha" novalidate="novalidate">
         
          <div class="newsletter_form_fields_content">
            <div class="newsletter_form_fields">
           <input id="get-user-subscribe-email" type="text" required="required" name="email" placeholder="Enter your email" />
           <label for="get-user-subscribe-email" generated="true" class="error">This field is required.</label>
          </div>
          <!-- <input type="hidden" name="contact_us_id" value="1" /> 
          <?php
              echo '<div id="recaptcha" class="g-recaptcha"
                        data-sitekey="'.$recaptcha_site_key.'"
                        data-callback="NewsletteronSubmit"
                        data-size="invisible">';
               echo '</div>';
              ?>  -->
          <button id="newsletter-submit-recaptcha" type="submit" class="submitted btn">
            <i class="fas fa-paper-plane"></i> 
            <span class="newsletter_submit_btn_text">Subscribe</span>
          </button>
          </div>
         
          </form>
        <!-- <script>onload();</script> -->
        </div>
        </div>
        </div>
        
    `,
}, 

{
  id:"Newsletter3",
  name:"Newsletter Four",
  height:"300px",
  head:`
  <head>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  /> 
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
  <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>   
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"></script>
  </head>

  <style>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  * {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  margin: 0px;
  width: 100%;
  background:#fff;
  padding:40px 0px;
}
  </style>
  
  `,
  
  code:`
         
  <script src="https://www.google.com/recaptcha/api.js"></script>
  <script>
    function NewslettertwoonSubmit(token) {
      $("#quick_newslatter_two_recaptcha").submit();
    }
  
    function validate_newslatter_two(event) {
      event.preventDefault();
  
      $("#quick_newslatter_two_recaptcha").validate({
        rules: {
          email: {
            required: true,
            email: true,
          },
        },
      });
  
      if ($("#quick_newslatter_two_recaptcha").valid()) {
        grecaptcha.execute();
      }
    }
  
    function newslattertwoonload() {
      var element = document.getElementById("newsletter_two-submit-recaptcha");
      element.onclick = validate_newslatter_two;
    }
  </script>
  <style>
    .newsletter_two_custom_section {
      display: block;
      width: 100%;
      padding: 0px 10px;
      float: left;
      margin-bottom: 40px;
    }
    .custom_container {
      max-width: 1140px;
      margin: 0px auto;
    }
    .newsletter_two_custom_box {
      display: grid;
      gap: 20px 20px;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: ". .";
      grid-template-rows: auto;
    }
    .newsletter_two_custom_box_content {
      display: flex;
      flex-direction: column;
    }
    .newsletter_two_custom_box_content h3 {
      font-family: "Work Sans" !important;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      color: #000000;
      padding-bottom: 8px;
      margin: 0px !important;
    }
    .newsletter_two_custom_box_content p {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #000000;
    }
  
    .home_newsletter_two_box {
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: ". .";
      gap: 20px 20px;
      width: 100%;
    }
  
    .newsletter_two_form_fields_content {
      display: flex;
      align-items: center;
      position: relative;
    }
  
    .newsletter_two_form_fields_content input {
      border: unset;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0px 160px 0px 20px;
      margin: 0px !important;
      background: #eae5db;
      border-radius: 40px;
      height: 56px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
    .newsletter_two_form_fields_content input:focus-visible {
      outline: none !important;
    }
    .newsletter_two_form_fields_content input::placeholder {
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      display: flex;
      align-items: center;
    }
    .newsletter_two_form_fields_content button {
      height: 56px;
      width: 155px;
      position: absolute;
      content: "";
      top: 0px;
      right: 0px;
      border: unset !important;
      background: #000000;
      border-radius: 0px 40px 40px 0px;
    }
    .newsletter_two_form_fields_content button i {
      display: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #e4c899;
    }
  
    .newsletter_two_form_fields_content label.error {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #ea2227;
      margin: 0px !important;
      position: absolute;
      bottom: -30px;
      left: 10px;
    }
    .newsletter_two_form_fields_content
      button
      span.newsletter_two_submit_btn_text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: #e4c899;
    }
  
    .newsletter_two_form_fields_content button:hover {
      color: #e4c899;
    }
    .newsletter_two_form_fields_content button:focus {
      outline: 0;
      box-shadow: unset !important;
    }
  
    @media (max-width: 1024px) {
      .newsletter_two_custom_box {
        grid-template-columns: 1fr 1.5fr;
      }
    }
    @media (max-width: 768px) {
      .newsletter_two_custom_box {
        grid-template-columns: 1fr;
        grid-template-areas: ".";
      }
      .newsletter_two_custom_box h3 {
        text-align: center;
      }
      .newsletter_two_custom_box_content {
        text-align: center;
      }
    }
    @media (max-width: 540px) {
      .newsletter_two_custom_section {
        margin-bottom: 30px;
      }
      .newsletter_two_form_fields_content button i {
        display: block;
      }
      .newsletter_two_form_fields_content
        button
        span.newsletter_two_submit_btn_text {
        display: none;
      }
  
      .newsletter_two_form_fields_content button {
        width: 70px;
      }
      .newsletter_two_form_fields_content input {
        padding: 0px 80px 0px 15px;
        min-width: 100% !important;
        min-height: 56px !important;
      }
      .newsletter_two_custom_box_content h3 {
        font-size: 24px;
        line-height: 28px;
      }
    }
  </style>
  <div class="newsletter_two_custom_section">
    <div class="custom_container">
      <div class="newsletter_two_custom_box">
        <div class="newsletter_two_custom_box_content">
          <h3>Subscribe to Our Newsletter</h3>
          <p>
            Subscribe to our newsletter to get lastest news &amp; updates directly
            to your inbox.
          </p>
        </div>
        <form
          method="POST"
          action="/website/insert_signup_newsletter"
          id="quick_newslatter_two_recaptcha"
          novalidate="novalidate"
        >
          <div class="newsletter_two_form_fields_content">
            <input
              id="get-user-subscribe-email"
              type="text"
              name="email"
              placeholder="Email"
            />
            <!-- <input type="hidden" name="contact_us_id" value="1" />  -->
            <!-- <?php
                echo '<div id="recaptcha" class="g-recaptcha"
                          data-sitekey="'.$recaptcha_site_key.'"
                          data-callback="NewslettertwoonSubmit"
                          data-size="invisible">';
                 echo '</div>';
                ?>  -->
            <button
              id="newsletter_two-submit-recaptcha"
              type="submit"
              class="submitted btn"
            >
              <i class="fas fa-paper-plane"></i>
              <span class="newsletter_two_submit_btn_text">Subscribe</span>
            </button>
            <label
              for="get-user-subscribe-email"
              generated="true"
              class="error"
            ></label>
          </div>
        </form>
        <!-- <script>newslattertwoonload();</script> -->
      </div>
    </div>
  </div>
  
          
      `,
  }, 

]
export default NewsletterObjects