import React, { useState } from 'react'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import { Controlled as ControlledEditor } from 'react-codemirror2'
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
 import $ from "jquery";
 import {CopyToClipboard} from 'react-copy-to-clipboard';
export default function Editor(props) {
  const {
    language,
    displayName,
    value,
    head,
    onChange
  } = props
  const [open, setOpen] = useState(true)
  const [copydatatextstate, setcopydatatextstate] = useState(false);
  function handleChange(editor, data, value) {
    onChange(value)
  }
  
  const onClick = ({target: {innerHTML}}) => {
    console.log(`Clicked on "${innerHTML}"!`); // eslint-disable-line
    setcopydatatextstate(true);
  };

  setTimeout(() => setcopydatatextstate(false), 10000);
 
  return (
    <>

       <div className="editor-title">
         <div className="editor-title_data name_of_single_component"> <h3>{displayName}</h3></div>
        <section className="editor-title_data copy_to_clipboard_section">
          <CopyToClipboard
            // onCopy={onCopy}
            // options={{message: 'Whoa!'}}
            text={value}>
            <button onClick={onClick}>   {copydatatextstate ? <span style={{color: '#fff'}}>Copied.</span> : 'Copy to clipboard'}
             <small></small>
            <small></small>
            <small></small>
            <small></small>
            </button>
          </CopyToClipboard>
        </section>
       
        <button
          type="button"
          className="editor-title_data show_component_data_btn expand-collapse-btn edit-show-code-btn"
          onClick={() => setOpen(prevOpen => !prevOpen)}
          
        >
          <span>Get Code</span>
           <FontAwesomeIcon icon={open ? faCompressAlt : faExpandAlt} /> 
        </button>
      </div>
     
    <div className={`editor-container ${open ? '' : 'collapsed'}`} onClick={()=>test()}>
    
      <ControlledEditor
      
        onBeforeChange={handleChange}
        value={value}
        className="code-mirror-wrapper test"
        options={{
          lineWrapping: true,
          lint: true,
          mode: language,
          theme: 'material',
          lineNumbers: true,
          
        }}
      />
    </div>

    </>
  )
}


